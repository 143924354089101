import React from 'react'
import { Button, ButtonActionBar } from 'modules/core/components'
import { RightCircleOutlined } from '@ant-design/icons'
import { useHistory, useParams } from 'react-router-dom'

const ButtonEnterFooter = ({ conceptId, conceptName }) => {
  let history = useHistory()
  let { periodId } = useParams()

  return (
    <ButtonActionBar position="start">
      <Button.LinkWhitRightIcon
        onClick={() => history.push(`/seguimiento/${periodId}/${conceptName}/${conceptId}`)}
        title="ACTION_ENTER"
        icon={<RightCircleOutlined />}
      />
    </ButtonActionBar>
  )
}

export default ButtonEnterFooter
