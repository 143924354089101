import React, { useState, useEffect } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { connect } from 'react-redux'
import { Navigator } from 'modules/core/components'
import planning from 'modules/planning'

const PeriodNavigator = ({ periodList, fetchPeriodList, items = [], ...props }) => {
  const [currentPeriod, setCurrentPeriod] = useState({})
  const [isLoading, setIsLoading] = useState(true)
  let { periodId } = useParams()
  let location = useLocation()

  useEffect(() => {
    periodList && setCurrentPeriod(periodList.find((period) => period.id === parseInt(periodId)))
    return () => {
      setCurrentPeriod({})
      setIsLoading(false)
    }
  }, [periodId, periodList])

  useEffect(() => {
    fetchPeriodList().then(() => setIsLoading(false))
  }, [fetchPeriodList])

  return (
    !isLoading && (
      <Navigator
        items={[
          {
            name: currentPeriod.name,
            children:
              periodList.length > 1
                ? [
                    ...periodList
                      .filter((period) => period.id !== parseInt(periodId))
                      .map((period) => ({
                        name: period.name,
                        url: `/${location.pathname.split('/')[1]}/${period.id}/${location.search}`,
                      })),
                  ]
                : null,
          },
          ...items,
        ]}
        {...props}
      />
    )
  )
}

const mapStateToProps = (state) => ({
  periodList: planning.selectors.getPeriodList(state),
})

const mapDispatchToProps = {
  fetchPeriodList: planning.actions.fetchPeriodList,
}

export default connect(mapStateToProps, mapDispatchToProps)(PeriodNavigator)
