import { message } from 'antd'
import { generateMessageError } from 'modules/core/utils'
import i18n from 'i18next'

export const handleDeleteRow = ({ setIsLoading, deleteRow, onRefetch, onClose }) => {
  setIsLoading(true)
  deleteRow()
    .then(() => {
      onRefetch()
      message.success(i18n.t('FEEDBACK_DELETE_SELECTION_SUCCESS'), 8)
      onClose()
    })
    .catch((error) => {
      message.error(generateMessageError(error, 'FEEDBACK_DELETE_SELECTION_ERROR'), 8)
      onClose()
    })
}

export const handleSave = ({ setIsLoading, createLayout, onRefetch, onRefresh, onClose }) => {
  setIsLoading(true)
  createLayout()
    .then(() =>
      onRefetch().then(() => {
        onRefresh()
        message.success(i18n.t('FEEDBACK_CHANGES_SAVED_SUCCESS'), 8)
        onClose()
      }),
    )
    .catch((error) => {
      message.error(generateMessageError(error, 'FEEDBACK_SAVE_CHANGES_FAIL_MESSAGE'), 8)
      onClose()
    })
}

export const handleSavePrimary = ({ setIsLoading, createLayout, onRefetch, onClose }) => {
  setIsLoading(true)
  createLayout()
    .then(() =>
      onRefetch().then(() => {
        message.success(i18n.t('FEEDBACK_CHANGES_SAVED_SUCCESS'), 8)
        onClose()
      }),
    )
    .catch((error) => {
      message.error(generateMessageError(error, 'FEEDBACK_SAVE_CHANGES_FAIL_MESSAGE'), 8)
      onClose()
    })
}

export const handleDelete = ({ setIsLoading, deleteLayout, onRefetch, onClose }) => {
  setIsLoading(true)
  deleteLayout()
    .then(() =>
      onRefetch().then(() => {
        message.success(i18n.t('FEEDBACK_CHANGES_SAVED_SUCCESS'), 8)
        onClose()
      }),
    )
    .catch((error) => {
      message.error(generateMessageError(error, 'FEEDBACK_SAVE_CHANGES_FAIL_MESSAGE'))
      onClose()
    })
}
