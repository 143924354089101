import React from 'react'
import { Col, Drawer, Row, Spin } from 'antd'
import { Button, CreateVariableModal, EmptyScreen } from 'modules/core/components'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { useState } from 'react'
import { TableActions, UserActions, VariablesTable } from './components'
import { ROLES } from 'modules/core/constants'
import { isUserAllowed } from 'modules/core/utils'
import emptyStateImage from 'assets/images/compositions/empty-big.svg'
import configuration from 'modules/configuration'

const { SETTINGS__PROJECTION_ELEMENTS__VARIABLES__ABM } = ROLES

const VariablesList = ({
  visible,
  onCancel,
  loading,
  variablesList,
  onRefetch,
  pagination,
  onChange,
  setSearchVariableValue,
}) => {
  const [showCreateModal, setShowCreateModal] = useState(false)
  const [dataSelection, setDataSelection] = useState([])
  const { t } = useTranslation()

  const canUserCreate = isUserAllowed(SETTINGS__PROJECTION_ELEMENTS__VARIABLES__ABM)

  const renderNoDataScreen = () => (
    <EmptyScreen
      image={emptyStateImage}
      title={t('PLANNING_VARS_EMPTY_SCREEN_UPLOAD_SUBTITLE')}
      footer={
        <Button.Primary
          onClick={() => setShowCreateModal(true)}
          disabled={!canUserCreate}
          title="CONFIG_VAR_CREATE_MODAL_TITLE"
        />
      }
    />
  )

  const renderTopActions = () => (
    <UserActions onClick={() => setShowCreateModal(true)} onSearch={setSearchVariableValue} />
  )

  const renderTable = () => (
    <VariablesTable
      dataSource={variablesList.values}
      loading={loading}
      onChange={onChange}
      count={variablesList.count}
      pagination={pagination}
      onFetchVariablesList={onRefetch}
      dataSelection={dataSelection}
      setDataSelection={setDataSelection}
      canDelete={canUserCreate}
    />
  )

  const renderTableAction = () => <TableActions dataSelection={dataSelection} />

  return (
    <Drawer
      title={t('CONFIG_VAR_LIST_DRAWER_TITLE')}
      visible={visible}
      onClose={onCancel}
      size="large"
    >
      <Spin spinning={loading}>
        {variablesList.count <= 0 && !loading && renderNoDataScreen()}
        {variablesList.count > 0 && !loading && (
          <Row gutter={[8, 8]}>
            <Col span={24}>{renderTopActions()}</Col>
            <Col span={24}>{renderTableAction()}</Col>
            <Col span={24}>{renderTable()}</Col>
          </Row>
        )}
        <CreateVariableModal
          visible={showCreateModal}
          onCancel={() => setShowCreateModal(false)}
          onRefetchVariablesList={onRefetch}
        />
      </Spin>
    </Drawer>
  )
}

const mapDispatchToProps = {
  editVariable: configuration.actions.editVariable,
  deleteVariable: configuration.actions.deleteVariable,
}

export default connect(null, mapDispatchToProps)(VariablesList)
