export const NAME = 'question'

export const FETCH_QUESTION_LIST = `${NAME}/FETCH_QUESTION_LIST`
export const FETCH_QUESTION_LIST_SUCCESS = `${NAME}/FETCH_QUESTION_LIST_SUCCESS`
export const FETCH_QUESTION_LIST_FAIL = `${NAME}/FETCH_QUESTION_LIST_FAIL`

export const CREATE_QUESTION = `${NAME}/CREATE_QUESTION`

export const DELETE_QUESTION = `${NAME}/DELETE_QUESTION`

export const EDIT_QUESTION = `${NAME}/EDIT_QUESTION`
