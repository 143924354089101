import i18n from 'i18next'
import _ from 'lodash'
import { generateSelectOptions } from 'modules/core/utils'
import { HOME } from 'modules/core/constants'

export const generateDefaultSelects = (
  dataFact,
  dataSel,
  dataFiles,
  indexFact,
  setDataSelect,
  dataSelection,
) => [
  {
    id: 'columns',
    name: 'HOME_INITIAL_STEPS_SELECT_DATA_COLUMNS_TRANSACTION_FILE_SELECT_TITLE',
    onChange: (val) => {
      setDataSelect({
        dataFactId: dataFact.id,
        dataSelId: dataSel.id,
        value: val,
        selectionType: 'columns',
      })
    },
    value: dataSel.columns,
    options: generateSelectOptions({
      options: dataFiles.fact_table[indexFact].columns.map((col) => {
        return { id: col, name: col }
      }),
      selection: _.compact(
        dataSelection.fact_table[indexFact].selections.map((sel) => sel.columns),
      ),
    }),
  },
  {
    id: 'type',
    name: 'FIELD_DATA_TYPE',
    onChange: (val) => {
      setDataSelect({
        dataFactId: dataFact.id,
        dataSelId: dataSel.id,
        value: val,
        selectionType: 'type',
        others: { dimension: null, match: null },
      })
    },
    value: dataSel.type,
    options: generateSelectOptions({
      options: HOME.MAPPING_DATA.COLUMN_TYPE_LIST.map((column) => {
        return { ...column, name: i18n.t(column.name) }
      }),
      selection: _.compact(
        dataSelection.fact_table[indexFact].selections.map((sel) => {
          if (
            sel.type &&
            (sel.type.split('-')[0] !== 'dimension' || sel.type.split('-')[1] === 'currency')
          ) {
            return sel.type
          }
          return null
        }),
      ),
    }),
  },
]

export const generateSelectionsSelects = (
  dataFact,
  dataSel,
  dataFiles,
  setDataSelect,
  loading,
  dimensionPlikaList,
  dimensionsList,
) => {
  return {
    dimension: [
      {
        id: 'dimensionPlika',
        name: 'HOME_INITIAL_STEPS_SELECT_DATA_DIMENSIONS_PLIKA_SELECT_TITLE',
        onChange: (val) => {
          setDataSelect({
            dataFactId: dataFact.id,
            dataSelId: dataSel.id,
            value: val,
            selectionType: 'dimension',
          })
        },
        value: dataSel.dimension,
        options: generateSelectOptions({
          options: [
            ...dimensionPlikaList.map((dim) => {
              return { id: `${dim.id}_base`, name: dim.name_dimension }
            }),
            ...dimensionsList.map((dim) => {
              return { id: `${dim.id}_custom`, name: dim.name_destination }
            }),
          ],
        }),
        loading,
      },
    ],
    transaction: [],
  }
}
