import { message } from 'antd'
import { generateMessageError } from 'modules/core/utils'
import i18n from 'i18next'

export const handleDelete = ({
  setIsLoading,
  deleteDimensionValuesSelections,
  onRefetch,
  onClose,
}) => {
  setIsLoading(true)
  deleteDimensionValuesSelections()
    .then(() =>
      onRefetch().then(() => {
        message.success(i18n.t('FEEDBACK_DELETE_SELECTION_SUCCESS'), 8)
        onClose()
      }),
    )
    .catch((error) => {
      message.error(generateMessageError(error, 'FEEDBACK_DELETE_SELECTION_ERROR'), 8)
      onClose()
    })
}
