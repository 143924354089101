import { message } from 'antd'
import { generateMessageError } from 'modules/core/utils'
import i18n from 'i18next'

export const handleConfig = ({ form, setIsLoading, onAction, onRefetch, onClose }) => {
  form.validateFields().then((values) => {
    setIsLoading(true)
    onAction(values)
      .then(() => {
        onRefetch && onRefetch()
        message.success(i18n.t('FEEDBACK_SAVE_CHANGES_SUCCES'), 8)
        onClose()
      })
      .catch((error) => {
        message.error(generateMessageError(error, 'FEEDBACK_SAVE_CHANGES_FAIL_MESSAGE'), 8)
        onClose()
      })
  })
}
