import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import { usePagination } from 'modules/core/customHooks'
import { Card, Col, Row, Space, Spin } from 'antd'
import { Navigator, EmptyScreen, Button, Typography } from 'modules/core/components'
import { SiderLayout } from 'modules/core/layouts'
import { isUserAllowed } from 'modules/core/utils'
import { ROLES } from 'modules/core/constants'
import {
  CreateValueDimensionModal,
  DimensionsDetailTable,
  ManagementModal,
  TableActions,
  UserActions,
} from './components'
import { ConfigurationUploadModal, DisplaySettingsModal } from 'modules/configuration/components'
import { handleDelete } from './utils'
import { client } from 'httpclient'
import configuration from 'modules/configuration'
import emptyStateImage from 'assets/images/compositions/empty-big.svg'
import moment from 'moment'

import './DimensionDetail.scss'

const { SETTINGS__DIMENSIONS__ABM } = ROLES

const DimensionDetail = ({
  dimensionValues: { values, count },
  fetchDimensionValues,
  fetchDimensionTemplateXLS,
  uploadDimensionValuesXLS,
  deleteDimensionValuesSelections,
  downloadDimensionValuesFile,
}) => {
  const [isTableLoading, setIsTableLoading] = useState(false)
  const [searchValue, setSearchValue] = useState(null)
  const [dataSelection, setDataSelection] = useState([])
  const [showCreateModal, setShowCreateModal] = useState(false)
  const [tablePagination, setTablePagination] = usePagination()
  const [isScreenLoading, setIsScreenLoading] = useState(false)
  const [showManagementModal, setShowManagementModal] = useState(false)
  const [showUploadModal, setShowUploadModal] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  const [dimensionsListValues, setDimensionListValues] = useState([])
  const [page, setPage] = useState(1)
  const [showSettingsModal, setShowSettingsModal] = useState(false)
  const [radioValue, setRadioValue] = useState(null)

  const { t } = useTranslation()
  let { dimensionId } = useParams()
  let history = useHistory()

  useEffect(() => {
    if (dimensionId) {
      setIsScreenLoading(true)
      client
        .get(`/dimensions/all_dimensions/${page ? `?page=${page}` : ''}`)
        .then((response) => {
          setDimensionListValues([...dimensionsListValues, ...response.data.results])
          if (response.data.next) {
            setPage(page + 1)
          } else {
            setIsScreenLoading(false)
          }
        })
        .catch(() => console.log('Dimension error'))
    }
    // eslint-disable-next-line
  }, [dimensionId, page])

  useEffect(() => {
    setIsTableLoading(true)
    fetchDimensionValues(dimensionId, {
      search: searchValue,
      ...tablePagination,
    }).then(() => setIsTableLoading(false))
  }, [fetchDimensionValues, searchValue, dimensionId, tablePagination])

  const userCanCreate = isUserAllowed(SETTINGS__DIMENSIONS__ABM)

  const onSetDataRowSelection = (data) => setDataSelection(data)

  const onRefetch = () => {
    const page = Math.ceil(count / 10)
    if (tablePagination.page === page) {
      setTablePagination({ current: 1 })
      return fetchDimensionValues(dimensionId, { search: searchValue, page_size: 10, page: 1 })
    } else {
      return fetchDimensionValues(dimensionId, { search: searchValue, ...tablePagination })
    }
  }

  const renderNoDataScreen = () => (
    <EmptyScreen
      image={emptyStateImage}
      title={t('LABEL_VALUES_DIMENSION')}
      description={t('CONFIG_DIMENSIONS_PROCESS_DATA_EMPTY_SCREEN_DESCRIPTION')}
      footer={
        <Space direction="horizontal">
          <Button.Default
            onClick={() => setShowUploadModal(true)}
            disabled={!userCanCreate}
            title="ACTION_UPLOAD_FILE"
          />
          <Button.Primary
            title="ACTION_CREATE_NEW"
            onClick={() => setShowCreateModal(true)}
            disabled={!userCanCreate}
          />
        </Space>
      }
    />
  )

  const renderHeader = () => (
    <Navigator
      floated={false}
      size="big"
      items={[
        {
          name: t('LABEL_CONFIG'),
          url: `/configuracion`,
        },
        {
          name: t('LABEL_DIMENSIONS'),
          url: '/configuracion/dimensiones',
        },
        {
          name:
            dimensionsListValues.length > 0
              ? dimensionsListValues.find((dim) => dim.id === parseInt(dimensionId))
                  ?.name_destination
              : '',
        },
      ]}
    />
  )

  const renderUserActions = () => (
    <UserActions
      setSearchValue={setSearchValue}
      onClick={() => setShowCreateModal(true)}
      onClickDownload={() => setShowUploadModal(true)}
      onClickSettings={() => setShowSettingsModal(true)}
      disabled={!userCanCreate}
      onClickActivity={() => {
        history.push(
          `/configuracion/dimensiones/${dimensionId}/actividad?type=dimension&module=configDimensionValue`,
        )
      }}
    />
  )

  const renderTable = () => (
    <DimensionsDetailTable
      dataSource={values}
      count={count}
      dimensionId={dimensionId}
      tableLoading={isTableLoading}
      onTableChange={setTablePagination}
      pagination={tablePagination}
      dataSelection={dataSelection}
      onSetRowSelection={(id) => {
        onSetDataRowSelection(id)
      }}
      onFetchDimensionValues={() => onRefetch()}
    />
  )

  const onClose = () => {
    setIsDeleting(false)
    setDataSelection([])
  }

  const renderTableActions = () => (
    <TableActions
      dataSelection={dataSelection}
      onConfirmDelete={() =>
        handleDelete({
          setIsLoading: setIsDeleting,
          deleteDimensionValuesSelections: () =>
            deleteDimensionValuesSelections(dimensionId, { dim_value: dataSelection }),
          onRefetch,
          onClose,
        })
      }
      disabled={!userCanCreate}
      isDeleting={isDeleting}
      onClickGroup={() => setShowManagementModal(true)}
      onClickDownload={() =>
        downloadDimensionValuesFile(
          dimensionId,
          t('CONFIG_DIMENSIONS_VALUES_FILE_NAME', { date: moment().format('lll') }),
        )
      }
    />
  )

  const renderKpis = () => (
    <Row gutter={[24, 24]}>
      <>
        {isTableLoading &&
          [1].map((index) => (
            <Col span={4} key={index}>
              <Card loading={true} />
            </Col>
          ))}
        {!isTableLoading && (
          <Col span={4}>
            <Card bodyStyle={{ backgroundColor: '#e1effa' }}>
              <div style={{ textAlign: 'center' }}>
                <Typography.Headline level={3}>{count}</Typography.Headline>
              </div>
              <div style={{ textAlign: 'center' }}>
                <Typography.Body level={3} type="secondary">
                  {t('CONFIG_DIMENSIONS_TOTAL_VALUES')}
                </Typography.Body>
              </div>
            </Card>
          </Col>
        )}
      </>
    </Row>
  )

  return (
    <SiderLayout>
      <div className="dimensions-detail-container">
        <Row gutter={[12, 12]}>
          <Col span={24}>{renderHeader()}</Col>
          <Spin spinning={isScreenLoading} />
          {count <= 0 && !searchValue && !isScreenLoading && (
            <Col span={24}>{renderNoDataScreen()}</Col>
          )}
          {(count > 0 || (searchValue && count === 0)) && !isScreenLoading && (
            <>
              <Col span={24}>{renderUserActions()}</Col>
              <Col span={24}>{renderKpis()}</Col>
              <Col span={24}>{renderTableActions()}</Col>
              <Col span={24}>{renderTable()}</Col>
            </>
          )}
        </Row>
        <ConfigurationUploadModal
          title={t('CONFIG_DIMENSION_VALUES_UPLOAD_MODAL_TITLE')}
          visible={showUploadModal}
          onCancel={() => setShowUploadModal(false)}
          onSuccessUpload={() => onRefetch()}
          uploadValuesXLS={(file) => uploadDimensionValuesXLS(file, dimensionId)}
          setIsTableLoading={setIsTableLoading}
          nameConfig={t('LABEL_VALUES_DIMENSION')}
          downloadTemplateFile={() =>
            fetchDimensionTemplateXLS(
              t('CONFIG_DIMENSIONS_VALUES_TEMPLATE_NAME_DEFAULT', { date: moment().format('lll') }),
            )
          }
        />
        <ManagementModal
          visible={showManagementModal}
          onCancel={() => {
            setShowManagementModal(false)
            setDataSelection([])
          }}
          dataSelection={dataSelection}
          setSearchValue={setSearchValue}
          dataSource={values}
          onFetchDimensionsValues={() => onRefetch()}
          dimensionId={dimensionId}
        />
        <CreateValueDimensionModal
          visible={showCreateModal}
          onCancel={() => setShowCreateModal(false)}
          onFetchDimensionValues={() => onRefetch()}
          dimensionId={dimensionId}
        />
        <DisplaySettingsModal
          dimensionId={dimensionId}
          showSettingsModal={showSettingsModal}
          setShowSettingsModal={setShowSettingsModal}
          radioValue={radioValue}
          setRadioValue={setRadioValue}
        />
      </div>
    </SiderLayout>
  )
}

const mapStateToProps = (state) => ({
  dimensionValues: configuration.selectors.getDimensionValues(state),
})

const mapDispatchToProps = {
  fetchDimensionValues: configuration.actions.fetchDimensionValues,
  fetchDimensionTemplateXLS: configuration.actions.fetchDimensionTemplateXLS,
  uploadDimensionValuesXLS: configuration.actions.uploadDimensionValuesXLS,
  deleteDimensionValuesSelections: configuration.actions.deleteDimensionValuesSelections,
  downloadDimensionValuesFile: configuration.actions.downloadDimensionValuesFile,
}

export default connect(mapStateToProps, mapDispatchToProps)(DimensionDetail)
