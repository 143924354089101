import React from 'react'
import { Space } from 'antd'
import { Button, Typography } from 'modules/core/components'
import { useTranslation } from 'react-i18next'
// import { ContainerSeeTutorial } from "./component";

const ContainerFooter = ({ setShowIitialSteps }) => {
  const { t } = useTranslation()
  return (
    <Space direction="vertical" className="initial-screen-info-container">
      {/* <ContainerSeeTutorial /> */}
      <Typography.Headline level={5}>{t('HOME_INITIAL_SCREEN_QUESTION')}</Typography.Headline>
      <Button.Primary onClick={() => setShowIitialSteps()} title="ACTION_BEGIN" />
    </Space>
  )
}

export default ContainerFooter
