import React from 'react'
import { Carousel } from 'antd'
import { CarrouselArrow } from 'modules/core/components'
import { CardKpi } from './components'

const ListKPIs = ({
  globalBudgetKPIS,
  setDataComments,
  dataComments,
  onRefetchComments,
  loadingComments,
  currencyData,
}) => {
  return (
    <Carousel
      className="global-kpi-carousel"
      slidesToShow={4}
      slidesToScroll={1}
      initialSlide={0}
      infinite={false}
      dots={false}
      arrows={true}
      nextArrow={<CarrouselArrow.Next />}
      prevArrow={<CarrouselArrow.Previus />}
      responsive={[
        {
          breakpoint: 1580,
          settings: { slidesToShow: 3, slidesToScroll: 1 },
        },
        {
          breakpoint: 1350,
          settings: { slidesToShow: 3, slidesToScroll: 1 },
        },
        {
          breakpoint: 1100,
          settings: { slidesToShow: 2, slidesToScroll: 1 },
        },
        {
          breakpoint: 850,
          settings: { slidesToShow: 1, slidesToScroll: 1 },
        },
      ]}
    >
      {globalBudgetKPIS.map((kpi, index) => (
        <CardKpi
          title={kpi.title}
          id={index}
          variation={kpi.variation}
          value={kpi.value}
          subKpis={kpi.children}
          setDataComments={setDataComments}
          dataComments={dataComments}
          onRefetchComments={onRefetchComments}
          loadingComments={loadingComments}
          currencyData={currencyData}
        />
      ))}
    </Carousel>
  )
}

export default ListKPIs
