import React from 'react'
import PropTypes from 'prop-types'

import './ButtonActionBar.scss'

/** Es un componente utilizado principalmente en los ABMs para alinear fila de botones. */
const ButtonActionBar = ({ children, position, className }) => {
  const classNameGroup = `${position} ${className}`
  return <div className={`bc-button-action-bar ${classNameGroup}`}>{children}</div>
}

const POSITION_OPTIONS = ['start', 'end', 'center', 'space-between']

ButtonActionBar.propTypes = {
  /** Es el nombre de clase para aplicarle estilos al interior del componente. */
  className: PropTypes.string,
  /** Indica la posicion de alineacion flex. */
  position: PropTypes.oneOf(POSITION_OPTIONS),
}
ButtonActionBar.defaultProps = {
  className: '',
  position: 'end',
}

export default ButtonActionBar
