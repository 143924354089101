import adjustments from './adjustments'
import configuration from './configuration'
import control from './control'
import dashboard from './dashboard'
import distributions from './distributions'
import forecast from './forecast'
import general from './general'
import login from './login'
import periods from './periods'
import planning from './planning'
import report from './report'
import home from './home'
import society from './society'
import integrations from './integrations'
import chatbot from './chatbot'
import question from './question'
import SecurityPolicy from './SecurityPolicy'

const index = {
  ...adjustments,
  ...configuration,
  ...dashboard,
  ...general,
  ...login,
  ...periods,
  ...planning,
  ...report,
  ...control,
  ...distributions,
  ...forecast,
  ...home,
  ...society,
  ...integrations,
  ...chatbot,
  ...question,
  ...SecurityPolicy,
}

export default index
