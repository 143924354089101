import { Card, List } from 'antd'
import { CardBody } from './components'

import './CurrencyCardList.scss'

const CurrencyCardsList = ({ dataSource, userCanActivate, onRefetch }) => {
  return (
    <List
      itemLayout="horizontal"
      size="small"
      dataSource={dataSource}
      renderItem={(item) => (
        <List.Item>
          <Card className="currency-card" bodyStyle={{ padding: 10 }}>
            <CardBody item={item} userCanActivate={userCanActivate} onRefetch={onRefetch} />
          </Card>
        </List.Item>
      )}
    />
  )
}

export default CurrencyCardsList
