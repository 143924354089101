import { Button, Space } from 'antd'
import googleIcon from 'assets/images/login/google-2.png'
import microsoftIcon from 'assets/images/login/microsoft.png'
import login from 'modules/login'
import React from 'react'
import { connect } from 'react-redux'
import { onRedirect } from './utils'

const LoginOtherContainer = ({ googleRedirect, azureRedirect, whoAmI }) => {
  const containerButtonStyle = {
    display: 'flex',
    justifyContent: 'center',
  }

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <div style={containerButtonStyle}>
        <Button
          type="default"
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: 250,
            height: 40,
            marginBottom: 10,
            borderRadius: 6,
            backgroundColor: '#ffffff',
            border: '1px solid #d9d9d9',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
            fontSize: 15,
            fontWeight: 500,
          }}
          onClick={() => onRedirect(googleRedirect, whoAmI)}
        >
          <img
            src={googleIcon}
            alt="Google"
            style={{
              width: 20,
              height: 20,
              objectFit: 'contain',
              marginRight: 8,
            }}
          />
          Sign in with Google
        </Button>
      </div>
      <div style={containerButtonStyle}>
        <Button
          type="default"
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: 250,
            height: 40,
            borderRadius: 6,
            backgroundColor: '#ffffff',
            border: '1px solid #d9d9d9',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
            fontSize: 15,
            fontWeight: 500,
          }}
          onClick={() => onRedirect(azureRedirect, whoAmI)}
        >
          <img
            src={microsoftIcon}
            alt="Microsoft"
            style={{
              width: 20,
              height: 20,
              objectFit: 'contain',
              marginRight: 8,
            }}
          />
          Sign in with Microsoft
        </Button>
      </div>
    </Space>
  )
}

const mapDispatchToProps = {
  googleRedirect: login.actions.googleRedirect,
  azureRedirect: login.actions.azureRedirect,
  whoAmI: login.actions.whoAmI,
}

export default connect(null, mapDispatchToProps)(LoginOtherContainer)
