import { Select } from 'antd'
import { generateSelectOptions } from 'modules/core/utils'

const SelectGlobalProjectionLine = ({ reportLineSelected, setReportLineSelected }) => {
  return (
    <Select
      bordered={false}
      style={{ width: 150 }}
      showSearch
      optionFilterProp="children"
      filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      defaultValue={reportLineSelected}
      onChange={(id) => setReportLineSelected(id)}
      options={generateSelectOptions({
        options: [],
      })}
    />
  )
}

export default SelectGlobalProjectionLine
