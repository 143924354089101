import React from 'react'
import { KpiCarrousel, LoadingKpiCard } from './components'

import './ReportKPICarousel.scss'

const ReportKPICarousel = ({
  dataKPI = [],
  isLoading = false,
  setDataComments,
  dataComments,
  onRefetchComments,
  loadingComments,
  commentKey,
  currencyData,
}) => {
  if (isLoading) {
    return <LoadingKpiCard />
  }

  if (!isLoading) {
    return (
      <KpiCarrousel
        dataKPI={dataKPI}
        setDataComments={setDataComments}
        dataComments={dataComments}
        onRefetchComments={onRefetchComments}
        loadingComments={loadingComments}
        commentKey={commentKey}
        currencyData={currencyData}
      />
    )
  }
}

export default ReportKPICarousel
