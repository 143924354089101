import { Collapse, Space } from 'antd'
import { HOME } from 'modules/core/constants'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { ContactLink, ContactMail, ContactName, ContactNumber } from './components'

const { Panel } = Collapse

const ListCollapseContacts = () => {
  const { t } = useTranslation()
  return (
    <Collapse accordion={true} defaultActiveKey={[1]}>
      {HOME.LIST_DATA_DASHBOARD.CONTACTS.map((contacto) => (
        <Panel header={t(contacto.title)} key={contacto.id}>
          {contacto.description.map((des) =>
            !des.link ? (
              <div key={des.id}>
                <Space direction="vertical" key={des.id}>
                  <ContactName name={des.name} />
                  <ContactMail mail={des.mail} />
                  <ContactNumber numero={des.numero} linkWhatsapp={des.linkWhatsapp} />
                </Space>
              </div>
            ) : (
              <div key={des.id}>
                <ContactLink id={des.id} link={des.link} />
              </div>
            ),
          )}
        </Panel>
      ))}
    </Collapse>
  )
}

export default ListCollapseContacts
