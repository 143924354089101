import React from 'react'
import { Input, Space } from 'antd'
import { ButtonActionBar, CreateReportModal, DropdownMenu } from 'modules/core/components'
import { ROLES } from 'modules/core/constants'
import { useTranslation } from 'react-i18next'
import { SolutionOutlined } from '@ant-design/icons'

const { Search } = Input
const { SETTINGS__REPORTS__REPORTSPNL__ABM } = ROLES

const UserActions = ({ onRefetch, setSearchValue, onClickSpreadsheets, onClickActivity }) => {
  const { t } = useTranslation()
  return (
    <ButtonActionBar>
      <Space size="middle">
        <Search
          className="configuration-reports-list-search"
          placeholder={t('ACTION_SEARCH')}
          allowClear={true}
          onSearch={(search) => setSearchValue(search)}
        />
        <CreateReportModal
          rolesAvailables={[SETTINGS__REPORTS__REPORTSPNL__ABM]}
          onFetchReportList={() => onRefetch()}
          onClickSpreadsheets={onClickSpreadsheets}
        />
        <DropdownMenu
          title={t('ACTION_MORE')}
          menu={[
            {
              title: t('ACTION_ACTIVITIES'),
              icon: <SolutionOutlined />,
              onClick: onClickActivity,
            },
          ]}
        />
      </Space>
    </ButtonActionBar>
  )
}

export default UserActions
