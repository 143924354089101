const adjustments = {
  ADJUSTMENT_MANAGMENT_LABEL: 'Management settings',
  ADJUSTMENT_EMPTY_STATE_ALERT_TITLE:
    'We still do not have all the data we need so that you can create your management settings',
  ADJUSTMENT_EMPTY_STATE_ALERT_TEXT:
    'In order for you to create adjustments we must have real data published. Once you do, you can create all the settings that you consider necessary.',
  ADJUSTMENT_EMPTY_STATE_TITLE: 'Create your first adjustment!',
  ADJUSTMENT_EMPTY_STATE_TEXT:
    'Make entries that allow you to adjust your data and correctly allocate all your sales and expenses',
  ADJUSTMENT_CREATE_ACTION: 'Create adjustment',
  ADJUSTMENT_DATE_FIELD: 'Adjusted year / month',
  ADJUSTMENT_TYPE_FIELD: 'Fit type',
  ADJUSTMENT_CREATION_TYPE: 'Type of creation',
  ADJUSTMENT_CREATE_FORM_TITLE: 'Setting creation',
  ADJUSTMENT_TYPE_EXTENSION: 'Extension',
  ADJUSTMENT_TYPE_RECLASSIFICATION: 'Reclassification',
  ADJUSTMENT_TYPE_REDUCTION: 'Reduction',
  ADJUSTMENT_ADD_MOVEMENT_ACTION: 'Add movement',
  ADJUSTMENT_CREATE_CONFIRM_TITLE: 'Setting creation confirmation',
  ADJUSTMENT_CREATE_CONFIRM_DESCRIPTION:
    'You are about to create an {{type}} in {{añoMes}}. Remember that once you do it, it will be registered, you can cancel it but not delete it.',
  ADJUSTMENT_CREATE_SUCCESS_FEEDBACK_TITLE: 'Registered setting',
  ADJUSTMENT_SUCCESS_FEEDBACK_DESCRIPTION:
    'You can already see the impact of it in actuals or analysis.',
  ADJUSTMENT_DETAIL_TITLE: 'Adjustment detail',
  ADJUSTMENT_DOWNLOAD_MOVEMENT_ACTION_TITLE: 'Download movements',

  ADJUSTMENT_EMPTY_STATE_APPROVE_DATA_TOOLTIP: 'No actual data published',

  ADJUSTMENT_VIEW_ACTION_TITLE: 'See setting',
  ADJUSTMENT_CREATE_FAIL_FEEDBACK_DESCRIPTION: 'The setting could not be created',
  ADJUSTMENT_LOAD_MOVEMENT_ACTION_TITLE: 'Load movements',
  ADJUSTMENT_DELETE_MOVEMENT_ACTION_TITLE: 'Delete motion',
  ADJUSTMENT_MOVEMENT_SUCCESS_FEEDBACK_TITLE: 'Movements loaded successfully',
  ADJUSTMENT_MOVEMENT_SUCCESS_FEEDBACK_DESCRIPTION: 'You can already work with them',

  ADJUSTMENT_DATA_TABLE_FILE_NAME: 'Plika - Management Settings - {{periodName}} (listing)',
  ADJUSTMENT_LIST_DEFAULT_FILE_NAME: 'Plika - Management Settings (listing)',
  ADJUSTMENT_DEFAULT_FILE_NAME: 'Plika - Management Settings ',
  ADJUSTMENT_DEFAULT_TEMPLATE_NAME: 'Plika template - management settings ({date}})',
  ADJUSTMENT_MOVEMENT_FILE_NAME: 'Plika Template- Management settings Movements.xlsx',

  ADJUSTEMNT_TYPE_DISTRIBUTION_LABEL_ORIGIN: 'Distributed base',
  ADJUSTEMNT_TYPE_DISTRIBUTION_LABEL_DESTINATION: 'Base to be distributed',
  ADJUSTEMNT_UPLOAD_DATA_INFORMATIVE_ALERT_DESCRIPTION:
    'In Plika we consider distributions as a type of adjustment, but as the file format and the data to be entered, we need to differentiate them. Therefore, choose an option to obtain the correct template for each type.',
  ADJUSTEMNT_DOWNLOAD_TEMPLATE_INFORMATIVE_TOOLTIP_TITLE:
    'You must choose a type of budget to download',
}

export default adjustments
