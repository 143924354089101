import React from 'react'
import { Skeleton, Card, Row, Col, Divider } from 'antd'

const DashboardSkeleton = () => (
  <div style={{ padding: '24px' }}>
    {/* Tarjetas de resumen */}
    <Row gutter={16}>
      {[1, 2, 3, 4].map((item) => (
        <Col span={6} key={item}>
          <Card>
            <Skeleton active />
          </Card>
        </Col>
      ))}
    </Row>

    <Divider />

    {/* Gráficos */}
    <Row gutter={16}>
      <Col span={12}>
        <Card style={{ height: window.innerHeight - 350 }}>
          <Skeleton active />
        </Card>
      </Col>
      <Col span={12}>
        <Card style={{ height: window.innerHeight - 350 }}>
          <Skeleton active />
        </Card>
      </Col>
    </Row>
  </div>
)

export default DashboardSkeleton
