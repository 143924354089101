import { saveAs } from 'file-saver'
import * as types from './constants'

export const createSociety =
  (data = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.CREATE_SOCIETY,
      payload: {
        request: {
          url: '/societies/society/create_society/',
          method: 'POST',
          data,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }

    return response
  }

export const fetchSocietiesList =
  (params = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.FETCH_SOCIETIES_LIST,
      payload: {
        request: {
          url: '/societies/all-societies/',
          method: 'GET',
          params,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }

    return response
  }

export const fetchSocietiesDetail =
  (id, params = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.FETCH_SOCIETIES_DETAIL,
      payload: {
        request: {
          url: `/societies/get-society/${id}/`,
          method: 'GET',
          params,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }

    return response
  }

export const editSociety =
  (id, data = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.EDIT_SOCIETY,
      payload: {
        request: {
          url: `/societies/update-society/${id}/`,
          method: 'PUT',
          data,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const deleteSociety = (id) => async (dispatch) => {
  const response = await dispatch({
    type: types.DELETE_SOCIETY,
    payload: {
      request: {
        url: `/societies/delete-society/${id}/`,
        method: 'DELETE',
      },
    },
  })
  if (response.error) {
    return Promise.reject(response.error.response.data)
  }
  return response
}

export const downloadASocietiesXLS =
  (filename, params = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.DOWNLOAD_SOCIETIES_XLS,
      payload: {
        request: {
          url: `/societies/society/download_tenants/`,
          method: 'GET',
          responseType: 'blob',
          params,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }

    saveAs(response.payload.data, `${filename}.xlsx`)
    return Promise.resolve()
  }

export const ActivateQuicksight =
  (data = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.ACTIVATE_QUICKSIGHT,
      payload: {
        request: {
          url: `/integrations/quicksight-user/`,
          method: 'POST',
          data,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const setUserUploadS3 = (data) => async (dispatch) => {
  const response = await dispatch({
    type: types.SET_USER_UPLOAD_S3,
    payload: {
      request: {
        url: `/auth/activate_user_link/`,
        method: 'PATCH',
        data,
      },
    },
  })
  if (response.error) {
    return Promise.reject(response.error)
  }
  return response
}

export const ActivateML =
  (data = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.ACTIVATE_ML,
      payload: {
        request: {
          url: `/societies/activate_desactivate_machine_learning/`,
          method: 'POST',
          data,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const fetchFunctionsButtonsList =
  (params = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.FETCH_FUNCTIONS_BUTTONS_LIST,
      payload: {
        request: {
          url: '/core/generic/get_functions',
          method: 'GET',
          params,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }

    return response
  }

export const FunctionsButtonsCreate =
  (data = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.FETCH_FUNCTIONS_BUTTONS_CREATE,
      payload: {
        request: {
          url: `/core/generic/save_function/`,
          method: 'POST',
          data,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const fetchFunctionsButtonsTenant =
  (params = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.FETCH_FUNCTIONS_BUTTONS_TENANT_LIST,
      payload: {
        request: {
          url: '/core/generic/get_buttons_generics/',
          method: 'GET',
          params,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }

    return response
  }

export const FunctionsButtonsExecute =
  (url, formFile = [], formData = {}, params = {}, responseType = null) =>
  async (dispatch) => {
    const dataSet = new FormData()
    formFile.forEach((file) => {
      dataSet.append('file', file)
    })

    Object.entries(formData).forEach(function ([key, value]) {
      if (key !== 'file_upload') {
        dataSet.append(key, value)
      }
    })

    const response = await dispatch({
      type: types.FETCH_FUNCTIONS_BUTTONS_EXECUTE,
      payload: {
        request: {
          url: url,
          method: 'POST',
          data: dataSet,
          params,
          ...(responseType !== null ? { responseType: responseType } : {}),
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }

    if (responseType !== null && responseType === 'blob') {
      saveAs(response.payload.data)
      return Promise.resolve()
    } else {
      return response
    }
  }

export const FormatNumberCreate =
  (id, data = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.CREATE_FORMAT,
      payload: {
        request: {
          url: `/societies/format/${id}/update_or_create/`,
          method: 'POST',
          data,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const fetchButtonsStandar =
  (params = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.FETCH_BUTTONS_STANDAR,
      payload: {
        request: {
          url: '/core/generic/get_modules_submodules/',
          method: 'GET',
          params,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }

    return response
  }

export const fetchSocietiesDimensions =
  (params = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.FETCH_FUNCTIONS_BUTTONS_DIMENSION_LIST,
      payload: {
        request: {
          url: '/core/generic/get_dimensions/',
          method: 'GET',
          params,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }

    return response
  }

export const editButtonsStandar =
  (data = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.EDIT_BUTTONS_STANDAR,
      payload: {
        request: {
          url: `/core/generic/update_data/`,
          method: 'PUT',
          data,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const deleteButtonStandar =
  (params = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.DELETE_BUTTON_STANDAR,
      payload: {
        request: {
          url: `/core/generic/delete_function/`,
          method: 'DELETE',
          params,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error.response.data)
    }
    return response
  }
