// Función para obtener el nombre de clase dinámica y establecer el color
export const getRowClassName = (record) => {
  const dynamicClassName = `row-${record.id}`

  // Definir una variable para almacenar el color
  let color = record.color

  // Validar si el registro es de tipo "totalizer" y el color es null
  if (record.type === 'totalizer' && color === null) {
    // Establecer el color como rojo
    color = 'var(--neutral-5)'
  }
  // Validar si el registro es de tipo "ratio"
  const textColor = record.type === 'ratio' ? 'var(--ratio-violet)' : null

  // Crear una clase dinámica con el color
  const style = document.createElement('style')
  style.innerHTML = `
        .${dynamicClassName} td {
            background-color: ${color} !important;
            color: ${textColor};
        }
    `
  document.head.appendChild(style)

  return dynamicClassName
}
