import React, { useState } from 'react'
import { Modal, Form } from 'antd'
import { useTranslation } from 'react-i18next'
import { Button, ButtonActionBar } from 'modules/core/components'
import { ContainerRule, FormRuleName } from './components'
import { isDisabled, isOtherDestination, onRuleCreate, validateNameDescription } from './utils'
import { connect } from 'react-redux'
import { PROJECTION_ELEMENTS } from 'modules/core/constants'
import configuration from 'modules/configuration'
import _ from 'lodash'

import './CreateRuleModal.scss'

const CreateRuleModal = ({
  visible,
  onCancel,
  dimensionsSelection,
  dataFormula,
  createRule,
  setClearData,
  onRefetch,
  variablesList,
  loading,
}) => {
  const [isNext, setIsNext] = useState(false)
  const [isReplaceValue, setIsReplaceValue] = useState(false)
  const [valuesFormRuleName, setValuesFormRuleName] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [destinationOption, setDestinationOption] = useState(
    PROJECTION_ELEMENTS.RULE.KEYS.TAKE_ORIGIN_KEY,
  )
  const [form] = Form.useForm()
  const { t } = useTranslation()

  const onClose = () => {
    form.resetFields()
    setIsReplaceValue(false)
    setIsNext(false)
    setIsLoading(false)
    setClearData()
    setDestinationOption(PROJECTION_ELEMENTS.RULE.KEYS.TAKE_ORIGIN_KEY)
    onCancel()
  }

  const onCreate = () => {
    const { formula, formula_type, variablesIds } = dataFormula

    let auxFormula = ''

    _.keys(variablesIds).forEach((v) => {
      //revisar si funciona bien
      const variableName = variablesList?.values.find((el) => el.id === variablesIds[v]).name
      auxFormula = formula.replace(v, variableName)
    })

    const data = {
      status: 'active',
      name: valuesFormRuleName.name,
      type_value: valuesFormRuleName.type_value,
      replace_values: isReplaceValue,
      formula: `amount*${auxFormula}`,
      formula_type,
      ...(valuesFormRuleName.description && {
        description: valuesFormRuleName.description,
      }),
      variable_ids: _.values(dataFormula.variablesIds),
      origin_dimensions: dimensionsSelection.origin_dimensions[0],
      target_dimensions: isOtherDestination(destinationOption)
        ? dimensionsSelection.target_dimensions[0]
        : {},
    }

    return createRule(data)
  }

  const validatorValue = /^[a-zA-ZáéíóúÁÉÍÓÚÑñ0-9\s]+$/

  return (
    <Modal
      bodyStyle={{ overflowY: 'auto', maxHeight: 400 }}
      width={600}
      centered
      forceRender
      onCancel={onClose}
      destroyOnClose={true}
      visible={visible}
      title={t('CONFIG_FORMULAS_CREATE_ACTION')}
      footer={
        <ButtonActionBar>
          <Button.Default title={t('ACTION_CANCEL')} onClick={onClose} />
          {!isNext && (
            <Button.Primary
              title={t('ACTION_NEXT')}
              tooltipTitle={
                !validatorValue.test(form.getFieldsValue().name)
                  ? t('CONFIG_RULE_NAME_WITHOUT_NUMBERS_INFORMATIVE_TOOLTIP')
                  : ''
              }
              disabled={!validatorValue.test(form.getFieldsValue().name)}
              onClick={() =>
                validateNameDescription({
                  form,
                  setValuesFormRuleName,
                  setIsNext,
                })
              }
            />
          )}
          {isNext && (
            <Button.Primary
              title={t('ACTION_FINISH')}
              onClick={() =>
                onRuleCreate({
                  setIsLoading,
                  createRule: () => onCreate(),
                  onRefetch,
                  onClose,
                })
              }
              disabled={isDisabled({
                dimensionsSelection,
                dataFormula,
                destinationOption,
              })}
              loading={isLoading}
            />
          )}
        </ButtonActionBar>
      }
    >
      {!isNext && <FormRuleName form={form} setIsReplaceValue={setIsReplaceValue} />}
      {isNext && (
        <ContainerRule
          visible={visible}
          loading={loading}
          dataDestinationOption={{ setDestinationOption, destinationOption }}
        />
      )}
    </Modal>
  )
}

const mapStateToProps = (state) => ({
  dimensionsSelection: configuration.selectors.getDimensionsSelection(state),
  dataFormula: configuration.selectors.getDataFormula(state),
})

const mapDispatchToProps = {
  createRule: configuration.actions.createRule,
  setClearData: configuration.actions.setClearData,
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateRuleModal)
