import { Space } from 'antd'
import { RangePicker, Typography } from 'modules/core/components'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import _ from 'lodash'

const RangeMonthsFilters = ({ dataPeriod, setSelectedDates, selectedDates }) => {
  const { t } = useTranslation()
  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Typography.Body level={2}>
        {t('PLANNING_CREATE_NEW_BUDGET_BASE_DATES_FORM_LABEL')}
      </Typography.Body>
      <RangePicker
        onSetRange={setSelectedDates}
        periodDate={{
          startDate: dataPeriod?.start_date,
          endDate: dataPeriod?.end_date,
        }}
        defaultPickerValue={
          !_.isEmpty(dataPeriod)
            ? [moment(dataPeriod?.start_date), moment(dataPeriod?.end_date)]
            : null
        }
        defaultValue={
          !_.isEmpty(selectedDates) && [
            moment(selectedDates['start-date'], 'YYYY-MM-01'),
            moment(selectedDates['end-date'], 'YYYY-MM-01'),
          ]
        }
      />
    </Space>
  )
}

export default RangeMonthsFilters
