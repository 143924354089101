import { Tag } from 'antd'
import { Button, ButtonActionBar } from 'modules/core/components'
import { LeftCircleOutlined } from '@ant-design/icons'
import { useHistory, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const UserActions = ({ reversion }) => {
  let { periodId } = useParams()
  let history = useHistory()
  const { t } = useTranslation()

  return (
    <ButtonActionBar position="space-between">
      <Button.LinkWhitLeftIcon
        title="ACTION_RETURN"
        style={{ paddingLeft: 0 }}
        onClick={() => history.push(`/seguimiento/${periodId}?tab=ajustes`)}
        icon={<LeftCircleOutlined />}
      />
      {reversion && <Tag color="red">{t('STATE_TAG_CANCELED')}</Tag>}
    </ButtonActionBar>
  )
}
export default UserActions
