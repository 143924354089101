import * as types from './constants'

export const uploadServices3 =
  (data = {}, header) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.UPLOAD_SERVICE_S3,
      payload: {
        request: {
          url: '/upload_service/generate_presigned_url/',
          method: 'POST',
          header,
          data,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }

    return response
  }

export const uploadUrlAws = (presigned_url, file) => async (dispatch) => {
  const url = presigned_url.payload.data.url
  const uploadDetails = presigned_url.payload.data.fields
  const formData = new FormData()
  for (const key in uploadDetails) {
    formData.append(key, uploadDetails[key])
  }
  // Agrega el tipo de contenido del archivo al formulario
  formData.append('Content-Type', file.type)

  // Añade el archivo al formulario
  formData.append('file', file.originFileObj)

  // Envía la solicitud sin incluir un token de autenticación
  const response = await fetch(url, {
    method: 'POST',
    body: formData,
  })

  if (response.error) {
    return Promise.reject(response.error)
  }

  return response
}

export const fetchSocietiesList = () => async (dispatch) => {
  const response = await dispatch({
    type: types.FETCH_FOLDER_LIST,
    payload: {
      request: {
        url: '/upload_service/get_objects_s3/',
        method: 'GET',
      },
    },
  })
  if (response.error) {
    return Promise.reject(response.error)
  }

  return response
}
