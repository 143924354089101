import { SettingOutlined } from '@ant-design/icons'
import { Col, Row, Skeleton, Space, Table, Tabs } from 'antd'
import { Typography } from 'modules/core/components'
import React from 'react'

const ConfigSkeleton = () => {
  // Define las columnas de la tabla como en la imagen
  const columns = [
    { title: 'Nombre', dataIndex: 'nombre', key: 'nombre' },
    { title: 'Fecha de Inicio', dataIndex: 'fechaInicio', key: 'fechaInicio' },
    { title: 'Fecha de Finalización', dataIndex: 'fechaFin', key: 'fechaFin' },
    { title: 'Acciones', dataIndex: 'acciones', key: 'acciones' },
  ]

  // Datos vacíos simulando la estructura del esqueleto
  const data = Array.from({ length: 4 }).map((_, index) => ({
    key: index,
    nombre: <Skeleton.Input style={{ width: 100 }} active={true} size="small" />,
    fechaInicio: <Skeleton.Input style={{ width: 80 }} active={true} size="small" />,
    fechaFin: <Skeleton.Input style={{ width: 80 }} active={true} size="small" />,
    acciones: (
      <>
        <Skeleton.Button active={true} size="small" style={{ marginRight: 8 }} />
        <Skeleton.Button active={true} size="small" />
      </>
    ),
  }))

  return (
    <Row gutter={[24, 24]} style={{ padding: 40 }}>
      <Col span={24}>
        <Space direction="horizontal">
          <Typography.Icon type="primary" icon={SettingOutlined} />
          <Typography.Headline type="primary" level={4}>
            Configuración
          </Typography.Headline>
        </Space>
      </Col>
      <Col span={24}>
        <Typography.Subtitle level={1}>
          Aquí podrás gestionar los datos que emplearás en Plika, visualizando, creando, editando o
          eliminando los mismos.
        </Typography.Subtitle>
      </Col>

      <Col span={24}>
        <div style={{ display: 'flex', gap: '16px', marginBottom: '16px' }}>
          <Skeleton.Button style={{ width: 100 }} active={true} size="default" />
          <Skeleton.Button style={{ width: 150 }} active={true} size="default" />
          <Skeleton.Button style={{ width: 130 }} active={true} size="default" />
          <Skeleton.Button style={{ width: 100 }} active={true} size="default" />
          <Skeleton.Button style={{ width: 120 }} active={true} size="default" />
        </div>
      </Col>

      <Col span={24}>
        {/* Skeleton para el mensaje de información */}
        <div
          style={{
            backgroundColor: '#e6f7ff',
            padding: '8px 16px',
            borderRadius: '4px',
            marginBottom: '16px',
          }}
        >
          <Skeleton.Input style={{ width: '80%' }} active={true} size="small" />
        </div>

        {/* Skeleton para el botón de "Crear nuevo" */}
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '16px' }}>
          <Skeleton.Button style={{ width: 120 }} active={true} />
        </div>
      </Col>

      <Col span={24}>
        <Table columns={columns} dataSource={data} pagination={false} bordered />
      </Col>

      <Col span={24}>
        <div style={{ height: window.innerHeight - 350 }}></div>
      </Col>
    </Row>
  )
}

export default ConfigSkeleton
