import { Col, Row } from 'antd'
import moment from 'moment'
import { ButtonsFooter } from './components'
import InputMonth from 'modules/core/components/InputMonth/InputMonth'
import _ from 'lodash'

const CollapseBody = ({
  values,
  originValues,
  disableInput,
  onChangeInput,
  onChangePopConfirm,
  onCancelPopConfirm,
  visiblePopconfirm,
  disableCnacel,
  onClickCancel,
  onClickSave,
  loading,
  disableSave,
}) => {
  const orderedKeys = Object.keys(values).sort((a, b) =>
    moment(a, 'MMM-YYYY').diff(moment(b, 'MMM-YYYY')),
  )

  return (
    <Row gutter={[4, 4]}>
      {_.map(orderedKeys, (month) => (
        <Col span={4} key={month}>
          <InputMonth
            month={month}
            key={month}
            value={values[month]}
            disabled={disableInput}
            onChange={(val) => onChangeInput(val, month)}
          />
        </Col>
      ))}
      {JSON.stringify(values) !== JSON.stringify(originValues) && (
        <Col span={24}>
          <ButtonsFooter
            onChangePopConfirm={onChangePopConfirm}
            onCancelPopConfirm={onCancelPopConfirm}
            visiblePopconfirm={visiblePopconfirm}
            disableCnacel={disableCnacel}
            onClickCancel={onClickCancel}
            onClickSave={onClickSave}
            loading={loading}
            disableSave={disableSave}
          />
        </Col>
      )}
    </Row>
  )
}

export default CollapseBody
