import { ROLES } from 'modules/core/constants'
import store from 'store'

// NOTE: Dado un array de KEYS (puede ser total:'MODULE/SUB1/OP1' o parcial: 'MODULE/SUB1'), retorna un array con las operaciones disponibles
// OUTPUT: ['MODULE'] >> ['MODULE/SUB1/OP1', 'MODULE/SUB1/OP2', 'MODULE/SUB2/OP1']
export const getOperationsByKeys = (
  keys = [],
  allOperationsKeys = ROLES.MODULES_OPERATIONS_LIST,
) => {
  const filterArrayKeys = keys.map((key) => allOperationsKeys.filter((el) => el.includes(key)))
  const filterKeys = [...new Set(filterArrayKeys.flat())]
  return filterKeys
}

// NOTE: Retorna todas las KEYS que refieren a los modulos contenidos en Periods
export const getPeriodOperationsKeys = () => {
  const { PLANNING, CONTROL, FORECAST, ANALYSIS } = ROLES
  return getOperationsByKeys([PLANNING, CONTROL, FORECAST, ANALYSIS])
}

// NOTE: No usar hasta que este todo lo de los roles implementado.
// NOTE: Valida si el usuario tiene alguno de los roles llegado por parametro, allowedOperations puede ser un array o directamente un unico elemento.
export const isUserAllowed = (allowedOperations = []) => {
  const userOperations = store.getState().login.loggedUser.operations
  return [...userOperations].some((el) => allowedOperations.indexOf(el) >= 0)
}

// NOTE: Ex-isUserAllowed, se reemplazara cuando este roles implementado.
export const isGroupAllowed = (userGroupsIds = [], allowedGroupsNames = []) => {
  const GROUPS = {
    Administrador: 1,
    Visualizador: 2,
    Analista: 3,
    Responsable: 4,
  }

  const allowedGroupsId = allowedGroupsNames.map((groupName) => GROUPS[groupName])
  return [...userGroupsIds].some((el) => allowedGroupsId.indexOf(el) >= 0)
}
