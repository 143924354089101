import { INTEGRATIONS } from 'modules/core/constants'
import Immutable from 'seamless-immutable'
import * as types from './constants'
import _ from 'lodash'

const initialDataSteps = [
  {
    key: 'load',
    title: 'HOME_INITIAL_STEPS_LOAD_FILE',
    // description: 'HOME_INITIAL_STEPS_LOAD_FILE_DESCRIPTION'
  },
  {
    key: 'selected',
    title: 'HOME_INITIAL_STEPS_SELECT_DATA',
    // description: 'HOME_INITIAL_STEPS_SELECT_DATA_DESCRIPTION',
  },
]

const initialState = Immutable({
  dataFiles: {
    code_batch: '',
    fact_table: [],
    dimensions_table: [],
  },
  dataSelection: {
    code_batch: '',
    fact_table: [],
    dimensions_table: [],
  },
  dataSteps: initialDataSteps,
  filesData: {
    fileList: [],
    typeFile: '',
  },
  headersList: [],
  dataConnectors: INTEGRATIONS.LIST,
  statusConnection: [],
  budgetTypeCards: [],
  reportCards: [],
  dataProjectionChart: [],
  dataBudgetTypeChart: [],
  dataDimensionChart: [],
  dashboardQuicksight: {},
})

const renderSelections = ({ auxData, infoSelections, data }) => {
  //doc: si hay diferencia me fijo que no sea con el column en null.
  //si es null pongo lo que viene desde el payload
  //si hay diferencia y no tiene columns en null, seteo el selections con lo que tenia el estado de dataSelection
  if (auxData.length > 0) {
    if (infoSelections.length > 0 && infoSelections.some((data) => !data.columns)) {
      return data
    } else {
      return infoSelections
    }
  } else {
    return data
  }
}

const setDefaultFields = (id, name_file, type_upload, concept = null) => {
  return {
    id,
    type_upload,
    concept,
    name_file,
    selections: [{ id: 0, columns: null, type: null, dimension: null, date_format: null }],
  }
}

/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default (state = initialState, action) => {
  switch (action.type) {
    case types.UPLOAD_INITIAL_FILE_SUCCESS:
      const fact_table = action.payload.data.files_name.filter(
        (file) => file.type_file === 'TRANSACTION',
      )
      return state.merge({
        dataFiles: {
          code_batch: action.payload.data.code_batch,
          fact_table: [...state.dataFiles.fact_table, ...fact_table],
          formatDateList: action.payload.data.format_date_available || [],
        },
        dataSelection: {
          code_batch: action.payload.data.code_batch,
          fact_table: fact_table.map((file) =>
            setDefaultFields(file.id, file.name_file, file.type_upload),
          ),
        },
        dataConnectors: state.dataConnectors.map((connector) => {
          if (connector.id === INTEGRATIONS.KEYS.FILE_KEY) {
            return {
              ...connector,
              is_connect: true,
            }
          }
          return connector
        }),
      })

    case types.CONNECT_ERP_SUCCESS:
      const prevStateFactTable = state.dataFiles.fact_table.asMutable()
      const prevStateDatatSelection = state.dataSelection.fact_table.asMutable()
      const auxId =
        prevStateFactTable.length === 0
          ? 1
          : prevStateFactTable[prevStateFactTable.length - 1].id + 1

      prevStateFactTable.push({ ...action.payload.data.connectors, id: auxId })
      prevStateDatatSelection.push(
        setDefaultFields(
          auxId,
          action.payload.data.connectors.name_file,
          action.payload.data.connectors.type_upload,
          1,
        ),
      )

      return state.merge({
        dataFiles: {
          ...state.dataFiles,
          fact_table: prevStateFactTable,
        },
        dataSelection: {
          ...state.dataSelection,
          fact_table: prevStateDatatSelection,
        },
        dataConnectors: state.dataConnectors.map((connector) => {
          if (action.payload.data.connectors.name_file.toLowerCase() === connector.id) {
            return {
              ...connector,
              is_connect: true,
            }
          }
          return connector
        }),
      })

    case types.SET_DATA_CONNECTORS:
      const { id, ...restProps } = action.payload
      return state.merge({
        dataConnectors: state.dataConnectors.map((connector) => {
          if (id === connector.id) {
            return {
              ...connector,
              ...restProps,
            }
          }
          return connector
        }),
      })

    case types.DELETE_FILE:
      const auxDataFiles = state.dataFiles.fact_table.asMutable()
      const auxDataSelections = state.dataSelection.fact_table.asMutable()
      auxDataFiles.splice(action.payload, 1)
      auxDataSelections.splice(action.payload, 1)
      return state.merge({
        dataFiles: {
          ...state.dataFiles,
          fact_table: auxDataFiles,
        },
        dataSelection: {
          ...state.dataSelection,
          fact_table: auxDataSelections,
        },
      })

    case types.SET_FILE_DATA:
      return state.merge({
        filesData: {
          fileList: action.payload.fileList,
          typeFile: action.payload.typeFile,
        },
        dataSteps: initialDataSteps,
      })

    case types.SET_CLEAN_DATA:
      const dataClean = {
        load: {
          filesData: {
            fileList: [],
            typeFile: '',
          },
          dataConnectors: INTEGRATIONS.LIST,
        },
        selected: {
          dataSelection: {
            ...state.dataSelection,
            fact_table: state.dataSelection.fact_table.map((data) => {
              return {
                id: data.id,
                concept: null,
                selections: [
                  { id: 0, columns: null, type: null, dimension: null, date_format: null },
                ],
              }
            }),
          },
        },
      }

      return state.merge({
        ...dataClean[action.payload],
      })

    case types.SET_DATA_SELECT:
      return state.merge({
        dataSelection: {
          ...state.dataSelection,
          fact_table: state.dataSelection.fact_table.map((info) => {
            if (info.id === action.payload.dataFactId) {
              return {
                ...info,
                ...(action.payload.dataFactType && {
                  [action.payload.dataFactType]: action.payload.value,
                }),
                selections: info.selections.map((infoSel) => {
                  if (infoSel.id === action.payload.dataSelId) {
                    return {
                      ...infoSel,
                      ...action.payload.others,
                      [action.payload.selectionType]: action.payload.value,
                    }
                  }
                  return infoSel
                }),
              }
            }
            return info
          }),
        },
      })

    case types.SET_SELECTION:
      return state.merge({
        dataSelection: {
          ...state.dataSelection,
          fact_table: state.dataSelection.fact_table.map((info) => {
            if (info.id === action.payload.id) {
              //doc: busco si hay diferencia entre la informacion que ya tenia el estado de dataSelection en selections en base a las columns (columnas del archivo transaccional)
              const auxData = _.differenceBy(action.payload.data, info.selections, 'columns')
              return {
                ...info,
                selections: renderSelections({
                  auxData,
                  infoSelections: info.selections,
                  data: action.payload.data,
                }),
              }
            }
            return info
          }),
        },
      })

    case types.DELETE_SELECTION:
      return state.merge({
        dataSelection: {
          ...state.dataSelection,
          fact_table: state.dataSelection.fact_table.map((info) => {
            if (info.id === action.payload.dataFactId) {
              const auxSelections = info.selections.asMutable()
              auxSelections.splice(action.payload.indexSelection, 1)
              return {
                ...info,
                selections: auxSelections,
              }
            }
            return info
          }),
        },
      })

    case types.ADD_NEW_SELECTION:
      return state.merge({
        dataSelection: {
          ...state.dataSelection,
          fact_table: state.dataSelection.fact_table.map((info, index) => {
            if (info.id === action.payload.dataFactId) {
              return {
                ...info,
                selections: [
                  ...info.selections,
                  {
                    id: info.selections[info.selections.length - 1].id + 1,
                    columns: null,
                    type: null,
                  },
                ],
              }
            }
            return info
          }),
        },
      })

    case types.FETCH_STATUS_CONNECTON_SUCCESS:
      // TODO: Revisar porque cambio el formato de la respuesta
      return state.merge({
        statusConnection: action.payload.data.data,
      })

    case types.FETCH_BUDGET_TYPE_CARDS_SUCCESS:
      return state.merge({
        budgetTypeCards: action.payload.data,
      })

    case types.FETCH_REPORTS_CARDS_SUCCESS:
      return state.merge({
        reportCards: action.payload.data,
      })

    case types.FETCH_DATA_PROJECTION_CHART_SUCCESS:
      return state.merge({
        dataProjectionChart: action.payload.data,
      })

    case types.FETCH_DATA_BUDGET_TYPE_CHART_SUCCESS:
      return state.merge({
        dataBudgetTypeChart: action.payload.data,
      })

    case types.FETCH_DATA_DIMENSION_CHART_SUCCESS:
      return state.merge({
        dataDimensionChart: action.payload.data,
      })

    // TODO: Temporally dashboard home quiscksit
    case types.FETCH_DASHBOARD_QUICKSIGHT_URL_SUCCESS:
      const dataInfo =
        action.payload.data?.data == null ? action.payload.data : action.payload.data.data
      return state.merge({ dashboardQuicksight: dataInfo })

    default:
      return state
  }
}
