import React from 'react'
import i18n from 'i18next'
import { FilterFilled, MessageOutlined } from '@ant-design/icons'
import { Popover, Tooltip } from 'antd'
import {
  TransactionCell,
  TableFilterDropdown,
  Typography,
  CellStyle,
} from 'modules/core/components'
import { dateMMYYYY, columnPadding, isAmount } from 'modules/core/utils'
import { COLUMNS, ROWS } from 'modules/core/constants'
import { Resizable } from 'react-resizable'

const HEADER_TITLE_SIZE = 22

//NOTE: SE AGREGA PARA CASOS DONDE EL NUMERO QUE DEVUELVE BACK TIENE UNA e. COMO SE LES COMPLICABA A ELLOS RESOLVERLO DESDE LA BASE, LOA GREGAMOS DESDE FRONT.
const renderAuxValueReview = (value) => (value && value.toString().includes('e') ? 0 : value)

const renderResizable = ({ onResize, width, ...restProps }) => {
  if (!width) {
    return <th {...restProps} />
  }

  return (
    <Resizable
      width={width}
      height={0}
      handle={
        <span
          className="react-resizable-handle"
          onClick={(e) => {
            e.stopPropagation()
          }}
        />
      }
      onResize={onResize}
    >
      <th {...restProps} />
    </Resizable>
  )
}

const tableComponents = {
  header: {
    cell: renderResizable,
  },
}

const renderSorterLocale = () => {
  return {
    triggerAsc: i18n.t('ACTION_SORT_ASC'),
    triggerDesc: i18n.t('ACTION_SORT_DESC'),
  }
}

const shouldBeFixed = ({ isCollapsed = true, columns = [] } = {}) =>
  isCollapsed ||
  columns.filter((column) => !column.is_transaction).length <= COLUMNS.MAX_COLUMNS_TO_FIX

const renderHeaderTooltip = ({ title, size = HEADER_TITLE_SIZE }) => {
  return {
    title: () => (
      <div style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-all' }}>
        {title.length > size ? (
          <Tooltip title={title}>{`${title.slice(0, size)}.`}</Tooltip>
        ) : (
          title
        )}
      </div>
    ),
  }
}

// NOTE: Recordar importar las clases css correspondientes
const renderCollapser = ({ width = 15, fixedColumns, isCollapsed, onCollapse }) => {
  return {
    width: width,
    title: `${isCollapsed ? '>' : '<'}`,
    className: COLUMNS.TYPES.COLLAPSER,
    ...(fixedColumns && { fixed: 'left' }),
    onHeaderCell: (column) => {
      return {
        onClick: onCollapse,
      }
    },
  }
}

// NOTE: Recordar importar las clases css correspondientes
const renderTotal = ({ width = COLUMNS.WIDTH.TRANSACTION, format, showNegativeInRed } = {}) => {
  return {
    className: COLUMNS.TYPES.TOTALIZER,
    align: 'right',
    width: width,
    // FIXME: Quitar cuando este el sort para transacciones
    sorter: false,
    render: (value, record) => (
      <CellStyle.Number
        value={renderAuxValueReview(value)}
        format={record.type === ROWS.RATIO ? '%' : format}
        showNegativeInRed={showNegativeInRed}
        type={record.type}
      />
    ),
  }
}

const renderVariation = () => {
  return {
    width: COLUMNS.WIDTH.VARIATION_PERCENTAGE,
    align: 'right',
    sorter: false,
    render: (value, record) => <CellStyle.VariationTable value={value} amount={value} />,
  }
}

const renderTransaction = ({
  width = COLUMNS.WIDTH.TRANSACTION,
  format,
  showNegativeInRed,
} = {}) => {
  return {
    className: COLUMNS.TYPES.TRANSACTION,
    align: 'right',
    width: width,
    // FIXME: Quitar cuando este el sort para transacciones
    sorter: false,
    render: (value, record) => (
      <CellStyle.Number
        value={renderAuxValueReview(value)}
        format={record.type === ROWS.RATIO ? '%' : format}
        showNegativeInRed={showNegativeInRed}
        type={record.type}
      />
    ),
  }
}

const renderFilters = ({ dataIndex, filterOptions, filters, onFilter }) => {
  if (!filterOptions || !filterOptions[dataIndex]) return null
  const filteredValues = filters[filterOptions[dataIndex].id]
  const { id: dimensionId, options } = filterOptions[dataIndex]
  const isFiltered = filters[dimensionId] && filters[dimensionId].length > 0 ? true : false

  return {
    filters: options,
    filterIcon: () => (
      <FilterFilled
        style={{
          ...(isFiltered && {
            padding: '8px',
            backgroundColor: '#D9D9D9',
            color: '#797979',
          }),
        }}
      />
    ),
    filterDropdown: (filterDropdownProps) => (
      <TableFilterDropdown
        {...filterDropdownProps}
        filteredValues={filteredValues}
        onFilter={(newFilters) => onFilter({ ...filters, [dimensionId]: newFilters })}
      />
    ),
  }
}

const renderComment = (width = COLUMNS.WIDTH.SMALL) => {
  return {
    width: width,
    fixed: 'right',
    align: 'center',
    dataIndex: 'comments',
    render: (comment) =>
      comment && comment.trim().length > 0 ? (
        <Popover placement="top" title={i18n.t('FIELD_COMMENT')} content={comment} trigger="click">
          <Typography.Icon icon={MessageOutlined} level={2} />
        </Popover>
      ) : (
        ''
      ),
  }
}

const renderDimension = ({
  dataIndex,
  title,
  width = COLUMNS.WIDTH.DIMENSION,
  fixedColumns,
  totalProps: { isTotal, ...restTotalProps },
  filterProps = {},
  collapserProps: { isCollapser, ...otherCollapserProps },
}) => {
  return {
    title: title,
    width: width,
    ...renderHeaderTooltip({ title }),
    ...(isTotal && renderTotal({ ...restTotalProps })),
    ...(!isTotal && renderFilters({ dataIndex, ...filterProps })),
    ...(fixedColumns && { fixed: 'left' }),
  }
}

const renderTransactionCell = ({
  initialValue,
  hasVarsOrRule,
  format,
  type,
  disabledInput,
  showContextMenu,
  showNegativeInRed,
  onCellEdit,
  onCellEditForward = null,
  showAdjustment = null,
  comments = {},
}) => (
  <TransactionCell
    initialValue={initialValue}
    hasVarsOrRule={hasVarsOrRule}
    format={format}
    type={type}
    disabledInput={disabledInput}
    showContextMenu={showContextMenu}
    showNegativeInRed={showNegativeInRed}
    onCellEdit={onCellEdit}
    {...(onCellEditForward && onCellEditForward)}
    {...(showAdjustment && showAdjustment)}
    comments={comments}
  />
)

const renderChildrenPlanning = ({
  children,
  date,
  format,
  disabled,
  showNegativeInRed,
  onCellEdit,
}) => {
  return {
    children: children.map((chil) => {
      return {
        ...chil,
        align: 'right',
        width: COLUMNS.WIDTH.TRANSACTION,
        render: (transaction, record) => {
          const isTotalizer = record.id === ROWS.TOTALIZER_ID

          if (transaction[date]) {
            return renderTransactionCell({
              initialValue: transaction[date][chil.data_index],
              hasVarsOrRule: {
                hasVars: record.has_variable_applied,
                hasRule: record.has_rule_applied,
              },
              format,
              type: record.type,
              disabledInput: disabled || isTotalizer,
              showContextMenu: !isTotalizer,
              showNegativeInRed,
              onCellEdit: (value) =>
                onCellEdit({ id: transaction[date].id, [chil.data_index]: value }, chil.data_index),
            })
          }
          return '-'
        },
      }
    }),
  }
}

// TODO: Quizas se podria hacer generica, ir viendo en cada caso.
// NOTE: Recordar importar las clases css correspondientes
const renderTransactionPlanning = ({
  title = null,
  date,
  cellFormat: { format, showNegativeInRed } = {},
  className = COLUMNS.TYPES.TRANSACTION,
  width = COLUMNS.WIDTH.TRANSACTION,
  disabled,
  onCellEdit,
  onCellEditForward = null,
  comments: { onComment = null, onDeleteComment = null, onEditComment = null } = {},
  showAdjustment = false,
  children = null,
  tableTypeKey,
}) => {
  return {
    title: title !== null ? title : dateMMYYYY(date, 'YYYY-MM'),
    align: 'right',
    width: width,
    // FIXME: Quitar cuando este el sort para transacciones
    sorter: false,
    className,
    ...(!isAmount(tableTypeKey) &&
      children &&
      renderChildrenPlanning({
        children,
        date,
        format,
        disabled,
        showNegativeInRed,
        onCellEdit,
      })),
    render: (transaction, record) => {
      const isTotalizer = record.id === ROWS.TOTALIZER_ID
      const isRatio = record.type === ROWS.RATIO

      if (transaction) {
        const hasPropAmount = transaction.hasOwnProperty('amount')
        const commentList = transaction.comments_thread ? transaction.comments_thread : []

        const transactionInfo = {
          columnId: date, // Only need YYYY-MM
          rowId: record.id,
          transactionId: transaction.id,
        }

        const adjustmentProps = {
          adjustment: {
            disabled: !transaction.adjustable,
            date: date,
            // TODO: adjustment_info deberia solo tener id y en vez de info > label
            row: record.adjustment_info,
          },
        }

        return renderTransactionCell({
          initialValue: hasPropAmount ? transaction.amount : renderAuxValueReview(transaction),
          hasVarsOrRule: {
            hasVars: record.has_variable_applied,
            hasRule: record.has_rule_applied,
          },
          format: isRatio ? '%' : format,
          type: record.type,
          disabledInput: disabled || isTotalizer,
          showContextMenu: !(isTotalizer || !hasPropAmount),
          showNegativeInRed: showNegativeInRed,
          onCellEdit: (value) => onCellEdit({ id: transaction.id, amount: value }, 'amount'),
          onCellEditForward: () =>
            onCellEditForward({
              rowId: record.id,
              startDate: date,
              amount: transaction.amount,
            }),
          showAdjustment: showAdjustment ? adjustmentProps : {},
          comments: {
            hasComment: commentList.length > 0 && !isTotalizer,
            commentList: commentList,
            ...(onComment && {
              onComment: (newComment) =>
                onComment({
                  ...transactionInfo,
                  comment: newComment,
                }),
            }),
            ...(onDeleteComment && {
              onDeleteComment: (commentId) => {
                onDeleteComment({
                  ...transactionInfo,
                  commentId,
                })
              },
            }),
            ...(onEditComment && {
              onEditComment: (commentId, newComment) => {
                onEditComment({
                  ...transactionInfo,
                  commentId,
                  comment: newComment,
                })
              },
            }),
          },
        })
      }
      return '-'
    },
  }
}

const renderOnHeaderResize = (onResize) => {
  return {
    onHeaderCell: (column) => ({
      width: column.width,
      onResize: (e, { size }) => {
        onResize((prevState) => {
          return [...prevState].map((col) => {
            return col.data_index === column.dataIndex || col.dataIndex === column.dataIndex
              ? { ...col, width: size.width }
              : col
          })
        })
      },
    }),
  }
}

const generator = ({
  columns = [],
  filteredColumns,
  fixedColumns = null,
  cellFormat,
  filterProps,
  collapserProps: { isCollapsed = true, ...otherCollapserProps },
  renderTransaction,
  showRowsComment = true,
  sorter = false,
  render = null,
  onResize = null,
}) => {
  const collapserProps = { isCollapsed: isCollapsed, ...otherCollapserProps }
  let filterCollapsedColumns = columns
  let auxFixedColumns =
    fixedColumns !== null ? fixedColumns : shouldBeFixed({ isCollapsed, columns })
  // Es necesario filtrar
  if (isCollapsed || filteredColumns) {
    filterCollapsedColumns = columns.filter((column, index) => {
      // Columnas fijas: Primera, Total y Transacciones.
      const fixedColumnsCondition =
        column.is_transaction ||
        index === COLUMNS.FIRST_INDEX ||
        column.data_index === COLUMNS.TOTAL_DATA_INDEX

      // Sin colapsar y reduce dimensiones visibles
      if (!isCollapsed && filteredColumns)
        return fixedColumnsCondition || filteredColumns.includes(column.data_index)
      // Sino devuelvo siempre las columnas fijas
      return fixedColumnsCondition
    })
  }

  const collapserColumn = renderCollapser({
    fixedColumns: auxFixedColumns,
    ...collapserProps,
  })

  const allDataColumns = filterCollapsedColumns.map((column, index) => {
    return {
      key: index,
      ellipsis: true,
      sorter,
      dataIndex: column.data_index,
      ...(!column.is_transaction &&
        renderDimension({
          dataIndex: column.data_index,
          title: column.title,
          width: column.width,
          fixedColumns: auxFixedColumns,
          totalProps: {
            isTotal: column.data_index === COLUMNS.TOTAL_DATA_INDEX,
            width: column.data_index === COLUMNS.TOTAL_DATA_INDEX && column.width,
            ...cellFormat,
          },
          filterProps,
          collapserProps: {
            isCollapser: index === COLUMNS.FIRST_INDEX,
            ...collapserProps,
          },
        })),
      ...(onResize && renderOnHeaderResize(onResize)),
      ...(column.is_transaction && renderTransaction(column)),
      ...(render && render(column)),
    }
  })

  const allColumns = [collapserColumn, ...allDataColumns]

  if (!showRowsComment) return allColumns

  return [...allColumns, renderComment()]
}

const generatorReportColumns = ({
  columns = [],
  onResize = null,
  cellFormat = {},
  editable = false,
  onCellEdit = null,
}) => {
  return columns.map((column, index) => {
    const isTotalizer = column.data_index === COLUMNS.TOTAL_DATA_INDEX
    const isTransaction = column.is_transaction
    const firstMonth = columns.filter((col) => col.is_transaction)[0].title

    const COLUMNS_BY_PERIOD = {
      concept: {
        title: i18n.t('FIELD_CONCEPT'),
        width: column.width,
      },
      TOTAL: {
        title: i18n.t('FIELD_TOTAL').toUpperCase(),
      },
    }

    return {
      key: index,
      ellipsis: true,
      dataIndex: column.data_index,
      ...(onResize && renderOnHeaderResize(onResize)),
      ...(!isTransaction && {
        ...COLUMNS_BY_PERIOD[column.data_index],
        fixed: 'left',
        render: (value, row) => columnPadding(value, row.level, row.type),
        ...(isTotalizer &&
          columnsHelper.renderTotal({
            width: isTotalizer && column.width,
            format: cellFormat.format,
          })),
      }),
      ...(isTransaction &&
        columnsHelper.renderTransactionPlanning({
          date: column.title,
          cellFormat: cellFormat,
          width: column.width,
          ...(!editable ? { disabled: true } : { disabled: column.title !== firstMonth }),
          ...(onCellEdit && { onCellEdit }),
        })),
    }
  })
}

const generatorComparisonColumns = ({ columns = [], onResize = null, renderValue }) => {
  return columns.map((col) => {
    return {
      ...col,
      title: col.title,
      ...(onResize && renderOnHeaderResize(onResize)),
      ...(col.dataIndex !== 'concept' && renderValue(col)),
    }
  })
}

const generatorDefault = ({
  columns = [],
  onResize = null,
  cellFormat = {},
  disabled = false,
  className = COLUMNS.TYPES.TRANSACTION,
  onCellEdit = null,
  onCellEditForward = null,
  comments: { onComment = null, onDeleteComment = null, onEditComment = null } = {},
}) => {
  const renderColumns = (column) => {
    return column.map((col) => {
      const isTotalizer = col.data_index === COLUMNS.TOTAL_DATA_INDEX
      const isVerticalAnalysis = col.title === COLUMNS.ANALYSIS_VERTICAL_INDEX
      const commentList = []
      return {
        ...(onResize && renderOnHeaderResize(onResize)),
        ...(!col.is_transaction && {
          title: col.title,
          dataIndex: col.data_index,
          ellipsis: true,
          width: 200,
          fixed: 'left',
          ...(isTotalizer &&
            columnsHelper.renderTotal({
              width: COLUMNS.WIDTH.TRANSACTION,
              format: cellFormat.format,
            })),
        }),
        ...(col.is_transaction &&
          !isVerticalAnalysis && {
            ...(col.children
              ? {
                  title: !isNaN(Date.parse(col.title))
                    ? dateMMYYYY(col.title, 'YYYY-MM')
                    : col.title,
                  width: COLUMNS.WIDTH.TRANSACTION,
                  className: !col.editable ? COLUMNS.TYPES.TOTALIZER : className,
                  ellipsis: true,
                  align: 'right',
                  children: renderColumns(col.children),
                }
              : {
                  title: !isNaN(Date.parse(col.title))
                    ? dateMMYYYY(col.title, 'YYYY-MM')
                    : col.title,
                  width: COLUMNS.WIDTH.TRANSACTION,
                  className: !col.editable ? COLUMNS.TYPES.TOTALIZER : className,
                  ellipsis: true,
                  align: 'right',
                  dataIndex: col.data_index,
                  render: (data, row) => {
                    if (data) {
                      if (row.type === ROWS.RATIO) {
                        return (
                          <CellStyle.Number
                            value={renderAuxValueReview(data.amount || data)}
                            format={'%'}
                            type={row.type}
                          />
                        )
                      }

                      const getTypeChange = (title) => {
                        if (title === 'p') return 'price'
                        if (title === 'q') return 'quantity'
                        return 'amount'
                      }

                      return (
                        <TransactionCell
                          initialValue={data.amount || data}
                          format={cellFormat.format}
                          type={row.type}
                          disabledInput={disabled || !col.editable || isTotalizer}
                          showContextMenu={!isTotalizer}
                          showNegativeInRed={cellFormat.showNegativeInRed}
                          onCellEdit={(value) => {
                            const auxData = {
                              ...(value.toString().includes('%')
                                ? {
                                    cell_id: data.id,
                                    percentage: parseInt(value.slice(0, -1)),
                                    type_change: getTypeChange(col.title),
                                  }
                                : {
                                    cell_id: data.id,
                                    amount: value,
                                    type_change:
                                      col.title === 'p' || col.title === 'q' ? col.title : 'amount',
                                  }),
                            }
                            onCellEdit(
                              auxData,
                              value.toString().includes('%') ? 'percentage' : 'default',
                            )
                          }}
                          {...(onCellEditForward && {
                            onCellEditForward: () =>
                              onCellEditForward({
                                rowId: row.id,
                                startDate: '',
                                amount: row.amount,
                              }),
                          })}
                          comments={{
                            hasComment: commentList.length > 0 && !isTotalizer,
                            commentList: commentList,
                            ...(onComment && {
                              onComment: (newComment) =>
                                onComment({
                                  //...transactionInfo,
                                  comment: newComment,
                                }),
                            }),
                            ...(onDeleteComment && {
                              onDeleteComment: (commentId) => {
                                onDeleteComment({
                                  //...transactionInfo,
                                  commentId,
                                })
                              },
                            }),
                            ...(onEditComment && {
                              onEditComment: (commentId, newComment) => {
                                onEditComment({
                                  //...transactionInfo,
                                  commentId,
                                  comment: newComment,
                                })
                              },
                            }),
                          }}
                        />
                      )
                    } else {
                      return '-'
                    }
                  },
                }),
          }),
        ...(isVerticalAnalysis && {
          ...(col.children
            ? {
                title: col.title.includes('-') ? dateMMYYYY(col.title, 'YYYY-MM') : col.title,
                width: COLUMNS.WIDTH.TRANSACTION,
                ellipsis: true,
                align: 'right',
                children: renderColumns(col.children),
              }
            : {
                title: col.title.includes('-') ? dateMMYYYY(col.title, 'YYYY-MM') : col.title,
                width: COLUMNS.WIDTH.TRANSACTION,
                ellipsis: true,
                align: 'right',
                dataIndex: col.data_index,
                render: (data, row) => {
                  if (data) {
                    return <CellStyle.Number value={data} format={'%'} type={row.type} />
                  } else {
                    return '-'
                  }
                },
              }),
        }),
      }
    })
  }
  return renderColumns(columns)
}

export const columnsHelper = {
  renderSorterLocale,
  shouldBeFixed,
  renderCollapser,
  renderTotal,
  renderVariation,
  renderFilters,
  renderComment,
  renderDimension,
  renderTransactionPlanning,
  generator,
  generatorReportColumns,
  generatorComparisonColumns,
  tableComponents,
  generatorDefault,
  renderTransaction,
  renderOnHeaderResize,
  renderAuxValueReview,
}
