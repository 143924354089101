import { Modal } from 'antd'
import { CombinationDimensionsTable } from 'modules/core/components/RulesComponents/CreateRuleModal/components/ContainerRule/components'
import { connect } from 'react-redux'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import configuration from 'modules/configuration'
import _ from 'lodash'

const EditDimensionModal = ({
  dataKey,
  visible,
  dataDimensions,
  onCancel,
  onConfirm,
  rowsDimensions,
  fetchAllDimensionsValues,
  allDimensionsList,
  setDimensionsSelection,
  dimensionsSelection,
}) => {
  const [isLoadingOptions, setIsLoadingOptions] = useState(false)
  const [areAllSelected, setAreAllSelected] = useState(false)
  const { t } = useTranslation()

  useEffect(() => {
    if (visible) {
      _.forEach(rowsDimensions[dataKey][0], (data, key) => {
        if (key !== 'id') {
          fetchAllDimensionsValues({ id_dimension: data.id }).then((response) => {
            setIsLoadingOptions(false)
            if (
              dataDimensions[data.id] &&
              response.payload.data.length === dataDimensions[data.id].length
            ) {
              setAreAllSelected(true)
            } else {
              setAreAllSelected(false)
            }
            setDimensionsSelection({
              key: dataKey,
              index: rowsDimensions[dataKey][0].id,
              data: { [data.id]: dataDimensions[data.id] },
              dimensionName: data.name,
              dataValueSelect: dataDimensions[data.id]
                ? dataDimensions[data.id].map((id) => `${data.name}_${id}`)
                : null,
              dimensionValues: response.payload.data,
            })
          })
        }
      })
    }
    // eslint-disable-next-line
  }, [dataDimensions, dataKey, fetchAllDimensionsValues, visible])

  return (
    <Modal
      title={t('CONFIG_DIMENSIONS_EDIT_VALUES')}
      visible={visible}
      bodyStyle={{ overflowY: 'auto', maxHeight: 400 }}
      width={560}
      centered
      forceRender
      destroyOnClose={true}
      onCancel={onCancel}
      onOk={() => onConfirm(dataKey, dimensionsSelection[dataKey][0])}
    >
      <CombinationDimensionsTable
        dataSource={rowsDimensions[dataKey]}
        loadingTable={false}
        dataKey={dataKey}
        loading={isLoadingOptions}
        setDimensionsSelection={setDimensionsSelection}
        dimensionsSelection={dimensionsSelection[dataKey]}
        dimensionsList={allDimensionsList}
        areAllSelected={areAllSelected}
        setAreAllSelected={setAreAllSelected}
      />
    </Modal>
  )
}

const mapStateToProps = (state) => ({
  rowsDimensions: configuration.selectors.getRowsDimensions(state),
  allDimensionsList: configuration.selectors.getAllDimensionsList(state),
  dimensionsSelection: configuration.selectors.getDimensionsSelection(state),
})

const mapDispatchToProps = {
  fetchAllDimensionsValues: configuration.actions.fetchAllDimensionsValues,
  setDimensionsSelection: configuration.actions.setDimensionsSelection,
}

export default connect(mapStateToProps, mapDispatchToProps)(EditDimensionModal)
