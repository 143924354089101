import React from 'react'
import { List } from 'antd'
import { ForecastCard } from './components'

const ForecastPeriodList = ({
  dataSource,
  userCanCreate,
  fetchForecasts,
  setDataComments,
  comments,
  onRefetchComments,
  loading,
}) => {
  return (
    <List
      grid={{ gutter: 24, xs: 1, sm: 1, md: 2, lg: 3, xl: 3, xxl: 3 }}
      dataSource={dataSource}
      renderItem={(forecast) => {
        return (
          <List.Item>
            <ForecastCard
              forecast={forecast}
              userCanCreate={userCanCreate}
              onRefetch={fetchForecasts}
              setDataComments={setDataComments}
              comments={comments}
              onRefetchComments={onRefetchComments}
              loading={loading}
            />
          </List.Item>
        )
      }}
    />
  )
}

export default ForecastPeriodList
