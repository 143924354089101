import { message } from 'antd'
import { tokenutils } from 'modules/login/actions'
import { generateMessageError } from 'modules/core/utils'

export const onRedirect = (action, whoAmI) => {
  whoAmI().then((response) => {
    if (response.error.response.status === 401) {
      tokenutils.removeToken()
    }
    action()
      .then((response) => {
        window.open(response.payload.data, '_self')
      })
      .catch((error) => {
        message.error(generateMessageError(error, 'FEEDBACK_DEFAULT_ERROR'), 8)
      })
  })
}
