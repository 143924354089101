import React, { useEffect, useState } from 'react'
import { Col, message, Row } from 'antd'
import { ProjectionControlTable } from './components'
import { DownloadModal, ProgressBar } from 'modules/core/components'
import { TableActions } from './components/ProjectionControlTable/components'
import { INTEGRATIONS, TABLES } from 'modules/core/constants'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { handleDelete, handleDeleteRow, handleSave, handleSavePrimary } from './utils'
import moment from 'moment'
import login from 'modules/login'
import control from 'modules/control'
import configuration from 'modules/configuration'
import _ from 'lodash'
import { normalizeFilters } from 'modules/core/utils/normalize-filters'
import { generateMessageError } from 'modules/core/utils'

const DEFAULT_FORMAT_CREATE = {
  'Suma (Decimales)': 'float',
  'Suma (Enteros)': 'int',
  'Suma (Miles)': 't',
  'Suma (Millones)': 'm',
}

const DEFAULT_FORMAT_SET = {
  float: 'Suma (Decimales)',
  int: 'Suma (Enteros)',
  t: 'Suma (Miles)',
  m: 'Suma (Millones)',
}

const getRowAuxName = (rows, allDimensionsList) => {
  return rows.map((row) => {
    const dimension = allDimensionsList.find((el) => parseInt(row) === el.id)
    return dimension ? dimension.name_origin : ''
  })
}

const getColsAuxName = (columns, allDimensionsList) => {
  return columns.map((col) => {
    if (col === 'Fecha') {
      return 'Fecha'
    } else {
      const dimension = allDimensionsList.find((el) => parseInt(col) === el.id)
      return dimension ? dimension.name_origin : ''
    }
  })
}

const getRowAuxId = (settings, allDimensionsList) => {
  return (
    !_.isEmpty(settings) &&
    settings.rows.map((row) => {
      const id = allDimensionsList.find((el) => el.name_origin === row).id
      return id.toString()
    })
  )
}

const getColsAuxId = (settings, allDimensionsList) => {
  return (
    !_.isEmpty(settings) &&
    settings.cols.map((col) => {
      const id = allDimensionsList.find((el) => el.name_origin === col)
        ? allDimensionsList.find((el) => el.name_origin === col).id
        : null
      if (col === 'Fecha') {
        return 'Fecha'
      } else {
        return id.toString()
      }
    })
  )
}

const Evolution = ({
  dataSource,
  isTableLoading,
  onRefresh,
  conceptId,
  conceptName,
  dataPeriod,
  periodId,
  setTablePagination,
  setDataSelection,
  canUserUpload,
  isProgressLoading,
  showProgressBar,
  loggedUser,
  downloadProjectionXLS,
  createLayout,
  fetchLayoutList,
  layoutList,
  fetchAllDimensions,
  allDimensionsList,
  deleteTransactionFilter,
  deleteLayout,
  hasComment,
  setHasComment,
  fetchCommentsList,
  commentsList,
  createComment,
  removeNewComment,
  currencyData,
  downloadProjectionExcel,
  downloadProjectionParquet,
}) => {
  const [showModal, setShowModal] = useState(false)
  const [tableSettings, setTableSettings] = useState({
    ...TABLES.DEFAULT_SETTINGS,
    negativeValuesType: TABLES.NEGATIVE_VALUES_TYPES.NORMAL,
    showNegativeInRed: false,
  })
  const [isDeleting, setIsDeleting] = useState(false)
  const [dataDelete, setDataDelete] = useState({})
  const [showPopover, setShowPopover] = useState(false)
  const [favouriteName, setFavouriteName] = useState('')
  const [isSaving, setIsSaving] = useState(false)
  const [settings, setSettings] = useState({})
  const [isLayoutLoading, setIsLayoutLoading] = useState(false)
  const [viewSelect, setViewSelect] = useState({})
  const [viewComment, setViewComment] = useState(false)
  const [dataComments, setDataComments] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [isCommentSaving, setIsCommentSaving] = useState(false)
  const { t } = useTranslation()

  const key = window.location.pathname.replaceAll('/', '-')

  useEffect(() => {
    setIsLoading(true)
    fetchCommentsList({ module: key }).then(() => setIsLoading(false))
  }, [fetchCommentsList, key])

  useEffect(() => {
    setDataComments(commentsList)
  }, [commentsList])

  useEffect(() => {
    setIsLayoutLoading(true)
    fetchLayoutList('real', `${periodId}-${conceptId}`).then(() => setIsLayoutLoading(false))
  }, [fetchLayoutList, periodId, conceptId])

  useEffect(() => {
    fetchAllDimensions()
  }, [fetchAllDimensions])

  useEffect(() => {
    const defaultLayout = layoutList.values.find((el) => el.primary)
    setViewSelect(defaultLayout)
  }, [layoutList.values])

  useEffect(() => {
    if (layoutList.values.length > 0 && !_.isEmpty(viewSelect) && allDimensionsList.length > 0) {
      setSettings((prevState) => {
        return {
          ...prevState,
          ...viewSelect.config,
          cols:
            getColsAuxName(viewSelect.config.columns, allDimensionsList).length > 0
              ? getColsAuxName(viewSelect.config.columns, allDimensionsList)
              : ['Fecha'],
          rows: getRowAuxName(viewSelect.config.rows, allDimensionsList),
          aggregatorName: DEFAULT_FORMAT_SET[viewSelect.config.default_format],
          vals: [viewSelect.config.default_value],
        }
      })
    }
  }, [allDimensionsList, layoutList.values, viewSelect])

  useEffect(() => {
    setDataDelete(settings.tableFilter)
  }, [settings.tableFilter])

  const handleCreate = ({ text, usersIds, idComment = null, setIsNewThread }) => {
    const dataa = {
      text,
      key: `${key}-table-evolution`,
      module: key,
      mentions: usersIds,
      ...(idComment && {
        parent: idComment,
      }),
    }
    setIsCommentSaving(true)
    createComment(dataa)
      .then(() =>
        fetchCommentsList({ module: key }).then(() => {
          message.success(t('FEEDBACK_SAVE_CHANGES_SUCCES'), 8)
          setIsCommentSaving(false)
          setIsNewThread(false)
        }),
      )
      .catch((error) => {
        message.error(generateMessageError(error, 'FEEDBACK_SAVE_CHANGES_FAIL_MESSAGE'), 8)
        setIsCommentSaving(false)
        setIsNewThread(false)
      })
  }

  const onCloseDeleteRow = () => {
    setIsDeleting(false)
    setDataSelection([])
    setTablePagination({ current: 1 })
  }

  const onCreate = () => {
    const data = {
      row_order: 0,
      column_order: 1,
      default_value: settings.vals[0],
      default_format: DEFAULT_FORMAT_CREATE[settings.aggregatorName],
      rows: getRowAuxId(settings, allDimensionsList),
      columns: getColsAuxId(settings, allDimensionsList),
    }

    return createLayout('real', `${periodId}-${conceptId}`, favouriteName, { config: data })
  }

  const onClose = () => {
    setIsSaving(false)
    setShowPopover(false)
    setFavouriteName('')
  }

  const defaultData = !_.isEmpty(viewSelect) && {
    ...viewSelect.config,
    rows: getRowAuxName(viewSelect.config.rows, allDimensionsList),
    columns: getColsAuxName(viewSelect.config.columns, allDimensionsList),
    default_format: DEFAULT_FORMAT_SET[viewSelect.config.default_format],
  }
  const defaultSettings = !_.isEmpty(settings) && {
    rows: settings.rows,
    columns: settings.cols || ['Fecha'],
    row_order: settings.row_order,
    column_order: settings.column_order,
    default_value: settings.vals ? settings.vals[0] : '',
    default_format: settings.aggregatorName,
  }

  const onSavePrimary = (data) => {
    const dataAux = layoutList.values.find((el) => el.name === data.name)
    return createLayout('real', `${periodId}-${conceptId}`, dataAux.name, {
      ...dataAux,
      primary: true,
    })
  }

  const getFileName = () => {
    return t('CONTROL_MENSUAL_EVOLUTION_FILE_NAME', {
      name: conceptName,
      period: dataPeriod.name,
      date: moment().format('lll'),
    })
  }

  const renderTableActions = () => (
    <TableActions
      setTableSettings={setTableSettings}
      tableSettings={tableSettings}
      columns={dataSource?.fields?.columns}
      onClickDownloadSpreadsheets={() => setShowModal(true)}
      onClickDownload={() =>
        downloadProjectionXLS(
          t('CONTROL_MENSUAL_EVOLUTION_FILE_NAME', {
            name: conceptName,
            period: dataPeriod.name,
            date: moment().format('lll'),
          }),
          {
            period_id: periodId,
            date_start: moment(dataPeriod.start_date).format('YYYY-MM-DD'),
            date_end: moment(dataPeriod.end_date).format('YYYY-MM-DD'),
          },
          {
            concept_id: conceptId,
          },
        )
      }
      dataDelete={dataDelete}
      onConfirmDelete={() =>
        handleDeleteRow({
          setIsLoading: setIsDeleting,
          deleteRow: () =>
            deleteTransactionFilter(
              periodId,
              conceptId,
              normalizeFilters(allDimensionsList, dataDelete),
            ),
          onRefetch: onRefresh,
          onClose: onCloseDeleteRow,
        })
      }
      isDeleting={isDeleting}
      disabled={!canUserUpload}
      onClickSaveFav={() => setShowPopover(true)}
      visibleFav={showPopover}
      onClickSave={() =>
        handleSave({
          setIsLoading: setIsSaving,
          createLayout: () => onCreate(),
          onRefetch: () => fetchLayoutList('real', `${periodId}-${conceptId}`),
          onRefresh,
          onClose,
        })
      }
      onClickCancelSaveFav={onClose}
      setFavouriteName={setFavouriteName}
      favouriteName={favouriteName}
      isLoading={isSaving}
      hasFavourite={layoutList.values.length > 0}
      options={layoutList.values.map((el) => {
        return { name: el.name, id: el.name, primary: el.primary }
      })}
      canSave={
        !_.isEmpty(settings) || (!_.isEmpty(settings) && !_.isEqual(defaultData, defaultSettings))
      }
      viewSelect={viewSelect}
      setViewSelect={(val) => {
        const aux = layoutList.values.find((el) => el.name === val)
        setViewSelect(aux)
      }}
      alreadyExist={_.isEqual(defaultData, defaultSettings)}
      layoutLoading={isLayoutLoading}
      setPrimary={(data) =>
        handleSavePrimary({
          setIsLoading: setIsSaving,
          createLayout: () => onSavePrimary(data),
          onRefetch: () => fetchLayoutList('real', `${periodId}-${conceptId}`),
          onClose: () => setIsSaving(false),
        })
      }
      onClickDeleteFav={(name) =>
        handleDelete({
          setIsLoading: setIsSaving,
          deleteLayout: () => deleteLayout('real', `${periodId}-${conceptId}`, name),
          onRefetch: () => fetchLayoutList('real', `${periodId}-${conceptId}`),
          onClose: () => setIsSaving(false),
        })
      }
      showComments={
        viewComment ||
        dataComments.filter((el) => el.key === `${key}-table-evolution` && !el.resolved).length > 0
      }
      hasComment={hasComment}
      setHasComment={setHasComment}
      setViewComment={setViewComment}
      userDetail={loggedUser}
      settings={settings}
      setDataComments={setDataComments}
      comments={dataComments.filter(
        (el) => (el.key === `${key}-table-evolution` && !el.resolved) || !el.text,
      )}
      onRefetchComments={() => fetchCommentsList({ module: key })}
      loading={isLoading || isCommentSaving}
      onCommentCreate={(text, usersIds, idComment, setIsNewThread) =>
        handleCreate({ text, usersIds, idComment, setIsNewThread })
      }
      removeNewComment={removeNewComment}
      currencyData={currencyData}
      onDownloadExcel={() =>
        downloadProjectionExcel(
          getFileName(),
          {
            period_id: periodId,
            date_start: moment(dataPeriod.start_date).format('YYYY-MM-DD'),
            date_end: moment(dataPeriod.end_date).format('YYYY-MM-DD'),
          },
          {
            concept_id: conceptId,
          },
        )
      }
      onDownloadParquet={() =>
        downloadProjectionParquet(
          getFileName(),
          {
            period_id: periodId,
            date_start: moment(dataPeriod.start_date).format('YYYY-MM-DD'),
            date_end: moment(dataPeriod.end_date).format('YYYY-MM-DD'),
          },
          {
            concept_id: conceptId,
          },
        )
      }
    />
  )

  return (
    <>
      <Row gutter={[8, 8]}>
        <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
          {showProgressBar && <ProgressBar isLoading={isProgressLoading} />}
        </Col>
        <Col span={24}>{renderTableActions()}</Col>
        <Col span={24}>
          <ProjectionControlTable
            dataSource={dataSource.data}
            fields={dataSource.fields}
            isTableLoading={isTableLoading}
            settings={settings}
            setSettings={setSettings}
            hasComment={hasComment}
            setViewComment={setViewComment}
            setHasComment={setHasComment}
          />
        </Col>
      </Row>
      <DownloadModal
        title={INTEGRATIONS.KEYS.SPREADSHEETS_KEY}
        visible={showModal}
        onCancel={() => setShowModal(false)}
        defaultValue={loggedUser.folder_id}
        dataSpreadsheets={{
          type_load: 'seguimiento',
          params: {
            date_start: moment(dataPeriod?.start_date).format('YYYY-MM-DD'),
            date_end: moment(dataPeriod?.end_date).format('YYYY-MM-DD'),
            period_id: periodId,
            concept_id: conceptId,
          },
        }}
      />
    </>
  )
}

const mapStateToProps = (state) => ({
  loggedUser: login.selectors.getWhoAmI(state),
  layoutList: control.selectors.getLayoutList(state),
  allDimensionsList: configuration.selectors.getAllDimensionsList(state),
  hasComment: configuration.selectors.getHasComment(state),
  commentsList: configuration.selectors.getCommentsList(state),
})

const mapDispatchToProps = {
  downloadProjectionXLS: control.actions.downloadProjectionXLS,
  createLayout: control.actions.createLayout,
  fetchLayoutList: control.actions.fetchLayoutList,
  fetchAllDimensions: configuration.actions.fetchAllDimensions,
  deleteTransactionFilter: control.actions.deleteTransactionFilterByControl,
  deleteLayout: control.actions.deleteLayout,
  setHasComment: configuration.actions.setHasComment,
  fetchCommentsList: configuration.actions.fetchCommentsList,
  createComment: configuration.actions.createComment,
  removeNewComment: configuration.actions.removeNewComment,
  downloadProjectionExcel: control.actions.downloadProjectionExcel,
  downloadProjectionParquet: control.actions.downloadProjectionParquet,
}

export default connect(mapStateToProps, mapDispatchToProps)(Evolution)
