import React, { useCallback, useRef, useState } from 'react'
import { Card, Dropdown, Menu, message } from 'antd'
import { ButtonActionBar } from 'modules/core/components'
import { connect } from 'react-redux'
import { executeExportAsImage, generateMessageError } from 'modules/core/utils'
import { CardBody, ExtraActions } from './components'
import { useParams } from 'react-router-dom'
import { onDelete } from './utils'
import { CommentOutlined } from '@ant-design/icons'
import reports from 'modules/reports'
import login from 'modules/login'
import configuration from 'modules/configuration'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import { GENERAL } from 'modules/core/constants'

const ElementContainer = ({
  key,
  dataElement,
  index,
  setShowCreateModal,
  setShowSettingModal,
  setDataElementDash,
  setDuplicateData,
  settingsChart,
  onRefetch,
  setDataComments,
  dataComments,
  onRefetchComments,
  loadingComments,
  commentKey,
  deleteDashboardElement,
  loggedUser,
  hasComment,
  setHasComment,
  createComment,
  removeNewComment,
}) => {
  const [deleteId, setDeleteId] = useState(null)
  const [isDeleting, setIsDeleting] = useState(false)
  const [viewComment, setViewComment] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const elementRef = useRef([])
  let { reportId } = useParams()
  const { t } = useTranslation()

  const onExportChart = useCallback((props) => {
    executeExportAsImage({ ...props })
  }, [])

  const onClose = () => {
    setDeleteId(null)
    setIsDeleting(false)
  }

  const renderExtra = () => (
    <ExtraActions
      onConfirmPopconfirm={() =>
        onDelete({
          setIsLoading: setIsDeleting,
          deleteDashboardElement: () => deleteDashboardElement(reportId, deleteId),
          onRefetch,
          onClose,
        })
      }
      loading={isDeleting}
      onCancelPopconfirm={() => setDeleteId(null)}
      visible={deleteId === dataElement.id}
      onClickEdit={() => {
        setShowCreateModal(true)
        setDuplicateData(dataElement)
      }}
      showConfig={dataElement.chart_type && dataElement.size !== 'small'}
      onClickConfig={() => {
        setShowSettingModal(true)
        setDataElementDash({ id: dataElement.id, chartType: dataElement.chart_type })
      }}
      onClickExport={(formatType) =>
        onExportChart({
          chartRef: elementRef.current[index],
          format: formatType.id,
          onExport: formatType.onAction,
          props: formatType.props,
          title: dataElement.title || '',
        })
      }
      onClickDelete={() => setDeleteId(dataElement.id)}
      showComment={
        viewComment ||
        dataComments.filter(
          (el) => el.key === `${commentKey}-chart-${dataElement.id}` && !el.resolved,
        ).length > 0
      }
      setHasComment={setHasComment}
      hasComment={hasComment}
      setViewComment={setViewComment}
      setDataComments={setDataComments}
      dataComments={dataComments.filter(
        (el) => (el.key === `${commentKey}-chart-${dataElement.id}` && !el.resolved) || !el.text,
      )}
      onRefetchComments={onRefetchComments}
      loadingComments={loadingComments || isSaving}
      onCommentCreate={(text, usersIds, idComment, setIsNewThread) =>
        handleCreate({ text, usersIds, idComment, setIsNewThread })
      }
      elementId={dataElement.id}
      userDetail={loggedUser}
      removeNewComment={removeNewComment}
      commentKey={commentKey}
    />
  )

  const renderExtraButton = () => (
    <ButtonActionBar>
      <div className="report-dashboard-chart-area-icon">{renderExtra()}</div>
    </ButtonActionBar>
  )

  const handleCreate = ({ text, usersIds, idComment = null, setIsNewThread }) => {
    const dataa = {
      text,
      key: `${commentKey}-chart-${dataElement.id}`,
      module: commentKey,
      mentions: usersIds,
      ...(idComment && {
        parent: idComment,
      }),
    }
    setIsSaving(true)
    createComment(dataa)
      .then(() =>
        onRefetchComments().then(() => {
          message.success(t('FEEDBACK_SAVE_CHANGES_SUCCES'), 8)
          setIsSaving(false)
          setIsNewThread(false)
        }),
      )
      .catch((error) => {
        message.error(generateMessageError(error, 'FEEDBACK_SAVE_CHANGES_FAIL_MESSAGE'), 8)
        setIsSaving(false)
        setIsNewThread(false)
      })
  }

  return (
    <Dropdown
      trigger={['contextMenu']}
      overlay={
        <Menu>
          {GENERAL.OPTION_COMMENT.map((el) => (
            <Menu.Item
              key={el.key}
              icon={<CommentOutlined />}
              onClick={() => {
                setViewComment(true)
                setHasComment({
                  type: 'chart',
                  data: !_.isEmpty(hasComment?.chart)
                    ? !_.isEmpty(hasComment?.chart[commentKey])
                      ? !hasComment?.chart[commentKey][dataElement.id]
                      : true
                    : true,
                  key: commentKey,
                  id: dataElement.id,
                })
              }}
            >
              {t(el.label)}
            </Menu.Item>
          ))}
        </Menu>
      }
    >
      <div key={key}>
        {!dataElement.title && dataElement.data && renderExtraButton()}
        <div key={dataElement.id} ref={(el) => (elementRef.current[index] = el)}>
          <Card
            title={dataElement.title || undefined}
            key={dataElement.id}
            size="small"
            bodyStyle={{ padding: 2 }}
            extra={dataElement.title && renderExtra()}
          >
            <CardBody dataElement={dataElement} settingsChart={settingsChart} />
          </Card>
        </div>
      </div>
    </Dropdown>
  )
}

const mapStateToProps = (state) => ({
  loggedUser: login.selectors.getWhoAmI(state),
  hasComment: configuration.selectors.getHasComment(state),
})

const mapDispatchToProps = {
  deleteDashboardElement: reports.actions.deleteDashboardElement,
  setHasComment: configuration.actions.setHasComment,
  createComment: configuration.actions.createComment,
  removeNewComment: configuration.actions.removeNewComment,
}

export default connect(mapStateToProps, mapDispatchToProps)(ElementContainer)
