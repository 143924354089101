import React, { useState } from 'react'
import { Col, Row } from 'antd'
import { connect } from 'react-redux'
import {
  ContainersButtons,
  MappingDataFile,
  PreviousModal,
  PruebaS3Modal,
  Steps,
  UploadDataScreen,
} from './components'
import { useTranslation } from 'react-i18next'
import { comprobeCompleted, functionMappingDataFile, renderTooltip } from './utils'
import { Button } from 'modules/core/components'
import { userCanAcces } from 'modules/core/utils'
import { ACCES_USERS } from 'modules/core/constants'
import home from 'modules/home'
import login from 'modules/login'

const ScreenSteps = ({
  dataSteps,
  dataSelection,
  setShowScreenProcessing,
  setCleanData,
  saveConfig,
  dataConnectors,
  loggedUser: { email },
}) => {
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [current, setCurrent] = useState(0)
  const [loading, setLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const { t } = useTranslation()

  const has_upload_files = dataSelection.fact_table.length > 0

  const components = {
    load: {
      content: <UploadDataScreen />,
      tooltip: 'HOME_INITIAL_STEPS_LOAD_DATA_INFORMATIVE_TOOLTIP_TITLE',
      has_data: true,
      completed: has_upload_files,
      onAction: () => setCurrent((prevState) => prevState + 1),
    },
    selected: {
      content: <MappingDataFile />,
      tooltip: has_upload_files && t(renderTooltip(dataSelection)),
      has_data:
        has_upload_files &&
        dataSelection.fact_table.some(
          (data) =>
            data.concept || data.selections.some((sel) => sel.type || sel.columns || sel.dimension),
        ),
      completed: has_upload_files && comprobeCompleted(dataSelection),
      loading,
      onAction: () =>
        functionMappingDataFile(dataSelection, setShowScreenProcessing, setLoading, saveConfig),
    },
  }

  const prev = () => {
    setCurrent((prevState) => prevState - 1)
    setCleanData(dataSteps[current - 1].key)
  }

  return (
    <Row gutter={[24, 24]}>
      {userCanAcces({
        patchAcces: ACCES_USERS.PATCH_ACCES_BUTTON_S3,
        email,
      }) && (
        <Col span={24}>
          <Button.Primary title="Prueba s3" onClick={() => setShowModal(true)} />
        </Col>
      )}
      <Col span={24}>
        <Steps dataSteps={dataSteps} current={current} />
      </Col>
      <Col span={24}>{components[dataSteps[current].key].content}</Col>
      <Col span={24}>
        <ContainersButtons
          current={current}
          components={components}
          dataSteps={dataSteps}
          setShowConfirmModal={() => setShowConfirmModal(true)}
          loading={loading}
          prev={prev}
          dataConnectors={dataConnectors}
        />
      </Col>
      <PreviousModal
        visible={showConfirmModal}
        onClose={() => setShowConfirmModal(false)}
        onConfirm={() => {
          prev()
          setShowConfirmModal(false)
        }}
      />
      <PruebaS3Modal visible={showModal} onCancel={() => setShowModal(false)} />
    </Row>
  )
}

const mapStateToProps = (state) => ({
  dataSteps: home.selectors.getDataSteps(state),
  dataSelection: home.selectors.getDataSelection(state),
  dataConnectors: home.selectors.getDataConnectors(state),
  loggedUser: login.selectors.getWhoAmI(state),
})

const mapDispatchToProps = {
  uploadInitialFile: home.actions.uploadInitialFile,
  saveConfig: home.actions.saveConfig,
  setCleanData: home.actions.setCleanData,
}

export default connect(mapStateToProps, mapDispatchToProps)(ScreenSteps)
