import React, { useEffect, useState } from 'react'
import { Alert, Col, Drawer, Row, Spin } from 'antd'
import { Button, EmptyScreen, ProjectionUploadModal } from 'modules/core/components'
import { useTranslation } from 'react-i18next'
import { ROLES } from 'modules/core/constants'
import { isUserAllowed } from 'modules/core/utils'
import { connect } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import GENERAL from '../../../constants/general'
import { CollapseHeader, CollapseRules, UserActions } from './components'
import { onFailUploadFile, onSuccessUploadFile } from '../utils'
import emptyStateImage from 'assets/images/compositions/empty-big.svg'
import configuration from 'modules/configuration'
import planning from 'modules/planning'
import moment from 'moment'

import './ScreenRules.scss'

const {
  SETTINGS__PROJECTION_ELEMENTS__BUSINESS_RULES__ABM,
  SETTINGS__PROJECTION_ELEMENTS__VARIABLES__ABM,
} = ROLES

const ScreenRules = ({
  visible,
  onClose,
  globalProjectionId,
  type,
  onRefetch,
  applyRule,
  unApplyRule,
  disabled = false,
  ruleList,
  downloadVariablesTemplateXLS,
  periodList,
  uploadVariablesFileXLS,
  fetchRulesList,
  typeModule = GENERAL.MODULES_KEYS.MODULE_PLANNING,
  moduleId = 0,
}) => {
  const [isScreenLoading, setIsScreenLoading] = useState(true)
  const [showUploadModal, setShowUploadModal] = useState(false)
  let { periodId } = useParams()
  let history = useHistory()
  const { t } = useTranslation()

  const dataPeriod = periodList.find((el) => el.id === parseInt(periodId))

  useEffect(() => {
    if (visible) {
      fetchRulesList({ period_id: periodId, type, pk: globalProjectionId }).then(() =>
        setIsScreenLoading(false),
      )
    }
  }, [fetchRulesList, dataPeriod, visible, periodId, type, globalProjectionId])

  const userCanRuleCreate = isUserAllowed(SETTINGS__PROJECTION_ELEMENTS__BUSINESS_RULES__ABM)
  const userCanVariableCreate = isUserAllowed(SETTINGS__PROJECTION_ELEMENTS__VARIABLES__ABM)

  const renderAlert = () => (
    <Alert
      showIcon
      closable
      type="info"
      description={t('PLANNING_RULE_KEEP_DATA_INFORMATIVE_ALERT_DESCRIPTION')}
    />
  )

  const renderNoDataScreen = () => (
    <EmptyScreen
      image={emptyStateImage}
      title={t('CONFIG_FORMULAS_EMPTY_SCREEN_TITLE')}
      description={t('CONFIG_FORMULAS_EMPTY_SCREEN_DESCRIPTION')}
      footer={
        <Button.Primary
          onClick={() => history.push('/configuracion/herramientas/')}
          title="ACTION_GO_TO_CONFIGURE"
        />
      }
    />
  )

  return (
    <>
      <Drawer
        className="scenario-simulation-drawer"
        visible={visible}
        title={t('ACTION_STAGE')}
        closable={true}
        onClose={onClose}
        size="large"
        destroyOnClose
        forceRender
      >
        <Spin spinning={isScreenLoading}>
          <Row gutter={[24, 24]}>
            {ruleList.count === 0 && <Col span={24}>{renderNoDataScreen()}</Col>}
            {ruleList.count > 0 && (
              <>
                <Col span={24}>
                  <UserActions
                    disabled={!userCanVariableCreate || disabled}
                    onClick={() => setShowUploadModal(true)}
                  />
                </Col>
                <Col span={24}>{renderAlert()}</Col>
                <Col span={24}>
                  <CollapseHeader />
                  <CollapseRules
                    ruleList={ruleList}
                    canCreate={userCanRuleCreate || userCanVariableCreate}
                    onRefetch={onRefetch}
                    onRefetchRules={() =>
                      fetchRulesList({ period_id: periodId, type, pk: globalProjectionId })
                    }
                    applyRule={applyRule}
                    unApplyRule={unApplyRule}
                    disabled={disabled}
                    typeModule={typeModule}
                    moduleId={moduleId}
                  />
                </Col>
              </>
            )}
          </Row>
        </Spin>
      </Drawer>
      <ProjectionUploadModal
        title={t('CONFIG_VAR_UPLOAD_VALUES_MODAL_TITLE')}
        visible={showUploadModal}
        onCancel={() => setShowUploadModal(false)}
        uploadFile={(fileList) => uploadVariablesFileXLS(periodId, fileList)}
        onSuccessUpload={() =>
          onSuccessUploadFile({
            setIsLoading: setIsScreenLoading,
            fetchRulesList,
          })
        }
        onFailUpload={(error) => onFailUploadFile(error)}
        downloadTemplateFile={() =>
          downloadVariablesTemplateXLS(
            periodId,
            t('PLANNING_TEMPLATE_UPLOAD_VARIABLES_VALUES', {
              periodName: dataPeriod.name,
              date: moment().format('lll'),
            }),
          )
        }
      />
    </>
  )
}

const mapStateToProps = (state) => ({
  periodList: planning.selectors.getPeriodList(state),
  ruleList: configuration.selectors.getRulesList(state),
})

const mapDispatchToProps = {
  downloadVariablesTemplateXLS: planning.actions.downloadVariablesTemplateXLS,
  uploadVariablesFileXLS: planning.actions.uploadVariablesFileXLS,
  fetchRulesList: configuration.actions.fetchRulesList,
}

export default connect(mapStateToProps, mapDispatchToProps)(ScreenRules)
