import React, { useState } from 'react'
import { connect } from 'react-redux'
// import { useHistory, useParams } from 'react-router-dom';
import { NumberCell, TableContextMenu } from 'modules/core/components'
import CommentPopover from '../CommentPopover/CommentPopover'
import adjustments from 'modules/adjustments'

const TransactionCell = ({
  initialValue = '',
  // hasVarsOrRule,
  format = '0,0',
  type = '',
  disabledInput = false,
  showContextMenu = false,
  showNegativeInRed = false,
  onCellEdit = null,
  onCellEditForward = null,
  comments: {
    hasComment = false,
    commentList = [],
    onComment = null,
    onDeleteComment = null,
    onEditComment = null,
  },
  // adjustment = false,
  // editAdjustmentValues=null,
}) => {
  const [previousValue, setPreviousValue] = useState(null)
  const [canUndo, setCanUndo] = useState(false)
  const [showComment, setShowComment] = useState(false)
  // let history = useHistory();
  // let { periodId } = useParams();

  const renderCommentPopover = () => {
    if (commentList.length <= 0 && onComment == null) {
      return null
    }
    return (
      <CommentPopover
        visible={showComment}
        onVisibleChange={setShowComment}
        data={commentList}
        onComment={onComment}
        onDelete={onDeleteComment}
        onEdit={onEditComment}
      >
        <span className="transaction-cell-comment" data-visible={hasComment} />
      </CommentPopover>
    )
  }

  return (
    <>
      {renderCommentPopover()}
      <TableContextMenu
        visible={showContextMenu}
        undo={{
          visible: !disabledInput,
          disabled: previousValue === null,
          onClick: () => setCanUndo(true),
        }}
        comment={{
          visible: onComment !== null,
          onClick: () => setShowComment(true),
        }}
        replicateValue={{
          visible: onCellEditForward !== null,
          disabled: disabledInput,
          onClick: () => {
            onCellEditForward()
            setPreviousValue(null)
          },
        }}
        // adjustment={{
        //   visible: adjustment ? true : false,
        //   disabled: adjustment.disabled,
        //   onClick: () => {
        //     editAdjustmentValues({
        //       date: adjustment.date,
        //       initialRow: [adjustment.row],
        //     });
        //     history.push(`/seguimiento/${periodId}/economico/ajustes/`);
        //   },
        // }}
      >
        <NumberCell
          format={format}
          // hasVarsOrRule={hasVarsOrRule}
          initialValue={initialValue}
          type={type}
          disabled={disabledInput}
          showNegativeInRed={showNegativeInRed}
          onCellUpdate={onCellEdit}
          undoAction={{
            canUndo,
            previousValue,
            setPreviousValue,
            setCanUndo,
          }}
        />
      </TableContextMenu>
    </>
  )
}

const mapDispatchToProps = {
  editAdjustmentValues: adjustments.actions.editAdjustmentValues,
}

export default connect(null, mapDispatchToProps)(TransactionCell)
