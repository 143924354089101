const DATA_LINKS = [
  {
    id: 1,
    href: '#cookies',
    title: 'SECURITY_POLICY_TERMS_SUBTITLE_1',
  },
  {
    id: 2,
    href: '#licencia',
    title: 'SECURITY_POLICY_TERMS_SUBTITLE_2',
  },
  {
    id: 3,
    href: '#hipervinculos',
    title: 'SECURITY_POLICY_TERMS_SUBTITLE_3',
  },
  {
    id: 4,
    href: '#responsabilidad-contenido',
    title: 'SECURITY_POLICY_TERMS_SUBTITLE_4',
  },
  {
    id: 5,
    href: '#reserva-derechos',
    title: 'SECURITY_POLICY_TERMS_SUBTITLE_5',
  },
  {
    id: 6,
    href: '#eliminacion-sitio',
    title: 'SECURITY_POLICY_TERMS_SUBTITLE_6',
  },
  {
    id: 7,
    href: '#extencion-responsabilidad',
    title: 'SECURITY_POLICY_TERMS_SUBTITLE_7',
  },
]

export const dataLinks = DATA_LINKS
