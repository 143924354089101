import ButtonDefault from '../../ButtonDefault'

const ButtonDefaultWhitRightIcon = ({
  title,
  icon = null,
  disabled = false,
  onClick = () => {},
  tooltipTitle = '',
  ...props
}) => {
  return (
    <ButtonDefault
      {...props}
      title={title}
      icon={icon}
      disabled={disabled}
      onClick={onClick}
      tooltipTitle={tooltipTitle}
    />
  )
}

export default ButtonDefaultWhitRightIcon
