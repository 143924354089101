import React from 'react'
import { AppLayout } from 'modules/core/layouts'
import { Space } from 'antd'
import { useTranslation } from 'react-i18next'
import { Typography } from 'modules/core/components'
import emptyImage from 'assets/images/compositions/empty-big.svg'

import './NotFoundScreen.scss'

const NotFoundScreen = ({ history, layout }) => {
  const { t } = useTranslation()

  return (
    <AppLayout showIcons={false}>
      <Space className="core-not-found-screen" direction="vertical" size="large">
        <img alt="not-found-screen" src={emptyImage} />
        <div>
          <Typography.Headline level={5} type="title">
            {t('NOT_FOUND_SCREEN_TITLE')}
          </Typography.Headline>
          {t('NOT_FOUND_SCREEN_DESCRIPTION')}
        </div>
      </Space>
    </AppLayout>
  )
}

export default NotFoundScreen
