import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import ChatbotHome from './ChatbotHome/ChatbotHome'

const Home = () => (
  <Switch>
    <Route exact path="/chatbot" component={ChatbotHome} />
    <Redirect to="/chatbot" />
  </Switch>
)

export default Home
