import React from 'react'
import { Col, Form, Radio, Row, Space } from 'antd'
import { CHARTS } from 'modules/core/constants'
import { useTranslation } from 'react-i18next'
import GeneralFormItems from '../GeneralFormItems/GeneralFormItems'

const BarChartSettings = ({ form, defaultSetting, chart_type }) => {
  const { t } = useTranslation()
  return (
    <Form form={form} layout="horizontal" hideRequiredMark={false} initialValues={defaultSetting}>
      {() => (
        <Row gutter={[8, 8]}>
          <Col span={24}>
            <Form.Item label={t('LABEL_POSITION_MODE')} name="groupMode">
              <Radio.Group>
                <Space direction="vertical">
                  {CHARTS.SETTINGS.BAR_GROUP_MODE.map((el) => (
                    <Radio value={el} key={el}>
                      {t(CHARTS.I18N[el])}
                    </Radio>
                  ))}
                </Space>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label={t('CHART_DISPLAY_MODE_SETTINGS')} name="layout">
              <Radio.Group>
                <Space direction="vertical">
                  {CHARTS.DISPLAY_OPTIONS.map((el) => (
                    <Radio value={el.id} key={el.id}>
                      {t(el.name)}
                    </Radio>
                  ))}
                </Space>
              </Radio.Group>
            </Form.Item>
          </Col>
          <GeneralFormItems chart_type={chart_type} />
        </Row>
      )}
    </Form>
  )
}

export default BarChartSettings
