import { useFeatureIsOn, useFeatureValue } from '@growthbook/growthbook-react'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { message, Radio, Spin } from 'antd'
import QuickSightDashboard from '../../../core/components/QuickSightDashboard'
import { SiderLayout } from '../../../core/layouts'
import home from '../../index'
import { DashboardGeneral, DashboardHome, InitialScreen } from './components'
import { generateMessageError, sendUserDataToGTM } from 'modules/core/utils'
// import { ROLES } from 'modules/core/constants';
import configuration from 'modules/configuration'
import planning from 'modules/planning'
import login from 'modules/login'
// import { getOperationsByKeys, isUserAllowed } from 'modules/core/utils';

// const { PLANNING, CONTROL, FORECAST, ANALYSIS, SETTINGS } = ROLES;

import './Home.scss'
import DashboardSkeleton from './components/DashboardSkeleton/DashboardSkeleton'

export const FRONT_URL = process.env.REACT_APP_FRONT_URL || 'https://next.stg.getplika.com'

const Home = ({
  dimensionsList,
  fetchDimensionsList,
  fetchPeriodList,
  loggedUser,
  periodList,
  fetchEmbedQuicksightUrl,
  dashboardQuicksight,
}) => {
  const [isConfigLoading, setIsConfigLoading] = useState(false)
  const enabledIntegrations = useFeatureIsOn('feature-dashbard-integrations')
  const dashboardType = useFeatureValue('feature-dashboard-home', { type: 'Local' })
  const [quickSightType, setQuickSightType] = useState('Dashboard')
  const [loadingIframe, setLoadingIframe] = useState(false)

  useEffect(() => {
    setIsConfigLoading(true)
    fetchDimensionsList()
      .then(() => setIsConfigLoading(false))
      .catch(() => setIsConfigLoading(false))

    return () => setIsConfigLoading(false)
  }, [fetchDimensionsList])

  useEffect(() => {
    fetchPeriodList()
  }, [fetchPeriodList])

  useEffect(() => {
    sendUserDataToGTM(loggedUser, '/')
  }, [loggedUser])

  const handleLoad = () => {
    console.log('Iframe loaded')
    setLoadingIframe(false)
  }

  useEffect(() => {
    setLoadingIframe(true)
    if (dashboardType.type === 'QuickSight') {
      let resourceId
      let resourceType
      if (dashboardType?.method === 'Switch') {
        const values = dashboardType?.switch
        if (!values) {
          return
        }
        const dashboardQuicksightSwitch = values[quickSightType]
        resourceId = dashboardQuicksightSwitch?.resourceId
        resourceType = dashboardQuicksightSwitch?.resourceType
      } else {
        resourceId = dashboardType?.resourceId
        resourceType = dashboardType?.resourceType
      }
      fetchEmbedQuicksightUrl({
        resource_id: resourceId,
        resource_type: resourceType,
      }).catch((error) => {
        message.error(generateMessageError(error), 8)
      })
    } else if (dashboardType.type === 'Local') {
      setLoadingIframe(false)
    }
  }, [fetchEmbedQuicksightUrl, dashboardType, quickSightType])

  const canUserViewDashboard = loggedUser.roles.find(
    (el) => el.code === 'adm' && el.name === 'Admin',
  )

  const renderDash = () => {
    if (dashboardType.type === 'Home') {
      return (
        <SiderLayout className={'dashboard-home-iframe'}>
          {loadingIframe && <DashboardSkeleton />}
          <iframe
            id="DashboardhHome"
            title="DashboardhHome"
            src={`${FRONT_URL}/dashboard`}
            width="100%"
            height="100%"
            style={{
              border: 'none',
              padding: '0 0',
              overflowX: 'hidden',
              overflowY: 'auto',
            }}
            onLoad={handleLoad}
          ></iframe>
        </SiderLayout>
      )
    } else if (dashboardType.type === 'QuickSight') {
      if (dashboardType?.method === 'Sdk') {
        return (
          <SiderLayout className={'dashboard-home-iframe'}>
            <QuickSightDashboard embedUrl={dashboardQuicksight?.EmbedUrl} />
          </SiderLayout>
        )
      } else if (dashboardType?.method === 'Switch') {
        return (
          <SiderLayout className={'dashboard-home-iframe'}>
            {loadingIframe && <DashboardSkeleton />}
            <div style={{ position: 'absolute', top: 60, right: 24, zIndex: 1 }}>
              <Radio.Group
                options={[
                  { label: 'Dashboard', value: 'Dashboard' },
                  { label: 'Editor', value: 'Analysis' },
                ]}
                onChange={(e) => setQuickSightType(e.target.value)}
                value={quickSightType}
                optionType="button"
                buttonStyle="solid"
                size={'small'}
              />
            </div>
            <iframe
              id="DashboardhHome"
              title="DashboardhHome"
              src={dashboardQuicksight?.EmbedUrl}
              width="100%"
              height="100%"
              onLoad={handleLoad}
              style={{
                border: 'none',
                padding: '0 0',
                overflowX: 'hidden',
                overflowY: 'auto',
              }}
            ></iframe>
          </SiderLayout>
        )
      } else {
        return (
          <SiderLayout className={'dashboard-home-iframe'}>
            {loadingIframe && <DashboardSkeleton />}
            <iframe
              id="DashboardhHome"
              title="DashboardhHome"
              src={dashboardQuicksight?.EmbedUrl}
              width="100%"
              height="100%"
              onLoad={handleLoad}
              style={{
                border: 'none',
                padding: '0 0',
                overflowX: 'hidden',
                overflowY: 'auto',
              }}
            ></iframe>
          </SiderLayout>
        )
      }
    }

    return <DashboardHome />
  }

  const renderIntegration = () => {
    if (!enabledIntegrations) {
      return <InitialScreen />
    }

    return (
      <div
        className={'sider-layout dashboard-home-iframe'}
        style={{
          width: '100%',
          height: window.innerHeight - 56,
        }}
      >
        <iframe
          id="DashboardIntegrations"
          title="DashboardIntegrations"
          src={`${FRONT_URL}/integrations/inject`}
          width="100%"
          height="100%"
          style={{
            border: 'none',
            padding: '0 0',
            overflowX: 'hidden',
            overflowY: 'auto',
          }}
        ></iframe>
      </div>
    )
  }

  return (
    <>
      <Spin style={{ paddingTop: 20 }} spinning={isConfigLoading} />
      {!isConfigLoading &&
        (dimensionsList.count <= 0 || (dimensionsList.count > 0 && periodList.length <= 0)) &&
        renderIntegration()}
      {!isConfigLoading &&
        dimensionsList.count > 0 &&
        periodList.length > 0 &&
        (canUserViewDashboard ? (
          renderDash()
        ) : (
          <DashboardGeneral hasDataConfig={dimensionsList.count > 0} />
        ))}
    </>
  )
}

const mapStateToProps = (state) => ({
  dimensionsList: configuration.selectors.getDimensionsList(state),
  periodList: planning.selectors.getPeriodList(state),
  loggedUser: login.selectors.getWhoAmI(state),
  dashboardQuicksight: home.selectors.getDashboardQuicksight(state),
})

const mapDispatchToProps = {
  fetchDimensionsList: configuration.actions.fetchDimensionsList,
  fetchPeriodList: planning.actions.fetchPeriodList,
  fetchEmbedQuicksightUrl: home.actions.fetchEmbedQuicksightUrl,
}

export default connect(mapStateToProps, mapDispatchToProps)(Home)
