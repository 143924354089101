import React from 'react'
import { CardReport } from './components'
import { List } from 'antd'

const CardsReportList = ({
  reportsCardsList,
  reportStructure,
  lineReportId,
  setLineReportId,
  isLinesReportLoading,
}) => {
  return (
    <List
      grid={{ gutter: 24, xs: 1, sm: 2, md: 2, lg: 2, xl: 2, xxl: 2 }}
      style={{ paddingLeft: 12 }}
      dataSource={reportsCardsList}
      className="list-report-line"
      renderItem={(dataList, index) => (
        <CardReport
          dataList={dataList}
          reportStructure={reportStructure}
          lineReportId={lineReportId}
          setLineReportId={setLineReportId}
          isLinesReportLoading={isLinesReportLoading}
          type={`line${index + 1}`}
        />
      )}
    />
  )
}

export default CardsReportList
