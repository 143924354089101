import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Form, Row, Col, notification, Divider, Spin } from 'antd'
import { LoginForm, ConfirmPasswordForm, EmailConfirmForm } from 'modules/login/components'
import { Button } from 'modules/core/components'
import { useTranslation } from 'react-i18next'
import { AssociatedAccountModal, CodeModal, LoginOtherContainer } from './components'
import { tokenutils } from 'modules/login/actions'
import {
  /*GA,*/ generateMessageError,
  renderLoginAndResetFormTitle,
  renderLoginWelcome,
} from 'modules/core/utils'
import { onSubmitChange, onSubmitLogin } from './utils'
import login from 'modules/login'

import './Login.scss'

const Login = ({
  history,
  onLogin,
  onLogout,
  whoAmI,
  confirmPassword,
  loggedUser: { pkid },
  googleCallback,
  fetchLoginOtherAccounts,
  azureCallback,
  sendEmailSelfResetPassword,
}) => {
  const [isLogging, setIsLogging] = useState(false)
  const [showConfirmPasswordForm, setShowConfirmPasswordForm] = useState(false)
  const [showEmailConfirmForm, setShowEmailConfirmForm] = useState(false)
  const [isLoggingOther, setIsLoggingOther] = useState(false)
  const [showAccountsModal, setShowAccountsModal] = useState(false)
  const [showQrModal, setShowQrModal] = useState(false)
  const [form] = Form.useForm()
  const { t } = useTranslation()

  var queryString = window.location.search
  var urlParams = new URLSearchParams(queryString)
  var code = urlParams.get('code')
  var error = urlParams.get('error')
  var state = urlParams.get('state')

  useEffect(() => {
    return () => {
      setShowEmailConfirmForm(false)
      setShowConfirmPasswordForm(false)
      setIsLogging(false)
      setIsLoggingOther(false)
      setShowAccountsModal(false)
    }
  }, [])

  useEffect(() => {
    if (code || state || error) {
      setIsLoggingOther(true)
      const actionAux = () =>
        code && state ? googleCallback({ code, error, state }) : azureCallback({ code })
      whoAmI().then((resp) => {
        if (resp.error.response.status === 401) {
          tokenutils.removeToken()
        }
        actionAux()
          .then((response) => {
            tokenutils.saveTokens(response.payload.data)
            fetchLoginOtherAccounts().then((responseAccount) => {
              if (responseAccount.payload.data.length > 1) {
                setShowAccountsModal(true)
              } else {
                setShowQrModal(true)
              }
            })
          })
          .catch((error) => {
            notification.error({
              message: t('LOGIN_USER_NOT_FOUND_FEEDBACK_TITLE'),
              description: generateMessageError(error, 'LOGIN_USER_NOT_FOUND_FEEDBACK_DESCRIPTION'),
              duration: 0,
            })
            setIsLoggingOther(false)
          })
      })
    }
    // eslint-disable-next-line
  }, [googleCallback, code, error, state])

  const onConfirm = () => {
    form.resetFields()
    setShowConfirmPasswordForm(true)
    setIsLogging(false)
  }

  const renderConfirmOrLoginForm = () => (
    <>
      {renderLoginAndResetFormTitle({
        title: showConfirmPasswordForm ? 'LOGIN_FORM_TITLE_CREATE_PASSWORD' : 'LOGIN_FORM_TITLE',
        level: 6,
        align: 'center',
      })}
      {showConfirmPasswordForm ? (
        <ConfirmPasswordForm
          form={form}
          onFinish={(values) =>
            onSubmitChange({
              setIsLoading: setIsLogging,
              confirmPassword: () => confirmPassword(pkid, values),
              onLogout,
              onClose: () => setIsLogging(false),
              onRedirect: () => history.push('/login'),
            })
          }
          loading={isLogging}
        />
      ) : (
        <LoginForm
          form={form}
          onFinish={(values) =>
            onSubmitLogin({
              setIsLoading: setIsLogging,
              onLogin: () => onLogin({ email: values.email, password: values.password }),
              whoAmI,
              onConfirm,
              onRedirect: () => history.push('/login'),
              onShowCode: () => setShowQrModal(true),
              email: values.email,
              sendEmailSelfResetPassword: () => sendEmailSelfResetPassword({ email: values.email }),
            })
          }
          loading={isLogging}
        />
      )}
    </>
  )

  const renderEmailConfirmForm = () => (
    <>
      {renderLoginAndResetFormTitle({
        title: 'LOGIN_FORM_RESET_PASSWORD_TITLE',
        level: 6,
        align: 'center',
      })}
      <EmailConfirmForm />
    </>
  )

  const renderForgotPasswordButton = () => (
    <div className="forgot-password-container">
      <Button.Link
        onClick={() => setShowEmailConfirmForm(!showEmailConfirmForm)}
        title={
          !showEmailConfirmForm ? 'LOGIN_FORGOT_PASSWORD_ACTION' : 'LOGIN_GO_BACK_SESSION_ACTION'
        }
      />
    </div>
  )

  return (
    <>
      <Spin spinning={isLoggingOther}>
        <div className="plika-login-container">
          <Row>
            <Col span={10} offset={1} className="brand-hero">
              {renderLoginWelcome()}
            </Col>
            <Col span={10} offset={3} className="login-form-container">
              <div className={showConfirmPasswordForm ? 'signin-sector' : 'login-sector'}>
                {!showEmailConfirmForm ? renderConfirmOrLoginForm() : renderEmailConfirmForm()}
                {!showConfirmPasswordForm && (
                  <>
                    {renderForgotPasswordButton()}
                    <Divider plain>{t('LABEL_OR')}</Divider>
                    <LoginOtherContainer />
                    <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                      <Button.Link
                        title="SECURITY_POLICY_TERMS_BUTTON_TITLE"
                        onClick={() => window.open('/politicayterminos', '_blank')}
                        style={{ marginTop: 15 }}
                      />
                    </div>
                  </>
                )}
              </div>
            </Col>
          </Row>
        </div>
      </Spin>
      <AssociatedAccountModal
        visible={showAccountsModal}
        onCancel={() => {
          setShowAccountsModal(false)
          setIsLoggingOther(false)
        }}
      />
      <CodeModal visible={showQrModal} onCancel={() => setShowQrModal(false)} />
    </>
  )
}

const mapStateToProps = (state) => ({
  loggedUser: login.selectors.getWhoAmI(state),
})

const mapDispatchToProps = {
  onLogin: login.actions.onLogin,
  onLogout: login.actions.onLogout,
  whoAmI: login.actions.whoAmI,
  confirmPassword: login.actions.confirmPassword,
  googleCallback: login.actions.googleCallback,
  fetchLoginOtherAccounts: login.actions.fetchLoginOtherAccounts,
  azureCallback: login.actions.azureCallback,
  sendEmailSelfResetPassword: login.actions.sendEmailSelfResetPassword,
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)
