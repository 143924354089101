import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { Space, Popover, Divider, Input, Typography as TypographyAntd, Tooltip } from 'antd'
import { DeleteOutlined, CheckOutlined, EditOutlined } from '@ant-design/icons'
import { Button, ButtonActionBar, DropdownMenu, Typography } from 'modules/core/components'
import { connect } from 'react-redux'
import login from 'modules/login'

const { TextArea } = Input
const { Paragraph } = TypographyAntd

const CommentPopoverContent = ({
  data = [],
  setVisible,
  onComment = null,
  onDelete = null,
  onEdit = null,
  email,
  // onResolve = null,
}) => {
  const [newCommentText, setNewCommentText] = useState('')
  const [cellEditId, setCellEditId] = useState(null)
  const { t } = useTranslation()

  const renderAuthor = ({ author, date }) => {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', marginRight: 8 }}>
        <Typography.Subtitle style={{ display: 'block', width: 180, wordWrap: 'break-word' }}>
          {author}
        </Typography.Subtitle>
        <Typography.Body level={3}>{`${moment(date).calendar()}`}</Typography.Body>
      </div>
    )
  }

  const renderComentIcon = ({ id, showResolve, author, text }) => {
    return (
      <Space size={2}>
        {/* TODO: No se muestra hasta que este implementado desde backend */}
        {false && showResolve && (
          <Tooltip title={t('TOOLTIP_CHECK_SOLVED_TITLE')}>
            <Button.Icon
              shape="circle"
              icon={<Typography.Icon level={2} icon={CheckOutlined} />}
              // onClick={() => onResolve(id)}
            />
          </Tooltip>
        )}
        {(onDelete !== null || onEdit !== null) && author === email && (
          <DropdownMenu
            title={t('ACTION_MORE')}
            menu={[
              {
                title: t('ACTION_EDIT'),
                icon: <EditOutlined />,
                onClick: () => {
                  setCellEditId(id)
                  setNewCommentText(text)
                },
              },
              {
                title: t('ACTION_DELETE'),
                icon: <DeleteOutlined />,
                onClick: () => onDelete(id),
              },
            ]}
          />
        )}
      </Space>
    )
  }

  const renderComment = ({
    comment: { id, author, created_on: date, text },
    isFirstComment,
    key,
  }) => (
    <Space key={id} direction="vertical" style={{ width: '100%' }}>
      <ButtonActionBar position="space-between">
        {renderAuthor({ author, date })}
        {/* FIXME: Hacer validación para que estos iconos los pueda ver solo el usuario que hizo el comentario. */}
        {renderComentIcon({ id, showResolve: isFirstComment, author, text })}
      </ButtonActionBar>
      {cellEditId === id ? (
        renderReplyArea(key, id)
      ) : (
        <Paragraph
          ellipsis={{
            rows: 3,
            expandable: true,
            symbol: t('ACTION_READ_MORE'),
          }}
          key={key}
        >
          {text}
        </Paragraph>
      )}
      <Divider style={{ marginTop: 10, marginBottom: 10 }} />
    </Space>
  )

  const renderReplyArea = (key, id = null) =>
    (onComment !== null || onEdit !== null) && (
      <>
        <TextArea
          defaultValue={newCommentText}
          key={key}
          placeholder={t('WRITE_COMMENT_PLACEHOLDER')}
          onChange={(value) => setNewCommentText(value.target.value)}
        />
        <ButtonActionBar>
          <Button.Default
            onClick={() => {
              setVisible(false)
              setNewCommentText('')
              setCellEditId(null)
            }}
            title="ACTION_CANCEL"
          />
          <Button.Primary
            onClick={() => {
              if (cellEditId) {
                onEdit(id, newCommentText)
              } else {
                onComment(newCommentText)
              }
              setNewCommentText('')
              setCellEditId(null)
            }}
            disabled={!newCommentText}
            title={cellEditId ? 'ACTION_EDIT' : 'ACTION_COMMENT'}
          />
        </ButtonActionBar>
      </>
    )

  return (
    <Space
      direction="vertical"
      style={{
        paddingRight: 12,
        marginRight: -12,
        marginLeft: -8,
        width: 260,
        maxHeight: 300,
        overflowY: 'auto',
      }}
    >
      {data.length > 0 &&
        data.map((comment, index) =>
          renderComment({
            comment,
            isFirstComment: index === 0,
            key: index,
          }),
        )}
      {!cellEditId && renderReplyArea(data.length)}
    </Space>
  )
}

const CommentPopover = ({
  visible,
  onVisibleChange,
  data = [],
  onComment,
  onDelete,
  onEdit,
  children,
  loggedUser: { email },
}) => {
  return (
    <Popover
      visible={visible}
      trigger="click"
      onVisibleChange={(visible) => onVisibleChange(visible)}
      content={
        <CommentPopoverContent
          setVisible={onVisibleChange}
          data={data}
          onComment={onComment}
          onDelete={onDelete}
          onEdit={onEdit}
          email={email}
        />
      }
    >
      {children}
    </Popover>
  )
}

const mapStateToProps = (state) => ({
  loggedUser: login.selectors.getWhoAmI(state),
})

export default connect(mapStateToProps, null)(CommentPopover)
