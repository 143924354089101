import React, { useEffect, useRef, useState } from 'react'
import { Card } from 'antd'
import { LineChart } from 'modules/core/components'
import { CHARTS } from 'modules/core/constants'
import { connect } from 'react-redux'
import numeral from 'numeral'
import home from 'modules/home'

//NOTE: VER LOS COLORES CUANDO ESTE CONECTADO
const COLORS_BY_BUDGET_TYPE = {
  1: CHARTS.COLORS_SALES,
  2: CHARTS.COLORS_EXPENSES,
  3: CHARTS.COLORS_COSTS,
  4: CHARTS.COLORS_HUMAN_RESOURCES,
  5: CHARTS.COLORS_OTHER_5,
  6: CHARTS.COLORS_OTHER_6,
}

const LineChartFilters = ({
  periodId,
  budgetTypeId,
  fetchDataBudgetTypeChart,
  dataBudgetTypeChart,
}) => {
  const [chartLoading, setChartLoading] = useState(false)
  const chartRef = useRef(null)

  useEffect(() => {
    if (periodId && budgetTypeId.lineChart) {
      setChartLoading(true)
      fetchDataBudgetTypeChart({ period_id: periodId, ppto_id: budgetTypeId.lineChart }).then(() =>
        setChartLoading(false),
      )
    }
    return () => setChartLoading(false)
  }, [fetchDataBudgetTypeChart, periodId, budgetTypeId])

  return (
    <div ref={chartRef}>
      <Card headStyle={{ height: 56 }} bodyStyle={{ padding: 0 }} loading={chartLoading}>
        <LineChart
          containerHeight={300}
          data={dataBudgetTypeChart.asMutable({ deep: true })}
          colors={COLORS_BY_BUDGET_TYPE[budgetTypeId.lineChart]}
          axisLeft={{
            format: (value) => `$ ${numeral(value).format('0,0[.]0 a')}`,
          }}
          margin={{ right: 130 }}
          value={dataBudgetTypeChart.asMutable({ deep: true }).map((val) => val)}
        />
      </Card>
    </div>
  )
}

const mapStateToProps = (state) => ({
  dataBudgetTypeChart: home.selectors.getDataBudgetTypeChart(state),
})

const mapDispatchToProps = {
  fetchDataBudgetTypeChart: home.actions.fetchDataBudgetTypeChart,
}

export default connect(mapStateToProps, mapDispatchToProps)(LineChartFilters)
