import { notification } from 'antd'
import { tokenutils } from 'modules/login/actions'
import { generateMessageError } from 'modules/core/utils'
import i18n from 'i18next'

export const onReset = ({ form, setIsLoading, resetPassword, history, onClose }) => {
  form.validateFields().then((values) => {
    setIsLoading(true)
    resetPassword(values)
      .then(() => {
        notification.success({
          message: i18n.t('FEEDBACK_VERY_GOOD'),
          key: 'close',
          description: (
            <>
              <span>{i18n.t('LOGIN_RESET_PASSWORD_FEEDBACK_SUCCESS_DESCRIPTION_1')}</span>
              <span
                className="link-go-to-login"
                onClick={() => {
                  history.push(`/login`)
                  notification.close('close')
                }}
              >
                {i18n.t('LOGIN')}
              </span>
              <span>{i18n.t('LOGIN_RESET_PASSWORD_FEEDBACK_SUCCESS_DESCRIPTION_2')}</span>
            </>
          ),
          duration: 0,
        })
        tokenutils.removeToken()
        onClose()
      })
      .catch((error) => {
        notification.error({
          message: i18n.t('FEEDBACK_DEFAULT_ERROR'),
          description: generateMessageError(error, 'FEEDBACK_CONFIRM_ERROR_DESCRIPTION'),
          duration: 0,
        })
        tokenutils.removeToken()
        onClose()
      })
  })
}
