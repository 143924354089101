import { NAME } from './constants'
import { createSelector } from 'reselect'
import { COLUMNS } from 'modules/core/constants'
import moment from 'moment'
import _ from 'lodash'

export const getControlListDefult = (state) => state[NAME].controlList
export const getDataTable = (state) => state[NAME].dataTable
export const getRealColumnsRaw = (state) => state[NAME].realColumns

export const getControlList = createSelector(getControlListDefult, (controlList) => {
  return {
    ...controlList,
    values: controlList?.values.map((el) => {
      return {
        ...el,
        line_chart: el?.line_chart
          ? el?.line_chart.map((item) => {
              return {
                ...item,
                data: item.data.map((op) => {
                  return {
                    y: op.y,
                    x: moment(op.x, 'YYYY-M').format('MMM'),
                  }
                }),
              }
            })
          : [],
        ranking_down_chart: el?.ranking_down_chart
          ? el?.ranking_down_chart.map((el) => {
              const key = _.keys(el).filter((f) => f !== 'dimension')[0]
              return {
                ...el,
                [key]: Math.round(el[key] * 100),
              }
            })
          : [],
        ranking_up_chart: el?.ranking_up_chart
          ? el?.ranking_up_chart.map((el) => {
              const key = _.keys(el).filter((f) => f !== 'dimension')[0]
              return {
                ...el,
                [key]: Math.round(el[key] * 100),
              }
            })
          : [],
      }
    }),
  }
})

export const getRealColumns = createSelector(getRealColumnsRaw, (realColumnsRaw) =>
  realColumnsRaw.map((column) => {
    if (COLUMNS.ALL_TRANSACTION.includes(column.data_index)) {
      return {
        ...column,
        width: COLUMNS.WIDTH.TRANSACTION,
        is_transaction: true,
      }
    }
    return {
      ...column,
      width:
        column.data_index === COLUMNS.TOTAL_DATA_INDEX
          ? COLUMNS.WIDTH.TRANSACTION
          : COLUMNS.WIDTH.DIMENSION,
      is_transaction: false,
    }
  }),
)

export const getRealRows = (state) => state[NAME].realRows
export const getControlDetailKpis = (state) => state[NAME].controlDetailKPIS
export const getMonthsToPublish = (state) => state[NAME].monthsToPublish
export const getMonthsToApprove = (state) => state[NAME].monthsToApprove
export const getApproveDates = (state) => state[NAME].approveDates
export const getPublishedMonths = (state) => state[NAME].publishedMonths
export const getLegalData = (state) => state[NAME].legalData
export const getDataTableAntv = (state) => state[NAME].dataTableAntv
export const getLayoutList = (state) => state[NAME].layoutList
