import React from 'react'
import { Alert, Form, Input, Space } from 'antd'
import { useTranslation } from 'react-i18next'

const InputLink = ({ form, extraBody = null }) => {
  const { t } = useTranslation()
  return (
    <Space direction="vertical">
      <Alert
        showIcon
        closable
        type="info"
        description={t('HOME_INITIAL_STEPS_UPLOAD_DATA_SPREADSHEETS_INFORMATIVE_ALERT_DESCRPTION')}
      />
      {extraBody}
      <Form form={form} layout="vertical">
        <Form.Item
          label="Link spreadsheet"
          name="link"
          rules={[{ required: true, message: t('REQUIRED_FIELD') }]}
        >
          <Input placeholder={t('INTEGRATIONS_LINK_SPREADSHEET_INPUT_PLACEHOLDER')} />
        </Form.Item>
      </Form>
    </Space>
  )
}

export default InputLink
