import React from 'react'
import { List, Tooltip } from 'antd'
import { Button, ButtonActionBar, ListCard, Typography } from 'modules/core/components'
import { REPORT, ROLES } from 'modules/core/constants'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import { RightCircleOutlined, HolderOutlined } from '@ant-design/icons'
import { isUserAllowed } from 'modules/core/utils'
import { SortableContext, rectSortingStrategy } from '@dnd-kit/sortable'
import { DragOverlay } from '@dnd-kit/core'

const { SETTINGS__REPORTS__REPORTSPNL__ABM } = ROLES

const CardList = ({
  loading,
  dataSource,
  activeId,
  setDataComments,
  dataComments,
  onRefetchComments,
  loadingComments,
}) => {
  const { t } = useTranslation()
  let history = useHistory()
  let { periodId } = useParams()

  const canUserCreate = isUserAllowed(SETTINGS__REPORTS__REPORTSPNL__ABM)

  const findCard = activeId && dataSource.find((el) => el.id === activeId)

  return (
    <>
      <SortableContext items={dataSource} strategy={rectSortingStrategy}>
        <List
          loading={loading}
          grid={{ gutter: 24, xs: 1, sm: 1, md: 1, lg: 1, xl: 3, xxl: 3 }}
          dataSource={dataSource}
          renderItem={(pnl) => {
            const { id, name, has_data, total, is_financial } = pnl

            return (
              <List.Item>
                {!loading && (
                  <ListCard
                    title={name}
                    type={REPORT.REPORTS_KEYS.PNL_KEY}
                    projectionId={id}
                    periodId={periodId}
                    description={t('REPORT_CARD_TITLE_NO_DATA')}
                    hasData={has_data}
                    extra={<Typography.Icon title={t('ACTION_SORT')} icon={HolderOutlined} />}
                    actions={[
                      <ButtonActionBar position="start">
                        <Tooltip title={!canUserCreate ? t('ALERT_NOT_ALLOWED') : ''}>
                          <Button.LinkWhitRightIcon
                            disabled={!has_data}
                            onClick={() =>
                              history.push(`/analisis/${periodId}/reportesplika/${id}`)
                            }
                            title="ACTION_ENTER"
                            icon={<RightCircleOutlined />}
                          />
                        </Tooltip>
                      </ButtonActionBar>,
                    ]}
                    emptyState={{ description: t('REPORT_CARD_TITLE_NO_DATA') }}
                    dataKpi={total}
                    reportType={
                      is_financial === true ? t('CONFIG_TEXT_FINANCIAL') : t('CONFIG_TEXT_ECONOMIC')
                    }
                    colorTag={is_financial === true ? 'red' : 'blue'}
                    setDataComments={setDataComments}
                    dataComments={dataComments}
                    onRefetchComments={onRefetchComments}
                    loadingComments={loadingComments}
                  />
                )}
              </List.Item>
            )
          }}
        />
      </SortableContext>
      <DragOverlay adjustScale>
        {activeId ? (
          <ListCard
            title={findCard.name}
            type={REPORT.REPORTS_KEYS.PNL_KEY}
            projectionId={activeId}
            periodId={periodId}
            description={t('REPORT_CARD_TITLE_NO_DATA')}
            hasData={findCard.has_data}
            extra={<Typography.Icon icon={HolderOutlined} />}
            actions={[
              <ButtonActionBar position="start">
                <Tooltip title={!canUserCreate ? t('ALERT_NOT_ALLOWED') : ''}>
                  <Button.LinkWhitRightIcon
                    disabled={!findCard.has_data}
                    onClick={() =>
                      history.push(`/analisis/${periodId}/reportesplika/${findCard.id}`)
                    }
                    title="ACTION_ENTER"
                    icon={<RightCircleOutlined />}
                  />
                </Tooltip>
              </ButtonActionBar>,
            ]}
            emptyState={{ description: t('REPORT_CARD_TITLE_NO_DATA') }}
            dataKpi={findCard.total}
            reportType={
              findCard.is_financial === true
                ? t('CONFIG_TEXT_FINANCIAL')
                : t('CONFIG_TEXT_ECONOMIC')
            }
            colorTag={findCard.is_financial === true ? 'red' : 'blue'}
            setDataComments={setDataComments}
            dataComments={dataComments}
            onRefetchComments={onRefetchComments}
            loadingComments={loadingComments}
          />
        ) : null}
      </DragOverlay>
    </>
  )
}

export default CardList
