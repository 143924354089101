import React from 'react'
import { Input } from 'antd'
import { Button, ButtonActionBar, DropdownMenu } from 'modules/core/components'
import { SolutionOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'

const { Search } = Input

const UserActions = ({ setSearchValue, onClickUpload, disabled, onClickActivity }) => {
  const { t } = useTranslation()
  return (
    <ButtonActionBar>
      <Search
        className="configuration-reports-detail-search"
        placeholder={t('ACTION_SEARCH')}
        allowClear={true}
        onSearch={setSearchValue}
      />
      <Button.Primary onClick={onClickUpload} disabled={disabled} title="ACTION_UPLOAD_FILE" />
      <DropdownMenu
        title={t('ACTION_MORE')}
        menu={[
          {
            title: t('ACTION_ACTIVITIES'),
            icon: <SolutionOutlined />,
            onClick: onClickActivity,
          },
        ]}
      />
    </ButtonActionBar>
  )
}

export default UserActions
