import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Select, Form, Modal, Space, message, Tooltip, Spin } from 'antd'
import { Typography } from 'modules/core/components'
import { connect } from 'react-redux'
import { generateMessageError, generateSelectOptions, isUserAllowed } from 'modules/core/utils'
import { ROLES } from 'modules/core/constants'
import configuration from 'modules/configuration'

const { SETTINGS__BUDGET_TYPE__VIEW } = ROLES //REVEER ROLES CUANDO ESTEN LOS PERMISOS DE LA PARTE FINANCIERA Y ECONOMICA

const AssociateDimensionModal = ({
  budgetTypeId,
  optionsDimension: { dimensions },
  fetchDimensionsList,
  createBudgetTypeAssociation,
  refreshData,
  selectedDimensions,
}) => {
  const [showModal, setShowModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isDimensionsLoading, setIsDimensionsLoading] = useState(false)
  const { t } = useTranslation()
  const [form] = Form.useForm()

  useEffect(() => {
    setIsDimensionsLoading(true)
    fetchDimensionsList({ flat: 'True', writeable: 'True' }).then(() =>
      setIsDimensionsLoading(false),
    )
  }, [fetchDimensionsList])

  const onClose = () => {
    form.resetFields()
    setShowModal(false)
  }

  const onConfirm = () => {
    form
      .validateFields()
      .then((values) => {
        setIsLoading(true)
        const data = {
          dimension: values.dimension,
          concept: budgetTypeId,
        }
        createBudgetTypeAssociation(data)
          .then(() => {
            message.success(t('FEEDBACK_CHANGES_SAVED_SUCCESS'), 8)
            onClose()
            refreshData()
            setIsLoading(false)
          })
          .catch((error) => {
            message.error(generateMessageError(error, 'FEEDBACK_SAVE_CHANGES_FAIL'), 8)
            onClose()
            setIsLoading(false)
          })
      })
      .catch((e) => {})
  }

  let filterData = []
  // dimensions.filter(
  //   dimension => !selectedDimensions.includes(dimension.id)
  // );
  return (
    <>
      <Tooltip
        title={
          !isDimensionsLoading && filterData.length === 0
            ? t('CONFIG_CONCEPTS_EMPTY_DIENSIONS_TITLE')
            : ''
        }
      >
        <Button
          type="primary"
          disabled={
            (!isDimensionsLoading && filterData.length === 0) ||
            !isUserAllowed(SETTINGS__BUDGET_TYPE__VIEW)
          }
          onClick={() => setShowModal(true)}
        >
          {t('CONFIG_CONCEPT_ASSOCIATE_DIMENSION_ACTION')}
        </Button>
      </Tooltip>
      <Modal
        title={t('CONFIG_CONCEPT_ASSOCIATE_DIMENSION_MODAL_TITLE')}
        visible={showModal}
        centered
        width={570}
        okText={t('CONFIG_CONCEPT_ASSOCIATE_DIMENSION_ACTION')}
        okButtonProps={{ loading: isLoading }}
        cancelText={t('ACTION_CANCEL')}
        onOk={onConfirm}
        onCancel={onClose}
      >
        <Spin spinning={isDimensionsLoading}>
          <Space direction="vertical" size="middle">
            <Typography.Body level={2}>
              {t('CONFIG_CONCEPT_ASSOCIATE_MODAL_SELECT_LABEL')}
            </Typography.Body>
            <Form form={form} layout="vertical">
              <Form.Item
                name="dimension"
                label={t('CONFIG_CONCEPT_ASSOCIATE_TO_DIMENSION_FIELD')}
                rules={[{ required: true, message: t('REQUIRED_FIELD') }]}
              >
                <Select
                  showArrow
                  showSearch={true}
                  placeholder={t('ACTION_SELECT')}
                  options={generateSelectOptions({ options: filterData })}
                />
              </Form.Item>
            </Form>
          </Space>
        </Spin>
      </Modal>
    </>
  )
}

const mapStateToProps = (state) => ({
  optionsDimension: configuration.selectors.getDimensionsList(state),
})

const mapDispatchToProps = {
  fetchDimensionsList: configuration.actions.fetchDimensionsList,
  createBudgetTypeAssociation: configuration.actions.createBudgetTypeAssociation,
}

export default connect(mapStateToProps, mapDispatchToProps)(AssociateDimensionModal)
