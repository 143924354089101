import { useFeatureValue } from '@growthbook/growthbook-react'
import React, { useState } from 'react'
import { Modal, Form, Input, Row, Col, Tooltip } from 'antd'
import { useTranslation } from 'react-i18next'
import {
  // isInElaboration,
  // isInitiated,
  // isPublished,
  isUserAllowed,
} from 'modules/core/utils'
import /* PROJECTION,*/ 'modules/core/constants'
import { Button } from 'modules/core/components'
import { globalProjectionCreation, isAvailable } from './utils'
import { FormList } from './components'

const FormItem = Form.Item

const CreateGlobalProjectionModal = ({
  periodId,
  text = '',
  title,
  globalText,
  role,
  onFetchGlobalBudgetList,
  createGlobalProjection,
  globalBudgetApproved = [],
  type,
  fetchProjectionInstanceList,
  projectionInstanceList,
  module = 'forecast',
}) => {
  const actionCrudByRole = useFeatureValue('feature-action-crud-role', {
    planning: 'True',
    forecast: 'True',
  })
  const [showModal, setShowModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [selectedBudgets, setSelectedBudgets] = useState([])
  const [conceptId, setConceptId] = useState(null)
  const [form] = Form.useForm()
  const { t } = useTranslation()

  const onClose = () => {
    form.resetFields()
    setShowModal(false)
    setIsLoading(false)
    setSelectedBudgets([])
    setConceptId(null)
  }

  const getUserCanCreate = () => {
    const canCreate = isUserAllowed(role)

    if (canCreate) {
      switch (module) {
        case 'planning':
        case 'planning_global':
          return actionCrudByRole.planning === 'True'
        case 'forecast':
        case 'forecast_global':
          return actionCrudByRole.forecast === 'True'
        default:
          return true
      }
    }
    return canCreate
  }

  const userCanCreate = getUserCanCreate()

  return (
    <>
      <Tooltip
        title={
          globalBudgetApproved.length > 0 ? t('PLANNING_DISABLED_CREATION_TOOLTIP') : ''
          // //NOTE: DESHBAILITAR SI NO ESTAN EN ESTADO CIERRE
          // ? t('PLANNING_GLOBAL_BUDGET_NOT_DATA_BUDGET_TOOLTIP_TITLE')
          // : ''
        }
      >
        <Button.Primary
          onClick={() => setShowModal(true)}
          disabled={
            !userCanCreate || globalBudgetApproved.length > 0
            //||
            //NOTE: DESHBAILITAR SI NO ESTAN EN ESTADO CIERRE
          }
          title={title}
        />
      </Tooltip>
      <Modal
        title={title}
        centered
        visible={showModal}
        destroyOnClose={true}
        bodyStyle={{ overflowY: 'auto', maxHeight: 500 }}
        onCancel={onClose}
        onOk={() =>
          globalProjectionCreation({
            form,
            setIsLoading,
            createGlobalProjection: (values) => {
              return createGlobalProjection({
                name: values.name,
                period_id: periodId,
                ...(type === 'forecast'
                  ? {
                      forecast_overall_budget: values.overall_budget,
                    }
                  : {
                      overall_budget: values.overall_budget,
                    }),
                ...(type === 'planning' && { period_id: periodId }),
              })
            },
            onRefetch: onFetchGlobalBudgetList,
            onClose,
          })
        }
        okText={t('ACTION_CREATE')}
        cancelText={t('ACTION_CANCEL')}
        okButtonProps={{ loading: isLoading, disabled: !isAvailable(form) }}
        cancelButtonProps={{ disabled: isLoading }}
        forceRender
      >
        <Form form={form} layout="vertical" initialValues={{ overall_budget: [''] }}>
          {() => (
            <Row gutter={24}>
              <Col span={24}>
                <FormItem
                  name="name"
                  label={t('FIELD_NAME')}
                  rules={[
                    { required: true, message: t('REQUIRED_FIELD') },
                    {
                      max: 80,
                      message: t(' REQUIRED_FIELD_CHARACTERS', { cant: 80 }),
                    },
                  ]}
                >
                  <Input placeholder={t('PLANNING_GLOBAL_BUDGET_CREATE_FORM_INPUT_PLACEHOLDER')} />
                </FormItem>
              </Col>
              <Col span={24}>
                <FormItem
                  name="overall_budget"
                  label={t(
                    'PLANNING_GLOBAL_BUDGET_CREATE_NEW_CHOOSE_TYPE_BUDGET_FORM_SELECT_LABEL',
                    { projection: t(globalText).toLowerCase() },
                  )}
                >
                  <FormList
                    form={form}
                    isLoading={isLoading}
                    selectedBudgets={selectedBudgets}
                    setSelectedBudgets={setSelectedBudgets}
                    conceptId={conceptId}
                    setConceptId={setConceptId}
                    fetchProjectionInstanceList={fetchProjectionInstanceList}
                    projectionInstanceList={projectionInstanceList}
                    type={type}
                  />
                </FormItem>
              </Col>
            </Row>
          )}
        </Form>
      </Modal>
    </>
  )
}

export default CreateGlobalProjectionModal
