import { Popover, Tooltip, Typography as TypographyAnt } from 'antd'
import { ROLES } from 'modules/core/constants'
import { getOperationsByKeys, isApproved, isPublished, isUserAllowed } from 'modules/core/utils'
import { useTranslation } from 'react-i18next'
import { ButtonFix } from './components'

const { PLANNING__GLOBAL_BUDGET__ABM, FORECAST__GLOBAL_FORECAST__ABM } = ROLES

const ContainerButtonFix = ({ isFixed, setFixed, status }) => {
  const { t } = useTranslation()
  return (
    ((setFixed && isPublished(status)) || (isFixed && isApproved(status))) &&
    isUserAllowed(
      getOperationsByKeys([PLANNING__GLOBAL_BUDGET__ABM, FORECAST__GLOBAL_FORECAST__ABM]),
    ) &&
    (isFixed ? (
      <Popover
        placement="bottom"
        title={t('FIELD_PREDETERMINED_BUDGET')}
        content={
          <TypographyAnt.Paragraph style={{ width: 300 }}>
            {t('PLANNING_GLOBAL_BUDGET_FIXED_POPOVER_TEXT')}
          </TypographyAnt.Paragraph>
        }
      >
        <ButtonFix isFixed={isFixed} setFixed={setFixed} />
      </Popover>
    ) : (
      <Tooltip title={t('PLANNING_GLOBAL_BUDGET_INFORMATIVE_TOOLTIP_TITLE')}>
        <ButtonFix isFixed={isFixed} setFixed={setFixed} />
      </Tooltip>
    ))
  )
}

export default ContainerButtonFix
