// NOTE: Las labels se obtienen de los archivos de traduccion (i18n).
// NOTE: Para el armado de las KEYS se va a utilizar la estructura MODULO/SUBMODULO/OPERACION, concatenandolo con el caracter '/'. Por lo tanto es recomendable que no se utilice '/' en la definicion de alguna KEY.

const KEY_SEPARATOR = '/'
// /\//g
const OBJECT_SEPARATOR_REGEX = new RegExp(KEY_SEPARATOR, 'g')
// NOTE: Fn que reemplaza keys para el objeto
// OUTPUT: MODULE/SUBMODULE/OP >> MODULE__SUBMODULE__OP
const replaceObjectKey = (key) => key.replace(OBJECT_SEPARATOR_REGEX, '__')

// +++++++++++++++++++++++++++++++++
// +++++++ SETUP OPERACIONES +++++++
// +++++++++++++++++++++++++++++++++

// NOTE: Se variabilizan las KEYs de cada OPERACION y se arman estructuras para ser reutilizadas.

// ++ OPERACIONES ++
const VIEW = { key: 'VIEW', label: 'ACTION_VIEW' }
const ABM = { key: 'ABM', label: 'ABM' }
const PUBLISH = { key: 'PUBLISH', label: 'ACTION_PUBLISH' }
const BASE_OPERATION_OPTIONS = [ABM, VIEW]

const PLANNING_BASE_OPERATION_OPTIONS = [...BASE_OPERATION_OPTIONS]

const CONTROL_BASE_OPERATION_OPTIONS = [...BASE_OPERATION_OPTIONS]

const FORECAST_BASE_OPERATIONS_OPTIONS = [...BASE_OPERATION_OPTIONS]

// +++++++++++++++++++++++++++++
// +++++++ SETUP MODULOS +++++++
// +++++++++++++++++++++++++++++

// NOTE: Se variabilizan las KEYs de cada MODULO y sus correspondientes SUBMODULOS.

// ++ MODULO: SETTINGS ++
const SETTINGS = 'SETTINGS'
const SETTINGS_LABEL = 'LABEL_CONFIG'

const BUDGET_TYPE = 'BUDGET_TYPE'
const BUDGET_TYPE_LABEL = 'LABEL_BUDGET_TYPE'

const DIMENSIONS = 'DIMENSIONS'
const DIMENSIONS_LABEL = 'LABEL_DIMENSIONS'

const PROJECTION_ELEMENTS = 'PROJECTION_ELEMENTS'
const PROJECTION_ELEMENTS_LABEL = 'LABEL_TOOLS'

const BUSINESS_RULES = 'BUSINESS_RULES'
const BUSINESS_RULES_LABEL = 'LABEL_BUSINESS_RULES'

const VARIABLES = 'VARIABLES'
const VARIABLES_LABEL = 'LABEL_VARIABLES'

const CURRENCY = 'CURRENCY'
const CURRENCY_LABEL = 'LABEL_CURRENCY'

const REPORTS = 'REPORTS'
const REPORTS_LABEL = 'LABEL_REPORTS'

const REPORTS_PNL = 'REPORTSPNL'
const REPORTS_PNL_LABEL = 'LABEL_REPORTS'

// const CUSTOM_REPORTS = 'CUSTOM_REPORTS';
// const CUSTOM_REPORTS_LABEL = 'LABEL_CUSTOM_REPORTS';

const USERS = 'USERS'
const USERS_LABEL = 'LABEL_USERS'

const ROLES = 'ROLES'
const ROLES_LABEL = 'LABEL_ROLES'

const SECURITY = 'SECURITY'
const SECURITY_LABEL = 'LABEL_SECURITY'

const SETTINGS_MODULE = {
  key: SETTINGS,
  label: SETTINGS_LABEL,
  children: [
    {
      key: BUDGET_TYPE,
      label: BUDGET_TYPE_LABEL,
      operations: BASE_OPERATION_OPTIONS,
    },
    {
      key: DIMENSIONS,
      label: DIMENSIONS_LABEL,
      operations: BASE_OPERATION_OPTIONS,
    },
    {
      key: PROJECTION_ELEMENTS,
      label: PROJECTION_ELEMENTS_LABEL,
      children: [
        {
          key: BUSINESS_RULES,
          label: BUSINESS_RULES_LABEL,
          operations: BASE_OPERATION_OPTIONS,
        },
        {
          key: VARIABLES,
          label: VARIABLES_LABEL,
          operations: BASE_OPERATION_OPTIONS,
        },
        {
          key: CURRENCY,
          label: CURRENCY_LABEL,
          operations: BASE_OPERATION_OPTIONS,
        },
      ],
    },
    {
      key: REPORTS,
      label: REPORTS_LABEL,
      children: [
        {
          key: REPORTS_PNL,
          label: REPORTS_PNL_LABEL,
          operations: BASE_OPERATION_OPTIONS,
        },
        // {
        //   key: CUSTOM_REPORTS,
        //   label: CUSTOM_REPORTS_LABEL,
        //   operations: BASE_OPERATION_OPTIONS,
        // },
      ],
    },
    {
      key: SECURITY,
      label: SECURITY_LABEL,
      children: [
        { key: USERS, label: USERS_LABEL, operations: BASE_OPERATION_OPTIONS },
        { key: ROLES, label: ROLES_LABEL, operations: BASE_OPERATION_OPTIONS },
      ],
    },
  ],
}

// ++ MODULO: PLANNING ++
const PLANNING = 'PLANNING'
const PLANNING_LABEL = 'LABEL_PLANNING'

const SALES = 'SALES'
const SALES_LABEL = 'LABEL_SALES'

const EXPENSES = 'EXPENSES'
const EXPENSES_LABEL = 'LABEL_EXPENSES'

const COSTS = 'COSTS'
const COSTS_LABEL = 'LABEL_COSTS'

const HUMAN_RESOURCES = 'HUMAN_RESOURCES'
const HUMAN_RESOURCES_LABEL = 'LABEL_HUMAN_RESOURCES'

const OTHER_5 = 'OTHER_5'
const OTHER_6 = 'OTHER_6'

const BASE = 'BASE'
const BASE_LABEL = 'Base'

const TOP_DOWN = 'TOP_DOWN'
const TOP_DOWN_LABEL = 'TopDown'

const BOTTOM_UP = 'BOTTOM_UP'
const BOTTOM_UP_LABEL = 'BottomUp'

const CLOSING = 'CLOSING'
const CLOSING_LABEL = 'LABEL_CLOSING'

const GLOBAL_BUDGET = 'GLOBAL_BUDGET'
const GLOBAL_BUDGET_LABEL = 'LABEL_GLOBAL_BUDGET'

const PLANNING_MODULE = {
  key: PLANNING,
  label: PLANNING_LABEL,
  children: [
    {
      id: 1,
      key: SALES,
      label: SALES_LABEL,
      children: [
        {
          key: BASE,
          label: BASE_LABEL,
          operations: PLANNING_BASE_OPERATION_OPTIONS,
        },
        {
          key: TOP_DOWN,
          label: TOP_DOWN_LABEL,
          operations: PLANNING_BASE_OPERATION_OPTIONS,
        },
        {
          key: BOTTOM_UP,
          label: BOTTOM_UP_LABEL,
          operations: PLANNING_BASE_OPERATION_OPTIONS,
        },
        {
          key: CLOSING,
          label: CLOSING_LABEL,
          operations: PLANNING_BASE_OPERATION_OPTIONS,
        },
      ],
    },
    {
      id: 2,
      key: EXPENSES,
      label: EXPENSES_LABEL,
      children: [
        {
          key: BASE,
          label: BASE_LABEL,
          operations: PLANNING_BASE_OPERATION_OPTIONS,
        },
        {
          key: TOP_DOWN,
          label: TOP_DOWN_LABEL,
          operations: PLANNING_BASE_OPERATION_OPTIONS,
        },
        {
          key: BOTTOM_UP,
          label: BOTTOM_UP_LABEL,
          operations: PLANNING_BASE_OPERATION_OPTIONS,
        },
        {
          key: CLOSING,
          label: CLOSING_LABEL,
          operations: PLANNING_BASE_OPERATION_OPTIONS,
        },
      ],
    },
    {
      id: 3,
      key: COSTS,
      label: COSTS_LABEL,
      children: [
        {
          key: BASE,
          label: BASE_LABEL,
          operations: PLANNING_BASE_OPERATION_OPTIONS,
        },
        {
          key: TOP_DOWN,
          label: TOP_DOWN_LABEL,
          operations: PLANNING_BASE_OPERATION_OPTIONS,
        },
        {
          key: BOTTOM_UP,
          label: BOTTOM_UP_LABEL,
          operations: PLANNING_BASE_OPERATION_OPTIONS,
        },
        {
          key: CLOSING,
          label: CLOSING_LABEL,
          operations: PLANNING_BASE_OPERATION_OPTIONS,
        },
      ],
    },
    {
      id: 4,
      key: HUMAN_RESOURCES,
      label: HUMAN_RESOURCES_LABEL,
      children: [
        {
          key: BASE,
          label: BASE_LABEL,
          operations: PLANNING_BASE_OPERATION_OPTIONS,
        },
        {
          key: TOP_DOWN,
          label: TOP_DOWN_LABEL,
          operations: PLANNING_BASE_OPERATION_OPTIONS,
        },
        {
          key: BOTTOM_UP,
          label: BOTTOM_UP_LABEL,
          operations: PLANNING_BASE_OPERATION_OPTIONS,
        },
        {
          key: CLOSING,
          label: CLOSING_LABEL,
          operations: PLANNING_BASE_OPERATION_OPTIONS,
        },
      ],
    },
    {
      id: 5,
      key: OTHER_5,
      label: OTHER_5,
      children: [
        {
          key: BASE,
          label: BASE_LABEL,
          operations: PLANNING_BASE_OPERATION_OPTIONS,
        },
        {
          key: TOP_DOWN,
          label: TOP_DOWN_LABEL,
          operations: PLANNING_BASE_OPERATION_OPTIONS,
        },
        {
          key: BOTTOM_UP,
          label: BOTTOM_UP_LABEL,
          operations: PLANNING_BASE_OPERATION_OPTIONS,
        },
        {
          key: CLOSING,
          label: CLOSING_LABEL,
          operations: PLANNING_BASE_OPERATION_OPTIONS,
        },
      ],
    },
    {
      id: 6,
      key: OTHER_6,
      label: OTHER_6,
      children: [
        {
          key: BASE,
          label: BASE_LABEL,
          operations: PLANNING_BASE_OPERATION_OPTIONS,
        },
        {
          key: TOP_DOWN,
          label: TOP_DOWN_LABEL,
          operations: PLANNING_BASE_OPERATION_OPTIONS,
        },
        {
          key: BOTTOM_UP,
          label: BOTTOM_UP_LABEL,
          operations: PLANNING_BASE_OPERATION_OPTIONS,
        },
        {
          key: CLOSING,
          label: CLOSING_LABEL,
          operations: PLANNING_BASE_OPERATION_OPTIONS,
        },
      ],
    },
    {
      id: 9999,
      key: GLOBAL_BUDGET,
      label: GLOBAL_BUDGET_LABEL,
      operations: PLANNING_BASE_OPERATION_OPTIONS,
    },
  ],
}

// ++ MODULO: CONTROL ++
const CONTROL = 'CONTROL'
const CONTROL_LABEL = 'LABEL_CONTROL'

const REAL = 'REAL'
const REAL_LABEL = 'LABEL_REAL'

const STATUS_CHANGE = 'STATUS_CHANGE'
const STATUS_CHANGE_LABEL = 'LABEL_STATUS_CHANGE'

const ADJUSTMENTS = 'ADJUSTMENTS'
const ADJUSTMENTS_LABEL = 'LABEL_ADJUSTMENTS'

const CONTROL_MODULE = {
  key: CONTROL,
  label: CONTROL_LABEL,
  children: [
    {
      key: REAL,
      label: REAL_LABEL,
      children: [
        {
          id: 1,
          key: SALES,
          label: SALES_LABEL,
          operations: CONTROL_BASE_OPERATION_OPTIONS,
        },
        {
          id: 2,
          key: EXPENSES,
          label: EXPENSES_LABEL,
          operations: CONTROL_BASE_OPERATION_OPTIONS,
        },
        {
          id: 3,
          key: COSTS,
          label: COSTS_LABEL,
          operations: CONTROL_BASE_OPERATION_OPTIONS,
        },
        {
          id: 4,
          key: HUMAN_RESOURCES,
          label: HUMAN_RESOURCES_LABEL,
          operations: CONTROL_BASE_OPERATION_OPTIONS,
        },
        {
          id: 5,
          key: OTHER_5,
          label: OTHER_5,
          operations: CONTROL_BASE_OPERATION_OPTIONS,
        },
        {
          id: 6,
          key: OTHER_6,
          label: OTHER_6,
          operations: CONTROL_BASE_OPERATION_OPTIONS,
        },
        {
          id: 9999,
          key: STATUS_CHANGE,
          label: STATUS_CHANGE_LABEL,
          operations: [PUBLISH],
        },
      ],
    },
    {
      key: ADJUSTMENTS,
      label: ADJUSTMENTS_LABEL,
      operations: CONTROL_BASE_OPERATION_OPTIONS,
    },
  ],
}

// ++ MODULO: FORECAST ++
const FORECAST = 'FORECAST'
const FORECAST_LABEL = 'LABEL_FORECAST'

const GLOBAL_FORECAST = 'GLOBAL_FORECAST'
const GLOBAL_FORECAST_LABEL = 'LABEL_GLOBAL_FORECAST'

const FORECAST_MODULE = {
  key: FORECAST,
  label: FORECAST_LABEL,
  // Reutilizo lo de planning
  children: PLANNING_MODULE.children.map((module) => {
    return {
      ...module,
      ...(module.key === GLOBAL_BUDGET && {
        id: module.id,
        key: GLOBAL_FORECAST,
        label: GLOBAL_FORECAST_LABEL,
        operations: FORECAST_BASE_OPERATIONS_OPTIONS,
      }),
    }
  }),
}

// ++ MODULO: ANALYSIS ++
const ANALYSIS = 'ANALYSIS'
const ANALYSIS_LABEL = 'LABEL_ANALYSIS'

const ANALYSIS_MODULE = {
  key: ANALYSIS,
  label: ANALYSIS_LABEL,
  children: [
    {
      key: REPORTS_PNL,
      label: REPORTS_PNL_LABEL,
      operations: [VIEW],
    },
    // {
    //   key: CUSTOM_REPORTS,
    //   label: CUSTOM_REPORTS_LABEL,
    //   operations: [VIEW],
    // },
  ],
}

// NOTE: Estructura inicial de la relacion entre los modulos y submodulos.
const MODULES_SKELETON = [
  SETTINGS_MODULE,
  PLANNING_MODULE,
  CONTROL_MODULE,
  FORECAST_MODULE,
  ANALYSIS_MODULE,
]

// NOTE: Se van a ir guardando todas las KEYs de los modulos y de operaciones.
// NOTE: Por otro lado guardando todas las KEYs de operaciones en array. (Contiene la concatenacion del nivel mas bajo)
let MODULES_KEYS = {}
let MODULES_OPERATIONS_LIST = []

// +++++++++++++++++++++++++++++++++
// +++++++ ARMADO ESTRUCTURA +++++++
// +++++++++++++++++++++++++++++++++

// NOTE: Itero sobre cada elemento de la estructura de modulos para ir generando las KEYS de operaciones en los nodos hojas.
const operationsKeyGenerator = (options, parentKey = null) => {
  return options.map((el) => {
    let parentNewKey = parentKey ? `${parentKey}${KEY_SEPARATOR}${el.key}` : el.key
    // NOTE: Guardo las KEYS de los modulos y submodulos.
    MODULES_KEYS[replaceObjectKey(parentNewKey)] = parentNewKey

    if (el.children) {
      return {
        ...el,
        key: parentNewKey,
        children: operationsKeyGenerator(el.children, parentNewKey),
      }
    } else {
      const operationsKeys = el.operations.map((op) => {
        let opKey = `${parentNewKey}${KEY_SEPARATOR}${op.key}`
        // NOTE: Guardo las KEYS de los modulos/submodulos con operaciones
        MODULES_KEYS[replaceObjectKey(opKey)] = opKey
        return opKey
      })

      MODULES_OPERATIONS_LIST = [...MODULES_OPERATIONS_LIST, ...operationsKeys]

      return {
        ...el,
        key: parentNewKey,
        operationsKeys: operationsKeys,
        operations: el.operations.map((op) => {
          return {
            ...op,
            key: `${parentNewKey}${KEY_SEPARATOR}${op.key}`,
          }
        }),
      }
    }
  })
}

// NOTE: Itero sobre cada modulo padre y agrego las KEYS de operaciones de sus nodos hijos
const addOperationsKeyToParents = (options) => {
  const filterOperationsByKey = (key) => MODULES_OPERATIONS_LIST.filter((el) => el.includes(key))

  return options.map((el) => {
    if (el.children) {
      return {
        ...el,
        operationsKeys: filterOperationsByKey(el.key),
        children: addOperationsKeyToParents(el.children),
      }
    }
    return el
  })
}

// NOTE: Genero la estructura final de los modulos
const moduleOptionsGenerator = () => {
  const MODULES_SKELETON_KEYS = operationsKeyGenerator(MODULES_SKELETON)
  return addOperationsKeyToParents(MODULES_SKELETON_KEYS)
}

const MODULES_OPTIONS = moduleOptionsGenerator()

const FEATURES_KEY = 'features'
const DATA_KEY = 'data'

const ROLES_KEY = {
  FEATURES_KEY,
  DATA_KEY,
}

const ROLES_INDEX = {
  MODULES_OPTIONS,
  MODULES_OPERATIONS_LIST,
  ROLES_KEY,
  ...MODULES_KEYS,
}

export default ROLES_INDEX
