import { NAME } from './constants'
import _ from 'lodash'
import { createSelector } from 'reselect'

export const getSelectorColumns = (state) => state[NAME].selectorColumns
export const getAdjustmentKPIS = (state) => state[NAME].adjustmentKPIS
export const getAdjustmentList = (state) => state[NAME].adjustmentList
export const getAdjustmentColumns = (state) => state[NAME].adjustmentColumns
export const getAdjustmentDetail = (state) => state[NAME].adjustmentDetail
export const getAdjustmentAvailableDates = (state) => state[NAME].availableDates
export const getAdjustmentAvailableTypes = (state) => state[NAME].availableTypes
export const getCreationDate = (state) => state[NAME].creation.date
export const getCreationType = (state) => state[NAME].creation.type
export const getCreationRows = (state) => Object.values(state[NAME].creation.rows)

export const getCreationWarning = createSelector(
  getCreationDate,
  getCreationType,
  getCreationRows,
  (date, type, rows) => {
    if (date === null || type === null || _.some(rows, ['amount', null])) {
      return true
    }
    return false
  },
)
