import TSVExportRenderer from '../TSVExportRenderer'
import makeRenderer from './Table'

export default {
  'Table': makeRenderer({ subtotals: true }),
  'Table Heatmap': makeRenderer({ heatmapMode: 'full', subtotals: true }),
  'Table Col Heatmap': makeRenderer({ heatmapMode: 'col', subtotals: true }),
  'Table Row Heatmap': makeRenderer({ heatmapMode: 'row', subtotals: true }),
  'Exportable TSV': TSVExportRenderer,
}
