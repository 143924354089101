import TSVExport from '../TSVExport'
import makeRenderer from './Table'
import TablePxQ from './TablePxQ'

export default {
  'Table': makeRenderer(),
  'Table PxQ': TablePxQ,
  'Table Heatmap': makeRenderer({ heatmapMode: 'full' }),
  'Table Col Heatmap': makeRenderer({ heatmapMode: 'col' }),
  'Table Row Heatmap': makeRenderer({ heatmapMode: 'row' }),
  'Exportable TSV': TSVExport,
}
