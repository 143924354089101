const login = {
  LOGIN_WELLCOME_TITLE: 'Agilidad y simpleza para el análisis financiero.',
  LOGIN_WELLCOME_SUBTITLE: 'Tené todo bajo control con Plika',

  LOGIN_ASSOCIATED_ACCOUNT_MODAL_TITLE: 'Seleccionar cuenta',
  LOGIN_ASSOCIATED_ACCOUNT_SOCIETY_NAME: 'Empresa: {{societyName}}',

  //Login form
  LOGIN_FORM_TITLE: 'Iniciar sesión para comenzar',
  LOGIN_FORM_TITLE_CREATE_PASSWORD: 'Crear contraseña',
  LOGIN_FORM_INPUT_EMAIL_LABEL: 'Ingresá tu e-mail',
  LOGIN_FORM_INPUT_EMAIL_PLACEHOLDER: 'Ej. nombre@email.com',
  LOGIN_FORM_INPUT_EMAIL_CAPTION_EMPTY: 'Por favor, ingresá un e-mail',
  LOGIN_FORM_INPUT_EMAIL_CAPTION_FORMAT: 'Por favor, ingresá un e-mail válido',
  LOGIN_FORM_INPUT_EMAIL_CAPTION_NAME: 'Por favor ingrese su nombre',
  LOGIN_FORM_INPUT_EMAIL_CAPTION_SURNAME: 'Por favor ingrese su apellido',
  LOGIN_FORM_INPUT_EMAIL_CAPTION_OK: 'Formato de e-mail válido',
  LOGIN_FORM_INPUT_PASSWORD_LABEL: 'Ingresá tu contraseña',
  LOGIN_FORM_INPUT_PASSWORD_PLACEHOLDER: 'Contraseña',
  LOGIN_FORM_INPUT_A_PASSWORD_CAPTION_EMPTY: 'Por favor, ingresá una contraseña',
  LOGIN_FORM_INPUT_PASSWORD_CAPTION_FORMAT: 'Por favor, ingresá una contraseña válida',
  LOGIN_FORM_INPUT_PASSWORD_CAPTION_OK: 'Formato de contraseña válido',
  LOGIN_FORM_BUTTON_SUBMIT: 'Iniciar sesión',
  LOGIN_FORM_CONFIRM_PASSWORD_LABEL_CURRENT: 'Contraseña actual',
  LOGIN_FORM_CONFIRM_PASSWORD_LABEL_NEW: 'Contraseña nueva',
  LOGIN_FORM_CONFIRM_PASSWORD_CHARACTERS: 'Debe contener al menos 8 caracteres',
  LOGIN_FORM_CONFIRM_PASSWORD_RECONFIRM: 'Reconfirmar contraseña nueva',
  LOGIN_FORM_PASSWORD_NOT_SAME: 'Las contraseñas no coinciden',
  LOGIN_FORM_PASSWORD_NOT_DIFFERENT: 'La contraseña debe ser distinta a la anterior',
  LOGIN_USER_NOT_FOUND_FEEDBACK_TITLE: 'No se encontró usuario con el mail ingresado en Plika',
  LOGIN_USER_NOT_FOUND_FEEDBACK_DESCRIPTION:
    'Para que puedas loguearte con Google o Microsoft debes crear un usuario con el mail ingresado en Plika. O si ya tienes una cuenta de Plika, asociar la misma con el mail ingresado desde configuración.',

  LOGIN_FORM_PASSWORD_VALIDATOR_FEEDBACK:
    'La contraseña debe tener al menos 1 número, 1 letra minúscula, 1 letra mayúscula, 1 carácter especial, no debe contener espacios y ser de al menos 8 dígitos',

  LOGIN_FEEDBACK_ERROR:
    'No reconocimos el e-mail o la contraseña que acabas de ingresar. Por favor, volve a intentar',

  LOGIN_FORGOT_PASSWORD_ACTION: 'Olvidé mi contraseña',
  LOGIN_GO_BACK_SESSION_ACTION: 'Volver a inicio de sesión',
  LOGIN_FORM_RESET_PASSWORD_TITLE: 'Restablecer contraseña',
  LOGIN_FORM_RESET_PASSWORD_NOTIFICATION_TEXT:
    'Te enviamos un mail para restablecer tu contraseña, por favor revisa tu casilla.',
  LOGIN_RESET_PASSWORD_FEEDBACK_SUCCESS_DESCRIPTION_1:
    'La constraseña se restableció con éxito. Ya puedes ',
  LOGIN_RESET_PASSWORD_FEEDBACK_SUCCESS_DESCRIPTION_2: ' con tu nueva contraseña.',

  LOGIN_CONGIF_SCAN_QR_INFORMATIVE_ALERT_DESCRIPTION:
    'Para poder escanear el código debes descargar alguna aplicación de autenticación. Por ej: Google authenticator.',
  LOGIN_CONFIG_ENTER_CODE_TYPOGRAPHY_BODY: 'Ingrese el código que aparece luego de escanear',
  LOGIN_FEEDBACK_USER_BLOCKED_ERROR:
    'El usuario se blockeó por varios intentos erroneos. Te enviaremos un mail a {{email}} para realizar la activación. ',
  LOGIN_SEND_EMAIL_FEEDBACK_TEXT: 'Enviar mail',
}

export default login
