import React from 'react'
import clsx from 'clsx'

const QuestionItem = ({ item, onSetMessage }) => {
  return (
    <div
      className={clsx('chat-list-item item-hover', { active: false })}
      onClick={() => onSetMessage(item)}
    >
      <div className="chat-list-item-content">
        <h3>{item.label}</h3>
      </div>
    </div>
  )
}

export default QuestionItem
