import * as types from './constants'
import moment from 'moment'
import decode from 'jwt-decode'
import Cookies from 'js-cookie'
// import { GA } from 'modules/core/utils';

export const tokenutils = {
  getAccessToken() {
    return localStorage.getItem('access_token')
  },
  saveAccessToken(token) {
    localStorage.setItem('access_token', token)
  },
  saveTokens(data) {
    localStorage.setItem('access_token', data.access)
    localStorage.setItem('refresh_token', data.refresh)
    Cookies.set('jwt_token', data.access, {
      domain: '.getplika.com',
      sameSite: 'None',
      secure: true,
    })
  },
  removeToken() {
    localStorage.removeItem('access_token')
    localStorage.removeItem('refresh_token')
    Cookies.remove('jwt_token', { domain: '.getplika.com' })
  },
  checkTokenFreshness(token, timeToExpire) {
    // NOTE: this does NOT verify the token signature is valid, only checks expiry date
    // integrity validation is done on the server
    try {
      let tokenPayload = decode(token)
      let expiry = moment.unix(tokenPayload.exp)
      return expiry.diff(moment(), 'seconds') > timeToExpire
    } catch (error) {
      //token is invalid (undefined) so cant do the expire validation
      return false
    }
  },
}

export const onLogin = (data) => async (dispatch) => {
  // try {
  const response = await dispatch({
    type: types.USER_LOGIN,
    payload: {
      request: {
        url: '/auth/jwt/create/',
        method: 'POST',
        data,
      },
    },
  })
  if (response.error) {
    // GA.event(GA.ACTIONS.USER_LOGIN_ERROR);
    return Promise.reject(response.error)
  }
  tokenutils.saveTokens(response.payload.data)
  return Promise.resolve(response)
}

export const confirmPassword =
  (id, data = {}) =>
  async (dispatch) => {
    try {
      const response = await dispatch({
        type: types.USER_CHANGE_PASSWORD,
        payload: {
          request: {
            url: `/auth/change_password/${id}/`,
            method: 'PUT',
            data,
          },
        },
      })
      return Promise.resolve(response)
    } catch (error) {
      return Promise.reject(error)
    }
  }

// TODO: Agregar llamada await a API si existe endpoint
export const onLogout = () => async (dispatch) => {
  try {
    tokenutils.removeToken()
    dispatch({ type: types.USER_LOGOUT })
    // GA.event(GA.ACTIONS.USER_LOGOUT);
    // GA.endSession();
    return Promise.resolve()
  } catch (error) {
    return Promise.reject(error.message)
  }
}

export const whoAmI =
  (data = {}) =>
  async (dispatch) => {
    try {
      const response = await dispatch({
        type: types.USER_WHO_AM_I,
        payload: {
          request: {
            url: '/auth/current/',
            method: 'GET',
          },
        },
      })
      return response
    } catch (error) {
      return Promise.reject(error)
    }
  }

// LANG SETTING
// export const editUserLang = (userId, lang) => async dispatch => {
//   const response = await dispatch({
//     type: types.USER_SETTING_LANG,
//     payload: {
//       request: {
//         url: `/auth/users/${userId}/`,
//         method: 'PATCH',
//         data: {
//           lang: lang,
//         },
//       },
//     },
//   });
//   if (response.error) {
//     return Promise.reject(response.error);
//   }
//   return Promise.resolve(response);
// };

export const emailConfirm =
  (data = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.EMAIL_CONFIRM,
      payload: {
        request: {
          url: `/auth/users/reset_password/`,
          method: 'POST',
          data,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return Promise.resolve(response)
  }

export const resetPassword =
  (data = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.RESET_PASSWORD,
      payload: {
        request: {
          url: `/auth/users/reset_password_confirm/`,
          method: 'POST',
          data,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return Promise.resolve(response)
  }

export const googleRedirect = () => async (dispatch) => {
  try {
    const response = await dispatch({
      type: types.GOOGLE_REDIRECT,
      payload: {
        request: {
          url: '/auth/google/redirect',
          method: 'GET',
        },
      },
    })
    return response
  } catch (error) {
    return Promise.reject(error)
  }
}

export const googleCallback =
  (data = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.GOOGLE_CALLBACK,
      payload: {
        request: {
          url: `/auth/google/callback/`,
          method: 'POST',
          data,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return Promise.resolve(response)
  }

export const googleAccounts =
  (data = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.GOOGLE_ACCOUNTS,
      payload: {
        request: {
          url: `/auth/sso/accounts/`,
          method: 'POST',
          data,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return Promise.resolve(response)
  }

export const fetchLoginOtherAccounts = () => async (dispatch) => {
  const response = await dispatch({
    type: types.FETCH_LOGIN_OTHER_ACCOUNTS,
    payload: {
      request: {
        url: `/auth/sso/accounts/`,
        method: 'GET',
      },
    },
  })
  if (response.error) {
    return Promise.reject(response.error)
  }
  return Promise.resolve(response)
}

export const associatedAccount =
  (data = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.ASSOCIATED_ACCOUNT,
      payload: {
        request: {
          url: `/auth/sso/login/`,
          method: 'POST',
          data,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return Promise.resolve(response)
  }

export const setAuthenticated = () => (dispatch) =>
  dispatch({
    type: types.SET_AUTHENTICATED,
  })

export const azureRedirect = () => async (dispatch) => {
  try {
    const response = await dispatch({
      type: types.AZURE_REDIRECT,
      payload: {
        request: {
          url: '/auth/azure/redirect',
          method: 'GET',
        },
      },
    })
    return response
  } catch (error) {
    return Promise.reject(error)
  }
}

export const azureCallback =
  (data = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.AZURE_CALLBACK,
      payload: {
        request: {
          url: `/auth/azure/callback/`,
          method: 'POST',
          data,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return Promise.resolve(response)
  }

export const qrCodeRedirect = () => async (dispatch) => {
  try {
    const response = await dispatch({
      type: types.QR_CODE_REDIRECT,
      payload: {
        request: {
          url: '/auth/totp/setup/',
          method: 'GET',
        },
      },
    })
    return response
  } catch (error) {
    return Promise.reject(error)
  }
}

export const verifyCode =
  (data = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.VERIFY_CODE,
      payload: {
        request: {
          url: `/auth/totp/verify/`,
          method: 'POST',
          data,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return Promise.resolve(response)
  }

export const deleteQrAunthentication = () => async (dispatch) => {
  const response = await dispatch({
    type: types.DELETE_QR_AUTHENTICATION,
    payload: {
      request: {
        url: `/auth/totp/delete/`,
        method: 'DELETE',
      },
    },
  })
  if (response.error) {
    return Promise.reject(response.error)
  }
  return response
}

export const sendEmailSelfResetPassword =
  (data = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.SEND_EMAIL_SELF_RESET_PASSWORD,
      payload: {
        request: {
          url: `/auth/activate_password/`,
          method: 'POST',
          data,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return Promise.resolve(response)
  }

export const selfResetPassword =
  (id, data = {}) =>
  async (dispatch) => {
    try {
      const response = await dispatch({
        type: types.SELF_REDET_PASSWORD,
        payload: {
          request: {
            url: `/auth/activate_user_account/${id}/`,
            method: 'PATCH',
            data,
          },
        },
      })
      return Promise.resolve(response)
    } catch (error) {
      return Promise.reject(error)
    }
  }
