import React from 'react'
import { Card, Col, Row } from 'antd'

const LoadingKpiCard = () => {
  return (
    <Row gutter={[24, 0]}>
      {[1, 2, 3, 4].map((index) => (
        <Col span={6} key={index}>
          <Card loading={true} />
        </Col>
      ))}
    </Row>
  )
}

export default LoadingKpiCard
