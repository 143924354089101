import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Spin, Row, Col, Space } from 'antd'
import { CustomBottomAction, Typography } from 'modules/core/components'
import emptyImage from 'assets/images/compositions/empty-big.svg'
import reports from 'modules/reports'
import login from 'modules/login'
import _ from 'lodash'
import * as QuickSightEmbedding from 'amazon-quicksight-embedding-sdk'

import './ReportDashboards.scss'

const getDashboard = (id, url) => {
  var dashboard
  const onDashboardLoad = (payload) => {
    console.log('Do something when the dashboard is fully loaded.')
  }

  const onError = (payload) => {
    console.log('Do something when the dashboard fails loading')
  }
  const embedDashboard = () => {
    var containerDiv = document.getElementById(id)
    var options = {
      url,
      container: containerDiv,
      parameters: {
        country: 'United States',
      },
      scrolling: 'no',
      iframeResizeOnSheetChange: true,
      height: 'AutoFit',
      loadingHeight: '700px',
      width: '100%',
    }
    dashboard = QuickSightEmbedding.embedDashboard(options)
    dashboard.on('error', onError)
    dashboard.on('load', onDashboardLoad)
    return dashboard
  }
  return embedDashboard
}

const ReportDashboards = ({ fetchDashboardUrl, dashboardUrl = {} }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [dataDashboard, setDataDashboard] = useState()
  const [error, setError] = useState(null)

  useEffect(() => {
    setIsLoading(true)
    fetchDashboardUrl()
      .then(() => setIsLoading(false))
      .catch((error) => {
        setError(error.response.data)
        setIsLoading(false)
      })
  }, [fetchDashboardUrl])

  useEffect(() => {
    if (!isLoading && !_.isEmpty(dashboardUrl)) {
      const dashboard = getDashboard('embeddingContainer', dashboardUrl.EmbedUrl)
      setDataDashboard(dashboard)
    }
  }, [dashboardUrl, isLoading])

  const renderNotPermissionScreen = () => (
    <Space className="not-permission-screen" direction="vertical" size="large">
      <img alt="not-permission-screen" src={emptyImage} />
      <div>
        <Typography.Headline level={5} type="title">
          {/* {error} */}
          No tienes permisos
        </Typography.Headline>
      </div>
    </Space>
  )

  return (
    <Spin spinning={isLoading}>
      <Col span={24}>
        <div style={{ float: 'right' }}>
          <CustomBottomAction extraButtons={{ module_name: 'reportes', concept: '2' }} />
        </div>
      </Col>
      {!isLoading && !error && !_.isEmpty(dashboardUrl) && (
        <div className="dashboard-container">
          <Row gutter={[8, 8]}>
            <Col span={24}>
              <div id="embeddingContainer"></div>
            </Col>
          </Row>
        </div>
      )}
      {!isLoading && error && renderNotPermissionScreen()}
    </Spin>
  )
}

const mapStateToProps = (state) => ({
  dashboardUrl: reports.selectors.getDashboardUrl(state),
  loggedUser: login.selectors.getWhoAmI(state),
})

const mapDispatchToProps = {
  fetchDashboardUrl: reports.actions.fetchDashboardUrl,
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportDashboards)
