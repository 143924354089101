import QueueAnim from 'rc-queue-anim'
import React from 'react'

const AnimateGroup = (props) => {
  const { height, animateStyle, ...rest } = props
  return (
    <QueueAnim
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: height,
        ...animateStyle,
      }}
      {...rest}
    >
      {props.children}
    </QueueAnim>
  )
}

export default AnimateGroup
