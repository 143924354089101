import { Input, Spin, Typography as TypographyAnt } from 'antd'

const { Text } = TypographyAnt

const CardTitle = ({ loading, defaultValue, editName, onBlur, onPressEnter, title }) => {
  return (
    <Spin spinning={loading}>
      {editName ? (
        <Input
          autoFocus
          disabled={loading}
          defaultValue={defaultValue}
          onBlur={onBlur}
          onPressEnter={onPressEnter}
        />
      ) : (
        <Text style={{ width: 150 }} ellipsis={true} title={title}>
          {title}
        </Text>
      )}
    </Spin>
  )
}

export default CardTitle
