import { Card, Drawer } from 'antd'
import clsx from 'clsx'
import React, { useState } from 'react'
import './ChatbotContainer.scss'

const ChatbotContainer = (props) => {
  const [isAppDrawerOpen, setAppDrawerOpen] = useState(false)
  const { title, sidebarContent, fullView, children } = props
  return (
    <div className="apps-wrap">
      <div className={clsx('apps-wrap-header', { appsWrapHeaderFull: fullView })}>
        <h2 className="text-truncate" key="title">
          {title}
        </h2>
      </div>

      <div className="apps-container">
        {sidebarContent ? (
          <div className="apps-sidebar">
            <Drawer
              closeIcon={null}
              placement={'left'}
              visible={isAppDrawerOpen}
              onClose={() => setAppDrawerOpen(!isAppDrawerOpen)}
              className="apps-sidebar-drawer"
            >
              {sidebarContent}
            </Drawer>
            <Card className="apps-sidebar-card">{sidebarContent}</Card>
          </div>
        ) : null}
        <div
          className={clsx('apps-main-content', {
            appsMainContentFull: fullView,
          })}
        >
          <Card
            bordered={false}
            key="content"
            className="apps-main-content-card"
            style={{ ...props.cardStyle }}
          >
            {children}
          </Card>
        </div>
      </div>
    </div>
  )
}

export default ChatbotContainer
