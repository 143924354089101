import React, { useState } from 'react'
import { List } from 'antd'
import { connect } from 'react-redux'
import { ImportDataCard } from './components'
import { ImportDataModal } from 'modules/core/components'
import home from 'modules/home'

import './UploadDataScreen.scss'

const UploadDataScreen = ({ dataConnectors }) => {
  const [showConnectModal, setShowConnectModal] = useState(false)
  const [connectorId, setConnectorId] = useState(null)

  const handleIntegration = (item) => {
    setShowConnectModal(true)
    setConnectorId(item.id)
  }

  return (
    <>
      <List
        grid={{ gutter: 24, xs: 1, sm: 2, md: 2, lg: 3, xl: 4, xxl: 5 }}
        dataSource={dataConnectors}
        renderItem={(item) => (
          <List.Item key={item.id}>
            <ImportDataCard item={item} onClick={() => handleIntegration(item)} />
          </List.Item>
        )}
      />
      {connectorId && (
        <ImportDataModal
          visible={showConnectModal}
          onCancel={() => setShowConnectModal(false)}
          connectorData={dataConnectors.find((el) => el.id === connectorId)}
        />
      )}
    </>
  )
}

const mapStateToProps = (state) => ({
  dataConnectors: home.selectors.getDataConnectors(state),
})

export default connect(mapStateToProps, null)(UploadDataScreen)
