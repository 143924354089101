import { Radio } from 'antd'
import { REPORT } from 'modules/core/constants'
import React from 'react'
import { useTranslation } from 'react-i18next'

const ComparativeSwitch = ({ tableTypeKey, onChange }) => {
  const { t } = useTranslation()
  return (
    <Radio.Group
      options={[
        {
          label: t('REPORT_COMPARATIVE_ACCUMULATIVE'),
          value: REPORT.REPORTS_KEYS.ACCUMULATED,
        },
        {
          label: t('REPORT_COMPARATIVE_MONTHLY'),
          value: REPORT.REPORTS_KEYS.MONTHLY,
        },
      ]}
      onChange={onChange}
      value={tableTypeKey}
      optionType="button"
      buttonStyle="solid"
    />
  )
}

export default ComparativeSwitch
