const periods = {
  PERIOD_TITLE: 'Budget Periods',

  PERIOD_CREATE_ACTION: 'Start period',

  PERIOD_EMPTY_STATE_TEXT:
    'From here, you will manage your periods, both current and past years. You can track them, compare them, analyze them, and more',

  PERIOD_CREATE_MODAL_TITLE: 'Initiate budget period',
  PERIOD_CREATE_MODAL_NAME_LABEL: 'Define a name',
  PERIOD_CREATE_MODAL_NAME_PLACEHOLDER: 'For example: Period 1',
  PERIOD_CREATE_MODAL_DATES_LABEL: 'Enter a range of dates',
  PERIOD_CREATE_SUCCESS_FEEDBACK: 'The period began correctly',

  PERIOD_DATES_LABEL: 'Start month - End month',

  PERIOD_DELETE_CONFIRM_TEXT: 'Are you sure you want to eliminate this period?',
  PERIOD_DELETE_SUCCESS_FEEDBACK: 'The period was deleted sucessfully',
  PERIOD_DELETE_WITH_DATA_MODAL_CONFIRM_TEXT:
    'The current period you want to delete may have loaded data',

  PERIOD_EDIT_NAME_SUCCES_FEEDBACK: 'Period name was successfully edited.',
  PERIOD_EDIT_DATE_SUCCES_FEEDBACK: 'Period dates was successfully edited.',

  PERIOD_ONBOARDING_INFORMATIVE_TOOLTIP_DESCRIPTION: `We recommend that you first perform the general configuration to start exploring Plika's functionalities to the fullest`,
  PERIOD_ALERT_TITLE: 'General settings',
  PERIOD_ALERT_DESCRIPTION:
    'Remember to configure the tool before proceeding (at least your account plan and a report), this way you will be able to perform your uploads successfully',

  PERIOD_CONFIG_TOOL_TOOLTIP_TITLE: 'Set the tool to enter',
}

export default periods
