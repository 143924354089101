import React from 'react'
import { Button, ButtonActionBar } from 'modules/core/components'
import { useTranslation } from 'react-i18next'

const ContainersButtons = ({
  current,
  components,
  dataSteps,
  setShowConfirmModal,
  loading,
  prev,
}) => {
  const { t } = useTranslation()
  return (
    <ButtonActionBar>
      {current > 0 && (
        <Button.Default
          onClick={() =>
            components[dataSteps[current].key].has_data ? setShowConfirmModal() : prev()
          }
          title="ACTION_PREVIOUS"
        />
      )}
      {current < dataSteps.length - 1 && (
        <Button.Primary
          loading={loading}
          disabled={!components[dataSteps[current].key].completed}
          onClick={() => components[dataSteps[current].key].onAction()}
          tooltipTitle={
            !components[dataSteps[current].key].completed
              ? t(components[dataSteps[current].key].tooltip)
              : ''
          }
          title="ACTION_NEXT"
        />
      )}
      {current === dataSteps.length - 1 && (
        <Button.Primary
          disabled={!components[dataSteps[current].key].completed}
          loading={components[dataSteps[current].key].loading}
          onClick={() => components[dataSteps[current].key].onAction()}
          tooltipTitle={t(components[dataSteps[current].key].tooltip)}
          title="ACTION_FINISH"
        />
      )}
    </ButtonActionBar>
  )
}

export default ContainersButtons
