import { combineReducers } from 'redux'
import configuration from './configuration'
import control from './control'
import login from './login'
import planning from './planning'
import reports from './reports'
import adjustments from './adjustments'
import forecast from './forecast'
import home from './home'
import societies from './societies'
import processes from './processes'
import chatbot from './chatbot'
import question from './question'
import uploadS3 from './uploadS3'

const appReducer = combineReducers({
  [configuration.NAME]: configuration.reducer,
  [control.NAME]: control.reducer,
  [login.NAME]: login.reducer,
  [planning.NAME]: planning.reducer,
  [reports.NAME]: reports.reducer,
  [adjustments.NAME]: adjustments.reducer,
  [forecast.NAME]: forecast.reducer,
  [home.NAME]: home.reducer,
  [societies.NAME]: societies.reducer,
  [processes.NAME]: processes.reducer,
  [chatbot.NAME]: chatbot.reducer,
  [question.NAME]: question.reducer,
  [uploadS3.NAME]: uploadS3.reducer,
})

const rootReducer = (state, action) => {
  // Al llamarse esa accion, los reducer locales vuelven al estado inicial
  if (action.type === 'login/USER_LOGOUT') {
    state = undefined
  }

  return appReducer(state, action)
}

export default rootReducer
