import { useFeatureIsOn } from '@growthbook/growthbook-react'
import React from 'react'
import { Tabs, Tooltip } from 'antd'
import { useQuery } from 'modules/core/customHooks'
import { useTranslation } from 'react-i18next'
import { Navigator } from 'modules/core/components'
import { SiderLayout } from 'modules/core/layouts'
import { RulesList, CurrencyList } from '../'
import { isUserAllowed, getOperationsByKeys } from 'modules/core/utils'
import { ROLES } from 'modules/core/constants'

const { SETTINGS__PROJECTION_ELEMENTS__BUSINESS_RULES, SETTINGS__PROJECTION_ELEMENTS__CURRENCY } =
  ROLES

const ModificadoresHome = () => {
  const enabled = useFeatureIsOn('feature-moneda-seguimiento')
  const { t } = useTranslation()
  let query = useQuery()

  const renderHeader = () => (
    <Navigator
      floated={false}
      size="big"
      items={[
        {
          name: t('LABEL_CONFIG'),
          url: `/configuracion`,
        },
        { name: t('LABEL_TOOLS') },
      ]}
    />
  )

  return (
    <SiderLayout>
      <>
        {renderHeader()}
        <Tabs
          size="small"
          defaultActiveKey={query.get('tab') !== null ? query.get('tab') : 'vars'}
          style={{ paddingTop: 24 }}
        >
          {isUserAllowed(getOperationsByKeys([SETTINGS__PROJECTION_ELEMENTS__BUSINESS_RULES])) && (
            <Tabs.TabPane
              tab={
                <Tooltip title={t('CONFIG_RULES_INFORMATIVE_TOOLTIP_TITLE')}>
                  {t('LABEL_BUSINESS_RULES')}
                </Tooltip>
              }
              key="rules"
            >
              <RulesList />
            </Tabs.TabPane>
          )}
          {
            // isUserAllowed(
            //   getOperationsByKeys([SETTINGS__PROJECTION_ELEMENTS__CURRENCY])
            // ) && (
            enabled && (
              <Tabs.TabPane
                tab={
                  <Tooltip title={t('CONFIG_CURRENCY_INFORMATIVE_TOOLTIP_TITLE')}>
                    {t('LABEL_CURRENCY')}
                  </Tooltip>
                }
                key="currency"
              >
                <CurrencyList />
              </Tabs.TabPane>
            )
            // )
          }
        </Tabs>
      </>
    </SiderLayout>
  )
}

export default ModificadoresHome
