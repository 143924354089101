import React from 'react'
import { Typography } from 'modules/core/components'
import { useTranslation } from 'react-i18next'

const DashboardSubtitle = ({ name }) => {
  const { t } = useTranslation()
  return (
    <div style={{ paddingBottom: 16 }}>
      <Typography.Subtitle>{t(name)}</Typography.Subtitle>
    </div>
  )
}

export default DashboardSubtitle
