import { message, notification } from 'antd'
import { generateMessageError } from 'modules/core/utils'
import i18n from 'i18next'

export const onDeleteForecast = ({ setIsLoading, deleteForecast, onRefetch, onClose }) => {
  setIsLoading(true)
  deleteForecast()
    .then(() => {
      onRefetch()
      onClose()
    })
    .catch((error) => {
      notification.error({
        message: i18n.t('FORECAST_DELETE_ERROR_FEEDBACK'),
        description: generateMessageError(error),
        duration: 0,
      })
      onClose()
    })
}

export const handleEdit = ({
  value,
  dataEdit,
  setIsLoading,
  editForecastName,
  onRefetch,
  onClose,
}) => {
  if (value !== dataEdit.name) {
    setIsLoading(true)
    editForecastName(dataEdit.id, { name: value })
      .then(() => {
        onRefetch()
        message.success(i18n.t('FORECAST_EDIT_NAME_SUCCESS_FEEDBACK'), 8)
        onClose()
      })
      .catch((error) => {
        message.error(generateMessageError(error, 'FORECAST_EDIT_NAME_ERROR_FEEDBACK'), 8)
        onClose()
      })
  } else {
    onClose()
  }
}
