export const NAME = 'processes'

export const FETCH_PROCESSES_LIST = `${NAME}/FETCH_PROCESSES_LIST`
export const FETCH_PROCESSES_LIST_SUCCESS = `${NAME}/FETCH_PROCESSES_LIST_SUCCESS`
export const FETCH_PROCESSES_LIST_FAIL = `${NAME}/FETCH_PROCESSES_LIST_FAIL`

export const DOWNLOAD_PROCESS_FILE = `${NAME}/DOWNLOAD_PROCESS_FILE`

export const FETCH_AUDIT_LIST = `${NAME}/FETCH_AUDIT_LIST`
export const FETCH_AUDIT_LIST_SUCCESS = `${NAME}/FETCH_AUDIT_LIST_SUCCESS`
export const FETCH_AUDIT_LIST_FAIL = `${NAME}/FETCH_AUDIT_LIST_FAIL`
