import React from 'react'
import { Form, Input, Modal } from 'antd'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { onDownload } from './utils'
import home from 'modules/home'
import login from 'modules/login'

const DownloadModal = ({
  title,
  visible,
  onCancel,
  defaultValue,
  dataSpreadsheets,
  extraAction = null,
  downloadSpreadsheets,
  updateSpreadsheets,
  whoAmI,
}) => {
  const [isSaving, setIsSaving] = useState(false)
  const [form] = Form.useForm()
  const { t } = useTranslation()

  const onClose = () => {
    form.resetFields()
    onCancel()
    setIsSaving(false)
  }

  const handleDownload = (values) => {
    if (defaultValue && values.folder_id !== defaultValue) {
      return updateSpreadsheets({ ...dataSpreadsheets, ...values })
    } else {
      return downloadSpreadsheets({ ...dataSpreadsheets, ...values })
    }
  }

  return (
    <Modal
      title={title}
      width={560}
      visible={visible}
      centered
      forceRender
      destroyOnClose
      onCancel={onClose}
      onOk={() =>
        onDownload({
          form,
          setIsLoading: setIsSaving,
          downloadSpreadsheets: (values) => handleDownload(values),
          extraAction,
          onRefetch: () => whoAmI(),
          onClose,
        })
      }
      okText={t('ACTION_DOWNLOAD')}
      cancelText={t('ACTION_CANCEL')}
      okButtonProps={{ loading: isSaving }}
      cancelButtonProps={{ disabled: isSaving }}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          label={t('INTEGRATION_FOLDER_ID_FORMITEM_LABEL')}
          name="folder_id"
          rules={[{ required: true, message: t('REQUIRED_FIELD') }]}
          initialValue={defaultValue || undefined}
        >
          <Input placeholder={t('INTEGRATIONS_FOLDER_ID_SPREADSHEET_INPUT_PLACEHOLDER')} />
        </Form.Item>
      </Form>
    </Modal>
  )
}

const mapDispatchToProps = {
  downloadSpreadsheets: home.actions.downloadSpreadsheets,
  updateSpreadsheets: home.actions.updateSpreadsheets,
  whoAmI: login.actions.whoAmI,
}

export default connect(null, mapDispatchToProps)(DownloadModal)
