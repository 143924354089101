import React, { useState, useEffect } from 'react'
import { Modal, Form, Row, Col, Input } from 'antd'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { onCreate, onDuplicate } from './utils'
import configuration from 'modules/configuration'
import login from 'modules/login'

const KEY_CREATE = 'create'

const ActionRoleModal = ({
  visible,
  onCancel,
  onSuccess,
  createRole,
  duplicateRole,
  initialValues = {},
  actionType,
  setShowLicenseDrawer,
  loggedUser: { society_id },
}) => {
  const [isLoadingModal, setIsLoadingModal] = useState(false)
  const [form] = Form.useForm()
  const { t } = useTranslation()

  useEffect(() => {
    if (visible) {
      form.setFieldsValue(initialValues)
    }
  }, [visible, form, initialValues])

  const onClose = () => {
    form.resetFields()
    setIsLoadingModal(false)
    onCancel()
  }

  const handleDuplicate = (values) => {
    const data = {
      role_id: initialValues.id_rol,
      name: values.name + ' - ' + t('CONFIG_ROLES_LABEL_COPY'),
      code: values.code + ' - ' + t('CONFIG_ROLES_LABEL_COPY'),
    }
    return duplicateRole(data)
  }

  return (
    <Modal
      title={
        actionType === KEY_CREATE
          ? t('CONFIG_ROLES_MODAL_CREATE_ROLE_TITLE')
          : t('CONFIG_ROLES_MODAL_DUPLICATE_ROLE_TITLE')
      }
      bodyStyle={{ overflowY: 'auto', maxHeight: 400 }}
      visible={visible}
      centered
      okText={
        actionType === KEY_CREATE
          ? t('CONFIG_ROLES_CREATE_ACTION')
          : t('CONFIG_ROLES_DUPLICATE_ACTION')
      }
      cancelText={t('ACTION_CANCEL')}
      onOk={
        actionType === KEY_CREATE
          ? () =>
              onCreate({
                form,
                setIsLoading: setIsLoadingModal,
                createRole: (values) => createRole({ ...values, society: society_id }),
                onRefetch: onSuccess,
                onClose,
                setShowLicenseDrawer,
              })
          : () =>
              onDuplicate({
                form,
                setIsLoading: setIsLoadingModal,
                duplicateRole: (values) => handleDuplicate(values),
                onRefetch: onSuccess,
                onClose,
              })
      }
      onCancel={onClose}
      width={570}
      okButtonProps={{ loading: isLoadingModal }}
      cancelButtonProps={{ disabled: isLoadingModal }}
    >
      <Form form={form} layout="vertical" initialValues={{ users: [''] }}>
        <Row gutter={24} type="flex" justify="center">
          <Col span={12}>
            <Form.Item
              label={t('FIELD_NAME')}
              name="name"
              rules={[{ required: true, message: t('REQUIRED_FIELD') }]}
            >
              <Input placeholder={t('CONFIG_ROLES_ACTION_MODAL_NAME_PLACEHOLDER')} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={t('FIELD_CODE')}
              name="code"
              rules={[{ required: true, message: t('REQUIRED_FIELD') }]}
            >
              <Input placeholder={t('CONFIG_ROLES_ACTION_MODAL_CODE_PLACEHOLDER')} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

const mapStateToProps = (state) => ({
  loggedUser: login.selectors.getWhoAmI(state),
})

const mapDispatchToProps = {
  createRole: configuration.actions.createRole,
  duplicateRole: configuration.actions.duplicateRole,
}

export default connect(mapStateToProps, mapDispatchToProps)(ActionRoleModal)
