import { message, notification } from 'antd'
import { generateMessageError } from 'modules/core/utils'
import i18n from 'i18next'
import _ from 'lodash'

export const onSave = ({ dataCellEdit, setIsLoading, editCurrencies, onRefetch, onClose }) => {
  if (!_.isEmpty(dataCellEdit)) {
    setIsLoading(true)
    editCurrencies()
      .then(() =>
        onRefetch().then(() => {
          message.success(i18n.t('FEEDBACK_CHANGES_SAVED_SUCCESS'), 8)
          onClose()
        }),
      )
      .catch((error) => {
        notification.error({
          message: i18n.t('FEEDBACK_DEFAULT_ERROR'),
          description: generateMessageError(error, 'FEEDBACK_SAVE_CHANGES_FAIL'),
          duration: 0,
        })
        onClose()
      })
  } else {
    onClose()
  }
}

export const onDelete = ({ setIsLoading, deleteCurrencies, onClose, onRefetch }) => {
  setIsLoading(true)
  deleteCurrencies()
    .then(() =>
      onRefetch().then(() => {
        message.success(i18n.t('FEEDBACK_CHANGES_SAVED_SUCCESS'), 8)
        onClose()
      }),
    )
    .catch((error) => {
      notification.error({
        message: i18n.t('FEEDBACK_DEFAULT_ERROR'),
        description: generateMessageError(error, 'FEEDBACK_SAVE_CHANGES_FAIL'),
        duration: 0,
      })
      onClose()
    })
}

export const onSetPin = ({ editCurrencies, setPin }) => {
  editCurrencies()
    .then(() => {
      message.success(i18n.t('FEEDBACK_CHANGES_SAVED_SUCCESS'), 8)
      setPin()
    })
    .catch(() => {
      message.error(i18n.t('FEEDBACK_SAVE_CHANGES_FAIL'), 8)
    })
}
