import React from 'react'
import { Route, Switch } from 'react-router-dom'
import ProcessesList from './ProcessesList/ProcessesList'

const Home = () => (
  <Switch>
    <Route exact path="/procesosdecarga" component={ProcessesList} />
  </Switch>
)

export default Home
