import _ from 'lodash'
import React from 'react'
import TableMultiRenderer from './Renderers/Base/TableMultiRenderer'
import { flatKey, PivotData, redColorScaleGenerator } from './Utilities'

function makeRenderer(opts = {}) {
  class TableRenderer extends React.PureComponent {
    constructor(props) {
      super(props)

      // We need state to record which entries are collapsed and which aren't.
      // This is an object with flat-keys indicating if the corresponding rows
      // should be collapsed.
      this.state = { collapsedRows: {}, collapsedCols: {} }
    }

    getBasePivotSettings() {
      const props = this.props
      const colAttrs = props.cols
      const rowAttrs = props.rows

      const tableOptions = Object.assign(
        {
          rowTotals: true,
          colTotals: true,
        },
        props.tableOptions,
      )

      const rowTotals = tableOptions.rowTotals || colAttrs.length === 0
      const colTotals = tableOptions.colTotals || rowAttrs.length === 0

      const subtotalOptions = Object.assign(
        {
          arrowCollapsed: '⨁',
          arrowExpanded: 'ⴱ',
        },
        props.subtotalOptions,
      )

      const colSubtotalDisplay = Object.assign(
        {
          displayOnTop: false,
          enabled: rowTotals,
          hideOnExpand: false,
        },
        subtotalOptions.colSubtotalDisplay,
      )

      const rowSubtotalDisplay = Object.assign(
        {
          displayOnTop: true,
          enabled: colTotals,
          hideOnExpand: false,
        },
        subtotalOptions.rowSubtotalDisplay,
      )

      const pivotData = new PivotData(
        props,
        !opts.subtotals
          ? {}
          : {
              rowEnabled: rowSubtotalDisplay.enabled,
              colEnabled: colSubtotalDisplay.enabled,
              rowPartialOnTop: rowSubtotalDisplay.displayOnTop,
              colPartialOnTop: colSubtotalDisplay.displayOnTop,
            },
      )

      const rowKeys = pivotData.getRowKeys()
      const colKeys = pivotData.getColKeys()
      // Also pre-calculate all the callbacks for cells, etc... This is nice to have to
      // avoid re-calculations of the call-backs on cell expansions, etc...
      const cellCallbacks = {}
      const rowTotalCallbacks = {}
      const colTotalCallbacks = {}
      let grandTotalCallback = null

      // Custom lines
      const realMonths = !_.isEmpty(tableOptions?.realMonths) ? tableOptions.realMonths : []
      const visibleTotal = tableOptions?.visibleTotal != null ? tableOptions?.visibleTotal : true

      return Object.assign(
        {
          pivotData,
          colAttrs,
          rowAttrs,
          colKeys,
          rowKeys,
          rowTotals,
          colTotals,
          arrowCollapsed: subtotalOptions.arrowCollapsed,
          arrowExpanded: subtotalOptions.arrowExpanded,
          colSubtotalDisplay,
          rowSubtotalDisplay,
          cellCallbacks,
          rowTotalCallbacks,
          colTotalCallbacks,
          grandTotalCallback,
          realMonths,
          visibleTotal,
        },
        TableRenderer.heatmapMappers(
          pivotData,
          props.tableColorScaleGenerator,
          colTotals,
          rowTotals,
        ),
      )
    }

    clickHandler(pivotData, rowValues, colValues) {
      const colAttrs = this.props.cols
      const rowAttrs = this.props.rows
      const value = pivotData.getAggregator(rowValues, colValues).value()
      const filters = {}
      const colLimit = Math.min(colAttrs.length, colValues.length)
      for (let i = 0; i < colLimit; i++) {
        const attr = colAttrs[i]
        if (colValues[i] !== null) {
          filters[attr] = colValues[i]
        }
      }
      const rowLimit = Math.min(rowAttrs.length, rowValues.length)
      for (let i = 0; i < rowLimit; i++) {
        const attr = rowAttrs[i]
        if (rowValues[i] !== null) {
          filters[attr] = rowValues[i]
        }
      }
      return (e) =>
        this.props.tableOptions.clickCallback(e, value, filters, pivotData, pivotData.props.vals[0])
    }

    collapseAttr(rowOrCol, attrIdx, allKeys) {
      return () => {
        // Collapse an entire attribute.

        const keyLen = attrIdx + 1
        const collapsed = allKeys.filter((k) => k.length === keyLen).map(flatKey)

        const updates = {}
        collapsed.forEach((k) => {
          updates[k] = true
        })

        if (rowOrCol) {
          this.setState((state) => ({
            collapsedRows: Object.assign({}, state.collapsedRows, updates),
          }))
        } else {
          this.setState((state) => ({
            collapsedCols: Object.assign({}, state.collapsedCols, updates),
          }))
        }
      }
    }

    expandAttr(rowOrCol, attrIdx, allKeys) {
      return () => {
        // Expand an entire attribute. This implicitly implies expanding all of the
        // parents as well. It's a bit inefficient but ah well...

        const updates = {}
        allKeys.forEach((k) => {
          for (let i = 0; i <= attrIdx; i++) {
            updates[flatKey(k.slice(0, i + 1))] = false
          }
        })

        if (rowOrCol) {
          this.setState((state) => ({
            collapsedRows: Object.assign({}, state.collapsedRows, updates),
          }))
        } else {
          this.setState((state) => ({
            collapsedCols: Object.assign({}, state.collapsedCols, updates),
          }))
        }
      }
    }

    toggleRowKey(flatRowKey) {
      return () => {
        this.setState((state) => ({
          collapsedRows: Object.assign({}, state.collapsedRows, {
            [flatRowKey]: !state.collapsedRows[flatRowKey],
          }),
        }))
      }
    }

    toggleColKey(flatColKey) {
      return () => {
        this.setState((state) => ({
          collapsedCols: Object.assign({}, state.collapsedCols, {
            [flatColKey]: !state.collapsedCols[flatColKey],
          }),
        }))
      }
    }

    calcAttrSpans(attrArr, numAttrs) {
      // Given an array of attribute values (i.e. each element is another array with
      // the value at every level), compute the spans for every attribute value at
      // every level. The return value is a nested array of the same shape. It has
      // -1's for repeated values and the span number otherwise.

      const spans = []
      // Index of the last new value
      const li = Array(numAttrs).map(() => 0)
      let lv = Array(numAttrs).map(() => null)
      for (let i = 0; i < attrArr.length; i++) {
        // Keep increasing span values as long as the last keys are the same. For
        // the rest, record spans of 1. Update the indices too.
        const cv = attrArr[i]
        const ent = []
        let depth = 0
        const limit = Math.min(lv.length, cv.length)
        while (depth < limit && lv[depth] === cv[depth]) {
          ent.push(-1)
          spans[li[depth]][depth]++
          depth++
        }
        while (depth < cv.length) {
          li[depth] = i
          ent.push(1)
          depth++
        }
        spans.push(ent)
        lv = cv
      }
      return spans
    }

    static heatmapMappers(pivotData, colorScaleGenerator, colTotals, rowTotals) {
      let valueCellColors = () => {}
      let rowTotalColors = () => {}
      let colTotalColors = () => {}
      if (opts.heatmapMode) {
        if (colTotals) {
          const colTotalValues = Object.values(pivotData.colTotals).map((a) => a.value())
          colTotalColors = colorScaleGenerator(colTotalValues)
        }
        if (rowTotals) {
          const rowTotalValues = Object.values(pivotData.rowTotals).map((a) => a.value())
          rowTotalColors = colorScaleGenerator(rowTotalValues)
        }
        if (opts.heatmapMode === 'full') {
          const allValues = []
          Object.values(pivotData.tree).map((cd) =>
            Object.values(cd).map((a) => allValues.push(a.value())),
          )
          const colorScale = colorScaleGenerator(allValues)
          valueCellColors = (r, c, v) => colorScale(v)
        } else if (opts.heatmapMode === 'row') {
          const rowColorScales = {}
          Object.entries(pivotData.tree).map(([rk, cd]) => {
            const rowValues = Object.values(cd).map((a) => a.value())
            rowColorScales[rk] = colorScaleGenerator(rowValues)
          })
          valueCellColors = (r, c, v) => rowColorScales[flatKey(r)](v)
        } else if (opts.heatmapMode === 'col') {
          const colColorScales = {}
          const colValues = {}
          Object.values(pivotData.tree).map((cd) =>
            Object.entries(cd).map(([ck, a]) => {
              if (!(ck in colValues)) {
                colValues[ck] = []
              }
              colValues[ck].push(a.value())
            }),
          )
          for (const k in colValues) {
            colColorScales[k] = colorScaleGenerator(colValues[k])
          }
          valueCellColors = (r, c, v) => colColorScales[flatKey(c)](v)
        }
      }
      return { valueCellColors, rowTotalColors, colTotalColors }
    }

    renderColHeaderRow(attrName, attrIdx, pivotSettings) {
      const {
        rowAttrs,
        colAttrs,
        colKeys,
        visibleColKeys,
        colAttrSpans,
        rowTotals,
        arrowExpanded,
        arrowCollapsed,
        colSubtotalDisplay,
        maxColVisible,
        realMonths,
      } = pivotSettings

      const needToggle =
        opts.subtotals && colSubtotalDisplay.enabled && attrIdx !== colAttrs.length - 1
      let clickHandle = null
      let subArrow = null
      if (needToggle) {
        clickHandle =
          attrIdx + 1 < maxColVisible
            ? this.collapseAttr(false, attrIdx, colKeys)
            : this.expandAttr(false, attrIdx, colKeys)
        subArrow = (attrIdx + 1 < maxColVisible ? arrowExpanded : arrowCollapsed) + ' '
      }
      const attrNameCell = (
        <th
          key="label"
          className="pvtAxisLabel width-min-250"
          onClick={clickHandle}
          style={{ textAlign: 'right' }}
        >
          {subArrow}
          {attrName}
        </th>
      )

      const attrValueCells = []
      const rowIncrSpan = rowAttrs.length !== 0 ? 1 : 0
      // Iterate through columns. Jump over duplicate values.
      let i = 0
      while (i < visibleColKeys.length) {
        const colKey = visibleColKeys[i]
        const colSpan = attrIdx < colKey.length ? colAttrSpans[i][attrIdx] : 1
        if (attrIdx < colKey.length) {
          const rowSpan = 1 + (attrIdx === colAttrs.length - 1 ? rowIncrSpan : 0)
          const flatColKey = flatKey(colKey.slice(0, attrIdx + 1))
          const onClick = needToggle ? this.toggleColKey(flatColKey) : null

          let hasRealMonth = false
          if (realMonths.length > 0) {
            hasRealMonth = this.checkDateInReal(colKey.slice(0, attrIdx + 1), realMonths)
          }

          attrValueCells.push(
            <th
              className="pvtColLabel"
              key={'colKey-' + flatColKey}
              colSpan={colSpan}
              rowSpan={rowSpan}
              onClick={onClick}
              style={{ ...(hasRealMonth ? { backgroundColor: '#eaeaea' } : {}) }}
            >
              {needToggle
                ? (this.state.collapsedCols[flatColKey] ? arrowCollapsed : arrowExpanded) + ' '
                : null}
              {colKey[attrIdx]}
            </th>,
          )
        } else if (attrIdx === colKey.length) {
          const rowSpan = colAttrs.length - colKey.length + rowIncrSpan
          const hasRealMonth = this.checkDateInReal(colKey, realMonths)
          attrValueCells.push(
            <th
              className="pvtColLabel"
              key={'colKeyBuffer-' + flatKey(colKey)}
              colSpan={colSpan}
              rowSpan={rowSpan}
              style={{ ...(hasRealMonth ? { backgroundColor: '#eaeaea' } : {}) }}
            >
              Subtotal
            </th>,
          )
        }
        // The next colSpan columns will have the same value anyway...
        i = i + colSpan
      }

      const totalCell =
        attrIdx === 0 && rowTotals ? (
          <th
            key="total"
            className="pvtTotalLabel"
            rowSpan={colAttrs.length + Math.min(rowAttrs.length, 1)}
          >
            Total
          </th>
        ) : null

      const cells = [attrNameCell, ...attrValueCells, totalCell]
      return <tr key={`colAttr-${attrIdx}`}>{cells}</tr>
    }

    renderRowHeaderRow(pivotSettings) {
      // Render just the attribute names of the rows (the actual attribute values
      // will show up in the individual rows).

      const {
        rowAttrs,
        rowKeys,
        arrowCollapsed,
        arrowExpanded,
        rowSubtotalDisplay,
        maxRowVisible,
      } = pivotSettings

      const rowLabel = rowAttrs.map((it) => it).join(' / ')
      const i = 0
      const needLabelToggle =
        opts.subtotals && rowSubtotalDisplay.enabled && i !== rowAttrs.length - 1
      let clickHandle = null
      let subArrow = null
      if (needLabelToggle) {
        clickHandle =
          i + 1 < maxRowVisible
            ? this.collapseAttr(true, i, rowKeys)
            : this.expandAttr(true, i, rowKeys)
        subArrow = (i + 1 < maxRowVisible ? arrowExpanded : arrowCollapsed) + ' '
      }

      return (
        <tr key="rowHdr">
          <th className="pvtAxisLabel width-min-250" key={`rowAttr-${i}`} onClick={clickHandle}>
            {subArrow} {rowLabel}
          </th>
        </tr>
      )
    }

    checkDateInReal(colKey, realMonths) {
      const pattern = /^\d{4}-\d{2}$/
      const baseSet = new Set(realMonths)
      const foundItem = _.find(colKey, (it) => pattern.test(it) && baseSet.has(it))
      return !!foundItem
    }

    renderTableRow(rowKey, rowIdx, pivotSettings) {
      // Render a single row in the pivot table.

      const {
        rowAttrs,
        rowAttrSpans,
        visibleColKeys,
        pivotData,
        rowTotals,
        valueCellColors,
        rowTotalColors,
        arrowExpanded,
        arrowCollapsed,
        rowTotalCallbacks,
        realMonths,
      } = pivotSettings

      const flatRowKey = flatKey(rowKey)
      const attrValueCells = rowKey.map((r, i) => {
        const rowSpan = rowAttrSpans[rowIdx][i]
        if (rowSpan > 0) {
          const flatRowKey = flatKey(rowKey.slice(0, i + 1))
          const needRowToggle = opts.subtotals && i !== rowAttrs.length - 1
          const onClick = needRowToggle ? this.toggleRowKey(flatRowKey) : null

          return (
            <th
              key={`rowKeyLabel-${i}`}
              className={`pvtRowLabel pl-${i} width-min-250`}
              rowSpan={1}
              onClick={onClick}
            >
              {needRowToggle
                ? (this.state.collapsedRows[flatRowKey] ? arrowCollapsed : arrowExpanded) + ' '
                : null}
              {r}
            </th>
          )
        }
        return null
      })

      const valueCells = visibleColKeys.map((colKey) => {
        const flatColKey = flatKey(colKey)
        const agg = pivotData.getAggregator(rowKey, colKey)
        const aggValue = agg.value()
        const style = valueCellColors(rowKey, colKey, aggValue)

        let hasRealMonth = false
        let handleClick = this.clickHandler(pivotData, rowKey, colKey)
        if (realMonths.length > 0) {
          hasRealMonth = this.checkDateInReal(colKey, realMonths)
          if (hasRealMonth) {
            handleClick = () => {}
          }
        }

        return (
          <td
            className="pvtVal"
            key={'pvtVal-' + flatColKey}
            onClick={handleClick}
            style={{ ...(style ? style : hasRealMonth ? { backgroundColor: '#eaeaea' } : {}) }}
          >
            {agg.format(aggValue)}
          </td>
        )
      })

      let totalCell = null
      if (rowTotals) {
        const agg = pivotData.getAggregator(rowKey, [])
        const aggValue = agg.value()
        const style = rowTotalColors(aggValue)
        totalCell = (
          <td
            key="total"
            className="pvtTotal"
            onClick={rowTotalCallbacks[flatRowKey]}
            style={style}
          >
            {agg.format(aggValue)}
          </td>
        )
      }

      const rowCells = [...attrValueCells, ...valueCells, totalCell]

      return <tr key={'keyRow-' + flatRowKey}>{rowCells}</tr>
    }

    renderTotalsRow(pivotSettings) {
      // Render the final totals rows that has the totals for all the columns.

      const {
        visibleColKeys,
        colTotalColors,
        rowTotals,
        pivotData,
        colTotalCallbacks,
        grandTotalCallback,
        realMonths,
      } = pivotSettings

      const totalLabelCell = (
        <th key="label" className="pvtTotalLabel width-min-250">
          TOTAL
        </th>
      )

      const totalValueCells = visibleColKeys.map((colKey) => {
        const flatColKey = flatKey(colKey)
        const agg = pivotData.getAggregator([], colKey)
        const aggValue = agg.value()
        const style = colTotalColors([], colKey, aggValue)

        let hasRealMonth = false
        if (realMonths.length > 0) {
          hasRealMonth = this.checkDateInReal(colKey, realMonths)
        }

        return (
          <td
            className="pvtTotal"
            key={'total-' + flatColKey}
            onClick={colTotalCallbacks[flatColKey]}
            style={{ ...(style ? style : hasRealMonth ? { backgroundColor: '#eaeaea' } : {}) }}
          >
            {agg.format(aggValue)}
          </td>
        )
      })

      let grandTotalCell = null
      if (rowTotals) {
        const agg = pivotData.getAggregator([], [])
        const aggValue = agg.value()
        grandTotalCell = (
          <td key="total" className="pvtGrandTotal" onClick={grandTotalCallback}>
            {agg.format(aggValue)}
          </td>
        )
      }

      const totalCells = [totalLabelCell, ...totalValueCells, grandTotalCell]

      return <tr key="total">{totalCells}</tr>
    }

    visibleKeys(keys, collapsed, numAttrs, subtotalDisplay) {
      return keys.filter(
        (key) =>
          // Is the key hidden by one of its parents?
          !key.some((k, j) => collapsed[flatKey(key.slice(0, j))]) &&
          // Leaf key.
          (key.length === numAttrs ||
            // Children hidden. Must show total.
            flatKey(key) in collapsed ||
            // Don't hide totals.
            !subtotalDisplay.hideOnExpand),
      )
    }

    render() {
      if (this.cachedProps !== this.props) {
        this.cachedProps = this.props
        this.cachedBasePivotSettings = this.getBasePivotSettings()
      }

      const {
        colAttrs,
        rowAttrs,
        rowKeys,
        colKeys,
        colTotals,
        rowSubtotalDisplay,
        colSubtotalDisplay,
        visibleTotal,
      } = this.cachedBasePivotSettings

      // Need to account for exclusions to compute the effective row
      // and column keys.
      const visibleRowKeys = opts.subtotals
        ? this.visibleKeys(rowKeys, this.state.collapsedRows, rowAttrs.length, rowSubtotalDisplay)
        : rowKeys
      const visibleColKeys = opts.subtotals
        ? this.visibleKeys(colKeys, this.state.collapsedCols, colAttrs.length, colSubtotalDisplay)
        : colKeys
      const pivotSettings = Object.assign(
        {
          visibleRowKeys,
          maxRowVisible: Math.max(...visibleRowKeys.map((k) => k.length)),
          visibleColKeys,
          maxColVisible: Math.max(...visibleColKeys.map((k) => k.length)),
          rowAttrSpans: this.calcAttrSpans(visibleRowKeys, rowAttrs.length),
          colAttrSpans: this.calcAttrSpans(visibleColKeys, colAttrs.length),
        },
        this.cachedBasePivotSettings,
      )

      return (
        <table className="pvtTable">
          <thead>
            {colAttrs.map((it, index) => this.renderColHeaderRow(it, index, pivotSettings))}
            {rowAttrs.length !== 0 && this.renderRowHeaderRow(pivotSettings)}
          </thead>
          <tbody>
            {visibleRowKeys.map((r, i) => this.renderTableRow(r, i, pivotSettings))}
            {visibleTotal && colTotals && this.renderTotalsRow(pivotSettings)}
          </tbody>
        </table>
      )
    }
  }

  TableRenderer.defaultProps = PivotData.defaultProps
  TableRenderer.propTypes = PivotData.propTypes
  TableRenderer.defaultProps.tableColorScaleGenerator = redColorScaleGenerator
  TableRenderer.defaultProps.tableOptions = {}
  return TableRenderer
}

class TSVExportRenderer extends React.PureComponent {
  render() {
    const pivotData = new PivotData(this.props)
    const rowKeys = pivotData.getRowKeys()
    const colKeys = pivotData.getColKeys()
    if (rowKeys.length === 0) {
      rowKeys.push([])
    }
    if (colKeys.length === 0) {
      colKeys.push([])
    }

    const baseRow = _.cloneDeep(pivotData.props.rows)
    const headerRow = baseRow.map((r) => r)
    if (colKeys.length === 1 && colKeys[0].length === 0) {
      headerRow.push(this.props.aggregatorName)
    } else {
      colKeys.map((c) => headerRow.push(c.join('-')))
    }

    const result = rowKeys.map((r) => {
      const row = r.map((x) => x)
      colKeys.map((c) => {
        const agg = pivotData.getAggregator(r, c)
        const aggValue = agg.value()
        const v = agg.format(aggValue)
        row.push(v ? v : '0,00')
      })
      return row
    })

    result.unshift(headerRow)

    return (
      <textarea
        value={result.map((r) => r.join('\t')).join('\n')}
        style={{ width: '100%', height: window.innerHeight / 2 }}
        readOnly={true}
      />
    )
  }
}

TSVExportRenderer.defaultProps = PivotData.defaultProps
TSVExportRenderer.propTypes = PivotData.propTypes

export default {
  'Table': makeRenderer({ subtotals: true }),
  'Table PXQ': TableMultiRenderer,
  'Table Heatmap': makeRenderer({ heatmapMode: 'full', subtotals: true }),
  'Table Col Heatmap': makeRenderer({ heatmapMode: 'col', subtotals: true }),
  'Table Row Heatmap': makeRenderer({ heatmapMode: 'row', subtotals: true }),
  'Exportable TSV': TSVExportRenderer,
}
