import { Alert, Input, Modal, Space } from 'antd'
import { Typography } from 'modules/core/components'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { handleVerify } from './utils'
import QRCode from 'react-qr-code'
import login from 'modules/login'

const QrCodeModal = ({ visible, onCancel, qrCodeData, verifyCode, whoAmI }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [qrCode, setQrCode] = useState('')
  const { t } = useTranslation()

  const onClose = () => {
    setIsLoading(false)
    setQrCode('')
    onCancel()
  }

  const onConfirm = () => onClose()

  return (
    <Modal
      title={t('FIELD_QR_BUTTOM')}
      visible={visible}
      centered={true}
      okText={t('ACTION_ACEPT')}
      onOk={() =>
        handleVerify({
          setIsLoading,
          verifyCode: () => verifyCode({ token: qrCode }),
          onRefetch: () => whoAmI(),
          onClose,
          saveTokens: null,
          onConfirm,
        })
      }
      cancelText={t('ACTION_CANCEL')}
      onCancel={onClose}
      okButtonProps={{ loading: isLoading, disabled: !qrCode }}
      destroyOnClose={true}
      forceRender
    >
      <Space direction="vertical">
        <Alert
          type="info"
          showIcon
          description={t('LOGIN_CONGIF_SCAN_QR_INFORMATIVE_ALERT_DESCRIPTION')}
        />
        {visible && (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <QRCode
              size={50}
              style={{ height: 'auto', maxWidth: '50%', width: '100%' }}
              value={qrCodeData}
              viewBox={`0 0 256 256`}
            />
          </div>
        )}
        <Typography.Body level={3}>{t('LOGIN_CONFIG_ENTER_CODE_TYPOGRAPHY_BODY')}</Typography.Body>
        <Input
          placeholder={t('ACTION_ENTER')}
          style={{ width: '100%' }}
          onChange={(val) => setQrCode(val.target.value)}
        />
      </Space>
    </Modal>
  )
}

const mapDispatchToProps = {
  verifyCode: login.actions.verifyCode,
  whoAmI: login.actions.whoAmI,
}

export default connect(null, mapDispatchToProps)(QrCodeModal)
