import React from 'react'

import './IframeLogin.scss'

const IframeLogin = ({ society }) => {
  return (
    <div className="iframe-login">
      <iframe
        id="Quickbooks"
        title="Quickbooks"
        src={`https://qc.stg.getplika.com/app/${society}/`}
        width="55%"
        height="90px"
        scrolling="no"
        style={{ border: 'none' }}
      ></iframe>
    </div>
  )
}

export default IframeLogin
