import React, { useState } from 'react'
import { Col, Form, Mentions, Modal, Row } from 'antd'
import { Button } from 'modules/core/components'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { CHATBOT } from '../../../core/constants'
import { onCreate } from './utils'
import question from 'modules/question'

const { Option } = Mentions

const CreateQuestionModal = ({ onRefetchQuestionList, createQuestion }) => {
  const [showCreateModal, setShowCreateModal] = useState(false)
  const [isCreating, setIsCreating] = useState(false)
  const [form] = Form.useForm()
  const { t } = useTranslation()

  const onClose = () => {
    form.resetFields()
    setIsCreating(false)
    setShowCreateModal(false)
  }

  const renderButton = () => (
    <Button.Primary title="ACTION_CREATE_NEW" onClick={() => setShowCreateModal(true)} />
  )

  return (
    <>
      {renderButton()}
      <Modal
        title={t('QUESTION_ADD_NEW_ACTION')}
        visible={showCreateModal}
        width={560}
        bodyStyle={{ overflowY: 'auto', maxHeight: 400 }}
        centered
        okText={t('ACTION_CREATE')}
        cancelText={t('ACTION_CANCEL')}
        onOk={() =>
          onCreate({
            form,
            setIsLoading: setIsCreating,
            createQuestion,
            onRefetch: onRefetchQuestionList,
            onClose,
          })
        }
        onCancel={onClose}
        okButtonProps={{ loading: isCreating }}
        cancelButtonProps={{ loading: isCreating }}
      >
        <Form form={form} layout="vertical">
          <Row gutter={24} type="flex" justify="end">
            <Col span={24}>
              <Form.Item
                name="name"
                label={t('FIELD_QUESTION')}
                rules={[
                  {
                    required: true,
                    message: t('REQUIRED_FIELD'),
                  },
                ]}
                extra={t('QUESTION_MESSAGE_HELP_TEXT')}
              >
                <Mentions placeholder={t('ENTER_QUESTION_PLACEHOLDER')} prefix="#">
                  {CHATBOT.CHATBOT_TAGS.map((tag) => (
                    <Option value={tag.key}>{tag.value}</Option>
                  ))}
                </Mentions>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  )
}

const mapDispatchToProps = {
  createQuestion: question.actions.createQuestion,
}

export default connect(null, mapDispatchToProps)(CreateQuestionModal)
