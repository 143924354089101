import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Tabs, Spin } from 'antd'
import { useQuery } from 'modules/core/customHooks'
import { SiderLayout } from 'modules/core/layouts'
import { PeriodNavigator } from 'modules/core/components'
import { TracingList } from './components'
import { isUserAllowed, getOperationsByKeys, sendUserDataToGTM } from 'modules/core/utils'
import { ADJUSTMENTS, PROJECTION, ROLES } from 'modules/core/constants'
import { useParams } from 'react-router-dom'
import { AdjustmentsList } from 'modules/adjustments/containers'
import control from 'modules/control'
import login from 'modules/login'

const { CONTROL__REAL, CONTROL__ADJUSTMENTS } = ROLES

const ControlList = ({ fetchControlList, controlList, loggedUser }) => {
  const [isScreenLoading, setIsScreenLoading] = useState(true)
  const [isDataLoading, setIsDataLoading] = useState(false)
  const [dimensionId, setDimensionId] = useState(null)
  const { t } = useTranslation()
  let query = useQuery()
  let { periodId } = useParams()

  useEffect(() => {
    setIsDataLoading(true)
    fetchControlList({
      period_id: periodId,
      ...(dimensionId && { dimension_id: dimensionId }),
    }).then(() => {
      setIsScreenLoading(false)
      setIsDataLoading(false)
    })
  }, [fetchControlList, periodId, dimensionId])

  useEffect(() => {
    sendUserDataToGTM(loggedUser, '/seguimiento')
  }, [loggedUser])

  const canUserAccessReal = isUserAllowed(getOperationsByKeys([CONTROL__REAL]))
  const canUserAccessAdjustments = isUserAllowed(getOperationsByKeys([CONTROL__ADJUSTMENTS]))

  const showDefaultPanelKey = () => {
    if (canUserAccessReal) {
      return PROJECTION.REAL
    } else if (canUserAccessAdjustments) {
      return ADJUSTMENTS.KEY.ADJUSTMENT_KEY
    }
  }

  const renderHeader = () => <PeriodNavigator items={[{ name: t('LABEL_CONTROL') }]} />

  return (
    <SiderLayout>
      <Spin spinning={isScreenLoading} />
      {!isScreenLoading && (
        <>
          {renderHeader()}
          <Tabs
            defaultActiveKey={query.get('tab') !== null ? query.get('tab') : showDefaultPanelKey()}
            size="small"
          >
            {canUserAccessReal && (
              <Tabs.TabPane tab={t('LABEL_REAL')} key="real">
                {controlList.count > 0 && (
                  <TracingList
                    dataSource={controlList}
                    onFetchProjections={() =>
                      fetchControlList({
                        period_id: periodId,
                        ...(dimensionId && { dimension_id: dimensionId }),
                      })
                    }
                    setDimensionId={setDimensionId}
                    isDataLoading={isDataLoading}
                  />
                )}
              </Tabs.TabPane>
            )}
            {canUserAccessAdjustments && (
              <Tabs.TabPane tab={t('ADJUSTMENT_MANAGMENT_LABEL')} key="ajustes">
                <AdjustmentsList />
              </Tabs.TabPane>
            )}
          </Tabs>
        </>
      )}
    </SiderLayout>
  )
}

const mapStateToProps = (state) => ({
  controlList: control.selectors.getControlList(state),
  loggedUser: login.selectors.getWhoAmI(state),
})

const mapDispatchToProps = {
  fetchControlList: control.actions.fetchControlList,
}

export default connect(mapStateToProps, mapDispatchToProps)(ControlList)
