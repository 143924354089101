import { ADJUSTMENTS } from 'modules/core/constants'
import moment from 'moment'
import numeral from 'numeral'
import i18n from 'i18next'

const columns = () => [
  {
    title: i18n.t('FIELD_TYPE'),
    dataIndex: 'type_ajustment_distribution',
    render: (value) =>
      i18n.t(ADJUSTMENTS.DATA_TYPE.find((el) => el.id === value.toUpperCase())?.name),
  },
  {
    title: i18n.t('LABEL_USER'),
    dataIndex: 'user',
    width: 200,
  },
  {
    title: i18n.t('FIELD_CREATION_DATE'),
    dataIndex: 'created_at',
    render: (value) => moment(value).calendar(),
  },
  // {
  //   title: i18n.t('ADJUSTMENT_DATE_FIELD'),
  //   dataIndex: 'date',
  //   render: value => moment(value).format('MMMM YYYY'),
  // },
  {
    title: i18n.t('ADJUSTMENT_TYPE_FIELD'),
    dataIndex: 'type_ajustment',
    render: (value, row) =>
      row.type_ajustment_distribution === 'distribution'
        ? i18n.t(
            ADJUSTMENTS.OPTIONS_DISTRIBUTION_TYPE.find((el) => el.id === value.toUpperCase()).name,
          )
        : i18n.t(
            ADJUSTMENTS.OPTIONS_ADJUSTMENT_TYPE.find((el) => el.id === value.toUpperCase())?.name,
          ),
  },
  {
    title: i18n.t('FIELD_OBSERVATIONS'),
    dataIndex: 'comment',
    ellipsis: true,
  },
  {
    title: i18n.t('FIELD_AMOUNT'),
    dataIndex: 'amount',
    align: 'right',
    width: 120,
    render: (amount) => `$ ${numeral(amount).format('0,0')}`,
  },
]

export default columns
