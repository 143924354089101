import React from 'react'
import { Table } from 'antd'
import { usePagination } from 'modules/core/customHooks'
import { useTranslation } from 'react-i18next'
import moment from 'moment'

const ActivityTrackingDetail = ({ dataSource, loading }) => {
  const [tablePagination, setTablePagination] = usePagination()
  const { t } = useTranslation()

  const columns = [
    {
      title: t('FIELD_DATE'),
      dataIndex: 'created_at',
      render: (value) =>
        `${moment(value).format('DD/MM/YYYY')} ${t('DATE_AT_TIME')} ${moment(value).format(
          'HH:mm',
        )}`,
    },
    {
      title: t('LABEL_USER'),
      dataIndex: 'user',
    },
    {
      title: t('FIELD_DESCRIPTION'),
      dataIndex: 'message',
    },
  ]

  return (
    <Table
      rowKey="id"
      bordered
      size="small"
      loading={loading}
      onChange={setTablePagination}
      columns={columns}
      dataSource={dataSource}
      pagination={{
        pageSize: tablePagination.page_size,
        current: tablePagination.page,
        total: dataSource.length,
        size: 'small',
      }}
    />
  )
}

export default ActivityTrackingDetail
