import React, { useEffect, useState } from 'react'
import { Col, Row, Space } from 'antd'
import { Typography } from 'modules/core/components'
import { LinksHeadersText } from 'modules/SecurityPolicy/components'
import { dataLinks } from './dataLinks'
import { useTranslation } from 'react-i18next'

const PrivacyPolicy = () => {
  const [targetOffset, setTargetOffset] = useState(undefined)
  const { t } = useTranslation()

  useEffect(() => {
    setTargetOffset(window.innerHeight / 2)
  }, [])

  return (
    <Row gutter={[12, 12]}>
      <Col span={6}>
        <LinksHeadersText
          targetOffset={targetOffset}
          dataLinks={dataLinks.map((el) => {
            return {
              ...el,
              title: t(el.title),
            }
          })}
        />
      </Col>
      <Col span={18}>
        <Space direction="vertical">
          <Typography.Headline level={4}>
            {t('SECURITY_POLICY_PRIVACY_POLICY_TAB_TITLE')}
          </Typography.Headline>
          <Typography.Body>{t('SECURITY_POLICY_PRIVACY_TEXT_1')}</Typography.Body>
          <Typography.Headline id="informacion-personal" level={5}>
            {t('SECURITY_POLICY_PRIVACY_SUBTITLE_1')}
          </Typography.Headline>
          <Typography.Body>{t('SECURITY_POLICY_PRIVACY_TEXT_2')}</Typography.Body>
          <Typography.Headline id="procesamos-datos" level={5}>
            {t('SECURITY_POLICY_PRIVACY_SUBTITLE_2')}
          </Typography.Headline>
          <Typography.Body>{t('SECURITY_POLICY_PRIVACY_TEXT_3')}</Typography.Body>
          <Typography.Headline id="tus-derechos" level={5}>
            {t('SECURITY_POLICY_PRIVACY_SUBTITLE_3')}:
          </Typography.Headline>
          <Typography.Body>{t('SECURITY_POLICY_PRIVACY_TEXT_4')}</Typography.Body>
          <ul>
            <li>
              <Typography.Body>{t('SECURITY_POLICY_PRIVACY_LIST_TEXT_1')}</Typography.Body>
            </li>
            <li>
              <Typography.Body>{t('SECURITY_POLICY_PRIVACY_LIST_TEXT_2')}</Typography.Body>
            </li>
            <li>
              <Typography.Body>{t('SECURITY_POLICY_PRIVACY_LIST_TEXT_3')}</Typography.Body>
            </li>
            <li>
              <Typography.Body>{t('SECURITY_POLICY_PRIVACY_LIST_TEXT_4')}</Typography.Body>
            </li>
            <li>
              <Typography.Body>{t('SECURITY_POLICY_PRIVACY_LIST_TEXT_5')}</Typography.Body>
            </li>
            <li>
              <Typography.Body>{t('SECURITY_POLICY_PRIVACY_LIST_TEXT_6')}</Typography.Body>
            </li>
            <li>
              <Typography.Body>{t('SECURITY_POLICY_PRIVACY_LIST_TEXT_7')}</Typography.Body>
            </li>
            <li>
              <Typography.Body>{t('SECURITY_POLICY_PRIVACY_LIST_TEXT_8')}</Typography.Body>
            </li>
          </ul>
          <Typography.Body>{t('SECURITY_POLICY_PRIVACY_TEXT_5')}</Typography.Body>
          <Typography.Headline id="enlaces-sitios" level={5}>
            {t('SECURITY_POLICY_PRIVACY_SUBTITLE_4')}:
          </Typography.Headline>
          <Typography.Body>{t('SECURITY_POLICY_PRIVACY_TEXT_6')}</Typography.Body>
          <Typography.Headline id="seguridad-informacion" level={5}>
            {t('SECURITY_POLICY_PRIVACY_SUBTITLE_5')}:
          </Typography.Headline>
          <Typography.Body>{t('SECURITY_POLICY_PRIVACY_TEXT_7')}</Typography.Body>
          <Typography.Headline id="divulgacion-legal" level={5}>
            {t('SECURITY_POLICY_PRIVACY_SUBTITLE_6')}:
          </Typography.Headline>
          <Typography.Body>{t('SECURITY_POLICY_PRIVACY_TEXT_8')}</Typography.Body>
          <Typography.Headline id="informacion-contacto" level={5}>
            {t('SECURITY_POLICY_PRIVACY_SUBTITLE_7')}:
          </Typography.Headline>
          <Typography.Body>{t('SECURITY_POLICY_PRIVACY_TEXT_9')}</Typography.Body>
        </Space>
      </Col>
    </Row>
  )
}

export default PrivacyPolicy
