import React from 'react'
import { Tag } from 'antd'
import { useTranslation } from 'react-i18next'
import { PROJECTION } from 'modules/core/constants'

const StatusTag = ({ status, is_closing = false, ...otherProps }) => {
  const { t } = useTranslation()
  return (
    <Tag {...otherProps} color={PROJECTION.STATUS_COLORS[status].color}>
      {PROJECTION.STATUS_COLORS[status].extraName && is_closing
        ? t(PROJECTION.STATUS_COLORS[status].extraName)
        : t(PROJECTION.STATUS_COLORS[status].name)}
    </Tag>
  )
}

export default StatusTag
