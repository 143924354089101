import React, { useEffect, useState } from 'react'
import { Card, Col, Row } from 'antd'
import { connect } from 'react-redux'
import { CardsReportList, ReportChart } from './components'
import { generateSelectOptions } from 'modules/core/utils'
import reports from 'modules/reports'
import SelectFilters from '../SelectFilters/SelectFilters'
import home from 'modules/home'
import _ from 'lodash'

import './ReportListChart.scss'

const ReportListChart = ({
  period_id,
  projectionId,
  analysisList,
  fetchAnalysisList,
  reportStructure,
  fetchReportDetailStructure,
  fetchReportsCards,
  reportCards,
}) => {
  const [isReportsLoading, setIsReportsLoading] = useState(false)
  const [reportId, setReportId] = useState(null)
  const [isLinesReportLoading, setIsLinesReportLoading] = useState(false)
  const [lineReportId, setLineReportId] = useState({ line1: null, line2: null })
  const [reportCardsLoading, setReportCardsLoading] = useState(false)

  useEffect(() => {
    setIsReportsLoading(true)
    fetchAnalysisList({ period_id }).then((response) => {
      setIsReportsLoading(false)
      response.payload.data.length > 0 && setReportId(response.payload.data[0].id)
    })
    return () => setIsLinesReportLoading(false)
  }, [fetchAnalysisList, period_id])

  useEffect(() => {
    if (reportId) {
      setIsLinesReportLoading(true)
      fetchReportDetailStructure(reportId).then((response) => {
        setIsLinesReportLoading(false)
        const totalizerRows = response.payload.data.rows.filter((el) => el.type === 'totalizer')
        if (totalizerRows.length > 0) {
          if (totalizerRows.length >= 2) {
            setLineReportId({
              line1: totalizerRows[totalizerRows.length - 2].id,
              line2: totalizerRows[totalizerRows.length - 1].id,
            })
          } else {
            setLineReportId({ line1: totalizerRows[totalizerRows.length - 1].id, line2: null })
          }
        }
      })
    }
    return () => setIsLinesReportLoading(false)
  }, [fetchReportDetailStructure, reportId])

  useEffect(() => {
    if (reportId && (lineReportId.line1 || lineReportId.line2)) {
      setReportCardsLoading(true)
      fetchReportsCards({
        period_id,
        report_id: reportId,
        projection: projectionId,
        report_row_ids: _.compact([lineReportId.line1, lineReportId.line2]),
      }).then(() => setReportCardsLoading(false))
    }
    return () => setReportCardsLoading(false)
  }, [fetchReportsCards, period_id, reportId, projectionId, lineReportId])

  return (
    <Row gutter={[24, 12]}>
      {analysisList?.values.length > 0 && (
        <>
          <Col span={24}>
            <SelectFilters
              title="LABEL_REPORT"
              loading={isReportsLoading}
              onChange={setReportId}
              value={reportId}
              options={generateSelectOptions({ options: analysisList?.values })}
              style={{ width: 200 }}
            />
          </Col>
          {!reportCardsLoading && !isLinesReportLoading && !isReportsLoading && (
            <Col span={12}>
              <CardsReportList
                reportsCardsList={reportCards}
                reportStructure={reportStructure?.values.filter((el) => el.type === 'totalizer')}
                lineReportId={lineReportId}
                setLineReportId={setLineReportId}
                isLinesReportLoading={isLinesReportLoading}
              />
            </Col>
          )}
          {(reportCardsLoading || isLinesReportLoading || isReportsLoading) &&
            [1, 2].map((index) => (
              <Col span={6} key={index}>
                <Card style={{ minHeight: 255 }} loading={true} />
              </Col>
            ))}
        </>
      )}
      <Col span={analysisList?.values.length > 0 ? 12 : 24}>
        <ReportChart period_id={period_id} projectionId={projectionId} />
      </Col>
    </Row>
  )
}

const mapStateToProps = (state) => ({
  analysisList: reports.selectors.getAnalysisList(state),
  reportStructure: reports.selectors.getReportStructure(state),
  reportCards: home.selectors.getReportCards(state),
})

const mapDispatchToProps = {
  fetchAnalysisList: reports.actions.fetchAnalysisList,
  fetchReportDetailStructure: reports.actions.fetchReportDetailStructure,
  fetchReportsCards: home.actions.fetchReportsCards,
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportListChart)
