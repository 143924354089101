import { message } from 'antd'
import { generateMessageError } from 'modules/core/utils'
import i18n from 'i18next'

export const handleEditFinancial = ({ updatedFinancial, onRefetch }) => {
  message.loading({
    key: 'loading_edit',
    content: i18n.t('FEEDBACK_LOADING_CHANGES'),
  })
  updatedFinancial()
    .then(() => {
      onRefetch()
      message.destroy('loading_edit')
      message.success(i18n.t('FEEDBACK_SAVE_CHANGES_SUCCES', 8))
    })
    .catch((error) => message.error(generateMessageError(error, 'FEEDBACK_DEFAULT_ERROR'), 8))
}
