import { useFeatureIsOn } from '@growthbook/growthbook-react'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Row, Col, Tabs, Spin, notification, message } from 'antd'
import {
  PeriodNavigator,
  ProjectionUploadModal,
  SpreadsheetsModal,
  UploadDraggerScreen,
  CreateTransactionModal,
} from 'modules/core/components'
import configuration from '../../../configuration'
import { Evolution, UserActions } from './components'
import { SiderLayout } from 'modules/core/layouts'
import { CONCEPTS, PROJECTION, ROLES, TAB_KEYS } from 'modules/core/constants'
import { /*GA,*/ checkedUpload, generateMessageError, isUserAllowed } from 'modules/core/utils'
import { connect } from 'react-redux'
import { useDefaultCurrency, usePagination } from 'modules/core/customHooks'
import { handleDeleteAll } from './utils'
import control from 'modules/control'
import moment from 'moment'
import planning from 'modules/planning'
import processes from 'modules/processes'
import _ from 'lodash'

import './ControlDetail.scss'

const {
  CONTROL__REAL__SALES__ABM,
  CONTROL__REAL__EXPENSES__ABM,
  CONTROL__REAL__HUMAN_RESOURCES__ABM,
  CONTROL__REAL__COSTS__ABM,
  CONTROL__REAL__OTHER_5__ABM,
  CONTROL__REAL__OTHER_6__ABM,
} = ROLES

// NOTE: En los roles, los conceptos deberian ser resueltos mediante restricciones de dimensiones y no de operaciones
const CONTROL__REAL__CONCEPT = {
  [CONCEPTS.IDS.SALES_ID]: {
    ABM: CONTROL__REAL__SALES__ABM,
  },
  [CONCEPTS.IDS.EXPENSES_ID]: {
    ABM: CONTROL__REAL__EXPENSES__ABM,
  },
  [CONCEPTS.IDS.COSTS_ID]: {
    ABM: CONTROL__REAL__COSTS__ABM,
  },
  [CONCEPTS.IDS.HUMAN_RESOURCES_ID]: {
    ABM: CONTROL__REAL__HUMAN_RESOURCES__ABM,
  },
  [CONCEPTS.IDS.OTHER_5_ID]: {
    ABM: CONTROL__REAL__OTHER_5__ABM,
  },
  [CONCEPTS.IDS.OTHER_6_ID]: {
    ABM: CONTROL__REAL__OTHER_6__ABM,
  },
}

const ControlDetail = ({
  downloadControlTemplateXLS,
  uploadCotrolFileXLS,
  periodList,
  fetchDataTable,
  dataTable,
  fetchAllDimensions,
  massiveCreateTransaction,
  fetchDataTableAntv,
  dataTableAntv,
  deleteAllTransaction,
  fetchCurrencyList,
  currencyList,
  fetchProcessesList,
}) => {
  const extraConcept = useFeatureIsOn('feature-extra-concept')
  const [activeTab, setActiveTab] = useState(TAB_KEYS.TAB_KEY_EVOLUTION)
  const [isTableLoading, setIsTableLoading] = useState(false)
  const [showUploadModal, setShowUploadModal] = useState(false)
  const [showSpreadsheetsModal, setShowSpreadsheetsModal] = useState(false)
  const [tablePagination, setTablePagination] = usePagination()
  const [dataSelection, setDataSelection] = useState([])
  const [isDeletingAll, setIsDeletingAll] = useState(false)
  const [showDeleteAll, setShowDeleteAll] = useState(false)
  const [typeOfLoad, setTypeOfLoad] = useState(PROJECTION.TYPE_UPLOAD_OPTION_DEFAULT)
  const [showCreateModal, setShowCreateModal] = useState(false)
  const [showProgressBar, setShowProgressBar] = useState(false)
  const [currencyChange, setCurrencyChange] = useState(null)
  const [isDisable, setIsDisable] = useState(false)

  const defaultCurrencyData = useDefaultCurrency()
  let history = useHistory()
  let { periodId, conceptName, conceptId } = useParams()
  const { t } = useTranslation()

  const auxCurrencyId = currencyChange ? currencyChange : defaultCurrencyData?.id

  console.log(isTableLoading)

  useEffect(() => {
    setIsTableLoading(true)
    setShowProgressBar(true)
    fetchDataTableAntv({
      concept_id: conceptId,
      period_id: periodId,
      ...(auxCurrencyId && { currency_id: auxCurrencyId }),
    })
      .then(() => {
        setIsTableLoading(false)
        setTimeout(() => {
          setShowProgressBar(false)
        }, 2000)
      })
      .catch(() => {
        setIsTableLoading(false)
        setShowProgressBar(false)
      })
  }, [fetchDataTableAntv, conceptId, periodId, auxCurrencyId])

  useEffect(() => {
    fetchAllDimensions()
  }, [fetchAllDimensions, periodId, conceptId])

  useEffect(() => {
    fetchCurrencyList()
  }, [fetchCurrencyList])

  const verifyPermission = (conceptId) => {
    const permission = [
      CONCEPTS.IDS.SALES_ID.toString(),
      CONCEPTS.IDS.EXPENSES_ID.toString(),
      CONCEPTS.IDS.COSTS_ID.toString(),
      CONCEPTS.IDS.HUMAN_RESOURCES_ID.toString(),
      CONCEPTS.IDS.OTHER_5_ID.toString(),
      CONCEPTS.IDS.OTHER_6_ID.toString(),
    ]
    if (permission.includes(conceptId.toString())) {
      return isUserAllowed(CONTROL__REAL__CONCEPT[conceptId].ABM)
    } else if (extraConcept) {
      return true
    }

    return false
  }

  const dataPeriod = periodList.find((el) => el.id === parseInt(periodId))
  const canUserUpload = !isTableLoading && verifyPermission(conceptId)

  const renderHeader = () => (
    <PeriodNavigator
      items={[
        { name: t('LABEL_CONTROL'), url: `/seguimiento/${periodId}` },
        { name: conceptName || '' },
      ]}
    />
  )

  const onRefresh = () => {
    setIsTableLoading(true)
    setShowProgressBar(true)
    fetchDataTableAntv({
      concept_id: conceptId,
      period_id: periodId,
      ...(auxCurrencyId && { currency_id: auxCurrencyId }),
    }).then(() => {
      setIsTableLoading(false)
      setTimeout(() => {
        setShowProgressBar(false)
      }, 2000)
      message.success(t('UPDATE_INFORMATION_MESSAGE'), 8)
    })
  }

  const onSuccessUploadFile = (response) => {
    checkedUpload({
      fetchProcessesList,
      setIsDisable,
      dataProjection: {
        id: conceptId,
        module: 'real',
      },
      onRefetch: onRefresh,
    })
    notification.success({
      message: t('FEEDBACK_EVERYTHING_READY'),
      description: (
        <>
          <span>{t('FEEDBACK_UPLOAD_DATA_FILE_SUCCESS')}</span>
          <span
            onClick={() => history.push('/procesosdecarga')}
            style={{ color: '#0047ba', cursor: 'pointer' }}
          >
            {t('LABEL_PROCESS_UPLOAD')}
          </span>
        </>
      ),
      duration: 0,
    })
    // GA.event(GA.ACTIONS.FILE_UPLOAD_REAL);
    // onRefresh()
  }

  const onFailUploadFile = (error) => {
    notification.error({
      key: 'error_file',
      message: t('FEEDBACK_DEFAULT_ERROR'),
      description: generateMessageError(error),
      duration: 0,
    })
    // GA.event(GA.ACTIONS.FILE_UPLOAD_REAL_ERROR);
  }

  const renderEmptyScreen = () => (
    <UploadDraggerScreen
      disabled={!canUserUpload}
      description={t('CONTROL_EMPTY_SCREEN_DRAG_TITLE')}
      downloadTemplate={() =>
        downloadControlTemplateXLS(
          t('CONTOL_TEMPLATE_FILE_NAME', {
            name: conceptName,
            period: dataPeriod.name,
            date: moment().format('lll'),
          }),
          { concept_id: conceptId, period_id: periodId },
        )
      }
      uploadFile={(fileList) =>
        uploadCotrolFileXLS(fileList, {
          period_id: periodId,
          concept_id: conceptId,
          type_file: 'follow_up',
          type_of_load: PROJECTION.TYPE_UPLOAD_OPTION_DEFAULT,
        })
      }
      onSuccessUpload={onSuccessUploadFile}
      onFailUpload={onFailUploadFile}
    />
  )

  const renderControlTables = () => (
    <Tabs defaultActiveKey={activeTab} size="small" onChange={setActiveTab}>
      <Tabs.TabPane tab={t('FIELD_EVOLUTION')} key={TAB_KEYS.TAB_KEY_EVOLUTION}>
        {activeTab === TAB_KEYS.TAB_KEY_EVOLUTION && (
          <Evolution
            dataSource={dataTableAntv}
            isTableLoading={isTableLoading || isDisable}
            onRefresh={onRefresh}
            conceptId={conceptId}
            conceptName={conceptName}
            dataPeriod={dataPeriod}
            periodId={periodId}
            num_pages={dataTable.num_pages}
            tablePagination={tablePagination}
            setTablePagination={setTablePagination}
            dataSelection={dataSelection}
            setDataSelection={setDataSelection}
            canUserUpload={canUserUpload}
            isProgressLoading={isTableLoading}
            showProgressBar={showProgressBar}
            currencyData={
              currencyChange
                ? currencyList.data.find((el) => el.id === currencyChange)
                : defaultCurrencyData
            }
          />
        )}
      </Tabs.TabPane>
    </Tabs>
  )

  const onClose = () => {
    setIsDeletingAll(false)
    setShowDeleteAll(false)
  }

  const renderUserActions = () => (
    <UserActions
      onClickFile={() => setShowUploadModal(true)}
      onClickSpreadsheets={() => setShowSpreadsheetsModal(true)}
      onClickActivity={() =>
        history.push(
          `/seguimiento/${periodId}/${conceptName}/actividad/${conceptId}?type=${PROJECTION.REAL}`,
        )
      }
      disabled={
        !canUserUpload || isDisable
        //   moment(period.end_date).format('YYYY-MM-01') ===
        //     moment(approve_date).format('YYYY-MM-01') ||
      }
      onClickDelete={() => setShowDeleteAll(true)}
      visible={showDeleteAll}
      loading={isDeletingAll}
      onCancelPopConfirm={() => setShowDeleteAll(false)}
      onConfirmPopConfirm={() =>
        handleDeleteAll({
          setIsLoading: setIsDeletingAll,
          deleteAll: () => deleteAllTransaction(periodId, conceptId),
          onRefetch: () =>
            fetchDataTable({
              period_id: periodId,
              concept_id: conceptId,
              page: tablePagination.page,
            }),
          onClose,
        })
      }
      hasCreate={true}
      onClickCreate={() => setShowCreateModal(true)}
      extraButtons={{
        module_name: 'seguimiento',
        submodule_name: conceptId,
      }}
      currencyOptions={currencyList.data}
      onChangeCurrency={(val) => setCurrencyChange(val)}
      defaultCurrency={currencyChange ? currencyChange : defaultCurrencyData?.id}
    />
  )

  const onChangeTypeOfLoad = (e) => {
    setTypeOfLoad(e.target.value)
  }

  return (
    <SiderLayout className="control-detail-container">
      {renderHeader()}
      {!_.isEmpty(dataTableAntv) && (
        <>
          <Row gutter={[8, 24]}>
            <Col span={24}>{renderUserActions()}</Col>
            <Col span={24}>{renderControlTables()}</Col>
          </Row>
          <ProjectionUploadModal
            title={t('CONTROL_UPLOAD_MODAL_TITLE')}
            visible={showUploadModal}
            onCancel={() => setShowUploadModal(false)}
            uploadFile={(fileList, typeOfLoad) =>
              uploadCotrolFileXLS(fileList, {
                period_id: periodId,
                concept_id: conceptId,
                type_of_load: typeOfLoad,
              })
            }
            onSuccessUpload={onSuccessUploadFile}
            onFailUpload={onFailUploadFile}
            downloadTemplateFile={() =>
              downloadControlTemplateXLS(
                t('CONTOL_TEMPLATE_FILE_NAME', {
                  name: conceptName,
                  period: dataPeriod.name,
                  date: moment().format('lll'),
                }),
                { concept_id: conceptId, period_id: periodId },
              )
            }
            enabledTypeOfLoad={true}
            extraInformativeCurrency={true}
          />
          <SpreadsheetsModal
            visible={showSpreadsheetsModal}
            onCancel={() => {
              setShowSpreadsheetsModal(false)
              setTypeOfLoad(PROJECTION.TYPE_UPLOAD_OPTION_DEFAULT)
            }}
            dataSpreadsheets={{
              type_load: 'seguimiento',
              concept_id: conceptId,
              type_of_load: typeOfLoad,
              period_id: periodId,
            }}
            onRefetch={onRefresh}
            onChangeTypeOfLoad={onChangeTypeOfLoad}
            typeOfLoad={typeOfLoad}
            enabledTypeOfLoad={true}
            periodId={periodId}
            conceptId={conceptId}
            setIsDisable={setIsDisable}
            dataProjection={{
              id: conceptId,
              module: 'real',
            }}
          />
          <CreateTransactionModal
            title={t('ACTION_CREATE_TRANSACTION')}
            dataPeriod={dataPeriod}
            visible={showCreateModal}
            onCancel={() => setShowCreateModal(false)}
            onRefetch={onRefresh}
            onCreateTransaction={(values) =>
              massiveCreateTransaction({
                ...values,
                period_id: periodId,
                concept_id: conceptId,
                type_sync_data: typeOfLoad,
              })
            }
            onChangeTypeOfLoad={onChangeTypeOfLoad}
            typeOfLoad={typeOfLoad}
          />
        </>
      )}
      {_.isEmpty(dataTableAntv) && !isTableLoading && renderEmptyScreen()}
    </SiderLayout>
  )
}

const mapStateToProps = (state) => ({
  periodList: planning.selectors.getPeriodList(state),
  dataTable: control.selectors.getDataTable(state),
  dataTableAntv: control.selectors.getDataTableAntv(state),
  currencyList: configuration.selectors.getCurrencyList(state),
})

const mapDispatchToProps = {
  downloadControlTemplateXLS: control.actions.downloadControlTemplateXLS,
  uploadCotrolFileXLS: control.actions.uploadCotrolFileXLS,
  fetchDataTable: control.actions.fetchDataTable,
  fetchAllDimensions: configuration.actions.fetchAllDimensions,
  massiveCreateTransaction: control.actions.massiveCreateTransaction,
  fetchDataTableAntv: control.actions.fetchDataTableAntv,
  deleteAllTransaction: control.actions.deleteTransactionFilterByControl,
  fetchCurrencyList: configuration.actions.fetchCurrencyList,
  fetchProcessesList: processes.actions.fetchProcessesList,
}

export default connect(mapStateToProps, mapDispatchToProps)(ControlDetail)
