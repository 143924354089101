import { CONCEPTS } from 'modules/core/constants'
import i18n from 'i18next'
import _ from 'lodash'

const BUDGET_TYPES = {
  LABEL_SALES: CONCEPTS.IDS.SALES_ID,
  LABEL_EXPENSES: CONCEPTS.IDS.EXPENSES_ID,
  LABEL_COSTS: CONCEPTS.IDS.COSTS_ID,
  LABEL_HUMAN_RESOURCES: CONCEPTS.IDS.HUMAN_RESOURCES_ID,
}

export const renderCondition = ({ collapse, budgetTypeList }) => {
  if (budgetTypeList?.values.length > 3) {
    if (
      collapse.label.includes('OTHER') &&
      budgetTypeList?.values.find((el) => el.id === parseInt(collapse.label.split('_')[1]))
    )
      return collapse
    return !collapse.label.includes('OTHER')
  }
  return !collapse.label.includes('OTHER')
}

export const renderLabel = ({ collapse, budgetTypeList }) => {
  if (budgetTypeList?.values.length > 3) {
    const hasBudgetType = budgetTypeList?.values.find(
      (el) => el.id === parseInt(collapse.label.split('_')[1]),
    )
    if (collapse.label.includes('OTHER') && hasBudgetType) return i18n.t(hasBudgetType.name)
    if (_.keys(BUDGET_TYPES).includes(collapse.label)) {
      return budgetTypeList?.values.find((el) => el.id === BUDGET_TYPES[collapse.label])
        ? budgetTypeList?.values.find((el) => el.id === BUDGET_TYPES[collapse.label]).name
        : ''
    } else {
      return i18n.t(collapse.label)
    }
  }
  return i18n.t(collapse.label)
}
