import { COLUMNS } from 'modules/core/constants'
import i18n from 'i18next'

const columnsComparison = () => [
  {
    title: i18n.t('FIELD_CONCEPT'),
    dataIndex: 'concept',
    fixed: 'left',
    width: COLUMNS.WIDTH.EXPANDABLE,
    ellipsis: true,
  },
  {
    title: 'Base',
    dataIndex: 'base',
    align: 'right',
    width: COLUMNS.WIDTH.REPORT_TRANSACTION,
    ellipsis: true,
  },
  {
    title: 'TopDown',
    dataIndex: 'top_down',
    align: 'right',
    width: COLUMNS.WIDTH.REPORT_TRANSACTION,
  },
  {
    title: 'BottomUp',
    dataIndex: 'bottom_up',
    align: 'right',
    width: COLUMNS.WIDTH.REPORT_TRANSACTION,
    ellipsis: true,
  },
  {
    title: i18n.t('LABEL_CLOSING'),
    dataIndex: 'closing',
    align: 'right',
    width: COLUMNS.WIDTH.REPORT_TRANSACTION,
    ellipsis: true,
  },
]

export default columnsComparison
