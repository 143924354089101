import React from 'react'
import { Card, Space, Tooltip } from 'antd'
import { Typography } from 'modules/core/components'
import { useTranslation } from 'react-i18next'
import { ExclamationCircleOutlined, HomeOutlined, InfoCircleOutlined } from '@ant-design/icons'

const ConfigurationCard = ({
  title,
  total,
  onClick = null,
  renderListItem = null,
  disabled = false,
  itemKey,
  tooltipText = '',
}) => {
  const { t } = useTranslation()

  const renderTooltipTitle = () => (
    <span>
      {t('CONFIG_CARD_DISABLED_INFORMATIVE_ICON_TOOLTIP_TITLE_1')}{' '}
      <Typography.Icon level={2} icon={HomeOutlined} />{' '}
      {t('CONFIG_CARD_DISABLED_INFORMATIVE_ICON_TOOLTIP_TITLE_2')}
    </span>
  )

  const renderTitle = () => {
    if (tooltipText) {
      return (
        <Space direction="horizontal">
          {title}
          <Tooltip title={tooltipText}>
            <Typography.Icon style={{ zIndex: 1 }} level={2} icon={InfoCircleOutlined} />
          </Tooltip>
        </Space>
      )
    }

    return title
  }

  return (
    <div className={itemKey}>
      <Card
        className={`configuration-card ${disabled ? 'disabled' : ''}`}
        headStyle={{ minHeight: 56 }}
        title={
          <div
            onClick={!disabled ? onClick : null}
            className="configuration-card-title"
            data-hoverable={onClick !== null}
          >
            {renderTitle()}
          </div>
        }
        extra={
          disabled ? (
            <Tooltip title={renderTooltipTitle()}>
              <Typography.Icon icon={ExclamationCircleOutlined} />
            </Tooltip>
          ) : null
        }
      >
        {total !== 0 ? (
          <div className="configuration-card-body">
            <Typography.Headline
              level={5}
              onClick={!disabled ? onClick : null}
              data-hoverable={onClick !== null}
            >
              {total}
            </Typography.Headline>
            {renderListItem && <ul className="configuration-card-list">{renderListItem}</ul>}
          </div>
        ) : (
          <Space className="info-empty-card" direction="vertical" size="small">
            <Typography.Body level={1} type={'primary'}>
              {t('EMPTY_STATE_NO_DATA_TITLE')}
            </Typography.Body>
            <Typography.Body level={2} type={'secondary'}>
              {t('CONFIG_CARD_EMPTY_STATE_TEXT', {
                config: t(title).toLowerCase(),
              })}
            </Typography.Body>
          </Space>
        )}
      </Card>
    </div>
  )
}

export default ConfigurationCard
