import { message, notification } from 'antd'
import { generateMessageError } from 'modules/core/utils'
import { tokenutils } from 'modules/login/actions'
import i18n from 'i18next'

export const onSubmitChange = ({
  setIsLoading,
  confirmPassword,
  onLogout,
  onClose,
  onRedirect,
}) => {
  setIsLoading(true)
  confirmPassword()
    .then(() =>
      onLogout().then(() => {
        message.success(i18n.t('FEEDBACK_SAVE_CHANGES_SUCCES'), 8)
        onRedirect()
      }),
    )
    .catch((error) => {
      notification.error({
        message: i18n.t('FEEDBACK_DEFAULT_ERROR'),
        description: generateMessageError(error),
        duration: 0,
      })
      onClose()
    })
}

const handleOnClick = (sendEmailSelfResetPassword) => {
  return sendEmailSelfResetPassword()
    .then(() => message.success(i18n.t('FEEDBACK_SEND_MAIL_SUCCESS'), 8))
    .catch((error) => message.error(generateMessageError(error, 'FEEDBACK_SEND_MAIL_ERROR'), 8))
}

export const onSubmitLogin = ({
  setIsLoading,
  onLogin,
  whoAmI,
  onConfirm,
  onRedirect,
  onShowCode,
  email,
  sendEmailSelfResetPassword,
}) => {
  setIsLoading(true)
  onLogin()
    .then((res) => {
      if (res.payload.data.requires_totp_verification) {
        onShowCode()
      } else {
        whoAmI().then((response) => {
          if (!response.payload.data.password_confirmed) {
            onConfirm()
          } else {
            // GA.init(response.payload.data.id);
            // GA.set({
            //   userId: response.payload.data.userId,
            //   society: response.payload.data.society,
            // });
            // GA.startSession();
            // GA.event(GA.ACTIONS.USER_LOGIN);
            onRedirect()
          }
        })
      }
    })
    .catch((error) => {
      tokenutils.removeToken()
      setIsLoading(false)
      notification.error({
        message: i18n.t('FEEDBACK_DEFAULT_ERROR'),
        description: error.response.data.message.includes('User blocked') ? (
          <div>
            <span>{i18n.t('LOGIN_FEEDBACK_USER_BLOCKED_ERROR', { email })}</span>
            <span
              onClick={() => handleOnClick(sendEmailSelfResetPassword)}
              style={{ color: '#0047ba', cursor: 'pointer' }}
            >
              {i18n.t('LOGIN_SEND_EMAIL_FEEDBACK_TEXT')}
            </span>
          </div>
        ) : (
          generateMessageError(error, 'LOGIN_FEEDBACK_ERROR')
        ),
        duration: 0,
      })
    })
}
