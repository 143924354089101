const integrations = {
  INTEGRATION_ENTER_USER_INPUT_PLACEHOLDER: 'Ingresa usuario',
  INTEGRATION_ENTER_PASSWORD_INPUT_PLACEHOLDER: 'Ingresa contraseña',
  INTEGRATION_CONNECTION_DATA_DIVIDER_TEXT: 'Datos para conexión',
  INTEGRATION_URL_FORMITEM_LABEL: 'URL base de datos',
  INTEGRATION_ENTER_URL_INPUT_PLACEHOLDER: 'Ingresa una url',
  INTEGRATION_DATA_BASE_NAME_FORMITEM_LABEL: 'Nombre base de datos',
  INTEGRATION_ENTER_DATA_BASE_INPUT_PLACEHOLDER: 'Ingresa nombre de base de datos',
  INTEGRATION_PORT_FORMITEM_LABEL: 'Puerto',
  INTEGRATION_ENTER_PORT_INPUT_PLACEHOLDER: 'Ingresa puerto',
  INTEGRATION_HOST_FORMITEM_LABEL: 'Host',
  INTEGRATION_ENTER_HOST_INPUT_PLACEHOLDER: 'Ingresa host',
  INTEGRATION_DATA_INTEGRATION_MODAL_TITLE: 'Datos {{name}}',
  INTEGRATIONS_SELECT_SOFTWARE_CONNECT_MODAL_TITLE: 'Selecciona tu software preferido',
  INTEGRATIONS_LINK_SPREADSHEET_INPUT_PLACEHOLDER: 'Ingrese link de hoja spreadsheet',
  INTEGRATIONS_CREDENTIALS_TAB_TITLE: 'Credenciales',
  INTEGRATIONS_FOLDER_ID_SPREADSHEET_INPUT_PLACEHOLDER: 'Ingrese id de la carpeta',
  INTEGRATION_FOLDER_ID_FORMITEM_LABEL: 'Id Carpeta',
}

export default integrations
