import axios from 'axios'

const Constants = {
  baseUrl: process.env.REACT_APP_BOT_ROOT || 'https://chat.stg.getplika.com',
}

const apiAxios = axios.create({ baseURL: Constants.baseUrl })

export async function get(url) {
  return apiAxios.get(url).then((response) => response.data)
}

export async function post(url, data) {
  return apiAxios.post(url, { ...data }).then((response) => response.data)
}
