import React from 'react'
import { Popconfirm } from 'antd'
import { DropdownMenu } from 'modules/core/components'
import { useTranslation } from 'react-i18next'
import { EditOutlined, DeleteOutlined } from '@ant-design/icons'
import _ from 'lodash'

const TableActions = ({
  isSaving,
  title,
  onConfirmPopconfirm,
  onCancelPopconfirm,
  visible,
  onClickEdit,
  onClickDelete,
  disabled,
}) => {
  const { t } = useTranslation()
  return (
    <Popconfirm
      placement="bottomRight"
      title={title}
      okText={t('ACTION_DELETE')}
      onConfirm={onConfirmPopconfirm}
      cancelText={t('ACTION_CANCEL')}
      onCancel={onCancelPopconfirm}
      okButtonProps={{ loading: isSaving, disabled: isSaving }}
      cancelButtonProps={{ disabled: isSaving }}
      visible={visible}
    >
      <DropdownMenu
        title={t('ACTION_MORE')}
        menu={_.compact([
          {
            title: t('ACTION_EDIT'),
            icon: <EditOutlined />,
            onClick: onClickEdit,
            disabled,
          },
          {
            title: t('ACTION_DELETE'),
            icon: <DeleteOutlined />,
            onClick: onClickDelete,
            disabled,
          },
        ])}
      />
    </Popconfirm>
  )
}

export default TableActions
