import {
  Primary,
  Default,
  Icon,
  IconWithType,
  Link,
  LinkWhitLeftIcon,
  LinkWhitRightIcon,
  Text,
  PrimaryForm,
  PrimaryGhost,
  PrimaryBlock,
  PrimaryIcon,
  PrimaryWhitRightIcon,
  PrimaryGhostWhitRightIcon,
  DefaultWhitILeftIcon,
  DefaultUser,
  DefaultWhitRightIcon,
} from './variants'

const Button = {
  Primary,
  Default,
  Icon,
  IconWithType,
  Link,
  LinkWhitLeftIcon,
  LinkWhitRightIcon,
  Text,
  PrimaryForm,
  PrimaryGhost,
  PrimaryBlock,
  PrimaryIcon,
  PrimaryWhitRightIcon,
  PrimaryGhostWhitRightIcon,
  DefaultWhitILeftIcon,
  DefaultUser,
  DefaultWhitRightIcon,
}

export default Button
