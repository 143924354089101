import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import {
  Modal,
  Form,
  Row,
  Col,
  Input,
  Tooltip,
  Select,
  notification,
  message,
  Checkbox,
  Space,
  Spin,
} from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { generateMessageError, generateSelectOptions, isUserAllowed } from 'modules/core/utils'
import reports from 'modules/reports'
import configuration from 'modules/configuration'
import _ from 'lodash'
import Button from '../Button/Button'

const FormItem = Form.Item

const CreateOtherReportModal = ({
  reportData,
  rolesAvailables = [],
  createOtherReport,
  fetchReportStructure,
  reportStructure: { values },
  fetchDimensionsList,
  dimensionsList,
  onFetchOtherReportList,
  onFetchCustomReportConfig = null,
}) => {
  const [showModal, setShowModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isCreating, setIsCreating] = useState(false)
  const [reportSelectedId, setReportSelectedId] = useState(null)
  const { t } = useTranslation()
  const [form] = Form.useForm()

  useEffect(() => {
    if (showModal) {
      setIsLoading(true)
      fetchDimensionsList({ flat: 'True', writeable: 'True' }).then(() => setIsLoading(false))
    }
  }, [fetchDimensionsList, showModal])

  useEffect(() => {
    reportSelectedId && fetchReportStructure(reportSelectedId)
  }, [fetchReportStructure, reportSelectedId])

  const userCanCreate = isUserAllowed(rolesAvailables)

  const onCancel = () => {
    form.resetFields()
    setShowModal(false)
  }

  const onConfirm = () => {
    form
      .validateFields()
      .then((values) => {
        setIsCreating(true)
        //NOTE: dv_filter, graph_type y order_type estan seteados asi provisiorio hasta que se agreguen. No los tiene en cuenta por ahora
        const data = {
          name: values.name,
          report_row: values.report_row,
          dimension: values.dimension,
          neg_values: values.neg_values ? values.neg_values : false,
          dv_filter: null,
          graph_type: 'cake',
          order_type: 'DESC',
        }
        createOtherReport(data)
          .then(() => {
            Promise.all([
              _.compact([
                onFetchCustomReportConfig ? onFetchCustomReportConfig() : null,
                onFetchOtherReportList(),
              ]),
            ]).then(() => {
              message.success(t('FEEDBACK_CHANGES_SAVED_SUCCESS'), 8)
              setIsCreating(false)
              setReportSelectedId(null)
              onCancel()
            })
          })
          .catch((error) => {
            notification.error({
              message: t('FEEDBACK_DEFAULT_ERROR'),
              description: generateMessageError(error, 'FEEDBACK_CREATION_FAIL'),
              duration: 0,
            })
            setIsCreating(false)
            setReportSelectedId(null)
            onCancel()
          })
      })
      .catch((e) => {})
  }

  return (
    <>
      <Button.Primary
        onClick={() => setShowModal(true)}
        disabled={!userCanCreate}
        title="ACTION_CREATE_NEW"
      />
      <Modal
        title={t('CONFIG_REPORT_CREATE_ACTION')}
        visible={showModal}
        onCancel={!isCreating ? onCancel : null}
        destroyOnClose={true}
        centered
        okText={t('ACTION_CREATE')}
        cancelText={t('ACTION_CANCEL')}
        onOk={onConfirm}
        confirmLoading={isCreating}
        okButtonProps={{ disabled: isCreating }}
      >
        <Spin spinning={isLoading}>
          <Form form={form} layout="vertical">
            <Row gutter={[16, 6]}>
              <Col span={24}>
                <FormItem
                  label={t('CONFIG_REPORT_CREATE_MODAL_NAME_LABEL')}
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: t('REQUIRED_FIELD'),
                    },
                  ]}
                >
                  <Input placeholder={t('CONFIG_OTHER_REPORT_CREATE_MODAL_NAME_PLACEHOLDER')} />
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem
                  name="report"
                  rules={[
                    {
                      required: true,
                      message: t('REQUIRED_FIELD'),
                    },
                  ]}
                  label={
                    <span>
                      {t('CONFIG_OTHER_REPORT_SELECT_REPORT_LABEL')}{' '}
                      <Tooltip title="">
                        <InfoCircleOutlined />
                      </Tooltip>
                    </span>
                  }
                >
                  <Select
                    placeholder={t('ACTION_SELECT')}
                    onChange={(id) => {
                      setReportSelectedId(id)
                      form.setFieldsValue({
                        report_row: undefined,
                      })
                    }}
                    options={generateSelectOptions({
                      options: reportData && reportData,
                    })}
                  />
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem
                  name="report_row"
                  rules={[
                    {
                      required: true,
                      message: t('REQUIRED_FIELD'),
                    },
                  ]}
                  label={t('FIELD_LINE_REPORT')}
                >
                  <Select
                    placeholder={t('ACTION_SELECT')}
                    disabled={
                      !reportSelectedId || reportSelectedId !== form.getFieldValue('report')
                    }
                    options={generateSelectOptions({
                      options: values && values,
                    })}
                  />
                </FormItem>
              </Col>
              <Col span={24}>
                <FormItem name="neg_values" valuePropName="checked">
                  <Checkbox>
                    <Space direction="horizontal">
                      {t('REPORT_OTHER_CHECKBOX_TEXT')}
                      <Tooltip title={t('REPORT_OTHER_CHECKBOX_TEXT_TOOTIP')}>
                        <InfoCircleOutlined />
                      </Tooltip>
                    </Space>
                  </Checkbox>
                </FormItem>
              </Col>
              <Col span={24}>
                <FormItem
                  name="dimension"
                  rules={[
                    {
                      required: true,
                      message: t('REQUIRED_FIELD'),
                    },
                  ]}
                  label={t('CONFIG_OTHER_REPORT_SELECT_DIMENSION_LABEL')}
                >
                  <Select
                    placeholder={t('ACTION_SELECT')}
                    allowClear
                    options={generateSelectOptions({
                      options: dimensionsList && dimensionsList?.dimensions,
                    })}
                  />
                </FormItem>
              </Col>
            </Row>
          </Form>
        </Spin>
      </Modal>
    </>
  )
}

const mapStateToProps = (state) => ({
  reportStructure: reports.selectors.getReportStructure(state),
  dimensionsList: configuration.selectors.getDimensionsList(state),
})

const mapDispatchToProps = {
  createOtherReport: configuration.actions.createOtherReport,
  fetchReportStructure: reports.actions.fetchReportStructure,
  fetchDimensionsList: configuration.actions.fetchDimensionsList,
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateOtherReportModal)
