import React from 'react'
import { Col, Form, Row, Switch } from 'antd'
import { useTranslation } from 'react-i18next'
import GeneralFormItems from '../GeneralFormItems/GeneralFormItems'

const LineChartSettings = ({ form, defaultSetting, chart_type }) => {
  const { t } = useTranslation()
  return (
    <Form form={form} layout="horizontal" hideRequiredMark={false} initialValues={defaultSetting}>
      {() => (
        <Row gutter={[8, 8]}>
          <Col span={12}>
            <Form.Item label={t('CHART_VIEW_AREA_SETTINGS')} name="enableArea">
              <Switch />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label={t('CHART_VIEW_POINTS_SETTINGS')} name="enablePoints">
              <Switch checked={true} />
            </Form.Item>
          </Col>
          <GeneralFormItems chart_type={chart_type} />
        </Row>
      )}
    </Form>
  )
}

export default LineChartSettings
