import i18n from 'i18next'
import _ from 'lodash'
import { CHARTS, COLUMNS, ROWS } from 'modules/core/constants'
import moment from 'moment'
import { createSelector } from 'reselect'
import { NAME } from './constants'

export const getReportDetailDefault = (state) => state[NAME].reportDetail
export const getReportComparativeDetailDefault = (state) => state[NAME].reportComparativeDetail
export const getReportList = (state) => state[NAME].reportList
export const getAnalysisList = (state) => state[NAME].analysisList
export const getAnalysisKPIs = (state) => state[NAME].analysisKPIs
export const getAnalysisFilters = (state) => state[NAME].analysisFilters

export const getReportDetail = createSelector(getReportDetailDefault, (reportDetail) => {
  const columns = reportDetail.columns.map((column) => {
    return {
      ...column,
      ...(!column.is_transaction && {
        width:
          column.data_index === COLUMNS.TOTAL_DATA_INDEX
            ? COLUMNS.WIDTH.TRANSACTION
            : COLUMNS.WIDTH.EXPANDABLE,
      }),
      ...(column.is_transaction && {
        width: COLUMNS.WIDTH.TRANSACTION,
      }),
    }
  })

  const data = {
    ...reportDetail,
    columns,
  }
  return data
})

export const getReportComparativeDetail = createSelector(
  getReportComparativeDetailDefault,
  (reportComparativeDetail) => {
    const columns = reportComparativeDetail.columns.map((column) => {
      return {
        ...column,
        ...(!column.is_transaction && {
          width:
            column.data_index === COLUMNS.TOTAL_DATA_INDEX
              ? COLUMNS.WIDTH.TRANSACTION
              : COLUMNS.WIDTH.EXPANDABLE,
        }),
        ...(column.is_transaction && {
          width: COLUMNS.WIDTH.TRANSACTION,
        }),
      }
    })

    const data = {
      ...reportComparativeDetail,
      columns,
    }
    return data
  },
)

export const getReportStructure = (state) => state[NAME].reportStructure

export const getReportChartData = createSelector(getReportDetailDefault, (reportDetail) => {
  // Preparo el eje X
  const horizontalAxis = reportDetail.columns
    .filter((column) => column.is_transaction && column.title !== 'AV')
    .map((column) => column.title)

  // Filtro la maxima row totalizadora.
  const maxReportElement = !reportDetail.lineId
    ? reportDetail.rows
        .filter((elem) => elem.type === ROWS.TOTALIZER)
        .reduce((prev, current) => (prev.y > current.y ? prev : current), {})
    : reportDetail.rows.find((data) => data.id === reportDetail.lineId)

  const valuesAxisY = horizontalAxis.map((period) => {
    // Igualo los periodos para que no haya defasaje de años.
    // TODO: Analizar en periodos mayores a 12 meses.
    const yValue = maxReportElement[period]
    return {
      x: moment(period, 'YYYY-M').format('MMM'),
      y: yValue ? yValue : '0',
    }
  })

  return {
    id: i18n.t('LABEL_REAL'),
    data: valuesAxisY.asMutable({ deep: true }),
  }
  // });
})

export const getReportDahsboardListDefault = (state) => state[NAME].reportDahsboardList

export const getReportDahsboardList = createSelector(
  getReportDahsboardListDefault,
  (reportDahsboardList) => {
    return reportDahsboardList.map((dataDash) => {
      if (dataDash.chart_type === CHARTS.TYPES.PIE) {
        return {
          ...dataDash,
          data: dataDash.data
            ? dataDash.data.map((item) => {
                const auxValue = Math.sign(item.value) === -1 ? item.value * -1 : item.value

                return {
                  ...item,
                  value: auxValue,
                  originValue: item.value,
                }
              })
            : [],
        }
      }
      if (dataDash.chart_type === CHARTS.TYPES.LINE) {
        return {
          ...dataDash,
          data: dataDash.data
            ? dataDash.data.map((item) => {
                return {
                  ...item,
                  data: item.data.map((el) => {
                    const auxValue = Math.sign(el.y) === -1 ? el.y * -1 : el.y
                    return {
                      ...el,
                      y: auxValue,
                      originValue: el.y,
                    }
                  }),
                }
              })
            : [],
        }
      }
      if (dataDash.chart_type === CHARTS.TYPES.BAR) {
        return {
          ...dataDash,
          originValue: dataDash.data,
          data: dataDash.data
            ? dataDash.data.map((item) => {
                const itemsKeys = _.keys(item)

                let auxData = {}

                itemsKeys.forEach((el) => {
                  const auxValue = Math.sign(item[el]) === -1 ? item[el] * -1 : item[el]
                  auxData = {
                    ...auxData,
                    [el]: el !== 'date' ? { value: auxValue, originValue: item[el] } : item[el],
                  }
                })

                return auxData
              })
            : [],
        }
      }
      if (dataDash.chart_type === CHARTS.TYPES.SANKEY) {
        return {
          ...dataDash,
          data: {
            ...dataDash.data,
            links: dataDash.data.links
              ? dataDash.data.links.map((el) => {
                  const auxValue = Math.sign(el.value) === -1 ? el.value * -1 : el.value
                  return {
                    ...el,
                    value: auxValue,
                  }
                })
              : [],
          },
        }
      }
      return dataDash
    })
  },
)

export const getSettingsCharts = (state) => state[NAME].settingsCharts
export const getDashboardUrl = (state) => state[NAME].dashboardUrl

// TODO: NEW ENDPOINTS FOR REVAMP TABLE
export const getDataKpisByReport = (state) => state[NAME].dataKpisByReport
export const getDataGraphByBaseReport = (state) => state[NAME].dataGraphByReport

export const getDataGraphByReport = createSelector(
  getDataGraphByBaseReport,
  (dataGraphByReport) => {
    const horizontalAxis = dataGraphByReport.columns
      .filter((column) => column.is_transaction && column.title !== 'AV')
      .map((column) => column.title)

    const maxReportElement = !dataGraphByReport.lineId
      ? dataGraphByReport.rows
          .filter((elem) => elem.type === ROWS.TOTALIZER)
          .reduce((prev, current) => (prev.y > current.y ? prev : current), {})
      : dataGraphByReport.rows.find((data) => data.id === dataGraphByReport.lineId)

    const valuesAxisY = horizontalAxis.map((period) => {
      const yValue = maxReportElement[period]
      return {
        x: moment(period, 'YYYY-M').format('MMM'),
        y: yValue ? yValue : '0',
      }
    })

    return {
      id: i18n.t('LABEL_REAL'),
      data: valuesAxisY.asMutable({ deep: true }),
    }
  },
)
export const getDataEvolutionByReport = (state) => state[NAME].dataEvolutionReport
export const getDataComparativeByReport = (state) => state[NAME].dataComparativeReport
