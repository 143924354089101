import { notification } from 'antd'
import { projectionSuccessUploadNotice } from 'modules/core/components'
import { generateMessageError } from 'modules/core/utils'
import i18n from 'i18next'

export const functionSearch = (values, searchValue) => {
  const resultado = []
  values.forEach((value) => {
    if (value.name.toLowerCase().includes(searchValue.toLowerCase())) {
      resultado.push(value)
    } else {
      if (value.children) {
        const childrenAux = value.children.filter((childValue) =>
          childValue.name.toLowerCase().includes(searchValue.toLowerCase()),
        )
        if (childrenAux.length !== 0) {
          let valueAux = { ...value, children: childrenAux }
          resultado.push(valueAux)
        }
      }
    }
  })
  return resultado
}

export const onSuccessUploadFile = (response, setIsTableLoading) => {
  projectionSuccessUploadNotice({
    projectionType: 'reporte',
    errorFile: response.payload.data.error_file,
  })
  setIsTableLoading(true)
  // fetchReportDetailStructure(reportId).then(() => setIsTableLoading(false));
}

export const onFailUploadFile = (error) => {
  notification.error({
    key: 'error_file',
    message: i18n.t('FEEDBACK_DEFAULT_ERROR'),
    description: generateMessageError(error),
    duration: 0,
  })
}
