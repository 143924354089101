import React from 'react'
import { Input, Spin, Typography } from 'antd'

const { Text } = Typography

const CardTitle = ({ isEditing, editName, handleEdit, titleValue, style = {} }) => {
  return (
    <Spin spinning={isEditing}>
      {editName ? (
        <Input
          autoFocus
          disabled={isEditing}
          defaultValue={titleValue}
          onBlur={(e) => handleEdit(e.target.value)}
          onPressEnter={(e) => handleEdit(e.target.value)}
        />
      ) : (
        <Text title={titleValue} style={style} ellipsis={true}>
          {titleValue}
        </Text>
      )}
    </Spin>
  )
}

export default CardTitle
