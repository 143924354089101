import React from 'react'
import { Divider, Form, Input } from 'antd'
import { useTranslation } from 'react-i18next'

const ConnectForm = ({ form }) => {
  const { t } = useTranslation()
  return (
    <Form form={form} layout="vertical">
      <Form.Item
        label={t('LABEL_USER')}
        name="user"
        rules={[{ required: true, message: t('REQUIRED_FIELD') }]}
      >
        <Input placeholder={t('INTEGRATION_ENTER_USER_INPUT_PLACEHOLDER')} />
      </Form.Item>
      <Form.Item
        label={t('LOGIN_FORM_INPUT_PASSWORD_PLACEHOLDER')}
        name="password"
        rules={[
          {
            required: true,
            message: t('REQUIRED_FIELD'),
          },
        ]}
      >
        <Input.Password placeholder={t('INTEGRATION_ENTER_PASSWORD_INPUT_PLACEHOLDER')} />
      </Form.Item>
      <Divider orientation="left" orientationmargin="0" plain>
        {t('INTEGRATION_CONNECTION_DATA_DIVIDER_TEXT')}
      </Divider>
      <Form.Item
        label={t('INTEGRATION_DATA_BASE_NAME_FORMITEM_LABEL')}
        name="dbname"
        rules={[{ required: true, message: t('REQUIRED_FIELD') }]}
      >
        <Input placeholder={t('INTEGRATION_ENTER_DATA_BASE_INPUT_PLACEHOLDER')} />
      </Form.Item>
      <Form.Item
        label={t('INTEGRATION_URL_FORMITEM_LABEL')}
        name="url_database"
        rules={[{ required: true, message: t('REQUIRED_FIELD') }]}
      >
        <Input placeholder={t('INTEGRATION_ENTER_URL_INPUT_PLACEHOLDER')} />
      </Form.Item>
    </Form>
  )
}

export default ConnectForm
