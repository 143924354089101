import { Button, ButtonActionBar } from 'modules/core/components'
import { RightCircleOutlined } from '@ant-design/icons'

const CardFooter = ({ onClick }) => {
  return (
    <ButtonActionBar position="start">
      <Button.LinkWhitRightIcon
        onClick={onClick}
        title="ACTION_ENTER"
        icon={<RightCircleOutlined />}
      />
    </ButtonActionBar>
  )
}

export default CardFooter
