import React, { useEffect, useState } from 'react'
import { Col, Row } from 'antd'
import { CONCEPTS, PROJECTION } from 'modules/core/constants'
import { generateSelectOptions } from 'modules/core/utils'
import { connect } from 'react-redux'
import { LineChartFilters, PieChartFilters, RangeMonthsFilters } from './components'
import { useTranslation } from 'react-i18next'
import configuration from 'modules/configuration'
import SelectFilters from '../SelectFilters/SelectFilters'

const ChartsDashboard = ({
  projectionId,
  setProjectionId,
  dataPeriod,
  budgetTypeList,
  fetchBudgetTypeList,
  allDimensionsList,
  fetchAllDimensions,
}) => {
  const [budgetTypeId, setBudgetTypeId] = useState({ pieChart: null, lineChart: null })
  const [isBudgetTypeLoading, setIsBudgetTypeLoading] = useState(false)
  const [isDimensionLoading, setIsDimensionLoading] = useState(false)
  const [dimensionId, setDimensionId] = useState(null)
  const [selectedDates, setSelectedDates] = useState({})

  const { t } = useTranslation()

  useEffect(() => {
    setIsBudgetTypeLoading(true)
    fetchBudgetTypeList().then((response) => {
      setIsBudgetTypeLoading(false)
      const budgetTypeIdAux = response.payload.data.concepts.find(
        (el) => el.id === CONCEPTS.IDS.SALES_ID,
      )?.id
      setBudgetTypeId({ pieChart: budgetTypeIdAux, lineChart: budgetTypeIdAux })
    })
    return () => setIsBudgetTypeLoading(false)
  }, [fetchBudgetTypeList])

  useEffect(() => {
    setIsDimensionLoading(true)
    fetchAllDimensions().then((response) => {
      setIsDimensionLoading(false)
      const dimId = response.payload.data.find((el) => el.name_origin === 'account')
        ? response.payload.data.find((el) => el.name_origin === 'account').id
        : response.payload.data[0].id
      setDimensionId(dimId)
    })
    return () => setIsDimensionLoading(false)
  }, [fetchAllDimensions])

  return (
    <Row gutter={[24, 8]}>
      <Col span={12}>
        <Row gutter={[24, 8]}>
          <Col span={24}>
            <Row gutter={[12, 8]}>
              <Col span={6}>
                <SelectFilters
                  title="LABEL_BUDGET_TYPE"
                  loading={isBudgetTypeLoading}
                  onChange={(val) => setBudgetTypeId({ ...budgetTypeId, pieChart: val })}
                  value={budgetTypeId.pieChart}
                  options={generateSelectOptions({ options: budgetTypeList?.values })}
                />
              </Col>
              <Col span={6}>
                <SelectFilters
                  title="FIELD_DIMENSION"
                  loading={isDimensionLoading}
                  onChange={setDimensionId}
                  value={dimensionId}
                  options={generateSelectOptions({
                    options: allDimensionsList.map((dim) => {
                      return { ...dim, name: dim.name_destination }
                    }),
                  })}
                />
              </Col>
              <Col span={6}>
                <SelectFilters
                  title="LABEL_PROJECTION"
                  loading={isDimensionLoading}
                  onChange={(val) => setProjectionId({ ...projectionId, pieChart: val })}
                  value={projectionId.pieChart}
                  options={generateSelectOptions({
                    options: PROJECTION.OPTIONS_ALL_PROJECTION_TYPE.map((op) => {
                      return { ...op, name: t(op.name) }
                    }),
                  })}
                />
              </Col>
              <Col span={6}>
                <RangeMonthsFilters
                  dataPeriod={dataPeriod}
                  setSelectedDates={setSelectedDates}
                  selectedDates={selectedDates}
                />
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <PieChartFilters
              dimensionId={dimensionId}
              projectionId={projectionId.pieChart}
              budgetTypeId={budgetTypeId.pieChart}
              selectedDates={selectedDates}
              periodId={dataPeriod?.id}
            />
          </Col>
        </Row>
      </Col>
      <Col span={12}>
        <Row gutter={[24, 8]}>
          <Col span={24}>
            <Row gutter={[24, 8]}>
              <Col span={8}>
                <SelectFilters
                  title="LABEL_BUDGET_TYPE"
                  loading={isBudgetTypeLoading}
                  onChange={(val) => setBudgetTypeId({ ...budgetTypeId, lineChart: val })}
                  value={budgetTypeId.lineChart}
                  options={generateSelectOptions({ options: budgetTypeList?.values })}
                />
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <LineChartFilters periodId={dataPeriod?.id} budgetTypeId={budgetTypeId} />
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

const mapStateToProps = (state) => ({
  budgetTypeList: configuration.selectors.getBudgetTypeList(state),
  allDimensionsList: configuration.selectors.getAllDimensionsList(state),
})

const mapDispatchToProps = {
  fetchBudgetTypeList: configuration.actions.fetchBudgetTypeList,
  fetchAllDimensions: configuration.actions.fetchAllDimensions,
}

export default connect(mapStateToProps, mapDispatchToProps)(ChartsDashboard)
