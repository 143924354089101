import { message, notification } from 'antd'
import { generateMessageError } from 'modules/core/utils'
import moment from 'moment'
import i18n from 'i18next'

export const projectionAction = ({
  form,
  setIsLoading,
  action,
  fileList,
  onRefetch,
  onClose,
  onClick,
  renderCheckedUpload = null,
}) => {
  form
    .validateFields()
    .then((values) => {
      setIsLoading(true)
      action(values)
        .then(() => {
          renderCheckedUpload && renderCheckedUpload()
          !renderCheckedUpload && onRefetch()
          if (fileList.length > 0) {
            notification.success({
              message: i18n.t('FEEDBACK_EVERYTHING_READY'),
              description: (
                <>
                  <span>{i18n.t('FEEDBACK_UPLOAD_DATA_FILE_SUCCESS')}</span>
                  <span onClick={onClick} style={{ color: '#0047ba', cursor: 'pointer' }}>
                    {i18n.t('LABEL_PROCESS_UPLOAD')}
                  </span>
                </>
              ),
              duration: 0,
            })
          } else {
            message.success(i18n.t('PLANNING_CREATE_BUDGET_SUCCESS_FEEDBACK'), 8)
          }
          onClose()
        })
        .catch((error) => {
          notification.error({
            message: i18n.t('FEEDBACK_DEFAULT_ERROR'),
            description: generateMessageError(error, 'PLANNING_CREATE_BUDGET_ERROR_FEEDBACK'),
            duration: 0,
          })
          onClose()
        })
    })
    .catch((e) => {})
}

export const disabledDate = (date, periodList, dataPeriod) => {
  const currentYear = moment(dataPeriod.end_date).utc().format('YYYY')
  const startYearsPeriodsCreated = [
    ...new Set(
      periodList
        .asMutable()
        .sort(
          (itemA, itemB) =>
            moment(itemA.start_date).utc().format('YYYY') -
            moment(itemB.start_date).utc().format('YYYY'),
        )
        .map((period) => moment(period.start_date).utc().format('YYYY')),
    ),
  ]

  const endYearsPeriodCreated = [
    ...new Set(
      periodList
        .asMutable()
        .sort(
          (itemA, itemB) =>
            moment(itemA.end_date).utc().format('YYYY') -
            moment(itemB.end_date).utc().format('YYYY'),
        )
        .map((period) => moment(period.end_date).utc().format('YYYY')),
    ),
  ]

  const endYearFilterByCurrentPeriod = endYearsPeriodCreated.filter((el) => el <= currentYear)

  const year = moment(date._d).format('YYYY')

  return (
    year < startYearsPeriodsCreated[0] ||
    year > endYearFilterByCurrentPeriod[endYearFilterByCurrentPeriod.length - 1]
  )
}

export const handleSetFormListValues = (form, values, index) => {
  const fields = form.getFieldsValue()
  const { dimension_list } = fields
  Object.assign(dimension_list[index], { values })
  return form.setFieldsValue({ dimension_list })
}
