import Button from 'modules/core/components/Button/Button'
import ButtonActionBar from 'modules/core/components/ButtonActionBar/ButtonActionBar'

const UserActions = ({ disabled, onClick }) => {
  return (
    <ButtonActionBar>
      <Button.Primary title="ACTION_UPLOAD_FILE" disabled={disabled} onClick={onClick} />
    </ButtonActionBar>
  )
}

export default UserActions
