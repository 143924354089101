import { NAME } from './constants'
import { createSelector } from 'reselect'
import { PROJECTION } from 'modules/core/constants'
import moment from 'moment'
import i18n from 'i18next'

export const getDataFiles = (state) => state[NAME].dataFiles
export const getDataSteps = (state) => state[NAME].dataSteps
export const getDataSelection = (state) => state[NAME].dataSelection
export const getFilesData = (state) => state[NAME].filesData
export const getDataSections = (state) => state[NAME].dataSections
export const getHeadersList = (state) => state[NAME].headersList
export const getDataConnectors = (state) => state[NAME].dataConnectors
export const getStatusConnection = (state) => state[NAME].statusConnection

export const getBudgetTypeCards = (state) => state[NAME].budgetTypeCards
export const getReportCards = (state) => state[NAME].reportCards
export const getDataProjectionChartDefault = (state) => state[NAME].dataProjectionChart
export const getDashboardQuicksight = (state) => state[NAME].dashboardQuicksight

export const getDataProjectionChart = createSelector(
  getDataProjectionChartDefault,
  (dataProjectionChart) => {
    return dataProjectionChart.map((chart) => {
      return {
        ...chart,
        data: chart.data.map((item) => {
          const auxValue = Math.sign(item.y) === -1 ? item.y * -1 : item.y
          return {
            ...item,
            y: auxValue,
            x: moment(item.x, 'YYYY-MM').format('MMM'),
          }
        }),
      }
    })
  },
)

export const getDataBudgetTypeChartDefault = (state) => state[NAME].dataBudgetTypeChart

export const getDataBudgetTypeChart = createSelector(
  getDataBudgetTypeChartDefault,
  (dataBudgetTypeChart) => {
    return dataBudgetTypeChart.map((chart) => {
      return {
        id: i18n.t(PROJECTION.NAME_PROJECTIONS[chart.id]),
        data: chart.data.map((item) => {
          const auxValue = Math.sign(item.y) === -1 ? item.y * -1 : item.y
          return {
            ...item,
            y: auxValue,
            x: moment(item.x, 'YYYY-MM').format('MMM'),
          }
        }),
      }
    })
  },
)

export const getDataDimensionChartDefault = (state) => state[NAME].dataDimensionChart

export const getDataDimensionChart = createSelector(
  getDataDimensionChartDefault,
  (dataDimensionChart) => {
    return dataDimensionChart.map((data) => {
      const auxValue = Math.sign(data.value) === -1 ? data.value * -1 : data.value

      return {
        ...data,
        value: auxValue,
        originValue: data.value,
      }
    })
  },
)
