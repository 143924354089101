import React, { useEffect, useState } from 'react'
import { Col, DatePicker, Form, InputNumber, Modal, Row, Select } from 'antd'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { AddAndDeleteFormItem, TypeUploadOptions } from '../index'
import { onCreate, onDisabledDate } from './utils'
import { generateSelectOptions } from 'modules/core/utils'
import { useFeatureIsOn } from '@growthbook/growthbook-react'
import FooterAddNewSelect from './components/FooterAddNewSelect'
import configuration from '../../../configuration'
import moment from 'moment'
import _ from 'lodash'

import './CreateTransaction.scss'

const SelectDimension = (propsSelect) => {
  const { key, field, dimension, data, setData, fetchDimensionsValues, createDimensionValue } =
    propsSelect

  const [dropdownOpen, setDropdownOpen] = useState({ isDropdownOpen: false, id: null })
  const [isCreating, setIsCreating] = useState(false)
  const [dimensionsValue, setDimensionsValue] = useState([])

  useEffect(() => {
    if (dropdownOpen.isDropdownOpen) {
      fetchDimensionsValues({ id_dimension: dropdownOpen.dimensionId }).then((response) => {
        setDimensionsValue(response.payload.data)
      })
    }
  }, [fetchDimensionsValues, dropdownOpen.isDropdownOpen, dropdownOpen.dimensionId])

  const renderDropdownContent = (menu, infoSelect) => {
    return (
      <FooterAddNewSelect
        menu={menu}
        onRefetch={() => fetchDimensionsValues({ id_dimension: infoSelect.id })}
        setIsCreating={setIsCreating}
        create={(data) =>
          createDimensionValue({ name: data.name, code: data.code, dimension_id: infoSelect.id })
        }
        isCreating={isCreating}
      />
    )
  }

  return (
    <Select
      showSearch
      key={key}
      placeholder={dimension.name_destination}
      onDropdownVisibleChange={(value) =>
        setDropdownOpen({
          isDropdownOpen: value,
          dimensionId: dimension.id,
        })
      }
      options={dimensionsValue.map((option) => {
        return {
          value: parseInt(option.id),
          label: option.name_destination,
        }
      })}
      filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      dropdownRender={(menu) => renderDropdownContent(menu, dimension)}
      onChange={(val) => {
        if (data.length === 0) {
          setData([{ [dimension.id]: val }])
        } else {
          if (data[field.key]) {
            data[field.key] = {
              ...data[field.key],
              [dimension.id]: val,
            }
          } else {
            data.push({ [dimension.id]: val })
          }
        }
      }}
    />
  )
}

const CreateTransactionModal = ({
  listDimensions,
  fetchDimensionsValues,
  createDimensionValue,
  dataPeriod,
  visible,
  onCancel,
  onRefetch,
  onCreateTransaction,
  title,
  dateBlocking = [],
  onChangeTypeOfLoad,
  typeOfLoad,
  fetchCurrencyList,
  currencyList,
}) => {
  const enabled = useFeatureIsOn('feature-moneda-seguimiento')
  const [isLoading, setIsLoading] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [data, setData] = useState([])
  const [form] = Form.useForm()
  const { t } = useTranslation()

  useEffect(() => {
    if (visible) {
      fetchCurrencyList()
    }
  }, [fetchCurrencyList, visible])

  const onClose = () => {
    form.resetFields()
    onCancel()
    setIsLoading(false)
    setIsSaving(false)
    setData([])
  }

  const disabledDate = (date) => {
    const dateToCheck = moment(date._d).utc()
    let result = onDisabledDate(date, dataPeriod)
    if (!_.isEmpty(dateBlocking) && !result) {
      const dateBlockingMoment = dateBlocking.map((it) => moment(it, 'YYYY-MM').utc())
      result = dateBlockingMoment.some((date) => date.isSame(dateToCheck, 'month'))
    }
    return result
  }

  return (
    <Modal
      title={title ? title : t('ACTION_CREATE_NEW')}
      width={1200}
      bodyStyle={{ overflowY: 'auto', maxHeight: 500 }}
      visible={visible}
      centered
      destroyOnClose
      onCancel={onClose}
      onOk={() =>
        onCreate({
          form,
          setIsLoading: setIsSaving,
          createTransaction: () => onCreateTransaction({ transactions: data }),
          onRefetch,
          onClose,
        })
      }
      okText={t('ACTION_CREATE')}
      cancelText={t('ACTION_CANCEL')}
      okButtonProps={{ loading: isSaving }}
      cancelButtonProps={{ disabled: isSaving }}
    >
      <Form
        form={form}
        layout="vertical"
        className={'create-transaction-form'}
        initialValues={{ transactions: [{}] }}
      >
        {() => (
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <TypeUploadOptions onChange={onChangeTypeOfLoad} value={typeOfLoad} typeFilter={1} />
            </Col>
            <Col span={24}>
              <Form.List name="transactions">
                {(fields, { add, remove }) => {
                  return (
                    <div>
                      {fields.map((field, index) => {
                        return (
                          <div key={field.key}>
                            <Row gutter={2} key={field.key} justify="start">
                              {listDimensions.map((dimension) => (
                                <Col span={4} key={`${index}-${dimension.id}-col`}>
                                  <Form.Item
                                    className={'mb-5-form'}
                                    name={[field.name, dimension.id]}
                                    label={[field.label, dimension.name_destination]}
                                  >
                                    <SelectDimension
                                      key={`${index}-${dimension.id}-select`}
                                      field={field}
                                      dimension={dimension}
                                      data={data}
                                      setData={setData}
                                      fetchDimensionsValues={fetchDimensionsValues}
                                      createDimensionValue={createDimensionValue}
                                    />
                                  </Form.Item>
                                </Col>
                              ))}
                              <Col span={4} key={`${index}-cantidad-col`}>
                                <Form.Item
                                  className={'mb-5-form'}
                                  name={[field.name, 'quantity']}
                                  label={[field.label, t('FIELD_COUNT')]}
                                >
                                  <InputNumber
                                    placeholder={t('ENTER_QUANTITY_PLACEHOLDER')}
                                    disabled={data.length > 0 && data[field.key]?.amount}
                                    onChange={(val) => {
                                      data[field.key] = {
                                        ...data[field.key],
                                        quantity: val,
                                      }
                                    }}
                                  />
                                </Form.Item>
                              </Col>
                              <Col span={4} key={`${index}-price-col`}>
                                <Form.Item
                                  className={'mb-5-form'}
                                  name={[field.name, 'price']}
                                  label={[field.label, t('FIELD_PRICE')]}
                                >
                                  <InputNumber
                                    placeholder={t('ENTER_PRICE_PLACEHOLDER')}
                                    disabled={data.length > 0 && data[field.key]?.amount}
                                    onChange={(val) => {
                                      data[field.key] = {
                                        ...data[field.key],
                                        price: val,
                                      }
                                    }}
                                  />
                                </Form.Item>
                              </Col>
                              <Col span={4} key={`${index}-amount-col`}>
                                <Form.Item
                                  className={'mb-5-form'}
                                  name={[field.name, 'amount']}
                                  label={[field.label, t('FIELD_AMOUNT')]}
                                >
                                  <InputNumber
                                    placeholder={t('ENTER_AMOUNT_PLACEHOLDER')}
                                    disabled={
                                      data.length > 0 &&
                                      (data[field.key]?.price || data[field.key]?.quantity)
                                    }
                                    onChange={(val) => {
                                      data[field.key] = {
                                        ...data[field.key],
                                        amount: val,
                                      }
                                    }}
                                  />
                                </Form.Item>
                              </Col>
                              {enabled && (
                                <Col span={4} key={`${index}-currency-col`}>
                                  <Form.Item
                                    className={'mb-5-form'}
                                    name={[field.name, 'currency_id']}
                                    label={[field.label, t('LABEL_CURRENCY')]}
                                  >
                                    <Select
                                      placeholder={t('SELECT_CURRENCY_PLACEHOLDER')}
                                      options={generateSelectOptions({
                                        options: currencyList.data,
                                      })}
                                      onChange={(val) => {
                                        data[field.key] = {
                                          ...data[field.key],
                                          currency_id: val,
                                        }
                                      }}
                                    />
                                  </Form.Item>
                                </Col>
                              )}
                              <Col span={4} key={`${index}-date-col`}>
                                <Form.Item
                                  className={'mb-5-form'}
                                  name={[field.name, 'date']}
                                  label={[field.label, t('FIELD_DATE')]}
                                >
                                  <DatePicker
                                    format="MMM YYYY"
                                    picker="month"
                                    style={{ width: '100%' }}
                                    disabledDate={(date) => disabledDate(date)}
                                    defaultPickerValue={
                                      !_.isEmpty(dataPeriod)
                                        ? moment(dataPeriod?.start_date).utc()
                                        : null
                                    }
                                    onChange={(val) => {
                                      data[field.key] = {
                                        ...data[field.key],
                                        date: val,
                                      }
                                    }}
                                  />
                                </Form.Item>
                              </Col>
                            </Row>
                            <Row justify={'end'}>
                              <AddAndDeleteFormItem
                                fieldsLength={fields.length}
                                index={index}
                                addData={{
                                  disabled: isLoading,
                                  onClick: () => {
                                    add({})
                                  },
                                  buttonName: 'TRANSACTION_ADD_NEW_ACTION',
                                }}
                                deleteData={{
                                  disabled: isLoading,
                                  onClick: () => {
                                    remove(index)
                                  },
                                  buttonName: 'TRANSACTION_DELETE_ACTION',
                                }}
                              />
                            </Row>
                          </div>
                        )
                      })}
                    </div>
                  )
                }}
              </Form.List>
            </Col>
          </Row>
        )}
      </Form>
    </Modal>
  )
}

const mapStateToProps = (state) => ({
  listDimensions: configuration.selectors.getAllDimensionsList(state),
  currencyList: configuration.selectors.getCurrencyList(state),
})

const mapDispatchToProps = {
  fetchDimensionsValues: configuration.actions.fetchAllDimensionsValues,
  createDimensionValue: configuration.actions.createDimensionValue,
  fetchCurrencyList: configuration.actions.fetchCurrencyList,
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateTransactionModal)
