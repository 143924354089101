import React from 'react'
import { Card, Tag } from 'antd'
import { CardBody } from './components'
import { useTranslation } from 'react-i18next'
import { Button } from 'modules/core/components'

const isManual = (value) => value === 'manual'

const ImportDataCard = ({ item, onClick }) => {
  const { t } = useTranslation()

  const renderExtra = () => (
    <Button.Link
      title={isManual(item.type) ? 'ACTION_UPLOAD' : 'ACTION_CONFIGURATE'}
      size="small"
      onClick={onClick}
    />
  )

  const renderTitle = () =>
    item.is_connect && (
      <Tag color="blue">
        {isManual(item.type) ? t('STATE_TAG_LOADED') : t('STATE_TAG_CONFIGURED')}
      </Tag>
    )

  return (
    <Card title={renderTitle()} size="small" extra={renderExtra()}>
      <CardBody item={item} />
    </Card>
  )
}

export default ImportDataCard
