import ButtonBase from 'modules/core/components/Button/ButtonBase'
import { useTranslation } from 'react-i18next'
import './ButtonDefaultUser'

const ButtonDefaultUser = ({
  title,
  icon = null,
  disabled = false,
  onClick = () => {},
  ...props
}) => {
  const { t } = useTranslation()
  return (
    <ButtonBase
      {...props}
      className="app-layout-user-section"
      title={t(title)}
      disabled={disabled}
      onClick={onClick}
    >
      {t(title)}
      {icon}
    </ButtonBase>
  )
}

export default ButtonDefaultUser
