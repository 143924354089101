import { message, notification } from 'antd'
import { generateMessageError } from 'modules/core/utils'
import i18n from 'i18next'
import _ from 'lodash'

export const handleSave = ({
  dataCellEdit,
  setIsLoading,
  id,
  assignRole,
  editUser,
  onRefetch,
  onClose,
  society_id,
}) => {
  if (!_.isEmpty(dataCellEdit)) {
    const { role_ids, ...restProps } = dataCellEdit
    setIsLoading(true)
    if (role_ids) {
      Promise.all([
        editUser(id, { society: society_id, ...restProps }),
        assignRole(id, { role_ids }),
      ])
        .then(() =>
          onRefetch().then(() => {
            message.success(i18n.t('FEEDBACK_SAVE_CHANGES_SUCCES'), 8)
            onClose()
          }),
        )
        .catch((error) => {
          notification.error({
            message: i18n.t('FEEDBACK_DEFAULT_ERROR'),
            description: generateMessageError(error, 'FEEDBACK_SAVE_CHANGES_FAIL'),
            duration: 0,
          })
          onClose()
        })
    } else {
      editUser(id, { society: society_id, ...restProps })
        .then(() =>
          onRefetch().then(() => {
            message.success(i18n.t('FEEDBACK_SAVE_CHANGES_SUCCES'), 8)
            onClose()
          }),
        )
        .catch((error) => {
          notification.error({
            message: i18n.t('FEEDBACK_DEFAULT_ERROR'),
            description: generateMessageError(error, 'FEEDBACK_SAVE_CHANGES_FAIL'),
            duration: 0,
          })
          onClose()
        })
    }
  }
}

export const handleActivate = ({ setIsLoading, activateUser, onRefetch, onClose }) => {
  setIsLoading(true)
  activateUser()
    .then(() =>
      onRefetch().then(() => {
        message.success(i18n.t('FEEDBACK_SAVE_CHANGES_SUCCES'), 8)
        onClose()
      }),
    )
    .catch((error) => {
      notification.error({
        message: i18n.t('FEEDBACK_DEFAULT_ERROR'),
        description: generateMessageError(error, 'FEEDBACK_SAVE_CHANGES_FAIL'),
        duration: 0,
      })
      onClose()
    })
}

export const onConfirmReset = ({ setIsLoading, resetPasswordUser, onRefetch, onClose }) => {
  setIsLoading(true)
  resetPasswordUser()
    .then(() =>
      onRefetch().then(() => {
        message.success(i18n.t('FEEDBACK_SAVE_CHANGES_SUCCES'), 8)
        onClose()
      }),
    )
    .catch((error) => {
      notification.error({
        message: i18n.t('FEEDBACK_DEFAULT_ERROR'),
        description: generateMessageError(error, 'FEEDBACK_SAVE_CHANGES_FAIL'),
        duration: 0,
      })
      onClose()
    })
}
