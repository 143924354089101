import React from 'react'
import { Route, Switch } from 'react-router-dom'
import UploadS3File from './UploadS3File/UploadS3File'

const Home = () => (
  <Switch>
    <Route exact path="/cargaarchivoss3" component={UploadS3File} />
  </Switch>
)

export default Home
