import { arrayMove } from '@dnd-kit/sortable'
import { message } from 'antd'
import { generateMessageError } from 'modules/core/utils'
import i18n from 'i18next'

export const handleDragEnd = ({ event, dataColumnsRows, setDataColumnsRows }) => {
  const { active, over } = event

  const containerIndex = dataColumnsRows.findIndex((el) =>
    el.options.find((el) => el.id === active.id),
  )

  if (active.id !== over.id) {
    const dataOptions = dataColumnsRows[containerIndex].options.asMutable()
    const containerId = dataColumnsRows[containerIndex].id
    const oldIndex = dataOptions.findIndex((el) => el.id === active.id)
    const newIndex = dataOptions.findIndex((el) => el.id === over.id)
    const newOptions = arrayMove(dataOptions, oldIndex, newIndex)

    setDataColumnsRows({ id: containerId, options: newOptions })
  }
}

export const handleApply = ({
  setIsLoading,
  createColumnsRows,
  setPagination,
  onRefresh,
  onClose,
}) => {
  setIsLoading(true)
  createColumnsRows()
    .then(() => {
      onRefresh()
      message.success(i18n.t('FEEDBACK_SAVE_CHANGES_SUCCES'), 8)
      setPagination({ current: 1 })
      onClose()
    })
    .catch((error) => {
      message.error(generateMessageError(error, 'FEEDBACK_SAVE_CHANGES_FAIL_MESSAGE'), 8)
      onClose()
    })
}
