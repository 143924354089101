import React from 'react'
import { Input } from 'antd'
import { Button, ButtonActionBar } from 'modules/core/components'
import { useTranslation } from 'react-i18next'

const { Search } = Input

const UserActions = ({ onClick, onSearch }) => {
  const { t } = useTranslation()
  return (
    <ButtonActionBar>
      <Search
        style={{ width: 200 }}
        placeholder={t('ACTION_SEARCH')}
        allowClear={true}
        onSearch={onSearch}
      />
      <Button.Primary onClick={onClick} disabled={false} title="ACTION_CREATE_NEW" />
    </ButtonActionBar>
  )
}

export default UserActions
