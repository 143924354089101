import React, { useState } from 'react'
import { Modal, Form, Row, Col, Input } from 'antd'
import { useTranslation } from 'react-i18next'
import { Button } from 'modules/core/components'
import { connect } from 'react-redux'
import configuration from 'modules/configuration'
import { onConfirm } from './utils'

const CreateCurrencyModal = ({ userCanActivate, onRefetch, createCurrencies }) => {
  const [showCreateModal, setShowCreateModal] = useState(false)
  const [isCreating, setIsCreating] = useState(false)
  const [form] = Form.useForm()
  const { t } = useTranslation()

  const onClose = () => {
    form.resetFields()
    setShowCreateModal(false)
    setIsCreating(false)
  }

  return (
    <>
      <Button.Primary
        title="ACTION_CREATE_NEW"
        onClick={() => setShowCreateModal(true)}
        disabled={!userCanActivate}
      />
      <Modal
        visible={showCreateModal}
        title={t('CONFIG_CURRENCY_CREATE_MODAL_TITLE')}
        bodyStyle={{ overflowY: 'auto', maxHeight: 400 }}
        centered
        okText={t('ACTION_CREATE')}
        cancelText={t('ACTION_CANCEL')}
        onOk={() =>
          onConfirm({
            form,
            setIsLoading: setIsCreating,
            createCurrencies: (values) => createCurrencies(values),
            onClose,
            onRefetch,
          })
        }
        onCancel={onClose}
        okButtonProps={{ loading: isCreating }}
        cancelButtonProps={{ loading: isCreating }}
      >
        <Form form={form} layout="vertical">
          <Row gutter={[8, 4]}>
            <Col span={12}>
              <Form.Item label={t('FIELD_SIGN')} name="symbol">
                <Input placeholder={t('CONFIG_CURRENCY_MODAL_CREATE_SYMBOL_FIELD_PLACEHOLDER')} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={t('FIELD_CODE')}
                name="code"
                rules={[{ required: true, message: t('REQUIRED_FIELD') }]}
              >
                <Input placeholder={t('ENTER_CODE_PLACEHOLDER')} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label={t('FIELD_NAME')} name="name">
                <Input placeholder={t('ENTER_NAME_PLACEHOLDER')} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label={t('FIELD_DESCRIPTION')} name="description">
                <Input.TextArea placeholder={t('WRITE_SOMETHING_PLACEHOLDER')} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  )
}

const mapStateToProps = (state) => ({
  currencyList: configuration.selectors.getCurrencyList(state),
})

const mapDispatchToProps = {
  createCurrencies: configuration.actions.createCurrencies,
  fetchCurrencyList: configuration.actions.fetchCurrencyList,
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateCurrencyModal)
