import { message, notification } from 'antd'
import { generateMessageError } from 'modules/core/utils'
import i18n from 'i18next'

export const onCreateDimension = ({ form, setIsLoading, createDimension, onRefetch, onClose }) => {
  form
    .validateFields()
    .then((values) => {
      setIsLoading(true)
      const { type_creation, ...restProps } = values

      const data = {
        ...restProps,
        dimension_plika: restProps.dimension_plika ? restProps.dimension_plika : null,
      }

      createDimension(data)
        .then(() =>
          onRefetch().then(() => {
            message.success(i18n.t('FEEDBACK_CREATE_SUCCESS'), 8)
            onClose()
          }),
        )
        .catch((error) => {
          notification.error({
            message: i18n.t('FEEDBACK_DEFAULT_ERROR'),
            description: generateMessageError(error, 'FEEDBACK_CREATION_FAIL'),
            duration: 0,
          })
          onClose()
        })
    })
    .catch((e) => {})
}
