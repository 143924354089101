import React, { useEffect, useState } from 'react'
import { Col, Form, Input, Modal, Radio, Row, Select } from 'antd'
import { connect } from 'react-redux'
import { generateSelectOptions } from 'modules/core/utils'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { onCreate, onEdit } from './utils'
import { CHARTS, PROJECTION } from 'modules/core/constants'
import reports from 'modules/reports'
import configuration from 'modules/configuration'
import _ from 'lodash'

const ElementCreateModal = ({
  visible,
  onCancel,
  duplicateData,
  setDuplicateData,
  chartType,
  onRefetch,
  fetchAllDimensions,
  fetchReportDetailStructure,
  allDimensionsList,
  reportStructure,
  createDashboardElement,
  editDashboardElement,
}) => {
  const [dataType, setDataType] = useState(CHARTS.CHART_KEY)
  const [isDimensionLoading, setIsDimensionLoading] = useState(false)
  const [isLinesReportLoading, setIsLinesReportLoading] = useState(false)
  const [valueType, setValueType] = useState(CHARTS.VALUE_AMOUNT)
  const [isSaving, setIsSaving] = useState(false)
  const [form] = Form.useForm()
  const { t } = useTranslation()
  let { reportId, periodId } = useParams()

  useEffect(() => {
    if (visible) {
      setIsDimensionLoading(true)
      fetchAllDimensions().then(() => setIsDimensionLoading(false))
    }
  }, [fetchAllDimensions, visible])

  useEffect(() => {
    if (reportId && visible) {
      setIsLinesReportLoading(true)
      fetchReportDetailStructure(reportId).then(() => setIsLinesReportLoading(false))
    }
  }, [fetchReportDetailStructure, reportId, visible])

  useEffect(() => {
    if (visible && !_.isEmpty(duplicateData) && chartType !== 'sankey') {
      const aux = {
        ...(duplicateData?.values?.dimension && { dimension: duplicateData?.values?.dimension }),
        ...(duplicateData?.values?.value && { value: duplicateData?.values?.value }),
        ...(duplicateData?.values?.report_line && {
          report_line: duplicateData?.values?.report_line,
        }),
      }
      form.setFieldsValue({ ...duplicateData, ...aux })
    }
  }, [visible, form, duplicateData, chartType])

  const onClose = () => {
    form.resetFields()
    setDuplicateData({})
    onCancel()
    setIsSaving(false)
  }

  const handleEdit = (values) => {
    const { value, dimension, report_line, ...restProps } = values

    const data = {
      ...restProps,
      ...((value || dimension || report_line) && {
        values: {
          ...duplicateData.values,
          ...(value && { value }),
          ...(dimension && { dimension }),
          ...(report_line && { report_line }),
        },
      }),
    }

    return editDashboardElement(duplicateData.id, data)
  }

  return (
    <Modal
      title={t('REPORT_DASHBOARD_CREATE_MODAL_TITLE', {
        action: !_.isEmpty(duplicateData) ? t('ACTION_EDIT') : t('ACTION_CREATE'),
      })}
      visible={visible}
      onCancel={onClose}
      onOk={
        !_.isEmpty(duplicateData)
          ? () =>
              onEdit({
                form,
                setIsLoading: setIsSaving,
                editDashboardElement: (values) => handleEdit(values),
                onRefetch,
                onClose,
              })
          : () =>
              onCreate({
                form,
                setIsLoading: setIsSaving,
                createDashboardElement: (data) => createDashboardElement(reportId, periodId, data),
                onRefetch,
                onClose,
              })
      }
      okText={!_.isEmpty(duplicateData) ? t('ACTION_EDIT') : t('ACTION_CREATE')}
      okButtonProps={{ loading: isSaving, disabled: isSaving }}
      cancelButtonProps={{ disabled: isSaving }}
      cancelText={t('ACTION_CANCEL')}
      bodyStyle={{ overflowY: 'auto', maxHeight: 400 }}
      centered
      destroyOnClose
      forceRender
    >
      <Form form={form} layout="vertical">
        {() => (
          <Row gutter={[8, 8]}>
            <Col span={24}>
              <Form.Item
                label={t('FIELD_ELMENT_TYPE')}
                name="type"
                rules={[{ required: true, message: t('REQUIRED_FIELD') }]}
                initialValue={dataType}
              >
                <Radio.Group>
                  {CHARTS.REPORT_DASHBOART_TYPES.map((option) => (
                    <Radio
                      value={option.id}
                      key={option.id}
                      onChange={(val) => setDataType(val.target.value)}
                    >
                      {t(option.name)}
                    </Radio>
                  ))}
                </Radio.Group>
              </Form.Item>
            </Col>
            {dataType === CHARTS.CHART_KEY && (
              <>
                <Col span={24}>
                  <Form.Item label={t('FIELD_TITLE')} name="title">
                    <Input placeholder={t('WRITE_SOMETHING_PLACEHOLDER')} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label={t('FIELD_CHART_TYPE')}
                    name="chart_type"
                    rules={[{ required: true, message: t('REQUIRED_FIELD') }]}
                  >
                    <Select
                      placeholder={t('ACTION_SELECT')}
                      options={generateSelectOptions({
                        options: CHARTS.CHART_OPTIONS.map((op) => {
                          return { ...op, name: t(op.name) }
                        }),
                      })}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label={t('FIELD_SIZE')}
                    name="size"
                    rules={[{ required: true, message: t('REQUIRED_FIELD') }]}
                  >
                    <Select
                      placeholder={t('ACTION_SELECT')}
                      options={generateSelectOptions({
                        options: CHARTS.SIZE_OPTIONS.map((op) => {
                          return { ...op, name: t(op.name) }
                        }),
                      })}
                    />
                  </Form.Item>
                </Col>
                {form.getFieldsValue().chart_type !== CHARTS.TYPES.SANKEY && (
                  <>
                    <Col span={12}>
                      <Form.Item
                        label={t('FIELD_DIMENSION')}
                        name="dimension"
                        rules={[
                          {
                            required:
                              form.getFieldsValue().chart_type === CHARTS.TYPES.PIE ? true : false,
                            message: t('REQUIRED_FIELD'),
                          },
                        ]}
                      >
                        <Select
                          placeholder={t('ACTION_SELECT')}
                          loading={isDimensionLoading}
                          options={generateSelectOptions({
                            options: allDimensionsList.map((dim) => {
                              return { ...dim, name: dim.name_destination }
                            }),
                          })}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label={t('FIELD_REPORT_LINE')}
                        name="report_line"
                        rules={[
                          {
                            required:
                              form.getFieldsValue().chart_type === CHARTS.TYPES.LINE ||
                              form.getFieldsValue().chart_type === CHARTS.TYPES.BAR
                                ? true
                                : false,
                            message: t('REQUIRED_FIELD'),
                          },
                        ]}
                      >
                        <Select
                          placeholder={t('ACTION_SELECT')}
                          loading={isLinesReportLoading}
                          options={generateSelectOptions({ options: reportStructure?.values })}
                        />
                      </Form.Item>
                    </Col>
                  </>
                )}
                <Col span={12}>
                  <Form.Item
                    label={t('LABEL_VALUE_TYPE')}
                    name="value"
                    rules={[{ required: true, message: t('REQUIRED_FIELD') }]}
                    initialValue={valueType}
                  >
                    <Radio.Group>
                      {PROJECTION.VALUES_TYPES.map((option) => (
                        <Radio
                          value={option.id}
                          key={option.id}
                          onChange={(val) => setValueType(val.target.value)}
                        >
                          {t(option.name)}
                        </Radio>
                      ))}
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </>
            )}
            {dataType === CHARTS.TABLE_KEY && (
              <>
                <Col span={24}>
                  <Form.Item label={t('FIELD_TITLE')} name="title">
                    <Input placeholder={t('WRITE_SOMETHING_PLACEHOLDER')} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label={t('FIELD_SIZE')}
                    name="size"
                    rules={[{ required: true, message: t('REQUIRED_FIELD') }]}
                  >
                    <Select
                      placeholder={t('ACTION_SELECT')}
                      options={generateSelectOptions({
                        options: CHARTS.SIZE_OPTIONS.filter((el) => el.id === 'large').map((op) => {
                          return { ...op, name: t(op.name) }
                        }),
                      })}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label={t('FIELD_REPORT_LINE')}
                    name="report_line"
                    rules={[{ required: true, message: t('REQUIRED_FIELD') }]}
                  >
                    <Select
                      placeholder={t('ACTION_SELECT')}
                      mode="multiple"
                      showSearch
                      showArrow
                      maxTagCount={1}
                      maxTagTextLength={8}
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      loading={isLinesReportLoading}
                      options={generateSelectOptions({ options: reportStructure?.values })}
                    />
                  </Form.Item>
                </Col>
              </>
            )}
          </Row>
        )}
      </Form>
    </Modal>
  )
}

const mapStateToProps = (state) => ({
  allDimensionsList: configuration.selectors.getAllDimensionsList(state),
  reportStructure: reports.selectors.getReportStructure(state),
})

const mapDispatchToProps = {
  fetchAllDimensions: configuration.actions.fetchAllDimensions,
  fetchReportDetailStructure: reports.actions.fetchReportDetailStructure,
  createDashboardElement: reports.actions.createDashboardElement,
  editDashboardElement: reports.actions.editDashboardElement,
}

export default connect(mapStateToProps, mapDispatchToProps)(ElementCreateModal)
