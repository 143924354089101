import React from 'react'
import { Card, Col, Dropdown, Empty, Menu, Row, Select, Space } from 'antd'
import { BarChart, LineChart } from '../../../../../../../core/components/Charts'
import { CHARTS, GENERAL } from 'modules/core/constants'
import { useTranslation } from 'react-i18next'
import { generateSelectOptions } from 'modules/core/utils'
import { RightCircleOutlined, CommentOutlined } from '@ant-design/icons'
import Typography from '../../../../../../../core/components/Typography/Typography'
import numeral from 'numeral'
import _ from 'lodash'

import './KpiCard.scss'

const COLORS_BY_BUDGET_TYPE = {
  1: CHARTS.COLORS_SALES,
  2: CHARTS.COLORS_EXPENSES,
  3: CHARTS.COLORS_COSTS,
  4: CHARTS.COLORS_HUMAN_RESOURCES,
  5: CHARTS.COLORS_OTHER_5,
  6: CHARTS.COLORS_OTHER_6,
}

const KpiCard = ({
  title,
  id,
  dataKpi,
  onClick,
  extra,
  bottomText,
  dataLineChart,
  dataRankingUp,
  dataRankingDown,
  allDimensionsList,
  dimension,
  setDimensionId,
  setViewComment,
  hasComment,
  setHasComment,
  // setCardId,
}) => {
  const { t } = useTranslation()

  const key = window.location.pathname.replaceAll('/', '-')

  return (
    <Dropdown
      trigger={['contextMenu']}
      overlay={
        <Menu>
          {GENERAL.OPTION_COMMENT.map((el) => (
            <Menu.Item
              key={el.key}
              icon={<CommentOutlined />}
              onClick={() => {
                setViewComment((prevState) => {
                  return { ...prevState, [id]: true }
                })
                setHasComment({
                  type: 'card',
                  data: !_.isEmpty(hasComment?.card)
                    ? !_.isEmpty(hasComment?.card[key])
                      ? !hasComment?.card[key][id]
                      : true
                    : true,
                  key,
                  id,
                })
                // setCardId()
              }}
            >
              {t(el.label)}
            </Menu.Item>
          ))}
        </Menu>
      }
    >
      <Card className="tracing-list-card" data-type={id.toString()}>
        <Row gutter={[12, 2]}>
          <Col span={22}>
            <Space direction="horizontal">
              <h2 className="title-style" onClick={onClick}>
                {title}
              </h2>
              <Typography.Icon style={{ marginBottom: 10 }} level={2} icon={RightCircleOutlined} />
            </Space>
          </Col>
          <Col style={{ display: 'flex', justifyContent: 'flex-end' }} span={2}>
            {extra}
          </Col>
          {(dataKpi === null || dataKpi === 0) && (
            <Col span={24}>
              <Empty description={t('CONTROL_EMPTY_DATA_CARDS_BUDGET_TYPE_BODY_TEXT', { title })} />
            </Col>
          )}
          {dataKpi !== null && dataKpi !== 0 && (
            <>
              <Col span={24} offset={12} style={{ paddingLeft: 20, paddingRight: 20 }}>
                <Typography.Body level={3}>{t('FIELD_DIMENSION')}</Typography.Body>
                <Select
                  defaultValue={dimension}
                  bordered={false}
                  optionFilterProp="children"
                  filterOption={(input, option) => {
                    return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }}
                  showSearch
                  onChange={setDimensionId}
                  options={generateSelectOptions({
                    options: allDimensionsList.map((el) => {
                      return { id: el.id, name: el.name_destination }
                    }),
                  })}
                />
              </Col>
              <Col
                span={4}
                style={{ paddingLeft: 20, paddingRight: 20 }}
                className="number-container-col"
              >
                <Space direction="vertical" size="small">
                  <div className="text-container">
                    <Typography.Headline title={numeral(dataKpi).format('0,0.00')} level={3}>
                      {dataKpi ? numeral(dataKpi).format('0[.]0a') : '-'}
                    </Typography.Headline>
                  </div>
                  <div className="text-container">
                    <Typography.Body level={3}>{bottomText}</Typography.Body>
                  </div>
                </Space>
              </Col>
              <Col
                span={8}
                style={{ paddingLeft: 20, paddingRight: 20 }}
                className="chart-cotainer"
              >
                <Typography.Body level={3}>{t('FIELD_EVOLUTION')}</Typography.Body>
                <LineChart
                  containerHeight={110}
                  margin={{ right: 15, left: 10, top: 8, bottom: 30 }}
                  axisLeft={false}
                  enableGridY={false}
                  enablePoints={false}
                  legends={[]}
                  data={dataLineChart}
                  colors={COLORS_BY_BUDGET_TYPE[id]}
                />
              </Col>
              <Col span={6} style={{ paddingLeft: 20, paddingRight: 10 }}>
                <Typography.Body level={3}>{t('FIELD_BEST_SELLERS')}</Typography.Body>
                <BarChart
                  containerHeight={110}
                  margin={{ left: 100, top: 0, bottom: 0 }}
                  enableLabel={true}
                  enableGridY={false}
                  enableGridX={false}
                  axisBottom={null}
                  legends={[]}
                  layout="horizontal"
                  indexBy="dimension"
                  keys={dataRankingUp.map((el) => {
                    return _.keys(el).filter((e) => e !== 'dimension')[0]
                  })}
                  data={dataRankingUp}
                  colors={COLORS_BY_BUDGET_TYPE[id]}
                  tooltip={(data) => {
                    return (
                      <div className="tooltip-chart-container">
                        <Space direction="horizontal">
                          <div style={{ width: 12, height: 12, backgroundColor: data.color }}></div>
                          <div style={{ width: 300 }}>
                            <Typography.Body level={3}>{data.id}: </Typography.Body>
                            <strong>{data.formattedValue}%</strong>
                          </div>
                        </Space>
                      </div>
                    )
                  }}
                  axisLeft={{
                    renderTick: (d) => {
                      return (
                        <g transform={`translate(${d.x},${d.y})`}>
                          <text
                            alignmentBaseline={d.textBaseline}
                            textAnchor={d.textAnchor}
                            transform={`translate(${d.textX},${d.textY})`}
                            style={{ fontSize: 10 }}
                          >
                            {d.value.length <= 10
                              ? d.value
                              : `${d.value.slice(0, -d.value.length + 10)}...`}
                          </text>
                        </g>
                      )
                    },
                  }}
                />
              </Col>
              <Col span={6} style={{ paddingLeft: 10, paddingRight: 20 }}>
                <Typography.Body level={3}>{t('FIELD_LESS_SOLD')}</Typography.Body>
                <BarChart
                  containerHeight={110}
                  margin={{ left: 100, top: 0, bottom: 0 }}
                  enableLabel={true}
                  enableGridY={false}
                  enableGridX={false}
                  axisBottom={null}
                  legends={[]}
                  layout="horizontal"
                  indexBy="dimension"
                  keys={dataRankingDown.map((el) => {
                    return _.keys(el).filter((e) => e !== 'dimension')[0]
                  })}
                  data={dataRankingDown}
                  colors={COLORS_BY_BUDGET_TYPE[id]}
                  tooltip={(data) => {
                    return (
                      <div className="tooltip-chart-container">
                        <Space direction="horizontal">
                          <div style={{ width: 12, height: 12, backgroundColor: data.color }}></div>
                          <div style={{ width: 300 }}>
                            <Typography.Body level={3}>{data.id}: </Typography.Body>
                            <strong>{data.formattedValue}%</strong>
                          </div>
                        </Space>
                      </div>
                    )
                  }}
                  axisLeft={{
                    renderTick: (d) => {
                      return (
                        <g transform={`translate(${d.x},${d.y})`}>
                          <text
                            alignmentBaseline={d.textBaseline}
                            textAnchor={d.textAnchor}
                            transform={`translate(${d.textX},${d.textY})`}
                            style={{ fontSize: 10 }}
                          >
                            {d.value.length <= 10
                              ? d.value
                              : `${d.value.slice(0, -d.value.length + 10)}...`}
                          </text>
                        </g>
                      )
                    },
                  }}
                />
              </Col>
            </>
          )}
        </Row>
      </Card>
    </Dropdown>
  )
}

export default KpiCard
