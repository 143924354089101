export const monthsToAdjust = ({ start_date = '', end_date = '', monthsToPublish }) => {
  let year = parseInt(start_date.split('-', 2)[0])
  let month = parseInt(start_date.split('-', 2)[1])

  const month_name_list = []

  while (`${year}-${month <= 9 ? `0${month}` : month}` <= end_date) {
    if (![...monthsToPublish].sort().includes(`${year}-${month <= 9 ? `0${month}` : month}`)) {
      month_name_list.push(`${year}-${month <= 9 ? `0${month}` : month}`)
    }

    //Avanzar al siguiente mes/año
    if (month === 12) {
      year = year + 1
      month = 1
    } else {
      month = month + 1
    }
  }

  return month_name_list
}
