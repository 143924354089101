const DIMENSION_NAME = 'dimension'
const CONCEPT_NAME = 'concepto'
const PERIOD_NAME = 'periodo'
const UNIT_OF_TIME_NAME = 'tipo_tiempo'
const UNIT_OF_AMOUNT_NAME = 'tipo_importe'

const DIMENSION_KEY = `#${DIMENSION_NAME}`
const CONCEPT_KEY = `#${CONCEPT_NAME}`
const PERIOD_KEY = `#${PERIOD_NAME}`
const UNIT_OF_TIME_KEY = `#${UNIT_OF_TIME_NAME}`
const UNIT_OF_AMOUNT_KEY = `#${UNIT_OF_AMOUNT_NAME}`
const EMPTY_VALUE = ''

const CHATBOT_KEYS = {
  DIMENSION_KEY: DIMENSION_KEY,
  CONCEPT_KEY: CONCEPT_KEY,
  PERIOD_KEY: PERIOD_KEY,
  UNIT_OF_TIME_KEY: UNIT_OF_TIME_KEY,
  UNIT_OF_AMOUNT_KEY: UNIT_OF_AMOUNT_KEY,
}

const DEFAULT_VALUES = {
  DIMENSION_KEY: EMPTY_VALUE,
  CONCEPT_KEY: EMPTY_VALUE,
  PERIOD_KEY: EMPTY_VALUE,
  UNIT_OF_TIME_KEY: EMPTY_VALUE,
  UNIT_OF_AMOUNT_KEY: EMPTY_VALUE,
}

const CHATBOT_TAGS = [
  { key: DIMENSION_NAME, value: DIMENSION_NAME },
  { key: CONCEPT_NAME, value: CONCEPT_NAME },
  { key: PERIOD_NAME, value: PERIOD_NAME },
  { key: UNIT_OF_TIME_NAME, value: UNIT_OF_TIME_NAME },
  { key: UNIT_OF_AMOUNT_NAME, value: UNIT_OF_AMOUNT_NAME },
]

const CHATBOT = {
  CHATBOT_KEYS,
  DEFAULT_VALUES,
  CHATBOT_TAGS,
}

export default CHATBOT
