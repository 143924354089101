import { Steps as StepsAntd } from 'antd'
import { useTranslation } from 'react-i18next'

const { Step } = StepsAntd

const Steps = ({ dataSteps, current }) => {
  const { t } = useTranslation()
  return (
    <StepsAntd current={current}>
      {dataSteps.map((step) => (
        <Step
          key={step.key}
          title={t(step.title)}
          // description={t(step.description)}
        />
      ))}
    </StepsAntd>
  )
}

export default Steps
