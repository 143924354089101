import Immutable from 'seamless-immutable'
import * as types from './constants'

const initialState = Immutable({
  controlList: {
    count: 0,
    values: [],
    last_publish_date: '',
  },
  realColumns: [],
  realRows: {
    transactions: [],
    count: 0,
  },
  controlDetailKPIS: [],
  monthsToPublish: [],
  approveDates: [],
  monthsToApprove: [],
  publishedMonths: [],
  legalData: {
    columns: [],
    rows: [],
    year_current: '',
    year_list: [],
  },
  dataTable: {
    rows: [],
    columns: [],
    num_pages: 1,
  },
  dataTableAntv: {
    data: [],
    fields: {},
    meta: [],
  },
  layoutList: {
    count: 0,
    values: [],
  },
})

const addNewNode = (nodes, nodeId, newNode) => {
  let newNodes = []
  nodes.forEach((node) => {
    if (node.id === nodeId) {
      node = {
        ...node,
        children: newNode && newNode,
      }
    }
    if (node.id !== nodeId && node.children) {
      node = {
        ...node,
        children: addNewNode(node.children, nodeId, newNode),
      }
    }
    newNodes.push(node)
  })
  return newNodes
}

/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default (state = initialState, action) => {
  let data
  switch (action.type) {
    case types.FETCH_CONTROL_LIST_SUCCESS:
      return state.merge({
        controlList: {
          count: action.payload.data.cards.length,
          values: action.payload.data.cards,
          last_publish_date: action.payload.data.last_publish_date,
        },
      })
    case types.FETCH_REAL_COLUMNS_SUCCESS:
      data = action.payload.data
      return state.merge({
        realColumns: data.columns,
      })
    case types.FETCH_REAL_ROWS_SUCCESS:
      data = action.payload.data
      return state.merge({
        realRows: {
          transactions: data.results.rows.map((row, index) => {
            return { ...row, id: index }
          }),
          count: data.count,
        },
      })
    case types.FETCH_CONTROL_DETAIL_KPIS_SUCCESS:
      data = action.payload.data
      return state.merge({
        controlDetailKPIS: data.kpis,
      })
    case types.FETCH_MONTHS_TO_PUBLISH_SUCCESS:
      data = action.payload.data.fechas
      return state.merge({
        monthsToPublish: data,
      })
    case types.FETCH_MONTHS_TO_APPROVE_SUCCESS:
      return state.merge({
        monthsToApprove: action.payload.data,
      })
    case types.FETCH_APPROVE_DATES_SUCCESS:
      data = action.payload.data
      return state.merge({
        approveDates: data,
      })

    //BORRAR
    case types.FETCH_PUBLISHED_MONTHS:
      return state.merge({
        publishedMonths: action.payload.data,
      })

    case types.FETCH_LEGAL_DATA_SUCCESS:
      return state.merge({
        legalData: action.payload.data,
      })

    case types.FETCH_DATA_TABLE_SUCCESS:
      const page = action.meta.previousAction.payload.request.data.page
      let auxrows =
        page === 1
          ? action.payload.data.rows
          : [...state.dataTable.rows, ...action.payload.data.rows]

      return state.merge({
        dataTable: {
          columns: action.payload.data.colums,
          rows: auxrows,
          num_pages: action.payload.data.num_pages,
        },
      })

    case types.FETCH_OPEN_ROW_SUCCESS:
      const parentId = action.payload.config.params.rowId
      let auxReportTableByPeriods = state.dataTable.rows.asMutable({
        deep: true,
      })
      return state.merge({
        dataTable: {
          ...state.dataTable,
          rows: addNewNode(auxReportTableByPeriods, parentId, action.payload.data.rows),
        },
      })

    case types.FETCH_DATA_TABLE_ANTV_SUCCESS:
      return state.merge({
        dataTableAntv: action.payload.data,
      })

    case types.FETCH_LAYOUT_LIST_SUCCESS:
      return state.merge({
        layoutList: {
          count: action.payload.data.count,
          values: action.payload.data.results,
        },
      })

    default:
      return state
  }
}
