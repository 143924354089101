import { message, notification } from 'antd'
import { ADJUSTMENTS } from 'modules/core/constants'
import { generateMessageError } from 'modules/core/utils'
import i18n from 'i18next'
import moment from 'moment'
import _ from 'lodash'

export const onCreate = ({ form, setIsLoading, createAdjustmentByFile, onRefetch, onClose }) => {
  form.validateFields().then((values) => {
    setIsLoading(true)
    createAdjustmentByFile(values)
      .then(() =>
        onRefetch().then(() => {
          message.success(i18n.t('ADJUSTMENT_CREATE_SUCCESS_FEEDBACK_TITLE'), 8)
          onClose()
        }),
      )
      .catch((error) => {
        notification.error({
          message: i18n.t('FEEDBACK_DEFAULT_ERROR'),
          description: generateMessageError(error, 'ADJUSTMENT_CREATE_FAIL_FEEDBACK_DESCRIPTION'),
          duration: 0,
        })
        onClose()
      })
  })
}

export const isAdjustment = (value) => value === ADJUSTMENTS.KEY.ADJUSTMENT_KEY

export const handleOnClick = ({
  dataType,
  form,
  downloadCreateAdjustmentTemplate,
  downloadCreateDistributionTemplate,
  dimensionsSelection,
  periodId,
}) => {
  if (isAdjustment(dataType)) {
    return (
      form.getFieldsValue().concept_id &&
      downloadCreateAdjustmentTemplate(
        i18n.t('ADJUSTMENT_DEFAULT_TEMPLATE_NAME', { date: moment().format('lll') }),
        { concept_id: form.getFieldsValue().concept_id },
      )
    )
  } else {
    return (
      form.getFieldsValue().concept_id &&
      !_.isEmpty(dimensionsSelection) &&
      downloadCreateDistributionTemplate(
        i18n.t('DISTRIBUTION_TEMPLATE_NAME', { date: moment().format('lll') }),
        {
          concept_id: form.getFieldsValue().concept_id,
          period_id: periodId,
          ...dimensionsSelection,
        },
      )
    )
  }
}

export const renderTitle = ({ dataType, form, dimensionsSelection }) => {
  if (isAdjustment(dataType) && !form.getFieldsValue().concept_id)
    return i18n.t('ADJUSTEMNT_DOWNLOAD_TEMPLATE_INFORMATIVE_TOOLTIP_TITLE')
  if (
    !isAdjustment(dataType) &&
    (!form.getFieldsValue().concept_id || _.isEmpty(dimensionsSelection))
  )
    return i18n.t('DISTIBUTION_DOWNLOAD_TEMPLATE_INFORMATIVE_TOOLTIP_TITLE')
}
