import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Modal, Form, Col, Row, Input } from 'antd'
import configuration from 'modules/configuration'
import { connect } from 'react-redux'
import { onVariableCreate } from './utils'

const FormItem = Form.Item

const CreateVariableModal = ({ visible, onCancel, createVariable, onRefetchVariablesList }) => {
  const [isCreating, setIsCreating] = useState(false)
  const [form] = Form.useForm()
  const { t } = useTranslation()

  const onClose = () => {
    form.resetFields()
    setIsCreating(false)
    onCancel()
  }

  return (
    <Modal
      title={t('CONFIG_VAR_CREATE_MODAL_TITLE')}
      visible={visible}
      onCancel={onCancel}
      centered
      okText={t('ACTION_CREATE')}
      cancelText={t('ACTION_CANCEL')}
      onOk={() =>
        onVariableCreate({
          form,
          setIsLoading: setIsCreating,
          createVariable: (values) => createVariable({ ...values, status: 'active' }),
          onRefetch: onRefetchVariablesList,
          onClose,
        })
      }
      forceRender
      okButtonProps={{ loading: isCreating }}
      cancelButtonProps={{ disabled: isCreating }}
    >
      <Form form={form} layout="vertical" hideRequiredMark={false}>
        <Row gutter={[12, 12]} type="flex" justify="end">
          <Col span={24}>
            <FormItem
              name="name"
              label={t('FIELD_NAME')}
              rules={[{ required: true, message: t('REQUIRED_FIELD') }]}
            >
              <Input placeholder={t('CONFIG_VAR_CREATE_MODAL_NAME_PLACEHOLDER')} />
            </FormItem>
          </Col>
          <Col span={24}>
            <FormItem name="description" label={t('FIELD_DESCRIPTION')}>
              <Input.TextArea placeholder={t('WRITE_SOMETHING_PLACEHOLDER')} />
            </FormItem>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

const mapDispatchToProps = {
  createVariable: configuration.actions.createVariable,
}

export default connect(null, mapDispatchToProps)(CreateVariableModal)
