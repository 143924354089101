import React from 'react'
import { Col, Form, Radio, Row, Space } from 'antd'
import { CHARTS } from 'modules/core/constants'
import { useTranslation } from 'react-i18next'

const SankeyChartSettings = ({ form, defaultSetting }) => {
  const { t } = useTranslation()
  return (
    <Form form={form} layout="horizontal" hideRequiredMark={false} initialValues={defaultSetting}>
      {() => (
        <Row gutter={[8, 8]}>
          <Col span={24}>
            <Form.Item label={t('CHART_DISPLAY_MODE_SETTINGS')} name="layout">
              <Radio.Group>
                <Space direction="vertical">
                  {CHARTS.DISPLAY_OPTIONS.map((el) => (
                    <Radio value={el.id} key={el.id}>
                      {t(el.name)}
                    </Radio>
                  ))}
                </Space>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label={t('CHART_SORTING_MODE_SETTINGS')} name="sort">
              <Radio.Group>
                <Space direction="vertical">
                  {CHARTS.SORT_OPTIONS.map((el) => (
                    <Radio value={el.id} key={el.id}>
                      {t(el.name)}
                    </Radio>
                  ))}
                </Space>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Row>
              <Col span={24}>
                {' '}
                <Form.Item label={t('FIELD_LABEL')} />
              </Col>
              <Col span={12}>
                <Form.Item label={t('CHART_PROVISION_SETTINGS')} name="labelOrientation">
                  <Radio.Group>
                    <Space direction="vertical">
                      {CHARTS.DISPLAY_OPTIONS.map((el) => (
                        <Radio value={el.id} key={el.id}>
                          {t(el.name)}
                        </Radio>
                      ))}
                    </Space>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label={t('LABEL_VALUE_POSITION')} name="labelPosition">
                  <Radio.Group>
                    <Space direction="vertical">
                      {CHARTS.SETTINGS.LABEL_POSITION.map((el) => (
                        <Radio value={el} key={el}>
                          {t(CHARTS.I18N[el])}
                        </Radio>
                      ))}
                    </Space>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
      )}
    </Form>
  )
}

export default SankeyChartSettings
