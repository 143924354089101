import React, { useState } from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Table, message } from 'antd'
import { columnsDetail } from '../../../../components'
import { generateMessageError, isUserAllowed } from 'modules/core/utils'
import { ROLES } from 'modules/core/constants'
import configuration from 'modules/configuration'

const { SETTINGS__BUDGET_TYPE__VIEW } = ROLES //REVEER ROLES CUANDO ESTEN LOS PERMISOS DE LA PARTE FINANCIERA Y ECONOMICA

const BudgetTypeDetailTable = ({ dataSource, tableLoading, deleteRow, refreshData }) => {
  const [isRemovingDimension, setIsRemovingDimension] = useState(false)
  const [removeDimensionId, setRemoveDimensionId] = useState(null)

  const { t } = useTranslation()

  const onRemoveDimension = (id) => {
    setIsRemovingDimension(true)
    deleteRow(id)
      .then(() => {
        message.success(t('FEEDBACK_CHANGES_SAVED_SUCCESS'), 8)
        setIsRemovingDimension(false)
        refreshData()
      })
      .catch((error) => {
        message.error(generateMessageError(error, 'FEEDBACK_SAVE_CHANGES_FAIL'), 8)
        setIsRemovingDimension(false)
      })
  }

  return (
    <Table
      rowKey="order"
      bordered
      size="small"
      loading={tableLoading}
      dataSource={dataSource}
      columns={columnsDetail({
        disabled: !isUserAllowed(SETTINGS__BUDGET_TYPE__VIEW),
        onAction: (id) => onRemoveDimension(id),
        loading: isRemovingDimension,
        removeDimensionId,
        setRemoveDimensionId,
      })}
      pagination={{
        size: 'small',
        pageSizeOptions: ['10', '20', '30'],
        showSizeChanger: true,
      }}
    />
  )
}

const mapDispatchToProps = {
  deleteRow: configuration.actions.deleteBudgetTypeAssociation,
}

export default connect(null, mapDispatchToProps)(BudgetTypeDetailTable)
