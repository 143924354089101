import { message, notification } from 'antd'
import { generateMessageError } from 'modules/core/utils'
import i18n from 'i18next'
import _ from 'lodash'

export const onDeleteRule = ({ setIsLoading, deleteRule, onRefetch, onClose }) => {
  setIsLoading(true)
  deleteRule()
    .then(() =>
      onRefetch().then(() => {
        message.success(i18n.t('FEEDBACK_CHANGES_SAVED_SUCCESS'), 8)
        onClose()
      }),
    )
    .catch((error) => {
      notification.error({
        message: i18n.t('FEEDBACK_DEFAULT_ERROR'),
        description: generateMessageError(error, 'FEEDBACK_DELETE_FAIL'),
        duration: 0,
      })
      onClose()
    })
}

export const onEditRule = ({ dataCellEdit, setIsLoading, editRule, onRefetch, onClose }) => {
  if (!_.isEmpty(dataCellEdit)) {
    setIsLoading(true)
    editRule()
      .then(() =>
        onRefetch().then(() => {
          //agregar endpoint asignar variables y despues origen-desino cuando este en la tabla
          message.success(i18n.t('FEEDBACK_CHANGES_SAVED_SUCCESS'), 8)
          onClose()
        }),
      )
      .catch((error) => {
        notification.error({
          message: i18n.t('FEEDBACK_DEFAULT_ERROR'),
          description: generateMessageError(error, 'FEEDBACK_SAVE_CHANGES_FAIL'),
          duration: 0,
        })
        onClose()
      })
  }
}

//NOTE: ajustar cuando se necesite una formula de mas de una variable
export const setNameVariableinFormula = ({ formula, variables }) => {
  let auxFormula = ''
  let varIds = {}
  const findVar = variables.find((el) => formula.includes(el.name))

  if (findVar) {
    auxFormula = formula.replace(findVar.name, `variable1`)
    varIds = { ...varIds, variable1: findVar.id }
  }

  return { auxFormula, varIds }
}
