import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import QuestionList from './QuestionList/QuestionList'

const Home = () => (
  <Switch>
    <Route exact path="/preguntas" component={QuestionList} />
    <Redirect to="/preguntas" />
  </Switch>
)

export default Home
