import React from 'react'
import { Table } from 'antd'
import { generateColumns } from '../../../../utils'
import { calculateScrollX } from 'modules/core/utils'
import { ROWS } from 'modules/core/constants'

import './ElementTable.scss'

const ElementTable = ({ dataElement }) => {
  const getRowClassName = (record) => {
    const typeClass = `${ROWS.TYPES[record.type]}`

    document.documentElement.style.setProperty(`--${typeClass}`, record.color)
    return typeClass
  }

  return (
    <div style={!dataElement.title ? { paddingTop: 25 } : {}}>
      <Table
        size="small"
        rowKey="id"
        className="dash-table"
        dataSource={dataElement?.data?.rows}
        rowClassName={getRowClassName}
        columns={generateColumns(dataElement?.data?.column)}
        pagination={false}
        scroll={{
          x: calculateScrollX(generateColumns(dataElement?.data?.column)),
          y: 300,
        }}
      />
    </div>
  )
}

export default ElementTable
