import imagenPlanning1 from 'assets/images/imagenes/portadavideoplanificacion1.png'
import imagenPlanning2 from 'assets/images/imagenes/portadavideoplanificacion2.png'
import imagenPlanning3 from 'assets/images/imagenes/portadavideoplanificacion3.png'
import imagenPlanning4 from 'assets/images/imagenes/portadavideoplanificacion4.png'
import imagenPlanning5 from 'assets/images/imagenes/portadavideoplanificacion5.png'
import imagenPlanning6 from 'assets/images/imagenes/portadavideoplanificacion6.png'
import imagenPlanning7 from 'assets/images/imagenes/portadavideoplanificacion7.png'
import imagenConfig1 from 'assets/images/imagenes/portadavideoconfiguracion1.png'
import imagenConfig2 from 'assets/images/imagenes/portadavideoconfiguracion2.png'
import imagenConfig3 from 'assets/images/imagenes/portadavideoconfiguracion3.png'
import imagenConfig4 from 'assets/images/imagenes/portadavideoconfiguracion4.png'
import imagenConfig5 from 'assets/images/imagenes/portadavideoconfiguracion5.png'
import imagenControl1 from 'assets/images/imagenes/portadavideoseguimiento1.png'
import imagenControl2 from 'assets/images/imagenes/portadavideoseguimiento2.png'
import imagenControl3 from 'assets/images/imagenes/portadavideoseguimiento3.png'
import imagenForecast1 from 'assets/images/imagenes/portadavideoforecast1.png'
import imagenForecast2 from 'assets/images/imagenes/portadavideoforecast2.png'
import imagenForecast3 from 'assets/images/imagenes/portadavideoforecast3.png'
import imagenAnalysis1 from 'assets/images/imagenes/portadavideoanalisis1.png'
import imagenAnalysis2 from 'assets/images/imagenes/portadavideoanalisis2.png'

export const dataCards = {
  analysis: [
    {
      id: 1,
      image: imagenAnalysis1,
      src: 'https://www.youtube.com/embed/RGKNmiIQ2WE?si=3Hize_w8mizMfLdO',
      text: 'REPORT_VIDEOS_DESCRIPTION_1',
    },
    {
      id: 2,
      image: imagenAnalysis2,
      src: 'https://www.youtube.com/embed/UXTp7Ip8LGU?si=uFy2CJeFZf-rrCHy',
      text: 'REPORT_VIDEOS_DESCRIPTION_2',
    },
  ],
  forecast: [
    {
      id: 1,
      image: imagenForecast1,
      src: 'https://www.youtube.com/embed/hTwNe4n92pQ?si=UfeG5npgOYjX65AE',
      text: 'FORECAST_VIDEO_DESCRIPTION_1',
    },
    {
      id: 2,
      image: imagenForecast2,
      src: 'https://www.youtube.com/embed/kJ6UGx2CIzo?si=3i_jdYgWd93xm_yU',
      text: 'FORECAST_VIDEO_DESCRIPTION_2',
    },
    {
      id: 3,
      image: imagenForecast3,
      src: 'https://www.youtube.com/embed/k2Xk6mmXK7I?si=HqysVaYxc0m1huFV',
      text: 'FORECAST_VIDEO_DESCRIPTION_3',
    },
  ],
  control: [
    {
      id: 1,
      image: imagenControl1,
      src: 'https://www.youtube.com/embed/N-QnfEXl_Wk?si=ADFXNhl56WcrKA-g',
      text: 'CONTROL_VIDEO_DESCRIPTION_1',
    },
    {
      id: 2,
      image: imagenControl2,
      src: 'https://www.youtube.com/embed/N-rZVY1zWXc?si=gXvTtmrsm1jbSi3i',
      text: 'CONTROL_VIDEO_DESCRIPTION_2',
    },
    {
      id: 3,
      image: imagenControl3,
      src: 'https://www.youtube.com/embed/i8v4LlMY3zw?si=XCPQciwRs8aDdgQb',
      text: 'CONTROL_VIDEO_DESCRIPTION_3',
    },
  ],
  config: [
    {
      id: 1,
      image: imagenConfig1,
      src: 'https://www.youtube.com/embed/rowMbzlavUM?si=PVtBGIIK8XlDoBEq',
      text: 'CONFIG_VIDEO_DESCRIPTION_1',
    },
    {
      id: 2,
      image: imagenConfig2,
      src: 'https://www.youtube.com/embed/hwrXG6ILA5I?si=WjEhIIeZ8dy9bggz',
      text: 'CONFIG_VIDEO_DESCRIPTION_2',
    },
    {
      id: 3,
      image: imagenConfig3,
      src: 'https://www.youtube.com/embed/9SxYFjMXP74?si=u2pfvOJeRyWId96a',
      text: 'CONFIG_VIDEO_DESCRIPTION_3',
    },
    {
      id: 4,
      image: imagenConfig4,
      src: 'https://www.youtube.com/embed/BfUKnF7lWdU?si=09aBbcqXw1L6TuuB',
      text: 'CONFIG_VIDEO_DESCRIPTION_4',
    },
    {
      id: 5,
      image: imagenConfig5,
      src: 'https://www.youtube.com/embed/cGhliCcApPs?si=NlgCIiKbdzRHZXb1',
      text: 'CONFIG_VIDEO_DESCRIPTION_5',
    },
  ],
  planning: [
    {
      id: 1,
      image: imagenPlanning1,
      src: 'https://www.youtube.com/embed/g6qizGSFhSU?si=GMkPHW2Op00vFXib',
      text: 'PLANNING_VIDEO_DESCRIPTION_1',
    },
    {
      id: 2,
      image: imagenPlanning2,
      src: 'https://www.youtube.com/embed/oAILuLbc2uI?si=98juSn_kyqS4PSga',
      text: 'PLANNING_VIDEO_DESCRIPTION_2',
    },
    {
      id: 3,
      image: imagenPlanning3,
      src: 'https://www.youtube.com/embed/x_3rGxqdJB0?si=uL9tgLtQpiuNPlF7',
      text: 'PLANNING_VIDEO_DESCRIPTION_3',
    },
    {
      id: 4,
      image: imagenPlanning4,
      src: 'https://www.youtube.com/embed/6Mn8ffY3R5M?si=EXALAEt0yMSUdV4u',
      text: 'PLANNING_VIDEO_DESCRIPTION_4',
    },
    {
      id: 5,
      image: imagenPlanning5,
      src: 'https://www.youtube.com/embed/WBuqek1VWnQ?si=SiOqyZTX551wR7Df',
      text: 'PLANNING_VIDEO_DESCRIPTION_5',
    },
    {
      id: 6,
      image: imagenPlanning6,
      src: 'https://www.youtube.com/embed/XgEX2sK39oo?si=suVb5sxs5bggg-y7',
      text: 'PLANNING_VIDEO_DESCRIPTION_6',
    },
    {
      id: 7,
      image: imagenPlanning7,
      src: 'https://www.youtube.com/embed/YDxTdHqGM-0?si=n_erViwreEmbiSUe',
      text: 'PLANNING_VIDEO_DESCRIPTION_7',
    },
  ],
}
