import React from 'react'
import { Space } from 'antd'
import { Typography } from 'modules/core/components'
import { useTranslation } from 'react-i18next'

const InitialHeader = () => {
  const { t } = useTranslation()
  return (
    <Space direction="vertical" className="initial-screen-info-container">
      <Typography.Headline level={3} type="primary">
        {t('DASHBOARD_WELLCOME')}
      </Typography.Headline>
      <Typography.Body>{t('HOME_INITIAL_SCREEN_SUBTITLE')}</Typography.Body>
    </Space>
  )
}

export default InitialHeader
