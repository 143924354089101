import React from 'react'
import { Col, Row, Table } from 'antd'
import { Typography } from 'modules/core/components'
import { COLUMNS } from 'modules/core/constants'
import { calculateScrollX, dateMMYYYY } from 'modules/core/utils'
import numeral from 'numeral'
import moment from 'moment'

import './AdjustmentDetailTable.scss'

const AdjustmentDetailTable = ({
  title = '',
  isLoading = false,
  dataSource = [],
  columns = [],
}) => {
  const columnsGenerator = (columns) =>
    columns.map((column) => {
      return {
        dataIndex: column.data_index,
        title: column.title,
        width: COLUMNS.WIDTH.DIMENSION,
        ellipsis: true,
        ...(column.data_index === 'amount' && {
          align: 'right',
          width: 120,
          render: (amount) => `$ ${numeral(amount).format('0,0')}`,
        }),
        ...(moment(column.title)['_f'] && {
          title: dateMMYYYY(column.title, 'YYYY-MM'),
          width: COLUMNS.WIDTH.TRANSACTION,
        }),
      }
    })

  return (
    <Row gutter={[8, 8]}>
      <Col span={24}>
        <Typography.Subtitle level={2}>{title}</Typography.Subtitle>
      </Col>
      <Col span={24}>
        <Table
          rowKey="id"
          size="small"
          bordered
          loading={isLoading}
          columns={columnsGenerator(columns)}
          dataSource={dataSource}
          scroll={{
            x: calculateScrollX(columnsGenerator(columns)),
          }}
          pagination={{
            size: 'small',
            showSizeChanger: false,
            hideOnSinglePage: true,
          }}
        />
      </Col>
    </Row>
  )
}

export default AdjustmentDetailTable
