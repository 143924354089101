import React from 'react'
import { Carousel } from 'antd'
import { DashboardKpiCard } from './components'
import { CarrouselArrow } from 'modules/core/components'

const CardsCarrousel = ({ dataKPI }) => {
  return (
    <Carousel
      className="dashboard-kpi-carousel"
      slidesToShow={4}
      slidesToScroll={1}
      initialSlide={0}
      infinite={false}
      dots={false}
      arrows={true}
      nextArrow={<CarrouselArrow.Next />}
      prevArrow={<CarrouselArrow.Previus />}
      responsive={[
        {
          breakpoint: 1580,
          settings: { slidesToShow: 3, slidesToScroll: 1 },
        },
        {
          breakpoint: 1350,
          settings: { slidesToShow: 3, slidesToScroll: 1 },
        },
        {
          breakpoint: 1100,
          settings: { slidesToShow: 2, slidesToScroll: 1 },
        },
        {
          breakpoint: 850,
          settings: { slidesToShow: 1, slidesToScroll: 1 },
        },
      ]}
    >
      {dataKPI.map((kpi) => (
        <DashboardKpiCard
          key={kpi.id}
          title={kpi.title}
          id={kpi.id}
          percentage={kpi.percentage}
          value={kpi.value}
          prefix={kpi.prefix}
          name={kpi.name}
          subKpis={kpi.children}
        />
      ))}
    </Carousel>
  )
}

export default CardsCarrousel
