import moment from 'moment'
import i18n from 'i18next'

// INPUT: 19-03-2020 --> OUTPUT: '19/03/2020'
export const dateDDMMYYYY = (date, separator = '/', initialFormat = 'DD-MM-YYYY') =>
  moment(date, initialFormat).locale(i18n.language).format(`DD${separator}MM${separator}YYYY`)

// INPUT: 19-03-2020 --> OUTPUT: 'Mar 2020'
export const dateMMYYYY = (date, initialFormat = 'DD-MM-YYYY') =>
  moment(date, initialFormat).locale(i18n.language).format('MMM YYYY')

// INPUT: 19-03-2020 --> OUTPUT: 'Marzo 2020'
export const dateMMMMYYYY = (date, initialFormat = 'DD-MM-YYYY') =>
  moment(date, initialFormat).locale(i18n.language).format('MMMM YYYY')

// INPUT: 19-03-2020 18:00:03 --> OUTPUT: '19/03/2020 06:00 PM'
export const dateTime = (date, separator = '/', initialFormat = 'DD-MM-YYYY hh:mm:ss') =>
  moment(date, initialFormat)
    .locale(i18n.language)
    .format(`DD${separator}MM${separator}YYYY hh:mm A`)

// INPUT: 19-03-2020 --> OUTPUT: 'hace 2 días'
export const fromNow = (date, initialFormat = 'DD-MM-YYYY hh:mm:ss') =>
  moment(date, initialFormat).fromNow()
