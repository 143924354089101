import React from 'react'
import { Space, Input, Tooltip } from 'antd'
import { Button, ButtonActionBar, DropdownMenu } from 'modules/core/components'
import { useTranslation } from 'react-i18next'
import { SolutionOutlined } from '@ant-design/icons'

const { Search } = Input

const UserActions = ({
  setSearchValue,
  onClick,
  onClickDownload,
  onClickSettings,
  disabled,
  onClickActivity,
}) => {
  const { t } = useTranslation()
  return (
    <ButtonActionBar>
      <Space size="middle">
        <Search
          className="dimension-search"
          placeholder={t('ACTION_SEARCH')}
          allowClear={true}
          onSearch={setSearchValue}
        />
        <Button.Primary onClick={onClick} disabled={disabled} title="ACTION_CREATE_NEW" />
        <Button.Default onClick={onClickDownload} disabled={disabled} title="ACTION_UPLOAD_FILE" />
        <Tooltip title={t('CONFIG_PREFERENCES_DIMENSIONS_INFORMATIVE_TOOLTIP_TITLE')}>
          <Button.Default
            title="CONFIG_SETTINGS_MODAL_TITLE"
            onClick={onClickSettings}
            disabled={disabled}
          />
        </Tooltip>
        <DropdownMenu
          title={t('ACTION_MORE')}
          menu={[
            {
              title: t('ACTION_ACTIVITIES'),
              icon: <SolutionOutlined />,
              onClick: onClickActivity,
            },
          ]}
        />
      </Space>
    </ButtonActionBar>
  )
}

export default UserActions
