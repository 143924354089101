import React from 'react'
import { Space } from 'antd'
import { Button, Typography } from 'modules/core/components'
import { useTranslation } from 'react-i18next'

const StepCard = ({ data }) => {
  const { t } = useTranslation()
  return (
    <Space key={data.id} direction="vertical" className="initial-screen-steps-space">
      <img className="initial-screen-steps-img" src={data.image} alt="" />
      <div style={{ textAlign: 'center' }} className="initial-screen-steps-text">
        <Typography.Body level={2}>{t(data.text)}</Typography.Body>
        {data.link && (
          <Button.Link
            onClick={() => window.open(data.link.url, '_blank')}
            title={data.link.name}
          />
        )}
      </div>
    </Space>
  )
}

export default StepCard
