import React from 'react'
import { Space } from 'antd'
import { Typography } from 'modules/core/components'
import { useTranslation } from 'react-i18next'
import { UploadOutlined } from '@ant-design/icons'

const CardBody = ({ item }) => {
  const { t } = useTranslation()
  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      {item.image ? (
        <img style={item.style} alt={item.name} width={item.width} src={item.image} />
      ) : (
        <Space direction="vertical">
          <Space direction="horizontal">
            <Typography.Icon icon={UploadOutlined} />
            <Typography.Headline level={5}>{t('ACTION_UPLOAD_FILE')}</Typography.Headline>
          </Space>
          <div style={{ textAlign: 'center' }}>
            <Typography.Body>.csv, .xls, .xlsx</Typography.Body>
          </div>
        </Space>
      )}
    </div>
  )
}

export default CardBody
