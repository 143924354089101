import { message, notification } from 'antd'
import { generateMessageError } from 'modules/core/utils'
import i18n from 'i18next'

export const onCreate = ({ form, setIsLoading, onSubmit, onClose }) => {
  form.validateFields().then((values) => {
    setIsLoading(true)
    onSubmit(values)
      .then(() => {
        message.success(i18n.t('MESSAGE_PROCESS_STATUS_SUCCESS'), 8)
        onClose()
      })
      .catch((error) => {
        notification.error({
          message: i18n.t('FEEDBACK_DEFAULT_ERROR'),
          description: generateMessageError(error, 'FEEDBACK_LOAD_DATA_ERROR'),
          duration: 0,
        })
        onClose()
      })
  })
}
