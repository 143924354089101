import React, { useEffect, useRef } from 'react'
import { embedDashboard } from 'amazon-quicksight-embedding-sdk'

const QuickSightDashboard = ({ embedUrl }) => {
  const containerRef = useRef(null)

  useEffect(() => {
    if (embedUrl && containerRef.current) {
      const options = {
        url: embedUrl,
        container: containerRef.current,
        parameters: {
          country: 'US',
        },
        scrolling: 'no',
        iframeResizeOnSheetChange: true,
        height: '100%',
        width: '100%',
        locale: 'en-US',
      }

      const dashboard = embedDashboard(options)

      // Manejo de errores
      dashboard.on('error', (event) => {
        console.error('Error embedding dashboard:', event)
      })
      dashboard.on('load', (event) => {
        console.error('Error embedding dashboard:', event)
      })
    }
  }, [embedUrl])

  return <div ref={containerRef} style={{ height: '100%', width: '100%' }} />
}

export default QuickSightDashboard
