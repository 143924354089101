import { useLocation, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import { Skeleton } from 'antd'
import PeriodNavigator from '../Navigator/PeriodNavigator'
import Navigator from '../Navigator/Navigator'

const { SiderLayout } = require('modules/core/layouts')

export const FRONT_URL = process.env.REACT_APP_FRONT_URL || 'https://next.stg.getplika.com'

const WorkflowScreen = ({ moduleType = null }) => {
  const [loadingIframe, setLoadingIframe] = useState(false)
  const { t } = useTranslation()
  let { periodId, forecastId } = useParams()

  const urlParams = new URLSearchParams(useLocation().search)
  const type = urlParams.get('type')

  const paramsQuery = new URLSearchParams({
    ...(type != null ? { type: type } : {}),
    ...(moduleType != null ? { moduleWorkflow: moduleType } : {}),
  }).toString()

  const renderHeader = () => {
    const customItem = () => {
      switch (type) {
        case 'planning':
          return [{ name: t('LABEL_PLANNING'), url: `/planificacion/${periodId}` }]
        case 'forecast':
          return [
            { name: t('LABEL_FORECAST'), url: `/forecast/${periodId}` },
            {
              name: `${t('LABEL_DETAIL')}: ${t('LABEL_FORECAST')}`,
              url: `/forecast/${periodId}/${forecastId}`,
            },
          ]
        default:
          return []
      }
    }

    if (moduleType) {
      return (
        <Navigator
          floated={false}
          size="big"
          style={{ padding: 20 }}
          items={[
            {
              name: t('LABEL_CONFIG'),
              url: `/configuracion`,
            },
            { name: t('LABEL_INTEGRATION') },
          ]}
        />
      )
    }

    return (
      !moduleType && (
        <PeriodNavigator
          style={{ paddingLeft: 20 }}
          items={[...customItem(), ...[{ name: 'Workflow' }]]}
        />
      )
    )
  }

  const handleLoad = () => {
    console.log('Iframe loaded')
    setLoadingIframe(false)
  }

  return (
    <SiderLayout className={'dashboard-home-iframe'}>
      {renderHeader()}
      {loadingIframe && <Skeleton active loading={loadingIframe} paragraph={{ rows: 10 }} />}
      <iframe
        id="workflow"
        title="Workflow"
        src={`${FRONT_URL}/workflow?${paramsQuery}`}
        width="100%"
        height="100%"
        style={{
          border: 'none',
          padding: '0 0',
          overflowX: 'hidden',
          overflowY: 'auto',
        }}
        onLoad={handleLoad}
      ></iframe>
    </SiderLayout>
  )
}

export default WorkflowScreen
