import { message, notification } from 'antd'
import { generateMessageError } from 'modules/core/utils'
import i18n from 'i18next'
import _ from 'lodash'

export const onEditRole = ({ dataCellEdit, setIsLoading, editRole, onRefetch, onClose }) => {
  if (!_.isEmpty(dataCellEdit)) {
    setIsLoading(true)
    editRole()
      .then(() => {
        onRefetch()
        message.success(i18n.t('FEEDBACK_CHANGES_SAVED_SUCCESS'), 8)
        onClose()
      })
      .catch((error) => {
        notification.error({
          message: i18n.t('FEEDBACK_DEFAULT_ERROR'),
          description: generateMessageError(error),
          duration: 0,
        })
        onClose()
      })
  }
}

export const onDeleteRole = ({ setIsLoading, deleteRole, onRefetch, onClose }) => {
  setIsLoading(true)
  deleteRole()
    .then(() => {
      onRefetch()
      message.success(i18n.t('FEEDBACK_CHANGES_SAVED_SUCCESS'), 8)
      onClose()
    })
    .catch((error) => {
      notification.error({
        message: i18n.t('FEEDBACK_DEFAULT_ERROR'),
        description: generateMessageError(error),
        duration: 0,
      })
      onClose()
    })
}
