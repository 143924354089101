import { isEmpty } from 'lodash'
import React from 'react'
import {
  aggregators,
  flatKey,
  formatter,
  normalizeName,
  PivotData,
  redColorScaleGenerator,
  spanSize,
  usFmt,
  usFmtPct,
} from '../../../Utilities'

function makeRenderer(opts = {}) {
  class TableRenderer extends React.PureComponent {
    getBasePivotSettings() {
      const props = this.props
      const colAttrs = props.cols
      const rowAttrs = props.rows

      const pivotData = new PivotData(this.props, {
        rowEnabled: true,
        colEnabled: false,
      })
      const colKeys = pivotData.getColKeys()
      const rowKeys = pivotData.getRowKeys()

      const tableOptions = props.tableOptions
      // Custom lines
      const linesOrder = !isEmpty(tableOptions?.linesOrder) ? tableOptions.linesOrder : []
      const linesTotal = !isEmpty(tableOptions?.linesTotal) ? tableOptions.linesTotal : []
      const linesRatio = !isEmpty(tableOptions?.linesRatio) ? tableOptions.linesRatio : []
      const linesRatioN = !isEmpty(tableOptions?.linesRatioN) ? tableOptions.linesRatioN : []
      const linesFinancial = !isEmpty(tableOptions?.linesFinancial)
        ? tableOptions.linesFinancial
        : null
      const lineVertical = !isEmpty(tableOptions?.lineVertical) ? tableOptions.lineVertical : null

      return Object.assign(
        {
          pivotData,
          colAttrs,
          rowAttrs,
          colKeys,
          rowKeys,
          linesOrder,
          linesTotal,
          linesRatio,
          linesRatioN,
          linesFinancial,
          lineVertical,
        },
        TableRenderer.heatmapMappers(pivotData, props.tableColorScaleGenerator, colKeys, rowKeys),
      )
    }

    static heatmapMappers(pivotData, colorScaleGenerator, colKeys, rowKeys) {
      let valueCellColors = () => {}
      let rowTotalColors = () => {}
      let colTotalColors = () => {}
      if (opts.heatmapMode) {
        const rowTotalValues = colKeys.map((x) => pivotData.getAggregator([], x).value())
        rowTotalColors = colorScaleGenerator(rowTotalValues)
        const colTotalValues = rowKeys.map((x) => pivotData.getAggregator(x, []).value())
        colTotalColors = colorScaleGenerator(colTotalValues)

        if (opts.heatmapMode === 'full') {
          const allValues = []
          rowKeys.map((r) =>
            colKeys.map((c) => allValues.push(pivotData.getAggregator(r, c).value())),
          )
          const colorScale = colorScaleGenerator(allValues)
          valueCellColors = (r, c, v) => colorScale(v)
        } else if (opts.heatmapMode === 'row') {
          const rowColorScales = {}
          rowKeys.map((r) => {
            const rowValues = colKeys.map((x) => pivotData.getAggregator(r, x).value())
            rowColorScales[r] = colorScaleGenerator(rowValues)
          })
          valueCellColors = (r, c, v) => rowColorScales[r](v)
        } else if (opts.heatmapMode === 'col') {
          const colColorScales = {}
          colKeys.map((c) => {
            const colValues = rowKeys.map((x) => pivotData.getAggregator(x, c).value())
            colColorScales[c] = colorScaleGenerator(colValues)
          })
          valueCellColors = (r, c, v) => colColorScales[c](v)
        }
      }

      return { valueCellColors, rowTotalColors, colTotalColors }
    }

    getFormatter(pivotData) {
      let formatterFunction = usFmt
      if (formatter.hasOwnProperty(pivotData.props.aggregatorName)) {
        formatterFunction = formatter[pivotData.props.aggregatorName]
      }
      return formatterFunction
    }

    getAggregator(pivotData, rowKey, colKey) {
      try {
        const agg = pivotData.getAggregator(rowKey, colKey)
        return agg.value()
      } catch (e) {
        return 0
      }
    }

    renderColHeaderRow(attrName, attrIdx, pivotSettings, lineVertical) {
      const { colAttrs, rowAttrs, colKeys } = pivotSettings

      const spanCell =
        attrIdx === 0 && rowAttrs.length !== 0 ? (
          <th className={`header_0`} colSpan={rowAttrs.length} rowSpan={colAttrs.length} />
        ) : null

      const attrNameCell = (
        <th
          key="label"
          className={`pvtAxisLabel header_${rowAttrs.length} header_tot`}
          style={{ textAlign: 'right' }}
        >
          {attrName}
        </th>
      )

      const attrValueCells = colKeys.map((colKey, i) => {
        const x = spanSize(colKeys, i, attrIdx)
        if (x === -1) {
          return null
        }
        return (
          <th
            className="pvtColLabel"
            key={`colKey-${i}`}
            colSpan={x}
            rowSpan={attrIdx === colAttrs.length - 1 && rowAttrs.length !== 0 ? 2 : 1}
          >
            {colKey[attrIdx]}
          </th>
        )
      })

      const totalCell =
        attrIdx === 0 ? (
          <th
            key="total"
            className="pvtTotalLabel"
            rowSpan={colAttrs.length + (rowAttrs.length === 0 ? 0 : 1)}
          >
            TOTAL
          </th>
        ) : null

      const cells = [spanCell, attrNameCell, ...attrValueCells, totalCell]
      return <tr key={`colAttr-${attrIdx}`}>{cells}</tr>
    }

    renderRowHeaderRow(pivotSettings) {
      const { rowAttrs, colAttrs } = pivotSettings
      return rowAttrs.length !== 0 ? (
        <tr key="rowHdr">
          {rowAttrs.map(function (r, i) {
            return (
              <th className={`pvtAxisLabel header_${i}`} key={`rowAttr${i}`}>
                {r}
              </th>
            )
          })}
          <th className={`pvtTotalLabel header_${rowAttrs.length} header_tot`}>
            {colAttrs.length === 0 ? 'Totals' : null}
          </th>
        </tr>
      ) : null
    }

    renderLinesRow(rowKey, rowIdx, pivotSettings, visibleRowKeys) {
      const { linesOrder, linesTotal, linesRatio, linesRatioN, lineVertical } = pivotSettings

      let isTree = false
      let flatRowKey = flatKey(rowKey)

      if (rowKey.length > 1) {
        flatRowKey = rowKey[0]
        isTree = true
      }
      const line = linesOrder?.find((it) => normalizeName(it.name) === flatRowKey)
      const lineTotal = linesTotal?.find((it) => normalizeName(it.name) === flatRowKey)
      const lineRatio = linesRatio?.find((it) => normalizeName(it.name) === flatRowKey)
      const lineRatioN = linesRatioN?.find((it) => normalizeName(it.name) === flatRowKey)
      const originalRowIdx = visibleRowKeys.findIndex((entry) => flatKey(entry) === flatKey(rowKey))

      if (lineTotal) {
        if (!isTree) {
          return this.renderTableTotalRow(rowKey, rowIdx, pivotSettings, lineTotal, line)
        }
      } else if (lineRatio) {
        if (!isTree) {
          return this.renderTableRatioRow(rowKey, rowIdx, pivotSettings, lineRatio, usFmtPct, line)
        }
      } else if (lineRatioN) {
        if (!isTree) {
          return this.renderTableRatioRow(rowKey, rowIdx, pivotSettings, lineRatioN, usFmt, line)
        }
      } else if (originalRowIdx < 0) {
        return this.renderTableLineZeroRow(rowKey, rowIdx, pivotSettings, line)
      } else {
        return this.renderTableRow(rowKey, originalRowIdx, pivotSettings, lineVertical, line)
      }
    }

    renderTableTotalRow(rowKey, rowIdx, pivotSettings, lineTotal, line) {
      const { colKeys, rowAttrs, pivotData } = pivotSettings
      const flatRowKey = flatKey(rowKey)
      const formatterFunction = this.getFormatter(pivotData)

      const lines = lineTotal.lines.reduce((mergedLines, it) => {
        return mergedLines.concat(it.lines)
      }, [])

      const attrValueCells = rowKey.map((r, i) => {
        return (
          <th
            key={`rowKeyLabel-${i}-${rowIdx}`}
            className={`pvtRowLabel fixed ${!line?.color ? 'pvtTotalizer' : ''}`}
            colSpan={rowAttrs.length + 1}
            style={{
              ...(line?.color
                ? { backgroundColor: line?.color }
                : { backgroundColor: '#eaeaea !important' }),
            }}
          >
            {r}
          </th>
        )
      })

      let valueCells = colKeys.map((colKey) => {
        const flatColKey = flatKey(colKey)
        const accumulator = lines.reduce((acc, it) => {
          const amount = this.getAggregator(pivotData, [normalizeName(it)], colKey)
          return acc + amount
        }, 0)

        return (
          <td
            className={`pvtVal ${!line?.color ? 'pvtTotalizer' : ''}`}
            key={'pvtVal-' + flatColKey}
            style={{ ...(line?.color ? { backgroundColor: line?.color } : {}) }}
          >
            {formatterFunction(accumulator)}
          </td>
        )
      })

      const accumulator = lines.reduce((acc, it) => {
        const amount = this.getAggregator(pivotData, [normalizeName(it)], [])
        return acc + amount
      }, 0)

      const totalCell = (
        <td
          key="total"
          className={`pvtTotal ${!line?.color ? 'pvtTotalizer' : ''}`}
          style={{ ...(line?.color ? { backgroundColor: line?.color } : {}) }}
        >
          {formatterFunction(accumulator)}
        </td>
      )

      const rowCells = [...attrValueCells, ...valueCells, totalCell]
      return <tr key={'keyRow-' + flatRowKey}>{rowCells}</tr>
    }

    renderTableLineZeroRow(rowKey, rowIdx, pivotSettings, line) {
      const { colKeys, rowAttrs, pivotData } = pivotSettings
      const flatRowKey = flatKey(rowKey)
      const formatterFunction = this.getFormatter(pivotData)

      const attrValueCells = rowKey.map((r, i) => {
        return (
          <th
            key={`rowKeyLabel-${i}-${rowIdx}`}
            className={`pvtRowLabel fixed`}
            colSpan={rowAttrs.length + 1}
            style={{ ...(line?.color ? { backgroundColor: line?.color } : {}) }}
          >
            {r}
          </th>
        )
      })

      const valueCells = colKeys.map((colKey) => {
        const flatColKey = flatKey(colKey)
        const accumulator = 0

        return (
          <td
            className={`pvtVal`}
            key={'pvtVal-' + flatColKey}
            style={{ ...(line?.color ? { backgroundColor: line?.color } : {}) }}
          >
            {formatterFunction(accumulator)}
          </td>
        )
      })

      const totalCell = (
        <td
          key="total"
          className="pvtTotal"
          style={{ ...(line?.color ? { backgroundColor: line?.color } : {}) }}
        >
          {formatterFunction(0)}
        </td>
      )

      const rowCells = [...attrValueCells, ...valueCells, totalCell]
      return <tr key={'keyRow-' + flatRowKey}>{rowCells}</tr>
    }

    renderTableRatioRow(rowKey, rowIdx, pivotSettings, lineRatio, formatter = usFmt, line) {
      const { colKeys, rowAttrs, pivotData } = pivotSettings
      const flatRowKey = flatKey(rowKey)

      let lines1 = []
      let lines2 = []
      if (!isEmpty(lineRatio.lines)) {
        lines1 = lineRatio.lines[0]
        lines2 = lineRatio.lines[1]
      }

      const attrValueCells = rowKey.map((r, i) => {
        return (
          <th
            key={`rowKeyLabel-${i}-${rowIdx}`}
            className={`pvtRowLabel fixed ${!line?.color ? 'pvtRatio' : ''}`}
            colSpan={rowAttrs.length + 1}
            style={{ ...(line?.color ? { backgroundColor: line?.color } : {}) }}
          >
            {r}
          </th>
        )
      })

      let valueCells = colKeys.map((colKey) => {
        const flatColKey = flatKey(colKey)

        let result = 0
        const accumulator1 = lines1.lines.reduce((acc, it) => {
          const amount = this.getAggregator(pivotData, [normalizeName(it)], colKey)
          return acc + amount
        }, 0)
        const accumulator2 = lines2.lines.reduce((acc, it) => {
          const amount = this.getAggregator(pivotData, [normalizeName(it)], colKey)
          return acc + amount
        }, 0)

        if (accumulator2 !== 0.0) {
          result = accumulator1 / accumulator2
        }

        return (
          <td
            className={`pvtVal ${!line?.color ? 'pvtRatio' : ''}`}
            key={'pvtVal-' + flatColKey}
            style={{ ...(line?.color ? { backgroundColor: line?.color } : {}) }}
          >
            {formatter(result)}
          </td>
        )
      })

      let result = 0
      const accumulator1 = lines1.lines.reduce((acc, it) => {
        const amount = this.getAggregator(pivotData, [normalizeName(it)], [])
        return acc + amount
      }, 0)

      const accumulator2 = lines2.lines.reduce((acc, it) => {
        const amount = this.getAggregator(pivotData, [normalizeName(it)], [])
        return acc + amount
      }, 0)

      if (accumulator2 !== 0.0) {
        result = accumulator1 / accumulator2
      }

      const totalCell = (
        <td
          key="total"
          className={`pvtTotal ${!line?.color ? 'pvtRatio' : ''}`}
          style={{ ...(line?.color ? { backgroundColor: line?.color } : {}) }}
        >
          {formatter(result)}
        </td>
      )

      const rowCells = [...attrValueCells, ...valueCells, totalCell]
      return <tr key={'keyRow-' + flatRowKey}>{rowCells}</tr>
    }

    renderTableRow(rowKey, rowIdx, pivotSettings, lineVertical, line) {
      const { colKeys, pivotData, rowAttrs, rowTotalColors, valueCellColors } = pivotSettings
      const flatRowKey = flatKey(rowKey)
      const formatterFunction = this.getFormatter(pivotData)
      const attrValueCells = rowKey.map((txt, j) => {
        const x =
          rowKey.length < rowAttrs.length ? rowAttrs.length + 1 : j === rowAttrs.length - 1 ? 2 : 1

        return (
          <th key={`rowKeyLabel-${rowIdx}-${j}`} className={`pvtRowLabel header_${j}`} colSpan={x}>
            {txt}
          </th>
        )
      })

      let valueCells = colKeys.map((colKey, j) => {
        const aggValue = this.getAggregator(pivotData, rowKey, colKey)
        const style = valueCellColors(rowKey, colKey, aggValue)

        return (
          <td
            className="pvtVal"
            key={`pvtVal-${rowIdx}-${j}`}
            style={{ ...(style ? style : line?.color ? { backgroundColor: line?.color } : {}) }}
          >
            {formatterFunction(aggValue)}
          </td>
        )
      })

      const aggValue = this.getAggregator(pivotData, rowKey, [])
      const style = rowTotalColors(aggValue)
      const totalCell = (
        <td
          key="total"
          className="pvtTotal"
          style={{ ...(style ? style : line?.color ? { backgroundColor: line?.color } : {}) }}
        >
          {formatterFunction(aggValue)}
        </td>
      )

      const rowCells = [...attrValueCells, ...valueCells, totalCell]
      return <tr key={'keyRow-' + flatRowKey}>{rowCells}</tr>
    }

    render() {
      if (this.cachedProps !== this.props) {
        this.cachedProps = this.props
        this.cachedBasePivotSettings = this.getBasePivotSettings()
      }
      const { colAttrs, rowAttrs, rowKeys, linesOrder, lineVertical } = this.cachedBasePivotSettings
      const pivotSettings = this.cachedBasePivotSettings

      let visibleRowKeys = []
      linesOrder.forEach((it) => {
        if (it.type === 'grouper') {
          const listFilter = rowKeys.filter((entry) => it.name === entry[0])
          if (listFilter.length > 0) {
            const firstItem = listFilter[0].length
            const sortedFilter = listFilter.filter((entry) => entry.length === firstItem)
            visibleRowKeys.push(...sortedFilter)
          } else {
            visibleRowKeys.push([it.name])
          }
        } else {
          visibleRowKeys.push([it.name])
        }
      })

      return (
        <table className="pvtTable">
          <thead>
            {colAttrs.map((it, index) =>
              this.renderColHeaderRow(it, index, pivotSettings, lineVertical),
            )}
            {rowAttrs.length !== 0 && this.renderRowHeaderRow(pivotSettings)}
          </thead>
          <tbody>
            {visibleRowKeys.map((r, i) => this.renderLinesRow(r, i, pivotSettings, rowKeys))}
          </tbody>
        </table>
      )
    }
  }

  TableRenderer.defaultProps = PivotData.defaultProps
  TableRenderer.propTypes = PivotData.propTypes
  TableRenderer.defaultProps.aggregators = aggregators
  TableRenderer.defaultProps.tableColorScaleGenerator = redColorScaleGenerator
  TableRenderer.defaultProps.tableOptions = {}
  return TableRenderer
}

export default makeRenderer
