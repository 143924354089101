import React, { useState } from 'react'
import { Modal, Row, Col, DatePicker } from 'antd'
import { Typography } from 'modules/core/components'
import { useTranslation } from 'react-i18next'
// import { GA } from 'modules/core/utils';
import { connect } from 'react-redux'
import { onDisabledDates, onPublishDates } from './utils'
import control from 'modules/control'
import moment from 'moment'

const PublishModal = ({
  visible,
  monthsToPublish,
  onConfirm = () => {},
  onClose,
  onSetDate,
  date,
  publishMonth,
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const { t } = useTranslation()

  const onCancel = () => {
    onSetDate([])
    onClose()
    setIsLoading(false)
  }

  return (
    <>
      <Modal
        title={t('CONTROL_MODAL_ACTION_DATA_TITLE', {
          action: t('ACTION_PUBLISH'),
        })}
        okText={t('ACTION_PUBLISH')}
        cancelText={t('ACTION_CANCEL')}
        centered
        visible={visible}
        onCancel={onCancel}
        width={568}
        okButtonProps={{ loading: isLoading, disabled: !date }}
        onOk={() =>
          onPublishDates({
            setIsLoading,
            publishMonth: () =>
              publishMonth({
                date_start: moment(date[0]).format('YYYY-MM'),
                date_end: moment(date[1]).format('YYYY-MM'),
              }),
            onClose: onCancel,
            onConfirm,
          })
        }
        destroyOnClose={true}
      >
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <Typography.Body level={2}>
              {t('CONTROL_CONCEPT_PUBLISH_MODAL_TEXT_SALES_EXPENSES')}
            </Typography.Body>
          </Col>
          <Col span={24}>
            <DatePicker.RangePicker
              defaultPickerValue={moment(monthsToPublish[0])}
              format="MMMM YYYY"
              picker="month"
              style={{ width: 260 }}
              disabledDate={(date) => onDisabledDates(date, monthsToPublish)}
              onChange={onSetDate}
            />
          </Col>
        </Row>
      </Modal>
    </>
  )
}

const mapDispatchToProps = {
  publishMonth: control.actions.publishMonth,
}

export default connect(null, mapDispatchToProps)(PublishModal)
