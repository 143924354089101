import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { ProjectionTrackingScreen, WorkflowScreen } from 'modules/core/components'
import PlanningDetail from './PlanningDetail/PlanningDetail'
import PlanningHome from './PlanningHome/PlanningHome'
import GlobalBudgetDetail from './GlobalBudgetDetail/GlobalBudgetDetail'

const Home = () => (
  <Switch>
    <Route exact path="/planificacion/:periodId" component={PlanningHome} />
    <Route
      exact
      path="/planificacion/:periodId/presupuestoglobal/:globalBudgetId"
      component={GlobalBudgetDetail}
    />
    <Route
      exact
      path="/planificacion/:periodId/:conceptName/:budgetId"
      component={PlanningDetail}
    />
    <Route
      exact
      path="/planificacion/:periodId/:conceptName/:step/actividad/:projectionId"
      component={ProjectionTrackingScreen}
    />
    `
    <Route exact path="/planificacion/:periodId/actividad" component={ProjectionTrackingScreen} />`
    <Route
      exact
      path="/planificacion/:periodId/presupuestoglobal/:globalBudgetId/actividad"
      component={ProjectionTrackingScreen}
    />
    <Route exact path="/planificacion/:periodId/workflow" component={WorkflowScreen} />`
    <Redirect to="/planificacion" />
  </Switch>
)

export default Home
