import React from 'react'
import { Space, InputNumber } from 'antd'
import { Typography } from 'modules/core/components'

const InputMonth = ({
  index,
  month,
  disabled = false,
  onChange,
  style = {},
  className = '',
  ...props
}) => {
  return (
    <Space direction="vertical" key={index} style={style} className={className}>
      <Typography.Subtitle level={2}>{month}</Typography.Subtitle>
      <InputNumber {...props} size="small" disabled={disabled} onChange={onChange} />
    </Space>
  )
}

export default InputMonth
