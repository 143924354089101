const MODULE_REPORTED = 'reported'
const SUBMODULE_QUICKSIGHT = 'reported quicksight'
const ACTION_CLICK = 'Click'
const ACTION_MODAL = 'Modal'
const ACTION_DOWNLOAD = 'Download'

const SOCIETIES_KEYS = {
  MODULE_REPORTED: MODULE_REPORTED,
  SUBMODULE_QUICKSIGHT: SUBMODULE_QUICKSIGHT,
  ACTION_CLICK: 'click',
  ACTION_MODAL: 'modal',
  ACTION_DOWNLOAD: 'download',
}

const SOCIETIES_ACTION = [
  { value: 'click', label: ACTION_CLICK },
  { value: 'modal', label: ACTION_MODAL },
  { value: 'download', label: ACTION_DOWNLOAD },
]

const SOCIETIES = {
  SOCIETIES_KEYS,
  SOCIETIES_ACTION,
}

export default SOCIETIES
