import React, { useEffect, useState } from 'react'
import { Row, Col, Spin } from 'antd'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import {
  Navigator,
  EmptyScreen,
  CreateUserModal,
  GeneralActions,
  UserTableList,
  Button,
} from 'modules/core/components'
import { useParams } from 'react-router-dom'
import { usePagination } from 'modules/core/customHooks'
import { ROLES } from 'modules/core/constants'
import { isUserAllowed } from 'modules/core/utils'
import { AdminLayout } from 'modules/core/layouts'
import { fetchSocietiesDimensions } from '../../actions'
import { getSocietiesDimensions } from '../../selectors'
import { ConfigurateSocietyModal } from './components'
import emptyStateImage from 'assets/images/compositions/empty-big.svg'
import societies from 'modules/societies'
import login from 'modules/login'
import _ from 'lodash'

const { SETTINGS__SECURITY__USERS__ABM } = ROLES

const SocietiesDetail = ({
  societiesDetail,
  fetchSocietiesDetail,
  fetchFunctionsButtonsList,
  fetchSocietiesDimensions,
  loggedUser: { isSuperuser },
}) => {
  const [searchValue, setSearchValue] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [tablePagination, setTablePagination] = usePagination()
  const [showCreateModal, setShowCreateModal] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const { t } = useTranslation()
  let { societyId } = useParams()

  useEffect(() => {
    setIsLoading(true)
    Promise.all([
      fetchSocietiesDetail(societyId, { search: searchValue }),
      fetchSocietiesDimensions({ society: societyId }),
      fetchFunctionsButtonsList({ society_id: societyId }),
    ])
      .then(() => {
        setIsLoading(false)
      })
      .catch((e) => {
        setIsLoading(false)
      })
  }, [
    fetchSocietiesDetail,
    fetchSocietiesDimensions,
    fetchFunctionsButtonsList,
    societyId,
    searchValue,
  ])

  const userCanCreate = isUserAllowed(SETTINGS__SECURITY__USERS__ABM)

  const onRefetch = () => fetchSocietiesDetail(societyId, { search: searchValue })

  const renderHeader = () => (
    <Navigator
      floated={false}
      size="big"
      items={[
        {
          name: t('SOCIETY_LABEL'),
          url: `/empresas`,
        },
        {
          name: societiesDetail.name ? societiesDetail.name : '',
        },
      ]}
    />
  )

  const renderEmptyScreen = () => (
    <EmptyScreen
      image={emptyStateImage}
      title={t('LABEL_USERS')}
      description={t('SOCIETY_EMPTY_SCREEN_USER_DESCRIPTION')}
      footer={<Button.Primary onClick={() => setShowCreateModal(true)} title="ACTION_CREATE_NEW" />}
    />
  )

  const renderUserActions = () => (
    <GeneralActions
      setSearchValue={setSearchValue}
      setShowCreateModal={setShowCreateModal}
      userCanCreate={isSuperuser || userCanCreate}
      isSuperuser={isSuperuser}
      onClickConfigurate={() => setShowModal(true)}
    />
  )

  const renderTable = () => (
    <UserTableList
      isLoading={isLoading}
      onTableChange={setTablePagination}
      pagination={tablePagination}
      dataSource={societiesDetail.users}
      onRefetch={() => onRefetch()}
      canDelete={true}
    />
  )

  return (
    <AdminLayout>
      <div className="societies-list-container">
        <Spin spinning={isLoading} />
        <Row gutter={[12, 12]}>
          <Col span={24}>{renderHeader()}</Col>
          {!_.isEmpty(societiesDetail) && !isLoading && societiesDetail.users.length <= 0 && (
            <Col span={24}>{renderEmptyScreen()}</Col>
          )}
          {!_.isEmpty(societiesDetail) && societiesDetail.users.length > 0 && (
            <>
              <Col span={24}>{renderUserActions()}</Col>
              <Col span={24}>{renderTable()}</Col>
            </>
          )}
        </Row>
        <CreateUserModal
          visible={showCreateModal}
          onCancel={() => setShowCreateModal(false)}
          onRefetch={() => onRefetch()}
        />
        <ConfigurateSocietyModal
          visible={showModal}
          onCancel={() => setShowModal(false)}
          societyId={societyId}
          onRefetch={() => fetchSocietiesDetail(societyId, { search: searchValue })}
          onRefetchButtonList={() => fetchFunctionsButtonsList({ society_id: societyId })}
          userList={societiesDetail.users}
          has_quicksight={societiesDetail?.quicksight}
          has_ml={societiesDetail?.is_ml}
          enabledFormat={societiesDetail?.format_enable}
          format_number={societiesDetail?.formats_number}
        />
      </div>
    </AdminLayout>
  )
}

const mapStateToProps = (state) => ({
  societiesDetail: societies.selectors.getSocietiesDetail(state),
  loggedUser: login.selectors.getWhoAmI(state),
})

const mapDispatchToProps = {
  fetchSocietiesDetail: societies.actions.fetchSocietiesDetail,
  fetchFunctionsButtonsList: societies.actions.fetchFunctionsButtonsList,
  fetchSocietiesDimensions: societies.actions.fetchSocietiesDimensions,
}

export default connect(mapStateToProps, mapDispatchToProps)(SocietiesDetail)
