import { useTranslation } from 'react-i18next'
import ButtonBase from '../../ButtonBase'

const ButtonLink = ({ title, disabled = false, onClick = () => {}, ...props }) => {
  const { t } = useTranslation()
  return (
    <ButtonBase {...props} type="link" title={t(title)} disabled={disabled} onClick={onClick}>
      {t(title)}
    </ButtonBase>
  )
}
export default ButtonLink
