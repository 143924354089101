import React from 'react'
import { Popconfirm, Space } from 'antd'
import { Button, DropdownMenu, Typography } from 'modules/core/components'
import { useTranslation } from 'react-i18next'
import { EyeOutlined, DeleteOutlined, EditOutlined, CopyOutlined } from '@ant-design/icons'

const TableActions = ({
  popconfirmOnConfirm,
  popconfirmOnCancel,
  popconfirmVisible,
  isLoading,
  detailPermissionsOnClick,
  editOnClick,
  disabled,
  duplicateOnClick,
  deleteOnClick,
}) => {
  const { t } = useTranslation()
  return (
    <Popconfirm
      placement="bottomRight"
      title={<div style={{ width: 300 }}>{t('CONFIG_ROLES_POPCONFIRM_DELETE_ROLE_TITLE')}</div>}
      okText={t('ACTION_DELETE')}
      onConfirm={popconfirmOnConfirm}
      okbuttonprops={{ loading: isLoading }}
      cancelText={t('ACTION_CANCEL')}
      onCancel={popconfirmOnCancel}
      visible={popconfirmVisible}
    >
      <Space size="middle">
        <Button.Icon
          icon={<Typography.Icon icon={EyeOutlined} />}
          title="CONFIG_ROLES_VIEW_TITLE"
          onClick={detailPermissionsOnClick}
        />
        <DropdownMenu
          title={t('ACTION_MORE')}
          menu={[
            {
              title: t('ACTION_EDIT'),
              icon: <EditOutlined />,
              onClick: editOnClick,
              disabled,
            },
            {
              title: t('ACTION_DUPLICATE'),
              icon: <CopyOutlined />,
              onClick: duplicateOnClick,
              disabled,
            },
            {
              title: t('ACTION_DELETE'),
              icon: <DeleteOutlined />,
              onClick: deleteOnClick,
              disabled,
            },
          ]}
        />
      </Space>
    </Popconfirm>
  )
}

export default TableActions
