import React, { useState } from 'react'
import { Card, Col, Dropdown, Menu, message, Row, Space } from 'antd'
import { KPIValue } from 'modules/core/components/KPI/KPI'
import { CommentOutlined } from '@ant-design/icons'
import { connect } from 'react-redux'
import { generateMessageError } from 'modules/core/utils'
import { useTranslation } from 'react-i18next'
import Typography from 'modules/core/components/Typography/Typography'
import numeral from 'numeral'
import Comments from '../../Comments/Comments'
import configuration from 'modules/configuration'
import _ from 'lodash'
import { GENERAL } from 'modules/core/constants'

const PhaseKpis = ({
  kpiLoading,
  dataKpis,
  userDetail,
  setData,
  comments,
  onRefetchComments,
  loading,
  phase,
  elementRefId,
  hasComment,
  setHasComment,
  createComment,
  removeNewComment,
}) => {
  const [viewComment, setViewComment] = useState({})
  const [isSaving, setIsSaving] = useState(false)

  const { t } = useTranslation()

  const key = window.location.pathname.replaceAll('/', '-')

  const handleCreate = ({ text, usersIds, id, idComment = null, setIsNewThread }) => {
    const dataa = {
      text,
      key: `${key}-${phase}-card-${id}`,
      module: `${key}-${phase}`,
      mentions: usersIds,
      ...(idComment && {
        parent: idComment,
      }),
    }
    setIsSaving(true)
    createComment(dataa)
      .then(() =>
        onRefetchComments().then(() => {
          message.success(t('FEEDBACK_SAVE_CHANGES_SUCCES'), 8)
          setIsSaving(false)
          setIsNewThread(false)
        }),
      )
      .catch((error) => {
        message.error(generateMessageError(error, 'FEEDBACK_SAVE_CHANGES_FAIL_MESSAGE'), 8)
        setIsSaving(false)
        setIsNewThread(false)
      })
  }

  return (
    <Row gutter={[24, 0]}>
      <>
        {kpiLoading &&
          [1, 2, 3].map((index) => (
            <Col span={8} key={index}>
              <Card loading={true} />
            </Col>
          ))}
        {!kpiLoading &&
          dataKpis.map((kpi) => (
            <Col span={8} key={kpi.id}>
              <Dropdown
                trigger={['contextMenu']}
                overlay={
                  <Menu>
                    {GENERAL.OPTION_COMMENT.map((el) => (
                      <Menu.Item
                        key={el.key}
                        icon={<CommentOutlined />}
                        onClick={() => {
                          setViewComment({ ...viewComment, [kpi.id]: true })
                          setHasComment({
                            type: 'card',
                            data: !_.isEmpty(hasComment?.card)
                              ? !_.isEmpty(hasComment?.card[`${key}-${phase}`])
                                ? !hasComment?.card[`${key}-${phase}`][kpi.id]
                                : true
                              : true,
                            key: `${key}-${phase}`,
                            id: kpi.id,
                          })
                        }}
                      >
                        {t(el.label)}
                      </Menu.Item>
                    ))}
                  </Menu>
                }
              >
                <Card key={kpi.id} style={{ height: '100%' }}>
                  {(viewComment[kpi.id] ||
                    comments.filter(
                      (el) => el.key === `${key}-${phase}-card-${kpi.id}` && !el.resolved,
                    ).length > 0) && (
                    <Comments
                      hasComment={
                        !_.isEmpty(hasComment?.card)
                          ? !_.isEmpty(hasComment?.card[`${key}-${phase}`]) &&
                            hasComment?.card[`${key}-${phase}`][kpi.id]
                          : false
                      }
                      onClick={(visible) => {
                        setHasComment({
                          type: 'card',
                          data: visible,
                          key: `${key}-${phase}`,
                          id: kpi.id,
                        })
                        !visible && removeNewComment()
                        !visible && setViewComment({ ...viewComment, [kpi.id]: visible })
                      }}
                      comments={comments.filter(
                        (el) =>
                          (el.key === `${key}-${phase}-card-${kpi.id}` && !el.resolved) || !el.text,
                      )}
                      setData={setData}
                      loading={isSaving || loading}
                      onRefetchComments={onRefetchComments}
                      onCommentCreate={(text, usersIds, idComment, setIsNewThread) =>
                        handleCreate({ text, usersIds, id: kpi.id, idComment, setIsNewThread })
                      }
                      onCleanHasComment={() => {
                        setHasComment({
                          type: 'card',
                          data: false,
                          key: `${key}-${phase}`,
                          id: kpi.id,
                        })
                        setViewComment(false)
                      }}
                    >
                      <div
                        style={{
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'flex-end',
                          position: 'absolute',
                          zIndex: 1,
                          left: -10,
                          top: 10,
                        }}
                      >
                        <Typography.Icon
                          style={{ cursor: 'pointer' }}
                          title={t('LABEL_COMMENTS')}
                          level={2}
                          icon={CommentOutlined}
                        />
                      </div>
                    </Comments>
                  )}
                  <Space>
                    <KPIValue
                      size="medium"
                      prefix={kpi.prefix}
                      fullValue={kpi.value && numeral(kpi.value).format('0,0.00')}
                      value={kpi.value ? numeral(kpi.value).format('0[.]0a') : '-'}
                    />
                    {kpi.variation && (
                      <KPIValue
                        value={kpi.variation ? numeral(kpi.variation).format('0%') : '-'}
                        type="secondary"
                      />
                    )}
                  </Space>
                  <br />
                  <Typography.Body level={3} type="secondary">
                    {kpi.name}
                  </Typography.Body>
                </Card>
              </Dropdown>
            </Col>
          ))}
      </>
    </Row>
  )
}

const mapStateToProps = (state) => ({
  elementRefId: configuration.selectors.getElementRefId(state),
  hasComment: configuration.selectors.getHasComment(state),
})

const mapDispatchToProps = {
  setHasComment: configuration.actions.setHasComment,
  createComment: configuration.actions.createComment,
  removeNewComment: configuration.actions.removeNewComment,
}

export default connect(mapStateToProps, mapDispatchToProps)(PhaseKpis)
