import { useSortable } from '@dnd-kit/sortable'
import { Col, Row } from 'antd'
import { CloseOutlined } from '@ant-design/icons'

import './SortableTag.scss'

const SortableTag = ({ op, onClose, showCloseIcon }) => {
  const { attributes, listeners, setNodeRef, transform } = useSortable({
    id: op.id,
  })

  const draggableStyle = transform
    ? {
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
        userSelect: 'none',
        cursor: 'move',
      }
    : undefined

  const tagStyle = {
    cursor: transform ? 'move' : 'pointer',
  }

  return (
    <Row gutter={[4, 4]}>
      <Col span={showCloseIcon ? 22 : 24}>
        <div ref={setNodeRef} style={draggableStyle} {...attributes} {...listeners}>
          <div className="tag-style" style={tagStyle}>
            {op.name}
          </div>
        </div>
      </Col>
      {showCloseIcon && (
        <Col span={2} style={{ display: 'flex', alignItems: 'center', marginBottom: 5 }}>
          <CloseOutlined onClick={() => onClose(op.id)} />
        </Col>
      )}
    </Row>
  )
}

export default SortableTag
