import React from 'react'
import { Card, List } from 'antd'
import { Button, ButtonActionBar, Typography } from 'modules/core/components'
import { HOME } from 'modules/core/constants'
import { RightCircleOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'

const ListCardsNews = () => {
  const { t } = useTranslation()
  return (
    <List
      grid={{ gutter: 24, column: 4 }}
      dataSource={HOME.LIST_DATA_DASHBOARD.CARDS_NEWS}
      renderItem={(item) => (
        <List.Item>
          <Card
            key={item.id}
            className="list-card-novedades"
            data-type="novedades"
            bodyStyle={{ backgroundColor: '#e1effa', padding: 0 }}
            actions={[
              <ButtonActionBar position="space-between">
                <Button.LinkWhitRightIcon
                  onClick={() => window.open(item.link, '_blank')}
                  title={item.textoBoton}
                  icon={<RightCircleOutlined />}
                />
              </ButtonActionBar>,
            ]}
          >
            <div className="card-body">
              <img className="container-imagen" src={item.img} alt={item.name} />
              <div className="container-description">
                <Typography.Subtitle>{t(item.name)}</Typography.Subtitle>
                <Typography.Body level={3}>{t(item.description)}</Typography.Body>
              </div>
            </div>
          </Card>
        </List.Item>
      )}
    />
  )
}

export default ListCardsNews
