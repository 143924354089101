import React, { useEffect, useState } from 'react'
import { Col, Row, Steps } from 'antd'
import { DestinationDimensions, Formula, OriginDimensions } from './components'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import configuration from 'modules/configuration'
import _ from 'lodash'

const { Step } = Steps

const ContainerRule = ({
  visible,
  loading,
  dataDestinationOption,
  dataRuleSteps,
  rowsDimensions,
  fetchAllDimensionsValues,
  setDimensionsSelection,
}) => {
  const [current, setCurrent] = useState(0)
  const [areAllSelected, setAreAllSelected] = useState(false)
  const [isLoadingOptions, setIsLoadingOptions] = useState(false)
  const { t } = useTranslation()

  useEffect(() => {
    if (visible) {
      _.forEach(rowsDimensions, (dataOptions, key) => {
        if (key !== 'default') {
          _.forEach(dataOptions[0], (data, id) => {
            if (id !== 'id') {
              setIsLoadingOptions(true)
              fetchAllDimensionsValues({ id_dimension: data.id }).then((response) => {
                setIsLoadingOptions(false)
                setDimensionsSelection({
                  key,
                  index: rowsDimensions[key][0].id,
                  data: {},
                  dimensionName: data.name,
                  dataValueSelect: data.dataValueSelect,
                  dimensionValues: response.payload.data,
                })
              })
            }
          })
        }
      })
    }
    // eslint-disable-next-line
  }, [visible, fetchAllDimensionsValues])

  const components = {
    origin: (
      <OriginDimensions
        loadingTable={loading}
        loading={isLoadingOptions}
        dataKey="origin_dimensions"
        areAllSelected={areAllSelected}
        setAreAllSelected={setAreAllSelected}
      />
    ),
    formula: <Formula />,
    destination: (
      <DestinationDimensions
        loadingTable={loading}
        loading={isLoadingOptions}
        dataKey="target_dimensions"
        dataDestinationOption={dataDestinationOption}
        areAllSelected={areAllSelected}
        setAreAllSelected={setAreAllSelected}
      />
    ),
  }

  return (
    <Row gutter={[12, 12]}>
      <Col span={24}>
        <Steps
          type="navigation"
          size="small"
          current={current}
          progressDot={() => null}
          onChange={(val) => setCurrent(val)}
          className="steps"
        >
          {dataRuleSteps.map((step) => (
            <Step key={step.key} title={t(step.title)} />
          ))}
        </Steps>
      </Col>
      <Col span={24}>{components[dataRuleSteps[current].key]}</Col>
    </Row>
  )
}

const mapStateToProps = (state) => ({
  dataRuleSteps: configuration.selectors.getDataRuleSteps(state),
  rowsDimensions: configuration.selectors.getRowsDimensions(state),
})

const mapDispatchToProps = {
  setDimensionsSelection: configuration.actions.setDimensionsSelection,
  fetchAllDimensionsValues: configuration.actions.fetchAllDimensionsValues,
}

export default connect(mapStateToProps, mapDispatchToProps)(ContainerRule)
