import React from 'react'
import { Col, Row } from 'antd'
import { Typography } from 'modules/core/components'
import { useTranslation } from 'react-i18next'
import emptyImage from 'assets/images/compositions/empty-card.svg'

const EmptyKpis = () => {
  const { t } = useTranslation()
  return (
    <Row align="middle">
      <Col span={14}>
        <Typography.Body level={2}>{t('EMPTY_STATE_NO_DATA_TITLE')}</Typography.Body>
        <br />
        <Typography.Body level={3} type="secondary">
          {t('PLANNING_EMPTY_CARD_BODY')}
        </Typography.Body>
      </Col>
      <Col span={4} offset={2}>
        <img alt="img" height="100" src={emptyImage} />
      </Col>
    </Row>
  )
}

export default EmptyKpis
