import React from 'react'
import { Avatar, Col, Row, Space, Typography as TypographyAntd } from 'antd'
import { Typography } from 'modules/core/components'

import './UserAvatar.scss'

const UserAvatar = ({
  avatarLetter = '',
  fullname = '',
  size = 'small',
  isItalic = false,
  description = false,
}) => (
  <Space className="configuration-user-avatar">
    <Avatar className="user-avatar" size={size}>
      {avatarLetter.toUpperCase()}
    </Avatar>
    <Row gutter={[0, 0]}>
      <Col span={24}>
        <TypographyAntd.Text ellipsis title={fullname}>
          {!isItalic ? fullname : <i> {fullname} </i>}
        </TypographyAntd.Text>
      </Col>
      {description && (
        <Col span={24}>
          <Typography.Body level={3}>{description}</Typography.Body>
        </Col>
      )}
    </Row>
  </Space>
)

export default UserAvatar
