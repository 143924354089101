import { message, notification } from 'antd'
import { generateMessageError } from 'modules/core/utils'
import i18n from 'i18next'

export const onCreatePeriod = ({ form, createPeriod, setIsLoading, onRefetch, onClose }) => {
  form
    .validateFields()
    .then((values) => {
      setIsLoading(true)
      const data = {
        name: values.name,
        start_date: values.range_date[0].format('YYYY-MM-01'),
        end_date: values.range_date[1].format('YYYY-MM-01'),
      }
      createPeriod(data)
        .then(() => {
          message.success(i18n.t('PERIOD_CREATE_SUCCESS_FEEDBACK'), 8)
          onClose()
        })
        .catch((error) => {
          notification.error({
            message: i18n.t('FEEDBACK_DEFAULT_ERROR'),
            description: generateMessageError(error, 'FEEDBACK_CREATION_FAIL'),
            duration: 0,
          })
          onClose()
        })
    })
    .catch((e) => {})
}
