import { useFeatureIsOn } from '@growthbook/growthbook-react'
import { Col, Row, Tabs } from 'antd'
import { Navigator, WorkflowScreen } from 'modules/core/components'
import { useQuery } from 'modules/core/customHooks'
import { SiderLayout } from 'modules/core/layouts'
import { useTranslation } from 'react-i18next'
import { StatusConnection } from './components'

const IntegrationsHome = () => {
  const enabledWorkflow = useFeatureIsOn('feature-workflow')
  const { t } = useTranslation()
  let query = useQuery()

  const CREDENTIALS = 'credentials'

  const renderHeader = () => (
    <Navigator
      floated={false}
      size="big"
      items={[
        {
          name: t('LABEL_CONFIG'),
          url: `/configuracion`,
        },
        { name: t('LABEL_INTEGRATION') },
      ]}
    />
  )

  if (enabledWorkflow) {
    return <WorkflowScreen moduleType="integrations" />
  }

  return (
    <SiderLayout>
      <Row gutter={[24, 24]}>
        <Col span={24}>{renderHeader()}</Col>
        <Col span={24}>
          <Tabs
            size="small"
            defaultActiveKey={query.get('tab') !== null ? query.get('tab') : CREDENTIALS}
          >
            <Tabs.TabPane tab={t('INTEGRATIONS_CREDENTIALS_TAB_TITLE')} key={CREDENTIALS}>
              {/* <TableActions /> */}
              <StatusConnection />
            </Tabs.TabPane>
          </Tabs>
        </Col>
      </Row>
    </SiderLayout>
  )
}

export default IntegrationsHome
