import { message, notification } from 'antd'
import { generateMessageError } from 'modules/core/utils'
import i18n from 'i18next'

export const handleDelete = ({ setIsLoading, deleteDimensionsSelections, onRefetch, onClose }) => {
  setIsLoading(true)
  deleteDimensionsSelections()
    .then(() =>
      onRefetch().then(() => {
        message.success(i18n.t('FEEDBACK_DELETE_SELECTION_SUCCESS'), 8)
        onClose()
      }),
    )
    .catch(() => {
      message.error(i18n.t('FEEDBACK_DELETE_SELECTION_ERROR'), 8)
      onClose()
    })
}

export const handleDeleteAll = ({ setIsLoading, deleteAllDimensions, onRefetch, onClose }) => {
  setIsLoading(true)
  deleteAllDimensions()
    .then(() =>
      onRefetch().then(() => {
        message.success(i18n.t('FEEDBACK_DELETE_DATA_SUCCESS'), 8)
        onClose()
      }),
    )
    .catch((error) => {
      notification.error({
        message: i18n.t('FEEDBACK_DEFAULT_ERROR'),
        description: generateMessageError(error, 'FEEDBACK_DELETE_DATA_ERROR'),
        duration: 0,
      })
      onClose()
    })
}
