import React, { useEffect, useState } from 'react'
import { Collapse, Empty, Popconfirm, Space, Spin, Tag, Tooltip } from 'antd'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { onSave } from '../../utils'
import { useHistory } from 'react-router-dom'
import { InfoCircleOutlined } from '@ant-design/icons'
import Button from 'modules/core/components/Button/Button'
import ButtonActionBar from 'modules/core/components/ButtonActionBar/ButtonActionBar'
import InputMonth from 'modules/core/components/InputMonth/InputMonth'
import Typography from 'modules/core/components/Typography/Typography'
import configuration from 'modules/configuration'
import _ from 'lodash'
import emptyStateImage from 'assets/images/compositions/empty-big.svg'
import EmptyScreen from 'modules/core/components/EmptyScreen/EmptyScreen'

const { Panel } = Collapse

const ExchangeRateComponent = ({
  visible,
  periodId,
  isCurrencyLoading,
  currencyList,
  onRefetchDataTable,
  type,
  params,
  disabledDate,
  fetchExchangeRateList,
  exchangeRateList,
  setExchangeRateValues,
  editExchangeRate,
}) => {
  const [showPopconfirm, setShowPopconfirm] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  let history = useHistory()
  const { t } = useTranslation()

  useEffect(() => {
    if (visible) {
      fetchExchangeRateList({ ...params, period_id: periodId })
    }
    // eslint-disable-next-line
  }, [fetchExchangeRateList, periodId, visible])

  const dataSouce = currencyList.data.filter((el) => !el.is_default)

  const renderNoDataScreen = () => (
    <EmptyScreen
      image={emptyStateImage}
      title={t('CONFIG_CURRENCY_EMPTY_SCREEN_TITLE')}
      footer={
        <Button.Primary
          onClick={() => history.push('/configuracion/herramientas?tab=currency')}
          title="ACTION_GO_TO_CONFIGURE"
        />
      }
    />
  )

  return (
    <Spin spinning={isCurrencyLoading}>
      {currencyList.data.length === 0 && renderNoDataScreen()}
      {currencyList.data.length > 0 && (
        <Space direction="vertical" style={{ width: '100%' }}>
          <Space direction="horizontal">
            <Typography.Body>{t('CONFIG_CURRENCY_EXCHANGE_RATE_DRAWER_SUBTITLE')}</Typography.Body>
            <Tooltip
              placement="leftTop"
              title={
                <div>
                  <Space direction="vertical">
                    <span>{t('LABEL_EXAMPLE')}</span>
                    <span>{t('CONFIG_CURRENCY_EXAMPLE_TEXT_DRAWER_1')}</span>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <span>{t('CONFIG_CURRENCY_EXAMPLE_TEXT_DRAWER_2')}</span>
                      <span>{t('CONFIG_CURRENCY_EXAMPLE_TEXT_DRAWER_3')}</span>
                      <span>{t('CONFIG_CURRENCY_EXAMPLE_TEXT_DRAWER_4')}</span>
                    </div>
                  </Space>
                </div>
              }
            >
              <Typography.Icon icon={InfoCircleOutlined} />
            </Tooltip>
          </Space>
          <ButtonActionBar>
            <Typography.Body>
              {t('CONFIG_CURRENCY_EXCHANGE_RATE_DEFAULT_CURRENCY_DRAWER')}
            </Typography.Body>
            {currencyList.data.find((el) => el.is_default) ? (
              <Tag color="blue">{currencyList.data.find((el) => el.is_default)?.code}</Tag>
            ) : (
              <Tag>{t('CONFIG_CURRENCY_EXCHANGE_RATE_NOT_PREDEFINED_CURRENCY_DRAWER')}</Tag>
            )}
          </ButtonActionBar>
          {dataSouce.length === 0 && (
            <Empty description={t('CONFIG_CURRENCY_EXCHANGE_RATE_NOT_CURRENCIES')} />
          )}
          {dataSouce.length > 0 && (
            <Collapse accordion={true}>
              {dataSouce.map((collapse) => {
                const exchangeRateAux = exchangeRateList.find(
                  (el) => el.destination_currency.id === collapse.id,
                )
                return (
                  <Panel header={collapse.code} key={collapse.id}>
                    <Spin spinning={isSaving}>
                      {_.map(exchangeRateAux?.date_rate_period, (value, date) => {
                        return (
                          <InputMonth
                            index={collapse.id}
                            month={date}
                            value={value}
                            style={{ paddingRight: 5 }}
                            disabled={
                              type === 'forecast_overall_budget' &&
                              disabledDate &&
                              `${date}-01` >= disabledDate.startDatePeriod &&
                              `${date}-01` <= disabledDate.cutDate
                            }
                            className="value-item"
                            onChange={(value) =>
                              setExchangeRateValues({
                                id: collapse.id,
                                data: { ...exchangeRateAux?.date_rate_period, [date]: value },
                              })
                            }
                          />
                        )
                      })}
                      {JSON.stringify(exchangeRateAux?.date_rate_period) !==
                        JSON.stringify(exchangeRateAux?.originValues) && (
                        <ButtonActionBar>
                          <Popconfirm
                            placement="bottomRight"
                            title={t('PLANNING_CURRENCY_CANCEL_POPCONFIRM_TITLE')}
                            okText={t('YES')}
                            onConfirm={() => {
                              setShowPopconfirm(false)
                              setExchangeRateValues({
                                id: collapse.id,
                                data: exchangeRateAux?.originValues,
                              })
                            }}
                            cancelText={t('NO')}
                            onCancel={() => setShowPopconfirm(false)}
                            visible={showPopconfirm}
                          >
                            <Button.Default
                              title="ACTION_CANCEL"
                              style={{ marginTop: 10 }}
                              onClick={() => setShowPopconfirm(true)}
                            />
                          </Popconfirm>
                          <Button.Primary
                            title="ACTION_SAVE"
                            style={{ marginTop: 10 }}
                            onClick={() =>
                              onSave({
                                setIsLoading: setIsSaving,
                                editExchangeRate: () =>
                                  editExchangeRate(
                                    collapse.id,
                                    { ...params },
                                    {
                                      date_rate_period: exchangeRateAux?.date_rate_period,
                                    },
                                  ),
                                onRefetch: () =>
                                  fetchExchangeRateList({
                                    ...params,
                                    period_id: periodId,
                                  }),
                                onRefetchDataTable,
                                onClose: () => setIsSaving(false),
                              })
                            }
                            disabled={showPopconfirm}
                          />
                        </ButtonActionBar>
                      )}
                    </Spin>
                  </Panel>
                )
              })}
            </Collapse>
          )}
        </Space>
      )}
    </Spin>
  )
}

const mapStateToProps = (state) => ({
  exchangeRateList: configuration.selectors.getExchangeRateList(state),
})

const mapDispatchToProps = {
  fetchExchangeRateList: configuration.actions.fetchExchangeRateList,
  setExchangeRateValues: configuration.actions.setExchangeRateValues,
  editExchangeRate: configuration.actions.editExchangeRate,
}

export default connect(mapStateToProps, mapDispatchToProps)(ExchangeRateComponent)
