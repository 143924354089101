import { notification } from 'antd'

export const onConfirmUpload = ({
  setIsLoading,
  uploadFile,
  onSuccessUpload,
  onFailUpload,
  onClose,
}) => {
  setIsLoading(true)
  notification.close('error_file')
  notification.close('warning_file')
  uploadFile()
    .then((response) => {
      onSuccessUpload && onSuccessUpload(response)
      onClose()
    })
    .catch((error) => {
      onFailUpload && onFailUpload(error)
      onClose()
    })
}
