import { Space, Table, Tag } from 'antd'
import { useTranslation } from 'react-i18next'
import { Button, /*DropdownMenu,**/ Typography } from 'modules/core/components'
import { useHistory, useParams } from 'react-router-dom'
import { EyeOutlined /* CopyOutlined, CloseCircleOutlined */ } from '@ant-design/icons'
import initialColumns from '../../columns'
// import _ from "lodash";

const AdjustmentTable = ({
  tableLoading,
  onChange,
  dataSource,
  pagination,
  count,
  // disabled,
}) => {
  let history = useHistory()
  let { periodId } = useParams()
  const { t } = useTranslation()

  const columns = [
    ...initialColumns(),
    {
      title: t('FIELD_ACTIONS'),
      dataIndex: 'id',
      align: 'right',
      width: 200,
      render: (id, row) => (
        <Space size="middle">
          {row.canceled ? <Tag color="red">{t('STATE_TAG_CANCELED')}</Tag> : ''}
          <Button.Icon
            onClick={() =>
              history.push(
                `/seguimiento/${periodId}/ajustes/${id}?type=${row.type_ajustment_distribution}`,
              )
            }
            icon={<Typography.Icon icon={EyeOutlined} />}
            title="ADJUSTMENT_VIEW_ACTION_TITLE"
          />
          {/* <DropdownMenu
              title={t('ACTION_MORE')}
              menu={_.compact([
                {
                  title: t('ACTION_DUPLICATE'),
                  icon: <CopyOutlined />,
                  onClick: () => console.log('Duplicar'),
                  disabled,
                },
                ...[
                  !row.canceled && {
                    title: t('ACTION_ANULATE'),
                    icon: <CloseCircleOutlined />,
                    onClick: () => console.log('Anular'),
                    disabled,
                  },
                ],
              ])}
            /> */}
        </Space>
      ),
    },
  ]

  return (
    <Table
      rowKey="id"
      size="small"
      bordered
      loading={tableLoading}
      onChange={onChange}
      columns={columns}
      dataSource={dataSource}
      pagination={{
        pageSize: pagination.page_size,
        current: pagination.page,
        total: count,
        size: 'small',
        showSizeChanger: false,
      }}
    />
  )
}

export default AdjustmentTable
