import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Spin, Row, Col } from 'antd'
import { SiderLayout } from 'modules/core/layouts'
import { PeriodNavigator } from 'modules/core/components'
import { useTranslation } from 'react-i18next'
import {
  AdjustmentDetailInfo,
  AdjustmentDetailTable,
  /* TableActions, AdjustmentInfoAccount,*/ UserActions,
} from './components'
import { ADJUSTMENTS } from 'modules/core/constants'
import adjustments from 'modules/adjustments'

import './AdjustmentDetail.scss'

const AdjustmentDetail = ({
  adjustmentDetail: { columns, original },
  fetchAdjustmentDetailTable,
}) => {
  const [isTableLoading, setIsTableLoading] = useState(false)
  const [isScreenLoading, setIsScreenLoading] = useState(true)
  let { periodId, adjustmentId } = useParams()
  const { t } = useTranslation()

  var queryString = window.location.search
  var urlParams = new URLSearchParams(queryString)
  var type = urlParams.get('type')

  useEffect(() => {
    setIsTableLoading(true)
    const endpointParams = {
      id_ajustment: adjustmentId,
      ...(type === 'distribution' && {
        type_ajustment: 'DISTRIBUTION',
      }),
    }
    fetchAdjustmentDetailTable(endpointParams).then(() => {
      setIsScreenLoading(false)
      setIsTableLoading(false)
    })
  }, [fetchAdjustmentDetailTable, adjustmentId, type])

  const renderHeader = () => (
    <PeriodNavigator
      items={[
        {
          name: t('LABEL_CONTROL'),
          url: `/seguimiento/${periodId}`,
        },
        {
          name: t('LABEL_ADJUSTMENTS'),
          url: `/seguimiento/${periodId}?tab=ajustes`,
        },
        {
          name: t('ADJUSTMENT_DETAIL_TITLE'),
        },
      ]}
    />
  )

  const renderType = () => {
    if (type === 'distribution') {
      return t(
        ADJUSTMENTS.OPTIONS_DISTRIBUTION_TYPE.find((el) => el.id === original.type.toUpperCase())
          .name,
      )
    } else {
      return t(
        ADJUSTMENTS.OPTIONS_ADJUSTMENT_TYPE.find((el) => el.id === original.type.toUpperCase())
          .name,
      )
    }
  }

  const renderInputs = () => (
    <AdjustmentDetailInfo date={original.date} type={renderType()} comment={original.comment} />
  )

  const renderTables = () => (
    <>
      <Col span={24}>
        <AdjustmentDetailTable
          // title={distribucion.length === 0 ? t('FIELD_MOVEMENTS') : t('ADJUSTEMNT_TYPE_DISTRIBUTION_LABEL_DESTINATION')}
          title={t('FIELD_MOVEMENTS')}
          isLoading={isTableLoading}
          dataSource={original.rows}
          columns={columns}
        />
      </Col>
      {/* {distribucion.length > 0 && (
        <Col span={24}>
          <AdjustmentDetailTable
            title={t('ADJUSTEMNT_TYPE_DISTRIBUTION_LABEL_ORIGIN')}
            isLoading={isTableLoading}
            dataSource={distribucion}
            columns={adjustmentColumns}
          />
        </Col>
      )} */}
    </>
  )

  const renderUserActions = () => <UserActions reversion={original.reversion} />

  return (
    <SiderLayout>
      <Spin size="large" spinning={isScreenLoading} />
      {!isScreenLoading && (
        <>
          {renderHeader()}
          <div className="adjustment-detail-container">
            <Row gutter={[8, 16]}>
              <>
                <Col span={24}>{renderUserActions()}</Col>
                <Col span={24}>{renderInputs()}</Col>
                {/* {distribucion.length > 0 && (
                    <Col span={24}><AdjustmentInfoAccount /></Col>
                  )} */}
                {/* <Col span={24}><TableActions /></Col> */}
                <Col span={24}>{renderTables()}</Col>
              </>
            </Row>
          </div>
        </>
      )}
    </SiderLayout>
  )
}

const mapStateToProps = (state) => ({
  adjustmentDetail: adjustments.selectors.getAdjustmentDetail(state),
})

const mapDispatchToProps = {
  fetchAdjustmentDetailTable: adjustments.actions.fetchAdjustmentDetailTable,
}

export default connect(mapStateToProps, mapDispatchToProps)(AdjustmentDetail)
