import React from 'react'
import { ChatbotList } from 'modules/chatbot/components'
import ReceiverMessageItem from './ReceiverMessageItem'
import SenderMessageItem from './SenderMessageItem'

const ListMessage = ({ userMessages, authUser, selectedUser, loading }) => {
  return (
    <div className="chat-message-list">
      <ChatbotList
        data={userMessages.messageData}
        footerProps={{ loading: loading }}
        renderItem={(item, index) => {
          if (item.sender === authUser.userId) {
            return (
              <SenderMessageItem
                authUser={authUser}
                item={item}
                isPreviousSender={
                  index > 0 && item.sender === userMessages.messageData[index - 1].sender
                }
                isLast={
                  (index + 1 < userMessages.messageData.length &&
                    item.sender !== userMessages.messageData[index + 1].sender) ||
                  index + 1 === userMessages.messageData.length
                }
                key={`message-${index}-Sender`}
              />
            )
          } else {
            return (
              <ReceiverMessageItem
                isPreviousSender={
                  index > 0 && item.sender === userMessages.messageData[index - 1].sender
                }
                isLast={
                  (index + 1 < userMessages.messageData.length &&
                    item.sender !== userMessages.messageData[index + 1].sender) ||
                  index + 1 === userMessages.messageData.length
                }
                selectedUser={selectedUser}
                item={item}
                key={`message-${index}-Receiver`}
              />
            )
          }
        }}
      />
    </div>
  )
}

export default ListMessage
