import React, { useState } from 'react'
import { Modal, Form, Row, Col, Input } from 'antd'
import { useTranslation } from 'react-i18next'
import configuration from 'modules/configuration'
import { connect } from 'react-redux'
import { onCreateDimensionValue } from './utils'

const ConfigurationCreateModal = ({
  visible,
  onCancel,
  createDimensionValue,
  onFetchDimensionValues,
  dimensionId,
}) => {
  const [isLoadingModal, setIsLoadingModal] = useState(false)
  const [form] = Form.useForm()
  const { t } = useTranslation()

  const onClose = () => {
    form.resetFields()
    onCancel()
    setIsLoadingModal(false)
  }

  return (
    <Modal
      title={t('CONFIG_DIMENSIONS_VALUES_MODAL_CREATE_NEW_VALUE_TITLE')}
      visible={visible}
      width={560}
      bodyStyle={{ overflowY: 'auto', maxHeight: 400 }}
      centered
      okText={t('ACTION_CREATE')}
      cancelText={t('ACTION_CANCEL')}
      onOk={() =>
        onCreateDimensionValue({
          form,
          setIsLoading: setIsLoadingModal,
          createDimensionValue: (values) =>
            createDimensionValue({ ...values, dimension_id: parseInt(dimensionId) }),
          onRefetch: onFetchDimensionValues,
          onClose,
        })
      }
      onCancel={onClose}
      okButtonProps={{ loading: isLoadingModal }}
      cancelButtonProps={{ disabled: isLoadingModal }}
    >
      <Form form={form} layout="vertical">
        <Row gutter={24} type="flex" justify="end">
          <Col span={12}>
            <Form.Item
              name="code"
              label={t('FIELD_CODE')}
              rules={[
                {
                  required: true,
                  message: t('REQUIRED_FIELD'),
                },
              ]}
            >
              <Input placeholder={t('CONFIG_DIMENSIONS_VALUES_INPUT_CODE_PLACEHOLDER')} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="name"
              label={t('FIELD_NAME')}
              rules={[
                {
                  required: true,
                  message: t('REQUIRED_FIELD'),
                },
              ]}
            >
              <Input placeholder={t('CONFIG_DIMENSIONS_VALUES_INPUT_NAME_PLACEHOLDER')}></Input>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

const mapDispatchToProps = {
  createDimensionValue: configuration.actions.createDimensionValue,
}

export default connect(null, mapDispatchToProps)(ConfigurationCreateModal)
