import React, { useEffect, useState } from 'react'
import { Col, Row, Spin } from 'antd'
import { SiderLayout } from 'modules/core/layouts'
import { connect } from 'react-redux'
import { PROJECTION } from 'modules/core/constants'
import {
  BudgetTypesCarrousel,
  ChartsDashboard,
  PeriodProjectionHeader,
  ReportListChart,
} from './components'
import planning from 'modules/planning'

const DashboardHome = ({ periodList, fetchPeriodList }) => {
  const [currentPeriod, setCurrentPeriod] = useState(undefined)
  const [isScreenLoading, setIsScreenLoading] = useState(false)
  const [projectionId, setProjectionId] = useState({
    base: PROJECTION.REAL,
    pieChart: PROJECTION.REAL,
  })

  const dataPeriod = currentPeriod ? currentPeriod : periodList[0]

  useEffect(() => {
    setIsScreenLoading(true)
    fetchPeriodList().then(() => setIsScreenLoading(false))
    return () => setIsScreenLoading(false)
  }, [fetchPeriodList])

  return (
    <SiderLayout>
      <Spin spinning={isScreenLoading}>
        <Row gutter={[8, 8]}>
          <Col span={24}>
            <PeriodProjectionHeader
              periodList={periodList}
              currentPeriod={currentPeriod}
              setCurrentPeriod={setCurrentPeriod}
              projectionId={projectionId}
              setProjectionId={setProjectionId}
            />
          </Col>
          <Col span={24}>
            <BudgetTypesCarrousel period_id={dataPeriod?.id} projectionId={projectionId.base} />
          </Col>
          <Col span={24}>
            <ReportListChart period_id={dataPeriod?.id} projectionId={projectionId.base} />
          </Col>
          <Col span={24}>
            <ChartsDashboard
              projectionId={projectionId}
              setProjectionId={setProjectionId}
              dataPeriod={dataPeriod}
            />
          </Col>
        </Row>
      </Spin>
    </SiderLayout>
  )
}

const mapStateToProps = (state) => ({
  periodList: planning.selectors.getPeriodList(state),
})

const mapDispatchToProps = {
  fetchPeriodList: planning.actions.fetchPeriodList,
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardHome)
