import React from 'react'
import { ChatbotList } from 'modules/chatbot/components'
import QuestionItem from '../QuestionItem/QuestionItem'

const QuestionList = ({ chatListData, onSetMessage }) => {
  return (
    <div className="chat-sidebar-scrollbar">
      <h3 className="chat-sidebar-title">Preguntas sugeridas</h3>
      <ChatbotList
        data={chatListData}
        renderItem={(item) => (
          <QuestionItem key={item.id} item={item} onSetMessage={onSetMessage} />
        )}
      />
    </div>
  )
}

export default QuestionList
