import { message } from 'antd'
import { generateMessageError } from 'modules/core/utils'
import i18n from 'i18next'

export const handleCellEdit = ({ updateTransaction, onRefetch }) => {
  message.loading({
    key: 'loading_edit',
    content: i18n.t('FEEDBACK_LOADING_CHANGES'),
  })
  updateTransaction()
    .then(() => {
      onRefetch()
      // .finally(() => {
      message.destroy('loading_edit')
      message.success(i18n.t('FEEDBACK_SAVE_CHANGES_SUCCES', 8))
      // });
    })
    .catch((error) => message.error(generateMessageError(error, 'FEEDBACK_DEFAULT_ERROR'), 8))
}

export const onCommentCell = ({ create, syncProjectionComment }) => {
  create()
    .then((response) => syncProjectionComment(response))
    .catch((error) => message.error(generateMessageError(error, 'FEEDBACK_DEFAULT_ERROR'), 8))
}

export const onEditCell = ({ edit, syncProjectionComment }) => {
  edit()
    .then((response) => syncProjectionComment(response))
    .catch((error) => message.error(generateMessageError(error, 'FEEDBACK_DEFAULT_ERROR'), 8))
}

export const onDeleteCommentCell = ({ deleted, syncProjectionComment }) => {
  deleted()
    .then(() => syncProjectionComment())
    .catch((error) => message.error(generateMessageError(error, 'FEEDBACK_DEFAULT_ERROR'), 8))
}

export const handleDeleteSelection = () => console.log('delete selection')

export const isStepClosing = (step) => step === 'closing'

export const renderButtonSendName = ({ step, dataPhasekey, is_closing, buttonName }) => {
  if (isStepClosing(step) && isStepClosing(dataPhasekey) && is_closing) {
    return 'STATUS_TAG_CLOSED'
  }

  return buttonName
}

const functionSendPashe = ({ setILoading, setNextPhase, onRefetch, onClose }) => {
  setILoading(true)
  setNextPhase()
    .then((response) =>
      onRefetch().then(() => {
        message.success(response.payload.data.message, 8)
        onClose()
      }),
    )
    .catch((error) => {
      message.error(generateMessageError(error, 'PLANNING_NEXT_STEP_ERROR_FEEDBACK'), 8)
      onClose()
    })
}

const functionClosingPhase = ({ setILoading, closing, onRefetch, onClose }) => {
  setILoading(true)
  closing()
    .then((response) =>
      onRefetch().then(() => {
        message.success(response.payload.data.message, 8)
        onClose()
      }),
    )
    .catch((error) => {
      message.error(generateMessageError(error, 'PLANNING_CLOSING_STEP_ERROR_FEEDBACK'), 8)
      onClose()
    })
}

export const renderFunctionSend = ({
  step,
  setILoading,
  setNextPhase,
  closing,
  onRefetch,
  onClose,
}) => {
  if (isStepClosing(step)) {
    return functionClosingPhase({
      setILoading,
      closing,
      onRefetch,
      onClose,
    })
  }

  return functionSendPashe({
    setILoading,
    setNextPhase,
    onRefetch,
    onClose,
  })
}
