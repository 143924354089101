import React, { useEffect, useState } from 'react'
import { List } from 'antd'
import { ContainerList } from './components'
import {
  DndContext,
  useSensor,
  useSensors,
  closestCenter,
  MouseSensor,
  TouchSensor,
} from '@dnd-kit/core'
import { SortableContext, rectSortingStrategy } from '@dnd-kit/sortable'
import { handleDragEnd, handleDragStart } from 'modules/core/utils'
import { DragOverlay } from '@dnd-kit/core'
import { connect } from 'react-redux'
import planning from 'modules/planning'
import configuration from 'modules/configuration'

const PeriodList = ({
  dataSource,
  disabled = false,
  onRefetch,
  orderPeriodCard,
  setPeriodOrder,
  fetchCommentsList,
  commentsList,
}) => {
  const [activeId, setActiveId] = useState(null)
  const [dataComments, setDataComments] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor))

  const key = window.location.pathname.replaceAll('/', '-')

  useEffect(() => {
    setIsLoading(true)
    fetchCommentsList({ module: key }).then(() => setIsLoading(false))
  }, [fetchCommentsList, key])

  useEffect(() => {
    setDataComments(commentsList)
  }, [commentsList])

  const findCard = activeId && dataSource.find((el) => el.id === activeId)

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragStart={(event) => handleDragStart(event, setActiveId)}
      onDragEnd={(event) =>
        handleDragEnd({
          event,
          values: dataSource,
          order: orderPeriodCard,
          setOrder: setPeriodOrder,
          type: 'periods_ids',
        })
      }
    >
      <SortableContext items={dataSource.asMutable()} strategy={rectSortingStrategy}>
        <List
          className="period-list"
          grid={{ gutter: 24, xs: 1, sm: 1, md: 2, lg: 3, xl: 3, xxl: 4 }}
          dataSource={dataSource}
          renderItem={(period) => {
            const { id, name, start_date, end_date } = period
            return (
              <List.Item>
                <ContainerList
                  name={name}
                  start_date={start_date}
                  end_date={end_date}
                  id={id}
                  disabled={disabled}
                  onRefetch={onRefetch}
                  activeId={activeId}
                  setDataComments={setDataComments}
                  dataComments={dataComments}
                  onRefetchComments={() => fetchCommentsList({ module: key })}
                  loadingComments={isLoading}
                />
              </List.Item>
            )
          }}
        />
      </SortableContext>
      <DragOverlay adjustScale>
        {activeId ? (
          <ContainerList
            name={findCard.name}
            start_date={findCard.start_date}
            end_date={findCard.end_date}
            id={findCard.id}
            disabled={disabled}
            onRefetch={onRefetch}
            activeId={activeId}
            setDataComments={setDataComments}
            dataComments={dataComments}
            onRefetchComments={() => fetchCommentsList({ module: key })}
            loadingComments={isLoading}
          />
        ) : null}
      </DragOverlay>
    </DndContext>
  )
}

const mapStateToProps = (state) => ({
  commentsList: configuration.selectors.getCommentsList(state),
})

const mapDispatchToProps = {
  orderPeriodCard: planning.actions.orderPeriodCard,
  setPeriodOrder: planning.actions.setPeriodOrder,
  fetchCommentsList: configuration.actions.fetchCommentsList,
}

export default connect(mapStateToProps, mapDispatchToProps)(PeriodList)
