import React from 'react'
import { Col, Form, /*InputNumber,*/ Radio, Row, Slider, Space, Switch } from 'antd'
import { CHARTS } from 'modules/core/constants'
import { useTranslation } from 'react-i18next'

const GeneralFormItems = ({ chart_type }) => {
  const { t } = useTranslation()
  return (
    <>
      <Col span={24}>
        <Form.Item label={t('FIELD_LEGENDS')} name="showLegends" valuePropName="checked">
          <Switch />
        </Form.Item>
      </Col>
      {/* <Col span={24}>
        <Form.Item label={t('CHARTS_VALUES_QUANTITY')} name="dataSize">
          <InputNumber min={2} />
        </Form.Item>
      </Col> */}
      <Col span={24}>
        <Form.Item label={t('FIELD_LABEL')} name="showLabel" valuePropName="checked">
          <Switch />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) =>
            prevValues.showLabel !== currentValues.showLabel
          }
        >
          {({ getFieldValue }) =>
            getFieldValue('showLabel') && (
              <>
                <Col span={12}>
                  <Form.Item label={t('LABEL_FONT_SIZE')} name="labelFontSize">
                    <Slider min={12} max={18} />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Row>
                    <Col span={12}>
                      <Form.Item label={t('LABEL_FORMAT')} name="labelFormat">
                        <Radio.Group>
                          <Space direction="vertical">
                            {CHARTS.SETTINGS.LABEL_FORMAT_LIST.map((el) => (
                              <Radio value={el} key={el}>
                                {t(CHARTS.I18N[el])}
                              </Radio>
                            ))}
                          </Space>
                        </Radio.Group>
                      </Form.Item>
                    </Col>
                    {chart_type === 'pie' && (
                      <Col span={12}>
                        <Form.Item label={t('LABEL_VALUE_POSITION')} name="labelPosition">
                          <Radio.Group>
                            <Space direction="vertical">
                              {CHARTS.SETTINGS.LABEL_POSITION.map((el) => (
                                <Radio value={el} key={el}>
                                  {t(CHARTS.I18N[el])}
                                </Radio>
                              ))}
                            </Space>
                          </Radio.Group>
                        </Form.Item>
                      </Col>
                    )}
                  </Row>
                </Col>
              </>
            )
          }
        </Form.Item>
      </Col>
    </>
  )
}

export default GeneralFormItems
