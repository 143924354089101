import React from 'react'
import { Col, DatePicker, Form, Select } from 'antd'
import { useTranslation } from 'react-i18next'
import { generateSelectOptions } from 'modules/core/utils'
import { connect } from 'react-redux'
import { PROJECTION } from 'modules/core/constants'
import { disabledDate } from '../../utils'
import planning from 'modules/planning'
import moment from 'moment'
import _ from 'lodash'

const HistoricalBase = ({
  form,
  projectionInstanceList,
  dataPeriod,
  periodList,
  type_base,
  filterDates,
  setFilterDates,
}) => {
  const { t } = useTranslation()

  return (
    <>
      <Col span={12}>
        <Form.Item
          name="type_base"
          label={t('PLANNING_CREATE_NEW_BUSGET_BASE_CHOOSE_TYPE_BASE_FORM_LABEL')}
          rules={[{ required: true, message: t('REQUIRED_FIELD') }]}
        >
          <Select
            placeholder={t('ACTION_SELECT')}
            options={generateSelectOptions({
              options: PROJECTION.PROJECTIONS_OPTIONS.map((op) => {
                return { ...op, name: t(op.name) }
              }),
            })}
          />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          name="dates"
          label={t('PLANNING_CREATE_NEW_BUDGET_BASE_DATES_FORM_LABEL')}
          rules={[{ required: true, message: t('REQUIRED_FIELD') }]}
        >
          <DatePicker.RangePicker
            format="MMM YYYY"
            picker="month"
            style={{ width: '100%' }}
            onChange={(val) =>
              setFilterDates({
                date_start: moment(val[0]).startOf('month').format('YYYY-MM-DD'),
                date_end: moment(val[1]).startOf('month').format('YYYY-MM-DD'),
              })
            }
            disabledDate={(date) => disabledDate(date, periodList, dataPeriod)}
            defaultPickerValue={
              !_.isEmpty(dataPeriod)
                ? [moment(dataPeriod?.start_date), moment(dataPeriod?.end_date)]
                : null
            }
          />
        </Form.Item>
      </Col>
      {type_base &&
        type_base === PROJECTION.BUDGET_PERSONALIZED_REPORT.toUpperCase() &&
        !_.isEmpty(filterDates) && (
          <Col span={24}>
            <Form.Item
              name="plannings_ids"
              label={t('LABEL_BUDGETS')}
              rules={[{ required: true, message: t('REQUIRED_FIELD') }]}
            >
              <Select
                placeholder={t('ACTION_SELECT')}
                mode="multiple"
                maxTagCount={1}
                showSearch
                maxTagTextLength={8}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                options={generateSelectOptions({ options: projectionInstanceList })}
              />
            </Form.Item>
          </Col>
        )}
    </>
  )
}

const mapStateToProps = (state) => ({
  periodList: planning.selectors.getPeriodList(state),
})

export default connect(mapStateToProps, null)(HistoricalBase)
