export const NAME = 'forecast'

export const FETCH_FORECASTS = `${NAME}/FETCH_FORECASTS`
export const FETCH_FORECASTS_SUCCESS = `${NAME}/FETCH_FORECASTS_SUCCESS`
export const FETCH_FORECASTS_FAIL = `${NAME}/FETCH_FORECASTS_FAIL`

export const FETCH_CUT_DATES = `${NAME}/FETCH_CUT_DATES`
export const FETCH_CUT_DATES_SUCCESS = `${NAME}/FETCH_CUT_DATES_SUCCESS`
export const FETCH_CUT_DATES_FAIL = `${NAME}/FETCH_CUT_DATES_FAIL`

export const SET_NEXT_PHASE = `${NAME}/SET_NEXT_PHASE`

export const FETCH_FORECAST_INSTANCE_LIST = `${NAME}/FETCH_FORECAST_INSTANCE_LIST`
export const FETCH_FORECAST_INSTANCE_LIST_SUCCESS = `${NAME}/FETCH_FORECAST_INSTANCE_LIST_SUCCESS`
export const FETCH_FORECAST_INSTANCE_LIST_FAIL = `${NAME}/FETCH_FORECAST_INSTANCE_LIST_FAIL`

export const FETCH_FORECAST_INSTANCE_DETAIL = `${NAME}/FETCH_FORECAST_INSTANCE_DETAIL`
export const FETCH_FORECAST_INSTANCE_DETAIL_SUCCESS = `${NAME}/FETCH_FORECAST_INSTANCE_DETAIL_SUCCESS`
export const FETCH_FORECAST_INSTANCE_DETAIL_FAIL = `${NAME}/FETCH_FORECAST_INSTANCE_DETAIL_FAIL`

export const CREATE_FORECAST = `${NAME}/CREATE_FORECAST`

export const CREATE_FORECAST_INSTANCE = `${NAME}/CREATE_FORECAST_INSTANCE`

export const DELETE_FORECAST_INSTANCE = `${NAME}/DELETE_FORECAST_INSTANCE`

export const EDIT_FORECAST_INSTANCE = `${NAME}/EDIT_FORECAST_INSTANCE`

export const DELETE_FORECAST = `${NAME}/DELETE_FORECAST`

export const EDIT_FORECAST_NAME = `${NAME}/EDIT_FORECAST_NAME`

export const FETCH_FORECAST_PROJECTIONS = `${NAME}/FETCH_FORECAST_PROJECTIONS`
export const FETCH_FORECAST_PROJECTIONS_SUCCESS = `${NAME}/FETCH_FORECAST_PROJECTIONS_SUCCESS`
export const FETCH_FORECAST_PROJECTIONS_FAIL = `${NAME}/FETCH_FORECAST_PROJECTIONS_FAIL`

export const FETCH_FORECAST_KPIS = `${NAME}/FETCH_FORECAST_KPIS`
export const FETCH_FORECAST_KPIS_SUCCESS = `${NAME}/FETCH_FORECAST_KPIS_SUCCESS`
export const FETCH_FORECAST_KPIS_FAIL = `${NAME}/FETCH_FORECAST_KPIS_FAIL`

export const FETCH_DATA_NESTED_TABLE_FORECAST_BY_PHASE = `${NAME}/FETCH_DATA_NESTED_TABLE_FORECAST_BY_PHASE`
export const FETCH_DATA_NESTED_TABLE_FORECAST_BY_PHASE_SUCCESS = `${NAME}/FETCH_DATA_NESTED_TABLE_FORECAST_BY_PHASE_SUCCESS`
export const FETCH_DATA_NESTED_TABLE_FORECAST_BY_PHASE_FAIL = `${NAME}/FETCH_DATA_NESTED_TABLE_FORECAST_BY_PHASE_FAIL`

export const FETCH_EXPAND_NESTED_TABLE_ROW = `${NAME}/FETCH_EXPAND_NESTED_TABLE_ROW`
export const FETCH_EXPAND_NESTED_TABLE_ROW_SUCCESS = `${NAME}/FETCH_EXPAND_NESTED_TABLE_ROW_SUCCESS`
export const FETCH_EXPAND_NESTED_TABLE_ROW_FAIL = `${NAME}/FETCH_EXPAND_NESTED_TABLE_ROW_FAIL`

export const DOWNLOAD_FORECAST_TABLE_NESTED_XLS = `${NAME}/DOWNLOAD_FORECAST_TABLE_NESTED_XLS`

export const FETCH_GLOBAL_FORECAST_PUBLISH_APPROVE = `${NAME}/FETCH_GLOBAL_FORECAST_PUBLISH_APPROVE`
export const FETCH_GLOBAL_FORECAST_PUBLISH_APPROVE_SUCCESS = `${NAME}/FETCH_GLOBAL_FORECAST_PUBLISH_APPROVE_SUCCESS`
export const FETCH_GLOBAL_FORECAST_PUBLISH_APPROVE_FAIL = `${NAME}/FETCH_GLOBAL_FORECAST_PUBLISH_APPROVE_FAIL`

export const CREATE_GLOBAL_FORECAST = `${NAME}/CREATE_GLOBAL_FORECAST`

export const EDIT_GLOBAL_FORECAST_NAME = `${NAME}/EDIT_GLOBAL_FORECAST_NAME`

export const DELETE_GLOBAL_FORECAST = `${NAME}/DELETE_GLOBAL_FORECAST`

export const FETCH_GLOBAL_FORECAST_EVOLUTION_TABLE = `${NAME}/FETCH_GLOBAL_FORECAST_EVOLUTION_TABLE`
export const FETCH_GLOBAL_FORECAST_EVOLUTION_TABLE_SUCCESS = `${NAME}/FETCH_GLOBAL_FORECAST_EVOLUTION_TABLE_SUCCESS`
export const FETCH_GLOBAL_FORECAST_EVOLUTION_TABLE_FAIL = `${NAME}/FETCH_GLOBAL_FORECAST_EVOLUTION_TABLE_FAIL`

export const FETCH_FORECAST_EVOLUTION_TABLE_OPEN_ROW = `${NAME}/FETCH_FORECAST_EVOLUTION_TABLE_OPEN_ROW`
export const FETCH_FORECAST_EVOLUTION_TABLE_OPEN_ROW_SUCCESS = `${NAME}/FETCH_FORECAST_EVOLUTION_TABLE_OPEN_ROW_SUCCESS`
export const FETCH_FORECAST_EVOLUTION_TABLE_OPEN_ROW_FAIL = `${NAME}/FETCH_FORECAST_EVOLUTION_TABLE_OPEN_ROW_FAIL`

export const DOWNLOAD_GLOBAL_FORECAST_EVOLUTION_XLS = `${NAME}/DOWNLOAD_GLOBAL_FORECAST_EVOLUTION_XLS`

export const FETCH_GLOBAL_FORECAST_LIST = `${NAME}/FETCH_GLOBAL_FORECAST_LIST`
export const FETCH_GLOBAL_FORECAST_LIST_SUCCESS = `${NAME}/FETCH_GLOBAL_FORECAST_LIST_SUCCESS`
export const FETCH_GLOBAL_FORECAST_LIST_FAIL = `${NAME}/FETCH_GLOBAL_FORECAST_LIST_FAIL`

export const FETCH_GLOBAL_FORECAST_FILTER_LIST = `${NAME}/FETCH_GLOBAL_FORECAST_FILTER_LIST`
export const FETCH_GLOBAL_FORECAST_FILTER_LIST_SUCCESS = `${NAME}/FETCH_GLOBAL_FORECAST_FILTER_LIST_SUCCESS`
export const FETCH_GLOBAL_FORECAST_FILTER_LIST_FAIL = `${NAME}/FETCH_GLOBAL_FORECAST_FILTER_LIST_FAIL`

export const FETCH_GLOBAL_FORECAST_COMPARISON_TABLE = `${NAME}/FETCH_GLOBAL_FORECAST_COMPARISON_TABLE`
export const FETCH_GLOBAL_FORECAST_COMPARISON_TABLE_SUCCESS = `${NAME}/FETCH_GLOBAL_FORECAST_COMPARISON_TABLE_SUCCESS`
export const FETCH_GLOBAL_FORECAST_COMPARISON_TABLE_FAIL = `${NAME}/FETCH_GLOBAL_FORECAST_COMPARISON_TABLE_FAIL`

export const FETCH_FORECAST_COMPARISON_TABLE_OPEN_ROW = `${NAME}/FETCH_FORECAST_COMPARISON_TABLE_OPEN_ROW`
export const FETCH_FORECAST_COMPARISON_TABLE_OPEN_ROW_SUCCESS = `${NAME}/FETCH_FORECAST_COMPARISON_TABLE_OPEN_ROW_SUCCESS`
export const FETCH_FORECAST_COMPARISON_TABLE_OPEN_ROW_FAIL = `${NAME}/FETCH_FORECAST_COMPARISON_TABLE_OPEN_ROW_FAIL`

export const DOWNLOAD_GLOBAL_FORECAST_COMPARISON_XLS = `${NAME}/DOWNLOAD_GLOBAL_FORECAST_COMPARISON_XLS`

export const DOWNLOAD_FORECAST_TABLE_BY_PHASE_XLS = `${NAME}/DOWNLOAD_FORECAST_TABLE_BY_PHASE_XLS`

export const CLOSING_FORECAST = `${NAME}/CLOSING_FORECAST`

export const PUBLISH_GLOBAL_FORECAST = `${NAME}/PUBLISH_GLOBAL_FORECAST`
export const APPROVE_GLOBAL_FORECAST = `${NAME}/APPROVE_GLOBAL_FORECAST`

export const SET_DEFAULT_FORECAST = `${NAME}/SET_DEFAULT_FORECAST`
export const SET_DEFAULT_FORECAST_SUCCESS = `${NAME}/SET_DEFAULT_FORECAST_SUCCESS`
export const SET_DEFAULT_FORECAST_FAIL = `${NAME}/SET_DEFAULT_FORECAST_FAIL`

export const UPDATE_TRANSACTION_FORECAST = `${NAME}/UPDATE_TRANSACTION_FORECAST`
export const UPDATE_TRANSACTION_NESTED_FORECAST = `${NAME}/UPDATE_TRANSACTION_NESTED_FORECAST`

export const FETCH_GLOBAL_FORECAST_CHART = `${NAME}/FETCH_GLOBAL_FORECAST_CHART`
export const FETCH_GLOBAL_FORECAST_CHART_SUCCESS = `${NAME}/FETCH_GLOBAL_FORECAST_CHART_SUCCESS`
export const FETCH_GLOBAL_FORECAST_CHART_FAIL = `${NAME}/FETCH_GLOBAL_FORECAST_CHART_FAIL`

export const CREATE_TRANSACTION_COMMENT_FORECAST = `${NAME}/CREATE_TRANSACTION_COMMENT_FORECAST`
export const EDIT_TRANSACTION_COMMENT_FORECAST = `${NAME}/EDIT_TRANSACTION_COMMENT_FORECAST`
export const DELETE_TRANSACTION_COMMENT_FORECAST = `${NAME}/DELETE_TRANSACTION_COMMENT_FORECAST`

export const SYNC_CREATE_FORECAST_COMMENT = `${NAME}/SYNC_CREATE_FORECAST_COMMENT`
export const SYNC_DELETE_FORECAST_COMMENT = `${NAME}/SYNC_DELETE_FORECAST_COMMENT`
export const SYNC_EDIT_FORECAST_COMMENT = `${NAME}/SYNC_EDIT_FORECAST_COMMENT`

export const FETCH_GLOBAL_FORECAST_DETAIL = `${NAME}/FETCH_GLOBAL_FORECAST_DETAIL`
export const FETCH_GLOBAL_FORECAST_DETAIL_SUCCESS = `${NAME}/FETCH_GLOBAL_FORECAST_DETAIL_SUCCESS`
export const FETCH_GLOBAL_FORECAST_DETAIL_FAL = `${NAME}/FETCH_GLOBAL_FORECAST_DETAIL_FAIL`

export const UPDATE_FORECAST_INSTANCE = `${NAME}/UPDATE_FORECAST_INSTANCE`
export const CHANGE_PROJECTION_FORECAST = `${NAME}/CHANGE_PROJECTION_FORECAST`

export const UNAPPLY_RULE_FORECAST = `${NAME}/UNAPPLY_RULE_FORECAST`
export const APPLY_RULE_FORECAST = `${NAME}/APPLY_RULE_FORECAST`
export const APPLY_RULE_INSTANCE_FORECAST = `${NAME}/APPLY_RULE_INSTANCE_FORECAST`
export const UNAPPLY_RULE_INSTANCE_FORECAST = `${NAME}/UNAPPLY_RULE_INSTANCE_FORECAST`

export const DELETE_ROW = `${NAME}/DELETE_ROW`
export const DELETE_ALL = `${NAME}/DELETE_ALL`
export const DELETE_FILTER = `${NAME}/DELETE_FILTER`

export const UPLOAD_FILE_BY_PHASE_FORECAST = `${NAME}/UPLOAD_FILE_BY_PHASE_FORECAST`

export const DUPLICATE_FORECAST = `${NAME}/DUPLICATE_FORECAST`

export const ORDER_FORECAST_CARD = `${NAME}/ORDER_FORECAST_CARD`
export const SET_FORECAST_ORDER = `${NAME}/SET_FORECAST_ORDER`

export const MASSIVE_CREATE_FORECAST_TRANSACTION = `${NAME}/MASSIVE_CREATE_FORECAST_TRANSACTION,`
export const MASSIVE_CREATE_FORECAST_TRANSACTION_SUCCESS = `${NAME}/MASSIVE_CREATE_FORECAST_TRANSACTION_SUCCESS`
export const MASSIVE_CREATE_FORECAST_TRANSACTION_FAIL = `${NAME}/MASSIVE_CREATE_FORECAST_TRANSACTION_FAIL`

export const FETCH_DATE_BLOCKING_BY_FORECAST = `${NAME}/FETCH_DATE_BLOCKING_BY_FORECAST,`
export const FETCH_DATE_BLOCKING_BY_FORECAST_SUCCESS = `${NAME}/FETCH_DATE_BLOCKING_BY_FORECAST_SUCCESS`
export const FETCH_DATE_BLOCKING_BY_FORECAST_FAIL = `${NAME}/FETCH_DATE_BLOCKING_BY_FORECAST_FAIL`

// TODO: NEW ENDPOINTS FOR REVAMP TABLE
export const FETCH_DATA_EVOLUTION_BY_OVERALL_FORECAST = `${NAME}/FETCH_DATA_EVOLUTION_BY_OVERALL_FORECAST`
export const FETCH_DATA_EVOLUTION_BY_OVERALL_FORECAST_SUCCESS = `${NAME}/FETCH_DATA_EVOLUTION_BY_OVERALL_FORECAST_SUCCESS`
export const FETCH_DATA_EVOLUTION_BY_OVERALL_FORECAST_FAIL = `${NAME}/FETCH_DATA_EVOLUTION_BY_OVERALL_FORECAST_FAIL`

export const FETCH_DATA_COMPARATIVE_BY_OVERALL_FORECAST = `${NAME}/FETCH_DATA_COMPARATIVE_BY_OVERALL_FORECAST`
export const FETCH_DATA_COMPARATIVE_BY_OVERALL_FORECAST_SUCCESS = `${NAME}/FETCH_DATA_COMPARATIVE_BY_OVERALL_FORECAST_SUCCESS`
export const FETCH_DATA_COMPARATIVE_BY_OVERALL_FORECAST_FAIL = `${NAME}/FETCH_DATA_COMPARATIVE_BY_OVERALL_FORECAST_FAIL`

export const FETCH_DATA_RDN_PERCENTAGE_BY_FORECAST = `${NAME}/FETCH_DATA_RDN_PERCENTAGE_BY_FORECAST`
export const FETCH_DATA_RDN_PERCENTAGE_BY_FORECAST_SUCCESS = `${NAME}/FETCH_DATA_RDN_PERCENTAGE_BY_FORECAST_SUCCESS`
export const FETCH_DATA_RDN_PERCENTAGE_BY_FORECAST_FAIL = `${NAME}/FETCH_DATA_RDN_PERCENTAGE_BY_FORECAST_FAIL`

export const UPDATE_TRANSACTION_PERCENTAGE_BY_FORECAST = `${NAME}/UPDATE_TRANSACTION_PERCENTAGE_BY_FORECAST`

export const APPLY_RDN_PERCENTAGE_BY_FORECAST = `${NAME}/APPLY_RDN_PERCENTAGE_BY_FORECAST`
export const UN_APPLY_RDN_PERCENTAGE_BY_FORECAST = `${NAME}/APPLY_RDN_PERCENTAGE_BY_FORECAST`

export const DELETE_TRANSACTION_FILTER_BY_FORECAST = `${NAME}/DELETE_TRANSACTION_FILTER_BY_FORECAST`

export const DUPLICATE_GLOBAL_FORECAST = `${NAME}/DUPLICATE_GLOBAL_FORECAST`

export const FETCH_FORECAST_FILTER_DATES = `${NAME}/FETCH_FORECAST_FILTER_DATES`
export const FETCH_FORECAST_FILTER_DATES_SUCCESS = `${NAME}/FETCH_FORECAST_FILTER_DATES_SUCCESS`
