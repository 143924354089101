import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import axiosMiddleware from 'redux-axios-middleware'
import Immutable from 'seamless-immutable'

import { client } from './httpclient'
import rootReducer from './modules'

const initialState = Immutable({})
const enhancers = []

const middleware = [thunk, axiosMiddleware(client)]

if (process.env.NODE_ENV === 'development') {
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__

  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension())
  }
}

const composedEnhancers = compose(applyMiddleware(...middleware), ...enhancers)

const store = createStore(rootReducer, initialState, composedEnhancers)

export default store
