import { Divider, Input, Space } from 'antd'
import { Button } from 'modules/core/components'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { addItem, generateInputsNewItem, onSelectOption } from '../utils'

const FooterAddNewSelect = ({ menu, onRefetch, setIsCreating, create, isCreating }) => {
  const [dataCreate, setDataCreate] = useState({ code: '', name: '' })
  const { t } = useTranslation()

  const onClose = () => {
    setIsCreating(false)
    setDataCreate({ code: '', name: '' })
  }

  return (
    <>
      {menu}
      <Divider style={{ margin: '8px 0' }} />
      <Space direction="vertical" style={{ padding: 5, width: '100%' }}>
        {generateInputsNewItem(dataCreate).map((dataInput) => (
          <Input
            key={dataInput.id}
            style={{ width: '100%' }}
            value={dataInput.value}
            placeholder={t(dataInput.placeholder)}
            onChange={(value) =>
              onSelectOption({
                value,
                key: dataInput.id,
                setDataCreate,
              })
            }
          />
        ))}
        <Button.PrimaryBlock
          style={{ width: '100%' }}
          loading={isCreating}
          onClick={(event) =>
            addItem({
              event,
              setIsLoading: setIsCreating,
              createDimensionValue: () => create(dataCreate),
              onRefetch,
              onClose,
            })
          }
          title="ACTION_CREATE"
        />
      </Space>
    </>
  )
}

export default FooterAddNewSelect
