import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Col, Row, Spin } from 'antd'
import { useTranslation } from 'react-i18next'
import { ButtonActionBar, DropdownMenu, EmptyScreen } from 'modules/core/components'
import { ROLES } from 'modules/core/constants'
import { CreateCurrencyModal, CurrencyCardsList } from './components'
import { isUserAllowed } from 'modules/core/utils'
import { SolutionOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router-dom'
import emptyStateImage from 'assets/images/compositions/empty-big.svg'
import configuration from 'modules/configuration'

const { SETTINGS__PROJECTION_ELEMENTS__CURRENCY__ABM } = ROLES

const CurrencyList = ({ currencyList, fetchCurrencyList }) => {
  const [isScreenLoading, setIsScreenLoading] = useState(false)
  const { t } = useTranslation()
  let history = useHistory()

  const userCanActivate = isUserAllowed(SETTINGS__PROJECTION_ELEMENTS__CURRENCY__ABM)

  useEffect(() => {
    setIsScreenLoading(true)
    fetchCurrencyList().then(() => {
      setIsScreenLoading(false)
    })
  }, [fetchCurrencyList])

  const renderEmptyScreen = () => (
    <EmptyScreen
      image={emptyStateImage}
      title={t('CONFIG_CURRENCY_EMPTY_SCREEN_TITLE')}
      footer={
        <CreateCurrencyModal
          userCanActivate={userCanActivate}
          onRefetch={() => fetchCurrencyList()}
        />
      }
    />
  )

  const renderCards = () => (
    <CurrencyCardsList
      dataSource={currencyList.data}
      userCanActivate={userCanActivate}
      onRefetch={() => fetchCurrencyList()}
    />
  )

  const renderUserActions = () => (
    <ButtonActionBar>
      <CreateCurrencyModal
        userCanActivate={userCanActivate}
        onRefetch={() => fetchCurrencyList()}
      />
      <DropdownMenu
        title={t('ACTION_MORE')}
        menu={[
          {
            title: t('ACTION_ACTIVITIES'),
            icon: <SolutionOutlined />,
            onClick: () =>
              history.push(`/configuracion/actividad?type=currency&module=configCurrency`),
          },
        ]}
      />
    </ButtonActionBar>
  )

  return (
    <Spin spinning={isScreenLoading} size="large">
      {!isScreenLoading && (
        <>
          {currencyList.count === 0 && renderEmptyScreen()}
          {currencyList.count > 0 && (
            <Row gutter={[24, 24]}>
              <Col span={24}>{renderUserActions()}</Col>
              <Col span={24}>{renderCards()}</Col>
            </Row>
          )}
        </>
      )}
    </Spin>
  )
}

const mapStateToProps = (state) => ({
  currencyList: configuration.selectors.getCurrencyList(state),
})

const mapDispatchToProps = {
  fetchCurrencyList: configuration.actions.fetchCurrencyList,
}

export default connect(mapStateToProps, mapDispatchToProps)(CurrencyList)
