import React, { useEffect, useState } from 'react'
import { Card } from 'antd'
import { PieChart } from 'modules/core/components'
import { connect } from 'react-redux'
import numeral from 'numeral'
import home from 'modules/home'

const PieChartFilters = ({
  dimensionId,
  projectionId,
  budgetTypeId,
  selectedDates,
  periodId,
  fetchDataDimensionChart,
  dataDimensionChart,
}) => {
  const [chartLoading, setChartLoading] = useState(false)

  useEffect(() => {
    if (dimensionId && periodId && budgetTypeId && projectionId) {
      setChartLoading(true)
      fetchDataDimensionChart(dimensionId, periodId, {
        ppto_id: budgetTypeId,
        projection: projectionId,
        ...selectedDates,
      }).then(() => setChartLoading(false))
    }
    return () => setChartLoading(false)
  }, [fetchDataDimensionChart, dimensionId, budgetTypeId, projectionId, selectedDates, periodId])

  return (
    <div>
      <Card headStyle={{ height: 56 }} bodyStyle={{ padding: 0 }} loading={chartLoading}>
        <PieChart
          containerHeight={300}
          data={dataDimensionChart.asMutable({ deep: true })}
          legends={{}}
          margin={{ top: 40, bottom: 40 }}
          enableArcLinkLabels={false}
          arcLabel={({ label, data }) => numeral(data.originValue).format('0,0[.]0 a')}
          valueFormat={(value) =>
            `${numeral(dataDimensionChart.find((el) => el.value === value)?.originValue).format(
              '0,0[.]0 a',
            )}`
          }
        />
      </Card>
    </div>
  )
}

const mapStateToProps = (state) => ({
  dataDimensionChart: home.selectors.getDataDimensionChart(state),
})

const mapDispatchToProps = {
  fetchDataDimensionChart: home.actions.fetchDataDimensionChart,
}

export default connect(mapStateToProps, mapDispatchToProps)(PieChartFilters)
