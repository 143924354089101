import React from 'react'
import { Tooltip, Space } from 'antd'
import { Typography } from 'modules/core/components'

const References = ({ referencesItems = [] }) => (
  <Space>
    {referencesItems.map((item, index) => (
      <Tooltip title={item.description} key={index}>
        <Space direction="horizontal" size="small">
          <Typography.Subtitle style={{ color: item.color }}>{item.type}</Typography.Subtitle>
          {item.title}
        </Space>
      </Tooltip>
    ))}
  </Space>
)

export default References
