import React from 'react'
import { MessageOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'

const NoUserScreen = () => {
  const { t } = useTranslation()

  return (
    <div className="no-user-screen">
      <MessageOutlined className="message-icon" />
      <p className="mb-0">{t('LABEL_CHAT_BOT_START_CHAT')}</p>
    </div>
  )
}

export default NoUserScreen
