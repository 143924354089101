import ButtonBase from '../../ButtonBase'
import { useTranslation } from 'react-i18next'

const ButtonPrimary = ({
  title,
  disabled = false,
  loading = false,
  onClick = () => {},
  extraTitle = null,
  tooltipTitle = '',
  icon = null,
  ...props
}) => {
  const { t } = useTranslation()
  return (
    <ButtonBase
      {...props}
      type="primary"
      title={t(title)}
      disabled={disabled}
      onClick={onClick}
      loading={loading}
      tooltipTitle={tooltipTitle}
    >
      {t(title, extraTitle)}
      {icon && icon}
    </ButtonBase>
  )
}

export default ButtonPrimary
