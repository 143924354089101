import React from 'react'
import { Col, Progress, Row, Space } from 'antd'
import { KPIValue, Typography } from 'modules/core/components'
import { PROJECTION } from 'modules/core/constants'
import { useTranslation } from 'react-i18next'
import { renderPercentage } from 'modules/home/containers/Home/components/Dashboards/DashboardHome/utils'
import numeral from 'numeral'

const CardBody = ({ percentage, value, prefix, name, subKpis }) => {
  const { t } = useTranslation()
  return (
    <Row gutter={[8, 0]}>
      <Col span={12} style={!percentage ? { marginBottom: 79 } : {}}>
        <Row gutter={[8, 8]}>
          <Col span={24}>
            <KPIValue
              size="medium"
              fullValue={value && numeral(value).format('0,0.00')}
              value={value ? numeral(value).format('0[.]0a') : '-'}
              prefix={prefix}
            />
            <Typography.Body level={3} type="secondary">
              {t(PROJECTION.NAME_PROJECTIONS[name])}
            </Typography.Body>
          </Col>
          <Col span={24}>
            {percentage && (
              <Space direction="vertical">
                <Progress
                  format={(data) =>
                    data === 100 ? <span style={{ color: 'grey' }}>100%</span> : `${data}%`
                  }
                  strokeLinecap="butt"
                  strokeColor="#5eb3e4"
                  strokeWidth={10}
                  type="circle"
                  percent={renderPercentage(percentage)}
                  width={60}
                />
                <Typography.Body level={3} type="secondary">
                  {percentage.name}
                </Typography.Body>
              </Space>
            )}
          </Col>
        </Row>
      </Col>
      <Col span={12}>
        {subKpis &&
          subKpis.map((kpi) => (
            <Row gutter={[8, 0]} key={kpi.id}>
              <Col span={14}>
                <KPIValue
                  prefix={kpi.prefix}
                  fullValue={kpi.value && numeral(kpi.value).format('0,0.00')}
                  value={kpi.value ? numeral(kpi.value).format('0[.]0a') : '-'}
                />
                <br />
                <Typography.Body level={3} type="secondary">
                  {t(PROJECTION.NAME_PROJECTIONS[kpi.name])}
                </Typography.Body>
              </Col>
              <Col span={10}>
                <KPIValue
                  isVariation={true}
                  value={kpi.variation ? kpi.variation : '-'}
                  type="secondary"
                />
              </Col>
            </Row>
          ))}
      </Col>
    </Row>
  )
}

export default CardBody
