import { columnsHelper } from 'modules/core/utils'

export const generatorEvolutionColumns = ({
  columns,
  periodTableSettings,
  setPeriodTableColumns,
}) =>
  columnsHelper.generatorDefault({
    columns,
    onResize: setPeriodTableColumns,
    cellFormat: {
      format: periodTableSettings.format,
      showNegativeInRed: periodTableSettings.showNegativeInRed,
    },
    disabled: true,
  })

export const onExpandByPeriod = ({
  expanded,
  record,
  setIsLoading,
  fetchTableOpenRow,
  setExpandedByPeriodRow,
}) => {
  setIsLoading(true)
  if (expanded) {
    fetchTableOpenRow().then(() => {
      setIsLoading(false)
      setExpandedByPeriodRow((prevExpanded) => [...prevExpanded, record.id])
    })
  } else {
    setExpandedByPeriodRow((prevExpanded) => prevExpanded.filter((item) => item !== record.id))
    setIsLoading(false)
  }
}
