import React from 'react'
import {
  Button,
  ButtonActionBar,
  CustomBottomAction,
  DropdownMenu,
  Typography,
} from 'modules/core/components'
import { useTranslation } from 'react-i18next'
import {
  DownOutlined,
  SolutionOutlined,
  DeleteOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons'
import { TABLES } from 'modules/core/constants'
import { Popconfirm, Select, Space, Tooltip } from 'antd'
import { generateSelectOptions } from 'modules/core/utils'
import { useFeatureIsOn } from '@growthbook/growthbook-react'

const UserActions = ({
  onClickFile,
  onClickSpreadsheets,
  onClickActivity,
  disabled,
  onClickDelete,
  visible,
  loading,
  onCancelPopConfirm,
  onConfirmPopConfirm,
  hasCreate = false,
  onClickCreate = () => {},
  extraButtons,
  currencyOptions,
  onChangeCurrency,
  defaultCurrency,
}) => {
  const enabled = useFeatureIsOn('feature-moneda-seguimiento')
  const { t } = useTranslation()

  const execute = {
    spreadsheets: onClickSpreadsheets,
    file: onClickFile,
  }

  return (
    <ButtonActionBar position={'space-between'}>
      <Space direction="horizontal">
        {enabled && (
          <>
            <Typography.Body>{t('LABEL_CURRENCY')}:</Typography.Body>
            <Select
              bordered={false}
              value={defaultCurrency}
              placeholder={t('ACTION_SELECT')}
              options={generateSelectOptions({ options: currencyOptions })}
              onChange={onChangeCurrency}
            />
          </>
        )}
      </Space>
      <Space direction="horizontal">
        {hasCreate && (
          <Tooltip title={t('ADD_NEW_TRANSACTION_INFORMATIVE_TOOLTIP_TITLE')}>
            <Button.PrimaryWhitRightIcon
              icon={<PlusCircleOutlined />}
              title="ACTION_ADD_NEW"
              disabled={disabled}
              onClick={onClickCreate}
            />
          </Tooltip>
        )}
        <DropdownMenu
          title={t('ACTION_UPLOAD_FILE')}
          placement="bottomRight"
          menu={TABLES.OPTIONS_DOWNLOAD.map((formatType) => {
            return {
              title: formatType.name,
              key: formatType.id,
              disabled,
              onClick: execute[formatType.id],
            }
          })}
        >
          <Button.PrimaryWhitRightIcon
            icon={<DownOutlined />}
            disabled={disabled}
            title="ACTION_UPLOAD_FILE"
          />
        </DropdownMenu>
        <CustomBottomAction extraButtons={extraButtons} />
        <DropdownMenu
          title={t('ACTION_MORE')}
          menu={[
            {
              title: t('ACTION_ACTIVITIES'),
              icon: <SolutionOutlined />,
              onClick: onClickActivity,
            },
            {
              title: (
                <Tooltip title={t('DELETE_ALL_INFORMATIVE_TOOLTIP_TITLE')}>
                  {t('ACTION_DELETE_ALL')}
                </Tooltip>
              ),
              icon: <DeleteOutlined />,
              onClick: onClickDelete,
            },
          ]}
        />
        <Popconfirm
          title={t('POPCONFIRM_DELETE_ALL_PAGES')}
          placement="right"
          okText={t('ACTION_DELETE')}
          okButtonProps={{ loading }}
          cancelButtonProps={{ disabled: loading }}
          onConfirm={onConfirmPopConfirm}
          cancelText={t('ACTION_CANCEL')}
          onCancel={onCancelPopConfirm}
          visible={visible}
        />
      </Space>
    </ButtonActionBar>
  )
}
export default UserActions
