import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { useHistory, useParams, Prompt } from 'react-router-dom'
import _ from 'lodash'
import { Button, Row, Col, notification, Tooltip } from 'antd'
import { SiderLayout } from 'modules/core/layouts'
import {
  ButtonActionBar,
  PeriodNavigator,
  CreationExitModal,
  ConfirmationModal,
} from 'modules/core/components'
import { AdjustmentCreationInfo, AdjustmentCreationTable } from './components'
import { dateMMMMYYYY, generateMessageError, isUserAllowed } from 'modules/core/utils'
import { ADJUSTMENTS, CONCEPTS, ROLES } from 'modules/core/constants'
import { useTranslation } from 'react-i18next'
import adjustments from 'modules/adjustments'
import control from 'modules/control'

import './AdjustmentCreation.scss'

const { CONTROL__ADJUSTMENTS__CREATE } = ROLES

const AdjustamentCreation = ({
  approveDates,
  adjustmentAvailableTypes,
  creationDate,
  creationType,
  creationRows,
  creationWarning,
  createAdjustment,
  fetchApproveDates,
  exitAdjustmentCreation,
  duplicateAdjustment,
}) => {
  const [showExitModal, setShowExitModal] = useState(false)
  const [lastLocation, setLastLocation] = useState(null)
  const [confirmedNavigation, setConfirmedNavigation] = useState(false)
  const [showCreationModal, setShowCreationModal] = useState(false)
  const [isLoadingDates, setIsLoadingDates] = useState(true)
  const [isCreatingAdjustment, setIsCreatingAdjustment] = useState(false)
  const [comment, setComment] = useState('')
  let { periodId } = useParams()
  let history = useHistory()
  const { t } = useTranslation()

  var queryString = window.location.search
  var urlParams = new URLSearchParams(queryString)
  var duplicatedId = urlParams.get('duplicatedId')

  useEffect(() => {
    fetchApproveDates(periodId, {
      concept_type: CONCEPTS.TYPE.ECONOMIC_KEY,
    }).then(() => setIsLoadingDates(false))
    return () => exitAdjustmentCreation()
  }, [fetchApproveDates, exitAdjustmentCreation, periodId])

  useEffect(() => {
    duplicateAdjustment(duplicatedId)
  }, [duplicateAdjustment, duplicatedId])

  const onConfirmCreation = (comment = '') => {
    setIsCreatingAdjustment(true)
    createAdjustment({
      date: creationDate,
      type: creationType,
      comment,
      rows: creationRows.map((row) => {
        return {
          dv_filter: _(row)
            .pickBy((value, key) => key !== 'id' && key !== 'amount' && value !== null)
            .mapValues('id')
            .value(),
          amount: row.amount,
        }
      }),
    })
      .then((response) => {
        setIsCreatingAdjustment(false)
        setShowCreationModal(false)
        notification.success({
          message: t('ADJUSTMENT_CREATE_SUCCESS_FEEDBACK_TITLE'),
          description: t('ADJUSTMENT_SUCCESS_FEEDBACK_DESCRIPTION'),
          duration: 8,
        })
        setConfirmedNavigation(true)
        exitAdjustmentCreation()
        history.push(
          `/seguimiento/${periodId}/economico/ajustes/${response.payload.data.id}?created=true`,
        )
      })
      .catch((error) => {
        setIsCreatingAdjustment(false)
        setShowCreationModal(false)
        notification.error({
          message: t('FEEDBACK_DEFAULT_ERROR'),
          description: generateMessageError(error, 'ADJUSTMENT_CREATE_FAIL_FEEDBACK_DESCRIPTION'),
          duration: 0,
        })
      })
  }

  const renderHeader = () => (
    <PeriodNavigator
      items={[
        {
          name: t('LABEL_CONTROL'),
          url: `/seguimiento/${periodId}`,
        },
        {
          name: t('LABEL_ECONOMIC'),
          url: `/seguimiento/${periodId}/economico?tab=ajustes`,
        },
        { name: t('ADJUSTMENT_CREATE_FORM_TITLE') },
      ]}
    />
  )

  const handleClickCancel = () => {
    if (creationDate || creationType || comment !== '') {
      setConfirmedNavigation(true)
      setShowExitModal(true)
    } else {
      history.push(`/seguimiento/${periodId}/economico?tab=ajustes`)
    }
  }

  const renderBottomActions = () => (
    <ButtonActionBar position="center">
      <Button onClick={() => handleClickCancel()}>{t('ACTION_CANCEL')}</Button>
      {isUserAllowed(CONTROL__ADJUSTMENTS__CREATE) && (
        <Tooltip title={creationWarning || comment === '' ? t('ALERT_NULL_DATA') : ''}>
          <Button
            type="primary"
            disabled={creationWarning || comment === ''}
            onClick={() => setShowCreationModal(true)}
          >
            {t('ADJUSTMENT_CREATE_ACTION')}
          </Button>
        </Tooltip>
      )}
    </ButtonActionBar>
  )

  const handleExitConfirm = () => {
    if (lastLocation) {
      setShowExitModal(false)
      history.push(lastLocation)
    } else {
      history.push(`/seguimiento/${periodId}/economico/?tab=ajustes`)
    }
  }

  const handleBlockedNavigation = (nextLocation) => {
    if (!confirmedNavigation) {
      setShowExitModal(true)
      setLastLocation(nextLocation)
      setConfirmedNavigation(true)
      return false
    }
    return true
  }

  return (
    <SiderLayout>
      <Prompt
        when={creationDate || creationType || comment !== ''}
        message={handleBlockedNavigation}
      />
      {renderHeader()}
      <div className="adjustment-creation-container">
        <Row gutter={[24, 24]}>
          <Col span={20}>
            <AdjustmentCreationInfo
              valueDate={creationDate}
              dateOptions={approveDates}
              valueType={creationType}
              typesOptions={adjustmentAvailableTypes}
              isLoadingDates={isLoadingDates}
              comment={comment}
              setComment={setComment}
            />
          </Col>
          <Col span={24}>
            <AdjustmentCreationTable valueDate={creationDate} dataSource={creationRows} />
          </Col>
        </Row>
        {renderBottomActions()}
      </div>
      <CreationExitModal
        visible={showExitModal}
        onClose={() => {
          setShowExitModal(false)
          setConfirmedNavigation(false)
        }}
        onConfirm={handleExitConfirm}
      />
      {!creationWarning && (
        <ConfirmationModal
          title={t('ADJUSTMENT_CREATE_CONFIRM_TITLE')}
          bodyText={t('ADJUSTMENT_CREATE_CONFIRM_DESCRIPTION', {
            type: ADJUSTMENTS.TYPES[creationType].toLowerCase(),
            añoMes: dateMMMMYYYY(creationDate, 'YYYY-MM-DD'),
          })}
          okText={t('ACTION_CREATE')}
          visible={showCreationModal}
          onClose={() => setShowCreationModal(false)}
          onConfirm={() => onConfirmCreation(comment)}
          loading={isCreatingAdjustment}
        />
      )}
    </SiderLayout>
  )
}

const mapStateToProps = (state) => ({
  adjustmentAvailableTypes: adjustments.selectors.getAdjustmentAvailableTypes(state),
  creationDate: adjustments.selectors.getCreationDate(state),
  creationType: adjustments.selectors.getCreationType(state),
  creationRows: adjustments.selectors.getCreationRows(state),
  creationWarning: adjustments.selectors.getCreationWarning(state),
  approveDates: control.selectors.getApproveDates(state),
})

const mapDispatchToProps = {
  fetchApproveDates: control.actions.fetchApproveDates,
  createAdjustment: adjustments.actions.createAdjustment,
  exitAdjustmentCreation: adjustments.actions.exitAdjustmentCreation,
  duplicateAdjustment: adjustments.actions.duplicateAdjustment,
}

export default connect(mapStateToProps, mapDispatchToProps)(AdjustamentCreation)
