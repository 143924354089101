import { Popconfirm, Select, Space } from 'antd'
import { Button, ButtonActionBar, Typography } from 'modules/core/components'
import { generateSelectOptions } from 'modules/core/utils'
import React from 'react'
import { useTranslation } from 'react-i18next'

const ActionBar = (props) => {
  const {
    rdnDefault,
    rdnOptions,
    handleRdnChange,
    onApplyConfirmPopConfirm,
    onUnApplyConfirmPopConfirm,
    hasApplyOrUnApply,
  } = props
  const { t } = useTranslation()

  return (
    <ButtonActionBar position="space-between">
      <Space direction="horizontal">
        <Typography.Body>{t('LABEL_BUSINESS_RULES')}:</Typography.Body>
        <Select
          style={{ width: 300 }}
          value={rdnDefault}
          placeholder={t('LABEL_BUSINESS_RULES')}
          options={generateSelectOptions({ options: rdnOptions })}
          onChange={handleRdnChange}
        />
      </Space>
      <Space>
        <Popconfirm
          placement="bottomRight"
          title={
            <div style={{ width: 300 }}>{t('PLANNING_PHASES_ACTION_CONFIRM_POPCONFIRM_TITLE')}</div>
          }
          onConfirm={onApplyConfirmPopConfirm}
        >
          <Button.Primary title={'Aplicar'} disabled={hasApplyOrUnApply} />
        </Popconfirm>
        <Popconfirm
          placement="bottomRight"
          title={
            <div style={{ width: 300 }}>{t('PLANNING_PHASES_ACTION_CONFIRM_POPCONFIRM_TITLE')}</div>
          }
          onConfirm={onUnApplyConfirmPopConfirm}
        >
          <Button.Primary title={'Desaplicar'} disabled={hasApplyOrUnApply} />
        </Popconfirm>
      </Space>
    </ButtonActionBar>
  )
}

export default ActionBar
