import { Radio, Space, Typography as TypographyAntd } from 'antd'
import { PROJECTION } from 'modules/core/constants'
import { useTranslation } from 'react-i18next'

const { Text } = TypographyAntd

const TypeUploadOptions = ({ onChange, value, typeFilter = null }) => {
  const { t } = useTranslation()

  const auxData = typeFilter
    ? PROJECTION.TYPE_UPLOAD_OPTIONS.filter((el) => el.id !== typeFilter)
    : PROJECTION.TYPE_UPLOAD_OPTIONS

  return (
    <Radio.Group onChange={onChange} value={value}>
      <Space direction="vertical">
        {auxData.map((item) => (
          <Radio value={item.id} key={item.id}>
            <Space direction="vertical">
              <Text strong>{t(item.title)}</Text>
              <Text italic>{t(item.text)}</Text>
            </Space>
          </Radio>
        ))}
      </Space>
    </Radio.Group>
  )
}

export default TypeUploadOptions
