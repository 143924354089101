import React from 'react'
import { Popconfirm, Space } from 'antd'
import { Button, DropdownMenu, Typography } from 'modules/core/components'
import { ROLES } from 'modules/core/constants'
import { isUserAllowed } from 'modules/core/utils'
import { useTranslation } from 'react-i18next'
import { EyeOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons'

const { SETTINGS__DIMENSIONS__ABM } = ROLES

const TableActions = ({
  onCancelDeleted,
  onConfirmDeleted,
  visible,
  onClickDetail,
  onClickEdit,
  onClickDeleted,
}) => {
  const { t } = useTranslation()
  return (
    <Popconfirm
      placement="bottomRight"
      title={t('CONFIG_DIMENSIONS_POPCONFIRM_DELETE_DIMENSION_TITLE')}
      okText={t('ACTION_DELETE')}
      onConfirm={onConfirmDeleted}
      cancelText={t('ACTION_CANCEL')}
      onCancel={onCancelDeleted}
      visible={visible}
    >
      <Space>
        <Button.Icon
          icon={<Typography.Icon icon={EyeOutlined} />}
          title="ACTION_OPEN_DETAIL"
          onClick={onClickDetail}
        />
        <DropdownMenu
          title={t('ACTION_MORE')}
          menu={[
            {
              title: t('ACTION_EDIT'),
              icon: <EditOutlined />,
              onClick: onClickEdit,
              disabled: !isUserAllowed(SETTINGS__DIMENSIONS__ABM),
            },
            {
              title: t('ACTION_DELETE'),
              icon: <DeleteOutlined />,
              onClick: onClickDeleted,
              disabled: !isUserAllowed(SETTINGS__DIMENSIONS__ABM),
            },
          ]}
        />
      </Space>
    </Popconfirm>
  )
}

export default TableActions
