import React from 'react'
import { Popconfirm, Tooltip } from 'antd'
import { Button, Typography } from 'modules/core/components'
import { useTranslation } from 'react-i18next'
import { DeleteOutlined } from '@ant-design/icons'

const DeleteSelectionButton = ({ onConfirm, disabled, isDeletingAll, type }) => {
  const { t } = useTranslation()
  return (
    <Popconfirm
      placement="bottomRight"
      title={t('POPCONFIRM_DELETE_SELECTION_TITLE')}
      okText={t('ACTION_DELETE')}
      onConfirm={onConfirm}
      cancelText={t('ACTION_CANCEL')}
    >
      <Tooltip
        title={t('DELETE_SELECTION_INFORMATIVE_TOOLTIP_TITLE', {
          extraText: type === 'forecast' ? t('EXTRA_TEXT_DELETE_INFORMATIVE_TOOLTIP_TITLE') : '',
        })}
      >
        <Button.Icon
          title={isDeletingAll ? t('ACTION_DELETE_PAGE') : t('ACTION_DELETE_SELECTION')}
          icon={<Typography.Icon icon={DeleteOutlined} level={1} />}
          disabled={disabled}
        />
      </Tooltip>
    </Popconfirm>
  )
}

export default DeleteSelectionButton
