import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import numeral from 'numeral'
import { Card, Row, Col } from 'antd'
import { KPIValue, Typography } from 'modules/core/components'
import control from 'modules/control'

const ControlKPIs = ({
  periodId,
  filters = {},
  selectedDates,
  conceptName = null,
  refetch: { needRefetch = false, setRefetch = null },
  controlDetailKpis,
  fetchControlDetailKPIs,
  renderPrefix,
}) => {
  const [isKpisLoading, setIsKpisLoading] = useState(true)

  useEffect(() => {
    if (conceptName) {
      setIsKpisLoading(true)
      fetchControlDetailKPIs(periodId, {
        concept: conceptName,
        ...selectedDates,
        ...filters,
      }).then(() => {
        if (needRefetch) {
          setRefetch((prevRefetch) => {
            return { ...prevRefetch, kpi: false }
          })
        }
        setIsKpisLoading(false)
      })
    }
  }, [
    fetchControlDetailKPIs,
    periodId,
    filters,
    selectedDates,
    conceptName,
    needRefetch,
    setRefetch,
  ])

  return (
    <Row gutter={[24, 0]}>
      <>
        {isKpisLoading &&
          [1, 2, 3].map((index) => (
            <Col span={8} key={index}>
              <Card loading={true} />
            </Col>
          ))}
        {!isKpisLoading &&
          controlDetailKpis.map((kpi, index) => (
            <Col span={8} key={index}>
              <Card>
                <Row align="middle" justify="end" key={index}>
                  <Col span={10}>
                    <KPIValue
                      size="medium"
                      fullValue={kpi.value && numeral(kpi.value).format('0,0.00')}
                      value={kpi.value ? numeral(kpi.value).format('0[.]0a') : '-'}
                      prefix={renderPrefix(kpi.prefix)}
                    />
                    <Typography.Body level={3} type="secondary">
                      {kpi.name}
                    </Typography.Body>
                  </Col>
                  <Col span={14}>
                    {kpi.children.map((kpi, index) => (
                      <Row gutter={[8, 8]} key={index}>
                        <Col span={12}>
                          <KPIValue
                            prefix={renderPrefix(kpi.prefix)}
                            fullValue={kpi.value && numeral(kpi.value).format('0,0.00')}
                            value={kpi.value ? numeral(kpi.value).format('0[.]0a') : '-'}
                          />
                          <br />
                          <Typography.Body level={3} type="secondary">
                            {kpi.name}
                          </Typography.Body>
                        </Col>
                        <Col span={12}>
                          <KPIValue
                            isVariation={true}
                            value={kpi.variation ? kpi.variation : '-'}
                            type="secondary"
                          />
                        </Col>
                      </Row>
                    ))}
                  </Col>
                </Row>
              </Card>
            </Col>
          ))}
      </>
    </Row>
  )
}

const mapStateToProps = (state) => ({
  controlDetailKpis: control.selectors.getControlDetailKpis(state),
})

const mapDispatchToProps = {
  fetchControlDetailKPIs: control.actions.fetchControlDetailKPIs,
}

export default connect(mapStateToProps, mapDispatchToProps)(ControlKPIs)
