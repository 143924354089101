export const setSelections = (dataColumnsRows) => {
  const optionsColumnsIds = dataColumnsRows[1].options.map((op) => op.id.toString())
  const optionsRowsIds = dataColumnsRows[0].options.map((op) => op.id.toString())
  const optionsBaseDisabled = [...optionsRowsIds, ...optionsColumnsIds]

  if (optionsColumnsIds.includes('pxq')) {
    return [...optionsBaseDisabled, 'p', 'q']
  }
  if (optionsColumnsIds.includes('p')) {
    return [...optionsBaseDisabled, 'q', 'pxq']
  }
  if (optionsColumnsIds.includes('q')) {
    return [...optionsBaseDisabled, 'p', 'pxq']
  }
  return optionsBaseDisabled
}
