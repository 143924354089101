export const NAME = 'reports'

export const FETCH_REPORT_LIST = `${NAME}/FETCH_REPORT_LIST`
export const FETCH_REPORT_LIST_SUCCESS = `${NAME}/FETCH_REPORT_LIST_SUCCESS`
export const FETCH_REPORT_LIST_FAIL = `${NAME}/FETCH_REPORT_LIST_FAIL`

export const FETCH_ANALYSIS_LIST = `${NAME}/FETCH_ANALYSIS_LIST`
export const FETCH_ANALYSIS_LIST_SUCCESS = `${NAME}/FETCH_ANALYSIS_LIST_SUCCESS`
export const FETCH_ANALYSIS_LIST_FAIL = `${NAME}/FETCH_ANALYSIS_LIST_FAIL`

export const FETCH_REPORT_DETAIL = `${NAME}/FETCH_REPORT_DETAIL`
export const FETCH_REPORT_DETAIL_SUCCESS = `${NAME}/FETCH_REPORT_DETAIL_SUCCESS`
export const FETCH_REPORT_DETAIL_FAIL = `${NAME}/FETCH_REPORT_DETAIL_FAIL`

export const SET_REPORT_LINE_SELECTED = `${NAME}/SET_REPORT_LINE_SELECTED`

export const SET_DASHBOARD_DATA_ELEMENT = `${NAME}/SET_DASHBOARD_DATA_ELEMENT`

export const EDIT_DASHBOARD_DATA_ELEMENT = `${NAME}/EDIT_DASHBOARD_DATA_ELEMENT`

export const DELETE_DASHBOARD_DATA_ELEMENT = `${NAME}/DELETE_DASHBOARD_DATA_ELEMENT`

export const FETCH_ANALYSIS_KPI = `${NAME}/FETCH_ANALYSIS_KPI`
export const FETCH_ANALYSIS_KPI_SUCCESS = `${NAME}/FETCH_ANALYSIS_KPI_SUCCESS`
export const FETCH_ANALYSIS_KPI_FAIL = `${NAME}/FETCH_ANALYSIS_KPI_FAIL`

export const FETCH_ANALYSIS_FILTERS = `${NAME}/FETCH_ANALYSIS_FILTERS`
export const FETCH_ANALYSIS_FILTERS_SUCCESS = `${NAME}/FETCH_ANALYSIS_FILTERS_SUCCESS`
export const FETCH_ANALYSIS_FILTERS_FAIL = `${NAME}/FETCH_ANALYSIS_FILTERS_FAIL`

export const FETCH_REPORT_DETAIL_STRUCTURE = `${NAME}/FETCH_REPORT_DETAIL_STRUCTURE`
export const FETCH_REPORT_DETAIL_STRUCTURE_SUCCESS = `${NAME}/FETCH_REPORT_DETAIL_STRUCTURE_SUCCESS`
export const FETCH_REPORT_DETAIL_STRUCTURE_FAIL = `${NAME}/FETCH_REPORT_DETAIL_STRUCTURE_FAIL`

export const FETCH_TABLE_OPEN_ROW = `${NAME}/FETCH_TABLE_OPEN_ROW`
export const FETCH_TABLE_OPEN_ROW_SUCCESS = `${NAME}/FETCH_TABLE_OPEN_ROW_SUCCESS`
export const FETCH_TABLE_OPEN_ROW_FAIL = `${NAME}/FETCH_TABLE_OPEN_ROW_FAIL`

export const CREATE_REPORT_BY_FILE = `${NAME}/CREATE_REPORT_BY_FILE`

export const EDIT_REPORT = `${NAME}/EDIT_REPORT`

export const DELETE_REPORT = `${NAME}/DELETE_REPORT`

export const UPLOAD_REPORT_VALUES_XLS = `${NAME}/UPLOAD_REPORT_VALUES_XLS`
export const FETCH_REPORT_TEMPLATE_XLS = `${NAME}/FETCH_REPORT_TEMPLATE_XLS`
export const FETCH_REPORT_CONSOLIDATED_XLS = `${NAME}/FETCH_REPORT_CONSOLIDATED_XLS`

export const DOWNLOAD_EVOLUTION_XLS = `${NAME}/DOWNLOAD_EVOLUTION_XLS`
export const DOWNLOAD_COMPARE_XLS = `${NAME}/DOWNLOAD_COMPARE_XLS`
export const DOWNLOAD_CUSTOM_XLS = `${NAME}/DOWNLOAD_CUSTOM_XLS`

export const DOWNLOAD_TEMPLATE_XLS = `${NAME}/DOWNLOAD_TEMPLATE_XLS`

export const FETCH_REPORT_COMPARATIVE_DETAIL = `${NAME}/FETCH_REPORT_COMPARATIVE_DETAIL`
export const FETCH_REPORT_COMPARATIVE_DETAIL_SUCCESS = `${NAME}/FETCH_REPORT_COMPARATIVE_DETAIL_SUCCESS`
export const FETCH_REPORT_COMPARATIVE_DETAIL_FAIL = `${NAME}/FETCH_REPORT_COMPARATIVE_DETAIL_FAIL`

export const FETCH_TABLE_COMPARATIVE_OPEN_ROW = `${NAME}/FETCH_TABLE_COMPARATIVE_OPEN_ROW`
export const FETCH_TABLE_COMPARATIVE_OPEN_ROW_SUCCESS = `${NAME}/FETCH_TABLE_COMPARATIVE_OPEN_ROW_SUCCESS`
export const FETCH_TABLE_COMPARATIVE_OPEN_ROW_FAIL = `${NAME}/FETCH_TABLE_COMPARATIVE_OPEN_ROW_FAIL`

export const FETCH_REPORT_DASHBOARD_LIST = `${NAME}/FETCH_REPORT_DASHBOARD_LIST`
export const FETCH_REPORT_DASHBOARD_LIST_SUCCESS = `${NAME}/FETCH_REPORT_DASHBOARD_LIST_SUCCESS`
export const FETCH_REPORT_DASHBOARD_LIST_FAIL = `${NAME}/FETCH_REPORT_DASHBOARD_LIST_FAIL`

export const EDIT_DASHBOARD_ELEMENT = `${NAME}/EDIT_DASHBOARD_ELEMENT`
export const DELETE_DASHBOARD_ELEMENT = `${NAME}/DELETE_DASHBOARD_ELEMENT`
export const CREATE_DASHBOARD_ELEMENT = `${NAME}/CREATE_DASHBOARD_ELEMENT`

export const DOWNLOAD_REPORT_PPT = `${NAME}/DOWNLOAD_REPORT_PPT`
export const DOWNLOAD_REPORT_PPT_DRIVE = `${NAME}/DOWNLOAD_REPORT_PPT_DRIVE`

export const SET_REPORT_ORDER = `${NAME}/SET_REPORT_ORDER`

export const ORDER_REPORTS = `${NAME}/ORDER_REPORTS`

export const SET_SETTINGS_CHARTS = `${NAME}/SET_SETTINGS_CHARTS`

//DASHBOARD SELF-SERVICE
export const FETCH_DASHBOARD_URL = `${NAME}/FETCH_DASHBOARD_URL`
export const FETCH_DASHBOARD_URL_SUCCESS = `${NAME}/FETCH_DASHBOARD_URL_SUCCESS`
export const FETCH_DASHBOARD_URL_FAIL = `${NAME}/FETCH_DASHBOARD_URL_FAIL`

//DASHBOARD SELF-SERVICE
// TODO: NEW ENDPOINTS FOR REVAMP TABLE
export const FETCH_DATA_EVOLUTION_BY_REPORT = `${NAME}/FETCH_DATA_EVOLUTION_BY_REPORT`
export const FETCH_DATA_EVOLUTION_BY_REPORT_SUCCESS = `${NAME}/FETCH_DATA_EVOLUTION_BY_REPORT_SUCCESS`
export const FETCH_DATA_EVOLUTION_BY_REPORT_FAIL = `${NAME}/FETCH_DATA_EVOLUTION_BY_REPORT_FAIL`

export const FETCH_DATA_GRAPH_AND_KPI_BY_REPORT = `${NAME}/FETCH_DATA_GRAPH_AND_KPI_BY_REPORT`
export const FETCH_DATA_GRAPH_AND_KPI_BY_REPORT_SUCCESS = `${NAME}/FETCH_DATA_GRAPH_AND_KPI_BY_REPORT_SUCCESS`
export const FETCH_DATA_GRAPH_AND_KPI_BY_REPORT_FAIL = `${NAME}/FETCH_DATA_GRAPH_AND_KPI_BY_REPORT_FAIL`

export const FETCH_DATA_COMPARATIVE_BY_REPORT = `${NAME}/FETCH_DATA_COMPARATIVE_BY_REPORT`
export const FETCH_DATA_COMPARATIVE_BY_REPORT_SUCCESS = `${NAME}/FETCH_DATA_COMPARATIVE_BY_REPORT_SUCCESS`
export const FETCH_DATA_COMPARATIVE_BY_REPORT_FAIL = `${NAME}/FETCH_DATA_COMPARATIVE_BY_REPORT_FAIL`

export const UPDATED_INITIAL_AMOUNT_BY_FINANCIAL = `${NAME}/UPDATED_INITIAL_AMOUNT_BY_FINANCIAL`
