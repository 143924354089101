import React, { useEffect, useState } from 'react'
import { SendOutlined } from '@ant-design/icons'
import { Button, Select } from 'antd'
import { CHATBOT } from 'modules/core/constants'
import { connect } from 'react-redux'
import configuration from 'modules/configuration'
import planning from 'modules/planning'
import chatbot from 'modules/chatbot/index'

const AddMessage = ({
  onSendMessage,
  currentMessage = {},
  valuesList,
  conceptList,
  periodList,
  dimensionList,
}) => {
  const DEFAULT_VALUES = CHATBOT.DEFAULT_VALUES
  DEFAULT_VALUES[CHATBOT.CHATBOT_KEYS.DIMENSION_KEY] = dimensionList[0].name_origin
  DEFAULT_VALUES[CHATBOT.CHATBOT_KEYS.CONCEPT_KEY] = conceptList.values[0].name
  DEFAULT_VALUES[CHATBOT.CHATBOT_KEYS.PERIOD_KEY] = periodList[0].name
  DEFAULT_VALUES[CHATBOT.CHATBOT_KEYS.UNIT_OF_TIME_KEY] = valuesList.unitOfTime[0].name
  DEFAULT_VALUES[CHATBOT.CHATBOT_KEYS.UNIT_OF_AMOUNT_KEY] = valuesList.unitOfAmount[0].name

  const [message, setMessage] = useState(currentMessage.name)
  const [values, setValues] = useState(DEFAULT_VALUES)

  useEffect(() => {
    setMessage(currentMessage.name)
  }, [currentMessage])

  const onKeyPress = (event) => {
    if (event.key === 'Enter') {
      onClickSendMessage()
    }
  }

  const onClickSendMessage = () => {
    if (message) {
      const textoModificado = Object.keys(values).reduce((text, key) => {
        return text.replaceAll(key, values[key])
      }, message)
      onSendMessage(textoModificado, currentMessage)
    }
  }

  const handleChangeSelector = (key, value) => {
    values[key] = value
    setValues(values)
  }

  const normalizeDataSelector = (messageKey) => {
    let defaultValue = ''
    let filteredOptions = []
    if (messageKey === CHATBOT.CHATBOT_KEYS.DIMENSION_KEY) {
      defaultValue = dimensionList[0].name_origin
      filteredOptions = dimensionList.map((item) => ({
        value: item.name_origin,
        label: item.name_origin,
      }))
    } else if (messageKey === CHATBOT.CHATBOT_KEYS.CONCEPT_KEY) {
      defaultValue = conceptList.values[0].name
      filteredOptions = conceptList.values.map((item) => ({
        value: item.name,
        label: item.name,
      }))
    } else if (messageKey === CHATBOT.CHATBOT_KEYS.PERIOD_KEY) {
      defaultValue = periodList[0].name
      filteredOptions = periodList.map((item) => ({
        value: item.name,
        label: item.name,
      }))
    } else if (messageKey === CHATBOT.CHATBOT_KEYS.UNIT_OF_TIME_KEY) {
      defaultValue = valuesList.unitOfTime[0].name
      filteredOptions = valuesList.unitOfTime.map((item) => ({
        value: item.name,
        label: item.name,
      }))
    } else if (messageKey === CHATBOT.CHATBOT_KEYS.UNIT_OF_AMOUNT_KEY) {
      defaultValue = valuesList.unitOfAmount[0].name
      filteredOptions = valuesList.unitOfAmount.map((item) => ({
        value: item.name,
        label: item.name,
      }))
    }

    return [defaultValue, filteredOptions]
  }

  const renderQuizQuestion = () => {
    const part_message = message.split(/(#\w+)/)
    return part_message.map((messageKey, index) => {
      if (messageKey.startsWith('#')) {
        const [defaultValue, filteredOptions] = normalizeDataSelector(messageKey)

        return (
          <Select
            key={index}
            className={'selected-add-new-message'}
            style={{ minWidth: 100 }}
            defaultValue={defaultValue}
            options={filteredOptions}
            onChange={(value) => handleChangeSelector(messageKey, value)}
          />
        )
      } else {
        return messageKey
      }
    })
  }

  return (
    <div>
      <div className="add-new-message">
        <div className={'ant-input'} onKeyDown={onKeyPress}>
          {renderQuizQuestion()}
        </div>

        <div className="add-new-message-action">
          <Button className="message-btn" onClick={onClickSendMessage}>
            <SendOutlined />
          </Button>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  valuesList: chatbot.selectors.getValuesList(state),
  conceptList: configuration.selectors.getBudgetTypeList(state),
  periodList: planning.selectors.getPeriodList(state),
  dimensionList: configuration.selectors.getAllDimensionsList(state),
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(AddMessage)
