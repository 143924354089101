import { Button, DropdownMenu } from 'modules/core/components'
import { TABLES } from 'modules/core/constants'
import { useTranslation } from 'react-i18next'
import { DownOutlined } from '@ant-design/icons'

const ButtonUploadData = ({ onClickFile, onClickSpreadsheets, disabled }) => {
  const { t } = useTranslation()

  const execute = {
    spreadsheets: onClickSpreadsheets,
    file: onClickFile,
  }
  return (
    <DropdownMenu
      title={t('ACTION_UPLOAD_FILE')}
      placement="bottomRight"
      menu={TABLES.OPTIONS_DOWNLOAD.map((formatType) => {
        return {
          title: formatType.name,
          key: formatType.id,
          disabled,
          onClick: execute[formatType.id],
        }
      })}
    >
      <Button.PrimaryGhostWhitRightIcon
        icon={<DownOutlined />}
        disabled={disabled}
        title="ACTION_UPLOAD_FILE"
      />
    </DropdownMenu>
  )
}

export default ButtonUploadData
