import React from 'react'
import { ResponsiveSankey } from '@nivo/sankey'
import { CHARTS } from 'modules/core/constants'
import ChartContainer from '../ChartContainer/ChartContainer'

const SankeyChart = ({ className = '', containerHeight, data, margin = {}, ...props }) => {
  return (
    <ChartContainer className={className} style={{ height: containerHeight }}>
      <ResponsiveSankey
        data={data}
        margin={{ top: 20, right: 20, bottom: 50, left: 60, ...margin }}
        align="justify"
        colors={CHARTS.COLORS}
        nodeOpacity={1}
        nodeHoverOthersOpacity={0.35}
        nodeThickness={18}
        nodeSpacing={24}
        nodeBorderWidth={0}
        nodeBorderColor={{
          from: 'color',
          modifiers: [['darker', 0.8]],
        }}
        nodeBorderRadius={3}
        linkOpacity={0.5}
        linkHoverOthersOpacity={0.1}
        linkContract={3}
        enableLinkGradient={true}
        labelPosition="outside"
        labelOrientation="vertical"
        labelPadding={16}
        layout="horizontal"
        labelTextColor={{
          from: 'color',
          modifiers: [['darker', 1]],
        }}
        legends={[
          {
            anchor: 'bottom-right',
            direction: 'column',
            translateX: 130,
            itemWidth: 100,
            itemHeight: 14,
            itemDirection: 'right-to-left',
            itemsSpacing: 2,
            itemTextColor: '#999',
            symbolSize: 14,
            effects: [
              {
                on: 'hover',
                style: {
                  itemTextColor: '#000',
                },
              },
            ],
          },
        ]}
        {...props}
      />
    </ChartContainer>
  )
}

export default SankeyChart
