import Immutable from 'seamless-immutable'
import { ROWS } from '../core/constants'
import * as types from './constants'

const GENERAL_DEFAULT_SETTINGS = {
  showLabel: true,
  labelFontSize: 12,
  dataSize: 5,
  labelFormat: 'value',
  showLegends: true,
}

const PIE_DEFAULT_SETTING = {
  ...GENERAL_DEFAULT_SETTINGS,
  showTotalAmount: false,
  totalFontSize: 25,
  labelPosition: 'inside',
}

const LINE_DEFAULT_SETTING = {
  ...GENERAL_DEFAULT_SETTINGS,
  enableArea: false,
  enablePoints: true,
  showLabel: false,
}

const BAR_DEFAULT_SETTING = {
  ...GENERAL_DEFAULT_SETTINGS,
  groupMode: 'stacked',
  layout: 'vertical',
  showLabel: false,
}

const SANKEY_DEFAULT_SETTING = {
  sort: 'ascending',
  layout: 'horizontal',
  labelOrientation: 'horizontal',
  labelPosition: 'inside',
  showLegends: false,
}

const INITIAL_STATE = {
  pie: PIE_DEFAULT_SETTING,
  bar: BAR_DEFAULT_SETTING,
  line: LINE_DEFAULT_SETTING,
  sankey: SANKEY_DEFAULT_SETTING,
}

const initialState = Immutable({
  reportList: {
    count: 0,
    values: [],
  },
  analysisList: {
    count: 0,
    values: [],
  },
  reportDetail: {
    columns: [],
    rows: [],
    name_report: '',
    lineId: undefined,
    startDate: undefined,
    endDate: undefined,
  },
  reportComparativeDetail: {
    columns: [],
    rows: [],
    lineId: undefined,
    startDate: undefined,
    endDate: undefined,
  },
  analysisKPIs: [],
  analysisFilters: [],
  reportStructure: {
    name: '',
    values: [],
  },
  reportDahsboardList: [],
  settingsCharts: {},
  dashboardUrl: {},
  dataEvolutionReport: {
    dataSource: [],
    fields: [],
    meta: [],
    linesOrder: [],
    linesTotal: [],
    linesRatio: [],
    linesRatioN: [],
    linesFinancial: null,
  },
  dataKpisByReport: [],
  dataGraphByReport: {
    lineId: undefined,
    columns: [],
    rows: [],
  },
  dataComparativeReport: {
    dataSource: [],
    fields: [],
    meta: [],
    linesOrder: [],
    linesTotal: [],
    linesRatio: [],
    linesRatioN: [],
  },
})

const addNewNode = (nodes, nodeId, newNode) => {
  let newNodes = []
  nodes.forEach((node) => {
    if (node.id === nodeId) {
      node = {
        ...node,
        children:
          newNode &&
          newNode.map((newNodeAux) => {
            return {
              ...newNodeAux,
              level: node.level,
              id: newNodeAux.id,
            }
          }),
      }
    }
    if (node.id !== nodeId && node.children) {
      node = {
        ...node,
        children: addNewNode(node.children, nodeId, newNode),
      }
    }
    newNodes.push(node)
  })
  return newNodes
}

/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_REPORT_LIST_SUCCESS:
      return state.merge({
        reportList: {
          count: action.payload.data.length,
          values: action.payload.data,
        },
      })

    case types.FETCH_ANALYSIS_LIST_SUCCESS:
      return state.merge({
        analysisList: {
          count: action.payload.data.length,
          values: action.payload.data,
        },
      })

    case types.FETCH_REPORT_DETAIL_SUCCESS:
      const startDate = action.meta.previousAction.payload.request.data.filter_date['start-date']
      const endDate = action.meta.previousAction.payload.request.data.filter_date['end-date']
      return state.merge({
        reportDetail: {
          columns: action.payload.data.columns,
          rows:
            action.payload.data.data.length > 0
              ? action.payload.data.data.map((row) => {
                  return {
                    ...row,
                    children: ['totalizer', 'ratio', 'ration'].includes(row.type) ? null : [],
                  }
                })
              : [],
          name_report: action.payload.data.name_report,
          lineId: undefined,
          startDate,
          endDate,
        },
        analysisKPIs: action.payload.data.cards,
      })

    case types.FETCH_REPORT_COMPARATIVE_DETAIL_SUCCESS:
      let startDateC = action.meta.previousAction.payload.request.data.filter_date['start-date']
      let endDateC = action.meta.previousAction.payload.request.data.filter_date['end-date']
      return state.merge({
        reportComparativeDetail: {
          columns: action.payload.data.columns,
          rows:
            action.payload.data.data.length > 0
              ? action.payload.data.data.map((row) => {
                  return {
                    ...row,
                    children: ['totalizer', 'ratio', 'ration'].includes(row.type) ? null : [],
                  }
                })
              : [],
          lineId: undefined,
          startDateC,
          endDateC,
        },
      })

    case types.FETCH_ANALYSIS_KPI_SUCCESS:
      return state.merge({
        analysisKPIs: action.payload.data,
      })

    case types.FETCH_ANALYSIS_FILTERS_SUCCESS:
      return state.merge({
        analysisFilters: action.payload.data,
      })

    case types.FETCH_REPORT_DETAIL_STRUCTURE_SUCCESS:
      return state.merge({
        reportStructure: {
          name: action.payload.data.name,
          values: action.payload.data.rows.map((row) => {
            return { ...row, ...(row.children.length === 0 && { children: null }) }
          }),
        },
      })

    case types.FETCH_TABLE_OPEN_ROW_SUCCESS:
      const parentId = JSON.parse(action.payload.config.data).row_id
      let auxReportTableByPeriods = state.reportDetail.rows.asMutable({
        deep: true,
      })

      return state.merge({
        reportDetail: {
          ...state.reportDetail,
          rows: addNewNode(auxReportTableByPeriods, parentId, action.payload.data),
        },
      })

    case types.SET_REPORT_LINE_SELECTED:
      return state.merge({
        reportDetail: {
          ...state.reportDetail,
          lineId: action.payload,
        },
        dataGraphByReport: {
          ...state.dataGraphByReport,
          lineId: action.payload,
        },
      })

    case types.SET_SETTINGS_CHARTS:
      return state.merge({
        settingsCharts: {
          ...state.settingsCharts,
          [action.payload.id]: {
            ...state.settingsCharts[action.payload.is],
            ...action.payload.data,
          },
        },
      })

    case types.FETCH_REPORT_DASHBOARD_LIST_SUCCESS:
      return state.merge({
        reportDahsboardList: action.payload.data,
        settingsCharts:
          action.payload.data.length > 0
            ? action.payload.data.reduce((acum, data) => {
                return {
                  ...acum,
                  [data.id]: INITIAL_STATE[data.chart_type],
                }
              }, {})
            : {},
      })

    case types.FETCH_TABLE_COMPARATIVE_OPEN_ROW_SUCCESS:
      const rowId = JSON.parse(action.payload.config.data).row_id
      let auxReportTableByPeriod = state.reportComparativeDetail.rows.asMutable({
        deep: true,
      })

      return state.merge({
        reportComparativeDetail: {
          ...state.reportComparativeDetail,
          rows: addNewNode(auxReportTableByPeriod, rowId, action.payload.data),
        },
      })

    case types.SET_REPORT_ORDER:
      return state.merge({
        analysisList: {
          ...state.analysisList,
          values: action.payload,
        },
      })

    case types.FETCH_DASHBOARD_URL_SUCCESS:
      return state.merge({
        dashboardUrl: action.payload.data.data,
      })

    case types.FETCH_DATA_EVOLUTION_BY_REPORT_SUCCESS:
      const lines = action.payload.data.totalizer ?? []
      return state.merge({
        dataEvolutionReport: {
          dataSource: action.payload.data.data,
          fields: action.payload.data.fields,
          meta: action.payload.data.meta,
          linesOrder: action.payload.data.order_columns,
          linesTotal: lines.filter((it) => it.type === ROWS.TOTALIZER),
          linesRatio: lines.filter((it) => it.type === ROWS.RATIO),
          linesRatioN: lines.filter((it) => it.type === ROWS.RATION),
          linesFinancial: action.payload.data.financial_data
            ? action.payload.data.financial_data
            : null,
        },
      })

    case types.FETCH_DATA_GRAPH_AND_KPI_BY_REPORT_SUCCESS:
      return state.merge({
        dataKpisByReport: action.payload.data.cards,
        dataGraphByReport: {
          columns: action.payload.data.columns,
          rows: action.payload.data.data,
        },
      })

    case types.FETCH_DATA_COMPARATIVE_BY_REPORT_SUCCESS:
      const linesComparative = action.payload.data.totalizer ?? []
      return state.merge({
        dataComparativeReport: {
          dataSource: action.payload.data.data,
          fields: action.payload.data.fields,
          meta: action.payload.data.meta,
          linesOrder: action.payload.data.order_columns,
          linesTotal: linesComparative.filter((it) => it.type === ROWS.TOTALIZER),
          linesRatio: linesComparative.filter((it) => it.type === ROWS.RATIO),
          linesRatioN: linesComparative.filter((it) => it.type === ROWS.RATION),
        },
      })

    default:
      return state
  }
}
