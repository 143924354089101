import { Anchor } from 'antd'

const { Link } = Anchor

const LinksHeadersText = ({ targetOffset, dataLinks }) => {
  return (
    <Anchor targetOffset={targetOffset} style={{ paddingLeft: 10, paddingTop: 10 }}>
      {dataLinks.map((data) => (
        <Link key={data.id} href={data.href} title={data.title} />
      ))}
    </Anchor>
  )
}

export default LinksHeadersText
