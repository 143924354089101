import ButtonPrimary from '../../ButtonPrimary'

const ButtonPrimaryForm = ({
  title,
  disabled = false,
  loading = false,
  onClick = () => {},
  ...props
}) => {
  return (
    <ButtonPrimary
      {...props}
      htmlType="submit"
      block
      title={title}
      disabled={disabled}
      loading={loading}
      onClick={onClick}
    />
  )
}

export default ButtonPrimaryForm
