import React, { useEffect, useState } from 'react'
import { Col, Row, Space } from 'antd'
import { Typography } from 'modules/core/components'
import { LinksHeadersText } from 'modules/SecurityPolicy/components'
import { dataLinks } from './dataLinks'
import { useTranslation } from 'react-i18next'

const TermsAndConditions = () => {
  const [targetOffset, setTargetOffset] = useState(undefined)
  const { t } = useTranslation()

  useEffect(() => {
    setTargetOffset(window.innerHeight / 2)
  }, [])

  return (
    <Row gutter={[12, 12]}>
      <Col span={6}>
        <LinksHeadersText
          targetOffset={targetOffset}
          dataLinks={dataLinks.map((el) => {
            return {
              ...el,
              title: t(el.title),
            }
          })}
        />
      </Col>
      <Col span={18}>
        <Space direction="vertical">
          <Typography.Headline level={4}>
            {t('SECURITY_POLICY_TERMS_CONDITIONS_TAB_TITLE')}
          </Typography.Headline>
          <Typography.Body>{t('SECURITY_POLICY_TERMS_TEXT_1')}</Typography.Body>
          <Typography.Body>{t('SECURITY_POLICY_TERMS_TEXT_2')}</Typography.Body>
          <Typography.Headline id="cookies" level={5}>
            {t('SECURITY_POLICY_TERMS_SUBTITLE_1')}:
          </Typography.Headline>
          <Typography.Body>{t('SECURITY_POLICY_TERMS_TEXT_3')}</Typography.Body>
          <Typography.Headline id="licencia" level={5}>
            {t('SECURITY_POLICY_TERMS_SUBTITLE_2')}:
          </Typography.Headline>
          <Typography.Body>{t('SECURITY_POLICY_TERMS_TEXT_4')}</Typography.Body>
          <Typography.Body>{t('SECURITY_POLICY_TERMS_TEXT_5')}</Typography.Body>
          <ul>
            <li>
              <Typography.Body>{t('SECURITY_POLICY_TERMS_LIST_MUST_TEXT_1')}</Typography.Body>
            </li>
            <li>
              <Typography.Body>{t('SECURITY_POLICY_TERMS_LIST_MUST_TEXT_2')}</Typography.Body>
            </li>
            <li>
              <Typography.Body>{t('SECURITY_POLICY_TERMS_LIST_MUST_TEXT_3')}</Typography.Body>
            </li>
            <li>
              <Typography.Body>{t('SECURITY_POLICY_TERMS_LIST_MUST_TEXT_4')}</Typography.Body>
            </li>
          </ul>
          <Typography.Body>{t('SECURITY_POLICY_TERMS_TEXT_6')}</Typography.Body>
          <Typography.Body>{t('SECURITY_POLICY_TERMS_TEXT_7')}</Typography.Body>
          <Typography.Body>{t('SECURITY_POLICY_TERMS_TEXT_8')}</Typography.Body>
          <Typography.Body>{t('SECURITY_POLICY_TERMS_TEXT_9')}</Typography.Body>
          <ul>
            <li>
              <Typography.Body>{t('SECURITY_POLICY_TERMS_LIST_REPRESENT_TEXT_1')}</Typography.Body>
            </li>
            <li>
              <Typography.Body>{t('SECURITY_POLICY_TERMS_LIST_REPRESENT_TEXT_2')}</Typography.Body>
            </li>
            <li>
              <Typography.Body>{t('SECURITY_POLICY_TERMS_LIST_REPRESENT_TEXT_3')}</Typography.Body>
            </li>
            <li>
              <Typography.Body>{t('SECURITY_POLICY_TERMS_LIST_REPRESENT_TEXT_4')}</Typography.Body>
            </li>
          </ul>
          <Typography.Body>{t('SECURITY_POLICY_TERMS_TEXT_10')}</Typography.Body>
          <Typography.Headline id="hipervinculos" level={5}>
            {t('SECURITY_POLICY_TERMS_SUBTITLE_3')}:
          </Typography.Headline>
          <Typography.Body>{t('SECURITY_POLICY_TERMS_TEXT_11')}</Typography.Body>
          <ul>
            <li>
              <Typography.Body>{t('SECURITY_POLICY_TERMS_LIST_HIPERLINK_TEXT_1')}</Typography.Body>
            </li>
            <li>
              <Typography.Body>{t('SECURITY_POLICY_TERMS_LIST_HIPERLINK_TEXT_2')}</Typography.Body>
            </li>
            <li>
              <Typography.Body>{t('SECURITY_POLICY_TERMS_LIST_HIPERLINK_TEXT_3')}</Typography.Body>
            </li>
            <li>
              <Typography.Body>{t('SECURITY_POLICY_TERMS_LIST_HIPERLINK_TEXT_4')}</Typography.Body>
            </li>
            <li>
              <Typography.Body>{t('SECURITY_POLICY_TERMS_LIST_HIPERLINK_TEXT_5')}</Typography.Body>
            </li>
          </ul>
          <Typography.Body>{t('SECURITY_POLICY_TERMS_TEXT_12')}</Typography.Body>
          <Typography.Body>{t('SECURITY_POLICY_TERMS_TEXT_13')}</Typography.Body>
          <ul>
            <li>
              <Typography.Body>{t('SECURITY_POLICY_TERMS_LIST_HIPERLINK_TEXT_6')}</Typography.Body>
            </li>
            <li>
              <Typography.Body>{t('SECURITY_POLICY_TERMS_LIST_HIPERLINK_TEXT_7')}</Typography.Body>
            </li>
            <li>
              <Typography.Body>{t('SECURITY_POLICY_TERMS_LIST_HIPERLINK_TEXT_8')}</Typography.Body>
            </li>
            <li>
              <Typography.Body>{t('SECURITY_POLICY_TERMS_LIST_HIPERLINK_TEXT_9')}</Typography.Body>
            </li>
            <li>
              <Typography.Body>{t('SECURITY_POLICY_TERMS_LIST_HIPERLINK_TEXT_10')}</Typography.Body>
            </li>
            <li>
              <Typography.Body>{t('SECURITY_POLICY_TERMS_LIST_HIPERLINK_TEXT_11')}</Typography.Body>
            </li>
            <li>
              <Typography.Body>{t('SECURITY_POLICY_TERMS_LIST_HIPERLINK_TEXT_12')}</Typography.Body>
            </li>
          </ul>
          <Typography.Body>{t('SECURITY_POLICY_TERMS_TEXT_14')}</Typography.Body>
          <Typography.Body>{t('SECURITY_POLICY_TERMS_TEXT_15')}</Typography.Body>
          <Typography.Body>{t('SECURITY_POLICY_TERMS_TEXT_16')}</Typography.Body>
          <Typography.Body>{t('SECURITY_POLICY_TERMS_TEXT_17')}</Typography.Body>
          <ul>
            <li>
              <Typography.Body>{t('SECURITY_POLICY_TERMS_LIST_HIPERLINK_TEXT_13')}</Typography.Body>
            </li>
            <li>
              <Typography.Body>{t('SECURITY_POLICY_TERMS_LIST_HIPERLINK_TEXT_14')}</Typography.Body>
            </li>
            <li>
              <Typography.Body>{t('SECURITY_POLICY_TERMS_LIST_HIPERLINK_TEXT_15')}</Typography.Body>
            </li>
          </ul>
          <Typography.Body>{t('SECURITY_POLICY_TERMS_TEXT_18')}</Typography.Body>
          <Typography.Headline id="responsabilidad-contenido" level={5}>
            {t('SECURITY_POLICY_TERMS_SUBTITLE_4')}:
          </Typography.Headline>
          <Typography.Body>{t('SECURITY_POLICY_TERMS_TEXT_19')}</Typography.Body>
          <Typography.Headline id="reserva-derechos" level={5}>
            {t('SECURITY_POLICY_TERMS_SUBTITLE_5')}:
          </Typography.Headline>
          <Typography.Body>{t('SECURITY_POLICY_TERMS_TEXT_20')}</Typography.Body>
          <Typography.Headline id="eliminacion-sitio" level={5}>
            {t('SECURITY_POLICY_TERMS_SUBTITLE_6')}:
          </Typography.Headline>
          <Typography.Body>{t('SECURITY_POLICY_TERMS_TEXT_21')}</Typography.Body>
          <Typography.Body>{t('SECURITY_POLICY_TERMS_TEXT_22')}</Typography.Body>
          <Typography.Headline id="extencion-responsabilidad" level={5}>
            {t('SECURITY_POLICY_TERMS_SUBTITLE_7')}:
          </Typography.Headline>
          <Typography.Body>{t('SECURITY_POLICY_TERMS_TEXT_23')}</Typography.Body>
          <ul>
            <li>
              <Typography.Body>
                {t('SECURITY_POLICY_TERMS_LIST_RESPONSIBILITIES_TEXT_1')}
              </Typography.Body>
            </li>
            <li>
              <Typography.Body>
                {t('SECURITY_POLICY_TERMS_LIST_RESPONSIBILITIES_TEXT_2')}
              </Typography.Body>
            </li>
            <li>
              <Typography.Body>
                {t('SECURITY_POLICY_TERMS_LIST_RESPONSIBILITIES_TEXT_3')}
              </Typography.Body>
            </li>
            <li>
              <Typography.Body>
                {t('SECURITY_POLICY_TERMS_LIST_RESPONSIBILITIES_TEXT_4')}
              </Typography.Body>
            </li>
          </ul>
          <Typography.Body>{t('SECURITY_POLICY_TERMS_TEXT_24')}</Typography.Body>
          <Typography.Body>{t('SECURITY_POLICY_TERMS_TEXT_25')}</Typography.Body>
        </Space>
      </Col>
    </Row>
  )
}

export default TermsAndConditions
