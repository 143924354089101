import { useFeatureIsOn, useFeatureValue } from '@growthbook/growthbook-react'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { SiderLayout } from 'modules/core/layouts'
import { Spin, Row, Col, Alert } from 'antd'
import { PeriodNavigator, EmptyScreen, Button } from 'modules/core/components'
import { CreateForecastModal, ForecastPeriodList, UserActions } from './components'
import { isInElaboration, isUserAllowed, sendUserDataToGTM } from 'modules/core/utils'
import { ROLES } from 'modules/core/constants'
import emptyStateImage from 'assets/images/compositions/empty-big.svg'
import forecast from 'modules/forecast'
import planning from 'modules/planning'
import login from 'modules/login'
import moment from 'moment'
import configuration from 'modules/configuration'

const {
  FORECAST__SALES__BASE__ABM,
  FORECAST__SALES__TOP_DOWN__ABM,
  FORECAST__SALES__BOTTOM_UP__ABM,
  FORECAST__SALES__CLOSING__ABM,
  FORECAST__EXPENSES__BASE__ABM,
  FORECAST__EXPENSES__TOP_DOWN__ABM,
  FORECAST__EXPENSES__BOTTOM_UP__ABM,
  FORECAST__EXPENSES__CLOSING__ABM,
  FORECAST__COSTS__BASE__ABM,
  FORECAST__COSTS__TOP_DOWN__ABM,
  FORECAST__COSTS__BOTTOM_UP__ABM,
  FORECAST__COSTS__CLOSING__ABM,
  FORECAST__HUMAN_RESOURCES__BASE__ABM,
  FORECAST__HUMAN_RESOURCES__TOP_DOWN__ABM,
  FORECAST__HUMAN_RESOURCES__BOTTOM_UP__ABM,
  FORECAST__HUMAN_RESOURCES__CLOSING__ABM,
  FORECAST__GLOBAL_BUDGET__ABM,
} = ROLES

const ForecastInitialScreen = ({
  forecasts,
  periodList,
  fetchForecasts,
  cutDates,
  fetchCutDates,
  fetchGlobalBudgetList,
  fetchGlobalForecastPublishApprove,
  globalBudgetList,
  globalForecastPublishApprove,
  loggedUser,
  fetchCommentsList,
  commentsList,
}) => {
  const actionCrudByRole = useFeatureValue('feature-action-crud-role', { forecast: 'True' })
  const enableDatesSamePeriod = useFeatureIsOn('dates-same-period')
  const [isScreenLoading, setIsScreenLoading] = useState(true)
  const [showCreateModal, setShowCreateModal] = useState(false)
  const [dataComments, setDataComments] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  let { periodId } = useParams()
  const { t } = useTranslation()
  let history = useHistory()

  const key = window.location.pathname.replaceAll('/', '-')

  useEffect(() => {
    setIsLoading(true)
    fetchCommentsList({ module: key }).then(() => setIsLoading(false))
  }, [fetchCommentsList, key])

  useEffect(() => {
    setDataComments(commentsList)
  }, [commentsList])

  useEffect(() => {
    Promise.all([fetchForecasts({ period_id: periodId }), fetchCutDates()]).then(() =>
      setIsScreenLoading(false),
    )
  }, [fetchForecasts, fetchCutDates, periodId])

  useEffect(() => {
    Promise.all([
      fetchGlobalForecastPublishApprove(),
      fetchGlobalBudgetList({ period_id: periodId }),
    ])
  }, [fetchGlobalBudgetList, fetchGlobalForecastPublishApprove, periodId])

  useEffect(() => {
    sendUserDataToGTM(loggedUser, '/forecast')
  }, [loggedUser])

  const getUserCanCreate = () => {
    const canCreate = isUserAllowed([
      FORECAST__SALES__BASE__ABM,
      FORECAST__SALES__TOP_DOWN__ABM,
      FORECAST__SALES__BOTTOM_UP__ABM,
      FORECAST__SALES__CLOSING__ABM,
      FORECAST__EXPENSES__BASE__ABM,
      FORECAST__EXPENSES__TOP_DOWN__ABM,
      FORECAST__EXPENSES__BOTTOM_UP__ABM,
      FORECAST__EXPENSES__CLOSING__ABM,
      FORECAST__COSTS__BASE__ABM,
      FORECAST__COSTS__TOP_DOWN__ABM,
      FORECAST__COSTS__BOTTOM_UP__ABM,
      FORECAST__COSTS__CLOSING__ABM,
      FORECAST__HUMAN_RESOURCES__BASE__ABM,
      FORECAST__HUMAN_RESOURCES__TOP_DOWN__ABM,
      FORECAST__HUMAN_RESOURCES__BOTTOM_UP__ABM,
      FORECAST__HUMAN_RESOURCES__CLOSING__ABM,
      FORECAST__GLOBAL_BUDGET__ABM,
    ])

    if (canCreate) {
      return actionCrudByRole.forecast === 'True'
    }
    return canCreate
  }

  const userCanCreate = getUserCanCreate()

  const dataPeriod = periodList.find((el) => el.id === parseInt(periodId))
  const disabledMonths = forecasts?.values.map((forecast) =>
    moment(forecast.year_month).utc().format('YYYY-M'),
  )
  const notPublishedApprovedForecast = isInElaboration(
    forecasts?.values[forecasts?.values.length - 1]?.status,
  )
  const publishedApproveGlobalBudget = globalBudgetList?.values.filter(
    (global) => !isInElaboration(global.status),
  )
  const filterCutDate = cutDates.filter((date) => !disabledMonths.includes(date))
  const disabledButtonCreate =
    !userCanCreate ||
    filterCutDate.length === 0 ||
    [...globalForecastPublishApprove, ...publishedApproveGlobalBudget].length === 0 ||
    notPublishedApprovedForecast
  const filterDatesCurrentYear =
    filterCutDate.length > 0
      ? filterCutDate.filter((el) => el.includes(moment(dataPeriod?.end_date).utc().format('YYYY')))
      : []

  const renderTooltip = () => {
    if (filterCutDate.length === 0)
      return t('FORECAST_NEED_NEW_PUBLISHED_DATE_INFORMATIVE_TOOLTIP_TITLE')
    if ([...globalForecastPublishApprove, ...publishedApproveGlobalBudget].length === 0)
      return t('FORECAST_NEED_GLOBAL_BUDGET_FORECAST_PUBLISHED_APPROVED_INFORMATIVE_TOOLTIP_TITLE')
    if (notPublishedApprovedForecast)
      return t(
        'FORECAST_NEED_LAST_CUT_DATE_WITH_GLOBAL_FORECAST_PUBLISHED_APPROVED_INFORMATIVE_TOOLTIP_TITLE',
      )
    return ''
  }

  const renderTopAlert = () => (
    <Alert
      showIcon
      closable
      type="warning"
      message={t('FORECAST_NO_DATA_ALERT_TITLE')}
      description={t('FORECAST_NO_DATA_ALERT_TEXT')}
    />
  )

  const renderNoDataScreen = () => (
    <EmptyScreen
      image={emptyStateImage}
      title={t('LABEL_FORECAST')}
      description={t('FORECAST_EMPTY_STATE_TEXT')}
      footer={
        <Button.Primary
          onClick={() => setShowCreateModal(true)}
          disabled={filterCutDate.length === 0 || publishedApproveGlobalBudget.length === 0}
          title="FORECAST_CREATE_ACTION"
          tooltipTitle={
            filterCutDate.length === 0 || publishedApproveGlobalBudget.length === 0
              ? t('FORECAST_NEED_PUBLISHED_DATE_GLOBAL_BUDGET_INFORMATIVE_TOOLTIP_TITLE')
              : ''
          }
        />
      }
    />
  )

  const renderHeader = () => <PeriodNavigator items={[{ name: t('LABEL_FORECAST') }]} />

  const renderCreateButton = () => (
    <UserActions
      onClick={() => setShowCreateModal(true)}
      disabled={disabledButtonCreate}
      tooltopTitle={renderTooltip()}
      onClickActivity={() =>
        history.push(`/forecast/${periodId}/general/actividad?type=forecast&module=forecastGeneral`)
      }
    />
  )

  const renderCutDates = () => {
    if (enableDatesSamePeriod) {
      return filterDatesCurrentYear
    }
    return filterCutDate
  }

  return (
    <SiderLayout>
      <Spin spinning={isScreenLoading} size="large" />
      {!isScreenLoading && renderHeader()}
      {!isScreenLoading && forecasts.count <= 0 && (
        <Row gutter={[24, 24]}>
          {(filterCutDate.length === 0 || publishedApproveGlobalBudget.length === 0) && (
            <Col span={24}> {renderTopAlert()}</Col>
          )}
          <Col span={24}>{renderNoDataScreen()}</Col>
        </Row>
      )}
      {!isScreenLoading && forecasts.count > 0 && (
        <>
          <Row gutter={[24, 24]}>
            <Col span={24}>{renderCreateButton()}</Col>
            <Col span={24}>
              <ForecastPeriodList
                dataSource={forecasts.values}
                userCanCreate={userCanCreate}
                fetchForecasts={() => fetchForecasts({ period_id: periodId })}
                setDataComments={setDataComments}
                comments={dataComments}
                onRefetchComments={() => fetchCommentsList({ module: key })}
                loading={isLoading}
              />
            </Col>
          </Row>
        </>
      )}
      <CreateForecastModal
        visible={showCreateModal}
        onCancel={() => setShowCreateModal(false)}
        actualPeriod={dataPeriod}
        onRefetch={() => fetchForecasts({ period_id: periodId })}
        cutDates={renderCutDates()}
        disabledMonths={disabledMonths}
        optionsSelect={[...globalForecastPublishApprove, ...publishedApproveGlobalBudget]}
      />
    </SiderLayout>
  )
}

const mapStateToProps = (state) => ({
  forecasts: forecast.selectors.getForecasts(state),
  periodList: planning.selectors.getPeriodList(state),
  cutDates: forecast.selectors.getCutDates(state),
  globalBudgetList: planning.selectors.getGlobalBudgetList(state),
  globalForecastPublishApprove: forecast.selectors.getGlobalForecastPublishApprove(state),
  loggedUser: login.selectors.getWhoAmI(state),
  commentsList: configuration.selectors.getCommentsList(state),
})

const mapDispatchToProps = {
  fetchForecasts: forecast.actions.fetchForecasts,
  fetchCutDates: forecast.actions.fetchCutDates,
  fetchGlobalBudgetList: planning.actions.fetchGlobalBudgetList,
  fetchGlobalForecastPublishApprove: forecast.actions.fetchGlobalForecastPublishApprove,
  fetchCommentsList: configuration.actions.fetchCommentsList,
  // deleteForecast: forecast.actions.deleteForecast,
}

export default connect(mapStateToProps, mapDispatchToProps)(ForecastInitialScreen)
