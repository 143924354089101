import { UploadOutlined } from '@ant-design/icons'
import { Col, Form, Modal, Radio, Row, Space, Upload } from 'antd'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Button } from '../../../index'
import { onCreate } from '../utils'

const CustomBottomModal = ({
  showModal,
  setShowModal,
  currentAction,
  setCurrentAction,
  handleExecute,
  extraButtons,
}) => {
  const [form] = Form.useForm()
  const [isSaving, setIsSaving] = useState(false)
  const { t } = useTranslation()
  const [fileList, setFileList] = useState([])

  const onClose = () => {
    form.resetFields()
    setShowModal(false)
    setCurrentAction(null)
    setFileList([])
  }

  const onAddUploadFile = (file) => {
    setFileList([file])
    return false
  }

  const items = currentAction?.function?.metadata['items'] || []
  const uploadProps = {
    name: 'file',
    accept: '.xls, .xlsx, .csv',
    showUploadList: { showPreviewIcon: false },
    beforeUpload: onAddUploadFile,
    onRemove: (file) => setFileList([]),
    fileList: fileList,
  }

  return (
    <>
      <Modal
        title={t('ACTION_MORE')}
        visible={showModal}
        width={560}
        bodyStyle={{ overflowY: 'auto', maxHeight: 400 }}
        centered
        okText={t('ACTION_CREATE')}
        cancelText={t('ACTION_CANCEL')}
        onOk={() =>
          onCreate({
            form,
            setIsLoading: setIsSaving,
            onSubmit: (formData) =>
              handleExecute(currentAction.function_call.slice(6), fileList, {
                ...formData,
                ...extraButtons,
              }),
            onClose,
          })
        }
        onCancel={onClose}
        okButtonProps={{ loading: isSaving }}
        cancelButtonProps={{ disabled: isSaving }}
      >
        <Form form={form} layout="vertical">
          <Row gutter={24} type="flex" justify="end">
            <Col span={24}>
              <Form.Item name="action" rules={[{ required: true, message: t('REQUIRED_FIELD') }]}>
                <Radio.Group>
                  <Space direction="vertical">
                    {items.map((it) => (
                      <Radio value={it.id_field} key={it.name_field}>
                        {it.name_field}
                      </Radio>
                    ))}
                  </Space>
                </Radio.Group>
              </Form.Item>
              {currentAction?.is_upload_file && (
                <Form.Item
                  label={t('ACTION_UPLOAD_FILE')}
                  name="file_upload"
                  rules={[{ required: true, message: t('REQUIRED_FIELD') }]}
                >
                  <Upload {...uploadProps}>
                    {uploadProps.fileList.length <= 0 && (
                      <Button.DefaultWhitILeftIcon
                        icon={<UploadOutlined />}
                        title="UPLOAD_FILE_PLACEHOLDER"
                      />
                    )}
                  </Upload>
                </Form.Item>
              )}
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  )
}

const mapDispatchToProps = {}

export default connect(null, mapDispatchToProps)(CustomBottomModal)
