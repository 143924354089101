import { CellStyle } from 'modules/core/components'
import { COLUMNS, REPORT, ROWS } from 'modules/core/constants'
import { columnPadding, columnsHelper, dateMMYYYY } from 'modules/core/utils'
import React from 'react'

const columnAccumulativeComparative = ({ columns, onResize, cellFormat }) => {
  return columns.map((column, index) => {
    const isTransaction = column.hasOwnProperty('is_transaction') ? column.is_transaction : false
    const isVariation = column.hasOwnProperty('is_variation') ? column.is_variation : false
    return {
      key: index,
      ellipsis: true,
      title: column.title,
      dataIndex: column.data_index,
      ...(onResize && columnsHelper.renderOnHeaderResize(onResize)),
      ...(!isTransaction &&
        !isVariation && {
          width: COLUMNS.WIDTH.EXPANDABLE,
          render: (value, row) => columnPadding(value, row.level, row.type),
        }),
      ...(isTransaction && {
        ...columnsHelper.renderTransaction({
          width: COLUMNS.WIDTH.TRANSACTION,
          format: cellFormat.format,
          showNegativeInRed: cellFormat.showNegativeInRed,
        }),
      }),
      ...(isVariation && {
        ...columnsHelper.renderVariation(),
      }),
    }
  })
}

const renderChildrenTransaction = ({
  width,
  format,
  showNegativeInRed,
  parent,
  reference,
} = {}) => {
  return {
    className: COLUMNS.TYPES.TRANSACTION,
    align: 'right',
    width: width,
    sorter: false,
    render: (value, record) => {
      let newValue = (value && value[parent] && value[parent][reference]) || 0
      return (
        <CellStyle.Number
          value={columnsHelper.renderAuxValueReview(newValue)}
          format={record.type === ROWS.RATIO ? '%' : format}
          showNegativeInRed={showNegativeInRed}
          type={record.type}
        />
      )
    },
  }
}

const renderChildrenVariation = ({ parent, reference }) => {
  return {
    width: COLUMNS.WIDTH.VARIATION_PERCENTAGE,
    align: 'right',
    sorter: false,
    render: (value, record) => {
      let newValue = (value && value[parent] && value[parent][reference]) || 0
      return <CellStyle.VariationTable value={newValue} amount={newValue} />
    },
  }
}

const renderChildrenColumnMonthly = ({ parent, childrens, onResize, cellFormat }) => {
  return {
    children: childrens.map((children) => {
      const isTransaction = children.hasOwnProperty('is_transaction')
        ? children.is_transaction
        : false
      const isVariation = children.hasOwnProperty('is_variation') ? children.is_variation : false
      return {
        ...children,
        align: 'right',
        width: COLUMNS.WIDTH.TRANSACTION,
        ...(onResize && columnsHelper.renderOnHeaderResize(onResize)),
        ...(isTransaction && {
          ...renderChildrenTransaction({
            width: COLUMNS.WIDTH.TRANSACTION,
            format: cellFormat.format,
            showNegativeInRed: cellFormat.showNegativeInRed,
            parent,
            reference: children.data_index,
          }),
        }),
        ...(isVariation && {
          ...renderChildrenVariation({ parent, reference: children.data_index }),
        }),
      }
    }),
  }
}

const renderColumnMonthly = ({ column, onResize, cellFormat }) => {
  const isTransaction = column.hasOwnProperty('is_transaction') ? column.is_transaction : false
  const isVariation = column.hasOwnProperty('is_variation') ? column.is_variation : false
  return {
    title: column.title !== null ? dateMMYYYY(column.title, 'YYYY-MM') : column.title,
    width: COLUMNS.WIDTH.TRANSACTION,
    sorter: false,
    className: COLUMNS.TYPES.TRANSACTION,
    ...(column.childrens &&
      renderChildrenColumnMonthly({
        parent: column.data_index,
        childrens: column.childrens,
        onResize,
        cellFormat,
      })),
    ...(isTransaction && {
      ...columnsHelper.renderTransaction({
        width: COLUMNS.WIDTH.TRANSACTION,
        format: cellFormat.format,
        showNegativeInRed: cellFormat.showNegativeInRed,
      }),
    }),
    ...(isVariation && {
      ...columnsHelper.renderVariation(),
    }),
  }
}

const columnMonthlyComparative = ({ columns, onResize, cellFormat }) => {
  return columns.map((column, index) => {
    const isTransaction = column.hasOwnProperty('is_transaction') ? column.is_transaction : false
    return {
      key: index,
      ellipsis: true,
      title: column.title,
      dataIndex: column.data_index,
      ...(!isTransaction && {
        width: COLUMNS.WIDTH.EXPANDABLE,
        render: (value, row) => columnPadding(value, row.level, row.type),
      }),
      ...(onResize && columnsHelper.renderOnHeaderResize(onResize)),
      ...(isTransaction && renderColumnMonthly({ column, onResize, cellFormat })),
    }
  })
}

export const generatorComparativeColumns = ({
  periodTableColumns,
  setPeriodTableColumns,
  periodTableSettings,
  tableTypeKey,
}) => {
  const params = {
    columns: periodTableColumns,
    onResize: setPeriodTableColumns,
    cellFormat: {
      format: periodTableSettings.format,
      showNegativeInRed: periodTableSettings.showNegativeInRed,
    },
  }
  return tableTypeKey === REPORT.REPORTS_KEYS.ACCUMULATED
    ? columnAccumulativeComparative(params)
    : columnMonthlyComparative(params)
}

export const onExpandByPeriod = ({
  expanded,
  record,
  setIsLoading,
  fetchTableOpenRow,
  setExpandedByPeriodRow,
}) => {
  setIsLoading(true)
  if (expanded) {
    fetchTableOpenRow().then(() => {
      setIsLoading(false)
      setExpandedByPeriodRow((prevExpanded) => [...prevExpanded, record.id])
    })
  } else {
    setExpandedByPeriodRow((prevExpanded) => prevExpanded.filter((item) => item !== record.id))
    setIsLoading(false)
  }
}
