import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { usePagination } from 'modules/core/customHooks'
import { Space, Row, Col, Spin, Tooltip, Card } from 'antd'
import {
  EmptyScreen,
  Button,
  CreateRuleModal,
  CreateVariableModal,
  Typography,
} from 'modules/core/components'
import {
  InformativeRuleModal,
  RulesTable,
  TableTopActions,
  UserActions,
  VariablesList,
} from './components'
import { isUserAllowed } from 'modules/core/utils'
import { ROLES } from 'modules/core/constants'
import configuration from 'modules/configuration'
import emptyStateImage from 'assets/images/compositions/empty-big.svg'

import './RulesList.scss'

const {
  SETTINGS__PROJECTION_ELEMENTS__BUSINESS_RULES__ABM,
  SETTINGS__PROJECTION_ELEMENTS__VARIABLES__ABM,
} = ROLES

const RulesList = ({
  fetchRulesList,
  rulesList,
  variablesList,
  fetchVariablesList,
  fetchAllDimensions,
  setRowsDimensions,
}) => {
  const { t } = useTranslation()
  const [isScreenLoading, setIsScreenLoading] = useState(true)
  const [isVariableLoading, setIsVariableLoading] = useState(false)
  const [showCreateModal, setShowCreateModal] = useState(false)
  const [showInformativeModal, setShowInformativeModal] = useState(false)
  const [searchValue, setSearchValue] = useState(null)
  const [searchVariableValue, setSearchVariableValue] = useState(null)
  const [isTableLoading, setIsTableLoading] = useState(true)
  const [tablePaginationRules, setTablePaginationRule] = usePagination()
  const [tablePaginationVars, setTablePaginationVArs] = usePagination()
  const [showVariablesDrawer, setShowVariablesDrawer] = useState(false)
  const [showVariableModal, setShowVariableModal] = useState(false)
  const [dataSelection, setDataSelection] = useState([])
  const [isDimensionsLoading, setIsDimensionsLoading] = useState(false)

  useEffect(() => {
    setIsDimensionsLoading(true)
    fetchAllDimensions().then(() => {
      setRowsDimensions()
      setIsDimensionsLoading(false)
    })
    // eslint-disable-next-line
  }, [fetchAllDimensions])

  useEffect(() => {
    setIsTableLoading(true)
    fetchRulesList({
      search: searchValue,
      ...tablePaginationRules,
    }).then(() => {
      setIsScreenLoading(false)
      setIsTableLoading(false)
    })
  }, [fetchRulesList, tablePaginationRules, searchValue])

  useEffect(() => {
    setIsVariableLoading(true)
    fetchVariablesList({ search: searchVariableValue, ...tablePaginationVars }).then(() =>
      setIsVariableLoading(false),
    )
  }, [fetchVariablesList, tablePaginationVars, searchVariableValue])

  const userCanCreate = isUserAllowed(SETTINGS__PROJECTION_ELEMENTS__BUSINESS_RULES__ABM)

  const userCanCreateVariable = isUserAllowed(SETTINGS__PROJECTION_ELEMENTS__VARIABLES__ABM)

  const renderNoDataScreen = () => (
    <EmptyScreen
      image={emptyStateImage}
      title={t('CONFIG_FORMULAS_EMPTY_SCREEN_TITLE')}
      description={t('CONFIG_FORMULAS_EMPTY_SCREEN_DESCRIPTION')}
      footer={
        <Space direction="horizontal">
          <Button.Default
            title="CONFIG_VAR_CREATE_MODAL_TITLE"
            disabled={!userCanCreateVariable}
            onClick={() => setShowVariableModal(true)}
          />
          <Tooltip
            title={
              variablesList?.values.length === 0
                ? t('CONFIG_RULES_NOT_CREATE_INFORMATIVE_TOOLTIP_TITLE')
                : ''
            }
          >
            <Button.Primary
              onClick={() => setShowInformativeModal(true)}
              disabled={!userCanCreate || variablesList?.values.length === 0}
              title="CONFIG_FORMULAS_CREATE_ACTION"
            />
          </Tooltip>
        </Space>
      }
    />
  )

  const renderTopActions = () => (
    <UserActions
      onSearch={(search) => setSearchValue(search)}
      disabledCreate={!userCanCreate}
      onClickCreate={() => setShowCreateModal(true)}
      onClickVariables={() => setShowVariablesDrawer(true)}
    />
  )

  const renderTable = () => (
    <RulesTable
      loading={isTableLoading}
      count={rulesList.count}
      dataSource={rulesList.values}
      onChange={setTablePaginationRule}
      tablePagination={tablePaginationRules}
      onFetchRulesList={() =>
        fetchRulesList({
          search: searchValue,
          ...tablePaginationRules,
        })
      }
      dataSelection={dataSelection}
      setDataSelection={setDataSelection}
      canDelete={userCanCreate}
      variablesList={variablesList?.values}
    />
  )

  const renderTopTableActions = () => <TableTopActions dataSelection={dataSelection} />

  const renderKpis = () => (
    <Row gutter={[24, 24]}>
      <>
        {isTableLoading &&
          [1].map((index) => (
            <Col span={4} key={index}>
              <Card loading={true} />
            </Col>
          ))}
        {!isTableLoading && (
          <Col span={4}>
            <Card bodyStyle={{ backgroundColor: '#e1effa' }}>
              <div style={{ textAlign: 'center' }}>
                <Typography.Headline level={3}>{rulesList.count}</Typography.Headline>
              </div>
              <div style={{ textAlign: 'center' }}>
                <Typography.Body level={3} type="secondary">
                  {t('CONFIG_RULES_TOTAL_RULES')}
                </Typography.Body>
              </div>
            </Card>
          </Col>
        )}
      </>
    </Row>
  )

  return (
    <Space className="formulas-container" direction="vertical" size="small">
      <Spin spinning={isScreenLoading} />
      {!isScreenLoading &&
        rulesList.count <= 0 &&
        !searchValue &&
        !isTableLoading &&
        renderNoDataScreen()}
      {!isScreenLoading && (rulesList.count > 0 || searchValue) && (
        <Row gutter={[8, 8]}>
          <Col span={24}>{renderTopActions()}</Col>
          <Col span={24}>{renderKpis()}</Col>
          <Col span={24}>{renderTopTableActions()}</Col>
          <Col span={24}>{renderTable()}</Col>
        </Row>
      )}
      <VariablesList
        visible={showVariablesDrawer}
        onCancel={() => setShowVariablesDrawer(false)}
        loading={isVariableLoading}
        variablesList={variablesList}
        pagination={tablePaginationVars}
        onChange={setTablePaginationVArs}
        onRefetch={() =>
          fetchVariablesList({ search: searchVariableValue, ...tablePaginationVars })
        }
        setSearchVariableValue={setSearchVariableValue}
      />
      <InformativeRuleModal
        visible={showInformativeModal && rulesList.count <= 0}
        onCancel={() => setShowInformativeModal(false)}
        onConfirm={() => {
          setShowInformativeModal(false)
          setShowCreateModal(true)
        }}
      />
      <CreateRuleModal
        visible={showCreateModal}
        onCancel={() => setShowCreateModal(false)}
        onRefetch={() => fetchRulesList({ search: searchValue, ...tablePaginationRules })}
        variablesList={variablesList}
        loading={isDimensionsLoading}
      />
      <CreateVariableModal
        visible={showVariableModal}
        onCancel={() => setShowVariableModal(false)}
        onRefetchVariablesList={() =>
          fetchVariablesList({ search: searchVariableValue, ...tablePaginationVars })
        }
      />
    </Space>
  )
}

const mapStateToProps = (state) => ({
  rulesList: configuration.selectors.getRulesList(state),
  variablesList: configuration.selectors.getVariablesList(state),
})

const mapDispatchToProps = {
  fetchRulesList: configuration.actions.fetchRulesList,
  fetchVariablesList: configuration.actions.fetchVariablesList,
  fetchAllDimensions: configuration.actions.fetchAllDimensions,
  setRowsDimensions: configuration.actions.setRowsDimensions,
}

export default connect(mapStateToProps, mapDispatchToProps)(RulesList)
