import { categoricalColorSchemes } from '@nivo/colors'
import { toJpeg, toPng } from 'html-to-image'

const CHART_KEY = 'chart'
const TABLE_KEY = 'table'

const PIE = 'pie'
const BAR = 'bar'
const LINE = 'line'
const SANKEY = 'sankey'

const TYPES = {
  PIE: PIE,
  BAR: BAR,
  LINE: LINE,
  SANKEY: SANKEY,
}

const VALUE = 'value'
const NAME_VALUE = 'name_value'
const NAME = 'name'
const LABEL_FORMAT = {
  NAME,
  VALUE,
  NAME_VALUE,
}
const LABEL_FORMAT_LIST = [VALUE, NAME_VALUE, NAME]

const POSITION_OUTER = 'outside'
const POSITION_INNER = 'inside'
const LABEL_POSITION = [POSITION_OUTER, POSITION_INNER]

const VALUE_PERCENTAGE = 'percentage'
const VALUE_AMOUNT = 'amount'
const VALUE_FORMAT = {
  PERCENTAGE: VALUE_PERCENTAGE,
  AMOUNT: VALUE_AMOUNT,
}
const VALUE_FORMAT_LIST = [VALUE_PERCENTAGE, VALUE_AMOUNT]

const DISPLAY_OPTIONS = [
  {
    id: 'horizontal',
    name: 'Horizontal',
  },
  {
    id: 'vertical',
    name: 'Vertical',
  },
]

const SORT_OPTIONS = [
  {
    id: 'ascending',
    name: 'CHART_UPWARD_SETTINGS',
  },
  {
    id: 'descending',
    name: 'CHART_FALLING_SETTINGS',
  },
]

const MODE_GROUPED = 'grouped'
const MODE_STACKED = 'stacked'
const BAR_GROUP_MODE = [MODE_GROUPED, MODE_STACKED]

const SETTINGS = {
  LABEL_FORMAT_LIST: LABEL_FORMAT_LIST,
  POSITION_INNER: POSITION_INNER,
  POSITION_OUTER: POSITION_OUTER,
  LABEL_POSITION: LABEL_POSITION,
  VALUE_FORMAT_LIST: VALUE_FORMAT_LIST,
  BAR_GROUP_MODE: BAR_GROUP_MODE,
}

const I18N = {
  [VALUE]: 'FIELD_VALUE',
  [NAME_VALUE]: 'LABEL_NAME_VALUE',
  [NAME]: 'FIELD_NAME',
  [POSITION_INNER]: 'LABEL_INNER',
  [POSITION_OUTER]: 'LABEL_OUTER',
  [VALUE_PERCENTAGE]: 'FIELD_PERCENTAGE',
  [VALUE_AMOUNT]: 'FIELD_AMOUNT',
  [MODE_GROUPED]: 'LABEL_GROUPED',
  [MODE_STACKED]: 'LABEL_STACKED',
  [PIE]: 'CHART_TYPE_PIE',
  [BAR]: 'CHART_TYPE_BAR',
  [LINE]: 'CHART_TYPE_LINE',
  [SANKEY]: 'CHART_TYPE_SANKEY',
}

//TODO: Definir con UX/UI paleta de colores para graficos.
const COLORS_PLIKA = ['#00AA63', '#98C4ED', '#2065C7', '#64D199']
const COLORS = [
  ...categoricalColorSchemes.nivo,
  ...categoricalColorSchemes.pastel1,
  ...categoricalColorSchemes.set3,
  ...COLORS_PLIKA,
]

const COLORS_BUDGET_TYPES = ['#00aa63', '#ffcd00', '#fd4a5c', '#e499b0', '#a73ba1', '#df9100']

const COLORS_SALES = ['#00aa63', '#34DD96', '#217C56', '#70CEA8']
const COLORS_EXPENSES = ['#ffcd00', '#E3B806', '#FDE78E', '#C0A539']
const COLORS_COSTS = ['#fd4a5c', '#EC2828', '#FEA5AE', '#A60C1B']
const COLORS_HUMAN_RESOURCES = ['#e499b0', '#DF2F65', '#CFB4BC', '#C1043D']
const COLORS_OTHER_5 = ['#a73ba1', '#FD02EF', '#FBBFF8', '#86017F']
const COLORS_OTHER_6 = ['#df9100', '#CF9138', '#FEDDAF', '#B26802']

const EXPORT_FORMAT_TYPE_OPTIONS = [
  { id: 'png', name: 'PNG', onAction: toPng },
  {
    id: 'jpeg',
    name: 'JPG',
    onAction: toJpeg,
    props: { backgroundColor: 'white' },
  },
]

const REPORT_DASHBOART_TYPES = [
  {
    id: 'chart',
    name: 'FIELD_CHART',
  },
  {
    id: 'table',
    name: 'FIELD_TABLE',
  },
]

const SIZE_OPTIONS = [
  {
    id: 'large',
    name: 'CHART_LARGE_SIZE',
  },
  {
    id: 'medium',
    name: 'CHART_MEDIUM_SIZE',
  },
  {
    id: 'small',
    name: 'CHART_SMALL_SIZE',
  },
]

const CHART_OPTIONS = [
  {
    id: 'pie',
    name: 'CHART_TYPE_PIE',
  },
  {
    id: 'bar',
    name: 'CHART_TYPE_BAR',
  },
  {
    id: 'line',
    name: 'CHART_TYPE_LINE',
  },
  {
    id: 'sankey',
    name: 'CHART_TYPE_SANKEY',
  },
]

const CHARTS = {
  COLORS,
  I18N,
  TYPES,
  SETTINGS,
  LABEL_FORMAT,
  VALUE_FORMAT,
  EXPORT_FORMAT_TYPE_OPTIONS,
  COLORS_BUDGET_TYPES,
  COLORS_COSTS,
  COLORS_PLIKA,
  COLORS_SALES,
  COLORS_EXPENSES,
  COLORS_HUMAN_RESOURCES,
  COLORS_OTHER_5,
  COLORS_OTHER_6,
  DISPLAY_OPTIONS,
  SORT_OPTIONS,
  REPORT_DASHBOART_TYPES,
  SIZE_OPTIONS,
  CHART_OPTIONS,
  VALUE_AMOUNT,
  CHART_KEY,
  TABLE_KEY,
}

export default CHARTS
