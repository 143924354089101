const society = {
  SOCIETY_ADD_NEW_ACTION: 'Crear nueva empresa',
  SOCIETY_LABEL_NAME: 'Nombre empresa',
  SOCIETY_LABEL_DESCRIPTION: 'Descripcion empresa',
  SOCIETY_ENTER_DESCRIPTION_PLACEHOLDER: 'ingresa descripcion',
  SOCIETY_LABEL_DOMAIN: 'Dominio empresa',
  SOCIETY_ENTER_DOMAIN_PLACEHOLDER: 'Por ej: empresa.com',
  SOCIETY_LABEL: 'Empresas',
  SOCIETY_EMPTY_SCREEN_DESCRIPTION: 'Aun no tenemos informacion de empresas para mostrar',
  SOCIETY_EMPTY_SCREEN_USER_DESCRIPTION:
    'Aun no tenemos informacion de usuarios correspondientes a la espresa para mostrar',
  SOCIETY_RESTORE_PASSWORD_LINK_POPCONFIRM_TITLE:
    '¿Desea enviar un link de restablecer contraseña al usuario indicado?',
  SOCIETY_LABEL_RESTORE_PASSWORD: 'Restablecer contraseña',
  SOCIETY_INACTIVATE_USER_POPCONFIRM_TITLE:
    '¿Estás seguro que deseas inactivar este usuario? No podrás iniciar secion con el mismo una vez realizada esta acción',
  SOCIETY_DELETE_POPCONFIRM_TITLE:
    'Estás seguro que deseas eliminar esta empresa? Recordá que una vez que lo hagas, ésta se inactivará al igual que los usuarios que la componen y no podrás usarla',
  SOCIETY_FILE_NAME: 'Plika - Empresas ({{date}})',
  SOCIETY_SELECT_BUDGET_TYPE_TITLE:
    'Escribe {{selectText}} el nombre de la carpeta donde se alojarán tus archivos',
  SOCIETY_UPLOAD_S3_FILE_TITLE: 'Subir archivo a Plika - servicio s3',
  SOCIETY_SELECT_BUDGET_TYPE_INFORMATIVE_TOOLTIP_TITLE:
    'Primero debes escribir {{selectText}} el nombre de la carpeta para poder cargar tus archivos',
  SOCIETY_CONFIG_MODAL: 'Configuración de empresa',
  SOCIETY_PLACEHOLDER_BOTTOM_MODULE: 'Módulo',
  SOCIETY_PLACEHOLDER_BOTTOM_GROUP: 'Agrupación',
  SOCIETY_PLACEHOLDER_BOTTOM_SUBMODULE: 'Submódulo',
  SOCIETY_PLACEHOLDER_BOTTOM_NAME: 'Nombre del bottom',
  SOCIETY_PLACEHOLDER_BOTTOM_ACTION: 'Acción del bottom',
  SOCIETY_PLACEHOLDER_BOTTOM_FUNCTION: 'Función del bottom',
  SOCIETY_SELECT_FORLDER_NAME_TITLE: 'o selecciona',
  SOCIETY_PLACEHOLDER_PLANNING: 'Planificación',
  SOCIETY_PLACEHOLDER_PLANNING_GLOBAL: 'Planificación global',
  SOCIETY_PLACEHOLDER_SEGUIMIENTO: 'Seguimiento',
  SOCIETY_PLACEHOLDER_FORECAST: 'Forecast',
  SOCIETY_PLACEHOLDER_FORECAST_GLOBAL: 'Forecast global',
  SOCIETY_PLACEHOLDER_REPORTES: 'Reportes',
  SOCIETY_PLACEHOLDER_REPORTES_QUICK: 'Reportes self-services Quicksight',
}

export default society
