export const NAME = 'adjustments'

export const FETCH_ADJUSTMENT_KPIS = `${NAME}/FETCH_ADJUSTMENT_KPIS`
export const FETCH_ADJUSTMENT_KPIS_SUCCESS = `${NAME}/FETCH_ADJUSTMENT_KPIS_SUCCESS`
export const FETCH_ADJUSTMENT_KPIS_FAIL = `${NAME}/FETCH_ADJUSTMENT_KPIS_FAIL`

export const DOWNLOAD_ADJUSTMENTS_XLS = `${NAME}/DOWNLOAD_ADJUSTMENTS_XLS`
export const DOWNLOAD_SINGLE_ADJUSTMENTS_XLS = `${NAME}/DOWNLOAD_SINGLE_ADJUSTMENTS_XLS`
export const DOWNLOAD_ADJUSTMENT_TEMPLATE_XLS = `${NAME}/DOWNLOAD_ADJUSTMENT_TEMPLATE_XLS`

export const FETCH_ADJUSTMENT_LIST = `${NAME}/FETCH_ADJUSTMENT_LIST`
export const FETCH_ADJUSTMENT_LIST_SUCCESS = `${NAME}/FETCH_ADJUSTMENT_LIST_SUCCESS`
export const FETCH_ADJUSTMENT_LIST_FAIL = `${NAME}/FETCH_ADJUSTMENT_LIST_FAIL`

export const FETCH_COLUMNS_ADJUSTMENT_DETAIL = `${NAME}/FETCH_COLUMNS_ADJUSTMENT_DETAIL`
export const FETCH_COLUMNS_ADJUSTMENT_DETAIL_SUCCESS = `${NAME}/FETCH_COLUMNS_ADJUSTMENT_DETAIL_SUCCESS`
export const FETCH_COLUMNS_ADJUSTMENT_DETAIL_FAIL = `${NAME}/FETCH_COLUMNS_ADJUSTMENT_DETAIL_FAIL`

export const FETCH_ADJUSTMENT_DETAIL_TABLE = `${NAME}/FETCH_ADJUSTMENT_DETAIL_TABLE`
export const FETCH_ADJUSTMENT_DETAIL_TABLE_SUCCESS = `${NAME}/FETCH_ADJUSTMENT_DETAIL_TABLE_SUCCESS`
export const FETCH_ADJUSTMENT_DETAIL_TABLE_FAIL = `${NAME}/FETCH_ADJUSTMENT_DETAIL_TABLE_FAIL`

export const DUPLICATE_ADJUSTMENT = `${NAME}/DUPLICATE_ADJUSTMENT`
export const DUPLICATE_ADJUSTMENT_SUCCESS = `${NAME}/DUPLICATE_ADJUSTMENT_SUCCESS`
export const DUPLICATE_ADJUSTMENT_FAIL = `${NAME}/DUPLICATE_ADJUSTMENT_FAIL`

export const CANCEL_ADJUSTMENT = `${NAME}/CANCEL_ADJUSTMENT`
export const CANCEL_ADJUSTMENT_SUCCESS = `${NAME}/CANCEL_ADJUSTMENT_SUCCESS`
export const CANCEL_ADJUSTMENT_FAIL = `${NAME}/CANCEL_ADJUSTMENT_FAIL`

export const FETCH_ADJUSTMENT_AVAILABLE_DATES = `${NAME}/FETCH_ADJUSTMENT_AVAILABLE_DATES`
export const FETCH_ADJUSTMENT_AVAILABLE_DATES_SUCCESS = `${NAME}/FETCH_ADJUSTMENT_AVAILABLE_DATES_SUCCESS`
export const FETCH_ADJUSTMENT_AVAILABLE_DATES_FAIL = `${NAME}/FETCH_ADJUSTMENT_AVAILABLE_DATES_FAIL`

export const FETCH_SELECTOR_COLUMNS = `${NAME}/FETCH_SELECTOR_COLUMNS`
export const FETCH_SELECTOR_COLUMNS_SUCCESS = `${NAME}/FETCH_SELECTOR_COLUMNS_SUCCESS`
export const FETCH_SELECTOR_COLUMNS_FAIL = `${NAME}/FETCH_SELECTOR_COLUMNS_FAIL`

export const UPLOAD_ADJUSTMENT_FILE = `${NAME}/UPLOAD_ADJUSTMENT_FILE`
export const UPLOAD_ADJUSTMENT_FILE_SUCCESS = `${NAME}/UPLOAD_ADJUSTMENT_FILE_SUCCESS`
export const UPLOAD_ADJUSTMENT_FILE_FAIL = `${NAME}/UPLOAD_ADJUSTMENT_FILE_FAIL`

export const EDIT_ADJUSTMENT_VALUES = `${NAME}/EDIT_ADJUSTMENT_VALUES`
export const EXIT_ADJUSTMENT_CREATION = `${NAME}/EXIT_ADJUSTMENT_CREATION`

export const ADD_ADJUSTMENT_ROW = `${NAME}/ADD_ADJUSTMENT_ROW`
export const COPY_ADJUSTMENT_ROW = `${NAME}/COPY_ADJUSTMENT_ROW`
export const REMOVE_ADJUSTMENT_ROW = `${NAME}/REMOVE_ADJUSTMENT_ROW`
export const EDIT_ADJUSTMENT_ROW = `${NAME}/EDIT_ADJUSTMENT_ROW`

export const CREATE_ADJUSTMENT_BY_FILE = `${NAME}/CREATE_ADJUSTMENT_BY_FILE`

export const DOWNLOAD_CREATE_ADJUSTMENT_TEMPLATE = `${NAME}/DOWNLOAD_CREATE_ADJUSTMENT_TEMPLATE`
export const DOWNLOAD_CREATE_DISTRIBUTION_TEMPLATE = `${NAME}/DOWNLOAD_CREATE_DISTRIBUTION_TEMPLATE`
