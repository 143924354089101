const dashboard = {
  DASHBOARD_WELLCOME: 'Wellcome to Plika!',
  DASHBOARD_CONFIG_ACTION: 'Configure Plika',
  DASHBOARD_EMPTY_STATE_DESCRIPTION: 'We are not ready to work',
  DASHBOARD_PERIODS_ENTER_ACTION: 'Go to periods',

  // Cards texts
  PLANNING_ENTER_TITLE: 'Manage your budget',
  CONTROL_ENTER_TITLE: 'Keep track of executions',
  FORECAST_ENTER_TITLE: 'Re-define your budgets',
  REPORTS_ENTER_TITLE: 'Analyze your budget period',

  //Onboarding ants
  ONBOARDING_PLANNING_DESCRIPTION:
    'Start building your Sales and Expenses budget. You can create as many scenarios as you need so that you can consolidate them between each other.',

  ONBOARDING_CONTROL_DESCRIPTION:
    'Upload real data for Sales and Expenses, also create Management settings and Distributions.',

  ONBOARDING_FORECAST_DESCRIPTION:
    'Re-budget in the Forecast module to identify what is not evolving as planned, for this you must have approved budgets in the Planning module and published actual sales and expenses in the Tracking module.',

  ONBOARDING_REPORT_DESCRIPTION:
    'Access to "Reports" and "Custom reports" to visualize the most relevant metrics, evolution graphs and comparisons.',

  DASHBOARD_USER_HELLO: 'Hello {{name}}!',
  DASHBOARD_SUBTITLE: 'We offer you access to information that may interest you',
  DASHBOARD_LABEL_ARTICLES: 'More recent articles',
  DASHBOARD_LABEL_DICTIONARY: 'FP&A Metric Dictionary',
  DASHBOARD_LABEL_NEW_FUNCTIONS: 'New functions in Plika',
  DASHBOARD_COMERCIAL_CONTACT: 'Commercial contact',
  DASHBOARD_SERVICES_CONTACT: 'Services contact',
  DASHBOARD_WHATSAPP_CONTACT: 'Make your query by WhatsApp doing',
  DASHBOARD_WHATSAPP_CONTACT_LINK: 'click here',
  DASHBOARD_NEWS_PLIKA: `Plika's news`,
  DASHBOARD_TUTORIALS: 'Tutorials',
  DASHBOARD_SUPPORT_INFO: 'Support information',
  DASHBOARD_EBOOK_DESCRIPTION: 'Main FP&A tendecies that impact financial teams',
  DASHBOARD_ARTICLE_DESCRIPTION: `Stay informed about trends in FP&A with the Plika Blog. Learn with a new article every week.`,
  DASHBOARD_METRICS_DESCRIPTION: `Access the metrics most commonly used by Financial Planning & Analysis professionals`,
  DASHBOARD_NEW_FUNCTIONS_DESCRIPTION: `Visit our YouTube channel to learn how to use the most recent features of Plika`,
  DASHBOARD_GO_EBOOK_LINK: 'Go to the ebook',
  DASHBOARD_GO_BLOG_LINK: 'Go to the blog',
  DASHBOARD_GO_NEW_FUNCTIONS_LINK: 'New functions',
  DASHBOARD_GO_METRICS_LINK: 'Go to metrics',
}

export default dashboard
