const home = {
  HOME_INITIAL_STEPS_LOAD_DATA_INFORMATIVE_TOOLTIP_TITLE:
    'Debes importar datos a través de alguna de estas opciones para continuar',
  HOME_INITIAL_STEPS_SELECTED_DATA_INFORMATIVE_TOOLTIP_TITLE:
    'Primero debes mapear la información de cada archivo cargado (dimensiones y transacciones) y asociarla a la información que Plika necesita para poder continuar',
  HOME_INITIAL_STEPS_PREVIOUS_STEP_MODAL_TITLE: 'Volver al paso anterior',
  HOME_INITIAL_STEPS_PREVIOUS_STEP_MODAL_BODY: 'Estas seguro que deseas volver al paso anterior?',
  HOME_INITIAL_STEPS_LOAD_FILE: 'Importá tus datos',
  HOME_INITIAL_STEPS_SELECT_DATA: 'Asociá tus datos de origen a Plika',
  HOME_INITIAL_SCREEN_FIRST_STEP: 'Prepara tu/s archivo/s .csv con tu información histórica.',
  HOME_INITIAL_SCREEN_SECOND_STEP:
    'Estos archivos pueden contener información de transacción y dimensiones o solo de dimensión.',
  HOME_INITIAL_SCREEN_SECOND_STEP_LNK: 'Ver ejemplos',
  HOME_INITIAL_SCREEN_THIRD_STEP: '¡Ya estás listo para ingresar al mundo Plika!',
  HOME_INITIAL_SCREEN_SUBTITLE: 'Estamos por comenzar a configurar la herramienta, pero antes:',
  HOME_INITIAL_SCREEN_TUTORIAL: '¿Te quedaron dudas? revisá este',
  HOME_INITIAL_SCREEN_TUTORIAL_LINK: 'Plika-tutorial',
  HOME_INITIAL_SCREEN_QUESTION: '¿Estás listo para esta aventura?',
  HOME_INITIAL_STEPS_LOAD_FILE_DESCRIPTION: 'Carga tus archivos en formato .csv',
  HOME_INITIAL_STEPS_SELECT_DATA_DESCRIPTION:
    'Elige y asocia los datos de tus archivos con los que necesita Plika', // la columna de cada archivo que quieres o necesitas llevar a plika', //y vincúlala a la dimension Plika y sus datos correspondiente',

  HOME_INITIAL_STEPS_SELECT_DATA_TRANSACTION_FILE_SUBTITLE: 'Transacciones',
  HOME_INITIAL_STEPS_SELECT_DATA_COLUMNS_TRANSACTION_FILE_SELECT_TITLE: 'Columna del archivo',
  HOME_INITIAL_STEPS_SELECT_DATA_DIMENSIONS_PLIKA_SELECT_TITLE: 'Dimensiones Plika',
  HOME_INITIAL_STEPS_SELECT_DATA_ADD_NEW_SELECTION_ACTION: 'Añadir nueva selección',
  HOME_INITIAL_STEPS_SELECT_DATA_DELETE_SELECTION_ACTION: 'Eliminar selección',
  HOME_INITIAL_STEPS_SELECT_DATA_DELETE_UPLOAD_FILE_POPCONFIRM_TITLE:
    'Estás seguro que deseas eliminar este archivo? Si lo haces, los datos de este archivo no serán procesado en esta instancia. Luego podrás cargarlo desde la sección Seguimineto',
  HOME_INITIAL_STEPS_PROCESSING_DATA_SCREEN_PROCESSING_TITLE: 'Estamos procesando la información',
  HOME_INITIAL_STEPS_INFORMATIVE_SCREEN_PROCESSING_TITLE:
    'La misma se irá cargando por partes, por lo que cuando tengamos algo que puedas visualizar podrás comenzar a navegar la herramienta..',
  HOME_INITIAL_STEPS_SELECT_DATA_FIELD_CODE_INFORMATIVE_TOOLTIP_TITLE:
    'Puede que alguna columna de tipo dimensión no se haya mapeado con el campo code de Plika',
  HOME_INITIAL_STEPS_SELECT_DATA_AMOUNT_DATE_INFORMATIVE_TOOLTIP_TITLE:
    'Debes seleccionar las columnas relacionadas a importe o precio y fecha para poder continuar',
  HOME_INITIAL_STEPS_SELECT_FORMAT_DATE_SELECT_TITLE: 'Formato de fecha',

  HOME_INITIAL_STEPS_EDIT_CREDENTIALS_ACTION: 'Editar credenciales',
  HOME_INITIAL_STEPS_CREDENTIAL_FEEDBACK_SUCCESS: 'Credencial correcta',
  HOME_INITIAL_STEPS_CREDENTIAL_FEEDBACK_FAIL: 'Fallo en credencial',
  HOME_INITIAL_STEPS_UPLOAD_DATA_SPREADSHEETS_INFORMATIVE_ALERT_DESCRPTION:
    'Recordá que primero deber asociar el archivo de google sheets con el mail de la cuenta donde se ecuentre alojado el mismo',
  HOME_INITIAL_STEPS_SELECT_DATA_TRANSACTION_FILE_TYPE_COLUMN_DIMENSION_NAME: 'Dimensión - Nombre',
  HOME_INITIAL_STEPS_SELECT_DATA_TRANSACTION_FILE_TYPE_COLUMN_DIMENSION_CODE: 'Dimensión - Código',
  HOME_INITIAL_STEPS_SELECT_DATA_TRANSACTION_FILE_TYPE_COLUMN_DIMENSION_DATE: 'Transacción - Fecha',
  HOME_INITIAL_STEPS_SELECT_DATA_TRANSACTION_FILE_TYPE_COLUMN_TRANSACTION_PRICE:
    'Transacción - Precio',
  HOME_INITIAL_STEPS_SELECT_DATA_TRANSACTION_FILE_TYPE_COLUMN_TRANSACTION_AMOUNT:
    'Transacción - Importe',
  HOME_INITIAL_STEPS_SELECT_DATA_TRANSACTION_FILE_TYPE_COLUMN_TRANSACTION_QUANTITY:
    'Transacción - Cantidad',
  HOME_INITIAL_STEPS_SELECT_DATA_TRANSACTION_FILE_TYPE_COLUMN_TRANSACTION_CURRENCY:
    'Transacción - Moneda',
}

export default home
