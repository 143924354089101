import React from 'react'
import { Space } from 'antd'
import { Typography } from 'modules/core/components'
import { useTranslation } from 'react-i18next'
import moment from 'moment'

const LastModificationDate = ({ lastModificationDate }) => {
  const { t } = useTranslation()
  const dateFormat = 'DD/MM/YYYY'

  return (
    <Space direction="vertical">
      <Typography.Body level={3} type={'secondary'}>
        {t('LABEL_LAST_UPDATE', {
          date: moment(lastModificationDate, 'DD/MM/YYYY').format(dateFormat),
        })}
      </Typography.Body>
    </Space>
  )
}

export default LastModificationDate
