import { useTranslation } from 'react-i18next'
import ButtonBase from '../../ButtonBase'

const ButtonLinkWhitRightIcon = ({
  title,
  icon = null,
  disabled = false,
  onClick = () => {},
  ...props
}) => {
  const { t } = useTranslation()
  return (
    <ButtonBase {...props} type="link" title={t(title)} disabled={disabled} onClick={onClick}>
      {t(title)}
      {icon}
    </ButtonBase>
  )
}

export default ButtonLinkWhitRightIcon
