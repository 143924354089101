import Immutable from 'seamless-immutable'
import * as types from './constants'

const initialLoggedUser = {
  userId: null,
  firstName: null,
  lastName: null,
  email: null,
  society: null,
  roles: [],
  groups: [],
  operations: [],
  lang: null,
  tokenEncrypt: null,
  loginOtherAccountsList: [],
  formatNumber: {
    format_enable: false,
  },
  qrCodeData: null,
  dataPermissions: [],
}

const initialState = Immutable({
  isAuthenticating: true,
  isAuthenticated: false,
  loggedUser: initialLoggedUser,
})

/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default (state = initialState, action) => {
  let data
  switch (action.type) {
    case types.USER_WHO_AM_I_SUCCESS:
      data = action.payload.data
      return state.merge({
        isAuthenticating: false,
        isAuthenticated:
          data.password_confirmed ||
          data.password_confirmed_google ||
          data.password_confirmed_azure,
        loggedUser: {
          userId: data.id,
          pkid: data.pkid,
          firstName: data.first_name,
          lastName: data.last_name,
          email: data.email,
          isSuperuser: data.is_superuser,
          society: data.society,
          society_id: data.society_id,
          society_code: data.society_code,
          quicksight: data.quicksight,
          password_confirmed_google: data.password_confirmed_google,
          password_confirmed_azure: data.password_confirmed_azure,
          totp_enabled: data.totp_enabled,
          link_upload_file: data.link_upload_file,
          roles: data.roles,
          // groups: [...new Set(data.roles.map(role => role.group))],
          operations: [
            ...new Set(
              data.roles.map((role) => role.operations_assign.map((el) => el.name)).flat(),
            ),
          ],
          lang: data.lang ? data.lang : 'es',
          tokenEncrypt: data?.token_encrypt,
          folder_id: data.folder_id,
          ...(data?.format_number !== undefined ? { formatNumber: data?.format_number } : {}),
          dataPermissions: data.roles.reduce((mergedLines, it) => {
            return mergedLines.concat(it.data_permissions)
          }, []),
        },
      })

    case types.SET_AUTHENTICATED:
    case types.USER_LOGIN_FAIL:
    case types.USER_WHO_AM_I_FAIL:
      return state.merge({
        isAuthenticating: false,
        isAuthenticated: false,
        loggedUser: initialLoggedUser,
      })

    // case types.USER_SETTING_LANG_SUCCESS:
    //   data = action.payload.data;
    //   return state.merge({
    //     loggedUser: { ...state.loggedUser, lang: data.lang },
    //   });

    case types.FETCH_LOGIN_OTHER_ACCOUNTS_SUCCESS:
      return state.merge({
        loginOtherAccountsList: action.payload.data,
      })

    case types.QR_CODE_REDIRECT_SUCCESS:
      return state.merge({
        qrCodeData: action.payload.data,
      })

    default:
      return state
  }
}
