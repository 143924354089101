import React, { useEffect, useState } from 'react'
import { Alert, Col, Row, Spin } from 'antd'
import { Button, EmptyScreen } from 'modules/core/components'
import { ROLES } from 'modules/core/constants'
import { usePagination } from 'modules/core/customHooks'
import { isUserAllowed } from 'modules/core/utils'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import {
  AdjustmentCreationModal,
  AdjustmentTable,
  Kpis,
  /*TableActions,*/ UserActions,
} from './components'
import { monthsToAdjust } from './utils'
import planning from 'modules/planning'
import emptyStateImage from 'assets/images/compositions/empty-big.svg'
import adjustments from 'modules/adjustments'
import control from 'modules/control'
import _ from 'lodash'

const { CONTROL__ADJUSTMENTS__ABM } = ROLES

const AdjustmentsList = ({
  fetchAdjustmentList,
  adjustmentList: { count, values },
  adjustmentKPIS,
  periodList,
  fetchMonthsToPublish,
  monthsToPublish,
}) => {
  const [isKpisLoading /*setIsKpisLoading*/] = useState(false)
  const [isScreenLoading, setIsScreenLoading] = useState(true)
  const [isTableLoading, setIsTableLoading] = useState(false)
  const [selectedDates, setSelectedDates] = useState({})
  const [tablePagination, setTablePagination] = usePagination()
  const [showUploadModal, setShowUploadModal] = useState(false)
  let { periodId } = useParams()
  const { t } = useTranslation()

  useEffect(() => {
    setIsTableLoading(true)
    fetchAdjustmentList().then(() => {
      setIsScreenLoading(false)
      setIsTableLoading(false)
    })
  }, [fetchAdjustmentList])

  useEffect(() => {
    fetchMonthsToPublish()
  }, [fetchMonthsToPublish])

  const userCanCreate = isUserAllowed(CONTROL__ADJUSTMENTS__ABM)
  const dataPeriod = periodList.find((el) => el.id === parseInt(periodId))

  const renderTopAlert = () => (
    <Alert
      showIcon
      closable
      type="warning"
      message={t('ADJUSTMENT_EMPTY_STATE_ALERT_TITLE')}
      description={t('ADJUSTMENT_EMPTY_STATE_ALERT_TEXT')}
    />
  )

  const renderNoDataScreen = () => (
    <EmptyScreen
      image={emptyStateImage}
      title={t('ADJUSTMENT_EMPTY_STATE_TITLE')}
      description={t('ADJUSTMENT_EMPTY_STATE_TEXT')}
      footer={
        <Button.Primary
          onClick={() => setShowUploadModal(true)}
          disabled={
            monthsToAdjust({
              start_date: dataPeriod?.start_date,
              end_date: dataPeriod?.end_date,
              monthsToPublish,
            }).length === 0 || !userCanCreate
          }
          title="ACTION_UPLOAD_DATA"
          tooltipTitle={
            monthsToAdjust({
              start_date: dataPeriod?.start_date,
              end_date: dataPeriod?.end_date,
              monthsToPublish,
            }).length === 0
              ? t('ADJUSTMENT_EMPTY_STATE_APPROVE_DATA_TOOLTIP')
              : ''
          }
        />
      }
    />
  )

  const renderTopActions = () => (
    <UserActions
      onClick={() => setShowUploadModal(true)}
      disabled={!userCanCreate}
      setSelectedDates={setSelectedDates}
      selectedDates={selectedDates}
      dataPeriod={dataPeriod}
    />
  )

  const renderKPIs = () => <Kpis loading={isKpisLoading} adjustmentKPIS={adjustmentKPIS} />

  const renderAdjustmentTable = () => (
    <AdjustmentTable
      tableLoading={isTableLoading}
      onChange={setTablePagination}
      dataSource={values}
      pagination={tablePagination}
      count={count}
      disabled={!userCanCreate}
    />
  )

  return (
    <>
      <Spin spinning={isScreenLoading} size="large" />
      {(!isKpisLoading || !isTableLoading) && (
        <>
          {count <= 0 && _.isEmpty(selectedDates) && (
            <Row gutter={[24, 24]}>
              {monthsToAdjust({
                start_date: dataPeriod?.start_date,
                end_date: dataPeriod?.end_date,
                monthsToPublish,
              }).length === 0 && <Col span={24}> {renderTopAlert()}</Col>}
              <Col span={24}>{renderNoDataScreen()}</Col>
            </Row>
          )}
          {(count > 0 || (count === 0 && !_.isEmpty(selectedDates))) && (
            <Row gutter={[8, 8]}>
              <Col span={24}>{renderTopActions()}</Col>
              <Col span={24}>{renderKPIs()}</Col>
              {/* <Col span={24}><TableActions /></Col> */}
              <Col span={24}>{renderAdjustmentTable()}</Col>
            </Row>
          )}
          <AdjustmentCreationModal
            visible={showUploadModal}
            onCancel={() => setShowUploadModal(false)}
            onRefetch={() => fetchAdjustmentList()}
          />
        </>
      )}
    </>
  )
}

const mapStateToProps = (state) => ({
  adjustmentKPIS: [],
  adjustmentList: adjustments.selectors.getAdjustmentList(state),
  periodList: planning.selectors.getPeriodList(state),
  monthsToPublish: control.selectors.getMonthsToPublish(state),
})

const mapDispatchToProps = {
  fetchAdjustmentList: adjustments.actions.fetchAdjustmentList,
  fetchMonthsToPublish: control.actions.fetchMonthsToPublish,
}

export default connect(mapStateToProps, mapDispatchToProps)(AdjustmentsList)
