import { Table } from 'antd'
import { getRowClassName } from 'modules/configuration/containers/Reports/containers/ReportsDetail/utils/DynamicClass'
import { calculateScrollX, columnsHelper } from 'modules/core/utils'
import reports from 'modules/reports/index'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import { generatorEvolutionColumns, onExpandByPeriod } from './utils'

const EvolutionTable = ({
  dataSource,
  loading,
  periodTableColumns,
  setPeriodTableColumns,
  periodTableSettings,
  filters,
  selectedDates,
  selectedProjection,
  tablePagination,
  fetchTableOpenRow,
  lineVertical,
}) => {
  const [isExpandingByPeriod, setIsExpandingByPeriod] = useState(false)
  const [expandedByPeriodRow, setExpandedByPeriodRow] = useState([])
  let { periodId, reportId } = useParams()

  const paramsOpenRow = {
    period_id: periodId,
    filter: filters,
    filter_date: selectedDates,
    type_report: selectedProjection,
    vertical_analysis: lineVertical,
    page: tablePagination.page,
  }

  // Generar clase dinamica para la row
  const generateRowClassName = (record) => {
    return getRowClassName(record)
  }

  return (
    <Table
      rowKey="id"
      size="small"
      columns={generatorEvolutionColumns({
        columns: periodTableColumns,
        periodTableSettings,
        setPeriodTableColumns,
      })}
      dataSource={dataSource}
      rowClassName={generateRowClassName}
      loading={isExpandingByPeriod || loading}
      expandable={{
        onExpand: (expanded, record) =>
          onExpandByPeriod({
            expanded,
            record,
            setIsLoading: setIsExpandingByPeriod,
            fetchTableOpenRow: () =>
              fetchTableOpenRow(reportId, record.id, {
                ...paramsOpenRow,
                row_id: record.id,
                type_data: record.type ? 'report' : 'row',
              }),
            setExpandedByPeriodRow,
          }),
        expandedRowKeys: expandedByPeriodRow,
      }}
      scroll={{
        x: calculateScrollX(
          generatorEvolutionColumns({
            columns: periodTableColumns,
            periodTableSettings,
            setPeriodTableColumns,
          }),
        ),
        y: 420,
      }}
      pagination={false}
      components={columnsHelper.tableComponents}
    />
  )
}

const mapDispatchToProps = {
  fetchTableOpenRow: reports.actions.fetchTableOpenRow,
}

export default connect(null, mapDispatchToProps)(EvolutionTable)
