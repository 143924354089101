import React, { useState } from 'react'
import { Alert, Col, Form, Input, Radio, Row, Typography, Upload, message } from 'antd'
import { Button, DownloadModal, TypeUploadOptions } from 'modules/core/components'
import { useTranslation } from 'react-i18next'
import { UploadOutlined } from '@ant-design/icons'
import { connect } from 'react-redux'
import { INTEGRATIONS, TABLES } from 'modules/core/constants'
import { generateMessageError } from 'modules/core/utils'
import moment from 'moment'
import control from 'modules/control'
import home from 'modules/home'
import login from 'modules/login'

import './WithoutBase.scss'

const { Text } = Typography

const WithoutBase = ({
  fileList,
  setFileList,
  concept,
  periodId,
  dataPeriod,
  fileName,
  uploadOtion,
  setUploadOption,
  enabledTypeOfLoad = false,
  onChangeTypeOfLoad = () => {},
  typeOfLoad = null,
  downloadControlTemplateXLS,
  downloadSpreadsheets,
  loggedUser: { folder_id },
}) => {
  const [showModal, setShowModal] = useState(false)
  const { t } = useTranslation()

  const onAddUploadFile = (file) => {
    setFileList([file])
    return false
  }

  const handleOnClick = () => {
    if (uploadOtion === INTEGRATIONS.KEYS.FILE_KEY) {
      downloadControlTemplateXLS(
        t(fileName, {
          name: concept.name,
          periodName: dataPeriod.name,
          date: moment().format('lll'),
        }),
        { concept_id: concept.id, period_id: periodId },
      )
        .then(() => message.success(t('FEEDBACK_DOWNLOAD_FILE_SUCCESS'), 8))
        .catch((error) =>
          message.error(generateMessageError(error, 'FEEDBACK_DOWNLOAD_FILE_ERROR'), 8),
        )
    } else {
      setShowModal(true)
    }
  }

  const uploadProps = {
    name: 'file',
    accept: '.xls, .xlsx, .csv',
    showUploadList: { showPreviewIcon: false },
    beforeUpload: onAddUploadFile,
    onRemove: (file) => setFileList([]),
    fileList: fileList,
  }

  return (
    <Col span={24}>
      <Form.Item
        name="file_upload"
        label={t('ACTION_UPLOAD_FILE')}
        rules={[{ required: true, message: t('REQUIRED_FIELD') }]}
      >
        <Row justify="center" gutter={[8, 8]}>
          <Col span={24}>
            <Alert
              type="info"
              showIcon
              closable
              description={t('INFORMATIVE_ALERT_UPLOAD_DATA_CURRENCY')}
            />
          </Col>
          <Col span={24}>
            <Radio.Group value={uploadOtion}>
              {TABLES.OPTIONS_DOWNLOAD.map((option) => (
                <Radio
                  value={option.id}
                  key={option.id}
                  onChange={(val) => setUploadOption(val.target.value)}
                >
                  {t(option.name)}
                </Radio>
              ))}
            </Radio.Group>
          </Col>
          {enabledTypeOfLoad && (
            <Col span={24}>
              <Text>* {t('FIELD_LOAD_TYPE')}</Text>
              <TypeUploadOptions onChange={onChangeTypeOfLoad} value={typeOfLoad} />
            </Col>
          )}
          {uploadOtion === INTEGRATIONS.KEYS.SPREADSHEETS_KEY && (
            <Col span={24}>
              <Input placeholder={t('INTEGRATIONS_LINK_SPREADSHEET_INPUT_PLACEHOLDER')} />
            </Col>
          )}
          {uploadOtion === INTEGRATIONS.KEYS.FILE_KEY && (
            <Col>
              <Upload {...uploadProps}>
                {uploadProps.fileList.length <= 0 && (
                  <Button.DefaultWhitILeftIcon
                    icon={<UploadOutlined />}
                    title="UPLOAD_FILE_PLACEHOLDER"
                  />
                )}
              </Upload>
            </Col>
          )}
          <Col span={24}>
            <div className="upload-moda-help">
              {t('INFO_UPLOAD_FILE_MODAL_DESCRIPTION')}{' '}
              <span role="button" onKeyUp={() => {}} onClick={() => handleOnClick()}>
                {t('INFO_UPLOAD_FILE_DOWNLOAD_ACTION')}
              </span>
            </div>
          </Col>
          <DownloadModal
            title={INTEGRATIONS.KEYS.SPREADSHEETS_KEY}
            visible={showModal}
            onCancel={() => setShowModal(false)}
            defaultValue={folder_id}
            dataSpreadsheets={{
              type_load: 'template',
              folder_id,
              params: { concept_id: concept.id, period_id: periodId },
            }}
          />
        </Row>
      </Form.Item>
    </Col>
  )
}

const mapStateToProps = (state) => ({
  loggedUser: login.selectors.getWhoAmI(state),
})

const mapDispatchToProps = {
  downloadControlTemplateXLS: control.actions.downloadControlTemplateXLS,
  downloadSpreadsheets: home.actions.downloadSpreadsheets,
}

export default connect(mapStateToProps, mapDispatchToProps)(WithoutBase)
