const configuration = {
  //xls names
  CONFIG_USERS_TEMPLATE: 'Plika Template - Settings Users.xlsx',
  CONFIG_CONCEPTS_TEMPLATE: 'Plika Template - Settings Concepts.xlsx',
  CONFIG_DIMENSIONS_VALUES_TEMPLATE_NAME_DEFAULT:
    'Plika Template-  Settings Dimensions(values) {{date}}.xlsx',
  CONFIG_ACCOUNTS_STRUCTURE_FILE_NAME: 'Plika - Settings Chart of accounts (structure).xlsx',
  CONFIG_DIMENSIONS_VALUES_FILE_NAME: 'Plika - Settings dimension (values) ({{date}}).xlsx',
  CONFIG_USERS_FILE_NAME: 'Plika - Settings Users (listing).xlsx',
  CONFIG_ACCOUNT_CONCEPT_ASSOCIATION_TEMPLATE_NAME: 'Plika template - Association Account-Concept',
  CONFIG_CONCEPTS_FILE_NAME: 'Plika - Settings Concepts {{conceptType}} ({{conceptName}}).xlsx',
  CONFIG_DIMENSIONS_FILE_NAME: 'Plika - Configuration Dimensions ({{date}}).xlsx',
  CONFIG_USER_LIST_FILE_NAME: 'Plika - Configuración User List - ({{date}})',
  CONFIG_REPORT_STRUCTURE_FILE_NAME: 'Plika - Configuration Report structure - ({{date}})',

  // General
  CONFIG_CARD_EMPTY_STATE_TEXT: 'Enter and start creating or uploading {{config}}',
  CONFIG_SETTINGS_MODAL_TITLE: 'Preferences',
  CONFIG_SETTINGS_MODAL_TEXT: 'How do you want your team to view the {{dimension}} dimension?',
  CONFIG_SETTINGS_MODAL_ALERT:
    'Warning! What you define here as a preference will impact the display of the entire product.',
  CONFIG_SETTINGS_OPTION_BOTH: 'By code and description.',
  CONFIG_SETTINGS_OPTION_CODE: 'By code only.',
  CONFIG_SETTINGS_OPTION_NAME: 'By description only',
  CONFIG_CARD_DISABLED_INFORMATIVE_ICON_TOOLTIP_TITLE_1:
    'You must first load a file to be able to see information. Go to home',
  CONFIG_CARD_DISABLED_INFORMATIVE_ICON_TOOLTIP_TITLE_2: 'to load it',

  //Conceptos
  CONFIG_CONCEPTS_UPLOAD_MODAL_TITLE: 'Upload concepts',
  CONFIG_CONCEPTS_EMPTY_DIENSIONS_TITLE: 'There are no associated dimensions yet',
  CONFIG_CONCEPT_ASSOCIATE_DIMENSION_ACTION: 'Associate dimension',
  CONFIG_CONCEPT_ASSOCIATE_DIMENSION_MODAL_TITLE: 'Associate dimension to concept',
  CONFIG_CONCEPT_ASSOCIATE_TO_DIMENSION_FIELD: 'Dimension(s) to associate',
  CONFIG_CONCEPT_ASSOCIATE_MODAL_SELECT_LABEL: 'Select the dimension(s) you want to use to budget',
  CONFIG_CONCEPT_ASSOCIATE_REMOVE_TITLE: 'Are you sure you want to remove "{{name}}" dimension?',
  CONFIG_FIELD_ECONOMIC_CONCEPTS: 'Economic concepts',
  CONFIG_FIELD_FINANCIAL_CONCEPTS: 'Financial concepts',
  CONFIG_CONCEPT_ADD_ACTION: 'Add another concept',
  CONFIG_CONCEPT_POPCONFIRM_DELETE_TITLE: 'Are you sure you want to delete this concept?',
  CONFIG_CONCEPT_EMPTY_STATE_CREATE_ACTION: 'Create concept',

  //Dimensiones
  CONFIG_DIMENSION_VALUES_UPLOAD_MODAL_TITLE: 'Upload dimension values',
  CONFIG_DIMENSIONS_EMPTY_STATE_CREATE_ACTION: 'Create dimension',
  CONFIG_DIMENSIONS_TOOLTIP_INFO_TITLE:
    'The actions you perform on the dimensions will not impact periods before the current one',
  CONFIG_DIMENSIONS_POPCONFIRM_DELETE_DIMENSION_TITLE:
    'Are you sure you want to eliminate this dimension?',
  CONFIG_DIMENSIONS_INPUT_NAME_PLACEHOLDER: 'Eg. Branch',
  CONFIG_DIMENSIONS_DELETE_ACTION: 'Eliminate dimension',
  CONFIG_DIMENSIONS_ADD_NEW_ACTION: 'Add another dimension',
  CONFIG_DIMENSIONS_VALUES_POPCONFIRM_DELETE_VALUE_TITLE:
    'Are you sure you want to delete this dimension value?',
  CONFIG_DIMENSIONS_VALUES_MODAL_CREATE_NEW_VALUE_TITLE: 'Create dimension value',
  CONFIG_DIMENSIONS_VALUES_INPUT_CODE_PLACEHOLDER: 'Eg NY',
  CONFIG_DIMENSIONS_VALUES_INPUT_NAME_PLACEHOLDER: 'Eg New York',
  CONFIG_DIMENSIONS_PROCESS_DATA_EMPTY_SCREEN_DESCRIPTION:
    'The information is still being processed. Once completed, you will be able to see your loaded data on this screen',
  CONFIG_DIMENSIONS_VALUES_MANAGEMENT_INFORMATIVE_ICON_TOOLTIP_TITLE: `Here you will see/assign the name that your information will take in Plika. Don't worry! This will not modify the original name`,
  CONFIG_DIMENSIONS_GROUP_DATA_MODAL_TITLE: 'Group data',
  CONFIG_DIMENSIONS_VALUES_SELECTED_FROM_SELECT_LABEL: 'Selected values',
  CONFIG_DIMENSIONS_TYPE_CREATION_NEW_SELECT_OPTION: 'New dimension',
  CONFIG_DIMENSIONS_CHOOSE_TYPE_CREATION_FORM_RADIO_LABEL:
    'Choose what dimension you want to create',

  CONFIG_DIMENSIONS_FIELD_ORIGIN_CODE: 'Origin code',
  CONFIG_DIMENSIONS_FIELD_DESTINATION_CODE: 'Plika code',
  CONFIG_DIMENSIONS_FIELD_ORIGIN_NAME: 'Origin name',
  CONFIG_DIMENSIONS_FIELD_DESTINATION_NAME: 'Plika name',
  CONFIG_DIMENSIONS_EDIT_VALUES: 'Edit dimensions',
  CONFIG_DIMENSIONS_TOTAL_DIMENSIONS: 'Total dimensions',
  CONFIG_DIMENSIONS_TOTAL_VALUES: 'Total values',

  //Reportes
  CONFIG_REPORTS_EMPTY_STATE_TITLE: 'Create your first report!',
  CONFIG_REPORTS_GROUPING_FIELD: 'Grouping lines',
  CONFIG_REPORTS_TOTALIZING_FILED: 'Adding lines',
  CONFIG_REPORTS_TYPE: 'Report type',
  CONFIG_REPORT_STRUCTURE_FIELD: 'Structure',
  CONFIG_REPORT_DELETE_TITLE: 'Are you sure you want to delete this report?',
  CONFIG_REPORT_CREATE_ACTION: 'Create report',
  CONFIG_REPORT_CREATE_MODAL_NAME_LABEL: 'Enter a name for the report you want to create',
  CONFIG_REPORT_CREATE_MODAL_NAME_PLACEHOLDER: 'Eg: p & l - directory report',
  CONFIG_REPORT_TOTAL_REPORTS: 'Total reports',
  CONFIG_REPORT_TOTAL_ECONOMIC: 'Economics reports',
  CONFIG_REPORT_TOTAL_FINANCIAL: 'Financial reports',
  // VER QUE ONDA DESDE ACA
  CONFIG_OTHER_REPORT_CREATE_MODAL_NAME_PLACEHOLDER: 'Eg: Premiuns office',
  CONFIG_REPORT_DATA_UPLOAD_MODAL_TITLE: 'Uplaod report data',
  CONFIG_OTHER_REPORT_SELECT_REPORT_LABEL: 'Chose a report',
  CONFIG_OTHER_REPORT_SELECT_DIMENSION_LABEL:
    'Chose the dimension you want to visualize your report',
  CONFIG_REPORT_ECONOMIC: 'Economic',
  CONFIG_REPORT_FINANCIAL: 'Financial',

  //Usuarios
  CONFIG_USERS_EMPTY_STATE_TITLE: 'The loading of users begins',
  CONFIG_USER_COMPANY_POSITION_FIELD: 'Role in the company',
  CONFIG_USERS_TOOLTIP_INFO_TITLE:
    'The actions you take on users will not impact on the periods prior to the current one',
  CONFIG_USERS_POPCOFIRM_DELETE_USER_TITLE: 'Are you sure you want to delete this user?',
  CONFIG_USERS_POPCONFIRM_RESET_PASSWORD_TITLE:
    'The password of this user will change to the one we have by default: plika123, are you sure you want to do it?',
  CONFIG_USERS_MODAL_CREATE_USER_TITLE: 'Create user',
  CONFIG_USERS_CONFIRM_PASSWORD_TYPOGRAPHY_TEXT:
    'To confirm this action we need you to enter your password',
  CONFIG_USERS_DRAWER_TITLE: 'User configuration',
  CONFIG_USERS_SELECTION: 'User Selection',

  //Variables
  CONFIG_VAR_LIST_DRAWER_TITLE: 'The list of variables',
  CONFIG_VAR_CREATE_MODAL_TITLE: 'Create variable',
  CONFIG_VAR_CREATE_MODAL_NAME_PLACEHOLDER: 'For example:  Inflation',
  CONFIG_VAR_UPLOAD_MODAL_TITLE: 'Upload variables',
  CONFIG_VAR_UPLOAD_VALUES_MODAL_TITLE: 'Upload variable values',
  CONFIG_VAR_DELETE_CONFIRM_TOOLTIP: 'Are you sure you want to delete the variable {{name}}?',
  CONFIG_VAR_ACCOUNTS_NOT_SELECTED_PLACEHOLDER: 'There are no selected accounts',

  // Reglas de negocio
  CONFIG_FORMULAS_EMPTY_SCREEN_TITLE: 'You still have no rules created',
  CONFIG_FORMULAS_EMPTY_SCREEN_DESCRIPTION:
    'Create your business rules to obtain amounts that you can later use to calculate your budgets',
  CONFIG_FACTOR_EMPTY_SCREEN_TITLE: `You don't have factors loaded in this rule yet!`,
  CONFIG_FACTOR_EMPTY_SCREEN_DESCRIPTION:
    'Create factors with their behaviors for this business rule.',
  CONFIG_FORMULAS_CREATE_ACTION: 'Create business rule',
  CONFIG_FORMULAS_CONFIRM_ACTION_POPCONFIRM_TITLE:
    'Do you want to delete the data currently calculated? Or do you prefer to keep it?',
  CONFIG_FACTOR_CONFIRM_ACTION_MODAL_DESCRIPTION:
    'You are about to delete factor {{factorName}}. Keep in mind that this will impact the calculation and its results.',
  CONFIG_FACTOR_CREATE_MODAL_PLACEHOLDER: 'Eg initial amount',
  CONFIG_FACTOR_CREATE_ACTION: 'Create factor',
  CONFIG_FACTOR_POPCONFIRM_DELETE_TITLE: 'Are you sure you want to eliminate this factor?',
  CONFIG_FACTOR_DELETE_FEEDBACK_SUCCESS: 'The factor was properly removed',
  CONFIG_FORMULAS_CREATE_MODAL_PLACEHOLDER: 'Eg Sales income',
  CONFIG_FORMULAS_POPCONFIRM_DELETE_TITLE: 'Are you sure you want to eliminate this business rule?',
  CONFIG_VAR_DIMENSIONS_NOT_SELECTED_PLACEHOLDER: 'No selected dimensions',
  CONFIG_RULE_EDIT_FORMULA_MODAL_TITLE: 'Edit formula',
  CONFIG_RULES_APPLY_BUTTON_ACTION: 'Apply rules',
  CONFIG_RULES_ORIGIN_VALUES_STEP_ORIGIN_SUBTITLE: 'Set of values ​​for your origin',
  CONFIG_RULES_FORMULA_VALUES_STEP_FORMULA_SUBTITLE: 'Chose the formula and its behavior',
  CONFIG_RULES_FORMULA_VALUES_STEP_FORMULA_INDIVIDUAL_RADIO_BUTTON_OPTION: 'Individual',
  CONFIG_RULES_FORMULA_VALUES_STEP_FORMULA_ACCUMULATIVE_RADIO_BUTTON_OPTION: 'accumulative',
  CONFIG_RULES_FORMULA_VALUES_STEP_FORMULA_LABEL_ORIGIN: 'Origin',
  CONFIG_RULES_FORMULA_VALUES_STEP_FORMULA_LABEL_DESTINATION: 'Destination',
  CONFIG_RULES_DESTINATION_VALUES_STEP_DESTINATION_SUBTITLE: 'Set of values ​​for your destination',
  CONFIG_RULES_DESTINATION_VALUES_STEP_DESTINATION_TAKE_ORIGIN_RADIO_BUTTON_OPTION:
    'Take the origin',
  CONFIG_RULES_DESTINATION_VALUES_STEP_DESTINATION_TDIFFERENT_RADIO_BUTTON_OPTION:
    'Different destination',
  CONFIG_RULES_SWITCH_FORM_LABEL_REPLACE_VALUES: 'Replace rule values',
  CONFIG_RULES_SWITCH_FORM_INFORMATIVE_TOOLTIP_TITLE:
    'If you activate this option, the rule will replace the previous values ​​of the destination',
  CONFIG_RULES_VIEW_VARIABLES_TITLE: 'See variables',
  CONFIG_RULES_INFORMATIVE_MODAL_BODY_1:
    'Establishing the behavior of your business rules includes three parts:',
  CONFIG_RULES_INFORMATIVE_MODAL_BODY_2:
    '1 - Source: set of dimension values that will be our basis for the calculation',
  CONFIG_RULES_INFORMATIVE_MODAL_BODY_3: '2 - Formula that will be calculated based on the source',
  CONFIG_RULES_INFORMATIVE_MODAL_BODY_4:
    '3 - Destination: set of dimension values, which can be the same as the source or a different one. The result of the calculation will be reflected in this destination.',
  CONFIG_RULES_NOT_CREATE_INFORMATIVE_TOOLTIP_TITLE:
    'You must create at least one variable to create a rule',
  CONFIG_RULES_FORMULA_SELECT_VARIABLE_TITLE: 'Variables that make up the formula',
  CONFIG_RULES_FORMULA_SELECT_VARIABLE_PLACEHOLDER: 'Select a formula',
  CONFIG_RULE_CALCULATE_FORM_LABEL: 'Calculate on',
  CONFIG_RULE_NAME_WITHOUT_NUMBERS_INFORMATIVE_TOOLTIP:
    'The rule name can only contain letters, numbers, and spaces.',
  CONFIG_RULES_TOTAL_RULES: 'Total rules',

  //Roles
  CONFIG_ROLES_EMPTY_STATE_DESCRIPTION:
    'You still do not have roles loaded for your users, start configuring them',
  CONFIG_ROLES_CREATE_ACTION: 'Create role',
  CONFIG_ROLES_DUPLICATE_ACTION: 'Duplicate',
  CONFIG_ROLES_VIEW_TITLE: 'View role',
  CONFIG_ROLES_MODAL_CREATE_ROLE_TITLE: 'Create new role',
  CONFIG_ROLES_MODAL_DUPLICATE_ROLE_TITLE: 'Duplicate role',
  CONFIG_ROLES_DRAWER_ROLE_OPERATIONS_TITLE: 'Role security: {{name}}',
  CONFIG_ROLES_POPCONFIRM_DELETE_ROLE_TITLE:
    'Are you sure you want to delete this role? If you do, it will also be removed from the associated user, potentially leaving the user without any roles.',
  CONFIG_ROLES_ACTION_MODAL_NAME_PLACEHOLDER: 'For example: Sales Manager',
  CONFIG_ROLES_ACTION_MODAL_CODE_PLACEHOLDER: 'For example: SM',
  CONFIG_ROLES_LABEL_COPY: 'copy',
  CONFIG_ROLES_DRAWER_FEATURES_TAB_LABEL: 'Features',
  CONFIG_ROLES_SAVE_FEATURES_BUTTON_DRAWER: 'Save features',
  CONFIG_ROLES_SAVE_DATA_BUTTON_DRAWER: 'Save data',

  //onboarding
  CONFIG_ONBOARDING_STEP_DESCRIPTION_SECURITY:
    'Create roles and permissions to later assign them to the users you create for your team.',
  CONFIG_ONBOARDING_STEP_DESCRIPTION_PLAN_ACCOUNT:
    'Create and load information for your chart of accounts, levels for the structure, and accounts to be nested in different levels.',
  CONFIG_ONBOARDING_STEP_DESCRIPTION_CONCEPT: `Here you'll find sales and expense concepts to organize and group your information`,
  CONFIG_ONBOARDING_STEP_DESCRIPTION_DIMENSIONS:
    'Create custom dimensions and their values to classify, order, or group information; they will be associated with the concepts.',
  CONFIG_ONBOARDING_STEP_DESCRIPTION_PROJECTIONS_ELEMENTS:
    'Create variables and business rules to load data, apply calculations, and simulate scenarios in the planning module.',
  CONFIG_ONBOARDING_STEP_DESCRIPTION_REPORT:
    'Visualize and create your reports. Build the structure of your income statement and create even more detailed custom reports.',
  CONFIG_ONBOARDING_SKIP_TOOLTIP_TITLE: `If you skip, you won't be able to see the walkthrough again.`,

  //moneda
  CONFIG_CURRENCY_EMPTY_SCREEN_TITLE: 'You still do not have currencies created!',
  CONFIG_CURRENCY_EMPTY_SCREEN_DESCRIPTION:
    'Create origin and functional currencies.The origin currencies will be in which you can upload your values in Plika, the funcional currencies in which you will see them reflected.',
  CONFIG_CURRENCY_HEADER_FUNCTIONAL: 'Functional currency',
  CONFIG_CURRENCY_HEADER_ORIGIN: 'Source currency',
  CONFIG_CURRENCY_ACTIVATE: 'Enable currency',
  CONFIG_CURRENCY_DEACTIVATE: 'Disable currency',
  CONFIG_CURRENCY_DEACTIVATE_CURRENCY_POPCONFIRM_TEXT:
    'Are you sure you want to {{action}} this currency?',
  CONFIG_CURRENCY_DELETE_ACTION: 'Eliminate currency',
  CONFIG_CURRENCY_ADD_ACTION: 'Add another currency',
  CONFIG_CURRENCY_CREATE_ACTION: 'Create currency',
  CONFIG_CURRENCY_CREATE_MODAL_TITLE: 'Create new currency',
  CONFIG_CURRENCY_CREATE_FEEDBACK_FAIL_DESCRIPTION:
    'The following codes were not created because they already exist: {{currencies}}',
  CONFIG_CURRENCY_FIXBUTTON_TITLE: 'Pin currency',
  CONFIG_CURRENCY_POPCONFIRM_OKTEXT: 'Fix',
  CONFIG_CURRENCY_FEEDBACK_SUCCESS: 'Currency {{action}} successfully',
  CONFIG_CURRENCY_FEEDBACK_FAIL: 'It was not possible to {{action}} this currency',
  CONFIG_CURRENCY_FEEDBACK_ERROR: 'Error',
  CONFIG_CURRENCY_ACTION_ACTIVATED: 'Activated',
  CONFIG_CURRENCY_ACTION_DEACTIVATED: 'Deactivated',
  CONFIG_CURRENCY_ACTION_FIXED: 'Fixed',
  CONFIG_CURRENCY_WARNING_MESSAGE_MODAL_CONFIRM_DESCRIPTION_1: `You are about to set the currency with code {{code}} as the functional currency for the entire tool permanently. This means that if you proceed, you won't be able to undo the action`,
  CONFIG_CURRENCY_WARNING_MESSAGE_MODAL_CONFIRM_DESCRIPTION_2:
    'Are you sure you want to set this currency?',
  CONFIG_CURRENCY_EXCHANGE_RATE_DRAWER_SUBTITLE:
    'Enter the exchange rate for each of the currencies you operate with.',
  CONFIG_CURRENCY_EXCHANGE_RATE_DEFAULT_CURRENCY_DRAWER: 'Default currency',
  CONFIG_CURRENCY_MODAL_CREATE_SYMBOL_FIELD_PLACEHOLDER: 'Eg $',
  CONFIG_CURRENCY_EXCHANGE_RATE_NOT_PREDEFINED_CURRENCY_DRAWER: 'There is no set currency',
  CONFIG_CURRENCY_EXCHANGE_RATE_NOT_CURRENCIES: '',
  CONFIG_CURRENCY_EXAMPLE_TEXT_DRAWER_2: '',
  CONFIG_CURRENCY_EXAMPLE_TEXT_DRAWER_1: '',
  CONFIG_CURRENCY_EXAMPLE_TEXT_DRAWER_3: '',
  CONFIG_CURRENCY_EXAMPLE_TEXT_DRAWER_4: '',

  //mockData listado monedas
  CONFIG_CURRENCY_USD: 'American dollar',
  CONFIG_CURRENCY_EUR: 'Euro',
  CONFIG_CURRENCY_ARS: 'Argentine Peso',
  CONFIG_CURRENCY_CLP: 'Chilean Peso',
  CONFIG_CURRENCY_COP: 'Colombian Peso',
  CONFIG_CURRENCY_MXN: 'Mexican Peso',
  CONFIG_CURRENCY_UYU: 'Uruguayan Peso',
  CONFIG_CURRENCY_PEN: 'Peruvian Sun',

  CONFIG_TEXT_FINANCIAL: 'Financial',
  CONFIG_TEXT_ECONOMIC: 'Economic',

  CONFIG_BUDGET_TYPE_INFORMATIVE_TOOLTIP_TITLE:
    'They are the concepts in which the information is structured throughout all the modules. These allow you to segregate and organize data loading and analysis. It is allowed to create up to 6 Types of Budgets.',
  CONFIG_DIMENSIONS_INFORMATIVE_TOOLTIP_TITLE:
    'They constitute the analytical layer of Plika, the filters that are provided to the tool. The dimensions will impact the data with which we have to feed Plika in the future.',
  CONFIG_RULES_INFORMATIVE_TOOLTIP_TITLE:
    'This Plika functionality allows you to generate calculations and apply certain formulas, within the same tool.',
  CONFIG_REPORTS_INFORMATIVE_TOOLTIP_TITLE:
    'Within this space, the economic and financial reports will be configured that will later impact the Analysis module.',
  CONFIG_ROLE_INFORMATIVE_TOOLTIP_TITLE:
    'The created role can be assigned to one or more users registered in the tool',
  CONFIG_SECURITY_INFORMATIVE_TOOLTIP_TITLE:
    'The role determines the type of access that will be had within the tool. For its part, the user refers to the person themselves.',
  CONFIG_PREFERENCES_DIMENSIONS_INFORMATIVE_TOOLTIP_TITLE:
    'Preferences allow you to modify the display of information within each module of the tool.',
  CONFIG_CURRENCY_INFORMATIVE_TOOLTIP_TITLE:
    'You can configure the different currencies in which you want to display the information later.',

  CONFIG_VIDEO_DESCRIPTION_1: 'We will see how to configure the different types of budgets.',
  CONFIG_VIDEO_DESCRIPTION_2: `We will delve into the configuration of the dimensions that make up Plika's analytical layer and determine the level of detail of your data.`,
  CONFIG_VIDEO_DESCRIPTION_3: 'We will see how to configure business rules.',
  CONFIG_VIDEO_DESCRIPTION_4: 'We will see how to configure the different reports.',
  CONFIG_VIDEO_DESCRIPTION_5: 'We will see how to configure the different roles and users.',
}

export default configuration
