import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Row, Col, Spin } from 'antd'
import { EmptyScreen } from 'modules/core/components'
import {
  ActionRoleModal,
  RolesTable,
  UserActions,
  CreateButton,
  LicenseDrawer,
  TableActions,
} from './components'
import { isUserAllowed } from 'modules/core/utils'
import { ROLES } from 'modules/core/constants'
import { usePagination } from 'modules/core/customHooks'
import emptyStateImage from 'assets/images/compositions/empty-big.svg'
import configuration from 'modules/configuration'
import login from 'modules/login'
import { handleDelete } from './utils'

const { SETTINGS__SECURITY__ROLES__ABM } = ROLES

const KEY_CREATE = 'create'
const KEY_DUPLICATE = 'duplicate'

// TODO: Implementar paginación cuando sea necesario (falta backend)
const RolesList = ({
  rolesList: { count, data },
  fetchRolesList,
  loggedUser: { society_id },
  deleteRolesSelections,
}) => {
  const [showCreatModal, setShowCreatModal] = useState(false)
  const [actionType, setActionType] = useState(null)
  const [duplicateRole, setDuplicateRole] = useState(null)
  const [searchValue, setSearchValue] = useState(null)
  const [showLicenseDrawer, setShowLicenseDrawer] = useState({
    roleId: null,
    name: '',
    visible: false,
  })
  const [isTableLoading, setIsTableLoading] = useState(false)
  const [isScreenLoading, setIsScreenLoading] = useState(false)
  const [tablePagination, setTablePagination] = usePagination()
  const [isDeleting, setIsDeleting] = useState(false)
  const [dataSelection, setDataSelection] = useState([])
  const { t } = useTranslation()

  useEffect(() => {
    setIsTableLoading(true)
    setIsScreenLoading(true)
    fetchRolesList({ search: searchValue, society: society_id, ...tablePagination }).then(() => {
      setIsTableLoading(false)
      setIsScreenLoading(false)
    })
  }, [fetchRolesList, society_id, searchValue, tablePagination])

  const reFetchTable = () => {
    setIsTableLoading(true)
    fetchRolesList({ search: searchValue, society: society_id, ...tablePagination }).then(() =>
      setIsTableLoading(false),
    )
  }

  const renderCreateButton = () => (
    <CreateButton
      disabled={!isUserAllowed(SETTINGS__SECURITY__ROLES__ABM)}
      onClick={() => {
        setActionType(KEY_CREATE)
        setShowCreatModal(true)
      }}
    />
  )

  const renderNoDataScreen = () => (
    <EmptyScreen
      image={emptyStateImage}
      title={t('LABEL_ROLES')}
      description={t('CONFIG_ROLES_EMPTY_STATE_DESCRIPTION')}
      footer={renderCreateButton()}
    />
  )

  const renderMainActions = () => (
    <UserActions
      setSearchValue={setSearchValue}
      disabledCreateButton={!isUserAllowed(SETTINGS__SECURITY__ROLES__ABM)}
      onClickCreateButton={() => {
        setActionType(KEY_CREATE)
        setShowCreatModal(true)
      }}
    />
  )

  const renderTable = () => (
    <RolesTable
      dataSource={data}
      reFetchTable={reFetchTable}
      onDuplicateRole={(data) => {
        setDuplicateRole(data)
        setActionType(KEY_DUPLICATE)
        setShowCreatModal(true)
      }}
      setShowLicenseDrawer={setShowLicenseDrawer}
      tableLoading={isTableLoading}
      setIsTableLoading={setIsTableLoading}
      count={count}
      pagination={tablePagination}
      onChange={setTablePagination}
      dataSelection={dataSelection}
      setDataSelection={setDataSelection}
      canDelete={isUserAllowed(SETTINGS__SECURITY__ROLES__ABM)}
    />
  )

  const onClose = () => {
    setIsDeleting(false)
    setDataSelection([])
  }

  const renderTableActions = () => (
    <TableActions
      dataSelection={dataSelection}
      onConfirmDelete={() =>
        handleDelete({
          setIsLoading: setIsDeleting,
          deleteRolesSelections: () => deleteRolesSelections({ roles: dataSelection }),
          onRefetch: reFetchTable,
          onClose,
        })
      }
      disabled={!isUserAllowed(SETTINGS__SECURITY__ROLES__ABM)}
      isDeleting={isDeleting}
    />
  )

  return (
    <>
      <Spin spinning={isScreenLoading} />
      <Row gutter={[8, 8]}>
        {count <= 0 && !searchValue && !isTableLoading && (
          <Col span={24}>{renderNoDataScreen()}</Col>
        )}
        {(count > 0 || (searchValue && count === 0)) && (
          <>
            <Col span={24}>{renderMainActions()}</Col>
            <Col span={24}>{renderTableActions()}</Col>
            <Col span={24}>{renderTable()}</Col>
          </>
        )}
        <ActionRoleModal
          visible={showCreatModal}
          actionType={actionType}
          initialValues={duplicateRole}
          onCancel={() => {
            setShowCreatModal(false)
            setDuplicateRole({})
          }}
          onSuccess={reFetchTable}
          setShowLicenseDrawer={setShowLicenseDrawer}
        />
        <LicenseDrawer
          roleId={showLicenseDrawer.roleId}
          visible={showLicenseDrawer.visible}
          roleName={showLicenseDrawer.name}
          onSuccess={reFetchTable}
          onClose={() => {
            document.body.style.removeProperty('overflow')
            setShowLicenseDrawer({
              roleId: null,
              name: '',
              visible: false,
            })
          }}
        />
      </Row>
    </>
  )
}

const mapStateToProps = (state) => ({
  rolesList: configuration.selectors.getRolesList(state),
  loggedUser: login.selectors.getWhoAmI(state),
})

const mapDispatchToProps = {
  fetchRolesList: configuration.actions.fetchRolesList,
  deleteRolesSelections: configuration.actions.deleteRolesSelections,
}

export default connect(mapStateToProps, mapDispatchToProps)(RolesList)
