import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Drawer } from 'antd'
import { Button, ButtonActionBar } from 'modules/core/components'
import { useTranslation } from 'react-i18next'
import { isUserAllowed } from 'modules/core/utils'
import { DIMENSIONS, ROLES } from 'modules/core/constants'
import { TabsLicense } from './components'
import { onCancel, onSave } from './utils'
import login from 'modules/login'
import configuration from 'modules/configuration'
import _ from 'lodash'

const { SETTINGS__SECURITY__ROLES__ABM } = ROLES

const LicenseDrawer = ({
  roleId,
  visible,
  roleName,
  onSuccess,
  onClose,
  whoAmI,
  roleOperationKeys,
  editRoleOperations,
  saveRoleOperations,
  asignRoleDimensions,
  roleDimensions,
  fetchBudgetTypeList,
  budgetTypeList,
  fetchRoleDimensionsList,
}) => {
  const [isSaving, setIsSaving] = useState(false)
  const [isLoadingDimensions, setIsLoadingDimensions] = useState(false)
  const [tabKey, setTabKey] = useState(ROLES.ROLES_KEY.FEATURES_KEY)
  const { t } = useTranslation()

  useEffect(() => {
    if (visible) {
      setIsLoadingDimensions(true)
      Promise.all([fetchBudgetTypeList(), fetchRoleDimensionsList()])
        .then(() => setIsLoadingDimensions(false))
        .catch(() => null)
    }
  }, [fetchBudgetTypeList, fetchRoleDimensionsList, visible])

  const canUserEdit = isUserAllowed([SETTINGS__SECURITY__ROLES__ABM])

  const onAssign = () => {
    let reports = []
    const dimensions = _.compact(
      _.map(roleDimensions, (dim, key) => {
        if (dim.label === DIMENSIONS.REPORTS.label) {
          reports = dim.values.map((id) => parseInt(id))
        } else {
          return {
            dim_id: parseInt(key),
            values: dim.values,
            all_selected: dim.all_selected || false,
          }
        }
      }),
    )
    return asignRoleDimensions(roleId, { dimensions, reports })
  }

  return (
    <Drawer
      title={t('CONFIG_ROLES_DRAWER_ROLE_OPERATIONS_TITLE', { name: roleName })}
      placement="right"
      destroyOnClose
      forceRender
      maskClosable={false}
      onClose={() => {
        onCancel({
          onClose,
          editRoleOperations: () =>
            editRoleOperations({
              checked: false,
              operationsKeys: roleOperationKeys,
            }),
        })
        setTabKey(ROLES.ROLES_KEY.FEATURES_KEY)
      }}
      visible={visible}
      width={500}
      footer={
        <ButtonActionBar>
          <Button.Default
            onClick={() => {
              onCancel({
                onClose,
                editRoleOperations: () =>
                  editRoleOperations({
                    checked: false,
                    operationsKeys: roleOperationKeys,
                  }),
              })
              setTabKey(ROLES.ROLES_KEY.FEATURES_KEY)
            }}
            title="ACTION_CANCEL"
          />
          <Button.Primary
            onClick={() =>
              onSave({
                setIsLoading: setIsSaving,
                saveRoleOperations: () =>
                  tabKey === ROLES.ROLES_KEY.FEATURES_KEY
                    ? saveRoleOperations(roleId, { operations_assign: roleOperationKeys })
                    : onAssign(),
                onRefetch: onSuccess,
                whoAmI,
                onClose: () => setIsSaving(false),
              })
            }
            loading={isSaving}
            disabled={!canUserEdit}
            title={
              tabKey === ROLES.ROLES_KEY.FEATURES_KEY
                ? 'CONFIG_ROLES_SAVE_FEATURES_BUTTON_DRAWER'
                : 'CONFIG_ROLES_SAVE_DATA_BUTTON_DRAWER'
            }
          />
        </ButtonActionBar>
      }
    >
      <TabsLicense
        isLoadingDimensions={isLoadingDimensions}
        setTabKey={setTabKey}
        budgetTypeList={budgetTypeList}
        visible={visible}
      />
    </Drawer>
  )
}

const mapStateToProps = (state) => ({
  roleOperationKeys: configuration.selectors.getRoleOperationKeys(state),
  roleDimensions: configuration.selectors.getRoleDimensions(state),
  budgetTypeList: configuration.selectors.getBudgetTypeList(state),
})

const mapDispatchToProps = {
  whoAmI: login.actions.whoAmI,
  editRoleOperations: configuration.actions.editRoleOperations,
  saveRoleOperations: configuration.actions.saveRoleOperations,
  asignRoleDimensions: configuration.actions.asignRoleDimensions,
  fetchBudgetTypeList: configuration.actions.fetchBudgetTypeList,
  fetchRoleDimensionsList: configuration.actions.fetchRoleDimensionsList,
}

export default connect(mapStateToProps, mapDispatchToProps)(LicenseDrawer)
