import React, { useState } from 'react'
import { Select } from 'antd'
import { DIMENSIONS } from 'modules/core/constants'
import { handleSelectAll, generateSelectOptions } from 'modules/core/utils'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'

const SelectCell = ({
  initialValue,
  onCellUpdate,
  name,
  options,
  style = { width: 200 },
  ...props
}) => {
  const [cellValue, setCellValue] = useState(initialValue)
  const { t } = useTranslation()

  const auxOptions = _.compact(
    options.map((option) => {
      return option.id !== DIMENSIONS.ALL_FIELDS_ID_STRING && option.id
    }),
  )

  const areAllSelected = _.difference(auxOptions, cellValue).length === 0
  const isMultiple = props.mode === 'multiple'

  const updateCellValue = () => {
    setCellValue(cellValue)
    if (initialValue !== cellValue) {
      onCellUpdate(name, cellValue)
    } else {
      setCellValue(initialValue)
    }
  }

  return (
    <Select
      {...props}
      onBlur={updateCellValue}
      onMouseLeave={updateCellValue}
      style={style}
      value={cellValue}
      onChange={(values) =>
        isMultiple
          ? handleSelectAll({
              values,
              allValues: auxOptions,
              areAllSelected,
              onSetValues: (values) => setCellValue(values),
            })
          : setCellValue(values)
      }
      optionFilterProp="children"
      placeholder={t('ACTION_SELECT')}
      showSearch
      filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      options={generateSelectOptions({
        options,
        includeOptionAll: isMultiple && name !== 'concept', // name !== 'concept' provisorio hasta que se agregue el seleccionar todas en diemnsones config
      })}
    />
  )
}

export default SelectCell
