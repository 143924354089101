import React, { useEffect, useState } from 'react'
import { Alert, Modal, notification, Radio, Space, Typography } from 'antd'
import { generateMessageError } from 'modules/core/utils'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import configuration from 'modules/configuration'

const { Text } = Typography

const DisplaySettingsModal = ({
  messageAlert,
  dimensionId,
  dimensionName = '',
  displaySettings,
  fetchDisplaySettings,
  updateDisplaySettings,
  showSettingsModal,
  setShowSettingsModal,
  radioValue,
  setRadioValue,
}) => {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    fetchDisplaySettings({ id_dimension: dimensionId }).then(() => setIsLoading(false))
  }, [fetchDisplaySettings, dimensionId, showSettingsModal])

  const handleConfirm = () => {
    updateDisplaySettings(dimensionId, {
      type_data_view: radioValue,
      id_dimension: parseInt(dimensionId),
    })
      .then(() => {
        notification.success({
          message: t('FEEDBACK_CHANGES_SAVED_SUCCESS'),
          duration: 8,
        })
        handleCancel()
      })
      .catch((error) =>
        notification.error({
          message: t('FEEDBACK_DEFAULT_ERROR'),
          description: generateMessageError(error),
          duration: 0,
        }),
      )
  }

  const handleCancel = () => {
    setRadioValue(null)
    setShowSettingsModal(false)
  }

  return (
    <>
      <Modal
        title={t('CONFIG_SETTINGS_MODAL_TITLE')}
        centered
        visible={showSettingsModal}
        destroyOnClose={true}
        onCancel={handleCancel}
        onOk={handleConfirm}
        okText={t('ACTION_APPLY')}
        cancelText={t('ACTION_CANCEL')}
        okButtonProps={{ disabled: radioValue === null }}
      >
        {!isLoading && (
          <Space direction="vertical" size="large">
            <Alert
              message={messageAlert ? messageAlert : t('CONFIG_SETTINGS_MODAL_ALERT')}
              type="info"
              showIcon
            />
            <Text>{t('CONFIG_SETTINGS_MODAL_TEXT', { dimension: dimensionName })}</Text>
            <Radio.Group
              onChange={(e) => setRadioValue(e.target.value)}
              defaultValue={displaySettings ? displaySettings.type_view_data : null}
            >
              {[
                { key: 'code_and_name', description: t('CONFIG_SETTINGS_OPTION_BOTH') },
                { key: 'code', description: t('CONFIG_SETTINGS_OPTION_CODE') },
                { key: 'name', description: t('CONFIG_SETTINGS_OPTION_NAME') },
              ].map((option) => (
                <Radio
                  key={option.key}
                  value={option.key}
                  style={{
                    display: 'block',
                    height: '30px',
                    lineHeight: '30px',
                  }}
                >
                  {option.description}
                </Radio>
              ))}
            </Radio.Group>
          </Space>
        )}
      </Modal>
    </>
  )
}

const mapStateToProps = (state) => ({
  displaySettings: configuration.selectors.getDisplaySettings(state),
})

const mapDispatchToProps = {
  fetchDisplaySettings: configuration.actions.fetchDisplaySettings,
  updateDisplaySettings: configuration.actions.updateDisplaySettings,
}

export default connect(mapStateToProps, mapDispatchToProps)(DisplaySettingsModal)
