import { Button, ButtonActionBar, DropdownMenu, Typography } from 'modules/core/components'
import { useTranslation } from 'react-i18next'
import { SolutionOutlined, InfoCircleOutlined } from '@ant-design/icons'
import { Popover } from 'antd'

const UserActions = ({ tooltopTitle, onClick, loading, disabled, onClickActivity }) => {
  const { t } = useTranslation()
  return (
    <ButtonActionBar>
      <Popover
        trigger="hover"
        placement="bottom"
        title="Forecast"
        content={
          <div style={{ width: 400 }}>{t('FORECAST_MODULE_INFORMATIVE_ALERT_DESCRIPTION')}</div>
        }
      >
        <Typography.Icon icon={InfoCircleOutlined} />
      </Popover>
      <Button.Primary
        onClick={onClick}
        loading={loading}
        disabled={disabled}
        title="ACTION_CREATE_NEW"
        tooltipTitle={tooltopTitle}
      />
      <DropdownMenu
        title={t('ACTION_MORE')}
        menu={[
          {
            title: t('ACTION_ACTIVITIES'),
            icon: <SolutionOutlined />,
            onClick: onClickActivity,
          },
        ]}
      />
    </ButtonActionBar>
  )
}

export default UserActions
