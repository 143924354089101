import { Popconfirm, Tooltip } from 'antd'
import {
  ButtonActionBar,
  Comments,
  DropdownMenu,
  StatusTag,
  Typography,
} from 'modules/core/components'
import { useTranslation } from 'react-i18next'
import {
  InfoCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  CommentOutlined,
  SolutionOutlined,
} from '@ant-design/icons'

const CardExtra = ({
  loading,
  onConfirmPopConfirm,
  onCancelPopConfirm,
  visiblePopConfirm,
  status,
  tooltiTitle,
  onClickEdit,
  disabled,
  onClickDelete,
  showComments,
  hasComment,
  setHasComment,
  setViewComment,
  loggedUser,
  setDataComments,
  comments,
  onRefetchComments,
  loadingComments,
  onCommentCreate,
  onCleanHasComment,
  removeNewComment,
  onClickActivity,
}) => {
  const { t } = useTranslation()
  return (
    <Popconfirm
      placement="bottomRight"
      title={t('FORECAST_DELETE_POPCONFIRM')}
      okText={t('ACTION_DELETE')}
      okButtonProps={{ loading }}
      onConfirm={onConfirmPopConfirm}
      cancelText={t('ACTION_CANCEL')}
      onCancel={onCancelPopConfirm}
      visible={visiblePopConfirm}
    >
      <ButtonActionBar>
        <StatusTag status={status} />
        <Tooltip title={tooltiTitle}>
          <Typography.Icon icon={InfoCircleOutlined} />
        </Tooltip>
        {showComments && (
          <Comments
            hasComment={hasComment}
            onClick={(visible) => {
              setHasComment(visible)
              !visible && removeNewComment()
              !visible && setViewComment(visible)
            }}
            comments={comments}
            setData={setDataComments}
            loading={loadingComments}
            onRefetchComments={onRefetchComments}
            onCommentCreate={onCommentCreate}
            onCleanHasComment={onCleanHasComment}
          >
            <Typography.Icon
              style={{ cursor: 'pointer' }}
              title={t('LABEL_COMMENTS')}
              icon={CommentOutlined}
            />
          </Comments>
        )}
        <DropdownMenu
          title={t('ACTION_MORE')}
          menu={[
            {
              title: t('ACTION_EDIT_NAME'),
              icon: <EditOutlined />,
              onClick: onClickDelete,
              disabled,
            },
            {
              title: t('ACTION_DELETE'),
              icon: <DeleteOutlined />,
              onClick: onClickEdit,
              disabled,
            },
            {
              title: t('ACTION_ACTIVITIES'),
              icon: <SolutionOutlined />,
              onClick: onClickActivity,
            },
          ]}
        />
      </ButtonActionBar>
    </Popconfirm>
  )
}

export default CardExtra
