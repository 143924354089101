import React from 'react'
import { Select, Space, Typography as TypographyAntd } from 'antd'
import { Typography } from 'modules/core/components'
import { useTranslation } from 'react-i18next'

const { Text } = TypographyAntd

const ContainerSelect = ({
  onChange = () => {},
  options = [],
  loading = false,
  title = '',
  disabled = false,
  style = { width: '100%' },
  id,
  value,
  ...props
}) => {
  const { t } = useTranslation()
  return (
    <Space key={id} direction="vertical" style={{ width: '100%' }}>
      <Text ellipsis>
        <Typography.Body level={2} title={title}>
          <Typography.Body type="danger">*</Typography.Body>
          {title}
        </Typography.Body>
      </Text>
      <Select
        {...props}
        placeholder={t('ACTION_SELECT')}
        showSearch
        value={value}
        disabled={disabled}
        options={options}
        loading={loading}
        style={style}
        onChange={onChange}
        optionFilterProp="children"
        filterOption={(input, option) =>
          option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      />
    </Space>
  )
}

export default ContainerSelect
