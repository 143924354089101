import { useFeatureIsOn } from '@growthbook/growthbook-react'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'modules/core/customHooks'
import { SiderLayout } from 'modules/core/layouts'
import { PeriodNavigator } from 'modules/core/components'
import { Tabs, Spin } from 'antd'
import { isUserAllowed, getOperationsByKeys } from 'modules/core/utils'
import { ROLES, CONCEPTS, PROJECTION } from 'modules/core/constants'
import GlobalBudgetList from '../GlobalBudgetList/GlobalBudgetList'
import PlanningList from '../PlanningList/PlanningList'
import configuration from 'modules/configuration'

const {
  PLANNING__EXPENSES,
  PLANNING__SALES,
  PLANNING__HUMAN_RESOURCES,
  PLANNING__COSTS,
  PLANNING__OTHER_5,
  PLANNING__OTHER_6,
  PLANNING__GLOBAL_BUDGET,
} = ROLES

const PlanningHome = ({ budgetTypeList, fetchBudgetTypeList }) => {
  const extraConcept = useFeatureIsOn('feature-extra-concept')
  const [isScreenLoading, setIsScreenLoading] = useState(false)
  const [tabKey, setTabKey] = useState('')
  const { t } = useTranslation()
  let { periodId } = useParams()
  let query = useQuery()

  let queryString = window.location.search
  let urlParams = new URLSearchParams(queryString)
  let tabUrl = urlParams.get('tab')

  const auxKey = window.location.pathname.replaceAll('/', '-')
  const key = !auxKey.includes('?tab=') ? `?tab=${tabKey}` : ''

  useEffect(() => {
    setIsScreenLoading(true)
    fetchBudgetTypeList().then((response) => {
      if (tabUrl) {
        setTabKey(tabUrl)
      } else {
        const aux = response.payload.data.concepts.filter((el) => PANEL__ACCESS[el.id])
        setTabKey(showDefaultPanelKey(aux[0]))
      }
      setIsScreenLoading(false)
    })
    // eslint-disable-next-line
  }, [fetchBudgetTypeList, tabUrl])

  const PANEL__ACCESS = {
    [PROJECTION.GLOBAL_BUDGET]: isUserAllowed(getOperationsByKeys([PLANNING__GLOBAL_BUDGET])),
    [CONCEPTS.IDS.SALES_ID]: isUserAllowed(getOperationsByKeys([PLANNING__SALES])),
    [CONCEPTS.IDS.EXPENSES_ID]: isUserAllowed(getOperationsByKeys([PLANNING__EXPENSES])),
    [CONCEPTS.IDS.COSTS_ID]: isUserAllowed(getOperationsByKeys([PLANNING__COSTS])),
    [CONCEPTS.IDS.HUMAN_RESOURCES_ID]: isUserAllowed(
      getOperationsByKeys([PLANNING__HUMAN_RESOURCES]),
    ),
    [CONCEPTS.IDS.OTHER_5_ID]: isUserAllowed(getOperationsByKeys([PLANNING__OTHER_5])),
    [CONCEPTS.IDS.OTHER_6_ID]: isUserAllowed(getOperationsByKeys([PLANNING__OTHER_6])),
  }

  const verifyPermission = (concept) => {
    const permission = [
      PROJECTION.GLOBAL_BUDGET.toString(),
      CONCEPTS.IDS.SALES_ID.toString(),
      CONCEPTS.IDS.EXPENSES_ID.toString(),
      CONCEPTS.IDS.COSTS_ID.toString(),
      CONCEPTS.IDS.HUMAN_RESOURCES_ID.toString(),
      CONCEPTS.IDS.OTHER_5_ID.toString(),
      CONCEPTS.IDS.OTHER_6_ID.toString(),
    ]
    const value = PANEL__ACCESS[concept.id]
    if (value === undefined && !permission.includes(concept.id.toString()) && extraConcept) {
      return true
    }

    return value
  }

  const filterByRole = budgetTypeList?.values.filter((el) => verifyPermission(el))

  const showDefaultPanelKey = (firstElement) => {
    if (firstElement && verifyPermission(firstElement)) {
      return firstElement.name
    } else if (PANEL__ACCESS[PROJECTION.GLOBAL_BUDGET]) {
      return PROJECTION.GLOBAL_BUDGET
    }
  }

  const renderHeader = () => <PeriodNavigator items={[{ name: t('LABEL_PLANNING') }]} />

  return (
    <SiderLayout extraKey={key}>
      <Spin spinning={isScreenLoading} size="large" />
      {!isScreenLoading && renderHeader()}
      {!isScreenLoading && (
        <Tabs
          defaultActiveKey={
            query.get('tab') !== null
              ? query.get('tab')
              : showDefaultPanelKey(filterByRole && filterByRole[0])
          }
          onChange={setTabKey}
          size="small"
        >
          {budgetTypeList?.values.map(
            (concept) =>
              verifyPermission(concept) && (
                <Tabs.TabPane tab={t(concept.name)} key={concept.name}>
                  {tabKey === concept.name && (
                    <PlanningList periodId={periodId} concept={concept} />
                  )}
                </Tabs.TabPane>
              ),
          )}
          {PANEL__ACCESS[PROJECTION.GLOBAL_BUDGET] && (
            <Tabs.TabPane tab={t('LABEL_GLOBAL_BUDGET')} key={PROJECTION.GLOBAL_BUDGET}>
              <GlobalBudgetList />
            </Tabs.TabPane>
          )}
        </Tabs>
      )}
    </SiderLayout>
  )
}

const mapStateToProps = (state) => ({
  budgetTypeList: configuration.selectors.getBudgetTypeList(state),
})

const mapDispatchToProps = {
  fetchBudgetTypeList: configuration.actions.fetchBudgetTypeList,
}

export default connect(mapStateToProps, mapDispatchToProps)(PlanningHome)
