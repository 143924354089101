import { notification } from 'antd'
import { generateMessageError } from 'modules/core/utils'
import i18n from 'i18next'

export const handleVariable = (value, dataFormula, setDataFormula) => {
  if (value) {
    const isVariable = [...value.matchAll('variable[0-9]')]
    isVariable.map((v) =>
      setDataFormula({ variablesIds: { ...dataFormula.variablesIds, [v[0]]: null } }),
    )
  }
}

export const onSuccessUploadFile = ({ setIsLoading, fetchRulesList }) => {
  // GA.event(GA.ACTIONS.FILE_UPLOAD_REAL);
  setIsLoading(true)
  fetchRulesList().then(() => setIsLoading(false))
}

export const onFailUploadFile = (error) => {
  notification.error({
    key: 'error_file',
    message: i18n.t('FEEDBACK_DEFAULT_ERROR'),
    description: generateMessageError(error),
    duration: 0,
  })
  // GA.event(GA.ACTIONS.FILE_UPLOAD_REAL_ERROR);
}
