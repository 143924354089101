import React from 'react'
import OpCheck from '../OpCheck/OpCheck'
import { useTranslation } from 'react-i18next'
import { Col, Row } from 'antd'

const OpContent = ({ operations }) => {
  const { t } = useTranslation()
  return operations.map((op, index) => (
    <Row key={index} justify="space-between" align="middle">
      <Col span={22}>{t(`${op.label}`)}</Col>
      <Col span={2} style={{ display: 'flex', justifyContent: 'end' }}>
        <OpCheck panelArrayKeys={[op.key]} />
      </Col>
    </Row>
  ))
}

export default OpContent
