import { Col, DatePicker, Form, Input, Modal, Row, Select } from 'antd'
import _ from 'lodash'
import { Typography } from 'modules/core/components'
import { PROJECTION } from 'modules/core/constants'
import { generateSelectOptions } from 'modules/core/utils'
import forecast from 'modules/forecast'
import planning from 'modules/planning'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import { disabledDate, forecastCreate } from './utils'
import { disabledDate as disabledDatePlanning } from 'modules/planning/components/CreateBudgetModal/utils'
import { useFeatureIsOn } from '@growthbook/growthbook-react'

const CreateForecastModal = ({
  visible,
  onCancel,
  actualPeriod,
  onRefetch,
  createForecast,
  cutDates,
  disabledMonths,
  optionsSelect,
  periodList,
  fetchForecastFilterDates,
  forecastFilterDates,
}) => {
  const enableDatesSamePeriod = useFeatureIsOn('dates-same-period')
  const [date, setDate] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [filterDates, setFilterDates] = useState({})
  const [form] = Form.useForm()
  let { periodId } = useParams()
  const { t } = useTranslation()

  useEffect(() => {
    if (!_.isEmpty(filterDates)) {
      fetchForecastFilterDates({ ...filterDates })
    }
  }, [fetchForecastFilterDates, filterDates])

  const onClose = () => {
    form.resetFields()
    setIsLoading(false)
    setFilterDates({})
    setDate('')
    onCancel()
  }

  const handleCreate = (values) => {
    const { type_budget, dates, projections, name, cutoff_date } = values

    const splitterProjections = projections.map((it) => {
      const splitter = it.split('_')
      return { id: parseInt(splitter[0]), type: splitter[1] }
    })

    const data = {
      name,
      type_budget,
      cutoff_date: moment(cutoff_date).format('YYYY-MM'),
      period_id: periodId,
      overall_budget_ids: splitterProjections
        .filter((it) => it.type === 'budget')
        .map((it) => it.id),
      forecast_overall_budget_ids: splitterProjections
        .filter((it) => it.type === 'forecast')
        .map((it) => it.id),
      ...(dates && {
        date_start: moment(dates[0]).startOf('month').format('YYYY-MM-DD'),
        date_end: moment(dates[1]).startOf('month').format('YYYY-MM-DD'),
      }),
    }
    return createForecast(data)
  }

  const disabledDateZeroValue = (date) => {
    const pickerDates = moment(date._d).utc().format('YYYY-MM')
    return !(pickerDates >= actualPeriod.start_date && pickerDates <= actualPeriod.end_date)
  }

  const hasZeroValue = (value) => {
    return value && value.toString() === 'VALOR_CERO'
  }

  const hasHistoricalValue = (value) => value && value.toString() === 'HISTORICAL_PREVIOUS_PERIOD'

  const renderDisabledDatePicker = (date) => {
    if (enableDatesSamePeriod) {
      return disabledDateZeroValue(date)
    }
    return disabledDatePlanning(date, periodList, actualPeriod)
  }

  const options = !_.isEmpty(forecastFilterDates)
    ? [
        ...forecastFilterDates.forecast_overall_budget.map((el) => {
          return {
            ...el,
            type: 'forecast',
          }
        }),
        ...forecastFilterDates.overall_budget.map((el) => {
          return {
            ...el,
            type: 'budget',
          }
        }),
      ]
    : []

  return (
    <Modal
      title={t('FORECAST_CREATE_ACTION')}
      okText={t('ACTION_CREATE')}
      cancelText={t('ACTION_CANCEL')}
      centered
      forceRender
      visible={visible}
      onCancel={onClose}
      width={568}
      okButtonProps={{ loading: isLoading, disabled: !date }}
      onOk={() =>
        forecastCreate({
          form,
          setIsLoading,
          createForecast: (values) => handleCreate(values),
          onRefetch,
          onClose,
        })
      }
      destroyOnClose={true}
    >
      <Form form={form} layout="vertical">
        {() => (
          <>
            <Row gutter={[8, 8]}>
              <Col span={24}>
                <Typography.Body level={2}>
                  {t('FORECAST_CUT_DATE_INFORMATIVE_TEXT_MODAL')}
                </Typography.Body>
              </Col>
              <Col span={24}>
                <Form.Item
                  label={t('FIELD_NAME')}
                  name="name"
                  rules={[{ required: true, message: t('REQUIRED_FIELD') }]}
                >
                  <Input placeholder={t('ENTER_NAME_PLACEHOLDER')} />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="type_budget"
                  label={t('FORECAST_CREATE_NEW_BUDGET_BASE_TYPE_FORM_LABEL')}
                  rules={[{ required: true, message: t('REQUIRED_FIELD') }]}
                >
                  <Select
                    placeholder={t('ACTION_SELECT')}
                    options={generateSelectOptions({
                      options: [
                        {
                          id: 'VALOR_CERO',
                          name: 'PLANNING_CREATE_NEW_BUDGET_BASE_OPTION_FORM_SELECT',
                          type: 'cero',
                        },
                        {
                          id: 'HISTORICAL_PREVIOUS_PERIOD',
                          name: 'PLANNING_CREATE_NEW_BUDGET_PREVIUS_PERIOD_OPTION_FORM_SELECT',
                          type: 'historical',
                        },
                      ].map((op) => {
                        return { ...op, name: t(op.name) }
                      }),
                      dataTooltipLabel: 'PLANNING_UPLOAD_VALUE_CERO_INFORMATIVE_TOOLTIP_TITLE',
                    })}
                  />
                </Form.Item>
              </Col>

              {(hasZeroValue(form.getFieldsValue().type_budget) ||
                hasHistoricalValue(form.getFieldsValue().type_budget)) && (
                <Row gutter={[8, 8]}>
                  <Col span={12}>
                    <Form.Item
                      name="cutoff_date"
                      label={t('FORECAST_CUTTING_MONTH_FORM_CREATE_MODAL_LABEL')}
                      rules={[
                        {
                          required: true,
                          message: t('REQUIRED_FIELD'),
                        },
                      ]}
                    >
                      <DatePicker
                        defaultPickerValue={
                          !_.isEmpty(actualPeriod) ? moment(actualPeriod?.start_date).utc() : null
                        }
                        placeholder={t('FORECAST_CUT_DATE_PLACEHOLDER_DATEPICKER_MODAL')}
                        format="MMMM YYYY"
                        picker="month"
                        style={{ width: 250 }}
                        disabledDate={(date) => disabledDate(date, cutDates, disabledMonths)}
                        onChange={setDate}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      name="dates"
                      label={t('PLANNING_CREATE_NEW_BUDGET_BASE_DATES_FORM_LABEL')}
                      rules={[{ required: true, message: t('REQUIRED_FIELD') }]}
                    >
                      <DatePicker.RangePicker
                        format="MMM YYYY"
                        picker="month"
                        style={{ width: '100%' }}
                        onChange={(val) => {
                          if (!_.isEmpty(val)) {
                            setFilterDates({
                              date_start: moment(val[0]).startOf('month').format('YYYY-MM-DD'),
                              date_end: moment(val[1]).startOf('month').format('YYYY-MM-DD'),
                            })
                          } else {
                            setFilterDates({})
                          }
                        }}
                        disabledDate={(date) => renderDisabledDatePicker(date)}
                        defaultPickerValue={
                          !_.isEmpty(actualPeriod)
                            ? [moment(actualPeriod?.start_date), moment(actualPeriod?.end_date)]
                            : null
                        }
                      />
                    </Form.Item>
                  </Col>
                  {!_.isEmpty(filterDates) && (
                    <Col span={24}>
                      <Form.Item
                        name="projections"
                        label={t('PLANNING_PROJECTION_LIST_FORM_LABEL')}
                        rules={[
                          {
                            required: true,
                            message: t('REQUIRED_FIELD'),
                          },
                        ]}
                      >
                        <Select
                          placeholder={t('ACTION_SELECT')}
                          showArrow
                          allowClear
                          showSearch
                          mode="multiple"
                          maxTagCount={3}
                          maxTagTextLength={1}
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                          options={generateSelectOptions({
                            options: options.map((el) => {
                              return {
                                id: `${el.id}_${el.type}`,
                                name: `${el.name} (${t(el.type)})-(${t(
                                  PROJECTION.STATUS_COLORS[el.status].name,
                                )})`,
                              }
                            }),
                          })}
                        />
                      </Form.Item>
                    </Col>
                  )}
                </Row>
              )}
            </Row>
          </>
        )}
      </Form>
    </Modal>
  )
}

const mapStateToProps = (state) => ({
  periodList: planning.selectors.getPeriodList(state),
  forecastFilterDates: forecast.selectors.getForecastFilterDates(state),
})

const mapDispatchToProps = {
  createForecast: forecast.actions.createForecast,
  fetchForecastFilterDates: forecast.actions.fetchForecastFilterDates,
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateForecastModal)
