import { Col, Typography as TypographyAntd } from 'antd'
import { Typography } from 'modules/core/components'
import { useTranslation } from 'react-i18next'

const { Paragraph } = TypographyAntd

const DefaultElements = ({ item }) => {
  const { t } = useTranslation()

  const renderSymbol = () => {
    if (item.symbol) {
      if (item.symbol.length > 2) {
        return item.symbol.slice(0, 3)
      } else {
        return item.symbol
      }
    } else {
      return '?'
    }
  }

  return (
    <>
      <Col span={4}>
        <div
          title={item.symbol}
          className="currency-card-body-symbol"
          style={{ background: !item.symbol ? '#D9D9D9' : '#98C4ED' }}
        >
          <strong>{renderSymbol()}</strong>
        </div>
      </Col>
      <Col span={4} className="currency-card-body-col">
        <Typography.Body>{item.code}</Typography.Body>
      </Col>
      <Col span={6} className="currency-card-body-col">
        <Typography.Body>{item.name || t('STATE_TAG_UNDEFINED')}</Typography.Body>
      </Col>
      <Col span={10} className="currency-card-body-col">
        {item.description && (
          <div className="currency-card-body-description">
            <Paragraph style={{ margin: 0 }} ellipsis={item.description.length > 50}>
              {item.description}
            </Paragraph>
          </div>
        )}
      </Col>
    </>
  )
}

export default DefaultElements
