import { Spin, Tabs } from 'antd'
import { useQuery } from 'modules/core/customHooks'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { CollapseOp, DimSelector } from './components'
import { ROLES } from 'modules/core/constants'
import configuration from 'modules/configuration'
import _ from 'lodash'

const TabsLicense = ({
  selectRoleDimension,
  roleDimensions,
  isLoadingDimensions,
  setTabKey,
  budgetTypeList,
  visible,
}) => {
  const { t } = useTranslation()
  let query = useQuery()

  return (
    <Tabs
      size="small"
      defaultActiveKey={query.get('tab') !== null ? query.get('tab') : ROLES.ROLES_KEY.FEATURES_KEY}
      onChange={setTabKey}
    >
      <Tabs.TabPane
        tab={t('CONFIG_ROLES_DRAWER_FEATURES_TAB_LABEL')}
        key={ROLES.ROLES_KEY.FEATURES_KEY}
      >
        <CollapseOp budgetTypeList={budgetTypeList} visible={visible} />
      </Tabs.TabPane>
      <Tabs.TabPane tab={t('LABEL_DATA')} key={ROLES.ROLES_KEY.DATA_KEY}>
        <Spin spinning={isLoadingDimensions} />
        {!isLoadingDimensions &&
          _.map(roleDimensions, (dim, key) => (
            <DimSelector
              key={key}
              dimension={{
                id: key,
                url: dim.url,
                label: dim.label,
                value: dim.values,
                all_selected: dim.all_selected,
              }}
              onChange={(values, all_selected) =>
                selectRoleDimension({ dimensionId: key, values, all_selected })
              }
            />
          ))}
      </Tabs.TabPane>
    </Tabs>
  )
}

const mapStateToProps = (state) => ({
  roleDimensions: configuration.selectors.getRoleDimensions(state),
})

const mapDispatchToProps = {
  selectRoleDimension: configuration.actions.selectRoleDimension,
}

export default connect(mapStateToProps, mapDispatchToProps)(TabsLicense)
