import React, { useState } from 'react'
import { Modal, Form, Row, Col, Input, Select, message, notification } from 'antd'
import { generateMessageError, generateSelectOptions } from 'modules/core/utils'
import { PROJECTION } from 'modules/core/constants'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import configuration from 'modules/configuration'

const FormItem = Form.Item

// TODO: AGREGAR CREACION MULTIPLE CUANDO SE ACEPTE DESDE BACK.
const CreateRuleDetailModal = ({
  visible,
  onCancel,
  optionsBehaviour,
  createFactor,
  refresh = () => {},
}) => {
  const [form] = Form.useForm()
  const [isCreating, setIsCreating] = useState(false)
  let { formulaId } = useParams()
  const { t } = useTranslation()

  const onClose = () => {
    form.resetFields()
    onCancel()
  }

  const handleCreateFactor = () => {
    form
      .validateFields()
      .then((values) => {
        const data = {
          ...values,
          formula: formulaId,
        }
        setIsCreating(true)
        createFactor(data)
          .then(() => {
            refresh()
            setIsCreating(false)
            message.success(t('FEEDBACK_CREATE_SUCCESS'), 8)
            onClose()
          })
          .catch((error) => {
            notification.error({
              message: t('FEEDBACK_DEFAULT_ERROR'),
              description: generateMessageError(error, 'FEEDBACK_CREATION_FAIL'),
              duration: 0,
            })
            setIsCreating(false)
          })
      })
      .catch((e) => {})
  }

  return (
    <Modal
      title={t('CONFIG_FACTOR_CREATE_ACTION')}
      visible={visible}
      okText={t('CONFIG_FACTOR_CREATE_ACTION')}
      onOk={handleCreateFactor}
      cancelText={t('ACTION_CANCEL')}
      onCancel={onClose}
      closable
      centered
      width={560}
      bodyStyle={{ overflowY: 'auto', maxHeight: 400 }}
      okButtonProps={{ loading: isCreating }}
      cancelButtonProps={{ disabled: isCreating }}
    >
      <Form form={form} layout="vertical" hideRequiredMark={false}>
        <Row gutter={24}>
          <Col span={12}>
            <FormItem
              name="name"
              label={t('FIELD_NAME')}
              rules={[{ required: true, message: t('REQUIRED_FIELD') }]}
            >
              <Input placeholder={t('CONFIG_FACTOR_CREATE_MODAL_PLACEHOLDER')} />
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem
              name="type"
              label={t('FIELD_TYPE')}
              rules={[{ required: true, message: t('REQUIRED_FIELD') }]}
            >
              <Select
                placeholder={t('ACTION_SELECT')}
                options={generateSelectOptions({
                  options: PROJECTION.FACTORS_TYPE_OPTIONS.map((factor) => {
                    return { ...factor, name: t(factor.name) }
                  }),
                })}
              />
            </FormItem>
          </Col>
          <Col span={24}>
            <FormItem
              name="behaviour"
              label={t('LABEL_FORMAT')}
              rules={[{ required: true, message: t('REQUIRED_FIELD') }]}
            >
              <Select
                placeholder={t('ACTION_SELECT')}
                options={generateSelectOptions({ options: optionsBehaviour })}
              />
            </FormItem>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

const mapDispatchToProps = {
  createFactor: configuration.actions.createFactor,
}

export default connect(null, mapDispatchToProps)(CreateRuleDetailModal)
