import { Input, Spin, Typography } from 'antd'

const { Text } = Typography

const CardTitle = ({ editName, name, handleEdit, isEditing }) => {
  return (
    <Spin spinning={isEditing}>
      {editName ? (
        <Input
          autoFocus
          disabled={isEditing}
          defaultValue={name}
          onBlur={(e) => handleEdit(e.target.value)}
          onPressEnter={(e) => handleEdit(e.target.value)}
        />
      ) : (
        <Text title={name} style={{ width: 250 }} ellipsis={true}>
          {name}
        </Text>
      )}
    </Spin>
  )
}

export default CardTitle
