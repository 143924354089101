import { Button, ButtonActionBar, Typography } from 'modules/core/components'
import { DownloadOutlined } from '@ant-design/icons'

const TableActions = ({ downloadReportStructure }) => {
  return (
    <ButtonActionBar>
      <Button.Icon
        title="ACTION_DOWNLOAD_TABLE"
        icon={<Typography.Icon icon={DownloadOutlined} />}
        onClick={downloadReportStructure}
      />
    </ButtonActionBar>
  )
}

export default TableActions
