import React from 'react'
import { Col, Divider, Empty, Row, Spin } from 'antd'
import {
  CardData,
  CardEmpty,
} from 'modules/core/components/Comments/components/CommentsContent/components'
import { useTranslation } from 'react-i18next'

const CommentData = ({
  userDetail,
  data,
  setData,
  editId,
  onClickEdit,
  onClickReplies,
  dataSource,
  onCommentCreate,
  loading,
  usersList,
  onRefetchComments,
  noDataDescription,
  onCleanHasComment,
}) => {
  const { t } = useTranslation()

  return (
    <>
      {dataSource.length === 0 && <Empty description={t(noDataDescription)} />}
      {dataSource.length > 0 && (
        <Spin spinning={loading}>
          <Row gutter={[8, 8]} style={{ width: 315, maxHeight: '68vh', overflowY: 'auto' }}>
            {dataSource.map((comment, index) => (
              <>
                <Col span={24}>
                  {index !== 0 && <Divider style={{ marginTop: 10, marginBottom: 10 }} />}
                </Col>
                <Col key={comment.key} span={24}>
                  <CardData
                    userDetail={userDetail}
                    el={comment}
                    editId={editId}
                    onClickEdit={onClickEdit}
                    onClickReplies={() => onClickReplies(comment.id)}
                    isParent={true}
                    usersList={usersList}
                    onRefetchComments={onRefetchComments}
                    onCleanHasComment={() => onCleanHasComment(comment.key, comment.module)}
                  />
                </Col>
                {comment.replies.length > 0 &&
                  comment.viewReplies &&
                  comment.replies.map((reply) => (
                    <>
                      <Col span={2} />
                      <Col key={reply.key} span={22}>
                        <CardData
                          userDetail={userDetail}
                          el={reply}
                          editId={editId}
                          onClickEdit={onClickEdit}
                          usersList={usersList}
                          onRefetchComments={onRefetchComments}
                          onCleanHasComment={() => onCleanHasComment(comment.key, comment.module)}
                        />
                      </Col>
                      <Col span={24}>
                        <Divider style={{ marginTop: 10, marginBottom: 10 }} />
                      </Col>
                    </>
                  ))}
                {comment.viewReplies && !comment.resolved && (
                  <>
                    <Col span={2} />
                    <Col span={22}>
                      <CardEmpty
                        userDetail={userDetail}
                        data={data}
                        setData={setData}
                        onCommentCreate={(text, usersIds) =>
                          onCommentCreate(text, usersIds, comment)
                        }
                        usersList={usersList}
                      />
                    </Col>
                  </>
                )}
              </>
            ))}
          </Row>
        </Spin>
      )}
    </>
  )
}

export default CommentData
