import React from 'react'
import { Input, Space } from 'antd'
import {
  ButtonActionBar,
  CreateReportModal,
  CustomBottomAction,
  DropdownMenu,
} from 'modules/core/components'
import { ROLES } from 'modules/core/constants'
import { SolutionOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'

const { Search } = Input

const { SETTINGS__REPORTS__REPORTSPNL__ABM } = ROLES

const UserActions = ({
  onRefetch,
  setSearchValue,
  dataPeriod,
  onClickSpreadsheets,
  onClickActivity,
}) => {
  const { t } = useTranslation()
  return (
    <ButtonActionBar>
      <Space size="middle">
        <Search placeholder={t('ACTION_SEARCH')} allowClear={true} onSearch={setSearchValue} />
        <CreateReportModal
          rolesAvailables={[SETTINGS__REPORTS__REPORTSPNL__ABM]}
          onFetchReportList={onRefetch}
          dataPeriod={dataPeriod}
          onClickSpreadsheets={onClickSpreadsheets}
        />
        <CustomBottomAction extraButtons={{ module_name: 'reportes', concept: '1' }} />
        <DropdownMenu
          title={t('ACTION_MORE')}
          menu={[
            {
              title: t('ACTION_ACTIVITIES'),
              icon: <SolutionOutlined />,
              onClick: onClickActivity,
            },
          ]}
        />
      </Space>
    </ButtonActionBar>
  )
}

export default UserActions
