import { Layout, Space } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { NavLink, withRouter } from 'react-router-dom'
import adminMenu from './adminMenu'

import '../SiderLayout/SiderLayout.scss'

const { Content, Sider } = Layout

const AdminLayout = ({ _match, header = null, children, className }) => {
  const { t } = useTranslation()

  return (
    <Layout className={`sider-layout ${className ? className : ''}`}>
      <Sider
        className="sider-layout-menu"
        collapsible
        collapsed={true}
        trigger={null}
        collapsedWidth={56}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100%',
            paddingBottom: 10,
          }}
        >
          <Space className="sub-layout-actions" align="center" direction="vertical" size="large">
            {adminMenu(t).map((item, index) => {
              const { title, url, exact, icon } = item
              return (
                <NavLink
                  key={index}
                  className="menu-icon"
                  to={url}
                  exact={exact}
                  activeClassName="selected"
                  title={title}
                >
                  {icon}
                </NavLink>
              )
            })}
          </Space>
        </div>
      </Sider>
      <Layout>
        {header}
        <Content className={`sider-layout-content ${className ? className : ''}`}>
          {children}
        </Content>
      </Layout>
    </Layout>
  )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AdminLayout))
