import React, { useState, useEffect, useMemo } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'modules/core/customHooks'
import { Spin, Tabs, Space } from 'antd'
import { Navigator, PeriodNavigator } from 'modules/core/components'
import { SiderLayout } from 'modules/core/layouts'
import { PROJECTION } from 'modules/core/constants'
import { client } from 'httpclient'
import { connect } from 'react-redux'
import ActivityTrackingDetail from './ActivityTrackingDetail'
import processes from 'modules/processes'
import _ from 'lodash'

import './ProjectionTrackingScreen.scss'

const { TabPane } = Tabs

const ACTIVITY = 'activity'

const ProjectionTrackingScreen = ({ fetchAuditList, auditList }) => {
  const [isScreenLoading, setIsScreenLoading] = useState(false)
  const [projectionDetail, setProjectionDetail] = useState({})

  const { t } = useTranslation()

  let {
    periodId,
    projectionId,
    conceptName,
    forecastId,
    step,
    reportId,
    dimensionId,
    globalBudgetId,
    globalForecastId,
  } = useParams()
  let query = useQuery()

  const urlParams = new URLSearchParams(useLocation().search)
  const type = urlParams.get('type')
  const module = urlParams.get('module')

  const FETCH_URL_DETAIL = useMemo(() => {
    return {
      planning: {
        url: module && module === 'planningDetail' ? `/planning/get/${projectionId}` : '',
        key:
          module && module === 'planningDetail'
            ? `${projectionId}-${step && step.toLowerCase()}`
            : 'all',
      },
      [PROJECTION.FORECAST]: {
        url: module && module === 'forecastDetail' ? `/planning/forecast/get/${projectionId}` : '',
        key:
          module && module === 'forecastDetail'
            ? `${projectionId}-${step && step.toLowerCase()}`
            : module === 'forecast'
            ? forecastId
            : 'all',
      },
      [PROJECTION.REAL]: {
        url: '',
        key: module && module === 'real' ? 'all' : `${periodId}-${projectionId}`,
      },
      report: {
        url:
          module && (module === 'analisisDetail' || module === 'configDetail')
            ? `/reports/${reportId}/get_reports_structure/`
            : '',
        key:
          module && (module === 'analisisDetail' || module === 'configDetail') ? reportId : 'all',
      },
      users: {
        url: '',
        key: 'all',
      },
      dimension: {
        url: module && module === 'configDimensionValue' ? '/dimensions/all_dimensions' : '',
        key: module && module === 'configDimensionValue' ? dimensionId : 'all',
      },
      currency: {
        url: '',
        key: 'all',
      },
      planning_overall_budget: {
        url:
          module && module === 'globalBudgetDetail'
            ? `/planning/overall_budget/get/${globalBudgetId}/`
            : '',
        key: module && module === 'globalBudgetDetail' ? globalBudgetId : 'all',
      },
      forecast_overall_budget: {
        url:
          module && module === 'globalForecastDetail'
            ? `/planning/forecast/overall_budget/get/${globalForecastId}/`
            : '',
        key: module && module === 'globalForecastDetail' ? globalForecastId : 'all',
      },
      periods: {
        url: '',
        key: 'all',
      },
    }
  }, [
    projectionId,
    periodId,
    step,
    reportId,
    module,
    dimensionId,
    globalBudgetId,
    globalForecastId,
  ])

  const breadcum = {
    analisisDetail: [
      { name: t('LABEL_ANALYSIS'), url: `/analisis/${periodId}` },
      { name: t('REPORT_PLIKA_CARD_TITLE') },
      { name: projectionDetail.name, url: `/analisis/${periodId}/reportesplika/${reportId}` },
    ],
    config: [
      { name: t('LABEL_CONFIG'), url: `/configuracion` },
      { name: t('LABEL_REPORTS'), url: `/configuracion/reportes` },
    ],
    analisis: [{ name: t('LABEL_ANALYSIS'), url: `/analisis/${periodId}` }],
    configDetail: [
      { name: t('LABEL_CONFIG'), url: `/configuracion` },
      { name: t('LABEL_REPORTS'), url: `/configuracion/reportes` },
      { name: projectionDetail.name, url: `/configuracion/reportes/${reportId}` },
    ],
    real: [
      {
        name: t('LABEL_CONTROL'),
        url: `/seguimiento/${periodId}`,
      },
    ],
    realDetail: [
      {
        name: t('LABEL_CONTROL'),
        url: `/seguimiento/${periodId}`,
      },
      {
        name: conceptName,
        url: `/seguimiento/${periodId}/${conceptName}/${projectionId}`,
      },
    ],
    planning: [{ name: t('LABEL_PLANNING'), url: `/planificacion/${periodId}` }],
    planningDetail: [
      { name: t('LABEL_PLANNING'), url: `/planificacion/${periodId}` },
      {
        name: projectionDetail.name,
        url: `/planificacion/${periodId}/${conceptName}/${projectionId}`,
      },
    ],
    forecastGeneral: [{ name: t('LABEL_FORECAST'), url: `/forecast/${periodId}` }],
    forecast: [
      { name: t('LABEL_FORECAST'), url: `/forecast/${periodId}` },
      {
        name: `${t('LABEL_DETAIL')}: ${t('LABEL_FORECAST')}`,
        url: `/forecast/${periodId}/${forecastId}`,
      },
    ],
    forecastDetail: [
      { name: t('LABEL_FORECAST'), url: `/forecast/${periodId}` },
      {
        name: `${t('LABEL_DETAIL')}: ${t('LABEL_FORECAST')}`,
        url: `/forecast/${periodId}/${forecastId}`,
      },
      {
        name: projectionDetail.name,
        url: `/forecast/${periodId}/${forecastId}/${conceptName}/${projectionId}/`,
      },
    ],
    configDimension: [
      {
        name: t('LABEL_CONFIG'),
        url: `/configuracion`,
      },
      { name: t('LABEL_DIMENSIONS'), url: '/configuracion/dimensiones' },
    ],
    configDimensionValue: [
      {
        name: t('LABEL_CONFIG'),
        url: `/configuracion`,
      },
      {
        name: t('LABEL_DIMENSIONS'),
        url: '/configuracion/dimensiones',
      },
      {
        name:
          !_.isEmpty(projectionDetail) && projectionDetail?.results?.length > 0
            ? projectionDetail?.results?.find((dim) => dim.id === parseInt(dimensionId))
                ?.name_destination
            : '',
        url: `/configuracion/dimensiones/${dimensionId}`,
      },
    ],
    globalBudget: [
      {
        name: t('LABEL_PLANNING'),
        url: `/planificacion/${periodId}`,
      },
      { name: t('LABEL_GLOBAL_BUDGET'), url: `/planificacion/${periodId}?tab=globalBudget` },
    ],
    globalBudgetDetail: [
      {
        name: t('LABEL_PLANNING'),
        url: `/planificacion/${periodId}`,
      },
      {
        name: t('LABEL_GLOBAL_BUDGET'),
        url: `/planificacion/${periodId}?tab=${PROJECTION.GLOBAL_BUDGET}`,
      },
      {
        name: projectionDetail.name,
        url: `/planificacion/${periodId}/presupuestoGlobal/${globalBudgetId}`,
      },
    ],
    globalForecast: [
      {
        name: t('LABEL_FORECAST'),
        url: `/forecast/${periodId}`,
      },
      {
        name: `${t('LABEL_DETAIL')}: ${t('LABEL_FORECAST')}`,
        url: `/forecast/${periodId}/${forecastId}`,
      },
      {
        name: t('LABEL_GLOBAL_FORECAST'),
        url: `/forecast/${periodId}/${forecastId}?tab=globalForecast`,
      },
    ],
    globalForecastDetail: [
      {
        name: t('LABEL_FORECAST'),
        url: `/forecast/${periodId}`,
      },
      {
        name: `${t('LABEL_DETAIL')}: ${t('LABEL_FORECAST')}`,
        url: `/forecast/${periodId}/${forecastId}`,
      },
      {
        name: t('LABEL_GLOBAL_FORECAST'),
        url: `/forecast/${periodId}/${forecastId}?tab=globalForecast`,
      },
      {
        name: projectionDetail.name,
        url: `/forecast/${periodId}/${forecastId}/forecastglobal/${globalForecastId}`,
      },
    ],
  }

  useEffect(() => {
    if (FETCH_URL_DETAIL[type]) {
      setIsScreenLoading(true)
      client
        .get(`${FETCH_URL_DETAIL[type].url}`)
        .then((response) => {
          setProjectionDetail(response.data)
          setIsScreenLoading(false)
        })
        .catch(() => setIsScreenLoading(false))
    } else {
      setIsScreenLoading(false)
    }
  }, [type, projectionId, FETCH_URL_DETAIL])

  useEffect(() => {
    setIsScreenLoading(true)
    fetchAuditList(type, FETCH_URL_DETAIL[type].key).then(() => setIsScreenLoading(false))
  }, [fetchAuditList, type, FETCH_URL_DETAIL])

  const renderHeader = () => {
    const customItem = () => {
      switch (type) {
        case 'planning':
          return breadcum[module]
        case PROJECTION.REAL:
          return breadcum[module]
        case PROJECTION.FORECAST:
          return breadcum[module]
        case 'report':
          return breadcum[module]
        case 'users':
          return [
            { name: t('LABEL_CONFIG'), url: `/configuracion` },
            { name: t('LABEL_SECURITY'), url: `/configuracion/seguridad?tab=users` },
            { name: t('LABEL_USERS') },
          ]
        case 'dimension':
          return breadcum[module]
        case 'currency':
          return [
            {
              name: t('LABEL_CONFIG'),
              url: `/configuracion`,
            },
            { name: t('LABEL_TOOLS'), url: '/configuracion/herramientas?tab=currency' },
            {
              name: t('LABEL_CURRENCY'),
            },
          ]
        case 'planning_overall_budget':
          return breadcum[module]
        case 'forecast_overall_budget':
          return breadcum[module]
        case 'periods':
          return [{ name: t('LABEL_PERIODS'), url: '/periodos' }]
        default:
          return []
      }
    }

    return module.includes('config') ? (
      <Navigator items={[...customItem(), ...[{ name: t('LABEL_ACTIVITIES') }]]} />
    ) : (
      <PeriodNavigator items={[...customItem(), ...[{ name: t('LABEL_ACTIVITIES') }]]} />
    )
  }

  const renderTrackingTabs = () => (
    <Tabs defaultActiveKey={query.get('tab') !== null ? query.get('tab') : ACTIVITY}>
      <TabPane tab={t('LABEL_ACTIVITY')} key={ACTIVITY}>
        <Space className="projection-tracking-screen-space" direction="vertical" size="large">
          <ActivityTrackingDetail dataSource={auditList} loading={isScreenLoading} />
        </Space>
      </TabPane>
    </Tabs>
  )

  return (
    <SiderLayout className="projection-tracking-screen">
      <Spin spinning={isScreenLoading} size="large" />
      {!isScreenLoading && (
        <>
          {renderHeader()}
          {renderTrackingTabs()}
        </>
      )}
    </SiderLayout>
  )
}

const mapStateToProps = (state) => ({
  auditList: processes.selectors.getAuditList(state),
})

const mapDispatchToProps = {
  fetchAuditList: processes.actions.fetchAuditList,
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectionTrackingScreen)
