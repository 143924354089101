import { Input, message, Space, Tabs } from 'antd'
import { Typography } from 'modules/core/components'
import { useQuery } from 'modules/core/customHooks'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CommentData } from './components'
import { connect } from 'react-redux'
import configuration from 'modules/configuration'
import { generateMessageError } from 'modules/core/utils'

const ACTIVE_KEY = 'active'
const SOLVED_KEY = 'resolved'

const { Search } = Input

const AllComments = ({
  userDetail,
  comments,
  setData,
  onRefetchComments,
  createComment,
  fetchUsersList,
  usersList,
  setHasComment,
}) => {
  const [editId, setEditId] = useState(null)
  const [searchValue, setSearchValue] = useState('')
  const [tabKet, setTabKet] = useState(ACTIVE_KEY)
  const [isSaving, setIsSaving] = useState(false)
  const { t } = useTranslation()
  let query = useQuery()

  useEffect(() => {
    fetchUsersList()
  }, [fetchUsersList])

  const handleViewReplies = (id) => {
    setData((prevState) =>
      prevState.map((el) => {
        if (el.id === id) {
          return {
            ...el,
            viewReplies: !el.viewReplies,
          }
        }
        return el
      }),
    )
  }

  //reveer
  const getDataSource = (dataComments) => {
    if (searchValue) {
      return dataComments.filter((el) => el.text.toLowerCase().includes(searchValue.toLowerCase()))
    } else {
      return dataComments
    }
  }

  const handleCreate = ({ text, usersIds, comment }) => {
    const dataa = {
      text,
      key: comment.key,
      module: comment.module,
      mentions: usersIds,
      parent: comment.id,
    }
    setIsSaving(true)
    createComment(dataa)
      .then(() =>
        onRefetchComments().then(() => {
          message.success(t('FEEDBACK_SAVE_CHANGES_SUCCES'), 8)
          setIsSaving(false)
        }),
      )
      .catch((error) => {
        message.error(generateMessageError(error, 'FEEDBACK_SAVE_CHANGES_FAIL_MESSAGE'), 8)
        setIsSaving(false)
      })
  }

  const handleOnClean = (key, module) => {
    const identifyData = key.split(module)[1]
    const type = identifyData.split('-')[1]
    const id = identifyData.split('-')[2]
    const data = {
      type,
      data: false,
      ...(type !== 'table' && {
        key: module,
        id,
      }),
    }
    setHasComment(data)
  }

  return (
    <Space style={{ width: '100%' }} direction="vertical">
      <Typography.Subtitle>{t('LABEL_COMMENTS')}</Typography.Subtitle>
      <Search
        placeholder={t('ACTION_SEARCH')}
        allowClear={true}
        onChange={(val) => setSearchValue(val.target.value)}
      />
      <Tabs
        onChange={(val) => setTabKet(val)}
        size="small"
        defaultActiveKey={query.get('tab') !== null ? query.get('tab') : 'active'}
      >
        <Tabs.TabPane tab={t('LABEL_ACTIVE')} key={ACTIVE_KEY}>
          {tabKet === ACTIVE_KEY && (
            <CommentData
              userDetail={userDetail}
              data={comments.filter((el) => !el.resolved && el.text)}
              setData={setData}
              editId={editId}
              onClickEdit={setEditId}
              onClickReplies={(parent) => handleViewReplies(parent)}
              dataSource={getDataSource(comments.filter((el) => !el.resolved && el.text))}
              onCommentCreate={(text, usersIds, comment) =>
                handleCreate({ text, usersIds, comment })
              }
              loading={isSaving}
              usersList={usersList}
              onRefetchComments={onRefetchComments}
              noDataDescription="EMPTY_NOT_COMMENTS_DESCRIPTION"
              onCleanHasComment={(key, module) => handleOnClean(key, module)}
            />
          )}
        </Tabs.TabPane>
        <Tabs.TabPane tab={t('LABEL_SOLVED')} key={SOLVED_KEY}>
          {tabKet === SOLVED_KEY && (
            <CommentData
              userDetail={userDetail}
              data={comments.filter((el) => el.resolved && el.text)}
              setData={setData}
              editId={editId}
              onClickEdit={setEditId}
              onClickReplies={(parent) => handleViewReplies(parent)}
              dataSource={getDataSource(comments.filter((el) => el.resolved && el.text))}
              onCommentCreate={(text, usersIds, comment) =>
                handleCreate({ text, usersIds, comment })
              }
              loading={isSaving}
              usersList={usersList}
              onRefetchComments={onRefetchComments}
              noDataDescription="EMPTY_NOT_SOLVED_COMMENTS_DESCRIPTION"
              onCleanHasComment={(key, module) => handleOnClean(key, module)}
            />
          )}
        </Tabs.TabPane>
      </Tabs>
    </Space>
  )
}

const mapStateToProps = (state) => ({
  usersList: configuration.selectors.getUsersList(state),
})

const mapDispatchToProps = {
  createComment: configuration.actions.createComment,
  fetchUsersList: configuration.actions.fetchUsersList,
  setHasComment: configuration.actions.setHasComment,
}

export default connect(mapStateToProps, mapDispatchToProps)(AllComments)
