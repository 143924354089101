import { message } from 'antd'
import { generateMessageError } from 'modules/core/utils'
import i18n from 'i18next'

export const handleCellEdit = ({ updateTransactionPercentage, onRefetch }) => {
  message.loading({
    key: 'loading_edit',
    content: i18n.t('FEEDBACK_LOADING_CHANGES'),
  })
  updateTransactionPercentage()
    .then(() => {
      onRefetch()
      message.destroy('loading_edit')
      message.success(i18n.t('FEEDBACK_SAVE_CHANGES_SUCCES', 8))
    })
    .catch((error) => message.error(generateMessageError(error, 'FEEDBACK_DEFAULT_ERROR'), 8))
}

export const handleApply = ({ applyRdnPercentage, onRefetch }) => {
  message.loading({
    key: 'loading_edit',
    content: i18n.t('FEEDBACK_LOADING_CHANGES'),
  })
  applyRdnPercentage()
    .then(() => {
      onRefetch()
      message.destroy('loading_edit')
      message.success(i18n.t('FEEDBACK_SAVE_CHANGES_SUCCES', 8))
    })
    .catch((error) => message.error(generateMessageError(error, 'FEEDBACK_DEFAULT_ERROR'), 8))
}

export const handleUnApply = ({ unApplyRdnPercentage, onRefetch }) => {
  message.loading({
    key: 'loading_edit',
    content: i18n.t('FEEDBACK_LOADING_CHANGES'),
  })
  unApplyRdnPercentage()
    .then(() => {
      onRefetch()
      message.destroy('loading_edit')
      message.success(i18n.t('FEEDBACK_SAVE_CHANGES_SUCCES', 8))
    })
    .catch((error) => message.error(generateMessageError(error, 'FEEDBACK_DEFAULT_ERROR'), 8))
}
