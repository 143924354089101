import React from 'react'
import { connect } from 'react-redux'
import { SiderLayout } from 'modules/core/layouts'
import { Button, ButtonActionBar, EmptyScreen } from 'modules/core/components'
import { Row, Col } from 'antd'
import { isUserAllowed, getPeriodOperationsKeys, getOperationsByKeys } from 'modules/core/utils'
import { ROLES } from 'modules/core/constants'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import {
  DashboardHeader,
  DashboardSubtitle,
  ListCardsNews,
  // ListCollapseContacts,
} from './components'
import emptyStateImage from 'assets/images/compositions/empty-big.svg'
import login from 'modules/login'

import './DashboardGeneral.scss'

const { SETTINGS } = ROLES

const DashboardGeneral = ({ loggedUser: { firstName }, hasDataConfig }) => {
  let history = useHistory()
  const { t } = useTranslation()

  const canGoToPeriods = isUserAllowed(getPeriodOperationsKeys())
  const canGoToConfig = isUserAllowed(getOperationsByKeys([SETTINGS]))

  const renderVideo = () => (
    <iframe
      width="100%"
      height="100%"
      src="https://www.youtube.com/embed/videoseries?list=PLJo0bl0cWTAO31mQYzB1FH3ErJFWmUi1t"
      title="YouTube video player"
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    ></iframe>
  )

  const renderNoDataScreen = () => (
    <EmptyScreen
      image={emptyStateImage}
      title={t('DASHBOARD_WELLCOME')}
      description={t('DASHBOARD_EMPTY_STATE_DESCRIPTION')}
      footer={
        <ButtonActionBar>
          {canGoToConfig && (
            <Button.Primary
              onClick={() => history.push(`/configuracion`)}
              title="DASHBOARD_CONFIG_ACTION"
            />
          )}
          {canGoToPeriods && (
            <Button.Primary
              onClick={() => history.push(`/periodos`)}
              title="DASHBOARD_PERIODS_ENTER_ACTION"
            />
          )}
        </ButtonActionBar>
      }
    />
  )

  return (
    <SiderLayout>
      {hasDataConfig && (
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <DashboardHeader firstName={firstName} />
          </Col>
          <Col span={24}>
            <DashboardSubtitle name="DASHBOARD_NEWS_PLIKA" />
            <ListCardsNews />
          </Col>
          <Col span={24}>
            <DashboardSubtitle name="DASHBOARD_TUTORIALS" />
            {renderVideo()}
          </Col>
          {/* <Col span={12}>
            <DashboardSubtitle name="DASHBOARD_SUPPORT_INFO" />
            <ListCollapseContacts />
          </Col> */}
        </Row>
      )}
      {!hasDataConfig && renderNoDataScreen()}
    </SiderLayout>
  )
}

const mapStateToProps = (state) => ({
  loggedUser: login.selectors.getWhoAmI(state),
})

export default connect(mapStateToProps, null)(DashboardGeneral)
