import React from 'react'
import { Button, ButtonActionBar } from 'modules/core/components'
import { RightCircleOutlined } from '@ant-design/icons'

const CardFooter = ({ onClick, extraFooter, disabled }) => {
  return (
    <ButtonActionBar position="space-between">
      <Button.LinkWhitRightIcon
        onClick={onClick}
        title="ACTION_ENTER"
        icon={<RightCircleOutlined />}
        disabled={disabled}
      />
      {extraFooter}
    </ButtonActionBar>
  )
}

export default CardFooter
