import React from 'react'
import { Select } from 'antd'
import { generateSelectOptions } from 'modules/core/utils'
import { useTranslation } from 'react-i18next'

const CardTitle = ({
  type,
  reportStructure,
  lineReportId,
  setLineReportId,
  isLinesReportLoading,
}) => {
  const { t } = useTranslation()
  return (
    <Select
      placeholder={t('ACTION_SELECT')}
      value={lineReportId[type]}
      style={{ width: '100%' }}
      onChange={(value) => setLineReportId({ ...lineReportId, [type]: value })}
      bordered={false}
      loading={isLinesReportLoading}
      options={generateSelectOptions({ options: reportStructure })}
    />
  )
}

export default CardTitle
