import React from 'react'
import { Col, Radio, Row } from 'antd'
import { Typography } from 'modules/core/components'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { PROJECTION_ELEMENTS } from 'modules/core/constants'
import { isOtherDestination } from '../../../../utils'
import configuration from 'modules/configuration'
import CombinationDimensionsTable from '../CombinationDimensionsTable/CombinationDimensionsTable'

const DESTINATION_TYPES_OPTIONS = [
  {
    id: PROJECTION_ELEMENTS.RULE.KEYS.TAKE_ORIGIN_KEY,
    name: 'CONFIG_RULES_DESTINATION_VALUES_STEP_DESTINATION_TAKE_ORIGIN_RADIO_BUTTON_OPTION',
  },
  {
    id: PROJECTION_ELEMENTS.RULE.KEYS.OTHER_DESTINATION_KEY,
    name: 'CONFIG_RULES_DESTINATION_VALUES_STEP_DESTINATION_TDIFFERENT_RADIO_BUTTON_OPTION',
  },
]

const DestinationDimensions = ({
  loadingTable,
  loading,
  dataKey,
  dataDestinationOption: { setDestinationOption, destinationOption },
  rowsDimensions,
  setDimensionsSelection,
  allDimensionsList,
  dimensionsSelection,
  areAllSelected,
  setAreAllSelected,
}) => {
  const { t } = useTranslation()
  return (
    <Row gutter={[12, 18]}>
      <Col span={24}>
        <Typography.Body level={2}>
          {t('CONFIG_RULES_DESTINATION_VALUES_STEP_DESTINATION_SUBTITLE')}
        </Typography.Body>
      </Col>
      <Col span={24}>
        <Radio.Group value={destinationOption}>
          {DESTINATION_TYPES_OPTIONS.map((option) => (
            <Radio
              key={option.id}
              value={option.id}
              onChange={(value) => setDestinationOption(value.target.value)}
            >
              {t(option.name)}
            </Radio>
          ))}
        </Radio.Group>
      </Col>
      {isOtherDestination(destinationOption) && (
        <Col span={24}>
          <CombinationDimensionsTable
            dataSource={rowsDimensions.target_dimensions}
            loadingTable={loadingTable}
            dataKey={dataKey}
            loading={loading}
            setDimensionsSelection={setDimensionsSelection}
            dimensionsSelection={dimensionsSelection.target_dimensions}
            dimensionsList={allDimensionsList}
            areAllSelected={areAllSelected}
            setAreAllSelected={setAreAllSelected}
          />
        </Col>
      )}
    </Row>
  )
}

const mapStateToProps = (state) => ({
  rowsDimensions: configuration.selectors.getRowsDimensions(state),
  dimensionsSelection: configuration.selectors.getDimensionsSelection(state),
  allDimensionsList: configuration.selectors.getAllDimensionsList(state),
})

const mapDispatchToProps = {
  setDimensionsSelection: configuration.actions.setDimensionsSelection,
}

export default connect(mapStateToProps, mapDispatchToProps)(DestinationDimensions)
