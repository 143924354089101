import { message, notification } from 'antd'
import { generateMessageError } from 'modules/core/utils'
import i18n from 'i18next'

export const handleDeleteAll = ({ setIsLoading, deleteAll, onRefetch, onClose }) => {
  setIsLoading(true)
  deleteAll()
    .then(() =>
      onRefetch().then(() => {
        message.success(i18n.t('FEEDBACK_DELETE_DATA_SUCCESS'), 8)
        onClose()
      }),
    )
    .catch((error) => {
      notification.error({
        message: i18n.t('FEEDBACK_DEFAULT_ERROR'),
        description: generateMessageError(error, 'FEEDBACK_DELETE_DATA_ERROR'),
        duration: 0,
      })
      onClose()
    })
}
