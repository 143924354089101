import React from 'react'
import { Input, Space } from 'antd'
import { Button, ButtonActionBar, DropdownMenu } from 'modules/core/components'
import { useTranslation } from 'react-i18next'
import { SolutionOutlined } from '@ant-design/icons'

const { Search } = Input

const GeneralActions = ({
  setSearchValue,
  setShowCreateModal,
  userCanCreate,
  isSuperuser = false,
  onClickConfigurate = null,
  onClickActivity,
}) => {
  const { t } = useTranslation()
  return (
    <ButtonActionBar>
      <Space size="middle">
        <Search
          className="user-search"
          placeholder={t('ACTION_SEARCH')}
          allowClear={true}
          onSearch={(search) => setSearchValue(search)}
        />
        <Button.Primary
          onClick={() => setShowCreateModal(true)}
          disabled={!userCanCreate}
          title="ACTION_CREATE_NEW"
        />
        {isSuperuser && <Button.Default title="ACTION_CONFIGURATE" onClick={onClickConfigurate} />}
        {!isSuperuser && (
          <DropdownMenu
            title={t('ACTION_MORE')}
            menu={[
              {
                title: t('ACTION_ACTIVITIES'),
                icon: <SolutionOutlined />,
                onClick: onClickActivity,
              },
            ]}
          />
        )}
      </Space>
    </ButtonActionBar>
  )
}

export default GeneralActions
