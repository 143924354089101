import React from 'react'
import { Col, Radio, Row, Space } from 'antd'
import { ContainerDataFormula, Typography } from 'modules/core/components'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { PROJECTION_ELEMENTS } from 'modules/core/constants'
import { handleVariable } from 'modules/core/components/RulesComponents/utils'
import configuration from 'modules/configuration'

const Formula = ({ setDataFormula, dataFormula, variablesList }) => {
  const { t } = useTranslation()

  return (
    <Row gutter={[12, 18]}>
      <Col span={24}>
        <Space direction="vertical">
          <Typography.Body level={2}>
            {t('CONFIG_RULES_FORMULA_VALUES_STEP_FORMULA_SUBTITLE')}
          </Typography.Body>
          <Radio.Group value={dataFormula.formula_type}>
            {PROJECTION_ELEMENTS.FORMULA_TYPES_OPTIONS.map((option) => (
              <Radio
                key={option.id}
                value={option.id}
                onChange={(value) => setDataFormula({ formula_type: value.target.value })}
              >
                {t(option.name)}
              </Radio>
            ))}
          </Radio.Group>
        </Space>
      </Col>
      <ContainerDataFormula
        variablesIds={dataFormula.variablesIds}
        value={dataFormula.formula}
        onChangeFormula={(value) => {
          setDataFormula({ formula: value, variablesIds: {} })
          handleVariable(value, dataFormula, setDataFormula)
        }}
        variablesList={variablesList?.values}
        onChangeVariable={(id, variable) =>
          setDataFormula({ variablesIds: { ...dataFormula.variablesIds, [variable]: id } })
        }
      />
    </Row>
  )
}

const mapStateToProps = (state) => ({
  variablesList: configuration.selectors.getVariablesList(state),
  dataFormula: configuration.selectors.getDataFormula(state),
})

const mapDispatchToProps = {
  setDataFormula: configuration.actions.setDataFormula,
}

export default connect(mapStateToProps, mapDispatchToProps)(Formula)
