import ButtonPrimary from '../../ButtonPrimary'

const ButtonPrimaryWhitRightIcon = ({
  title,
  icon = null,
  disabled = false,
  onClick = () => {},
  ...props
}) => {
  return (
    <ButtonPrimary {...props} title={title} icon={icon} disabled={disabled} onClick={onClick} />
  )
}

export default ButtonPrimaryWhitRightIcon
