import React from 'react'
import { RangePicker, Typography } from 'modules/core/components'
import { useTranslation } from 'react-i18next'
import moment from 'moment'

const ContainerBody = ({ start_date, end_date }) => {
  const { t } = useTranslation()
  return (
    <>
      <Typography.Body level={2}>{t('PERIOD_DATES_LABEL')}</Typography.Body>
      <RangePicker
        className={`period-card-date-picker`}
        // style={
        //   editPeriodDate.id === id
        //     ? { marginBottom: 5, borderColor: '#2065c7' }
        //     : {}
        // }
        disabled={true}
        allowClear={false}
        allowEmpty={false}
        format="MMMM YYYY"
        value={[moment(start_date, 'YYYY-MM-01'), moment(end_date, 'YYYY-MM-01')]}
      />
    </>
  )
}

export default ContainerBody
