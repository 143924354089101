import { DatePicker, Form, Input, Modal, Select } from 'antd'
import _ from 'lodash'
import { PROJECTION } from 'modules/core/constants'
import { generateSelectOptions } from 'modules/core/utils'
import forecast from 'modules/forecast'
import moment from 'moment/moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import { onCreate } from './utils'
import { disabledDate } from 'modules/planning/components/CreateBudgetModal/utils'
import planning from 'modules/planning'
import { useFeatureIsOn } from '@growthbook/growthbook-react'

const CreateForecastInstanceModal = ({
  visible,
  onCancel,
  optionsSelect,
  onRefetch,
  conceptId,
  dataPeriod,
  createForecastInstance,
  periodList,
  fetchForecastFilterDates,
  forecastFilterDates,
}) => {
  const enableDatesSamePeriod = useFeatureIsOn('dates-same-period')
  const [isLoading, setIsLoading] = useState(false)
  const [filterDates, setFilterDates] = useState({})
  const [form] = Form.useForm()
  const { t } = useTranslation()
  let { forecastId } = useParams()

  useEffect(() => {
    if (!_.isEmpty(filterDates)) {
      fetchForecastFilterDates({ ...filterDates, forecast_id: forecastId })
    }
  }, [fetchForecastFilterDates, filterDates, forecastId])

  const onclose = () => {
    form.resetFields()
    setIsLoading(false)
    setFilterDates({})
    onCancel()
  }

  const handleCreate = (values) => {
    const { type_budget, dates, projections, name } = values

    const splitterProjections = projections.map((it) => {
      const splitter = it.split('_')
      return { id: parseInt(splitter[0]), type: splitter[1] }
    })

    const data = {
      name,
      type_budget,
      concept: conceptId,
      forecast_id: forecastId,
      overall_budget_ids: splitterProjections
        .filter((it) => it.type === 'budget')
        .map((it) => it.id),
      forecast_overall_budget_ids: splitterProjections
        .filter((it) => it.type === 'forecast')
        .map((it) => it.id),
      ...(dates && {
        date_start: moment(dates[0]).startOf('month').format('YYYY-MM-DD'),
        date_end: moment(dates[1]).startOf('month').format('YYYY-MM-DD'),
      }),
    }

    return createForecastInstance(data)
  }

  const disabledDateZeroValue = (date) => {
    const pickerDates = moment(date._d).utc().format('YYYY-MM')
    return !(pickerDates >= dataPeriod.start_date && pickerDates <= dataPeriod.end_date)
  }

  const renderDisabledDatePicker = (date) => {
    if (enableDatesSamePeriod) {
      return disabledDateZeroValue(date)
    }
    return disabledDate(date, periodList, dataPeriod)
  }

  const hasZeroValue = (value) => {
    return value && value.toString() === 'VALOR_CERO'
  }

  const hasHistoricalValue = (value) => value && value.toString() === 'HISTORICAL_PREVIOUS_PERIOD'

  const options = !_.isEmpty(forecastFilterDates)
    ? [
        ...forecastFilterDates.forecast_overall_budget.map((el) => {
          return {
            ...el,
            type: 'forecast',
          }
        }),
        ...forecastFilterDates.overall_budget.map((el) => {
          return {
            ...el,
            type: 'budget',
          }
        }),
      ]
    : []

  return (
    <Modal
      title={t('FORECAST_CREATE_MODAL_TITLE')}
      okText={t('ACTION_CREATE')}
      cancelText={t('ACTION_CANCEL')}
      centered
      forceRender
      visible={visible}
      onCancel={onclose}
      onOk={() =>
        onCreate({
          form,
          setIsLoading,
          createForecastInstance: (values) => handleCreate(values),
          onRefetch,
          onclose,
        })
      }
      destroyOnClose={true}
      okButtonProps={{ loading: isLoading }}
      cancelButtonProps={{ disabled: isLoading }}
    >
      <Form form={form} layout="vertical">
        {() => (
          <>
            <Form.Item
              label={t('FIELD_NAME')}
              name="name"
              rules={[{ required: true, message: t('REQUIRED_FIELD') }]}
            >
              <Input placeholder={t('ENTER_NAME_PLACEHOLDER')} />
            </Form.Item>

            <Form.Item
              name="type_budget"
              label={t('FORECAST_CREATE_NEW_BUDGET_BASE_TYPE_FORM_LABEL')}
              rules={[{ required: true, message: t('REQUIRED_FIELD') }]}
            >
              <Select
                placeholder={t('ACTION_SELECT')}
                options={generateSelectOptions({
                  options: [
                    {
                      id: 'VALOR_CERO',
                      name: 'PLANNING_CREATE_NEW_BUDGET_BASE_OPTION_FORM_SELECT',
                      type: 'cero',
                    },
                    {
                      id: 'HISTORICAL_PREVIOUS_PERIOD',
                      name: 'PLANNING_CREATE_NEW_BUDGET_PREVIUS_PERIOD_OPTION_FORM_SELECT',
                      type: 'historical',
                    },
                  ].map((op) => {
                    return { ...op, name: t(op.name) }
                  }),
                  dataTooltipLabel: 'PLANNING_UPLOAD_VALUE_CERO_INFORMATIVE_TOOLTIP_TITLE',
                })}
              />
            </Form.Item>

            {(hasZeroValue(form.getFieldsValue().type_budget) ||
              hasHistoricalValue(form.getFieldsValue().type_budget)) && (
              <>
                <Form.Item
                  name="dates"
                  label={t('PLANNING_CREATE_NEW_BUDGET_BASE_DATES_FORM_LABEL')}
                  rules={[{ required: true, message: t('REQUIRED_FIELD') }]}
                >
                  <DatePicker.RangePicker
                    format="MMM YYYY"
                    picker="month"
                    style={{ width: '100%' }}
                    onChange={(val) => {
                      if (!_.isEmpty(val)) {
                        setFilterDates({
                          date_start: moment(val[0]).startOf('month').format('YYYY-MM-DD'),
                          date_end: moment(val[1]).startOf('month').format('YYYY-MM-DD'),
                        })
                      } else {
                        setFilterDates({})
                      }
                    }}
                    disabledDate={(date) => renderDisabledDatePicker(date)}
                    defaultPickerValue={
                      !_.isEmpty(dataPeriod)
                        ? [moment(dataPeriod?.start_date), moment(dataPeriod?.end_date)]
                        : null
                    }
                  />
                </Form.Item>
                {!_.isEmpty(filterDates) && (
                  <Form.Item
                    name="projections"
                    label={t('PLANNING_PROJECTION_LIST_FORM_LABEL')}
                    rules={[
                      {
                        required: true,
                        message: t('REQUIRED_FIELD'),
                      },
                    ]}
                  >
                    <Select
                      placeholder={t('ACTION_SELECT')}
                      showArrow
                      allowClear
                      showSearch
                      mode="multiple"
                      maxTagCount={3}
                      maxTagTextLength={1}
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      options={generateSelectOptions({
                        options: options.map((el) => {
                          return {
                            id: `${el.id}_${el.type}`,
                            name: `${el.name}(${t(el.type)})-(${t(
                              PROJECTION.STATUS_COLORS[el.status].name,
                            )})`,
                          }
                        }),
                      })}
                    />
                  </Form.Item>
                )}
              </>
            )}
          </>
        )}
      </Form>
    </Modal>
  )
}

const mapStateToProps = (state) => ({
  periodList: planning.selectors.getPeriodList(state),
  forecastFilterDates: forecast.selectors.getForecastFilterDates(state),
})

const mapDispatchToProps = {
  createForecastInstance: forecast.actions.createForecastInstance,
  fetchForecastFilterDates: forecast.actions.fetchForecastFilterDates,
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateForecastInstanceModal)
