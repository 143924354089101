import { RobotOutlined } from '@ant-design/icons'
import { Avatar, Spin } from 'antd'
import React from 'react'

const ListFooter = ({ loading, footerText }) => {
  return loading ? (
    <div className={'chat-message-list-item left last-chat-message'}>
      <div className="message-chat-view">
        <div className="message-chat-receiver">
          <Avatar className="message-chat-avatar">
            <RobotOutlined />
          </Avatar>
        </div>
        <div className="message-chat-item">
          <div className="message-chat" style={{ whiteSpace: 'pre-line' }}>
            <p className="message-type-para">
              <Spin size={'small'} /> Consultando...
            </p>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="list-footer">
      <p>{footerText}</p>
    </div>
  )
}

export default ListFooter
