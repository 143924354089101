import { Modal, Upload, message } from 'antd'
import { Button } from 'modules/core/components'
import { useState } from 'react'
import { connect } from 'react-redux'
import home from 'modules/home'

const PruebaS3Modal = ({ visible, onCancel, uploadFileTest }) => {
  const [fileList, setFileList] = useState([])
  const [loadingPrueba, setLoadingPrueba] = useState(false)

  const onAddUploadFile = (file) => {
    setFileList([file])
    return false
  }

  const uploadProps = {
    name: 'file',
    accept: '.xls, .xlsx',
    showUploadList: { showPreviewIcon: false },
    beforeUpload: onAddUploadFile,
    onRemove: (file) => setFileList([]),
    fileList: fileList,
  }

  const formData = new FormData()
  fileList.forEach((file) => {
    formData.append('data', file)
  })

  const handleOnClick = () => {
    setLoadingPrueba(true)
    uploadFileTest({ file_name: fileList[0]?.name }).then((response) => {
      setLoadingPrueba(false)
      fetch(response.payload.data.upload_url, { method: 'PUT', body: formData }).then((response) =>
        message.success('Cargado con éxito', 8),
      )
    })
  }

  return (
    <Modal
      title="Prueba carga archivo s3"
      visible={visible}
      onCancel={onCancel}
      onOk={handleOnClick}
      okText="Cargar"
      cancelText="Cancelar"
      centered
      forceRender
      destroyOnClose
      okButtonProps={{ loading: loadingPrueba }}
      cancelButtonProps={{ disabled: loadingPrueba }}
    >
      <Upload {...uploadProps}>
        {uploadProps.fileList.length <= 0 && (
          <Button.DefaultWhitILeftIcon
            //   icon={<UploadOutlined />}
            title="UPLOAD_FILE_PLACEHOLDER"
          />
        )}
      </Upload>
    </Modal>
  )
}

const mapDispatchToProps = {
  uploadFileTest: home.actions.uploadFileTest,
}

export default connect(null, mapDispatchToProps)(PruebaS3Modal)
