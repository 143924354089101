import React from 'react'
import { Col, Form, Row, Upload } from 'antd'
import { Button } from 'modules/core/components'
import { UploadOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'

const UploadFiles = ({ form }) => {
  const { t } = useTranslation()
  const onAddUploadFile = () => {
    return false
  }

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e
    }
    return e?.fileList
  }

  return (
    <Form form={form} layout="vertical">
      <Row gutter={[24, 24]} style={{ width: '90%', display: 'flex', justifyContent: 'center' }}>
        <Col span={18} offset={4}>
          <Form.Item
            name="fileList"
            valuePropName="fileList"
            rules={[{ required: true, message: t('REQUIRED_FIELD') }]}
            getValueFromEvent={normFile}
          >
            <Upload
              name="file"
              multiple={true}
              accept=".csv, .xls, .xlsx"
              showUploadList={{ showPreviewIcon: false }}
              beforeUpload={onAddUploadFile}
            >
              <Button.DefaultWhitILeftIcon
                icon={<UploadOutlined />}
                title="UPLOAD_FILE_PLACEHOLDER"
              />
            </Upload>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}

export default UploadFiles
