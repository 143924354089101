import { message, notification } from 'antd'
import { generateMessageError } from 'modules/core/utils'
import i18n from 'i18next'

export const onDeleteBudgetType = ({ setIsLoading, deleteBudgetType, onRefetch, onClose }) => {
  setIsLoading(true)
  deleteBudgetType()
    .then(() =>
      onRefetch().then(() => {
        message.success(i18n.t('FEEDBACK_CHANGES_SAVED_SUCCESS'), 8)
        onClose()
      }),
    )
    .catch((error) => {
      notification.error({
        message: i18n.t('FEEDBACK_DEFAULT_ERROR'),
        description: generateMessageError(error, 'FEEDBACK_DELETE_FAIL'),
        duration: 0,
      })
      onClose()
    })
}

export const onSave = ({ setIsLoading, editBudgetType, onRefetch, onClose }) => {
  setIsLoading(true)
  editBudgetType()
    .then(() =>
      onRefetch().then(() => {
        message.success(i18n.t('FEEDBACK_CHANGES_SAVED_SUCCESS'), 8)
        onClose()
      }),
    )
    .catch((error) => {
      notification.error({
        message: i18n.t('FEEDBACK_DEFAULT_ERROR'),
        description: generateMessageError(error, 'FEEDBACK_SAVE_CHANGES_FAIL'),
        duration: 0,
      })
      onClose()
    })
}
