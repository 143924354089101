import React, { useState } from 'react'
import { Table } from 'antd'
import { useTranslation } from 'react-i18next'
import { DeleteAndUpdate, InputCell, SelectCell } from 'modules/core/components'
import { isUserAllowed } from 'modules/core/utils'
import { ROLES } from 'modules/core/constants'
import { TableActions } from './components'
import { connect } from 'react-redux'
import configuration from 'modules/configuration'
import { onDeleteVariable, onSaveChanges } from './utils'
import { STATUS_OPTIONS } from 'modules/core/constants/tables'

const { SETTINGS__PROJECTION_ELEMENTS__VARIABLES__ABM } = ROLES

const VariablesTable = ({
  dataSource,
  loading,
  onChange,
  count,
  pagination,
  onFetchVariablesList,
  dataSelection,
  setDataSelection,
  canDelete,
  editVariable,
  deleteVariable,
}) => {
  const [editRow, setEditRow] = useState(null)
  const [dataCellEdit, setDataCellEdit] = useState({})
  const [deleteVariableId, setDeleteVariableId] = useState(null)
  const [isSaving, setIsSaving] = useState(false)
  const { t } = useTranslation()

  const onCellEdit = (name, value) => setDataCellEdit({ ...dataCellEdit, [`${name}`]: value })

  const onClose = () => setEditRow(null)

  const onConfirm = () => {
    setEditRow(null)
    setDataCellEdit({})
    setIsSaving(false)
  }

  const onCancel = () => {
    setIsSaving(false)
    setDeleteVariableId(null)
  }

  const columns = [
    {
      title: t('FIELD_NAME'),
      dataIndex: 'name',
      render: (name, row) => {
        if (editRow && editRow === row.id) {
          return <InputCell initialValue={name} onCellUpdate={onCellEdit} name="name" />
        }
        return name
      },
    },
    {
      title: t('FIELD_STATUS'),
      dataIndex: 'status',
      render: (status, row) => {
        if (editRow && editRow === row.id) {
          return (
            <SelectCell
              initialValue={status}
              onCellUpdate={onCellEdit}
              name="status"
              allowClear
              options={STATUS_OPTIONS.map((status) => {
                return { ...status, name: t(status.name) }
              })}
            />
          )
        }
        return status === 'active' ? t('STATE_TAG_ACTIVE') : t('STATE_TAG_INACTIVE')
      },
    },
    {
      title: t('FIELD_ACTIONS'),
      dataIndex: 'id',
      align: 'right',
      render: (id, record) => {
        return id !== editRow ? (
          <TableActions
            isSaving={isSaving}
            title={t('CONFIG_VAR_DELETE_CONFIRM_TOOLTIP', { name: record.name })}
            onConfirmPopconfirm={() =>
              onDeleteVariable({
                setIsLoading: setIsSaving,
                deleteVariable: () => deleteVariable(id),
                onRefetch: onFetchVariablesList,
                onClose: onCancel,
              })
            }
            onCancelPopconfirm={() => setDeleteVariableId(id)}
            visible={deleteVariableId === id}
            onClickEdit={() => setEditRow(id)}
            onClickDelete={() => setDeleteVariableId(id)}
            disabled={!isUserAllowed(SETTINGS__PROJECTION_ELEMENTS__VARIABLES__ABM)}
          />
        ) : (
          <DeleteAndUpdate
            onCancel={onClose}
            onConfirm={onConfirm}
            onSave={() =>
              onSaveChanges({
                setIsLoading: setIsSaving,
                dataCellEdit,
                editVariable: () => editVariable(editRow, dataCellEdit),
                onRefetch: onFetchVariablesList,
                onClose: onConfirm,
              })
            }
            dataCellEdit={dataCellEdit}
            loading={isSaving}
          />
        )
      },
    },
  ]

  const renderRowSelection = () => {
    return {
      selectedRowKeys: dataSelection,
      onChange: (value) => setDataSelection(value),
    }
  }

  return (
    <Table
      rowKey="id"
      bordered
      size="small"
      columns={columns}
      dataSource={dataSource}
      rowSelection={canDelete ? renderRowSelection() : undefined}
      loading={loading || isSaving}
      onChange={onChange}
      pagination={{
        pageSize: pagination.pageSize,
        size: 'small',
        total: count,
        current: pagination.page,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '30'],
      }}
    />
  )
}

const mapDispatchToProps = {
  editVariable: configuration.actions.editVariable,
  deleteVariable: configuration.actions.deleteVariable,
}

export default connect(null, mapDispatchToProps)(VariablesTable)
