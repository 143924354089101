import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Row, Col, Card } from 'antd'
import {
  EmptyScreen,
  CreateReportModal,
  Navigator,
  DownloadModal,
  Typography,
} from 'modules/core/components'
import { ReportListTable, TableTopActions, UserActions } from './components'
import { INTEGRATIONS, ROLES } from 'modules/core/constants'
import { SiderLayout } from 'modules/core/layouts'
import { functionSearch } from './utils'
import { usePagination } from 'modules/core/customHooks'
import { isUserAllowed } from 'modules/core/utils'
import emptyStateImage from 'assets/images/compositions/empty-big.svg'
import reports from 'modules/reports'
import login from 'modules/login'
import { useHistory } from 'react-router-dom'

const { SETTINGS__REPORTS__REPORTSPNL__ABM } = ROLES

const ReportsList = ({ reportList, fetchReportList, loggedUser: { folder_id } }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [searchValue, setSearchValue] = useState(null)
  const [tablePagination, setTablePagination] = usePagination()
  const [dataSelection, setDataSelection] = useState([])
  const [showModal, setShowModal] = useState(false)
  const { t } = useTranslation()
  let history = useHistory()

  useEffect(() => {
    setIsLoading(true)
    fetchReportList({ search: searchValue, ...tablePagination }).then(() => setIsLoading(false))
  }, [fetchReportList, tablePagination, searchValue])

  const dataKpis = [
    {
      color: '#e1effa',
      value: reportList.count,
      title: 'CONFIG_REPORT_TOTAL_REPORTS',
    },
    {
      color: '',
      value: reportList.values.filter((el) => !el.is_financial).length,
      title: 'CONFIG_REPORT_TOTAL_ECONOMIC',
    },
    {
      color: '',
      value: reportList.values.filter((el) => el.is_financial).length,
      title: 'CONFIG_REPORT_TOTAL_FINANCIAL',
    },
  ]

  const renderHeader = () => (
    <Navigator
      floated={false}
      size="big"
      items={[
        {
          name: t('LABEL_CONFIG'),
          url: `/configuracion`,
        },
        { name: t('LABEL_REPORTS') },
      ]}
    />
  )

  const renderEmptyScreen = () => (
    <EmptyScreen
      image={emptyStateImage}
      title={t('CONFIG_REPORTS_EMPTY_STATE_TITLE')}
      footer={
        <CreateReportModal
          rolesAvailables={[SETTINGS__REPORTS__REPORTSPNL__ABM]}
          onFetchReportList={() => fetchReportList({ search: searchValue, ...tablePagination })}
          onClickSpreadsheets={() => setShowModal(true)}
        />
      }
    />
  )

  const renderUserActions = () => (
    <UserActions
      onRefetch={() => fetchReportList({ search: searchValue, ...tablePagination })}
      setSearchValue={setSearchValue}
      onClickSpreadsheets={() => setShowModal(true)}
      onClickActivity={() => history.push('/configuracion/actividad?type=report&module=config')}
    />
  )

  const renderTable = () => (
    <ReportListTable
      searchValue={searchValue}
      dataSource={reportList.values}
      functionSearch={functionSearch}
      isLoading={isLoading}
      onFetchReportList={() => fetchReportList({ search: searchValue, ...tablePagination })}
      pagination={tablePagination}
      onChange={setTablePagination}
      count={reportList.count}
      dataSelection={dataSelection}
      setDataSelection={setDataSelection}
      canDelete={isUserAllowed(SETTINGS__REPORTS__REPORTSPNL__ABM)}
    />
  )

  const renderTableActions = () => <TableTopActions dataSelection={dataSelection} />

  const renderKpis = () => (
    <Row gutter={[24, 24]}>
      <>
        {isLoading &&
          [1, 2, 3].map((index) => (
            <Col span={4} key={index}>
              <Card loading={true} />
            </Col>
          ))}
        {!isLoading &&
          dataKpis.map((kpi) => (
            <Col span={4}>
              <Card bodyStyle={kpi.color ? { backgroundColor: '#e1effa', width: '100%' } : {}}>
                <div style={{ textAlign: 'center' }}>
                  <Typography.Headline level={3}>{kpi.value}</Typography.Headline>
                </div>
                <div style={{ textAlign: 'center' }}>
                  <Typography.Body level={3} type="secondary">
                    {t(kpi.title)}
                  </Typography.Body>
                </div>
              </Card>
            </Col>
          ))}
      </>
    </Row>
  )

  return (
    <SiderLayout>
      {renderHeader()}
      <Row gutter={[8, 8]}>
        {reportList.count <= 0 && !isLoading && renderEmptyScreen()}
        {reportList.count > 0 && (
          <>
            <Col span={24}>{renderUserActions()}</Col>
            <Col span={24}>{renderKpis()}</Col>
            <Col span={24}>{renderTableActions()}</Col>
            <Col span={24}>{renderTable()}</Col>
            <DownloadModal
              title={INTEGRATIONS.KEYS.SPREADSHEETS_KEY}
              visible={showModal}
              onCancel={() => setShowModal(false)}
              defaultValue={folder_id}
              dataSpreadsheets={{
                type_load: 'temp_reporte',
                folder_id,
              }}
            />
          </>
        )}
      </Row>
    </SiderLayout>
  )
}

const mapStateToProps = (state) => ({
  reportList: reports.selectors.getReportList(state),
  loggedUser: login.selectors.getWhoAmI(state),
})

const mapDispatchToProps = {
  fetchReportList: reports.actions.fetchReportList,
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportsList)
