import React, { useState /*, useEffect */ } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Row, Col /*Spin */ } from 'antd'
// import { DownloadOutlined } from '@ant-design/icons';
import {
  // Button,
  ButtonActionBar,
  Navigator,
  // Typography,
} from 'modules/core/components'
// import { CONCEPTS } from 'modules/core/constants';
import { SiderLayout } from 'modules/core/layouts'
// import planning from 'modules/planning';
import { AssociateDimensionModal } from '../../components'
import { BudgetTypeDetailTable } from './components'

const BudgetTypeDetail = ({
  budgetTypeDetail: { configs, name },
  // fetchConceptsDetail,
  // fetchConceptConsolidatedXLS,
}) => {
  // const [isScreenLoading, setIsScreenLoading] = useState(true);
  const [isTableLoading, setIsTableLoading] = useState(false)
  const { t } = useTranslation()
  let { conceptId } = useParams()

  // useEffect(() => {
  //   fetchConceptsDetail(conceptId).then(() => setIsScreenLoading(false));
  // }, [fetchConceptsDetail, conceptId]);

  const renderHeader = () => (
    <Navigator
      floated={false}
      size="big"
      items={[
        {
          name: t('LABEL_CONFIG'),
          url: `/configuracion`,
        },
        {
          name: t('LABEL_BUDGET_TYPE'),
          url: `/configuracion/tipodepresupuesto/`,
        },
        { name: name ? t(name) : '' },
      ]}
    />
  )

  const renderUserActions = () => (
    <ButtonActionBar>
      <AssociateDimensionModal
        conceptId={conceptId}
        refreshData={refreshData}
        selectedDimensions={configs.map((conf) => conf.dimension_id)}
      />
    </ButtonActionBar>
  )

  const refreshData = () => {
    setIsTableLoading(true)
    // fetchConceptsDetail(conceptId).then(() => setIsTableLoading(false));
  }

  // const renderTableActions = () => (
  //   <ButtonActionBar>
  // <Button.Icon
  //   title='ACTION_DOWNLOAD_TABLE'
  //   icon={<Typography.Icon icon={DownloadOutlined} />}
  //   onClick={() =>
  //     fetchConceptConsolidatedXLS(t('LABEL_ECONOMIC').toLowerCase(), {
  //       concept_name: name,
  //   })}
  // />
  // );

  return (
    <SiderLayout>
      <Row gutter={[24, 24]}>
        <Col span={24}>{renderHeader()}</Col>
        <Col span={24}>{/*!isScreenLoading && */ renderUserActions()}</Col>
        {/* <Col span={24}>{!isScreenLoading && renderTableActions()}</Col> */}
        <Col span={24}>
          {/* {!isScreenLoading && ( */}
          <BudgetTypeDetailTable
            dataSource={configs}
            tableLoading={isTableLoading}
            refreshData={refreshData}
          />
          {/* )} */}
        </Col>
      </Row>
      {/* <Spin spinning={isScreenLoading} /> */}
    </SiderLayout>
  )
}

const mapStateToProps = (state) => ({
  budgetTypeDetail: {
    configs: [
      { config_id: 1, order: 0, dimension_id: 1, dimension: 'Cuenta' },
      { config_id: 3, order: 1, dimension_id: 3, dimension: 'Clientes' },
    ],
    count: 19,
    id: 1,
    name: 'Ventas',
    type: 'economic',
  },
  // planning.selectors.getConceptsDetail(state),
})

const mapDispatchToProps = {
  // fetchConceptsDetail: planning.actions.fetchConceptsDetail,
  // fetchConceptConsolidatedXLS: planning.actions.fetchConceptConsolidatedXLS,
}

export default connect(mapStateToProps, mapDispatchToProps)(BudgetTypeDetail)
