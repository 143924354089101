import React from 'react'
import { Space, Radio, Checkbox, InputNumber } from 'antd'
import i18n from 'i18next'
import { TABLES } from 'modules/core/constants'

const itemsDataView = (negativeValuesType, showNegativeInRed) => {
  const renderNegativeValuesType = (negativeValuesType) => {
    return (
      <Space direction="vertical">
        <Radio.Group defaultValue={negativeValuesType}>
          <Space direction="vertical">
            {TABLES.NEGATIVE_VALUES_TYPES_LIST.map((option) => (
              <Radio value={option.value} key={option.key}>
                {i18n.t(option.key)}
              </Radio>
            ))}
          </Space>
        </Radio.Group>
      </Space>
    )
  }

  const renderShowNegativeInRed = () => <Checkbox>{i18n.t('FIELD_RED')}</Checkbox>

  return {
    dataView: [
      {
        name: 'negativeValuesType',
        label: i18n.t('PLANNING_DISPLAY_NEGATIVE_DATA_FORM_LABEL'),
        component: renderNegativeValuesType(negativeValuesType),
        initialValue: negativeValuesType,
      },
      {
        name: 'showNegativeInRed',
        label: '',
        prop: 'checked',
        component: renderShowNegativeInRed(),
        initialValue: showNegativeInRed,
      },
    ],
  }
}

const itemsTableView = (cantItems) => {
  const renderCantDimensionsView = () => <InputNumber min={2} />

  return {
    tableView: [
      {
        name: 'cantItems',
        label: i18n.t('REPORT_DIMENSION_SHOW_CANT_LABEL'),
        component: renderCantDimensionsView(),
        initialValue: cantItems,
      },
    ],
  }
}

export const formItemsHelper = {
  itemsDataView,
  itemsTableView,
}
