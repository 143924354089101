import { ButtonActionBar, DeleteSelectionButton } from 'modules/core/components'
// import { DownloadOutlined } from '@ant-design/icons';

const TableTopActions = ({ dataSelection }) => {
  return (
    <ButtonActionBar position={dataSelection.length > 0 ? 'space-between' : 'end'}>
      {dataSelection.length > 0 && (
        <DeleteSelectionButton
          onConfirm={() => console.log('borrar')}
          disabled={false}
          // isDeletingAll={isDeletingAll}
        />
      )}
      {/* <Button.Icon 
                title='ACTION_DOWNLOAD_TABLE'
                icon={<Typography.Icon icon={DownloadOutlined} />}
                onClick={() => console.log('descargar tabla')}
            /> */}
    </ButtonActionBar>
  )
}

export default TableTopActions
