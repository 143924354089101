import React from 'react'
import { connect } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'
import { AppLayout } from 'modules/core/layouts'
import login from 'modules/login'

const PrivateRoute = ({ loggedUser, isAuthenticated, onLogout, component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      if (isAuthenticated) {
        if (rest.path === '/login') {
          return <Redirect to="/" />
        }
        if (rest.path === '/' && loggedUser.isSuperuser) {
          return <Redirect to="/empresas" />
        } else if (rest.path === '/' && loggedUser.link_upload_file) {
          return <Redirect to="cargaarchivoss3" />
        } else {
          return (
            <AppLayout loggedUser={loggedUser} onLogout={onLogout}>
              <Component {...props} />
            </AppLayout>
          )
        }
      } else {
        if (
          rest.path === '/login' ||
          rest.path === '/restablecercontrasenia' ||
          rest.path === '/politicayterminos'
        ) {
          return <Component {...props} />
        } else {
          return <Redirect to="/login" />
        }
      }
    }}
  />
)

const mapStateToProps = (state) => ({
  loggedUser: login.selectors.getWhoAmI(state),
  isAuthenticated: login.selectors.getIsAuthenticated(state),
})

const mapDispatchToProps = {
  onLogout: login.actions.onLogout,
}

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute)
