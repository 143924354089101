import React, { useState } from 'react'
import { Form, Modal } from 'antd'
import { useTranslation } from 'react-i18next'
import { ConnectForm, IframeLogin, InputLink, UploadFiles } from './components'
import { connect } from 'react-redux'
import { handleConfig } from './utils'
import { INTEGRATIONS } from 'modules/core/constants'
import login from 'modules/login'
import home from 'modules/home'
import _ from 'lodash'

const ImportDataModal = ({
  visible,
  onCancel,
  connectorData,
  onRefetch = null,
  loggedUser: { society_code },
  uploadInitialFile,
  connectERP,
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [form] = Form.useForm()
  const { t } = useTranslation()

  const onClose = () => {
    // form.resetFields()
    setIsLoading(false)
    onCancel()
  }

  const integrationAditionalData = {
    [INTEGRATIONS.KEYS.FILE_KEY]: {
      modalData: <UploadFiles form={form} />,
      onAction: (values) => uploadInitialFile(values.fileList, { type_file: 'FACT_TABLE' }),
    },
    [INTEGRATIONS.KEYS.ODOO_KEY]: {
      modalData: <ConnectForm form={form} />,
      onAction: (values) => connectERP({ ...values, type: connectorData.id }),
    },
    [INTEGRATIONS.KEYS.SPREADSHEETS_KEY]: {
      modalData: <InputLink form={form} />,
      onAction: (values) => connectERP({ ...values, type: connectorData.id }),
    },
    [INTEGRATIONS.KEYS.QUICKBOOK_KEY]: {
      modalData: <IframeLogin society={society_code} />,
      onAction: (values) => connectERP({ ...values, type: 'quickbooks_web' }),
    },
  }

  return (
    <Modal
      title={t('INTEGRATION_DATA_INTEGRATION_MODAL_TITLE', { name: connectorData.name })}
      width={560}
      bodyStyle={{ overflowY: 'auto', maxHeight: 400 }}
      visible={visible}
      centered
      forceRender
      destroyOnClose
      onCancel={onClose}
      onOk={() =>
        handleConfig({
          form,
          setIsLoading,
          onAction: (values) => integrationAditionalData[connectorData.id].onAction(values),
          onRefetch,
          onClose,
        })
      }
      okText={t('ACTION_SAVE')}
      cancelText={t('ACTION_CANCEL')}
      okButtonProps={{ loading: isLoading }}
      cancelButtonProps={{ disabled: isLoading }}
    >
      {!_.isEmpty(connectorData) && integrationAditionalData[connectorData.id].modalData}
    </Modal>
  )
}

const mapStateToProps = (state) => ({
  loggedUser: login.selectors.getWhoAmI(state),
})

const mapDispatchToProps = {
  uploadInitialFile: home.actions.uploadInitialFile,
  connectERP: home.actions.connectERP,
}

export default connect(mapStateToProps, mapDispatchToProps)(ImportDataModal)
