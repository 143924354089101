import React, { useEffect, useState } from 'react'
import { Modal, Form, Input, Select, Space, Row, Col, DatePicker } from 'antd'
import { useTranslation } from 'react-i18next'
import { checkedUpload, generateSelectOptions /*handleSelectAll*/ } from 'modules/core/utils'
import { /*handleSetFormListValues,*/ disabledDate, projectionAction } from './utils'
import { INTEGRATIONS, PROJECTION } from 'modules/core/constants'
import { /*AddAndDeleteFormItem,*/ Button, ButtonActionBar } from 'modules/core/components'
import { HistoricalBase, WithoutBase } from './components'
import { connect } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { useFeatureIsOn } from '@growthbook/growthbook-react'
import configuration from 'modules/configuration'
import planning from 'modules/planning'
import processes from 'modules/processes'
import moment from 'moment'
import _ from 'lodash'

const FormItem = Form.Item

const CreateBudgetModal = ({
  title = 'PLANNING_CREATE_FORM_BUDGET_TITLE',
  nameButton = 'ACTION_CREATE',
  visible,
  concept,
  onCancel,
  actionProjection,
  actionSpreadsheets,
  onRefetchProjections,
  dataPeriod,
  fileName = '',
  projectionInstanceList,
  extraProps = {},
  enabledTypeOfLoad = false,
  setIsDisable,
  dataProjection,
  fetchAllDimensions,
  // fetchAllDimensionsValues,
  // allDimensionsList,
  // allDimensionsValues,
  fetchMLList,
  mlList,
  fetchProcessesList,
  periodList,
  fetchPlanningFilterDates,
  planningFilterDates,
}) => {
  const enableBaseHistorica = useFeatureIsOn('base-historica')
  const [isLoading, setIsLoading] = useState(false)
  const [fileList, setFileList] = useState([])
  const [uploadOtion, setUploadOption] = useState(INTEGRATIONS.KEYS.FILE_KEY)
  const [value, setValue] = useState(PROJECTION.TYPE_UPLOAD_OPTION_DEFAULT)
  // const [dimensionId, setDimensionId] = useState(null)
  // const [selectedDimensions, setSelectedDimensions] = useState([])
  // const [areAllSelected, setAreAllSelected] = useState(false)
  const [filterDates, setFilterDates] = useState({})
  const [form] = Form.useForm()
  let { periodId } = useParams()
  let history = useHistory()
  const { t } = useTranslation()

  useEffect(() => {
    if (visible) {
      fetchAllDimensions()
    }
  }, [fetchAllDimensions, visible])

  useEffect(() => {
    if (!_.isEmpty(filterDates)) {
      fetchPlanningFilterDates({ ...filterDates, concept_id: concept.id })
    }
  }, [fetchPlanningFilterDates, filterDates, concept.id])

  // useEffect(() => {
  //   if (dimensionId) {
  //     fetchAllDimensionsValues({ id_dimension: dimensionId })
  //   }
  // }, [fetchAllDimensionsValues, dimensionId])

  useEffect(() => {
    if (visible) {
      fetchMLList()
    }
  }, [fetchMLList, visible])

  const informativeTexts = {
    VALOR_CERO: 'PLANNING_UPLOAD_VALUE_CERO_INFORMATIVE_TOOLTIP_TITLE',
    VALUE_AVERAGE: 'PLANNING_UPLOAD_VALUE_AVERAGE_INFORMATIVE_TOOLTIP_TITLE',
    HISTORICAL_PREVIOUS_PERIOD:
      'PLANNING_UPLOAD_HISTORICAL_PREVIOUS_PERIOD_INFORMATIVE_TOOLTIP_TITLE',
    VALUE_WITHOUT_BASE: 'PLANNING_UPLOAD_VALUE_WITHOUT_BASE_INFORMATIVE_TOOLTIP_TITLE',
  }

  const getOptions = () => {
    if (!enableBaseHistorica) {
      return mlList.filter((el) => el.id !== 'HISTORICAL_PREVIOUS_PERIOD')
    }
    return mlList
  }

  const onClose = () => {
    form.resetFields()
    onCancel()
    setIsLoading(false)
    setFileList([])
    setValue(PROJECTION.TYPE_UPLOAD_OPTION_DEFAULT)
    setUploadOption(INTEGRATIONS.KEYS.FILE_KEY)
    setFilterDates({})
  }

  //NOTE: REVEER FILE_UPLOAD CUANDO ESTE LA CONEXION PARA VER COMO ENVIAR LO DE SPREADSHETS
  const onDefaultCreate = (values) => {
    const { dates, dimension_list, file_upload, ...restProps } = values
    const setDimensionList = () =>
      dimension_list.map((dim) => {
        return {
          ...dim,
          values: dim.values.map((val) => parseInt(val.split('_', 2)[0])),
        }
      })

    const data = {
      concept: parseInt(concept.id),
      type_of_load: value,
      ...(dates && {
        date_start: moment(dates[0]).startOf('month').format('YYYY-MM-DD'),
        date_end: moment(dates[1]).startOf('month').format('YYYY-MM-DD'),
      }),
      ...(dimension_list &&
        dimension_list[0] && {
          dimension_list: setDimensionList(),
        }),
      ...(restProps.type_budget === 'ALGORITMO_ARIMA' && {
        start_date: moment(dataPeriod.start_date).format('YYYY-MM-DD'),
      }),
      ...(fileList.length > 0 && {
        file_upload: fileList,
      }),
      ...extraProps,
      ...restProps,
    }
    return actionProjection(data)
  }

  const onSpreadsheetsCreate = (values) => {
    const { name, file_upload, type_budget } = values
    const { period_id, planning_id } = extraProps

    const data = {
      ...(period_id
        ? {
            type_load: 'planificacion',
            tipe_file: 'planning',
            params: {
              type_budget,
              concept: parseInt(concept.id),
              name,
              period_id,
            },
          }
        : {
            type_load: 'planificacion_update',
            params: {
              type_budget,
              type_of_load: value,
              concept: parseInt(concept.id),
              planning_id,
            },
          }),
      sheet_id: file_upload,
    }

    return actionSpreadsheets(data)
  }

  const onChangeRadio = (e) => {
    setValue(e.target.value)
  }

  const componentsByDataType = {
    historica: (
      <HistoricalBase
        form={form}
        projectionInstanceList={planningFilterDates}
        dataPeriod={dataPeriod}
        type_base={form.getFieldsValue().type_base}
        filterDates={filterDates}
        setFilterDates={setFilterDates}
      />
    ),
    upload: (
      <WithoutBase
        fileList={fileList}
        setFileList={setFileList}
        concept={concept}
        periodId={periodId}
        dataPeriod={dataPeriod}
        fileName={fileName}
        uploadOtion={uploadOtion}
        setUploadOption={setUploadOption}
        enabledTypeOfLoad={enabledTypeOfLoad}
        onChangeTypeOfLoad={onChangeRadio}
        typeOfLoad={value}
      />
    ),
  }

  const hasZeroValue = (value) => {
    return value && value.toString() === 'VALOR_CERO'
  }

  const hasZeroValueProjection = (value) => {
    return value && value.toString() === PROJECTION.BUDGET_PERSONALIZED_REPORT.toUpperCase()
  }

  return (
    <Modal
      title={t(title)}
      centered
      visible={visible}
      destroyOnClose={true}
      onCancel={onClose}
      forceRender
      bodyStyle={{ overflowY: 'auto', maxHeight: 700 }}
      footer={
        <ButtonActionBar>
          <Space direction="horizontal">
            <Button.Default disabled={isLoading} onClick={onClose} title="ACTION_CANCEL" />
            <Button.Primary
              loading={isLoading}
              onClick={() =>
                projectionAction({
                  form,
                  setIsLoading,
                  action: (values) =>
                    uploadOtion === INTEGRATIONS.KEYS.SPREADSHEETS_KEY
                      ? onSpreadsheetsCreate(values)
                      : onDefaultCreate(values),
                  fileList,
                  onRefetch: onRefetchProjections,
                  onClose,
                  onClick: () => history.push('/procesosdecarga'),
                  renderCheckedUpload: dataProjection
                    ? () =>
                        checkedUpload({
                          fetchProcessesList,
                          setIsDisable,
                          dataProjection,
                          onRefetch: onRefetchProjections,
                        })
                    : null,
                })
              }
              title={nameButton}
            />
          </Space>
        </ButtonActionBar>
      }
    >
      <Form form={form} layout="vertical" initialValues={{ dimension_list: [''] }}>
        {() => (
          <>
            <Row gutter={[8, 8]}>
              {!extraProps.step && (
                <Col span={24}>
                  <FormItem
                    name="name"
                    label={t('FIELD_NAME')}
                    rules={[
                      { required: true, message: t('REQUIRED_FIELD') },
                      { max: 80, message: t('REQUIRED_FIELD_CHARACTERS', { cant: 80 }) },
                    ]}
                  >
                    <Input
                      placeholder={t('PLANNING_CREATE_FORM_BUDGET_PLACEHOLDER', {
                        name: concept ? t(concept.name) : t('LABEL_BUDGET'),
                      })}
                    />
                  </FormItem>
                </Col>
              )}
              <Col span={24}>
                <FormItem
                  name="type_budget"
                  label={t('PLANNING_CREATE_NEW_BUDGET_BASE_TYPE_FORM_LABEL')}
                  rules={[{ required: true, message: t('REQUIRED_FIELD') }]}
                >
                  <Select
                    placeholder={t('ACTION_SELECT')}
                    options={generateSelectOptions({
                      options: getOptions().map((op) => {
                        return { ...op, name: t(op.name) }
                      }),
                      dataTooltipLabel: informativeTexts,
                    })}
                  />
                </FormItem>
              </Col>
              {form.getFieldsValue().type_budget &&
                form.getFieldsValue().type_budget !== 'VALOR_CERO' &&
                componentsByDataType[
                  mlList.find((el) => el.id === form.getFieldsValue().type_budget).type
                ]}
            </Row>
            {/* {form.getFieldsValue().type_budget &&
              form.getFieldsValue().type_budget !== 'VALOR_CERO' &&
              form.getFieldsValue().type_budget !== 'VALUE_WITHOUT_BASE' && (
                <Form.Item
                  name="dimension_list"
                  label={t('PLANNING_CREATE_NEW_BUSGET_BASE_DATA_FILTER_FORM_LABEL')}
                >
                  <Form.List name="dimension_list">
                    {(fields, { add, remove }) => {
                      return (
                        <div>
                          {fields.map((field, index) => {
                            return (
                              <Row gutter={24} key={field.key} justify="end">
                                <Col span={12}>
                                  <Form.Item name={[field.name, 'dimension_id']}>
                                    <Select
                                      placeholder={t('FIELD_DIMENSION')}
                                      onChange={(id) => setDimensionId(id)}
                                      options={generateSelectOptions({
                                        options: allDimensionsList.map((dim) => {
                                          return { id: dim.id, name: dim.name_destination }
                                        }),
                                        selection: _.compact(
                                          form
                                            .getFieldValue('dimension_list')
                                            .map((el) => (el ? el?.dimension_id.toString() : null)),
                                        ),
                                      })}
                                    />
                                  </Form.Item>
                                </Col>
                                <Col span={12}>
                                  <Form.Item name={[field.name, 'values']}>
                                    <Select
                                      placeholder={t('FIELD_DIMENSION_VALUES')}
                                      mode="multiple"
                                      showArrow
                                      allowClear
                                      showSearch
                                      maxTagCount={1}
                                      maxTagTextLength={10}
                                      onChange={(values) => {
                                        handleSelectAll({
                                          values,
                                          allValues: allDimensionsValues
                                            .asMutable()
                                            .map(
                                              (dimValue) =>
                                                `${dimValue.id}_${dimValue.name_destination}`,
                                            ),
                                          areAllSelected,
                                          onSetValues: (values) =>
                                            handleSetFormListValues(form, values, index),
                                          onSetAreAllSelected: (state) => setAreAllSelected(state),
                                        })
                                      }}
                                      optionFilterProp="children"
                                      filterOption={(input, option) =>
                                        option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                      }
                                      disabled={
                                        !dimensionId ||
                                        (form.getFieldValue('dimension_list')[field.name] &&
                                          dimensionId !==
                                            form.getFieldValue('dimension_list')[field.name][
                                              'dimension_id'
                                            ])
                                      }
                                      options={generateSelectOptions({
                                        options: allDimensionsValues.map((valDim) => {
                                          return {
                                            id: `${valDim.id}_${valDim.name_destination}`,
                                            code: valDim.code_destination,
                                            name: valDim.name_destination,
                                          }
                                        }),
                                        includeOptionAll: true,
                                      })}
                                    />
                                  </Form.Item>
                                </Col>
                                <AddAndDeleteFormItem
                                  fieldsLength={fields.length}
                                  index={index}
                                  addData={{
                                    disabled: isLoading,
                                    onClick: () => {
                                      add()
                                      if (dimensionId) {
                                        setSelectedDimensions([
                                          ...selectedDimensions,
                                          dimensionId.toString(),
                                        ])
                                      }
                                      setDimensionId(null)
                                    },
                                    buttonName: 'CONFIG_DIMENSIONS_ADD_NEW_ACTION',
                                  }}
                                  deleteData={{
                                    disabled: isLoading,
                                    onClick: () => {
                                      if (form.getFieldValue('dimension_list')[field.name]) {
                                        const selectedID =
                                          form.getFieldValue('dimension_list')[field.name][
                                            'dimension_id'
                                          ]
                                        setSelectedDimensions(
                                          selectedDimensions.filter(
                                            (id) => id !== selectedID.toString(),
                                          ),
                                        )
                                      }
                                      remove(field.name)
                                    },
                                    buttonName: 'CONFIG_DIMENSIONS_DELETE_ACTION',
                                  }}
                                />
                              </Row>
                            )
                          })}
                        </div>
                      )
                    }}
                  </Form.List>
                </Form.Item>
              )} */}

            {/*  Zero Value*/}
            {hasZeroValue(form.getFieldsValue().type_budget) && (
              <Row gutter={[8, 8]}>
                <Col span={12}>
                  <Form.Item
                    name="type_base"
                    label={t('PLANNING_CREATE_NEW_BUSGET_BASE_CHOOSE_TYPE_BASE_FORM_LABEL')}
                    rules={[{ required: true, message: t('REQUIRED_FIELD') }]}
                  >
                    <Select
                      placeholder={t('ACTION_SELECT')}
                      options={generateSelectOptions({
                        options: PROJECTION.PROJECTIONS_OPTIONS.map((op) => {
                          return { ...op, name: t(op.name) }
                        }),
                      })}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="dates"
                    label={t('PLANNING_CREATE_NEW_BUDGET_BASE_DATES_FORM_LABEL')}
                    rules={[{ required: true, message: t('REQUIRED_FIELD') }]}
                  >
                    <DatePicker.RangePicker
                      format="MMM YYYY"
                      picker="month"
                      onChange={(val) =>
                        setFilterDates({
                          date_start: moment(val[0]).startOf('month').format('YYYY-MM-DD'),
                          date_end: moment(val[1]).startOf('month').format('YYYY-MM-DD'),
                        })
                      }
                      style={{ width: '100%' }}
                      disabledDate={(date) => disabledDate(date, periodList, dataPeriod)}
                      defaultPickerValue={
                        !_.isEmpty(dataPeriod)
                          ? [moment(dataPeriod?.start_date), moment(dataPeriod?.end_date)]
                          : null
                      }
                    />
                  </Form.Item>
                </Col>

                {hasZeroValueProjection(form.getFieldsValue().type_base) &&
                  !_.isEmpty(filterDates) && (
                    <Col span={24}>
                      <Form.Item
                        name="plannings_ids"
                        label={t('LABEL_BUDGETS')}
                        rules={[{ required: true, message: t('REQUIRED_FIELD') }]}
                      >
                        <Select
                          placeholder={t('ACTION_SELECT')}
                          showArrow
                          allowClear
                          showSearch
                          mode="multiple"
                          maxTagCount={3}
                          maxTagTextLength={1}
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                          options={generateSelectOptions({
                            options: planningFilterDates,
                          })}
                        />
                      </Form.Item>
                    </Col>
                  )}
              </Row>
            )}
          </>
        )}
      </Form>
    </Modal>
  )
}

const mapStateToProps = (state) => ({
  allDimensionsList: configuration.selectors.getAllDimensionsList(state),
  allDimensionsValues: configuration.selectors.getAllDimensionsValues(state),
  mlList: planning.selectors.getMlList(state),
  periodList: planning.selectors.getPeriodList(state),
  planningFilterDates: planning.selectors.getPlanningFilterDates(state),
})

const mapDispatchToProps = {
  fetchAllDimensions: configuration.actions.fetchAllDimensions,
  fetchAllDimensionsValues: configuration.actions.fetchAllDimensionsValues,
  fetchMLList: planning.actions.fetchMLList,
  fetchProcessesList: processes.actions.fetchProcessesList,
  fetchPlanningFilterDates: planning.actions.fetchPlanningFilterDates,
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateBudgetModal)
