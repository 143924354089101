import { dateMMYYYY } from 'modules/core/utils'
import i18n from 'i18next'
import _ from 'lodash'

export const setReportLineName = ({ rows, lineId }) => {
  if (rows.length > 0) {
    if (lineId !== undefined) {
      return rows.find((report) => report.id === lineId)?.concept
    }
    return rows[rows.length - 1].id
  }

  return ''
}

export const setChartTitle = (selectedDates) =>
  `${i18n.t('REPORT_GRAPHIC_TITLE')} ${
    !_.isEmpty(selectedDates)
      ? `(${dateMMYYYY(selectedDates['start-date'], 'YYYY-MM')} - ${dateMMYYYY(
          selectedDates['end-date'],
          'YYYY-MM',
        )})`
      : ''
  }`
