import React, { useState } from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Table } from 'antd'
import { InputCell, DeleteAndUpdate } from 'modules/core/components'
import { isUserAllowed } from 'modules/core/utils'
import { ROLES } from 'modules/core/constants'
import configuration from 'modules/configuration'
import { TableActions } from './components'
import { onDeleteRole, onEditRole } from './utils'

const { SETTINGS__SECURITY__ROLES__ABM } = ROLES

const RolesTable = ({
  editRoleOperations,
  deleteRole,
  editRole,
  onDuplicateRole,
  dataSource,
  reFetchTable,
  setShowLicenseDrawer,
  tableLoading,
  count,
  pagination,
  onChange,
  dataSelection,
  setDataSelection,
  canDelete,
}) => {
  const [deleteRoleId, setDeleteRoleId] = useState(null)
  const [isDeleting, setIsDeleting] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [editRow, setEditRow] = useState(null)
  const [dataCellEdit, setDataCellEdit] = useState({})
  const { t } = useTranslation()

  const onCellEdit = (name, value) => {
    setDataCellEdit({ ...dataCellEdit, [`${name}`]: value })
  }

  const onConfirm = () => {
    setEditRow(null)
    setDataCellEdit({})
  }

  const onCancel = () => setEditRow(null)

  const onCloseDelete = () => {
    setIsDeleting(false)
    setDeleteRoleId(null)
  }

  const onCloseEdit = () => {
    setIsEditing(false)
    setEditRow(null)
    setDataCellEdit({})
  }

  const columns = [
    {
      title: t('FIELD_NAME'),
      dataIndex: 'name',
      width: '50%',
      editable: true,
      render: (name, row) => {
        if (editRow && row.id === editRow) {
          return <InputCell initialValue={name} name="name" onCellUpdate={onCellEdit} />
        } else {
          return name
        }
      },
    },
    {
      title: t('FIELD_CODE'),
      dataIndex: 'code',
      width: '25%',
      editable: true,
      render: (code, row) => {
        if (editRow && row.id === editRow) {
          return <InputCell initialValue={code} name="code" onCellUpdate={onCellEdit} />
        } else {
          return code
        }
      },
    },
    {
      title: t('FIELD_ACTIONS'),
      dataIndex: 'id',
      align: 'right',
      render: (id, row) => {
        return id !== editRow ? (
          <TableActions
            popconfirmOnConfirm={() =>
              onDeleteRole({
                setIsLoading: setIsDeleting,
                deleteRole: () => deleteRole(id),
                onRefetch: reFetchTable,
                onClose: onCloseDelete,
              })
            }
            popconfirmOnCancel={() => setDeleteRoleId(null)}
            popconfirmVisible={deleteRoleId === id}
            isLoading={isDeleting}
            detailPermissionsOnClick={() => {
              document.body.style.overflow = 'hidden'
              editRoleOperations({
                checked: true,
                operationsKeys: row.operations_assign.map((el) => el.name),
                dimensions: row.data_permissions,
              })
              setShowLicenseDrawer({
                roleId: id,
                name: row.name,
                visible: true,
              })
            }}
            editOnClick={() => setEditRow(id)}
            disabled={!isUserAllowed(SETTINGS__SECURITY__ROLES__ABM)}
            duplicateOnClick={() =>
              onDuplicateRole({
                id_rol: id,
                name: row.name,
                code: row.code,
              })
            }
            deleteOnClick={() => setDeleteRoleId(id)}
          />
        ) : (
          <DeleteAndUpdate
            onCancel={onCancel}
            onConfirm={onConfirm}
            onSave={() =>
              onEditRole({
                dataCellEdit: { dataCellEdit },
                setIsLoading: setIsEditing,
                editRole: () =>
                  editRole(editRow, { name: row.name, code: row.code, ...dataCellEdit }),
                onRefetch: reFetchTable,
                onClose: onCloseEdit,
              })
            }
            dataCellEdit={dataCellEdit}
            loading={isEditing}
          />
        )
      },
    },
  ]

  const renderRowSelection = () => {
    return {
      selectedRowKeys: dataSelection,
      onChange: (value) => setDataSelection(value),
    }
  }

  return (
    <>
      <Table
        rowKey="id"
        bordered
        size="small"
        loading={tableLoading || isDeleting || isEditing}
        rowSelection={canDelete ? renderRowSelection() : undefined}
        dataSource={dataSource}
        columns={columns}
        onChange={onChange}
        pagination={{
          pageSize: pagination.page_size,
          current: pagination.page,
          total: count,
          size: 'small',
          pageSizeOptions: ['10', '20', '30'],
          showSizeChanger: true,
        }}
      />
    </>
  )
}

const mapDispatchToProps = {
  editRoleOperations: configuration.actions.editRoleOperations,
  deleteRole: configuration.actions.deleteRole,
  editRole: configuration.actions.editRole,
}

export default connect(null, mapDispatchToProps)(RolesTable)
