import Immutable from 'seamless-immutable'
import * as types from './constants'

const initialState = Immutable({
  folderS3List: [],
})

/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_FOLDER_LIST_SUCCESS:
      return state.merge({
        folderS3List: action.payload.data,
      })

    default:
      return state
  }
}
