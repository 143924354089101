import React, { useEffect, useState } from 'react'
import { Modal, Form, Row, Col, Input } from 'antd'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import configuration from 'modules/configuration'
import login from 'modules/login'
import { onUserCreate } from './utils'
import { useParams } from 'react-router-dom'

const CreateUserModal = ({
  visible,
  onCancel,
  createUser,
  onRefetch,
  loggedUser: { society_id },
  fetchRolesList,
  // rolesList,
  // assignRole,
}) => {
  const [isLoadingModal, setIsLoadingModal] = useState(false)
  const [form] = Form.useForm()
  const { t } = useTranslation()
  let { societyId } = useParams()

  useEffect(() => {
    fetchRolesList({ society: society_id })
  }, [fetchRolesList, society_id])

  const onClose = () => {
    form.resetFields()
    onCancel()
  }

  return (
    <Modal
      title={t('CONFIG_USERS_MODAL_CREATE_USER_TITLE')}
      bodyStyle={{ overflowY: 'auto', maxHeight: 400 }}
      visible={visible}
      centered
      okText={t('ACTION_CREATE')}
      cancelText={t('ACTION_CANCEL')}
      onOk={() =>
        onUserCreate({
          form,
          setIsLoading: setIsLoadingModal,
          createUser: (data) => createUser({ ...data, society: societyId || society_id }),
          // assignRole: (id,data)=> assignRole(id,data),
          onRefetch,
          onClose,
        })
      }
      onCancel={onClose}
      width={570}
      okButtonProps={{ loading: isLoadingModal }}
      cancelButtonProps={{ disabled: isLoadingModal }}
    >
      <Form form={form} layout="vertical">
        <Row gutter={[12, 12]} type="flex" justify="center">
          <Col span={12}>
            <Form.Item
              name="first_name"
              label={t('FIELD_NAME')}
              validateTrigger="onBlur"
              rules={[
                {
                  required: true,
                  message: t('REQUIRED_FIELD'),
                },
              ]}
            >
              <Input placeholder={t('ACTION_ENTER')} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="last_name"
              label={t('FIELD_SURNAME')}
              validateTrigger="onBlur"
              rules={[
                {
                  required: true,
                  message: t('REQUIRED_FIELD'),
                },
              ]}
            >
              <Input placeholder={t('ACTION_ENTER')} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="email"
              label={t('FIELD_EMAIL')}
              validateTrigger="onBlur"
              rules={[
                {
                  type: 'email',
                  message: t('LOGIN_FORM_INPUT_EMAIL_CAPTION_FORMAT'),
                },
                {
                  required: true,
                  message: t('REQUIRED_FIELD'),
                },
              ]}
            >
              <Input placeholder={t('LOGIN_FORM_INPUT_EMAIL_PLACEHOLDER')} />
            </Form.Item>
          </Col>
          {/* <Col span={12}>
            <Form.Item
                name='role_ids'
                label={t('LABEL_ROLE')}
                validateTrigger="onBlur"
                rules={[
                  {
                    required: true,
                    message: t('REQUIRED_FIELD'),
                  },
                ]}
              >
                <Select
                  placeholder={t('ACTION_SELECT')}
                  options={generateSelectOptions({options: rolesList.data})}
                  mode="multiple"
                  maxTagCount={1}
                  maxTagTextLength={10}
                  showArrow
                  allowClear
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                />
              </Form.Item>
          </Col> */}
        </Row>
      </Form>
    </Modal>
  )
}

const mapStateToProps = (state) => ({
  loggedUser: login.selectors.getWhoAmI(state),
  rolesList: configuration.selectors.getRolesList(state),
})

const mapDispatchToProps = {
  createUser: configuration.actions.createUser,
  fetchRolesList: configuration.actions.fetchRolesList,
  assignRole: configuration.actions.assignRole,
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateUserModal)
