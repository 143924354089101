import React from 'react'
import { Space } from 'antd'
import { CalendarOutlined, DownOutlined } from '@ant-design/icons'
import { dateMMMMYYYY } from 'modules/core/utils'
import { useTranslation } from 'react-i18next'
import { AdjustmentDetailInfoItem } from './components'

import './AdjustmentDetailInfo.scss'

const AdjustmentDetailInfo = ({ date = null, type = '-', comment = '' }) => {
  const { t } = useTranslation()
  return (
    <Space size="large" align="top">
      <AdjustmentDetailInfoItem
        title={t('FIELD_YEAR_MONTH')}
        icon={<CalendarOutlined />}
        content={date ? dateMMMMYYYY(date, 'YYYY-MM-DD') : '-'}
      />
      <AdjustmentDetailInfoItem
        title={t('ADJUSTMENT_TYPE_FIELD')}
        icon={<DownOutlined />}
        content={type}
      />
      <AdjustmentDetailInfoItem
        title={t('FIELD_OBSERVATIONS')}
        isTextArea
        content={comment}
        placeholder={t('CONTROL_NO_OBSERVATIONS')}
      />
    </Space>
  )
}

export default AdjustmentDetailInfo
