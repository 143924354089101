import React, { useEffect, useState } from 'react'
import { Modal, Input, Form, Select, Row, Col } from 'antd'
import { useTranslation } from 'react-i18next'
import { generateSelectOptions } from 'modules/core/utils'
import { connect } from 'react-redux'
import configuration from 'modules/configuration'
import { onManagement } from './utils'

const ManagementModal = ({
  visible,
  onCancel,
  dataSelection,
  setSearchValue,
  dataSource,
  groupAliasDimension,
  onFetchDimensionsValues,
  dimensionId,
}) => {
  const [isSaving, setIsSaving] = useState(false)
  const [form] = Form.useForm()
  const { t } = useTranslation()

  useEffect(() => {
    if (visible) {
      form.setFieldsValue({ group_dimension: dataSelection })
    }
  }, [visible, form, dataSelection])

  const onClose = () => {
    form.resetFields()
    setIsSaving(false)
    onCancel()
  }

  return (
    <Modal
      title={t('CONFIG_DIMENSIONS_GROUP_DATA_MODAL_TITLE')}
      visible={visible}
      onCancel={onClose}
      cancelText={t('ACTION_CANCEL')}
      onOk={() =>
        onManagement({
          form,
          setIsLoading: setIsSaving,
          groupAliasDimension: (values) =>
            groupAliasDimension({
              ...values,
              group_dimension: values.group_dimension.map((id) => parseInt(id)),
              id_dimension: parseInt(dimensionId),
            }),
          onRefetch: onFetchDimensionsValues,
          onClose,
        })
      }
      okText={t('ACTION_SAVE')}
      centered
      destroyOnClose={true}
      okButtonProps={{ loading: isSaving, disabled: isSaving }}
      cancelButtonProps={{ disabled: isSaving }}
    >
      <Form form={form} layout="vertical">
        {() => (
          <Row gutter={[12, 12]}>
            <Col span={24}>
              <Form.Item
                label={t('CONFIG_DIMENSIONS_VALUES_SELECTED_FROM_SELECT_LABEL')}
                name="group_dimension"
              >
                <Select
                  mode="multiple"
                  maxTagTextLength={10}
                  maxTagCount={3}
                  allowClear
                  showSearch
                  showArrow
                  optionFilterProp="children"
                  filterOption={(input, option) => {
                    setSearchValue(input)
                    return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }}
                  options={generateSelectOptions({
                    options: dataSource.map((data) => {
                      return { id: data.id, name: data.code_origin }
                    }),
                  })}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={t('CONFIG_DIMENSIONS_FIELD_DESTINATION_NAME')}
                name="name"
                rules={[
                  {
                    required: true,
                    message: t('REQUIRED_FIELD'),
                  },
                ]}
              >
                <Input placeholder={t('ENTER_NAME_PLACEHOLDER')} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={t('CONFIG_DIMENSIONS_FIELD_DESTINATION_CODE')}
                name="code"
                rules={[
                  {
                    required: true,
                    message: t('REQUIRED_FIELD'),
                  },
                ]}
              >
                <Input placeholder={t('ENTER_CODE_PLACEHOLDER')} />
              </Form.Item>
            </Col>
          </Row>
        )}
      </Form>
    </Modal>
  )
}

const mapDispatchToProps = {
  groupAliasDimension: configuration.actions.groupAliasDimension,
}

export default connect(null, mapDispatchToProps)(ManagementModal)
