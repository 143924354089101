import { Button, ButtonActionBar } from 'modules/core/components'
// import { DateFilter } from "./components";

const UserActions = ({ onClick, disabled, setSelectedDates, selectedDates, dataPeriod }) => {
  return (
    <ButtonActionBar /*position="space-between"*/>
      {/* <DateFilter 
                setSelectedDates={setSelectedDates}
                selectedDates={selectedDates}
                dataPeriod={dataPeriod}
            /> */}
      <Button.Primary onClick={onClick} disabled={disabled} title="ACTION_UPLOAD_DATA" />
      {/* </ButtonActionBar><Button
            type="primary"
            onClick={() =>
                history.push(`/seguimiento/${periodId}/ajustes/`)
            }
            >
            {t('ADJUSTMENT_CREATE_ACTION')}
            </Button> */}
    </ButtonActionBar>
  )
}

export default UserActions
