import React, { useEffect, useState } from 'react'
import { Modal, Form, Row, Col, Input, Select, Radio } from 'antd'
import { generateSelectOptions } from 'modules/core/utils'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { onCreateDimension } from './utils'
import { DIMENSIONS } from 'modules/core/constants'
import configuration from 'modules/configuration'
import _ from 'lodash'

const ConfigurationCreateModal = ({
  visible,
  onCancel,
  createDimension,
  onFetchDimensionList,
  fetchDimensionPlika,
  dimensionPlikaList,
  dimensionsList,
  budgetTypeList,
}) => {
  const [isLoadingModal, setIsLoadingModal] = useState(false)
  const [form] = Form.useForm()
  const { t } = useTranslation()

  useEffect(() => {
    fetchDimensionPlika()
  }, [fetchDimensionPlika])

  const onClose = () => {
    form.resetFields()
    onCancel()
    setIsLoadingModal(false)
  }

  return (
    <Modal
      title={t('CONFIG_DIMENSIONS_EMPTY_STATE_CREATE_ACTION')}
      visible={visible}
      width={560}
      bodyStyle={{ overflowY: 'auto', maxHeight: 400 }}
      centered
      okText={t('ACTION_CREATE')}
      cancelText={t('ACTION_CANCEL')}
      onOk={() =>
        onCreateDimension({
          form,
          setIsLoading: setIsLoadingModal,
          createDimension,
          onRefetch: onFetchDimensionList,
          onClose,
        })
      }
      forceRender
      onCancel={onClose}
      okButtonProps={{ loading: isLoadingModal }}
      cancelButtonProps={{ disabled: isLoadingModal }}
    >
      <Form form={form} layout="vertical">
        {() => (
          <Row gutter={24} type="flex" justify="start">
            <Col span={24}>
              <Form.Item
                name="type_creation"
                label={t('CONFIG_DIMENSIONS_CHOOSE_TYPE_CREATION_FORM_RADIO_LABEL')}
                rules={[
                  {
                    required: true,
                    message: t('REQUIRED_FIELD'),
                  },
                ]}
                initialValue="new_dimension"
              >
                <Radio.Group>
                  {DIMENSIONS.OPTIONS_CREATE.map((option) => (
                    <Radio key={option.id} value={option.id}>
                      {t(option.name)}
                    </Radio>
                  ))}
                </Radio.Group>
              </Form.Item>
            </Col>
            {form.getFieldsValue().type_creation === 'dimension_plika' && (
              <Col span={12}>
                <Form.Item
                  name="dimension_plika"
                  label={t('HOME_INITIAL_STEPS_SELECT_DATA_DIMENSIONS_PLIKA_SELECT_TITLE')}
                  rules={[
                    {
                      required: true,
                      message: t('REQUIRED_FIELD'),
                    },
                  ]}
                >
                  <Select
                    options={generateSelectOptions({
                      options: dimensionPlikaList.map((dim) => {
                        return { id: dim.id, name: dim.name_dimension }
                      }),
                      selection: _.compact(
                        dimensionPlikaList.map((dim) => {
                          const has_dimension = dimensionsList.find(
                            (el) => el.name_destination === dim.name_dimension,
                          )
                          if (has_dimension) {
                            return dim.id.toString()
                          }
                          return null
                        }),
                      ),
                    })}
                    placeholder={t('ACTION_SELECT')}
                  />
                </Form.Item>
              </Col>
            )}
            <Col span={12}>
              <Form.Item
                name="name"
                label={t('FIELD_NAME')}
                rules={[
                  {
                    required: true,
                    message: t('REQUIRED_FIELD'),
                  },
                ]}
              >
                <Input placeholder={t('CONFIG_DIMENSIONS_INPUT_NAME_PLACEHOLDER')} />
              </Form.Item>
            </Col>
            <Col span={form.getFieldsValue().type_creation === 'dimension_plika' ? 24 : 12}>
              <Form.Item
                name="concepts"
                label={t('LABEL_BUDGET_TYPE')}
                rules={[
                  {
                    required: true,
                    message: t('REQUIRED_FIELD'),
                  },
                ]}
              >
                <Select
                  placeholder={t('ACTION_SELECT')}
                  allowClear
                  showArrow
                  showSearch
                  mode="multiple"
                  maxTagCount={1}
                  maxTagTextLength={8}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  options={generateSelectOptions({ options: budgetTypeList })}
                />
              </Form.Item>
            </Col>
          </Row>
        )}
      </Form>
    </Modal>
  )
}

const mapStateToProps = (state) => ({
  dimensionPlikaList: configuration.selectors.getDimensionPlikaList(state),
})

const mapDispatchToProps = {
  createDimension: configuration.actions.createDimension,
  fetchDimensionPlika: configuration.actions.fetchDimensionPlika,
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfigurationCreateModal)
