import React from 'react'
import { Col, Row } from 'antd'
import { /*Button, ButtonActionBar,*/ Typography } from 'modules/core/components'
import { connect } from 'react-redux'
// import { PlusCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next'
import CombinationDimensionsTable from '../CombinationDimensionsTable/CombinationDimensionsTable'
import configuration from 'modules/configuration'

const OriginDimensions = ({
  loadingTable,
  loading,
  dataKey,
  // deleteDimensionsSelection,
  // addDimensionsSelections,
  rowsDimensions,
  setDimensionsSelection,
  dimensionsSelection,
  allDimensionsList,
  areAllSelected,
  setAreAllSelected,
}) => {
  const { t } = useTranslation()

  // const columnActions = [
  //     {
  //         dataIndex: 'id',
  //         width: 30,
  //         fixed: 'right',
  //         render: (id, index) => (
  //             <Button.Icon
  //                 title='ADJUSTMENT_DELETE_MOVEMENT_ACTION_TITLE'
  //                 disabled={rowsDimensions.origin_dimensions.length <= 1}
  //                 icon={
  //                 <Typography.Icon icon={DeleteOutlined} level={2} />
  //                 }
  //                 onClick={() => deleteDimensionsSelection({key: dataKey, index})}
  //             />

  //         )
  //     }

  // ]

  return (
    <Row gutter={[12, 12]}>
      <Col span={24}>
        <Typography.Body level={2}>
          {t('CONFIG_RULES_ORIGIN_VALUES_STEP_ORIGIN_SUBTITLE')}
        </Typography.Body>
      </Col>
      <Col span={24}>
        <CombinationDimensionsTable
          dataSource={rowsDimensions.origin_dimensions}
          loadingTable={loadingTable}
          dataKey={dataKey}
          loading={loading}
          setDimensionsSelection={setDimensionsSelection}
          dimensionsSelection={dimensionsSelection.origin_dimensions}
          dimensionsList={allDimensionsList}
          // extraColumns={columnActions}
          areAllSelected={areAllSelected}
          setAreAllSelected={setAreAllSelected}
        />
      </Col>
      {/* <Col span={24}>
                <ButtonActionBar>
                    <Button.LinkWhitLeftIcon
                        icon={<PlusCircleOutlined />}
                        onClick={()=> addDimensionsSelections({key: dataKey})}
                        title='ADJUSTMENT_ADD_MOVEMENT_ACTION'
                    />
                </ButtonActionBar>
            </Col> */}
    </Row>
  )
}

const mapStateToProps = (state) => ({
  rowsDimensions: configuration.selectors.getRowsDimensions(state),
  dimensionsSelection: configuration.selectors.getDimensionsSelection(state),
  allDimensionsList: configuration.selectors.getAllDimensionsList(state),
})

const mapDispatchToProps = {
  deleteDimensionsSelection: configuration.actions.deleteDimensionsSelection,
  // addDimensionsSelections: configuration.actions.addDimensionsSelection,
  setDimensionsSelection: configuration.actions.setDimensionsSelection,
}

export default connect(mapStateToProps, mapDispatchToProps)(OriginDimensions)
