import React, { useState } from 'react'
import { Space } from 'antd'
import {
  Button,
  ButtonActionBar,
  FiltersContainer,
  RangePicker,
  TopCollapsableMenu,
} from 'modules/core/components'
import moment from 'moment'
import _ from 'lodash'

const DateAndDimensionFilters = ({
  dataPeriod,
  setSelectedDates,
  selectedDates,
  periodId,
  filters,
  setFilters,
  showButtonDeleteFilter,
  isRefresh,
  setIsRefresh,
  setPagination,
}) => {
  const [selectionToFilter, setSelectionToFilter] = useState({})

  const handleOnClick = () => {
    showButtonDeleteFilter && showButtonDeleteFilter(true)
    setFilters({ ...filters, ...selectionToFilter })
    setPagination && setPagination()
  }

  const handleChangeDate = (e) => {
    const isEmpty = !_.isEmpty(e)
    setSelectedDates && setSelectedDates(e)
    showButtonDeleteFilter && showButtonDeleteFilter(isEmpty)
  }

  return (
    <TopCollapsableMenu>
      <Space direction="vertical" style={{ paddingRight: 30 }}>
        <Space size="small" className="style-detail-space">
          {!_.isEmpty(dataPeriod) && (
            <RangePicker
              onSetRange={handleChangeDate}
              periodDate={{
                startDate: dataPeriod?.start_date,
                endDate: dataPeriod?.end_date,
              }}
              defaultPickerValue={
                !_.isEmpty(dataPeriod)
                  ? [moment(dataPeriod?.start_date), moment(dataPeriod?.end_date)]
                  : null
              }
              defaultValue={
                !_.isEmpty(selectedDates) && [
                  moment(selectedDates['start-date'], 'YYYY-MM-01'),
                  moment(selectedDates['end-date'], 'YYYY-MM-01'),
                ]
              }
            />
          )}
          <FiltersContainer
            periodId={periodId}
            filter={filters}
            showButtonDeleteFilter={showButtonDeleteFilter}
            isRefresh={isRefresh}
            setIsRefresh={setIsRefresh}
            setPagination={setPagination}
            selectionToFilter={selectionToFilter}
            setSelectionToFilter={setSelectionToFilter}
          />
        </Space>
        <ButtonActionBar>
          <Button.Primary onClick={handleOnClick} title="ACTION_APPLY" />
        </ButtonActionBar>
      </Space>
    </TopCollapsableMenu>
  )
}

export default DateAndDimensionFilters
