import { Card, Col } from 'antd'

const LoadingKPIs = () => {
  return [1, 2, 3].map((index) => (
    <Col span={8} key={index}>
      <Card loading={true} />
    </Col>
  ))
}

export default LoadingKPIs
