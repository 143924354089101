import { Popconfirm } from 'antd'
import React from 'react'
import { DropdownMenu } from 'modules/core/components'
import { isUserAllowed } from 'modules/core/utils'
import { useTranslation } from 'react-i18next'
import { EditOutlined, DeleteOutlined } from '@ant-design/icons'
import { ROLES } from 'modules/core/constants'

const { SETTINGS__DIMENSIONS__ABM } = ROLES

const TableActions = ({ onConfirmDelete, onCancelDelete, visible, onClickEdit, onClickDelete }) => {
  const { t } = useTranslation()
  return (
    <Popconfirm
      placement="bottomRight"
      title={t('CONFIG_DIMENSIONS_VALUES_POPCONFIRM_DELETE_VALUE_TITLE')}
      okText={t('ACTION_DELETE')}
      onConfirm={onConfirmDelete}
      cancelText={t('ACTION_CANCEL')}
      onCancel={onCancelDelete}
      visible={visible}
    >
      <DropdownMenu
        title={t('ACTION_MORE')}
        menu={[
          {
            title: t('ACTION_EDIT'),
            icon: <EditOutlined />,
            onClick: onClickEdit,
            disabled: !isUserAllowed(SETTINGS__DIMENSIONS__ABM),
          },
          {
            title: t('ACTION_DELETE'),
            icon: <DeleteOutlined />,
            onClick: onClickDelete,
            disabled: !isUserAllowed(SETTINGS__DIMENSIONS__ABM),
          },
        ]}
      />
    </Popconfirm>
  )
}

export default TableActions
