import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Row, Col } from 'antd'
import { ConfigurationUploadModal } from 'modules/configuration/components'
import { BudgetTypeTable, TableActions } from './components'
import { SiderLayout } from 'modules/core/layouts'
import { ButtonActionBar, Navigator } from 'modules/core/components'
import { CreateBudgetTypeModal } from '../../components'
import { usePagination } from 'modules/core/customHooks'
import planning from 'modules/planning'
import configuration from 'modules/configuration'

//TODO: DOWNLOAD TABLE DATA
const BudgetTypeList = ({ uploadBudgetTypeValuesXLS, fetchBudgetTypeList, budgetTypeList }) => {
  const [showUploadModal, setShowUploadModal] = useState(false)
  const [isTableLoading, setIsTableLoading] = useState(false)
  const [tablePagination, setTablePagination] = usePagination()
  const { t } = useTranslation()

  useEffect(() => {
    setIsTableLoading(true)
    fetchBudgetTypeList({ ...tablePagination }).then(() => setIsTableLoading(false))
  }, [fetchBudgetTypeList, tablePagination])

  const renderHeader = () => (
    <Navigator
      floated={false}
      size="big"
      items={[
        {
          name: t('LABEL_CONFIG'),
          url: `/configuracion`,
        },
        { name: t('LABEL_BUDGET_TYPE') },
      ]}
    />
  )

  const renderTable = () => (
    <BudgetTypeTable
      dataSource={budgetTypeList?.values}
      count={budgetTypeList?.count}
      tableLoading={isTableLoading}
      onChange={setTablePagination}
      pagination={tablePagination}
      onRefetchBudgetTypeList={() => fetchBudgetTypeList({ ...tablePagination })}
    />
  )

  const renderUserActions = () => (
    <ButtonActionBar>
      <CreateBudgetTypeModal
        budgetTypeList={budgetTypeList}
        onRefetchBudgetTypeList={() => fetchBudgetTypeList({ ...tablePagination })}
      />
    </ButtonActionBar>
  )

  const renderTableActions = () => <TableActions />

  return (
    <SiderLayout>
      <Row gutter={[8, 8]}>
        <Col span={24}>{renderHeader()}</Col>
        <Col span={24}>{renderUserActions()}</Col>
        <Col span={24}>{renderTableActions()}</Col>
        <Col span={24}>{renderTable()}</Col>
      </Row>
      <ConfigurationUploadModal
        title={t('CONFIG_CONCEPTS_UPLOAD_MODAL_TITLE')}
        visible={showUploadModal}
        onCancel={() => setShowUploadModal(false)}
        onSuccessUpload={() => fetchBudgetTypeList({ ...tablePagination })}
        uploadValuesXLS={(file) => uploadBudgetTypeValuesXLS(file)}
      />
    </SiderLayout>
  )
}

const mapStateToProps = (state) => ({
  budgetTypeList: configuration.selectors.getBudgetTypeList(state),
})

const mapDispatchToProps = {
  uploadBudgetTypeValuesXLS: planning.actions.uploadBudgetTypeValuesXLS,
  fetchBudgetTypeList: configuration.actions.fetchBudgetTypeList,
}

export default connect(mapStateToProps, mapDispatchToProps)(BudgetTypeList)
