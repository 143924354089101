import React, { useEffect, useState } from 'react'
import { Input } from 'antd'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { QuestionList } from './components'
import chatbot from 'modules/chatbot'
import configuration from 'modules/configuration'
import planning from 'modules/planning'

import './ChatSideBar.scss'

const ChatSideBar = ({
  onSetMessage,
  questionList,
  valuesList,
  conceptList,
  periodList,
  dimensionList,
}) => {
  const { Search } = Input
  const { t } = useTranslation()

  const [keywords, setKeywords] = useState('')
  const [questionListData, setQuestionListData] = useState([])

  useEffect(() => {
    if (!questionList) return
    let listValues = getQuestionList()
    setQuestionListData(listValues)

    // eslint-disable-next-line
  }, [questionList, conceptList, dimensionList, periodList, valuesList])

  const getQuestionList = () => {
    let normalizeQuestion = questionList.values.map((item) => {
      let labelQuestion = item.name
        .replaceAll('#concepto', conceptList?.values[0].name.toLowerCase())
        .replaceAll('#dimension', dimensionList[0]?.name_origin.toLowerCase())
        .replaceAll('#periodo', periodList[0].name.toLowerCase())
        .replaceAll('#tipo_tiempo', valuesList?.unitOfTime[0].name.toLowerCase())
        .replaceAll('#tipo_importe', valuesList.unitOfAmount[0].name.toLowerCase())

      return { ...item, label: labelQuestion }
    })

    if (keywords !== '') {
      return normalizeQuestion.filter((item) =>
        item.label.toUpperCase().includes(keywords.toUpperCase()),
      )
    }
    return normalizeQuestion
  }

  return (
    <div className="chat-sidebar">
      <div className="chat-sidebar-user-view"></div>
      <div className="chat-sidebar-search-view">
        <Search
          className="chat-sidebar-search"
          placeholder={t('common.searchHere')}
          value={keywords}
          onChange={(e) => setKeywords(e.target.value)}
        />
      </div>
      <QuestionList chatListData={questionListData} onSetMessage={onSetMessage} />
    </div>
  )
}

const mapStateToProps = (state) => ({
  questionList: chatbot.selectors.getQuestionList(state),
  valuesList: chatbot.selectors.getValuesList(state),
  conceptList: configuration.selectors.getBudgetTypeList(state),
  periodList: planning.selectors.getPeriodList(state),
  dimensionList: configuration.selectors.getAllDimensionsList(state),
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(ChatSideBar)
