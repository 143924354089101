import React, { useState } from 'react'
import { Spin } from 'antd'
import { ScreenSteps } from './components'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { ScreenWellDone } from 'modules/core/components'
import timerImage from 'assets/images/compositions/timer.svg'

const InitialSteps = () => {
  const [showScreenProcessing, setShowScreenProcessing] = useState(false)
  const { t } = useTranslation()

  let history = useHistory()

  const renderTime = () => {
    setTimeout(() => {
      setShowScreenProcessing(false)
      history.push('/configuracion/dimensiones')
    }, 15000)
  }

  return (
    <>
      {!showScreenProcessing && <ScreenSteps setShowScreenProcessing={setShowScreenProcessing} />}
      {showScreenProcessing && (
        <ScreenWellDone
          image={timerImage}
          title={t('HOME_INITIAL_STEPS_PROCESSING_DATA_SCREEN_PROCESSING_TITLE')}
          description={t('HOME_INITIAL_STEPS_INFORMATIVE_SCREEN_PROCESSING_TITLE')}
          footer={<Spin spinning={renderTime()} />}
        />
      )}
    </>
  )
}

export default InitialSteps
