import { Collapse } from 'antd'
import _ from 'lodash'
import { ROLES } from 'modules/core/constants'
import { OpCheck, OpContent } from './components'
import { renderCondition, renderLabel } from './utils'

const { Panel } = Collapse
const { MODULES_OPTIONS } = ROLES

const filterByConcept = (key, values, ids) => {
  if (['PLANNING', 'CONTROL/REAL', 'FORECAST'].includes(key)) {
    const childrenRolesIds = _.concat(ids, [9999])
    return values.filter((it) => childrenRolesIds.includes(it.id))
  }
  return values
}

const CollapseOp = ({ budgetTypeList, visible }) => {
  const conceptIds = budgetTypeList?.values.map((it) => it.id)

  const renderScreenCollapse = (options) => (
    <Collapse accordion={true}>
      {options.map((collapse) => {
        return (
          renderCondition({ collapse, budgetTypeList }) &&
          visible && (
            <Panel
              header={visible && renderLabel({ collapse, budgetTypeList })}
              key={collapse.key}
              extra={<OpCheck panelArrayKeys={collapse.operationsKeys} />}
            >
              {/* NOTE: Se diferencian las opciones que tienen submodulos y las que no */}
              {collapse.children ? (
                renderScreenCollapse(filterByConcept(collapse.key, collapse.children, conceptIds))
              ) : (
                <OpContent operations={collapse.operations} />
              )}
            </Panel>
          )
        )
      })}
    </Collapse>
  )
  return renderScreenCollapse(MODULES_OPTIONS)
}

export default CollapseOp
