import React, { useEffect, useState } from 'react'
import { Popconfirm, Space } from 'antd'
import { Button, DropdownMenu, Typography } from 'modules/core/components'
import { EyeOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import { onDeleteSociety } from './utils'
import societies from 'modules/societies'

const TableActions = ({ id, setEditRow, setIsLoading, deleteSociety, onFetchSocietiesList }) => {
  const [deleteSocietyId, setDeleteSocietyId] = useState(null)
  const { t } = useTranslation()
  let history = useHistory()

  useEffect(() => {
    return () => {
      setDeleteSocietyId(null)
    }
  }, [])

  const onClose = () => {
    setDeleteSocietyId(null)
    setIsLoading(false)
  }

  return (
    <Popconfirm
      placement="bottomRight"
      title={<div style={{ width: 400 }}>{t('SOCIETY_DELETE_POPCONFIRM_TITLE')}</div>}
      okText={t('ACTION_DELETE')}
      onConfirm={() =>
        onDeleteSociety({
          deleteSociety: () => deleteSociety(id),
          setIsLoading,
          onRefetch: onFetchSocietiesList,
          onClose,
        })
      }
      cancelText={t('ACTION_CANCEL')}
      onCancel={() => setDeleteSocietyId(null)}
      visible={deleteSocietyId === id}
    >
      <Space>
        <Button.Icon
          icon={<Typography.Icon icon={EyeOutlined} />}
          title="ACTION_OPEN_DETAIL"
          onClick={() => history.push(`/empresas/${id}`)}
        />
        <DropdownMenu
          title={t('ACTION_MORE')}
          menu={[
            {
              title: t('ACTION_EDIT'),
              icon: <EditOutlined />,
              onClick: () => {
                setEditRow(id)
              },
            },
            {
              title: t('ACTION_DELETE'),
              icon: <DeleteOutlined />,
              onClick: () => setDeleteSocietyId(id),
            },
          ]}
        />
      </Space>
    </Popconfirm>
  )
}

const mapDispatchToProps = {
  deleteSociety: societies.actions.deleteSociety,
}

export default connect(null, mapDispatchToProps)(TableActions)
