import React from 'react'
import { Divider } from 'antd'
import { Button, ButtonActionBar } from 'modules/core/components'
import { PlusCircleOutlined, DeleteOutlined } from '@ant-design/icons'

const AddAndDeleteFormItem = ({
  fieldsLength,
  index,
  addData,
  deleteData,
  otherCondition = null,
}) => {
  return (
    <>
      <ButtonActionBar>
        {(otherCondition ? otherCondition : fieldsLength > 1) && (
          <Button.LinkWhitLeftIcon
            icon={<DeleteOutlined />}
            onClick={deleteData.onClick}
            disabled={deleteData.disabled}
            title={deleteData.buttonName}
          />
        )}
        {fieldsLength - 1 === parseInt(index) && (
          <Button.LinkWhitLeftIcon
            icon={<PlusCircleOutlined />}
            onClick={addData.onClick}
            disabled={addData.disabled}
            title={addData.buttonName}
          />
        )}
      </ButtonActionBar>
      {fieldsLength - 1 !== parseInt(index) && <Divider />}
    </>
  )
}
export default AddAndDeleteFormItem
