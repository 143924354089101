import React from 'react'
import { Col, Form, Row, Slider, Switch } from 'antd'
import { useTranslation } from 'react-i18next'
import GeneralFormItems from '../GeneralFormItems/GeneralFormItems'

const PieChartSettings = ({ form, defaultSetting, chart_type }) => {
  const { t } = useTranslation()
  return (
    <Form form={form} layout="horizontal" hideRequiredMark={false} initialValues={defaultSetting}>
      {() => (
        <Row gutter={[8, 8]}>
          <Col span={10}>
            <Form.Item
              label={t('FIELD_TOTAL_AMOUNT')}
              name="showTotalAmount"
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
          </Col>
          <Col span={14}>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) =>
                prevValues.showTotalAmount !== currentValues.showTotalAmount
              }
            >
              {({ getFieldValue }) =>
                getFieldValue('showTotalAmount') && (
                  <Form.Item label={t('LABEL_FONT_SIZE')} name="totalFontSize">
                    <Slider min={18} max={35} />
                  </Form.Item>
                )
              }
            </Form.Item>
          </Col>
          <GeneralFormItems chart_type={chart_type} />
        </Row>
      )}
    </Form>
  )
}

export default PieChartSettings
