import React, { useState } from 'react'
import { Card, Col, Dropdown, Menu, message, Row } from 'antd'
import { ButtonActionBar, Comments, KPIValue, Typography } from 'modules/core/components'
import { CommentOutlined } from '@ant-design/icons'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import numeral from 'numeral'
import login from 'modules/login'
import configuration from 'modules/configuration'
import _ from 'lodash'
import { generateMessageError, renderPrefix } from 'modules/core/utils'
import { GENERAL } from 'modules/core/constants'

const ReportDetailKPICard = ({
  title,
  value,
  prefix,
  name,
  id,
  subkpis,
  hasComment,
  setHasComment,
  loggedUser,
  setDataComments,
  dataComments,
  onRefetchComments,
  loadingComments,
  commentKey,
  createComment,
  removeNewComment,
  currencyData,
}) => {
  const [viewComment, setViewComment] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const { t } = useTranslation()

  const handleCreate = ({ text, usersIds, idComment = null, setIsNewThread }) => {
    const dataa = {
      text,
      key: `${commentKey}-card-${id}`,
      module: commentKey,
      mentions: usersIds,
      ...(idComment && {
        parent: idComment,
      }),
    }
    setIsSaving(true)
    createComment(dataa)
      .then(() =>
        onRefetchComments().then(() => {
          message.success(t('FEEDBACK_SAVE_CHANGES_SUCCES'), 8)
          setIsSaving(false)
          setIsNewThread(false)
        }),
      )
      .catch((error) => {
        message.error(generateMessageError(error, 'FEEDBACK_SAVE_CHANGES_FAIL_MESSAGE'), 8)
        setIsSaving(false)
        setIsNewThread(false)
      })
  }

  const renderExtra = () => (
    <ButtonActionBar>
      {(viewComment ||
        dataComments.filter((el) => el.key === `${commentKey}-card-${id}` && !el.resolved).length >
          0) && (
        <Comments
          hasComment={
            !_.isEmpty(hasComment?.card)
              ? !_.isEmpty(hasComment?.card[commentKey]) && hasComment?.card[commentKey][id]
              : false
          }
          onClick={(visible) => {
            setHasComment({ type: 'card', data: visible, key: commentKey, id })
            !visible && removeNewComment()
            !visible && setViewComment(visible)
          }}
          comments={dataComments.filter(
            (el) => (el.key === `${commentKey}-card-${id}` && !el.resolved) || !el.text,
          )}
          setData={setDataComments}
          loading={isSaving || loadingComments}
          onRefetchComments={onRefetchComments}
          onCommentCreate={(text, usersIds, idComment, setIsNewThread) =>
            handleCreate({ text, usersIds, idComment, setIsNewThread })
          }
          onCleanHasComment={() => {
            setHasComment({ type: 'card', data: false, key: commentKey, id })
            setViewComment(false)
          }}
        >
          <Typography.Icon
            style={{ cursor: 'pointer' }}
            title={t('LABEL_COMMENTS')}
            level={2}
            icon={CommentOutlined}
          />
        </Comments>
      )}
    </ButtonActionBar>
  )

  return (
    <Dropdown
      trigger={['contextMenu']}
      overlay={
        <Menu>
          {GENERAL.OPTION_COMMENT.map((el) => (
            <Menu.Item
              key={el.key}
              icon={<CommentOutlined />}
              onClick={() => {
                setViewComment(true)
                setHasComment({
                  type: 'card',
                  data: !_.isEmpty(hasComment?.card)
                    ? !_.isEmpty(hasComment?.card[commentKey])
                      ? !hasComment?.card[commentKey][id]
                      : true
                    : true,
                  key: commentKey,
                  id,
                })
              }}
            >
              {t(el.label)}
            </Menu.Item>
          ))}
        </Menu>
      }
    >
      <Card
        className="report-detail-kpi-card"
        extra={renderExtra()}
        title={title}
        size="small"
        data-type={'default'}
      >
        <Row align="middle" gutter={[8, 8]}>
          <Col span={24}>
            <KPIValue
              size="medium"
              fullValue={value && numeral(value).format('0,0.00')}
              value={value ? numeral(value).format('0[.]0a') : '-'}
              prefix={renderPrefix(currencyData, prefix)}
            />
            <Typography.Body title={title} level={3} type="secondary">
              {name}
            </Typography.Body>
          </Col>
          {subkpis.map((kpi, index) => (
            <Col span={8} key={index}>
              <KPIValue
                size="extra-small"
                prefix={renderPrefix(currencyData, kpi.prefix)}
                fullValue={kpi.value && numeral(kpi.value).format('0,0.00')}
                value={kpi.value ? numeral(kpi.value).format('0[.]0a') : '-'}
                variationValue={kpi.variation}
              />
              <br />
              <Typography.Body title={kpi.name} level={3} type="secondary">
                {kpi.name}
              </Typography.Body>
            </Col>
          ))}
        </Row>
      </Card>
    </Dropdown>
  )
}

const mapStateToProps = (state) => ({
  loggedUser: login.selectors.getWhoAmI(state),
  hasComment: configuration.selectors.getHasComment(state),
})

const mapDispatchToProps = {
  setHasComment: configuration.actions.setHasComment,
  createComment: configuration.actions.createComment,
  removeNewComment: configuration.actions.removeNewComment,
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportDetailKPICard)
