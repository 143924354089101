import React, { useState } from 'react'
import { Modal } from 'antd'
import { useTranslation } from 'react-i18next'
import { onPublishApprove } from './utils'

const ActionModal = ({
  globalBudgetId,
  visible,
  onFetchDetail,
  onConfirm = () => {},
  onClose,
  onAction,
  isActionApprove,
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const { t } = useTranslation()

  const onCancel = () => {
    onConfirm()
    onClose()
    setIsLoading(false)
  }

  return (
    <Modal
      title={t('PLANNING_ACTION_MODAL_TITLE', {
        action: isActionApprove ? t('ACTION_APPROVE') : t('ACTION_PUBLISH'),
      })}
      visible={visible}
      destroyOnClose={true}
      onOk={() =>
        onPublishApprove({
          setIsLoading,
          onAction: () => onAction(globalBudgetId),
          onRefetch: onFetchDetail,
          onClose: onCancel,
          isActionApprove,
        })
      }
      okText={isActionApprove ? t('ACTION_APPROVE') : t('ACTION_PUBLISH')}
      cancelText={t('ACTION_CANCEL')}
      onCancel={onClose}
      centered
      width={568}
      okButtonProps={{ loading: isLoading }}
      cancelButtonProps={{ disabled: isLoading }}
    >
      {isActionApprove ? t('PLANNING_APPROVE_MODAL_TEXT') : t('PLANNIG_PUBLISH_MODAL_TEXT')}
    </Modal>
  )
}

export default ActionModal
