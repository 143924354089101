import { Popover } from 'antd'
import { CommentsContent } from './components'

const Comments = ({
  children,
  hasComment,
  onClick,
  comments,
  setData,
  loading,
  onRefetchComments,
  onCommentCreate,
  onCleanHasComment,
}) => {
  return (
    <Popover
      visible={hasComment}
      trigger="click"
      destroyTooltipOnHide={true}
      placement="topRight"
      onVisibleChange={(visible) => onClick(visible)}
      content={
        <CommentsContent
          data={comments}
          setData={setData}
          onCommentCreate={onCommentCreate}
          onRefetchComments={onRefetchComments}
          loading={loading}
          onCleanHasComment={onCleanHasComment}
        />
      }
    >
      {children}
    </Popover>
  )
}

export default Comments
