import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { Spin, Table, Row, Col } from 'antd'
import { Button, ButtonActionBar, Navigator } from 'modules/core/components'
import { SiderLayout } from 'modules/core/layouts'
import { UserAvatar } from 'modules/configuration/components'
import { client } from 'httpclient'
import { ConfigUserDrawer } from './components'
import configuration from 'modules/configuration'
import login from 'modules/login'

const UserDetail = ({
  loggedUser: { password_confirmed_google, password_confirmed_azure, totp_enabled },
}) => {
  const [isScreenLoading, setIsScreenLoading] = useState(true)
  const [options, setOptions] = useState([])
  const [showDrawer, setShowDrawer] = useState(false)
  const { t } = useTranslation()
  let { userId, myProfile } = useParams()

  useEffect(() => {
    if (userId) {
      client.get('/auth/list_users/').then((response) => {
        setIsScreenLoading(false)
        setOptions(response.data)
      })
    }
  }, [userId])

  const userDetail = options.find((user) => user.id === parseInt(userId))

  const renderHeader = () => (
    <Navigator
      floated={false}
      size="big"
      items={
        myProfile
          ? [{ name: t('LABEL_PROFILE') }]
          : [
              {
                name: t('LABEL_CONFIG'),
                url: `/configuracion`,
              },
              {
                name: t('LABEL_USERS'),
                url: '/configuracion/seguridad/?tab=users',
              },
              {
                name: t('LABEL_DETAIL'),
              },
            ]
      }
    />
  )

  const renderUserAvatar = () => {
    // if (loggedUser.password_confirmed) {
    return (
      <UserAvatar
        size="large"
        avatarLetter={`${userDetail?.last_name[0]}${userDetail?.first_name[0]}`}
        fullname={`${userDetail?.last_name}, ${userDetail?.first_name}`}
      />
    )
    // }
    // return (
    //   <UserAvatar
    //     size="large"
    //     avatarLetter={userDetail?.email[0]}
    //     fullname={userDetail?.email.substr(0, userDetail?.email.indexOf('@'))}
    //     isItalic={true}
    //   />
    // );
  }

  const renderDetailTable = () => (
    <Table
      bordered
      size="small"
      rowKey="profile"
      columns={[
        { title: t('LABEL_PROFILE'), dataIndex: 'profile' },
        { title: '', dataIndex: 'value' },
      ]}
      dataSource={[
        { profile: t('FIELD_EMAIL'), value: userDetail?.email },
        { profile: t('CONFIG_USER_COMPANY_POSITION_FIELD'), value: '-' },
        {
          profile: t('LABEL_ROLES'),
          value:
            userDetail?.roles.length > 0
              ? userDetail?.roles.map((rol) => rol.name).join(', ')
              : '-',
        },
        {
          profile: t('FIELD_STATUS'),
          value: userDetail?.status ? t('STATE_TAG_ACTIVE') : t('STATE_TAG_INACTIVE'),
        },
      ]}
      pagination={false}
    />
  )
  return (
    <SiderLayout>
      <Spin spinning={isScreenLoading} />
      {!isScreenLoading && (
        <>
          <Row gutter={[8, 8]}>
            <Col span={24}>{renderHeader()}</Col>
            <Col span={24}>
              <ButtonActionBar>
                <Button.Primary title="ACTION_CONFIGURATE" onClick={() => setShowDrawer(true)} />
              </ButtonActionBar>
            </Col>
            <Col span={12} offset={10}>
              {renderUserAvatar()}
            </Col>
            <Col span={24}>{renderDetailTable()}</Col>
          </Row>
          <ConfigUserDrawer
            visible={showDrawer}
            onClose={() => setShowDrawer(false)}
            is_configured_google={password_confirmed_google}
            is_configured_azure={password_confirmed_azure}
            is_configured_qrCode={totp_enabled}
          />
        </>
      )}
    </SiderLayout>
  )
}

const mapStateToProps = (state) => ({
  loggedUser: login.selectors.getWhoAmI(state),
  usersList: configuration.selectors.getUsersList(state),
})

const mapDispatchToProps = {
  fetchUsersList: configuration.actions.fetchUsersList,
}

export default connect(mapStateToProps, mapDispatchToProps)(UserDetail)
