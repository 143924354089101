import { Table } from 'antd'
import { getRowClassName } from 'modules/configuration/containers/Reports/containers/ReportsDetail/utils/DynamicClass'
import React from 'react'
import { useTranslation } from 'react-i18next'

const ReportDetailTable = ({ dataSource, loading }) => {
  const { t } = useTranslation()

  // Generar clase dinamica para la row
  const generateRowClassName = (record) => {
    return getRowClassName(record)
  }

  const columns = [
    {
      title: t('CONFIG_REPORT_STRUCTURE_FIELD'),
      dataIndex: 'name',
    },
  ]

  return (
    <Table
      rowKey="id"
      bordered
      size="small"
      loading={loading}
      dataSource={dataSource}
      columns={columns}
      rowClassName={generateRowClassName}
      expandable={{
        rowExpandable: (record) => record.children.length && record.children.length > 0,
      }}
      pagination={false}
    />
  )
}

export default ReportDetailTable
