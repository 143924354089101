import React, { useEffect, useState } from 'react'
import { Spin } from 'antd'
import { ChatbotContainer } from 'modules/chatbot/components'
import { SiderLayout } from 'modules/core/layouts'
import { connect } from 'react-redux'
import { ChatContent, ChatSideBar } from './components'
import chatbot from 'modules/chatbot'
import planning from 'modules/planning'
import configuration from 'modules/configuration'
import login from 'modules/login'
import { sendUserDataToGTM } from 'modules/core/utils'

const ChatbotHome = ({
  fetchQuestionList,
  fetchValuesList,
  fetchConceptList,
  fetchPeriodList,
  fetchDimensionList,
  loggedUser,
}) => {
  const [isDataLoading, setIsDataLoading] = useState(true)
  const [message, setMessage] = useState('')

  useEffect(() => {
    setIsDataLoading(true)
    Promise.all([
      fetchQuestionList(),
      fetchValuesList(),
      fetchConceptList(),
      fetchPeriodList(),
      fetchDimensionList(),
    ])
      .then(() => {
        setIsDataLoading(false)
      })
      .catch((e) => {
        setIsDataLoading(false)
      })
  }, [fetchQuestionList, fetchValuesList, fetchConceptList, fetchPeriodList, fetchDimensionList])

  useEffect(() => {
    sendUserDataToGTM(loggedUser, '/chatbot')
  }, [loggedUser])

  return (
    <SiderLayout>
      <Spin spinning={isDataLoading} size="large" />
      <ChatbotContainer sidebarContent={<ChatSideBar onSetMessage={setMessage} />}>
        <ChatContent onMessage={message} />
      </ChatbotContainer>
    </SiderLayout>
  )
}

const mapStateToProps = (state) => ({
  loggedUser: login.selectors.getWhoAmI(state),
})

const mapDispatchToProps = {
  fetchQuestionList: chatbot.actions.fetchQuestionList,
  fetchValuesList: chatbot.actions.fetchValuesList,
  fetchConceptList: configuration.actions.fetchBudgetTypeList,
  fetchPeriodList: planning.actions.fetchPeriodList,
  fetchDimensionList: configuration.actions.fetchAllDimensions,
}

export default connect(mapStateToProps, mapDispatchToProps)(ChatbotHome)
