import ReactGA from 'react-ga'

//FIXME: Emprolijar con unua variable de entorno que sea definidad en el proceso de CI/CD
// process.env.REACT_APP_GA_TRACKING_CODE
const TRACKING_CODE_MAP = {
  localhost: 'UA-175794731-2',
  test: 'UA-175794731-2',
  stage: 'UA-175794731-3',
  we: 'UA-175794731-4',
}

const init = (userId) => {
  const hostname =
    window.location.hostname === 'localhost'
      ? window.location.hostname
      : window.location.hostname.split('.')[0]
  const TRACKING_CODE = TRACKING_CODE_MAP[hostname]

  return ReactGA.initialize(TRACKING_CODE, {
    debug: false,
    gaOptions: {
      userId: userId,
    },
  })
}

const set = (user) => {
  const { userId, society } = user
  return ReactGA.set({
    dimension1: society,
    dimension2: userId,
    userId: userId,
  })
}

const startSession = () => {
  // Starts a new session.
  return ReactGA.ga('send', 'pageview', { sessionControl: 'start' })
}

const endSession = () => {
  // Ends current session.
  return ReactGA.ga('send', 'pageview', { sessionControl: 'end' })
}

const pageView = () => {
  return ReactGA.pageview(window.location.pathname + window.location.search)
}

const event = (event = { category: '', action: '' }) => {
  return ReactGA.event({
    ...event,
  })
}

//++ ACTIONS - GOOGLE ANALYTIC ++
const USER_LOGIN = {
  category: 'Log In',
  action: 'Usuario logeado',
}

const USER_LOGIN_ERROR = {
  category: 'Error',
  action: 'Usuario logeado',
}

const USER_LOGOUT = {
  category: 'Log Out',
  action: 'Usuario deslogeado',
}

const FILE_UPLOAD_REAL = {
  category: 'Carga de datos',
  action: 'Carga de datos reales',
}

const FILE_UPLOAD_REAL_ERROR = {
  category: 'Error',
  action: 'Error carga de datos reales',
}

const FILE_UPLOAD_BUDGET = {
  category: 'Carga de datos',
  action: 'Carga de datos en presupuesto',
}

const FILE_UPLOAD_BUDGET_ERROR = {
  category: 'Error',
  action: 'Error carga de datos en presupuesto',
}

// const FILE_UPLOAD_REPORT_CONFIG = {
//   category: 'Carga de datos',
//   action: 'Carga de datos de reporte',
// };

// const FILE_UPLOAD_REPORT_CONFIG_ERROR = {
//   category: 'Error',
//   action: 'Error carga de datos de reporte',
// };

const PUBLISH_DATA_REAL = {
  category: 'Cambio de status',
  action: 'Publicación de datos reales',
}

// const PUBLISH_DATA_REAL_ERROR = {
//   category: 'Error',
//   action: 'Error en publicación de datos reales',
// };

const APPROVE_DATA_BUDGET = {
  category: 'Cambio de status',
  action: 'Aprobación de datos presupuestados',
}

// const APPROVE_DATA_BUDGET_ERROR = {
//   category: 'Error',
//   action: 'Error en aprobación de datos presupuestados',
// };

const REPORT_ENTRY = {
  category: 'Analytics',
  action: 'Ingreso a Reporting',
}

const CUSTOM_REPORT_ENTRY = {
  category: 'Analytics',
  action: 'Ingreso a Reportes Custom',
}

export const ACTIONS = {
  USER_LOGIN,
  USER_LOGIN_ERROR,
  USER_LOGOUT,
  FILE_UPLOAD_REAL,
  FILE_UPLOAD_REAL_ERROR,
  FILE_UPLOAD_BUDGET,
  FILE_UPLOAD_BUDGET_ERROR,
  // FILE_UPLOAD_REPORT_CONFIG,
  // FILE_UPLOAD_REPORT_CONFIG_ERROR,
  PUBLISH_DATA_REAL,
  // PUBLISH_DATA_REAL_ERROR,
  APPROVE_DATA_BUDGET,
  // APPROVE_DATA_BUDGET_ERROR,
  REPORT_ENTRY,
  CUSTOM_REPORT_ENTRY,
}

const GA = {
  init,
  set,
  pageView,
  event,
  startSession,
  endSession,
  ACTIONS,
}

export default GA
