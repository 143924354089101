import { LeftCircleOutlined, RightCircleOutlined } from '@ant-design/icons'

const Previus = ({ className, onClick }) => (
  <LeftCircleOutlined className={`${className} report-kpi-carousel-arrow`} onClick={onClick} />
)

const Next = ({ className, onClick }) => (
  <RightCircleOutlined className={`${className} report-kpi-carousel-arrow`} onClick={onClick} />
)

const CarrouselArrow = {
  Previus,
  Next,
}

export default CarrouselArrow
