import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import { Checkbox, Input, Tooltip } from 'antd'

import './TableFilterDropdown.scss'
import Button from '../Button/Button'

// TODO: Estaria bueno que imprima los checked primeros
const TableFilterDropdown = ({ confirm, filters, visible, onFilter, filteredValues = [] }) => {
  const [searchValue, setSearchValue] = useState('')
  const [selectedValues, setSelectedValues] = useState(filteredValues)

  const { t } = useTranslation()

  useEffect(() => {
    if (!visible) setSearchValue('')
  }, [visible])

  // NOTE: Handling blur
  useEffect(() => {
    if (!visible && !_.isEqual(selectedValues, filteredValues)) {
      onFilter(selectedValues)
    }
  }, [visible, onFilter, selectedValues, filteredValues])

  const handleOnClearAll = (value) => {
    setSearchValue('')
    setSelectedValues([])
    onFilter([])
  }

  const handleOnChange = ({ checked, value }) => {
    if (!checked) {
      const auxSelectedValues = selectedValues.filter((id) => id !== value)
      return setSelectedValues(auxSelectedValues)
    }
    return setSelectedValues((prevSelectedValues) => [...prevSelectedValues, value])
  }

  const handleOnApply = () => {
    onFilter(selectedValues)
    confirm()
  }

  const renderCheckboxLabel = ({ code, name }) => {
    const fullLabel = `${code ? `${code} - ` : ''}${name}`

    if (fullLabel.length > 20) {
      return <Tooltip title={fullLabel}>{fullLabel.slice(0, 20) + '...'}</Tooltip>
    }
    return fullLabel
  }

  return (
    <div className="table-filter-dropdown">
      <div className="table-filter-dropdown-searchbox">
        <Input
          value={searchValue}
          allowClear={true}
          placeholder={t('ACTION_SEARCH')}
          onChange={(event) => setSearchValue(event.target.value)}
        />
      </div>
      <ul className="table-filter-dropdown-list">
        {filters
          .filter((el) => {
            const option = `${el.code ? `${el.code} - ` : ''}${el.name}`
            return option.toLowerCase().includes(searchValue.toLowerCase())
          })
          .map((el) => (
            <li key={el.id} className="table-filter-dropdown-list-item">
              <Checkbox
                checked={selectedValues.includes(el.id)}
                className="table-filter-dropdown-option"
                onChange={(event) =>
                  handleOnChange({
                    checked: event.target.checked,
                    value: el.id,
                  })
                }
              >
                {renderCheckboxLabel({ code: el.code, name: el.name })}
              </Checkbox>
            </li>
          ))}
      </ul>
      <div className="table-filter-dropdown-buttons">
        <Button.Link size="small" onClick={handleOnClearAll} title="ACTION_CLEAR" />
        <Button.Primary
          size="small"
          style={{ width: 100 }}
          onClick={handleOnApply}
          title="ACTION_APPLY"
        />
      </div>
    </div>
  )
}

export default TableFilterDropdown
