import React, { useState } from 'react'
import { Row, Col } from 'antd'
import { ContainerFooter, InitialHeader, InitialSteps, StepCardsList } from './components'

import './InitialScreen.scss'

const InitialScreen = () => {
  const [showIitialSteps, setShowIitialSteps] = useState(false)

  return (
    <div className="initial-screen-container">
      {!showIitialSteps && (
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <InitialHeader />
          </Col>
          <Col span={24} />
          <Col span={24}>
            <StepCardsList />
          </Col>
          <Col span={24} />
          <Col span={24}>
            <ContainerFooter setShowIitialSteps={() => setShowIitialSteps(true)} />
          </Col>
        </Row>
      )}
      {showIitialSteps && <InitialSteps />}
    </div>
  )
}

export default InitialScreen
