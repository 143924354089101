import { message, notification } from 'antd'
import { BarChart, LineChart, PieChart, SankeyChart } from 'modules/core/components'
import { CHARTS, TABLES } from 'modules/core/constants'
import { columnsHelper, generateMessageError } from 'modules/core/utils'
import numeral from 'numeral'
import moment from 'moment'
import i18n from 'i18next'
import _ from 'lodash'

const NUMBER_FORMAT = {
  ABBREVIATIONS: '0,0[.]0 a',
  SEPARATORS: '0,0',
  PERCENTAGE: '0%',
}

export const generateColumns = (columns) =>
  columnsHelper.generatorDefault({
    columns,
    cellFormat: {
      format: TABLES.DEFAULT_SETTINGS.format,
    },
    disabled: true,
  })

export const getDashboardElement = (dataElement, settingsChart) => {
  const renderLabelTrim = ({ label, length = 12, suffix = '...' }) =>
    label.length > length ? `${label.slice(0, length)}${suffix}` : label

  const renderLabelFormat = ({ label, data }) => {
    const value = renderValueFormat(data)

    if (settingsChart.labelFormat === CHARTS.LABEL_FORMAT.NAME) {
      return renderLabelTrim({ label, length: 23, suffix: '.' })
    }
    if (settingsChart.labelFormat === CHARTS.LABEL_FORMAT.VALUE) {
      return value
    }
    if (settingsChart.labelFormat === CHARTS.LABEL_FORMAT.NAME_VALUE) {
      return `${renderLabelTrim({ label, suffix: '.' })}: ${value}`
    }
  }

  const renderValueFormat = (value, format = NUMBER_FORMAT.ABBREVIATIONS) =>
    `${dataElement.values.value !== 'q' ? '$' : ''} ${numeral(value).format(format)}`

  const renderCenteredMetric = ({ dataWithArc, centerX, centerY }) => {
    let total = 0
    dataWithArc.forEach((datum) => (total += datum.value))

    return (
      <text
        x={centerX}
        y={centerY}
        textAnchor="middle"
        dominantBaseline="central"
        style={{ fontSize: settingsChart.totalFontSize }}
      >
        {renderValueFormat(total)}
      </text>
    )
  }

  const setDataLineChart = () => {
    if (dataElement.chart_type === CHARTS.TYPES.LINE) {
      return dataElement.data.asMutable({ deep: true }).map((val) => {
        return {
          ...val,
          data: val.data.map((item) => {
            return {
              ...item,
              x: moment(item.x, 'YYYY-M').format('MMM'),
            }
          }),
        }
      })
    }
  }

  const setDataBarChart = () => {
    if (dataElement.chart_type === CHARTS.TYPES.BAR) {
      return dataElement.data.map((val) => {
        let auxData = {}
        const keys = _.keys(val)
        keys.forEach(
          (el) => (auxData = { ...auxData, [el]: el !== 'date' ? val[el].value : val[el] }),
        )
        return {
          ...auxData,
          date: moment(val.date, 'YYYY-M').format('MMM'),
        }
      })
    }
  }

  return {
    line: (
      <LineChart
        containerHeight={dataElement.title ? 252 : 300}
        data={setDataLineChart()}
        colors={CHARTS.COLORS}
        axisLeft={{
          format: (value) => renderValueFormat(value),
        }}
        margin={{
          top: 20,
          left: 60,
          ...(dataElement.size === 'small' || !settingsChart.showLegends ? { right: 20 } : {}),
        }}
        value={setDataLineChart()}
        enableArea={settingsChart.enableArea}
        enablePoints={settingsChart.enablePoints}
        enablePointLabel={settingsChart.showLabel}
        pointLabel={({ x, y }) => renderLabelFormat({ label: x, data: y })}
        legends={
          settingsChart.showLegends && dataElement.size !== 'small'
            ? [
                {
                  anchor: 'bottom-right',
                  direction: 'column',
                  justify: false,
                  translateX: 110,
                  translateY: dataElement.title ? -20 : 155,
                  itemsSpacing: 0,
                  itemDirection: 'left-to-right',
                  itemWidth: 100,
                  itemHeight: 20,
                  itemOpacity: 0.75,
                  symbolSize: 10,
                  symbolShape: 'circle',
                  symbolBorderColor: 'rgba(0, 0, 0, .5)',
                  toggleSerie: true,
                },
              ]
            : []
        }
      />
    ),
    pie: (
      <PieChart
        containerHeight={dataElement.title ? 252 : 300}
        data={dataElement.data}
        arcLabel={({ label, data }) => renderLabelFormat({ label, data: data.originValue })}
        arcLinkLabel={({ label, data }) => renderLabelFormat({ label, data: data.originValue })}
        valueFormat={(value) =>
          renderValueFormat(dataElement.data.find((el) => el.value === value)?.originValue)
        }
        enableArcLinkLabels={
          settingsChart.showLabel && settingsChart.labelPosition === CHARTS.SETTINGS.POSITION_OUTER
        }
        enableArcLabels={
          settingsChart.showLabel && settingsChart.labelPosition === CHARTS.SETTINGS.POSITION_INNER
        }
        {...(settingsChart.showTotalAmount && {
          innerRadius: 0.6,
          layers: ['arcs', 'arcLabels', 'arcLinkLabels', 'legends', renderCenteredMetric],
        })}
        legends={
          settingsChart.showLegends && dataElement.size !== 'small'
            ? [
                {
                  anchor: 'bottom-right',
                  direction: 'column',
                  justify: false,
                  translateX: 90,
                  translateY: 5,
                  itemsSpacing: 0,
                  itemWidth: 100,
                  itemHeight: 24,
                  itemTextColor: '#999',
                  itemDirection: 'left-to-right',
                  itemOpacity: 1,
                  toggleSerie: true,
                  symbolSize: 10,
                  symbolShape: 'circle',
                },
              ]
            : []
        }
        margin={{
          top: 10,
          bottom: 10,
          right: 10,
          left: 10,
          ...(dataElement.size !== 'small' && settingsChart.showLegends
            ? { right: 280, left: 10 }
            : {}),
        }}
      />
    ),
    bar: (
      <BarChart
        containerHeight={dataElement.title ? 252 : 300}
        keys={_.keys(dataElement.data[0]).filter((el) => el !== 'date')}
        indexBy="date"
        data={setDataBarChart()}
        groupMode={settingsChart.groupMode}
        layout={settingsChart.layout}
        axisLeft={{ format: (value) => renderValueFormat(value) }}
        label={({ id, value }) => renderLabelFormat({ label: id, data: value })}
        valueFormat={(value) => renderValueFormat(value)}
        enableLabel={settingsChart.showLabel}
        legends={
          settingsChart.showLegends && dataElement.size !== 'small'
            ? [
                {
                  anchor: 'bottom-right',
                  direction: 'column',
                  justify: false,
                  itemHeight: 20,
                  itemWidth: 100,
                  toggleSerie: true,
                  translateX: 110,
                  translateY: 20,
                  itemsSpacing: 0,
                  itemDirection: 'left-to-right',
                  symbolShape: 'circle',
                  symbolSize: 10,
                  itemOpacity: 1,
                },
              ]
            : []
        }
        margin={{
          ...(settingsChart.showLegends && dataElement.size !== 'small' && { right: 170 }),
        }}
      />
    ),
    sankey: (
      <SankeyChart
        containerHeight={dataElement.title ? 252 : 300}
        data={dataElement.data}
        sort={settingsChart.sort}
        layout={settingsChart.layout}
        labelOrientation={settingsChart.labelOrientation}
        labelPosition={settingsChart.labelPosition}
        legends={[]}
        valueFormat={(value) => renderValueFormat(value)}
        margin={settingsChart.labelPosition === CHARTS.SETTINGS.POSITION_OUTER && { right: 100 }}
      />
    ),
  }
}

export const onDelete = ({ setIsLoading, deleteDashboardElement, onRefetch, onClose }) => {
  setIsLoading(true)
  deleteDashboardElement()
    .then(() =>
      onRefetch().then(() => {
        message.success(i18n.t('FEEDBACK_DELETE_SUCCESS'))
        onClose()
      }),
    )
    .catch((error) => {
      notification.error({
        message: i18n.t('FEEDBACK_DEFAULT_ERROR'),
        description: generateMessageError(error, 'FEEDBACK_DELETE_FAIL'),
        duration: 0,
      })
      onClose()
    })
}
