import React from 'react'
import { Popconfirm, Space } from 'antd'
import { Button, DropdownMenu, Typography } from 'modules/core/components'
import { useTranslation } from 'react-i18next'
import { DeleteOutlined, EyeOutlined, EditOutlined } from '@ant-design/icons'
import { isUserAllowed } from 'modules/core/utils'
import { ROLES } from 'modules/core/constants'

const { SETTINGS__REPORTS__REPORTSPNL__ABM } = ROLES

const TableActions = ({
  onConfirm,
  visible,
  setDeleteReportId,
  isDeletingReport,
  onClick,
  onClickEdit,
}) => {
  const { t } = useTranslation()
  return (
    <Popconfirm
      placement="bottomRight"
      title={t('CONFIG_REPORT_DELETE_TITLE')}
      okText={t('ACTION_DELETE')}
      okButtonProps={{ loading: isDeletingReport }}
      onConfirm={onConfirm}
      cancelText={t('ACTION_CANCEL')}
      onCancel={() => setDeleteReportId(null)}
      visible={visible}
    >
      <Space size="middle">
        <Button.Icon
          icon={<Typography.Icon icon={EyeOutlined} />}
          title="ACTION_OPEN_DETAIL"
          onClick={onClick}
        />
        <DropdownMenu
          title={t('ACTION_MORE')}
          menu={[
            {
              title: t('ACTION_EDIT'),
              icon: <EditOutlined />,
              onClick: onClickEdit,
              disabled: !isUserAllowed(SETTINGS__REPORTS__REPORTSPNL__ABM),
            },
            {
              title: t('ACTION_DELETE'),
              icon: <DeleteOutlined />,
              onClick: () => setDeleteReportId(),
              disabled: !isUserAllowed(SETTINGS__REPORTS__REPORTSPNL__ABM),
            },
          ]}
        />
      </Space>
    </Popconfirm>
  )
}

export default TableActions
