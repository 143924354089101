import React from 'react'
import { Avatar } from 'antd'
import clsx from 'clsx'

const SenderMessageItem = ({ authUser, item, isPreviousSender = false, isLast }) => {
  const getUserAvatar = () => {
    const name = authUser.firstName
    if (name) {
      return name.charAt(0).toUpperCase()
    }
    if (authUser.email) {
      return authUser.email.charAt(0).toUpperCase()
    }
  }

  return (
    <div
      className={clsx(
        'chat-message-list-item right',
        isPreviousSender ? 'hide-user-info' : 'first-chat-message',
        isLast ? 'last-chat-message' : '',
      )}
    >
      <div className="message-chat-view">
        <div className="message-chat-item">
          <span className="message-time">{item.time}</span>
          <div className="message-chat">
            <p className="message-type-para">{item.message}</p>
          </div>
        </div>
        <div className="message-chat-sender">
          <Avatar className="message-chat-avatar">{getUserAvatar()}</Avatar>
        </div>
      </div>
    </div>
  )
}

export default SenderMessageItem
