import { omitBy } from 'lodash'

export const normalizeFilters = (dimensions, rows) => {
  const filters = {}
  Object.keys(rows).forEach((key) => {
    if (key === 'Fecha') {
      filters['date'] = rows[key]
    } else {
      const dimension = dimensions.find((el) => el.name_destination === key)
      if (dimension) {
        filters[dimension.name] = rows[key]
      }
    }
  })
  return omitBy(filters, (prop) => prop == null)
}
