import React from 'react'
import { Route, Switch } from 'react-router-dom'
import SocietiesList from './SocietiesList/SocietiesList'
import SocietiesDetail from './SocietiesDetail/SocietiesDetail'

const Home = () => (
  <Switch>
    <Route exact path="/empresas" component={SocietiesList} />
    <Route exact path="/empresas/:societyId" component={SocietiesDetail} />
  </Switch>
)

export default Home
