import { message, notification } from 'antd'
import { generateMessageError } from 'modules/core/utils'
import i18n from 'i18next'

export const handleEdit = ({ condition, setILoading, editPeriod, onRefetch, onClose }) => {
  if (condition) {
    setILoading(true)
    editPeriod()
      .then(() =>
        onRefetch().then(() => {
          message.success(i18n.t('PERIOD_EDIT_NAME_SUCCES_FEEDBACK'), 8)
          onClose()
        }),
      )
      .catch((error) => {
        notification.error({
          message: i18n.t('FEEDBACK_DEFAULT_ERROR'),
          description: generateMessageError(error, 'FEEDBACK_SAVE_CHANGES_FAIL'),
          duration: 0,
        })
        onClose()
      })
  } else {
    onClose()
  }
}

export const handleDelete = async ({ deletePeriod_, id, onRefetch, setIsDeleting, onClose }) => {
  try {
    setIsDeleting(true) // Establecer el estado de eliminación a true antes de la acción
    await deletePeriod_(id)
    await onRefetch() // Llamar a onRefetch después de eliminar
    message.success(i18n.t('PERIOD_DELETE_SUCCESS_FEEDBACK'), 8)
    onClose()
  } catch (error) {
    notification.error({
      message: i18n.t('FEEDBACK_DEFAULT_ERROR'),
      description: generateMessageError(error, 'FEEDBACK_DELETE_PERIOD_FAIL'),
      duration: 0,
    })
  } finally {
    setIsDeleting(false) // Restablecer el estado de eliminación después de la acción (éxito o fallo)
  }
}
