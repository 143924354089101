import React, { useState } from 'react'
import { Table } from 'antd'
import { CellStyle } from 'modules/core/components'
import { COLUMNS, ROWS, TABLES } from 'modules/core/constants'
import { calculateScrollX, columnsHelper } from 'modules/core/utils'
import { useParams } from 'react-router-dom'
import columnsComparison from './columnsComparison'

import './ComparisonTable.scss'

const ComparisonTable = ({
  loading,
  globalProjectionComparisonTable,
  fetchComparisonTableOpenRow,
  reportId,
  selectedDates,
  filters,
}) => {
  const [tableSettings /*setTableSettings*/] = useState(TABLES.DEFAULT_SETTINGS)
  const [expandedByPeriodRow, setExpandedByPeriodRow] = useState([])
  const [isExpandingByPeriod, setIsExpandingByPeriod] = useState(false)
  let { globalBudgetId } = useParams()

  const onExpandByPeriod = (expanded, record) => {
    setIsExpandingByPeriod(true)
    if (expanded) {
      fetchComparisonTableOpenRow(reportId, globalBudgetId, record.id, {
        ...selectedDates,
        ...filters,
      }).then(() => {
        setIsExpandingByPeriod(false)
        setExpandedByPeriodRow((prevExpanded) => [...prevExpanded, record.id])
      })
    } else {
      setExpandedByPeriodRow((prevExpanded) => prevExpanded.filter((item) => item !== record.id))
      setIsExpandingByPeriod(false)
    }
  }

  const renderValueColumn = ({ width }) => {
    return {
      width: width,
      align: 'right',
      className: COLUMNS.TYPES.TRANSACTION,
      render: (value, row) => (
        <CellStyle.Number
          value={value}
          format={row.type === ROWS.RATIO ? '%' : tableSettings.format}
        />
      ),
    }
  }

  const renderRowClassName = (record) => {
    if (record.has_rule_applied) {
      return `${record.has_rule_applied ? ROWS.TYPES.APPLIED_RULE_ROW : ''}`
    }

    return ROWS.TYPES[record.type]
  }

  const generatorComparisonColumns = () =>
    columnsHelper.generatorComparisonColumns({
      columns: columnsComparison(),
      // onResize: setDetailTableColumns,
      renderValue: (column) =>
        renderValueColumn({
          width: column.width,
        }),
    })

  return (
    <Table
      rowKey="id"
      size="small"
      className="report-comparison-table"
      loading={loading || isExpandingByPeriod}
      columns={generatorComparisonColumns()}
      dataSource={globalProjectionComparisonTable}
      rowClassName={renderRowClassName}
      expandable={{
        onExpand: onExpandByPeriod,
        expandedRowKeys: expandedByPeriodRow,
      }}
      scroll={{ x: calculateScrollX(generatorComparisonColumns()), y: 450 }}
      pagination={false}
    />
  )
}

export default ComparisonTable
