import { notification } from 'antd'
import { tokenutils } from 'modules/login/actions'
import { generateMessageError } from 'modules/core/utils'
import i18n from 'i18next'

export const handleOnClick = ({
  setIsLoading,
  associatedAccount,
  whoAmI,
  onClose,
  history,
  setAuthenticated,
  onShowCode,
}) => {
  setIsLoading(true)
  associatedAccount()
    .then((response) => {
      tokenutils.saveTokens(response.payload.data)
      if (response.payload.data.requires_totp_verification) {
        onShowCode(true)
      } else {
        whoAmI().then(() => {
          history.push('/')
          onClose()
        })
      }
    })
    .catch((error) => {
      onClose()
      setAuthenticated()
      tokenutils.removeToken()
      history.push('/login')
      notification.error({
        message: i18n.t('FEEDBACK_DEFAULT_ERROR'),
        description: generateMessageError(error, 'FEEDBACK_SELECT_ACCOUNT_ERROR'),
        duration: 0,
      })
    })
}
