import { message, notification } from 'antd'
import { generateMessageError } from 'modules/core/utils'
import i18n from 'i18next'
import _ from 'lodash'

const no_data = (acum, dimension) =>
  acum.length === 0 ||
  (acum.length > 0 &&
    _.isEmpty(acum.find((el) => el.dimension_plika === parseInt(dimension.split('_')[0]))) &&
    _.isEmpty(acum.find((el) => el.dimension_custom === parseInt(dimension.split('_')[0]))))

const getDataBase = (typeDimension, dimension) =>
  typeDimension === 'base' ? parseInt(dimension.split('_')[0]) : null
const getDataCustom = (typeDimension, dimension) =>
  typeDimension === 'custom' ? parseInt(dimension.split('_')[0]) : null
const isSameId = (dimension_plika, dimension_custom, dimension) =>
  dimension_plika === parseInt(dimension.split('_')[0]) ||
  dimension_custom === parseInt(dimension.split('_')[0])

const generateDimensions = (dataDimensions) =>
  dataDimensions.reduce((acum, val) => {
    if (val?.dimension) {
      const typeDimension = val.dimension.split('_')[1]
      if (no_data(acum, val?.dimension)) {
        acum.push({
          dimension_plika: getDataBase(typeDimension, val?.dimension),
          dimension_custom: getDataCustom(typeDimension, val?.dimension),
          columns_plika: {
            [val.type.split('-')[1]]: val.columns,
          },
        })
      } else {
        acum = acum.map((a) => {
          if (isSameId(a?.dimension_plika, a?.dimension_custom, val?.dimension)) {
            return {
              ...a,
              columns_plika: {
                ...a.columns_plika,
                [val.type.split('-')[1]]: val.columns,
              },
            }
          }
          return a
        })
      }
    }
    return acum
  }, [])

const generateDataSaveConfig = (data) => {
  return {
    code_batch: data.code_batch,
    files: data.fact_table.map((dataSel) => {
      const dataTransactions = dataSel.selections.filter(
        (data) => data.type && data.type.split('-')[0] === 'transaction',
      )
      const dataDimensions = dataSel.selections.filter(
        (data) => data.type && data.type.split('-')[0] === 'dimension',
      )
      return {
        concept_id: dataSel.concept,
        file_id: dataSel.id,
        name_file: dataSel.name_file,
        type_upload: dataSel.type_upload,
        transaction: {
          ...dataTransactions.reduce((acum, val) => {
            return {
              ...acum,
              ...{
                [val.type.split('-')[1]]: val.columns,
                ...(val.date_format && { date_format: val.date_format }),
              },
            }
          }, {}),
        },
        dimensions: generateDimensions(dataDimensions),
      }
    }),
  }
}

export const functionMappingDataFile = (data, setShowScreen, setIsLoading, saveConfig) => {
  setIsLoading(true)
  saveConfig(generateDataSaveConfig(data))
    .then(() => {
      message.success(i18n.t('FEEDBACK_CHANGES_SAVED_SUCCESS'), 8)
      setIsLoading(false)
      setShowScreen(true)
    })
    .catch((error) => {
      notification.error({
        message: i18n.t('FEEDBACK_DEFAULT_ERROR'),
        description: generateMessageError(error, 'FEEDBACK_SAVE_CHANGES_FAIL'),
        duration: 0,
      })
      setIsLoading(false)
    })
}

const comprobeTransaction = (dataSelection) =>
  generateDataSaveConfig(dataSelection).files.every(
    (data) =>
      !_.isEmpty(data.transaction) &&
      (_.keys(data.transaction).includes('amount') || _.keys(data.transaction).includes('price')) &&
      _.keys(data.transaction).includes('date'),
  )

const comprobeHasCode = (dataSelection) =>
  generateDataSaveConfig(dataSelection).files.every(
    (data) =>
      data.dimensions.length > 0 &&
      data.dimensions.every(
        (data) => (data.dimension_plika || data.dimension_custom) && !_.isEmpty(data.columns_plika),
      ),
  )

const comprobeCompleteFields = (dataSelection) =>
  generateDataSaveConfig(dataSelection).files.every(
    (data) =>
      !_.isEmpty(data.transaction) &&
      data.dimensions.every(
        (data) => (data.dimension_plika || data.dimension_custom) && !_.isEmpty(data.columns_plika),
      ),
  ) &&
  dataSelection.fact_table.every((data) => data.selections.every((sel) => sel.type && sel.columns))

export const renderTooltip = (dataSelection) => {
  if (!comprobeCompleteFields(dataSelection)) {
    return 'HOME_INITIAL_STEPS_SELECTED_DATA_INFORMATIVE_TOOLTIP_TITLE'
  } else {
    if (!comprobeHasCode(dataSelection))
      return 'HOME_INITIAL_STEPS_SELECT_DATA_FIELD_CODE_INFORMATIVE_TOOLTIP_TITLE'
    if (!comprobeTransaction(dataSelection))
      return 'HOME_INITIAL_STEPS_SELECT_DATA_AMOUNT_DATE_INFORMATIVE_TOOLTIP_TITLE'
  }

  return ''
}

export const comprobeCompleted = (dataSelection) =>
  comprobeCompleteFields(dataSelection) &&
  comprobeTransaction(dataSelection) &&
  comprobeHasCode(dataSelection)
