import React from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import { Space, Select, Typography, Input, DatePicker } from 'antd'
import adjustments from 'modules/adjustments'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { generateSelectOptions } from 'modules/core/utils'

import './AdjustmentCreationInfo.scss'

const { TextArea } = Input
const { Text } = Typography

// TODO: Ver de hacerlo formulario e incluir el comentario.
const AdjustmentCreationInfo = ({
  valueDate,
  dateOptions = [],
  valueType,
  typesOptions = {},
  editAdjustmentValues,
  comment,
  setComment,
}) => {
  const { t } = useTranslation()

  const disabledDate = (date) => {
    const pickerDates = moment(date._d).format('YYYY-MM-01')
    if (dateOptions.length === 0) {
      return true
    }
    return !dateOptions.includes(pickerDates)
  }

  return (
    <Space align="top" size="large">
      <Space direction="vertical">
        <Text>
          <Text type="danger">*</Text> {t('FIELD_YEAR_MONTH')}
        </Text>
        <DatePicker
          className="adjustment-creation-info-select"
          placeholder={t('MONTH_PLACEHOLDER')}
          defaultValue={valueDate ? moment(valueDate) : undefined}
          defaultPickerValue={moment(dateOptions[0])}
          format="MMMM YYYY"
          picker="month"
          disabledDate={disabledDate}
          onChange={(value) =>
            editAdjustmentValues(value ? { date: moment(value._d).format('YYYY-MM-01') } : null)
          }
        />
      </Space>
      <Space direction="vertical">
        <Text>
          <Text type="danger">*</Text> {t('ADJUSTMENT_TYPE_FIELD')}
        </Text>
        <Select
          className="adjustment-creation-info-select"
          value={valueType ? valueType : undefined}
          placeholder={t('FIELD_TYPE')}
          notFoundContent={t('FEEDBACK_DEFAULT_NOT_FOUND')}
          onChange={(value) => editAdjustmentValues(value ? { type: value } : null)}
          options={generateSelectOptions({
            options: _.map(typesOptions, (option, key) => {
              return { name: option, id: key }
            }),
          })}
        />
      </Space>
      <Space direction="vertical">
        <Text>
          <Text type="danger">*</Text> {t('FIELD_OBSERVATIONS')}
        </Text>
        <TextArea
          value={comment}
          onChange={(event) => setComment(event.target.value)}
          autoComplete="off"
          autoSize={{ maxRows: 1 }}
          style={{ width: 400 }}
        />
      </Space>
    </Space>
  )
}

const mapDispatchToProps = {
  editAdjustmentValues: adjustments.actions.editAdjustmentValues,
}

export default connect(null, mapDispatchToProps)(AdjustmentCreationInfo)
