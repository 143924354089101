import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Row, Col } from 'antd'
import { CreateReportModal, EmptyScreen, DownloadModal } from 'modules/core/components'
import { useTranslation } from 'react-i18next'
import { INTEGRATIONS, ROLES } from 'modules/core/constants'
import { CardList, UserActions } from './components'
import { functionSearch } from './utils'
import { useHistory, useParams } from 'react-router-dom'
import { handleDragEnd, handleDragStart } from 'modules/core/utils'
import {
  DndContext,
  useSensor,
  useSensors,
  closestCenter,
  MouseSensor,
  TouchSensor,
} from '@dnd-kit/core'
import emptyStateImage from 'assets/images/compositions/empty-big.svg'
import reports from 'modules/reports'
import planning from 'modules/planning'
import login from 'modules/login'
import configuration from 'modules/configuration'

const { SETTINGS__REPORTS__REPORTSPNL__ABM } = ROLES

const ListPNL = ({
  analysisList: { count, values },
  fetchAnalysisList,
  periodList,
  loggedUser: { folder_id },
  setReportOrder,
  orderReports,
  fetchCommentsList,
  commentsList,
}) => {
  const [isDataLoading, setIsDataLoading] = useState(false)
  const [searchValue, setSearchValue] = useState(null)
  const [showModal, setShowModal] = useState(false)
  const [activeId, setActiveId] = useState(null)
  const [dataComments, setDataComments] = useState([])
  const [isLoadingComments, setIsLoadingComments] = useState(false)
  const { periodId } = useParams()
  const { t } = useTranslation()
  let history = useHistory()
  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor))

  const auxKey = window.location.pathname.replaceAll('/', '-')
  const key = !auxKey.includes('?tab=') ? `${auxKey}?tab=reportesplika` : auxKey

  useEffect(() => {
    setIsLoadingComments(true)
    fetchCommentsList({ module: key }).then(() => setIsLoadingComments(false))
  }, [fetchCommentsList, key])

  useEffect(() => {
    setDataComments(commentsList)
  }, [commentsList])

  useEffect(() => {
    setIsDataLoading(true)
    fetchAnalysisList({ period_id: periodId, search: searchValue }).then((response) =>
      setIsDataLoading(false),
    )
  }, [fetchAnalysisList, periodId, searchValue])

  const dataPeriod = periodList.find((el) => el.id === parseInt(periodId))

  const renderList = () => (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragStart={(event) => handleDragStart(event, setActiveId)}
      onDragEnd={(event) =>
        handleDragEnd({
          event,
          values,
          order: orderReports,
          setOrder: setReportOrder,
          type: 'report_ids',
        })
      }
    >
      <CardList
        loading={isDataLoading}
        dataSource={
          searchValue === null
            ? values.asMutable()
            : functionSearch({ values: values.asMutable(), searchValue })
        }
        activeId={activeId}
        setDataComments={setDataComments}
        dataComments={dataComments}
        onRefetchComments={() => fetchCommentsList({ module: key })}
        loadingComments={isLoadingComments}
      />
    </DndContext>
  )

  const renderTopActions = () => (
    <UserActions
      onRefetch={() => fetchAnalysisList({ period_id: periodId, search: searchValue })}
      setSearchValue={setSearchValue}
      dataPeriod={dataPeriod}
      onClickSpreadsheets={() => setShowModal(true)}
      onClickActivity={() =>
        history.push(`/analisis/${periodId}/actividad?type=report&module=analisis`)
      }
    />
  )

  const renderEmptyScreen = () => (
    <EmptyScreen
      image={emptyStateImage}
      title={t('CONFIG_REPORTS_EMPTY_STATE_TITLE')}
      footer={
        <CreateReportModal
          rolesAvailables={[SETTINGS__REPORTS__REPORTSPNL__ABM]}
          onFetchReportList={() => fetchAnalysisList({ period_id: periodId, search: searchValue })}
          dataPeriod={dataPeriod}
          onClickSpreadsheets={() => setShowModal(true)}
        />
      }
    />
  )

  return (
    <Row gutter={[24, 24]}>
      {count <= 0 && !isDataLoading && renderEmptyScreen()}
      {count > 0 && (
        <>
          <Col span={24}>{renderTopActions()}</Col>
          <Col span={24}>{renderList()}</Col>
          <DownloadModal
            title={INTEGRATIONS.KEYS.SPREADSHEETS_KEY}
            visible={showModal}
            onCancel={() => setShowModal(false)}
            defaultValue={folder_id}
            dataSpreadsheets={{
              type_load: 'temp_reporte',
              folder_id,
            }}
          />
        </>
      )}
    </Row>
  )
}

const mapStateToProps = (state) => ({
  analysisList: reports.selectors.getAnalysisList(state),
  periodList: planning.selectors.getPeriodList(state),
  loggedUser: login.selectors.getWhoAmI(state),
  commentsList: configuration.selectors.getCommentsList(state),
})

const mapDispatchToProps = {
  fetchAnalysisList: reports.actions.fetchAnalysisList,
  setReportOrder: reports.actions.setReportOrder,
  orderReports: reports.actions.orderReports,
  fetchCommentsList: configuration.actions.fetchCommentsList,
}

export default connect(mapStateToProps, mapDispatchToProps)(ListPNL)
