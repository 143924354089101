import React, { useState } from 'react'
import { connect } from 'react-redux'
import { notification } from 'antd'
import { UploadModal } from 'modules/core/components'
import { useTranslation } from 'react-i18next'
import { generateMessageError } from 'modules/core/utils'
import adjustments from 'modules/adjustments'

const AdjustmentUploadModal = ({ visible, onCancel, uploadAdjustmentFile }) => {
  const [isUploadingFile, setIsUploadingFile] = useState(false)
  const [fileList, setFileList] = useState([])
  const { t } = useTranslation()

  const onCloseUploadModal = () => {
    onCancel()
    setFileList([])
  }

  const onConfirmUpload = () => {
    setIsUploadingFile(true)
    uploadAdjustmentFile(fileList)
      .then(() => {
        notification.success({
          message: t('ADJUSTMENT_MOVEMENT_SUCCESS_FEEDBACK_TITLE'),
          description: t('ADJUSTMENT_MOVEMENT_SUCCESS_FEEDBACK_DESCRIPTION'),
          duration: 8,
        })
        setIsUploadingFile(false)
        onCloseUploadModal()
      })
      .catch((error) => {
        notification.error({
          message: t('FEEDBACK_DEFAULT_ERROR'),
          description: generateMessageError(error),
          duration: 0,
        })
        setIsUploadingFile(false)
        onCloseUploadModal()
      })
  }

  const onAddUploadFile = (file) => {
    setFileList([file])
    return false
  }

  return (
    <UploadModal
      title={t('ADJSUTMENT_LOAD_MOVEMENT_ACTION_TITLE')}
      visible={visible}
      onCancel={onCloseUploadModal}
      onConfirm={onConfirmUpload}
      uploadProps={{
        name: 'file',
        accept: '.xls, .xlsx',
        showUploadList: { showPreviewIcon: false },
        beforeUpload: onAddUploadFile,
        onRemove: (file) => setFileList([]),
        fileList: fileList,
      }}
      isUploadingFile={isUploadingFile}
    />
  )
}

const mapDispatchToProps = {
  uploadAdjustmentFile: adjustments.actions.uploadAdjustmentFile,
}

export default connect(null, mapDispatchToProps)(AdjustmentUploadModal)
