import React, { useState } from 'react'
import { Space } from 'antd'
import { DownSquareOutlined, UpSquareOutlined } from '@ant-design/icons'

import './TopColapsableMenu.scss'
import Button from '../Button/Button'

const TopColapsableMenu = ({ children, collText }) => {
  const [collapsed, setCollapsed] = useState(false)
  return (
    <Space className={`top-collapsable-menu ${collapsed ? 'collapsed' : ''} `}>
      <Button.Icon
        size="small"
        icon={collapsed ? <DownSquareOutlined /> : <UpSquareOutlined />}
        onClick={() => setCollapsed(!collapsed)}
        title={collapsed ? 'SHOW_FILTERS_MENU' : 'HIDE_FILTERS_MENU'}
      />
      {!collapsed && children}
    </Space>
  )
}

export default TopColapsableMenu
