import { Select } from 'antd'
import { generateSelectOptions, orderBySelection } from 'modules/core/utils'

const SelectReportLine = ({ rows, lineId, onChange }) => {
  const reportLineId = rows[rows.length - 1].id
  const reportLinesOptions = rows.map((reportLine) => {
    return { id: reportLine.id, name: reportLine.name }
  })
  return (
    <Select
      bordered={false}
      style={{ width: 150 }}
      showSearch
      optionFilterProp="children"
      filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      defaultValue={reportLineId}
      onChange={onChange}
      options={generateSelectOptions({
        options: orderBySelection(!lineId ? [reportLineId] : [lineId], reportLinesOptions),
      })}
    />
  )
}

export default SelectReportLine
