import React from 'react'
import { Checkbox } from 'antd'
import { connect } from 'react-redux'
import { ROLES } from 'modules/core/constants'
import { isUserAllowed } from 'modules/core/utils'
import configuration from 'modules/configuration'

const { SETTINGS__SECURITY__ROLES__ABM } = ROLES

const OpCheck = ({ panelArrayKeys, editRoleOperations, roleOperationKeys }) => {
  const canUserEdit = isUserAllowed([SETTINGS__SECURITY__ROLES__ABM])
  const allChecked = panelArrayKeys.every((r) => roleOperationKeys.includes(r))
  const partialChecked =
    !allChecked && [...panelArrayKeys].some((r) => roleOperationKeys.includes(r))

  return (
    <Checkbox
      onClick={(event) => event.stopPropagation()}
      checked={allChecked}
      indeterminate={partialChecked}
      disabled={!canUserEdit}
      onChange={(event) =>
        editRoleOperations({
          checked: event.target.checked,
          operationsKeys: panelArrayKeys,
        })
      }
    />
  )
}

const mapStateToProps = (state) => ({
  roleOperationKeys: configuration.selectors.getRoleOperationKeys(state),
})

const mapDispatchToProps = {
  editRoleOperations: configuration.actions.editRoleOperations,
}

export default connect(mapStateToProps, mapDispatchToProps)(OpCheck)
