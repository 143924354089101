import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { CardsCarrousel } from './components'
import { LoadingKpiCard } from 'modules/reports/components/ReportKPICarousel/components'
import home from 'modules/home'

import './BudgetTypesCarrousel.scss'

const BudgetTypesCarrousel = ({
  period_id,
  projectionId,
  fetchBudgetTypesCards,
  budgetTypeCards,
}) => {
  const [budgetTypesLoading, setBudgetTypeLoading] = useState(false)

  useEffect(() => {
    if (period_id && projectionId) {
      setBudgetTypeLoading(true)
      fetchBudgetTypesCards({ period_id, projection: projectionId }).then(() =>
        setBudgetTypeLoading(false),
      )
    }
    return () => setBudgetTypeLoading(false)
  }, [fetchBudgetTypesCards, period_id, projectionId])

  if (budgetTypesLoading) {
    return <LoadingKpiCard />
  }

  if (!budgetTypesLoading) {
    return <CardsCarrousel dataKPI={budgetTypeCards} />
  }
}

const mapStateToProps = (state) => ({
  budgetTypeCards: home.selectors.getBudgetTypeCards(state),
})

const mapDispatchToProps = {
  fetchBudgetTypesCards: home.actions.fetchBudgetTypesCards,
}

export default connect(mapStateToProps, mapDispatchToProps)(BudgetTypesCarrousel)
