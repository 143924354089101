const { Col, Input } = require('antd')
const { InputCell } = require('modules/core/components')

const EditElements = ({ onCellEdit, item }) => {
  return (
    <>
      <Col span={4} className="currency-card-body-col">
        <InputCell
          initialValue={item.symbol}
          onCellUpdate={onCellEdit}
          name="symbol"
          style={{ width: 150 }}
        />
      </Col>
      <Col span={4} className="currency-card-body-col">
        <InputCell initialValue={item.code} onCellUpdate={onCellEdit} name="code" />
      </Col>
      <Col span={6} className="currency-card-body-col">
        <InputCell initialValue={item.name} onCellUpdate={onCellEdit} name="name" />
      </Col>
      <Col span={8} className="currency-card-body-col">
        <Input.TextArea
          defaultValue={item.description}
          autoSize
          onChange={(text) => onCellEdit('description', text.target.value)}
        />
      </Col>
    </>
  )
}

export default EditElements
