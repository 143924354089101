import { NAME } from './constants'

// ++ DIMENSIONS ++
export const getDimensionPlikaList = (state) => state[NAME].dimensionPlikaList
export const getColumnsRowsSelections = (state) => state[NAME].columnsRowsSelections
export const getDataColumnsRows = (state) => state[NAME].dataColumnsRows
export const getDimensionsList = (state) => state[NAME].dimensionsList
export const getAllDimensionsList = (state) => state[NAME].allDimensionsList
export const getAllDimensionsValues = (state) => state[NAME].allDimensionsValues
export const getDimensionDetail = (state) => state[NAME].dimensionDetail
export const getDimensionValues = (state) => state[NAME].dimensionValues
export const getValuesDimension = (state) => state[NAME].valuesDimension
export const getDisplaySettings = (state) => state[NAME].displaySettings
export const getDimensionValuesParents = (state) => state[NAME].dimensionValuesParents

//BudgetType
export const getBudgetTypeList = (state) => state[NAME].budgetTypeList

// ++ USERS ++
export const getUsersList = (state) => state[NAME].usersList
export const getUserDetail = (state) => state[NAME].userDetail
export const getUserRoles = (state) => state[NAME].userRoles

// ++ MODIFICADORES ++
export const getModifiersList = (state) => state[NAME].modifiersList

// ++ VARIABLES ++
export const getVariablesList = (state) => state[NAME].variablesList
export const getVariablesKpis = (state) => state[NAME].variablesKpis
export const getBehaviourList = (state) => state[NAME].behaviourList
export const getVariableDetail = (state) => state[NAME].variableDetail

// ++ REGLAS ++
export const getRulesList = (state) => state[NAME].rulesList
export const getDataRuleSteps = (state) => state[NAME].dataRuleSteps
export const getDimensionsSelection = (state) => state[NAME].dimensionsSelection
export const getDataFormula = (state) => state[NAME].dataFormula
export const getFormulaDetail = (state) => state[NAME].formulaDetail
export const getRowsDimensions = (state) => state[NAME].rowsDimensions

//FACTORES
export const getFactoresList = (state) => state[NAME].factoresList
export const getFactoresBehaviours = (state) => state[NAME].factoresBehaviours

//otros reportes
export const getOtherReportsList = (state) => state[NAME].otherReportsList
export const getOtherReportsStructure = (state) => state[NAME].otherReportsStructure

// ++ ROLES ++
export const getRolesList = (state) => state[NAME].rolesList
export const getRoleOperationKeys = (state) => state[NAME].roleOperationsKeys
export const getRoleDimensions = (state) => state[NAME].roleDimensions

/*-----MULTIMONEDA-------*/
export const getCurrencyList = (state) => state[NAME].currencyList
export const getExchangeRateList = (state) => state[NAME].exchangeRateList

/*-----ONBOARDING-------*/
export const getOnboardingSteps = (state) => state[NAME].onboardingSteps

export const getNotificationsList = (state) => state[NAME].notificationsList
export const getShowAllComments = (state) => state[NAME].showAllComments

export const getElementRefId = (state) => state[NAME].elementRefId

export const getHasComment = (state) => state[NAME].hasComment

export const getCommentsList = (state) => state[NAME].commentsList
