import { message, notification } from 'antd'
import { generateMessageError } from 'modules/core/utils'
import i18n from 'i18next'

export const onDownload = ({
  form,
  setIsLoading,
  downloadSpreadsheets,
  extraAction,
  onRefetch,
  onClose,
}) => {
  form.validateFields().then((values) => {
    setIsLoading(true)
    downloadSpreadsheets(values)
      .then(() => {
        if (extraAction) {
          extraAction().then(() => {
            message.success(i18n.t('FEEDBACK_DOWNLOAD_FILE_SUCCESS'), 8)
            onClose()
          })
        } else {
          onRefetch().then(() => {
            message.success(i18n.t('FEEDBACK_DOWNLOAD_FILE_SUCCESS'), 8)
            onClose()
          })
        }
      })
      .catch((error) => {
        notification.error({
          message: i18n.t('FEEDBACK_DEFAULT_ERROR'),
          description: generateMessageError(error, 'FEEDBACK_DOWNLOAD_FILE_ERROR'),
          duration: 0,
        })
        onClose()
      })
  })
}
