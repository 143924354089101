import React, { useEffect, useState } from 'react'
import { Alert, Col, Form, Input, Modal, Radio, Row, Select, Table, Tooltip, Upload } from 'antd'
import { Button } from 'modules/core/components'
import { useTranslation } from 'react-i18next'
import { UploadOutlined } from '@ant-design/icons'
import { ADJUSTMENTS } from 'modules/core/constants'
import { connect } from 'react-redux'
import { generateSelectOptions } from 'modules/core/utils'
import { handleOnClick, isAdjustment, onCreate, renderTitle } from './utils'
import { useParams } from 'react-router-dom'
import { columns } from './columns'
import adjustments from 'modules/adjustments'
import configuration from 'modules/configuration'
import _ from 'lodash'

const AdjustmentCreationModal = ({
  visible,
  onCancel,
  onRefetch,
  createAdjustmentByFile,
  fetchBudgetTypeList,
  budgetTypeList,
  downloadCreateAdjustmentTemplate,
  downloadCreateDistributionTemplate,
  setRowsDimensions,
  fetchAllDimensions,
  rowsDimensions,
  allDimensionsList,
  fetchAllDimensionsValues,
  allDimensionsValues,
  setDimensionsSelection,
  dimensionsSelection,
}) => {
  const [fileList, setFileList] = useState([])
  const [dataType, setDataType] = useState(ADJUSTMENTS.KEY.ADJUSTMENT_KEY)
  const [isCreating, setIsCreating] = useState(false)
  const [dropdownOpen, setDropdownOpen] = useState({})
  const [isLoadingOptions, setIsLoadingOptions] = useState(false)
  const [form] = Form.useForm()
  let { periodId } = useParams()
  const { t } = useTranslation()

  useEffect(() => {
    if (visible) {
      fetchBudgetTypeList()
    }
  }, [fetchBudgetTypeList, visible])

  useEffect(() => {
    if (visible) {
      fetchAllDimensions().then(() => setRowsDimensions())
    }
    // eslint-disable-next-line
  }, [fetchAllDimensions, visible])

  useEffect(() => {
    if (dropdownOpen.isDropdownOpen) {
      setIsLoadingOptions(true)
      fetchAllDimensionsValues({ id_dimension: dropdownOpen.dimensionId }).then(() =>
        setIsLoadingOptions(false),
      )
    }
  }, [fetchAllDimensionsValues, dropdownOpen.isDropdownOpen, dropdownOpen.dimensionId])

  const onClose = () => {
    form.resetFields()
    setFileList([])
    setDataType(ADJUSTMENTS.KEY.ADJUSTMENT_KEY)
    setIsCreating(false)
    onCancel()
  }

  const onAddUploadFile = (file) => {
    setFileList([file])
    return false
  }

  const uploadProps = {
    name: 'file',
    accept: '.xls, .xlsx',
    showUploadList: { showPreviewIcon: false },
    beforeUpload: onAddUploadFile,
    onRemove: (file) => setFileList([]),
    fileList: fileList,
  }

  return (
    <Modal
      title={t('ADJUSTMENT_CREATE_FORM_TITLE')}
      visible={visible}
      bodyStyle={{ overflowY: 'auto', maxHeight: 400 }}
      centered
      destroyOnClose
      forceRender
      onCancel={onClose}
      okText={t('ACTION_CREATE')}
      cancelText={t('ACTION_CANCEL')}
      onOk={() =>
        onCreate({
          form,
          setIsLoading: setIsCreating,
          createAdjustmentByFile: (values) =>
            createAdjustmentByFile({
              period_id: periodId,
              dimensions: dimensionsSelection.default[0],
              ...values,
            }),
          onRefetch,
          onClose,
        })
      }
      okButtonProps={{ loading: isCreating }}
      cancelButtonProps={{ disabled: isCreating }}
    >
      <Form form={form} layout="vertical">
        {() => (
          <Row gutter={[16, 6]}>
            <Alert
              showIcon
              type="info"
              closable
              description={t('ADJUSTEMNT_UPLOAD_DATA_INFORMATIVE_ALERT_DESCRIPTION')}
            />
            <Col span={24}>
              <Form.Item
                label={t('ADJUSTMENT_CREATION_TYPE')}
                name="type_ajustment"
                rules={[{ required: true, message: t('REQUIRED_FIELD') }]}
                initialValue={dataType}
              >
                <Radio.Group>
                  {ADJUSTMENTS.DATA_TYPE.map((option) => (
                    <Radio
                      value={option.id}
                      key={option.id}
                      onChange={(val) => setDataType(val.target.value)}
                    >
                      {t(option.name)}
                    </Radio>
                  ))}
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={t('LABEL_BUDGET_TYPE')}
                name="concept_id"
                rules={[{ required: true, message: t('REQUIRED_FIELD') }]}
              >
                <Select
                  placeholder={t('ACTION_SELECT')}
                  options={generateSelectOptions({ options: budgetTypeList?.values })}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={t('ADJUSTMENT_TYPE_FIELD')}
                name="type_ajustment_distribution"
                rules={[{ required: true, message: t('REQUIRED_FIELD') }]}
              >
                <Select
                  placeholder={t('ACTION_SELECT')}
                  options={generateSelectOptions({
                    options: isAdjustment(dataType)
                      ? ADJUSTMENTS.OPTIONS_ADJUSTMENT_TYPE.map((op) => {
                          return { ...op, name: t(op.name) }
                        })
                      : ADJUSTMENTS.OPTIONS_DISTRIBUTION_TYPE.map((op) => {
                          return { ...op, name: t(op.name) }
                        }),
                  })}
                />
              </Form.Item>
            </Col>
            {isAdjustment(dataType) && (
              <Col span={24}>
                <Form.Item label={t('CONTROL_ORIGINAL_OBS_FILED')} name="comment">
                  <Input.TextArea
                    placeholder={t('SOCIETY_ENTER_DESCRIPTION_PLACEHOLDER')}
                    maxLength={100}
                  />
                </Form.Item>
              </Col>
            )}
            {!isAdjustment(dataType) && (
              <Col span={24}>
                <Form.Item
                  label={t('CONFIG_RULES_FORMULA_VALUES_STEP_FORMULA_LABEL_ORIGIN')}
                  name="origin"
                  rules={[
                    {
                      required: _.isEmpty(dimensionsSelection.default[0]) ? true : false,
                      message: t('REQUIRED_FIELD'),
                    },
                  ]}
                >
                  <Table
                    rowKey="id"
                    size="small"
                    showHeader={false}
                    dataSource={rowsDimensions.default}
                    columns={columns({
                      allDimensionsList,
                      isLoadingOptions,
                      allDimensionsValues,
                      setDropdownOpen,
                      setDimensionsSelection,
                    })}
                    scroll={{ x: 'max-content' }}
                    pagination={false}
                  />
                </Form.Item>
              </Col>
            )}
            <Col span={24}>
              <Form.Item
                label={t('ACTION_UPLOAD_FILE')}
                name="file_upload"
                rules={[{ required: true, message: t('REQUIRED_FIELD') }]}
              >
                <Upload {...uploadProps}>
                  {uploadProps.fileList.length <= 0 && (
                    <Button.DefaultWhitILeftIcon
                      icon={<UploadOutlined />}
                      title="UPLOAD_FILE_PLACEHOLDER"
                    />
                  )}
                </Upload>
              </Form.Item>
            </Col>
            <Col span={24}>
              <div className="upload-moda-help">
                {t('INFO_UPLOAD_FILE_MODAL_DESCRIPTION')}{' '}
                <Tooltip
                  title={renderTitle({
                    dataType,
                    form,
                    dimensionsSelection: dimensionsSelection.default[0],
                  })}
                >
                  <span
                    onKeyUp={() => {}}
                    onClick={() =>
                      handleOnClick({
                        dataType,
                        form,
                        downloadCreateAdjustmentTemplate,
                        downloadCreateDistributionTemplate,
                        dimensionsSelection: dimensionsSelection.default[0],
                        periodId,
                      })
                    }
                  >
                    {t('INFO_UPLOAD_FILE_DOWNLOAD_ACTION')}
                  </span>
                </Tooltip>
              </div>
            </Col>
          </Row>
        )}
      </Form>
    </Modal>
  )
}

const mapStateToProps = (state) => ({
  budgetTypeList: configuration.selectors.getBudgetTypeList(state),
  allDimensionsList: configuration.selectors.getAllDimensionsList(state),
  rowsDimensions: configuration.selectors.getRowsDimensions(state),
  allDimensionsValues: configuration.selectors.getAllDimensionsValues(state),
  dimensionsSelection: configuration.selectors.getDimensionsSelection(state),
})

const mapDispatchToProps = {
  createAdjustmentByFile: adjustments.actions.createAdjustmentByFile,
  fetchBudgetTypeList: configuration.actions.fetchBudgetTypeList,
  downloadCreateAdjustmentTemplate: adjustments.actions.downloadCreateAdjustmentTemplate,
  downloadCreateDistributionTemplate: adjustments.actions.downloadCreateDistributionTemplate,
  setRowsDimensions: configuration.actions.setRowsDimensions,
  fetchAllDimensions: configuration.actions.fetchAllDimensions,
  fetchAllDimensionsValues: configuration.actions.fetchAllDimensionsValues,
  setDimensionsSelection: configuration.actions.setDimensionsSelection,
}

export default connect(mapStateToProps, mapDispatchToProps)(AdjustmentCreationModal)
