import React, { useEffect, useState } from 'react'
import { Card, Select, Space } from 'antd'
import { generateSelectOptions } from 'modules/core/utils'
import { SortableTag } from './components'
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { connect } from 'react-redux'
import { setSelections } from './utils'
import { useTranslation } from 'react-i18next'
import configuration from 'modules/configuration'

import './ContainerItems.scss'

const ContainerItems = ({
  dataColumnsRows,
  infoContainer,
  addNewColumnsRows,
  deleteColumnsRows,
  allDimensionsList,
  fetchAllDimensions,
}) => {
  const [isDimensionLoading, setIsDimensionLoading] = useState(false)
  const { t } = useTranslation()

  useEffect(() => {
    setIsDimensionLoading(true)
    fetchAllDimensions().then(() => setIsDimensionLoading(false))
  }, [fetchAllDimensions])

  return (
    <Card
      key={infoContainer.id}
      title={infoContainer.title}
      bodyStyle={{ overflowY: 'auto', minHeight: 150, maxHeight: 150 }}
      size="small"
    >
      <Space direction="vertical" className="space-items">
        <SortableContext
          items={infoContainer.options.asMutable()}
          strategy={verticalListSortingStrategy}
        >
          {infoContainer.options.map((op) => (
            <SortableTag
              key={op.id}
              op={op}
              onClose={(id) => deleteColumnsRows({ id: infoContainer.id, tagId: id })}
              showCloseIcon={infoContainer.options.length > 1}
            />
          ))}
        </SortableContext>
        <Select
          placeholder={t('ACTION_ADD_NEW')}
          size="small"
          defaultValue={null}
          loading={isDimensionLoading}
          value={null}
          showSearch
          showArrow={false}
          className="space-items"
          optionFilterProp="children"
          filterOption={(input, option) => {
            return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }}
          onChange={(val, row) => addNewColumnsRows({ id: infoContainer.id, row })}
          options={generateSelectOptions({
            options: [
              ...allDimensionsList.map((data) => {
                return { id: data.id, name: data.name_destination }
              }),
              ...infoContainer.extraOptios,
            ],
            selection: setSelections(dataColumnsRows),
          })}
        />
      </Space>
    </Card>
  )
}

const mapStateToProps = (state) => ({
  allDimensionsList: configuration.selectors.getAllDimensionsList(state),
})

const mapDispatchToProps = {
  addNewColumnsRows: configuration.actions.addNewColumnsRows,
  deleteColumnsRows: configuration.actions.deleteColumnsRows,
  fetchAllDimensions: configuration.actions.fetchAllDimensions,
}

export default connect(mapStateToProps, mapDispatchToProps)(ContainerItems)
