import React, { useState } from 'react'
import { Table } from 'antd'
import { DeleteAndUpdate, InputCell } from 'modules/core/components'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { TableActions } from './components'
import { onDelete, onSave } from './utils'
import question from 'modules/question'

const QuestionTable = ({
  dataSource,
  count,
  tableLoading,
  onChange,
  pagination,
  onRefetchQuestionList,
  editQuestion,
  deleteQuestion,
}) => {
  const { t } = useTranslation()
  const [deleteQuestionId, setDeleteQuestionId] = useState(null)
  const [dataCellEdit, setDataCellEdit] = useState({})
  const [isEditing, setIsEditing] = useState(false)
  const [editRow, setEditRow] = useState(null)

  const onCellEdit = (name, value) => setDataCellEdit({ ...dataCellEdit, [`${name}`]: value })

  const onCancel = () => setEditRow(null)

  const onConfirm = () => {
    setEditRow(null)
    setDataCellEdit({})
    setIsEditing(false)
  }

  const onClose = () => {
    setIsEditing(false)
    setDeleteQuestionId(null)
  }

  const columns = [
    {
      title: t('FIELD_QUESTION'),
      dataIndex: 'name',
      render: (name, row) => {
        if (editRow && editRow === row.id) {
          return (
            <InputCell
              initialValue={name}
              onCellUpdate={onCellEdit}
              name="name"
              style={{ width: '100%' }}
            />
          )
        }
        return name
      },
    },
    {
      title: t('FIELD_ACTIONS'),
      dataIndex: 'id',
      align: 'right',
      render: (id) => {
        return id !== editRow ? (
          <TableActions
            onConfirmDelete={() =>
              onDelete({
                setIsLoading: setIsEditing,
                deleteQuestion: () => deleteQuestion(parseInt(id), { id: parseInt(id) }),
                onRefetch: onRefetchQuestionList,
                onClose,
              })
            }
            onCancelDelete={() => setDeleteQuestionId(null)}
            visible={deleteQuestionId === id}
            onClickEdit={() => setEditRow(id)}
            onClickDelete={() => setDeleteQuestionId(id)}
          />
        ) : (
          <DeleteAndUpdate
            onCancel={onCancel}
            onConfirm={onConfirm}
            onSave={() =>
              onSave({
                setIsLoading: setIsEditing,
                editQuestion: () =>
                  editQuestion(parseInt(id), { id: parseInt(id), ...dataCellEdit }),
                onRefetch: onRefetchQuestionList,
                onClose: onConfirm,
              })
            }
            dataCellEdit={dataCellEdit}
            loading={isEditing}
          />
        )
      },
    },
  ]

  return (
    <Table
      bordered
      rowKey="id"
      size="small"
      loading={tableLoading}
      dataSource={dataSource}
      columns={columns}
      pagination={{
        pageSize: pagination.page_size,
        size: 'small',
        total: count,
        current: pagination.page,
        pageSizeOptions: ['10', '20', '30'],
        showSizeChanger: true,
      }}
      onChange={onChange}
    />
  )
}

const mapDispatchToProps = {
  editQuestion: question.actions.editQuestion,
  deleteQuestion: question.actions.deleteQuestion,
}

export default connect(null, mapDispatchToProps)(QuestionTable)
