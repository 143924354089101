import { useState } from 'react'
import { Input, Modal } from 'antd'
import { Typography } from 'modules/core/components'
import { useTranslation } from 'react-i18next'
import { handleVerify } from 'modules/configuration/containers/Security/UserDetail/components/ConfigUserDrawer/components/QrCodeModal/utils'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { tokenutils } from 'modules/login/actions'
import login from 'modules/login'

const CodeModal = ({ visible, onCancel, verifyCode, whoAmI }) => {
  const [qrCode, setQrCode] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const { t } = useTranslation()
  let history = useHistory()

  const onClose = () => {
    setIsLoading(false)
    setQrCode('')
    onCancel()
    tokenutils.removeToken()
    history.push('/login')
  }

  const onConfirm = (data) => {
    setIsLoading(false)
    setQrCode('')
    onCancel()
    history.push('/')
  }

  return (
    <Modal
      title={t('FIELD_CODE')}
      visible={visible}
      centered={true}
      okText={t('ACTION_ACEPT')}
      onOk={() =>
        handleVerify({
          setIsLoading,
          verifyCode: () => verifyCode({ token: qrCode }),
          onRefetch: () => whoAmI(),
          onClose,
          saveTokens: (data) => tokenutils.saveTokens(data),
          onConfirm,
        })
      }
      cancelText={t('ACTION_CANCEL')}
      onCancel={onClose}
      okButtonProps={{ loading: isLoading, disabled: !qrCode }}
      destroyOnClose={true}
      forceRender
    >
      <Typography.Body level={3}>{t('LOGIN_CONFIG_ENTER_CODE_TYPOGRAPHY_BODY')}</Typography.Body>
      <Input
        placeholder={t('ACTION_ENTER')}
        style={{ width: '100%' }}
        onChange={(val) => setQrCode(val.target.value)}
      />
    </Modal>
  )
}

const mapDispatchToProps = {
  verifyCode: login.actions.verifyCode,
  whoAmI: login.actions.whoAmI,
}

export default connect(null, mapDispatchToProps)(CodeModal)
