import React from 'react'
import { Modal } from 'antd'
import { useTranslation } from 'react-i18next'

//ver porque no me cambia de key cuando vuelvo un paso. Ver si el curren hay que setearlo en el reducer o ver como manejar este caso.
const PreviousModal = ({ visible, onClose, onConfirm }) => {
  const { t } = useTranslation()
  return (
    <Modal
      title={t('HOME_INITIAL_STEPS_PREVIOUS_STEP_MODAL_TITLE')}
      visible={visible}
      onCancel={onClose}
      centered
      okText={t('ACTION_RETURN_ANYWAY')}
      cancelText={t('ACTION_CANCEL')}
      onOk={onConfirm}
    >
      <>
        {t('HOME_INITIAL_STEPS_PREVIOUS_STEP_MODAL_BODY')}
        <br />
        {t('PLANNING_VARIABLES_RETURN_CONFIRM_TOOLTIP')}
      </>
    </Modal>
  )
}

export default PreviousModal
