import { Breadcrumb, Menu, Select, Space } from 'antd'
import { Typography } from 'modules/core/components'
import { generateSelectOptions } from 'modules/core/utils'
import { CalendarOutlined } from '@ant-design/icons'
import { PROJECTION } from 'modules/core/constants'
import { useTranslation } from 'react-i18next'

const PeriodProjectionHeader = ({
  periodList,
  currentPeriod,
  setCurrentPeriod,
  projectionId,
  setProjectionId,
}) => {
  const { t } = useTranslation()
  const periodMenu = (
    <Menu
      style={{
        maxHeight: 500,
        overflowY: 'scroll',
        position: 'absolute',
        right: 'auto',
      }}
    >
      {periodList
        .filter(
          (period) => period.id !== parseInt(currentPeriod ? currentPeriod?.id : periodList[0]?.id),
        )
        .map((period) => (
          <Menu.Item key={period.id} onClick={() => setCurrentPeriod(period)}>
            {period.name}
          </Menu.Item>
        ))}
    </Menu>
  )
  return (
    <Space direction="horizontal" size="large">
      <Space>
        <Typography.Icon level={2} icon={CalendarOutlined} />
        <Breadcrumb>
          <Breadcrumb.Item overlay={periodList.length > 1 && periodMenu}>
            {currentPeriod ? currentPeriod?.name : periodList[0]?.name}
          </Breadcrumb.Item>
        </Breadcrumb>
      </Space>
      <Select
        placeholder={t('ACTION_SELECT')}
        value={projectionId.base}
        onChange={(val) => setProjectionId({ ...projectionId, base: val })}
        style={{ width: 150 }}
        options={generateSelectOptions({
          options: PROJECTION.OPTIONS_ALL_PROJECTION_TYPE.map((op) => {
            return { ...op, name: t(op.name) }
          }),
        })}
      />
    </Space>
  )
}

export default PeriodProjectionHeader
