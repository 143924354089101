import React, { useState } from 'react'
import { Col, Row } from 'antd'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { generateDefaultSelects, generateSelectionsSelects } from './utils'
import { AddAndDeleteFormItem } from 'modules/core/components'
import ContainerSelect from '../ContainerSelect/ContainerSelect'
import FooterAddNewSelect from '../FooterAddNewSelect/FooterAddNewSelect'
import home from 'modules/home'
import configuration from 'modules/configuration'

const ContainerSelections = ({
  dataSel,
  dataFact,
  indexFact,
  setDataSelect,
  dataSelection,
  dataFiles,
  indexSel,
  addNewSelection,
  deleteSelection,
  dimensionPlikaList,
  loading,
  createDimension,
  fetchAllDimensions,
  allDimensionsList,
}) => {
  const [isCreating, setIsCreating] = useState(false)
  const { t } = useTranslation()

  const renderDisabled = (dataSel, type_upload) =>
    !dataSel.type ||
    !dataSel.columns ||
    (dataSel.type && dataSel.type.split('-')[0] === 'dimension' && !dataSel.dimension) ||
    dataFiles.fact_table[indexFact].columns.length ===
      dataSelection.fact_table[indexFact].selections.length

  const renderDropdowContent = (menu, infoSelect) => {
    if (infoSelect.id === 'dimensionPlika') {
      return (
        <FooterAddNewSelect
          menu={menu}
          onRefetch={() => fetchAllDimensions()}
          setIsCreating={setIsCreating}
          create={(data) => createDimension({ name: data.name, dimension_plika: null })}
          isCreating={isCreating}
        />
      )
    }
    return menu
  }

  return (
    <Row gutter={[16, 16]} key={dataSel.id} style={{ width: '100%', paddingLeft: 8 }}>
      {generateDefaultSelects(
        dataFact,
        dataSel,
        dataFiles,
        indexFact,
        setDataSelect,
        dataSelection,
      ).map((dataSelect) => (
        <Col key={dataSelect.id} span={6}>
          <ContainerSelect
            onChange={dataSelect.onChange}
            options={dataSelect.options}
            id={dataSelect.id}
            title={t(dataSelect.name)}
            value={dataSelect.value}
          />
        </Col>
      ))}
      {dataSel.type &&
        generateSelectionsSelects(
          dataFact,
          dataSel,
          dataFiles,
          setDataSelect,
          loading,
          dimensionPlikaList,
          allDimensionsList,
        )[dataSel.type.split('-')[0]].map((infoSelect) => (
          <Col key={infoSelect.id} span={6}>
            <ContainerSelect
              onChange={infoSelect.onChange}
              options={infoSelect.options}
              loading={infoSelect.loading || (infoSelect.id === 'dimensionPlika' && isCreating)}
              disabled={infoSelect.disabled}
              id={infoSelect.id}
              title={t(infoSelect.name)}
              value={infoSelect.value}
              dropdownRender={(menu) => renderDropdowContent(menu, infoSelect)}
            />
          </Col>
        ))}
      <Col span={24}>
        <AddAndDeleteFormItem
          fieldsLength={dataSelection.fact_table[indexFact].selections.length}
          index={indexSel}
          addData={{
            disabled: renderDisabled(dataSel, dataSelection.fact_table[indexFact].type_upload),
            onClick: () => {
              addNewSelection({ dataFactId: dataFact.id })
            },
            buttonName: t('HOME_INITIAL_STEPS_SELECT_DATA_ADD_NEW_SELECTION_ACTION'),
          }}
          deleteData={{
            onClick: () => {
              deleteSelection({ dataFactId: dataFact.id, indexSelection: indexSel })
            },
            buttonName: t('HOME_INITIAL_STEPS_SELECT_DATA_DELETE_SELECTION_ACTION'),
          }}
        />
      </Col>
    </Row>
  )
}

const mapStateToProps = (state) => ({
  dataFiles: home.selectors.getDataFiles(state),
  dataSelection: home.selectors.getDataSelection(state),
  dimensionPlikaList: configuration.selectors.getDimensionPlikaList(state),
  allDimensionsList: configuration.selectors.getAllDimensionsList(state),
})

const mapDispatchToProps = {
  setDataSelect: home.actions.setDataSelect,
  addNewSelection: home.actions.addNewSelection,
  deleteSelection: home.actions.deleteSelection,
  createDimension: configuration.actions.createDimension,
  fetchAllDimensions: configuration.actions.fetchAllDimensions,
}

export default connect(mapStateToProps, mapDispatchToProps)(ContainerSelections)
