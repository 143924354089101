import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Tabs } from 'antd'
import { PeriodNavigator } from 'modules/core/components'
import { SiderLayout } from 'modules/core/layouts'
import { useTranslation } from 'react-i18next'
import { ReportDashboards, ReportList } from './components'
import { sendUserDataToGTM } from 'modules/core/utils'
import login from 'modules/login'

import './ReportHome.scss'
import { useQuery } from 'modules/core/customHooks'

const ReportHome = ({ loggedUser }) => {
  const [tabKey, setTabKey] = useState('reportesplika')
  const { t } = useTranslation()
  let query = useQuery()

  const auxKey = window.location.pathname.replaceAll('/', '-')
  const key = !auxKey.includes('?tab=') ? `?tab=${tabKey}` : ''

  useEffect(() => {
    sendUserDataToGTM(loggedUser, '/analisis')
  }, [loggedUser])

  const renderHeader = () => <PeriodNavigator items={[{ name: t('LABEL_ANALYSIS') }]} />

  return (
    <SiderLayout extraKey={key}>
      {renderHeader()}
      <Tabs
        size="small"
        onChange={setTabKey}
        defaultActiveKey={query.get('tab') !== null ? query.get('tab') : 'reportesplika'}
      >
        <Tabs.TabPane tab={t('REPORT_PLIKA_CARD_TITLE')} key={'reportesplika'}>
          <ReportList />
        </Tabs.TabPane>
        {loggedUser.quicksight && (
          <Tabs.TabPane tab={t('REPORT_SELF_SERVICE_FIELD')} key={'reportesselfservice'}>
            <ReportDashboards />
          </Tabs.TabPane>
        )}
      </Tabs>
    </SiderLayout>
  )
}

const mapStateToProps = (state) => ({
  loggedUser: login.selectors.getWhoAmI(state),
})

export default connect(mapStateToProps, null)(ReportHome)
