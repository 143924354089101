const forecast = {
  // Actions
  FORECAST_CREATE_ACTION: 'Crear forecast',
  // Fields
  FORECAST_CUT_DATE_FIELD: 'Mes de corte:',

  // Messages
  FORECAST_NO_DATA_ALERT_TITLE:
    'Todavía no tenemos todos los datos que necesitamos para crear tu forecast',
  FORECAST_NO_DATA_ALERT_TEXT:
    'Para que puedas crear forecast debemos tener al menos un presupuesto global publicado y un mes con datos reales publicados. Una vez que lo hagas, podrás crear tus re-presupuestaciones.',
  FORECAST_EMPTY_STATE_TEXT:
    'Identificá aquello que no evoluciona según lo planificado y re-definí tus objetivos. Modificá el futuro a corto y mediano plazo para disminuir riesgos',

  FORECAST_DELETE_ERROR_FEEDBACK: 'No se pudo eliminar el forecast',
  FORECAST_EDIT_NAME_SUCCESS_FEEDBACK: 'El nombre del forecast se editó correctamente.',
  FORECAST_EDIT_NAME_ERROR_FEEDBACK:
    'No pudimos editar el nombre del forecast. Por favor, volvé a intentarlo.',
  FORECAST_DELETE_POPCONFIRM: '¿Estás seguro que deseas eliminar este forecast?',

  // XLS names
  FORECAST_TEMPLATE_NAME: 'Plantilla Plika - Forecast {{name}} - {{periodName}} ({{date}})',
  FORECAST_GLOBAL_BUDGET_EVOLUTION_TABLE_FILE_NAME: `Plika - Forecast {{name}}({{reportName}}) - {{periodName}} (Forecast global)`,
  FORECAST_PHASE_TABLE_FILE_NAME:
    'Plika - Forecast {{name}} etapa {{phase}} ({{conceptName}}) - {{periodName}} ({{date}})',

  FORECAST_CUT_DATE_INFORMATIVE_TEXT_MODAL:
    'Elige un nombre, una base y selecciona el mes de corte para crear tu forecast. Este es el último mes del que se toman datos reales publicados',
  FORECAST_CUT_DATE_PLACEHOLDER_DATEPICKER_MODAL: 'Seleccionar mes',
  FORECAST_CREATE_GLOBAL_FORECAST_MODAL_TITLE: 'Crear forecast global',
  FORECAST_CUTTING_MONTH_FORM_CREATE_MODAL_LABEL: 'Mes de corte',
  FORECAST_NEED_NEW_PUBLISHED_DATE_INFORMATIVE_TOOLTIP_TITLE:
    'Necesitas publicar una nueva fecha para poder generar un forecast con un nuevo mes de corte.',
  FORECAST_NEED_GLOBAL_BUDGET_FORECAST_PUBLISHED_APPROVED_INFORMATIVE_TOOLTIP_TITLE:
    'Debes tener al menos un presupuesto global o forecast global (del mes de corte anterior) publicado o aprobado para poder crear un forecast nuevo',
  FORECAST_NEED_LAST_CUT_DATE_WITH_GLOBAL_FORECAST_PUBLISHED_APPROVED_INFORMATIVE_TOOLTIP_TITLE:
    'Debes publicar o aprobar un forecast global (del ultimo mes de corte) para poder crear un nuevo forecast',
  FORECAST_NEED_PUBLISHED_DATE_GLOBAL_BUDGET_INFORMATIVE_TOOLTIP_TITLE:
    'Debes publicar una fecha real y publicar un presupuesto global para poder crear tu primer forecast',
  FORECAST_CREATE_NEW_BUDGET_BASE_TYPE_FORM_LABEL: 'Base por la cual crear tu forecast',
  FORECAST_CREATE_MODAL_TITLE: 'Crear nuevo forecast base',

  FORECAST_MODULE_INFORMATIVE_ALERT_DESCRIPTION:
    'Este módulo sirve para generar una reproyección de las estimaciones iniciales del presupuesto. Se puede crear un pronóstico (Forecast) por cada mes de corte, el cual podrá mantener múltiples tarjetas (cards) en paralelo. Para la creación de un Forecast, se debe seleccionar un mes de corte, que es el último mes con información real dentro de este módulo. Es importante aclarar que el Forecast creado traerá la información publicada en el módulo de Seguimiento al momento de generarlo; si luego la información en el módulo de Seguimiento es actualizada, no impactará en la reproyección creada anteriormente. En este caso, se debe volver a publicar en el módulo de Seguimiento y volver a crear la reproyeccion. Por último, si el botón "Crear nuevo" se encuentra grisado, revisa si tienes datos publicados en el módulo de Seguimiento y un Presupuesto Consolidado publicado.',

  FORECAST_VIDEO_DESCRIPTION_1:
    'Te explicaremos las funcionalidades clave del módulo de Forecast, diseñado para generar re-proyecciones de la planificación establecida al inicio del período o del año.',
  FORECAST_VIDEO_DESCRIPTION_2:
    'Te enseñaremos a generar nuevas tarjetas y crear escenarios adicionales dentro de un forecast ya existente.',
  FORECAST_VIDEO_DESCRIPTION_3:
    'Te explicaremos la función del Forecast global, entendido como un consolidado que se analiza luego en el módulo de Análisis.',
}

export default forecast
