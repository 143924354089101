import React, { useEffect, useState } from 'react'
import { Col, Divider, Form, Input, Modal, Row, Select, Space, Switch, message } from 'antd'
import { useTranslation } from 'react-i18next'
import SOCIETIES from '../../../../../core/constants/societies'
import { handleActivate } from './utils'
import { connect } from 'react-redux'
import { AddAndDeleteFormItem, Typography } from 'modules/core/components'
import { generateMessageError, generateSelectOptions } from 'modules/core/utils'
import societies from 'modules/societies'
import _, { isEmpty } from 'lodash'

const ConfigurateSocietyModal = ({
  visible,
  onCancel,
  societyId,
  onRefetch,
  onRefetchButtonList,
  userList,
  has_quicksight,
  has_ml,
  enabledFormat,
  format_number,
  ActivateQuicksight,
  setUserUploadS3,
  ActivateML,
  FunctionsButtonsList,
  FunctionsButtonsCreate,
  FormatNumberCreate,
  fetchButtonsStandar,
  buttonsStandar,
  editButtonsStandar,
  deleteButtonStandar,
  societiesDimensions,
}) => {
  const [isSaving, setIsSaving] = useState(false)
  const [selectedUsers, setSelectedUsers] = useState([])
  const [userId, setUserId] = useState(null)
  const [showS3, setShowS3] = useState(false)
  const [customBottom, setCustomBottom] = useState(false)
  const [isButtonsLoading, setIsButtonsLoading] = useState(false)
  const [form] = Form.useForm()
  const { t } = useTranslation()

  useEffect(() => {
    if (visible) {
      setIsButtonsLoading(true)
      fetchButtonsStandar({ society_id: societyId }).then(() => setIsButtonsLoading(false))
    }
  }, [fetchButtonsStandar, societyId, visible])

  const auxHasQuicksight = has_quicksight ? true : false

  useEffect(() => {
    if (visible) {
      const auxUsersList = userList.filter((el) => el.link_upload_file)
      form.setFieldsValue({
        ...(auxUsersList.length > 0 && {
          users: userList
            .filter((el) => el.link_upload_file)
            .map((us) => {
              return {
                user_id: us.id,
                activate_link: us.link_upload_file,
                versioned: us.versioned_upload_file,
              }
            }),
        }),
        customBottoms:
          FunctionsButtonsList.values.length > 0
            ? FunctionsButtonsList.values.map((el) => {
                return {
                  ...el,
                  submodule: el.submodule.length > 0 ? el.submodule.map((it) => parseInt(it)) : [],
                  ...(el.concept ? { concept: parseInt(el.concept) } : {}),
                  enabled_metadata: !isEmpty(el.metadata),
                  filter_by_dimension: el.metadata
                    ? el.metadata.filter_by_dimension
                      ? el.metadata.filter_by_dimension
                      : null
                    : null,
                }
              })
            : [
                {
                  module: null,
                  submodule: [],
                  concept: null,
                  action: null,
                  name_button: '',
                  name_lambda: '',
                  enabled_metadata: false,
                  filter_by_dimension: null,
                },
              ],
      })
      FunctionsButtonsList.values.length > 0 && setCustomBottom(true)
    }
  }, [FunctionsButtonsList.values, form, visible, userList])

  const onClose = () => {
    form.resetFields()
    setIsSaving(false)
    setUserId(null)
    setSelectedUsers([])
    setShowS3(false)
    form.setFieldsValue({ users: [''] })
    onCancel()
  }

  const onSave = (values) => {
    const auxUsers = showS3
      ? userList.map((user) => {
          const findUser = values.users.find((el) => el.user_id === user.id)
          return findUser && user.link_upload_file !== findUser.activate_link
        })
      : []

    const handleSaveButtonsStandar = () => {
      if (customBottom) {
        const aux1 = FunctionsButtonsList.values.map((el) => {
          const { id, function_call, is_upload_file, ...restProps } = el
          return { ...restProps }
        })
        const aux2 = values.customBottoms.map((el) => {
          const { id, function_call, is_upload_file, ...restProps } = el
          return { ...restProps, submodule: el.submodule.toString() }
        })
        if (
          FunctionsButtonsList.values.length > 0 &&
          FunctionsButtonsList.values.length === values.customBottoms.length &&
          !_.isEqual(aux1, aux2)
        ) {
          const data = values.customBottoms.map((el) => {
            const {
              id,
              concept,
              function_call,
              is_upload_file,
              metadata,
              filter_by_dimension,
              enabled_metadata,
              ...restProps
            } = el
            return {
              ...restProps,
              ...(concept ? { concept: concept } : {}),
              ...(enabled_metadata && filter_by_dimension
                ? {
                    metadata: { filter_by_dimension: filter_by_dimension },
                  }
                : {}),
              function_id: id,
            }
          })
          return editButtonsStandar({ items: data })
        }

        return FunctionsButtonsCreate({
          society_id: societyId,
          items: values.customBottoms
            .filter((el) => !el.id)
            .map((it) => {
              const { concept, metadata, filter_by_dimension, enabled_metadata, ...restProps } = it
              return {
                ...restProps,
                ...(concept ? { concept: concept } : {}),
                ...(filter_by_dimension
                  ? {
                      metadata: { filter_by_dimension: filter_by_dimension },
                    }
                  : {}),
              }
            }),
        })
      }
    }

    const quicksight =
      auxHasQuicksight !== values.status &&
      ActivateQuicksight({
        society: societyId,
        status: values.status,
        url_account_dashboard: values.url_account_dashboard,
      })
    const users =
      showS3 &&
      (values.users.every((user) => user.user_id) || _.compact(auxUsers).length > 0) &&
      setUserUploadS3(values.users)
    const ml =
      has_ml !== values.status_ml && ActivateML({ status: values.status_ml, society_id: societyId })
    const customButtons = handleSaveButtonsStandar()
    const formatNumber =
      values.enabled_format &&
      FormatNumberCreate(societyId, {
        delimiter_thousand: values.delimiter_thousand,
        delimiter_decimal: values.delimiter_decimal,
        abbreviation_thousand: values.abbreviation_thousand,
        abbreviation_million: values.abbreviation_million,
        abbreviation_billion: values.abbreviation_billion,
        abbreviation_trillion: values.abbreviation_trillion,
      })
    return [quicksight, users, ml, customButtons, formatNumber]
  }

  const handleDeleteButtonsStandar = (data) => {
    deleteButtonStandar({ function_id: data.id })
      .then(() =>
        onRefetchButtonList().then(() => message.success(t('FEEDBACK_DELETE_DATA_SUCCESS'), 8)),
      )
      .catch((error) => message.error(generateMessageError(error, 'FEEDBACK_DELETE_FAIL'), 8))
  }

  const getSubmodule = (module, concept = undefined) => {
    const cards = buttonsStandar.find((el) => el.module === module)
    if (isEmpty(cards)) return []

    const submodules = cards.has_concept
      ? cards.submodule.filter((it) => it.concept === concept)
      : cards.submodule

    return submodules.map((el) => ({
      ...el,
      id: el.id,
      name: el.name_card,
      label: el.name_card,
    }))
  }

  const getConcept = (module) => {
    const cards = buttonsStandar.find((el) => el.module === module)
    if (isEmpty(cards)) return []

    return cards.has_concept ? cards.concept : []
  }

  return (
    <Modal
      title={t('SOCIETY_CONFIG_MODAL')}
      visible={visible}
      onCancel={onClose}
      width={550}
      onOk={() =>
        handleActivate({
          form,
          setIsLoading: setIsSaving,
          endpointActions: (values) => onSave(values),
          onRefetch,
          onRefetchButtonList,
          onClose,
        })
      }
      okText={t('ACTION_SAVE')}
      cancelText={t('ACTION_CANCEL')}
      bodyStyle={{ overflowY: 'auto', maxHeight: 500 }}
      okButtonProps={{ loading: isSaving, disabled: isSaving }}
      cancelButtonProps={{ disabled: isSaving }}
      destroyOnClose={true}
      centered
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={{ users: [''], customBottoms: [''], ...format_number }}
      >
        {() => (
          <Row gutter={[8, 8]}>
            <Col span={24}>
              <Typography.Body>Quicksight</Typography.Body>
            </Col>
            <Col span={18}>
              <Form.Item
                name="url_account_dashboard"
                label={t('Url dashboard quicksight')}
                rules={[
                  {
                    required:
                      !form.getFieldsValue().status ||
                      auxHasQuicksight === form.getFieldsValue().status
                        ? false
                        : true,
                    message: t('REQUIRED_FIELD'),
                  },
                ]}
                initialValue={has_quicksight || undefined}
              >
                <Input
                  placeholder={t('INTEGRATION_ENTER_URL_INPUT_PLACEHOLDER')}
                  disabled={!form.getFieldsValue().status}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                name="status"
                label={t('FIELD_STATUS')}
                rules={[
                  {
                    required: auxHasQuicksight === form.getFieldsValue().status ? false : true,
                    message: t('REQUIRED_FIELD'),
                  },
                ]}
                initialValue={auxHasQuicksight}
              >
                <Switch
                  title={auxHasQuicksight ? t('ACTION_DEACTIVATE') : t('ACTION_ACTIVATE')}
                  defaultChecked={auxHasQuicksight}
                  checkedChildren={t('STATE_TAG_ACTIVE')}
                  unCheckedChildren={t('STATE_TAG_INACTIVE')}
                />
              </Form.Item>
            </Col>
            <Col span={7}>
              <Typography.Body>Machine Learning</Typography.Body>
            </Col>
            <Col span={8}>
              <Form.Item
                name="status_ml"
                rules={[{ required: true, message: t('REQUIRED_FIELD') }]}
                initialValue={has_ml}
              >
                <Switch
                  title={has_ml ? t('ACTION_DEACTIVATE') : t('ACTION_ACTIVATE')}
                  defaultChecked={has_ml}
                  checkedChildren={t('STATE_TAG_ACTIVE')}
                  unCheckedChildren={t('STATE_TAG_INACTIVE')}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Space direction="horizontal">
                <Typography.Body>{t('FIELD_FILE_S3_UPLOAD')}</Typography.Body>
                <Switch
                  title={showS3 ? t('ACTION_HIDE') : t('ACTION_SHOW')}
                  onChange={setShowS3}
                  checked={showS3}
                />
              </Space>
            </Col>
            {showS3 && (
              <Col span={24}>
                <Form.Item label={t('CONFIG_USERS_SELECTION')}>
                  <Form.List name="users">
                    {(fields, { add, remove }) => {
                      return (
                        <div>
                          {fields.map((field, index) => {
                            return (
                              <Row gutter={[8, 8]} key={field.key} justify="end">
                                <Col span={18}>
                                  <Form.Item
                                    name={[field.name, 'user_id']}
                                    rules={[
                                      {
                                        required: showS3 ? true : false,
                                        message: t('REQUIRED_FIELD'),
                                      },
                                    ]}
                                    initialValue={null}
                                  >
                                    <Select
                                      placeholder={t('LABEL_USER')}
                                      onChange={setUserId}
                                      options={generateSelectOptions({
                                        options: userList.map((user) => {
                                          return {
                                            id: user.id,
                                            name: `${user.first_name} ${user.last_name}`,
                                          }
                                        }),
                                        selection: selectedUsers,
                                      })}
                                    />
                                  </Form.Item>
                                </Col>
                                <Col span={6}>
                                  <Form.Item name={[field.name, 'activate_link']}>
                                    <Switch
                                      title={
                                        form.getFieldValue('users')[field.name] &&
                                        form.getFieldValue('users')[field.name]['user_id'] &&
                                        userList.find(
                                          (user) =>
                                            user.id ===
                                            form.getFieldValue('users')[field.name]['user_id'],
                                        ).link_upload_file
                                          ? t('ACTION_DEACTIVATE')
                                          : t('ACTION_ACTIVATE')
                                      }
                                      defaultChecked={
                                        form.getFieldValue('users')[field.name] &&
                                        form.getFieldValue('users')[field.name]['user_id'] &&
                                        userList.find(
                                          (user) =>
                                            user.id ===
                                            form.getFieldValue('users')[field.name]['user_id'],
                                        ).link_upload_file
                                      }
                                      key={
                                        form.getFieldValue('users')[field.name] &&
                                        form.getFieldValue('users')[field.name]['user_id'] &&
                                        userList.find(
                                          (user) =>
                                            user.id ===
                                            form.getFieldValue('users')[field.name]['user_id'],
                                        ).link_upload_file
                                      }
                                      checkedChildren={t('STATE_TAG_ACTIVE')}
                                      unCheckedChildren={t('STATE_TAG_INACTIVE')}
                                    />
                                  </Form.Item>
                                </Col>
                                {((form.getFieldValue('users')[field.name] &&
                                  form.getFieldValue('users')[field.name].activate_link) ||
                                  (form.getFieldValue('users')[field.name] &&
                                    form.getFieldValue('users')[field.name]['user_id'] &&
                                    userList.find(
                                      (user) =>
                                        user.id ===
                                        form.getFieldValue('users')[field.name]['user_id'],
                                    ).link_upload_file)) && (
                                  <Col span={24}>
                                    <Space
                                      direction="horizontal"
                                      style={{ display: 'flex', alignItems: 'flex-start' }}
                                    >
                                      <div style={{ paddingTop: 5 }}>
                                        <Typography.Body>{t('FIELD_VERSIONED')}</Typography.Body>
                                      </div>
                                      <Form.Item name={[field.name, 'versioned']}>
                                        <Switch
                                          title={
                                            form.getFieldValue('users')[field.name] &&
                                            form.getFieldValue('users')[field.name]['user_id'] &&
                                            userList.find(
                                              (user) =>
                                                user.id ===
                                                form.getFieldValue('users')[field.name]['user_id'],
                                            ).versioned_upload_file
                                              ? t('ACTION_DEACTIVATE')
                                              : t('ACTION_ACTIVATE')
                                          }
                                          defaultChecked={
                                            form.getFieldValue('users')[field.name] &&
                                            form.getFieldValue('users')[field.name]['user_id'] &&
                                            userList.find(
                                              (user) =>
                                                user.id ===
                                                form.getFieldValue('users')[field.name]['user_id'],
                                            ).versioned_upload_file
                                          }
                                          key={
                                            form.getFieldValue('users')[field.name] &&
                                            form.getFieldValue('users')[field.name]['user_id'] &&
                                            userList.find(
                                              (user) =>
                                                user.id ===
                                                form.getFieldValue('users')[field.name]['user_id'],
                                            ).versioned_upload_file
                                          }
                                          checkedChildren={t('STATE_TAG_ACTIVE')}
                                          unCheckedChildren={t('STATE_TAG_INACTIVE')}
                                        />
                                      </Form.Item>
                                    </Space>
                                  </Col>
                                )}
                                <AddAndDeleteFormItem
                                  fieldsLength={fields.length}
                                  index={index}
                                  addData={{
                                    disabled: false,
                                    onClick: () => {
                                      add()
                                      if (userId) {
                                        setSelectedUsers([...selectedUsers, userId.toString()])
                                      }
                                      setUserId(null)
                                    },
                                    buttonName: 'ACTION_ADD_NEW',
                                  }}
                                  deleteData={{
                                    disabled: false,
                                    onClick: () => {
                                      if (form.getFieldValue('users')[field.name]) {
                                        const selectedID =
                                          form.getFieldValue('users')[field.name]['user_id']
                                        setSelectedUsers(
                                          selectedUsers.filter(
                                            (id) => id !== selectedID.toString(),
                                          ),
                                        )
                                      }
                                      remove(field.name)
                                    },
                                    buttonName: 'ACTION_DELETE',
                                  }}
                                />
                              </Row>
                            )
                          })}
                        </div>
                      )
                    }}
                  </Form.List>
                </Form.Item>
              </Col>
            )}
            <Divider />
            <Col span={24}>
              <Space direction="horizontal">
                <Typography.Body>{t('FIELD_CUSTOM_BOTTOM')}</Typography.Body>
                <Switch
                  title={customBottom ? t('ACTION_HIDE') : t('ACTION_SHOW')}
                  onChange={setCustomBottom}
                  checked={customBottom}
                />
              </Space>
            </Col>
            {customBottom && (
              <Col span={24}>
                <Form.Item label="">
                  <Form.List name="customBottoms">
                    {(fields, { add, remove }) => {
                      return (
                        <div>
                          {fields.map((field, index) => {
                            return (
                              <Row gutter={[8, 0]} key={field.key} justify="end">
                                <Col span={12}>
                                  <Form.Item
                                    name={[field.name, 'module']}
                                    rules={[
                                      {
                                        required: customBottom ? true : false,
                                        message: t('REQUIRED_FIELD'),
                                      },
                                    ]}
                                    initialValue={null}
                                  >
                                    <Select
                                      placeholder={t('SOCIETY_PLACEHOLDER_BOTTOM_MODULE')}
                                      loading={isButtonsLoading}
                                      options={generateSelectOptions({
                                        options: buttonsStandar.map((op) => {
                                          return {
                                            id: op.module,
                                            name: t(
                                              `SOCIETY_PLACEHOLDER_${op.module.toUpperCase()}`,
                                            ),
                                          }
                                        }),
                                      })}
                                    />
                                  </Form.Item>
                                </Col>
                                <Col span={12}>
                                  <Form.Item
                                    name={[field.name, 'concept']}
                                    rules={[
                                      {
                                        required: false,
                                        message: t('REQUIRED_FIELD'),
                                      },
                                    ]}
                                    initialValue={null}
                                  >
                                    <Select
                                      allowClear
                                      placeholder={t('SOCIETY_PLACEHOLDER_BOTTOM_GROUP')}
                                      options={generateSelectOptions({
                                        options:
                                          form.getFieldsValue()?.customBottoms &&
                                          form.getFieldsValue()?.customBottoms[index]?.module
                                            ? getConcept(
                                                form.getFieldsValue()?.customBottoms[index]?.module,
                                              )
                                            : [],
                                      })}
                                    />
                                  </Form.Item>
                                </Col>
                                <Col span={12}>
                                  <Form.Item
                                    name={[field.name, 'submodule']}
                                    rules={[
                                      {
                                        required: false,
                                        message: t('REQUIRED_FIELD'),
                                      },
                                    ]}
                                    initialValue={null}
                                  >
                                    <Select
                                      mode="multiple"
                                      allowClear
                                      maxTagCount={1}
                                      maxTagTextLength={12}
                                      optionFilterProp="children"
                                      placeholder={t('SOCIETY_PLACEHOLDER_BOTTOM_SUBMODULE')}
                                      filterOption={(input, option) =>
                                        option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                      }
                                      options={generateSelectOptions({
                                        options:
                                          form.getFieldsValue()?.customBottoms &&
                                          form.getFieldsValue()?.customBottoms[index]?.module
                                            ? getSubmodule(
                                                form.getFieldsValue()?.customBottoms[index]?.module,
                                                form.getFieldsValue()?.customBottoms[index]
                                                  ?.concept,
                                              )
                                            : [],
                                        includeOptionAll: true,
                                      })}
                                    />
                                  </Form.Item>
                                </Col>
                                <Col span={12}>
                                  <Form.Item
                                    name={[field.name, 'name_button']}
                                    rules={[
                                      {
                                        required: customBottom ? true : false,
                                        message: t('REQUIRED_FIELD'),
                                      },
                                    ]}
                                    initialValue={null}
                                  >
                                    <Input placeholder={t('SOCIETY_PLACEHOLDER_BOTTOM_NAME')} />
                                  </Form.Item>
                                </Col>
                                <Col span={10}>
                                  <Form.Item
                                    name={[field.name, 'action']}
                                    rules={[
                                      {
                                        required: customBottom ? true : false,
                                        message: t('REQUIRED_FIELD'),
                                      },
                                    ]}
                                    initialValue={null}
                                  >
                                    <Select
                                      placeholder={t('SOCIETY_PLACEHOLDER_BOTTOM_ACTION')}
                                      options={SOCIETIES.SOCIETIES_ACTION.map((it) => {
                                        return { ...it, label: t(it.label) }
                                      })}
                                    />
                                  </Form.Item>
                                </Col>
                                <Col span={14}>
                                  <Form.Item
                                    name={[field.name, 'name_lambda']}
                                    rules={[
                                      {
                                        required: customBottom ? true : false,
                                        message: t('REQUIRED_FIELD'),
                                      },
                                    ]}
                                    initialValue={null}
                                  >
                                    <Input placeholder={t('SOCIETY_PLACEHOLDER_BOTTOM_FUNCTION')} />
                                  </Form.Item>
                                </Col>
                                <Col span={24}>
                                  <Space direction="horizontal">
                                    <Typography.Body level={2}>Metadata</Typography.Body>
                                    <Form.Item
                                      name={[field.name, 'enabled_metadata']}
                                      rules={[{ required: false, message: t('REQUIRED_FIELD') }]}
                                      style={{ marginBottom: 0, marginTop: 0 }}
                                      valuePropName="checked"
                                    >
                                      <Switch
                                        title={enabledFormat ? t('ACTION_HIDE') : t('ACTION_SHOW')}
                                        size={'small'}
                                      />
                                    </Form.Item>
                                  </Space>
                                  {!isEmpty(form.getFieldsValue()?.customBottoms) &&
                                    form.getFieldsValue()?.customBottoms[index]
                                      ?.enabled_metadata && (
                                      <Row gutter={[8, 0]} key={field.key} justify="start">
                                        <Col span={12}>
                                          <Form.Item
                                            name={[field.name, 'filter_by_dimension']}
                                            rules={[
                                              {
                                                required: false,
                                                message: t('REQUIRED_FIELD'),
                                              },
                                            ]}
                                            initialValue={null}
                                            style={{ marginBottom: 5, marginTop: 5 }}
                                          >
                                            <Select
                                              placeholder={'Filtro de datos en base a dimension'}
                                              options={societiesDimensions.values.map((it) => {
                                                return {
                                                  id: it.id,
                                                  value: it.id,
                                                  label: it.name_destination,
                                                }
                                              })}
                                            />
                                          </Form.Item>
                                        </Col>
                                      </Row>
                                    )}
                                </Col>

                                <AddAndDeleteFormItem
                                  fieldsLength={fields.length}
                                  index={index}
                                  addData={{
                                    disabled: false,
                                    onClick: () => {
                                      add({ submodule: [] })
                                    },
                                    buttonName: 'ACTION_ADD_NEW',
                                  }}
                                  deleteData={{
                                    disabled: false,
                                    onClick: () => {
                                      remove(index)
                                      if (FunctionsButtonsList.values.length > 0) {
                                        const aux = FunctionsButtonsList.values[index]
                                        handleDeleteButtonsStandar(aux)
                                      }
                                    },
                                    buttonName: 'ACTION_DELETE',
                                  }}
                                />
                              </Row>
                            )
                          })}
                        </div>
                      )
                    }}
                  </Form.List>
                </Form.Item>
              </Col>
            )}
            <Divider />
            <Col span={24}>
              <Space direction="horizontal">
                <Typography.Body>{t('FIELD_FORMAT')}</Typography.Body>
                <Form.Item
                  name="enabled_format"
                  rules={[{ required: false, message: t('REQUIRED_FIELD') }]}
                  initialValue={enabledFormat}
                  style={{ marginBottom: 0 }}
                >
                  <Switch
                    title={enabledFormat ? t('ACTION_HIDE') : t('ACTION_SHOW')}
                    defaultChecked={enabledFormat}
                    checkedChildren={t('STATE_TAG_ACTIVE')}
                    unCheckedChildren={t('STATE_TAG_INACTIVE')}
                  />
                </Form.Item>
              </Space>
            </Col>
            {form.getFieldsValue().enabled_format && (
              <Col span={24}>
                <Row gutter={[2, 2]} justify="start">
                  <Col span={24}>
                    <Typography.Body>{t('LABEL_DELIMITERS')}</Typography.Body>
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      name="delimiter_thousand"
                      label={t('LABEL_DELIMITER_THOUSANDS')}
                      rules={[{ required: true, message: t('REQUIRED_FIELD') }]}
                      initialValue={'.'}
                      style={{ marginBottom: 5 }}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      name="delimiter_decimal"
                      label={t('LABEL_DELIMITER_DECIMAL')}
                      rules={[{ required: true, message: t('REQUIRED_FIELD') }]}
                      initialValue={','}
                      style={{ marginBottom: 5 }}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Typography.Body>{t('LABEL_ABBREVIATIONS')}</Typography.Body>
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      name="abbreviation_thousand"
                      label={t('LABEL_ABBREVIATION_THOUSAND')}
                      rules={[{ required: true, message: t('REQUIRED_FIELD') }]}
                      initialValue={'k'}
                      style={{ marginBottom: 5 }}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      name="abbreviation_million"
                      label={t('LABEL_ABBREVIATION_MILLION')}
                      rules={[{ required: true, message: t('REQUIRED_FIELD') }]}
                      initialValue={'M'}
                      style={{ marginBottom: 5 }}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      name="abbreviation_billion"
                      label={t('LABEL_ABBREVIATION_BILLION')}
                      rules={[{ required: true, message: t('REQUIRED_FIELD') }]}
                      initialValue={'B'}
                      style={{ marginBottom: 5 }}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      name="abbreviation_trillion"
                      label={t('LABEL_ABBREVIATION_TRILLION')}
                      rules={[{ required: true, message: t('REQUIRED_FIELD') }]}
                      initialValue={'T'}
                      style={{ marginBottom: 5 }}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            )}
          </Row>
        )}
      </Form>
    </Modal>
  )
}

const mapStateToProps = (state) => ({
  FunctionsButtonsList: societies.selectors.getFunctionsButtonsList(state),
  buttonsStandar: societies.selectors.getButtonsStandar(state),
  societiesDimensions: societies.selectors.getSocietiesDimensions(state),
})

const mapDispatchToProps = {
  ActivateQuicksight: societies.actions.ActivateQuicksight,
  setUserUploadS3: societies.actions.setUserUploadS3,
  ActivateML: societies.actions.ActivateML,
  FunctionsButtonsCreate: societies.actions.FunctionsButtonsCreate,
  FormatNumberCreate: societies.actions.FormatNumberCreate,
  fetchButtonsStandar: societies.actions.fetchButtonsStandar,
  editButtonsStandar: societies.actions.editButtonsStandar,
  deleteButtonStandar: societies.actions.deleteButtonStandar,
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfigurateSocietyModal)
