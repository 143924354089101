import { message, notification } from 'antd'
import { generateMessageError } from 'modules/core/utils'
import i18n from 'i18next'
import moment from 'moment'

export const onCreate = ({ form, setIsLoading, createTransaction, onRefetch, onClose }) => {
  form.validateFields().then(() => {
    setIsLoading(true)
    createTransaction()
      .then(() => {
        onRefetch()
        message.success(i18n.t('MESSAGE_PROCESS_STATUS_SUCCESS'), 8)
        onClose()
      })
      .catch((error) => {
        notification.error({
          message: i18n.t('FEEDBACK_DEFAULT_ERROR'),
          description: generateMessageError(error, 'FEEDBACK_LOAD_DATA_ERROR'),
          duration: 0,
        })
        onClose()
      })
  })
}

export const onDisabledDate = (date, dataPeriod) => {
  const pickerDates = moment(date._d).utc()

  return !(
    pickerDates >= moment(dataPeriod.start_date).utc() &&
    pickerDates <= moment(dataPeriod.end_date).utc()
  )
}

export const addItem = ({ event, setIsLoading, createDimensionValue, onRefetch, onClose }) => {
  event.preventDefault()
  setIsLoading(true)
  createDimensionValue()
    .then(() => {
      onRefetch().then(() => {
        message.success(i18n.t('FEEDBACK_CREATE_SUCCESS'), 8)
        onClose()
      })
    })
    .catch((error) => {
      notification.error({
        message: i18n.t('FEEDBACK_DEFAULT_ERROR'),
        description: generateMessageError(error, 'FEEDBACK_CREATION_FAIL'),
        duration: 0,
      })
      onClose()
    })
}

export const generateInputsNewItem = (dataCreate) => [
  {
    id: 'code',
    placeholder: 'ENTER_CODE_PLACEHOLDER',
    value: dataCreate.code,
  },
  {
    id: 'name',
    placeholder: 'ENTER_NAME_PLACEHOLDER',
    value: dataCreate.name,
  },
]

export const onSelectOption = ({ value, key, setDataCreate }) => {
  setDataCreate((prevState) => ({ ...prevState, [key]: value.target.value }))
}
