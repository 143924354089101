import imageStep_1 from 'assets/images/compositions/step_1.svg'
import imageStep_2 from 'assets/images/compositions/step_2.svg'
import imageStep_3 from 'assets/images/compositions/step_3.svg'

export const mockDataCards = [
  {
    id: 1,
    text: 'HOME_INITIAL_SCREEN_FIRST_STEP',
    image: imageStep_1,
  },
  {
    id: 2,
    text: 'HOME_INITIAL_SCREEN_SECOND_STEP',
    link: {
      name: 'HOME_INITIAL_SCREEN_SECOND_STEP_LNK',
      url: 'https://drive.google.com/drive/folders/1yxdLsl8NOw7G9yahY2Dq55ZjXG3NPlBD',
    },
    image: imageStep_2,
  },
  {
    id: 3,
    text: 'HOME_INITIAL_SCREEN_THIRD_STEP',
    image: imageStep_3,
  },
]
