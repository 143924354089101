import { message } from 'antd'
import { generateMessageError } from 'modules/core/utils'
import i18n from 'i18next'

export const onSave = ({ setIsLoading, saveRoleOperations, onRefetch, whoAmI, onClose }) => {
  setIsLoading(true)
  saveRoleOperations()
    .then(() => {
      // NOTE: Refetch del usuario logeado por si cambio su rol. Falta contemplar la condicion del rol.
      whoAmI()
      onRefetch()
      message.success(i18n.t('FEEDBACK_SAVE_CHANGES_SUCCES'), 8)
      onClose()
    })
    .catch((error) => {
      message.error(generateMessageError(error, 'FEEDBACK_SAVE_CHANGES_FAIL'), 8)
      onClose()
    })
}

// NOTE: Limpio las opciones tildadas
export const onCancel = ({ onClose, editRoleOperations }) => {
  onClose()
  editRoleOperations()
}
