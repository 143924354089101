import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Col, Collapse, Row } from 'antd'
import { Typography } from 'modules/core/components'
import { generateSelectOptions } from 'modules/core/utils'
import {
  ButtonDeletedFile,
  ContainerSelect,
  ContainerSelections,
  FooterAddNewSelect,
} from './components'
import { useTranslation } from 'react-i18next'
// import { handleOnChange } from './utils';
import home from 'modules/home'
import configuration from 'modules/configuration'

import './MappingDataFile.scss'

const { Panel } = Collapse

const MappingDataFile = ({
  dataFiles,
  dataSelection,
  fetchBudgetTypeList,
  budgetTypeList,
  setDataSelect,
  fetchDimensionPlika,
  // setSelection,
  createBudgetType,
}) => {
  const [dataLoading, setDataLoading] = useState(false)
  const [isCreating, setIsCreating] = useState(false)
  const { t } = useTranslation()

  useEffect(() => {
    setDataLoading(true)
    Promise.all([fetchBudgetTypeList(), fetchDimensionPlika()]).then(() => setDataLoading(false))
  }, [fetchBudgetTypeList, fetchDimensionPlika])

  return (
    <Row gutter={[24, 24]}>
      <Col span={24}>
        <>
          <Typography.Subtitle>
            {t('HOME_INITIAL_STEPS_SELECT_DATA_TRANSACTION_FILE_SUBTITLE')}
          </Typography.Subtitle>
          <Collapse
            accordion
            defaultActiveKey={[dataFiles.fact_table[0]?.id]}
            style={{ marginTop: 5 }}
            // onChange={(idFile)=> dataFiles.fact_table.length > 1 && handleOnChange({idFile, dataSelection, dataFiles, setSelection})}
          >
            {dataFiles.fact_table.map((dataFact, indexFact) => (
              <Panel
                header={dataFact.name_file}
                key={dataFact.id}
                className="mapping-data-file-header"
                extra={
                  dataFiles.fact_table.length > 1 && (
                    <ButtonDeletedFile indexFact={indexFact} dataFact={dataFact} />
                  )
                }
              >
                <Row gutter={[16, 16]} style={{ overflowY: 'auto', maxHeight: 300 }}>
                  {dataFact?.type_upload === 'file' && (
                    <Col span={6}>
                      <ContainerSelect
                        onChange={(val) => {
                          setDataSelect({
                            dataFactId: dataFact.id,
                            value: val,
                            dataFactType: 'concept',
                          })
                        }}
                        options={generateSelectOptions({ options: budgetTypeList?.values })}
                        loading={dataLoading || isCreating}
                        title={t('LABEL_BUDGET_TYPE')}
                        style={{ width: 250 }}
                        dropdownRender={(menu) => (
                          <FooterAddNewSelect
                            menu={menu}
                            onRefetch={() => fetchBudgetTypeList()}
                            setIsCreating={setIsCreating}
                            create={(data) => createBudgetType(data)}
                            isCreating={isCreating}
                          />
                        )}
                      />
                    </Col>
                  )}
                  {dataSelection.fact_table[indexFact].concept &&
                    dataSelection.fact_table[indexFact].selections.map((dataSel, indexSel) => (
                      <div style={{ width: '100%' }} key={dataSel.id}>
                        <ContainerSelections
                          dataSel={dataSel}
                          dataFact={dataFact}
                          indexFact={indexFact}
                          indexSel={indexSel}
                          loading={dataLoading}
                        />
                      </div>
                    ))}
                </Row>
              </Panel>
            ))}
          </Collapse>
        </>
      </Col>
    </Row>
  )
}

const mapStateToProps = (state) => ({
  dataFiles: home.selectors.getDataFiles(state),
  dataSelection: home.selectors.getDataSelection(state),
  budgetTypeList: configuration.selectors.getBudgetTypeList(state),
})

const mapDispatchToProps = {
  fetchBudgetTypeList: configuration.actions.fetchBudgetTypeList,
  createBudgetType: configuration.actions.createBudgetType,
  setDataSelect: home.actions.setDataSelect,
  fetchDimensionPlika: configuration.actions.fetchDimensionPlika,
  // setSelection: home.actions.setSelection,
}

export default connect(mapStateToProps, mapDispatchToProps)(MappingDataFile)
