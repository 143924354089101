import { message } from 'antd'
import { generateMessageError } from 'modules/core/utils'
import i18n from 'i18next'

export const onUserCreate = ({ form, setIsLoading, createUser, onRefetch, onClose }) => {
  form
    .validateFields()
    .then((values) => {
      setIsLoading(true)
      const data = {
        username: values.email.trim(),
        email: values.email.trim(),
        first_name: values.first_name.trim(),
        last_name: values.last_name.trim(),
        password: 'plika123',
        re_password: 'plika123',
      }
      createUser(data)
        .then(
          () =>
            // assignRole(response.payload.data.pkid,{role_ids: values.role_ids}).then(()=>
            onRefetch().then(() => {
              setIsLoading(false)
              message.success(i18n.t('FEEDBACK_CREATE_SUCCESS'), 8)
              onClose()
            }),
          // )
        )
        .catch((error) => {
          setIsLoading(false)
          message.error(generateMessageError(error, 'FEEDBACK_CREATION_FAIL'), 8)
          onClose()
        })
    })
    .catch((e) => {})
}
