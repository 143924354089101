import { useState } from 'react'
import { useToggle } from '.'

const useCollapser = ({ collapsed = true } = {}) => {
  const [isCollapsed, setIsCollapsed] = useToggle(collapsed)
  const [isCollapsing, setIsCollapsing] = useState(false)

  // setTimeout is use to give the table time to recalculate columns.
  const toggleCollapser = () => {
    setIsCollapsing(true)
    setIsCollapsed()
    setTimeout(() => setIsCollapsing(false), 5)
  }

  return [isCollapsed, toggleCollapser, isCollapsing]
}

export default useCollapser
