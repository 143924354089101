const COMPARISON_KEY = 'comparison'
const SEGMENTED_KEY = 'segmented'
const VERTICAL_KEY = 'vertical'
const EVOLUTION_KEY = 'evolution'
const PLSEGMENTED_KEY = 'plsegmented'
const DETALLE_RDN = 'detallerdn'
const VARIATION_KEY = 'variation'

const REPORTE_KEY = 'reporte'
const CUSTOM_KEY = 'custom'

const PNL_KEY = 'pnl'
const FNL_KEY = 'fnl'

const AMOUNT = 'Importe'

const ACCUMULATED = 'Acumulado'
const MONTHLY = 'Mensual'

const ECONOMIC = 'Económico'
const FINANCIAL = 'Financiero'

const REPORTS_TYPES = [
  { id: ECONOMIC, name: 'CONFIG_REPORT_ECONOMIC' },
  { id: FINANCIAL, name: 'CONFIG_REPORT_FINANCIAL' },
]

const REPORTS_KEYS = {
  COMPARISON_KEY: COMPARISON_KEY,
  SEGMENTED_KEY: SEGMENTED_KEY,
  VERTICAL_KEY: VERTICAL_KEY,
  EVOLUTION_KEY: EVOLUTION_KEY,
  PLSEGMENTED_KEY: PLSEGMENTED_KEY,
  REPORTE_KEY: REPORTE_KEY,
  CUSTOM_KEY: CUSTOM_KEY,
  AMOUNT: AMOUNT,
  DETALLE_RDN: DETALLE_RDN,
  PNL_KEY: PNL_KEY,
  FNL_KEY: FNL_KEY,
  ACCUMULATED: ACCUMULATED,
  MONTHLY: MONTHLY,
  ECONOMIC: ECONOMIC,
  FINANCIAL: FINANCIAL,
  VARIATION_KEY: VARIATION_KEY,
}

const REPORT = {
  REPORTS_KEYS,
  REPORTS_TYPES,
}

export default REPORT
