export const NAME = 'real'

export const FETCH_CONTROL_LIST = `${NAME}/FETCH_CONTROL_LIST`
export const FETCH_CONTROL_LIST_SUCCESS = `${NAME}/FETCH_CONTROL_LIST_SUCCESS`
export const FETCH_CONTROL_LIST_FAIL = `${NAME}/FETCH_CONTROL_LIST_FAIL`

export const FETCH_DATA_TABLE = `${NAME}/FETCH_DATA_TABLE`
export const FETCH_DATA_TABLE_SUCCESS = `${NAME}/FETCH_DATA_TABLE_SUCCESS`
export const FETCH_DATA_TABLE_FAIL = `${NAME}/FETCH_DATA_TABLE_FAIL`

export const FETCH_OPEN_ROW = `${NAME}/FETCH_OPEN_ROW`
export const FETCH_OPEN_ROW_SUCCESS = `${NAME}/FETCH_OPEN_ROW_SUCCESS`
export const FETCH_OPEN_ROW_FAIL = `${NAME}/FETCH_OPEN_ROW_FAIL`

export const FETCH_REAL_COLUMNS = `${NAME}/FETCH_REAL_COLUMNS`
export const FETCH_REAL_COLUMNS_SUCCESS = `${NAME}/FETCH_REAL_COLUMNS_SUCCESS`
export const FETCH_REAL_COLUMNS_FAIL = `${NAME}/FETCH_REAL_COLUMNS_FAIL`

export const FETCH_REAL_ROWS = `${NAME}/FETCH_REAL_ROWS`
export const FETCH_REAL_ROWS_SUCCESS = `${NAME}/FETCH_REAL_ROWS_SUCCESS`
export const FETCH_REAL_ROWS_FAIL = `${NAME}/FETCH_REAL_ROWS_FAIL`

export const FETCH_CONTROL_DETAIL_KPIS = `${NAME}/FETCH_CONTROL_DETAIL_KPIS`
export const FETCH_CONTROL_DETAIL_KPIS_SUCCESS = `${NAME}/FETCH_CONTROL_DETAIL_KPIS_SUCCESS`
export const FETCH_CONTROL_DETAIL_KPIS_FAIL = `${NAME}/FETCH_CONTROL_DETAIL_KPIS_FAIL`

export const DOWNLOAD_CONTROL_TEMPLATE_XLS = `${NAME}/DOWNLOAD_CONTROL_TEMPLATE_XLS`
export const UPLOAD_CONTROL_FILE_XLS = `${NAME}/UPLOAD_CONTROL_FILE_XLS`

export const DOWNLOAD_PROJECTION_XLS = `${NAME}/DOWNLOAD_PROJECTION_XLS,`
export const DOWNLOAD_PROJECTION_XLS_SUCCESS = `${NAME}/DOWNLOAD_PROJECTION_XLS_SUCCESS`
export const DOWNLOAD_PROJECTION_XLS_FAIL = `${NAME}/DOWNLOAD_PROJECTION_XLS_FAIL`

export const PUBLISH_PROJECTION = `${NAME}/PUBLISH_PROJECTION`
export const PUBLISH_PROJECTION_SUCCESS = `${NAME}/PUBLISH_PROJECTION_SUCCESS`
export const PUBLISH_PROJECTION_FAIL = `${NAME}/PUBLISH_PROJECTION_FAIL`

export const FETCH_MONTHS_TO_PUBLISH = `${NAME}/FETCH_MONTHS_TO_PUBLISH`
export const FETCH_MONTHS_TO_PUBLISH_SUCCESS = `${NAME}/FETCH_MONTHS_TO_PUBLISH_SUCCESS`
export const FETCH_MONTHS_TO_PUBLISH_FAIL = `${NAME}/FETCH_MONTHS_TO_PUBLISH_FAIL`

export const FETCH_MONTHS_TO_APPROVE = `${NAME}/FETCH_MONTHS_TO_APPROVE`
export const FETCH_MONTHS_TO_APPROVE_SUCCESS = `${NAME}/FETCH_MONTHS_TO_APPROVE_SUCCESS`
export const FETCH_MONTHS_TO_APPROVE_FAIL = `${NAME}/FETCH_MONTHS_TO_APPROVE_FAIL`

export const FETCH_PUBLISHED_MONTHS = `${NAME}/FETCH_PUBLISHED_MONTHS`
export const FETCH_PUBLISHED_MONTHS_SUCCESS = `${NAME}/FETCH_PUBLISHED_MONTHS_SUCCESS`
export const FETCH_PUBLISHED_MONTHS_FAIL = `${NAME}/FETCH_PUBLISHED_MONTHS_FAIL`

export const PUBLISH_MONTHS = `${NAME}/PUBLISH_MONTHS`
export const PUBLISH_MONTHS_SUCCESS = `${NAME}/PUBLISH_MONTHS_SUCCESS`
export const PUBLISH_MONTHS_FAIL = `${NAME}/PUBLISH_MONTHS_FAIL`

export const APPROVE_MONTHS = `${NAME}/APPROVE_MONTHS`

export const DOWNLOAD_COMPARATION_XLS = `${NAME}/DOWNLOAD_COMPARATION_XLS`
export const DOWNLOAD_COMPARATION_XLS_SUCCESS = `${NAME}/DOWNLOAD_COMPARATION_XLS_SUCCESS`
export const DOWNLOAD_COMPARATION_XLS_FAIL = `${NAME}/DOWNLOAD_COMPARATION_XLS_FAIL`

export const FETCH_APPROVE_DATES = `${NAME}/FETCH_APPROVE_DATES`
export const FETCH_APPROVE_DATES_SUCCESS = `${NAME}/FETCH_APPROVE_DATES_SUCCESS`
export const FETCH_APPROVE_DATES_FAIL = `${NAME}/FETCH_APPROVE_DATES_FAIL`

//my data
export const FETCH_LEGAL_DATA = `${NAME}/FETCH_LEGAL_DATA`
export const FETCH_LEGAL_DATA_SUCCESS = `${NAME}/FETCH_LEGAL_DATA_SUCCESS`
export const FETCH_LEGAL_DATA_FAIL = `${NAME}/FETCH_LEGAL_DATA_FAIL`

export const DELETE_ROW = `${NAME}/DELETE_ROW`
export const DELETE_ALL = `${NAME}/DELETE_ALL`
export const DELETE_FILTER = `${NAME}/DELETE_FILTER`

export const MASSIVE_CREATE_TRANSACTION = `${NAME}/CREATE_TRANSACTION,`
export const MASSIVE_CREATE_TRANSACTION_SUCCESS = `${NAME}/CREATE_TRANSACTION_SUCCESS`
export const MASSIVE_CREATE_TRANSACTION_FAIL = `${NAME}/CREATE_TRANSACTION_FAIL`

export const FETCH_DATA_TABLE_ANTV = `${NAME}/FETCH_DATA_TABLE_ANTV`
export const FETCH_DATA_TABLE_ANTV_SUCCESS = `${NAME}/FETCH_DATA_TABLE_ANTV_SUCCESS`
export const FETCH_DATA_TABLE_ANTV_FAIL = `${NAME}/FETCH_DATA_TABLE_ANTV_FAIL`

export const CREATE_LAYOUT = `${NAME}/CREATE_LAYOUT`

export const FETCH_LAYOUT_LIST = `${NAME}/FETCH_LAYOUT_LIST`
export const FETCH_LAYOUT_LIST_SUCCESS = `${NAME}/FETCH_LAYOUT_LIST_SUCCESS`
export const FETCH_LAYOUT_LIST_FAIL = `${NAME}/FETCH_LAYOUT_LIST_FAIL`

export const DELETE_LAYOUT = `${NAME}/DELETE_LAYOUT`

// TODO: NEW ENDPOINTS FOR REVAMP TABLE
export const DELETE_TRANSACTION_FILTER_BY_CONTROL = `${NAME}/DELETE_TRANSACTION_FILTER_BY_CONTROL`
