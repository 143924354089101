import { useState } from 'react'
import { Popconfirm, Row, Space } from 'antd'
import { Button, DeleteAndUpdate, DropdownMenu, Typography } from 'modules/core/components'
import { PushpinOutlined, PushpinFilled, EditOutlined, DeleteOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { DefaultElements, EditElements } from './components'
import { onDelete, onSave, onSetPin } from './utils'
import configuration from 'modules/configuration'

const CardBody = ({
  item,
  userCanActivate,
  onRefetch,
  editCurrencies,
  deleteCurrencies,
  setPin,
}) => {
  const [currencyEdit, setCurrencyEdit] = useState(null)
  const [dataCellEdit, setDataCellEdit] = useState({})
  const [deleteId, setDeleteId] = useState(null)
  const [isSaving, setIsSaving] = useState(false)

  const { t } = useTranslation()

  const onCellEdit = (name, value) => setDataCellEdit({ ...dataCellEdit, [`${name}`]: value })

  const onCancel = () => setCurrencyEdit(null)

  const onConfirm = () => {
    setCurrencyEdit(null)
    setDataCellEdit({})
    setIsSaving(false)
    setDeleteId(null)
  }

  return (
    <div className="currency-card-body-cotainer">
      <Row gutter={[24, 8]} className="currency-card-body-row">
        {currencyEdit === item.id ? (
          <EditElements onCellEdit={onCellEdit} item={item} />
        ) : (
          <DefaultElements item={item} />
        )}
      </Row>
      <Space direction="horizontal" size="large" className="currency-card-body-extra-actions">
        {currencyEdit === item.id ? (
          <DeleteAndUpdate
            onCancel={onCancel}
            onConfirm={onConfirm}
            onSave={() =>
              onSave({
                dataCellEdit,
                setIsLoading: setIsSaving,
                editCurrencies: () =>
                  editCurrencies({
                    name: item.name,
                    code: item.code,
                    symbol: item.symbol,
                    description: item.description,
                    is_default: item.is_default,
                    id_currency: currencyEdit,
                    ...dataCellEdit,
                  }),
                onRefetch,
                onClose: onConfirm,
              })
            }
            dataCellEdit={dataCellEdit}
            loading={isSaving}
          />
        ) : (
          <>
            <Button.Icon
              title="CONFIG_CURRENCY_FIXBUTTON_TITLE"
              icon={<Typography.Icon icon={item.is_default ? PushpinFilled : PushpinOutlined} />}
              onClick={() => {
                const pinId = item.id
                onSetPin({
                  editCurrencies: () =>
                    editCurrencies({
                      name: item.name,
                      code: item.code,
                      symbol: item.symbol,
                      description: item.description,
                      id_currency: pinId,
                      is_default: true,
                    }),
                  setPin: () => setPin({ id: item.id, is_default: true }),
                })
              }}
            />
            <Popconfirm
              placement="bottomRight"
              title={t('CONFIG_CURRENCY_DEACTIVATE_CURRENCY_POPCONFIRM_TEXT', {
                action: t('ACTION_DELETE').toLowerCase(),
              })}
              okText={t('ACTION_DELETE')}
              onConfirm={() =>
                onDelete({
                  setIsLoading: setIsSaving,
                  deleteCurrencies: () => deleteCurrencies(deleteId),
                  onClose: onConfirm,
                  onRefetch,
                })
              }
              okButtonProps={{ loading: isSaving }}
              cancelText={t('ACTION_CANCEL')}
              onCancel={() => setDeleteId(null)}
              visible={deleteId === item.id}
            >
              <DropdownMenu
                title={t('ACTION_MORE')}
                menu={[
                  {
                    title: t('ACTION_EDIT'),
                    icon: <EditOutlined />,
                    onClick: () => setCurrencyEdit(item.id),
                    disabled: !userCanActivate,
                  },
                  ...(!item.is_default
                    ? [
                        {
                          title: t('ACTION_DELETE'),
                          icon: <DeleteOutlined />,
                          onClick: () => setDeleteId(item.id),
                          disabled: !userCanActivate,
                        },
                      ]
                    : []),
                ]}
              />
            </Popconfirm>
          </>
        )}
      </Space>
    </div>
  )
}

const mapDispatchToProps = {
  editCurrencies: configuration.actions.editCurrencies,
  deleteCurrencies: configuration.actions.deleteCurrencies,
  setPin: configuration.actions.setPin,
}

export default connect(null, mapDispatchToProps)(CardBody)
