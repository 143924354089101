import React, { useEffect, useState } from 'react'
import { Input, Select, Space, Tooltip, Upload } from 'antd'
import { connect } from 'react-redux'
import { Button, Typography } from 'modules/core/components'
import { useTranslation } from 'react-i18next'
import { onConfirmUpload } from './utils'
import { generateSelectOptions } from 'modules/core/utils'
import upload from 'modules/uploadS3'
import emptyImage from 'assets/images/compositions/empty-small.svg'

import './UploadS3File.scss'

const { Dragger } = Upload

const UploadS3File = ({ uploadServices3, uploadUrlAws, fetchSocietiesList, folderS3List }) => {
  const [isUploadingFile, setIsUploadingFile] = useState(false)
  const [folderName, setFolderName] = useState('')
  const [folderNameSelected, setFolderNameSelected] = useState('')
  const [fileList, setFileList] = useState([])
  const [isFolderLoading, setIsFolderLoading] = useState(false)
  const { t } = useTranslation()

  useEffect(() => {
    setIsFolderLoading(true)
    fetchSocietiesList().then(() => setIsFolderLoading(false))
  }, [fetchSocietiesList])

  const onClose = () => {
    setFileList([])
    setFolderName('')
    setFolderNameSelected('')
    setIsUploadingFile(false)
  }

  const inputStyle = {
    paddingTop: 5,
    ...(folderS3List.length === 0 && { width: 350 }),
  }

  const renderExtraData = () => (
    <Space direction="vertical">
      <Typography.Body level={3}>
        {t('SOCIETY_SELECT_BUDGET_TYPE_TITLE', {
          selectText: folderS3List.length > 0 ? t('SOCIETY_SELECT_FORLDER_NAME_TITLE') : '',
        })}
      </Typography.Body>
      <Space direction="horizontal" style={{ display: 'flex', justifyContent: 'center' }}>
        <Input
          placeholder={t('ENTER_NAME_PLACEHOLDER')}
          style={inputStyle}
          value={folderName}
          disabled={folderNameSelected}
          onChange={(value) => setFolderName(value.target.value)}
        />
        {folderS3List.length > 0 && (
          <>
            <Typography.Body>{t('LABEL_OR')}</Typography.Body>
            <Select
              placeholder={t('ACTION_SELECT')}
              loading={isFolderLoading}
              allowClear
              style={{ width: 170 }}
              disabled={folderName}
              onChange={setFolderNameSelected}
              options={generateSelectOptions({
                options: folderS3List.map((data) => {
                  return { id: data, name: data }
                }),
              })}
            />
          </>
        )}
      </Space>
    </Space>
  )

  const onAddUploadFile = () => {
    return false
  }

  return (
    <div className="upload-container">
      <Space className="upload-dragger-screen" size="large" direction="vertical">
        <Space className="upload-dragger-screen-containter" size="large" direction="vertical">
          <div className="upload-dragger-title">
            <Typography.Headline level={5} type="primary">
              {t('EMPTY_STATE_DEFAULT_TITLE')}
            </Typography.Headline>
            <Typography.Body>{t('SOCIETY_UPLOAD_S3_FILE_TITLE')}</Typography.Body>
          </div>
          {renderExtraData()}
          <Dragger
            {...{
              name: 'file',
              accept: '.xls, .xlsx, .csv, .txt, tsv',
              beforeUpload: onAddUploadFile,
              multiple: true,
              onRemove: (file) => setFileList([]),
              onChange: (data) => setFileList(data.fileList),
              fileList: fileList,
            }}
          >
            <Tooltip
              title={
                !folderName && !folderNameSelected
                  ? t('SOCIETY_SELECT_BUDGET_TYPE_INFORMATIVE_TOOLTIP_TITLE', {
                      selectText:
                        folderS3List.length > 0
                          ? `${t('LABEL_OR')} ${t('ACTION_SELECT').toLowerCase()}`
                          : '',
                    })
                  : ''
              }
              placement="right"
            >
              <Space size="large" direction="vertical">
                <img alt="img" src={emptyImage} />
                <div className="upload-dragger-title">
                  <Typography.Body type="title">{t('EMPTY_STATE_DRAG_FILE_TEXT')}</Typography.Body>
                  <div className="upload-dragger-body-1"></div>
                  {t('INFO_UPLOAD_TOOLTIP')}
                </div>
              </Space>
            </Tooltip>
          </Dragger>
          <Button.Primary
            onClick={() =>
              onConfirmUpload({
                setIsLoading: setIsUploadingFile,
                fileList,
                uploadServices3: (file) =>
                  uploadServices3(
                    { file_name: file.name, type_of_file: folderName || folderNameSelected },
                    { 'content-type': file.type },
                  ),
                uploadUrlAws,
                onClose,
              })
            }
            loading={isUploadingFile}
            disabled={fileList.length <= 0}
            title="ACTION_UPLOAD_START"
          />
        </Space>
      </Space>
    </div>
  )
}

const mapStateToProps = (state) => ({
  folderS3List: upload.selectors.getFolderS3List(state),
})

const mapDispatchToProps = {
  uploadServices3: upload.actions.uploadServices3,
  uploadUrlAws: upload.actions.uploadUrlAws,
  fetchSocietiesList: upload.actions.fetchSocietiesList,
}

export default connect(mapStateToProps, mapDispatchToProps)(UploadS3File)
