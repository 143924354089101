import { notification, message } from 'antd'
import { generateMessageError } from 'modules/core/utils'
import i18n from 'i18next'

export const onCreateReport = ({ form, setIsLoading, createReportByFile, onRefetch, onClose }) => {
  form
    .validateFields()
    .then((values) => {
      setIsLoading(true)
      const data = {
        ...values,
        type_file: 'REPORT_CONFIG',
      }
      createReportByFile(data)
        .then(() => {
          onRefetch().then(() => {
            message.success(i18n.t('FEEDBACK_CHANGES_SAVED_SUCCESS'), 8)
            onClose()
          })
        })
        .catch((error) => {
          notification.error({
            message: i18n.t('FEEDBACK_DEFAULT_ERROR'),
            description: generateMessageError(error, 'FEEDBACK_CREATION_FAIL'),
            duration: 0,
          })
          onClose()
        })
    })
    .catch((e) => {})
}
