import { useFeatureIsOn } from '@growthbook/growthbook-react'
import React from 'react'
import { Tabs } from 'antd'
import { REPORT } from 'modules/core/constants'
import { useTranslation } from 'react-i18next'
import { Comparative, Evolution, Variation } from './components'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import planning from 'modules/planning'

const TabsReports = ({
  periodList,
  isEvolutionLoading,
  iscomparativeLoading,
  filters,
  selectedDates,
  groupProjection,
  periodTableSettings,
  setPeriodTableSettings,
  selectedProjection,
  setSelectedProjection,
  referenceBase,
  setReferenceBase,
  referenceComparison,
  setReferenceComparison,
  keyComparisonTable,
  setKeyComparisonTable,
  setTablePagination,
  tablePagination,
  onRefetch,
  tabKey,
  setTabKey,
  lineVertical,
  setLineVertical,
  onRefetchComparative,
  onRefetchEvolution,
  setDataComments,
  dataComments,
  onRefetchComments,
  loadingComments,
  isProgressLoading,
  showProgressBar,
  commentKey,
  currencyData,
  referenceBaseVariant,
  setReferenceBaseVariant,
  referenceComparisonVariant,
  setReferenceComparisonVariant,
}) => {
  let { periodId } = useParams()
  const { t } = useTranslation()
  const dataPeriod = periodList.find((el) => el.id === parseInt(periodId))
  const enabledVariant = useFeatureIsOn('feature-variant-analysis')

  return (
    <Tabs defaultActiveKey={tabKey} onChange={setTabKey} size="small">
      <Tabs.TabPane tab={t('FIELD_EVOLUTION')} key={REPORT.REPORTS_KEYS.EVOLUTION_KEY}>
        {tabKey === REPORT.REPORTS_KEYS.EVOLUTION_KEY && (
          <Evolution
            loading={isEvolutionLoading}
            selectedFilters={filters}
            selectedDates={selectedDates}
            selectedPeriod={dataPeriod}
            groupProjection={groupProjection}
            periodTableSettings={periodTableSettings}
            setPeriodTableSettings={setPeriodTableSettings}
            selectedProjection={selectedProjection}
            setSelectedProjection={setSelectedProjection}
            setTablePagination={setTablePagination}
            tablePagination={tablePagination}
            onRefetch={onRefetch}
            lineVertical={lineVertical}
            setLineVertical={setLineVertical}
            onRefetchEvolution={onRefetchEvolution}
            setDataComments={setDataComments}
            dataComments={dataComments}
            onRefetchComments={onRefetchComments}
            loadingComments={loadingComments}
            isProgressLoading={isProgressLoading}
            showProgressBar={showProgressBar}
            commentKey={commentKey}
            currencyData={currencyData}
          />
        )}
      </Tabs.TabPane>
      <Tabs.TabPane tab={t('FIELD_COMPARISONS')} key={REPORT.REPORTS_KEYS.COMPARISON_KEY}>
        {tabKey === REPORT.REPORTS_KEYS.COMPARISON_KEY && (
          <Comparative
            loading={iscomparativeLoading}
            selectedFilters={filters}
            selectedDates={selectedDates}
            selectedPeriod={dataPeriod}
            groupProjection={groupProjection}
            periodTableSettings={periodTableSettings}
            setPeriodTableSettings={setPeriodTableSettings}
            referenceBase={referenceBase}
            setReferenceBase={setReferenceBase}
            referenceComparison={referenceComparison}
            setReferenceComparison={setReferenceComparison}
            keyComparisonTable={keyComparisonTable}
            setKeyComparisonTable={setKeyComparisonTable}
            onRefetchComparative={onRefetchComparative}
            setDataComments={setDataComments}
            dataComments={dataComments}
            onRefetchComments={onRefetchComments}
            loadingComments={loadingComments}
            isProgressLoading={isProgressLoading}
            showProgressBar={showProgressBar}
            commentKey={commentKey}
            currencyData={currencyData}
          />
        )}
      </Tabs.TabPane>
      {enabledVariant && (
        <Tabs.TabPane tab={t('FIELD_VARIATION_PXQ')} key={REPORT.REPORTS_KEYS.VARIATION_KEY}>
          {tabKey === REPORT.REPORTS_KEYS.VARIATION_KEY && (
            <Variation
              loading={iscomparativeLoading}
              groupProjection={groupProjection}
              referenceBase={referenceBaseVariant}
              setReferenceBase={setReferenceBaseVariant}
              referenceComparison={referenceComparisonVariant}
              setReferenceComparison={setReferenceComparisonVariant}
              onRefetchComparative={onRefetchComparative}
              setDataComments={setDataComments}
              dataComments={dataComments}
              onRefetchComments={onRefetchComments}
              loadingComments={loadingComments}
              commentKey={commentKey}
              currencyData={currencyData}
              isProgressLoading={isProgressLoading}
              showProgressBar={showProgressBar}
            />
          )}
        </Tabs.TabPane>
      )}
    </Tabs>
  )
}

const mapStateToProps = (state) => ({
  periodList: planning.selectors.getPeriodList(state),
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(TabsReports)
