import React from 'react'
import { Button, Typography } from 'modules/core/components'
import { MessageOutlined } from '@ant-design/icons'

const ShowCommentsButton = ({ onClick }) => {
  return (
    <Button.Icon
      icon={<Typography.Icon icon={MessageOutlined} level={1} />}
      onClick={onClick}
      title="ACTION_SHOW_COMMENTS"
    />
  )
}

export default ShowCommentsButton
