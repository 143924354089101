//  This must be the first line in src/index.js, is to support IE 11.
import 'react-app-polyfill/ie11'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
import { sentryInit } from './libs/sentry'
import { App } from './modules/app'
import store from './store'
import * as serviceWorker from './serviceWorker'
import TagManager from 'react-gtm-module'

// normalize styles between browsers
import 'normalize.css'
import './styles/plika-theme.css'
// import 'antd/dist/antd.css';
import './index.scss'

const rootElement = document.getElementById('root')

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_TAG_MANAGER_API_KEY || 'GTM-K58WWK3F',
}

TagManager.initialize(tagManagerArgs)

sentryInit()

function render(AppComponent) {
  ReactDOM.render(
    <Provider store={store}>
      <Router>
        <AppComponent />
      </Router>
    </Provider>,
    rootElement,
  )
}

render(App)

serviceWorker.unregister()
