const MODULE_PLANNING = 'planning'
const MODULE_FORECAST = 'forecast'
const MODULE_PLANNING_OVERALL = 'planning_overall_budget'
const MODULE_FORECAST_OVERALL = 'forecast_overall_budget'

const MODULES_KEYS = {
  MODULE_PLANNING: MODULE_PLANNING,
  MODULE_FORECAST: MODULE_FORECAST,
  MODULE_PLANNING_OVERALL: MODULE_PLANNING_OVERALL,
  MODULE_FORECAST_OVERALL: MODULE_FORECAST_OVERALL,
}

const UPLOAD_DATE_FORMAT = [
  { id: 'mm/dd/yyyy', name: 'mm/dd/yyyy' },
  { id: 'dd/mm/yyyy', name: 'dd/mm/yyyy' },
  { id: 'yyyy/mm/dd', name: 'yyyy/mm/dd' },
  { id: 'yyyy/dd/mm', name: 'yyyy/dd/mm' },
  { id: 'mm-dd-yyyy', name: 'mm-dd-yyyy' },
  { id: 'dd-mm-yyyy', name: 'dd-mm-yyyy' },
  { id: 'yyyy-mm-dd', name: 'yyyy-mm-dd' },
  { id: 'yyyy-dd-mm', name: 'yyyy-dd-mm' },
  { id: 'yyyy-mm', name: 'yyyy-mm' },
  { id: 'mm-yyyy', name: 'mm-yyyy' },
]

const OPTION_COMMENT = [
  {
    key: 'comment',
    label: 'ACTION_COMMENT',
  },
]

const GENERAL = {
  MODULES_KEYS,
  UPLOAD_DATE_FORMAT,
  OPTION_COMMENT,
}

export default GENERAL
