import React, { useState } from 'react'
import { Collapse } from 'antd'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import GENERAL from '../../../../../constants/general'
import { handleAction, handleSave } from './utils'
import { CollapseBody, ExtraPanel } from './components'
import Typography from 'modules/core/components/Typography/Typography'
import configuration from 'modules/configuration'
import planning from 'modules/planning'

const { Panel } = Collapse

const CollapseRules = ({
  ruleList,
  canCreate,
  onRefetch,
  onRefetchRules,
  applyRule,
  unApplyRule,
  disabled,
  setVariablesValues,
  saveVariableValues,
  typeModule = GENERAL.MODULES_KEYS.MODULE_PLANNING,
  moduleId = 0,
}) => {
  const [variableId, setVariableId] = useState(null)
  const [showPopconfirm, setShowPopconfirm] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  let { periodId } = useParams()
  const { t } = useTranslation()

  const onCreate = (ruleId, variable) => {
    setVariablesValues({
      ruleId,
      variableId: variable.id,
      dataVariable: { originValues: variable.values },
    })
    return saveVariableValues(ruleId, variable.id, {
      period: periodId,
      value_by_month: variable.values,
      type: typeModule,
      pk: moduleId,
    })
  }

  return (
    <Collapse accordion>
      {ruleList.values.map((rule) => {
        return (
          <Panel
            header={rule.name}
            key={rule.id}
            extra={
              <ExtraPanel
                disabled={
                  !canCreate ||
                  disabled ||
                  rule.variables.some(
                    (data) => JSON.stringify(data.values) !== JSON.stringify(data.originValues),
                  )
                }
                onChange={(checked) => {
                  const auxAction = checked ? () => applyRule(rule.id) : () => unApplyRule(rule.id)
                  const text = checked
                    ? t('RULES_APPLY_TEXT').toLowerCase()
                    : t('RULES_UNAPPLY_TEXT').toLowerCase()
                  handleAction({
                    setIsLoading: setIsSaving,
                    action: auxAction,
                    onRefetchRules,
                    onRefetch,
                    text,
                    onClose: () => setIsSaving(false),
                  })
                }}
                loading={isSaving}
                isApplied={rule.isApplied}
              />
            }
          >
            <Typography.Body>{`${t('LABEL_FORMULA')} : ${rule.formula.slice(7)}`}</Typography.Body>
            {rule.variables.map((variable) => (
              <Collapse accordion key={variable.id}>
                <Panel header={variable.name} key={variable.id}>
                  <CollapseBody
                    values={variable.values}
                    originValues={variable.originValues}
                    disableInput={!canCreate || disabled}
                    onChangeInput={(val, month) =>
                      setVariablesValues({
                        ruleId: rule.id,
                        variableId: variable.id,
                        dataVariable: { values: { ...variable.values, [month]: val } },
                      })
                    }
                    onChangePopConfirm={() => {
                      setVariablesValues({
                        ruleId: rule.id,
                        variableId: variable.id,
                        dataVariable: { values: variable.originValues },
                      })
                      setShowPopconfirm(false)
                      setVariableId(null)
                    }}
                    onCancelPopConfirm={() => {
                      setShowPopconfirm(false)
                      setVariableId(null)
                    }}
                    visiblePopconfirm={variable.id === variableId && showPopconfirm}
                    disableCnacel={isSaving}
                    onClickCancel={() => {
                      setShowPopconfirm(true)
                      setVariableId(variable.id)
                    }}
                    onClickSave={() =>
                      handleSave({
                        setIsLoading: setIsSaving,
                        saveVariableValues: () => onCreate(rule.id, variable),
                        onClose: () => setIsSaving(false),
                      })
                    }
                    loading={isSaving}
                    disableSave={variable.id === variableId && showPopconfirm}
                  />
                </Panel>
              </Collapse>
            ))}
          </Panel>
        )
      })}
    </Collapse>
  )
}

const mapDispatchToProps = {
  setVariablesValues: configuration.actions.setVariablesValues,
  saveVariableValues: planning.actions.saveVariableValues,
}

export default connect(null, mapDispatchToProps)(CollapseRules)
