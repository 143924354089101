import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { Col, Row } from 'antd'
import { connect } from 'react-redux'
import { ListKPIs, LoadingKPIs } from './components'
import planning from 'modules/planning'

import './KPIsGlobalDetail.scss'

const KPIsGlobalDetail = ({
  periodId,
  globalBudgetId,
  budget_type,
  globalBudgetKpi,
  fetchGlobalBudgetKpi,
  setDataComments,
  dataComments,
  onRefetchComments,
  loadingComments,
  currencyData,
}) => {
  const [isKpiLoading, setIsKpiLoading] = useState(false)

  useEffect(() => {
    if (currencyData) {
      setIsKpiLoading(true)
      fetchGlobalBudgetKpi(periodId, globalBudgetId, {
        budget_type,
        currency_id: currencyData.id,
      }).then(() => setIsKpiLoading(false))
    }
  }, [fetchGlobalBudgetKpi, periodId, globalBudgetId, budget_type, currencyData])

  return (
    <Row gutter={24}>
      {isKpiLoading && <LoadingKPIs />}
      {!isKpiLoading && !_.isEmpty(globalBudgetKpi[0]) && (
        <Col span={24}>
          <ListKPIs
            globalBudgetKPIS={globalBudgetKpi}
            setDataComments={setDataComments}
            dataComments={dataComments}
            onRefetchComments={onRefetchComments}
            loadingComments={loadingComments}
            currencyData={currencyData}
          />
        </Col>
      )}
    </Row>
  )
}

const mapStateToProps = (state) => ({
  globalBudgetKpi: planning.selectors.getGlobalBudgetKpi(state),
})

const mapDispatchToProps = {
  fetchGlobalBudgetKpi: planning.actions.fetchGlobalBudgetKpi,
}

export default connect(mapStateToProps, mapDispatchToProps)(KPIsGlobalDetail)
