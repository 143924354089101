const SecurityPolicy = {
  SECURITY_POLICY_TERMS_BUTTON_TITLE: 'Privacy policy and terms',
  SECURITY_POLICY_PRIVACY_POLICY_TAB_TITLE: 'Privacy policies',
  SECURITY_POLICY_TERMS_CONDITIONS_TAB_TITLE: 'Terms and conditions',
  SECURITY_POLICY_PRIVACY_TEXT_1:
    'The website getplika.com is owned by Bluedraft, which is a data controller for your personal data. We have adopted this Privacy Policy, which outlines how we process information collected by getplika.com, and also provides the reasons why we need to collect certain personal data about you. Therefore, you should read this Privacy Policy before using the getplika.com website. We care about your personal data and are committed to ensuring its confidentiality and security.',
  SECURITY_POLICY_PRIVACY_SUBTITLE_1: 'Personal Information We Collect',
  SECURITY_POLICY_PRIVACY_TEXT_2: `When you visit getplika.com, we automatically collect certain information about your device, including information about your web browser, IP address, time zone, and some of the cookies installed on your device. Additionally, as you browse, we collect information about individual web pages or products you view, which websites or search terms referred you to the website, and how you interact. We refer to this automatically collected information as 'Device Information.' Additionally, we may collect personal data you provide to us (including, among others, name, surname, address, payment information, etc.) during registration to fulfill the agreement.`,
  SECURITY_POLICY_PRIVACY_SUBTITLE_2: 'Why We Process Your Data?',
  SECURITY_POLICY_PRIVACY_TEXT_3: `Our top priority is the security of customer data, and as such, we may only process the minimum user data, only to the extent absolutely necessary to maintain the website. The automatically collected information is used only to identify potential cases of abuse and to provide statistical information about the use of the website. This statistical information is not aggregated in a way that identifies any particular user of the system. You can visit the website without telling us who you are or revealing any information by which someone can identify you as a specific person. However, if you want to use some of the website's features, or you want to receive our newsletter or provide other details by completing a form, you can provide us with personal data, such as your email, name, surname, city of residence, organization, and phone number. You can choose not to provide your personal data, but you may not be able to take advantage of some of the website's features. For example, you won't be able to receive our newsletter or contact us directly from the website. Users unsure of what information is mandatory can contact us at social@getplika.com.`,
  SECURITY_POLICY_PRIVACY_SUBTITLE_3: 'Your Rights',
  SECURITY_POLICY_PRIVACY_TEXT_4:
    'If you are a resident of Europe, you have the following rights regarding your personal data:',
  SECURITY_POLICY_PRIVACY_LIST_TEXT_1: 'The right to be informed.',
  SECURITY_POLICY_PRIVACY_LIST_TEXT_2: 'The right of access.',
  SECURITY_POLICY_PRIVACY_LIST_TEXT_3: 'The right to rectification.',
  SECURITY_POLICY_PRIVACY_LIST_TEXT_4: 'The right to erasure.',
  SECURITY_POLICY_PRIVACY_LIST_TEXT_5: 'The right to restrict processing.',
  SECURITY_POLICY_PRIVACY_LIST_TEXT_6: 'The right to data portability.',
  SECURITY_POLICY_PRIVACY_LIST_TEXT_7: 'The right to object.',
  SECURITY_POLICY_PRIVACY_LIST_TEXT_8: 'Rights regarding automated decision-making and profiling.',
  SECURITY_POLICY_PRIVACY_TEXT_5:
    'If you wish to exercise these rights, please contact us through the contact information below. Additionally, if you are a European resident, please note that we process your information to fulfill contracts we might have with you (for example, if you place an order through the website) or otherwise to pursue our legitimate business interests as listed above. Also, be aware that your information may be transferred outside of Europe, including to Canada and the United States.',
  SECURITY_POLICY_PRIVACY_SUBTITLE_4: 'Links to Other Websites',
  SECURITY_POLICY_PRIVACY_TEXT_6:
    'Our website may contain links to other websites that are not owned or controlled by us. Please note that we are not responsible for such websites or the privacy practices of third parties. We recommend that you be aware when leaving our website and read the privacy statements of each website that may collect personal information.',
  SECURITY_POLICY_PRIVACY_SUBTITLE_5: 'Information Security',
  SECURITY_POLICY_PRIVACY_TEXT_7:
    'We secure the information you provide on computer servers in a controlled, secure environment, protected from unauthorized access, use, or disclosure. We maintain reasonable administrative, technical, and physical security measures to protect against unauthorized access, use, alteration, and disclosure of personal data under our control and custody. However, the transmission of data over the Internet or wireless networks cannot be guaranteed.',
  SECURITY_POLICY_PRIVACY_SUBTITLE_6: 'Legal Disclosure',
  SECURITY_POLICY_PRIVACY_TEXT_8:
    'We will disclose any information we collect, use, or receive if required or permitted by law, such as to comply with a subpoena or similar legal process, and when we believe in good faith that disclosure is necessary to protect our rights, protect your safety or the safety of others, investigate fraud, or respond to a government request.',
  SECURITY_POLICY_PRIVACY_SUBTITLE_7: 'Contact Information',
  SECURITY_POLICY_PRIVACY_TEXT_9:
    'If you wish to contact us to understand more about this Policy or contact us regarding any matter related to individual rights and your personal information, you can email us at social@getplika.com.',
  SECURITY_POLICY_TERMS_TEXT_1: 'Welcome to getplika!',
  SECURITY_POLICY_TERMS_TEXT_2:
    'These terms and conditions describe the rules and regulations for the use of the Bluedraft website, located at https://getplika.com. By accessing this website, we assume that you accept these terms and conditions. Do not continue to use getplika if you do not agree with all the terms and conditions set forth on this page.',
  SECURITY_POLICY_TERMS_SUBTITLE_1: 'Cookies',
  SECURITY_POLICY_TERMS_TEXT_3:
    'The website uses cookies to help personalize your online experience. By accessing getplika, you agreed to use necessary cookies. A cookie is a text file that a web page server places on your hard drive. Cookies cannot be used to run programs or deliver viruses to your computer. Cookies are uniquely assigned to you, and only a web server in the domain that issued the cookie can read them. We may use cookies to collect, store, and track information for statistical or marketing purposes to operate our website. You have the ability to accept or decline optional cookies. There are some mandatory cookies that are necessary for the operation of our website. These cookies do not require your consent as they always function. Please note that by accepting the required cookies, you also accept third-party cookies, which may be used through services provided by third parties if you use such services on our website, for example, a video display window provided by third parties and integrated into our website.',
  SECURITY_POLICY_TERMS_SUBTITLE_2: 'License',
  SECURITY_POLICY_TERMS_TEXT_4:
    'Unless otherwise stated, Bluedraft and/or its licensors own the intellectual property rights for all material on getplika. All intellectual property rights are reserved. You may access getplika for your personal use subject to restrictions set in these terms and conditions.',
  SECURITY_POLICY_TERMS_TEXT_5: 'You must not:',
  SECURITY_POLICY_TERMS_LIST_MUST_TEXT_1: 'Copy or republish material from getplika',
  SECURITY_POLICY_TERMS_LIST_MUST_TEXT_2: 'Sell, rent, or sublicense material from getplika',
  SECURITY_POLICY_TERMS_LIST_MUST_TEXT_3: 'Reproduce, duplicate, or copy material from getplika',
  SECURITY_POLICY_TERMS_LIST_MUST_TEXT_4: 'Redistribute content from getplika',
  SECURITY_POLICY_TERMS_TEXT_6: 'This Agreement shall begin on the present date.',
  SECURITY_POLICY_TERMS_TEXT_7:
    'Parts of this website offer users the opportunity to post and exchange opinions and information in certain areas. Bluedraft does not filter, edit, publish, or review comments prior to their presence on the website. Comments do not reflect the views or opinions of Bluedraft, its agents, and/or affiliates. Comments reflect the views and opinions of the person who posts. To the extent permitted by applicable laws, Bluedraft shall not be liable for comments or for any liability, damages, or expenses caused and/or suffered as a result of any use or posting or appearance of comments on this website.',
  SECURITY_POLICY_TERMS_TEXT_8:
    'Bluedraft reserves the right to monitor all comments and to remove any that may be considered inappropriate, offensive, or violate these Terms and Conditions.',
  SECURITY_POLICY_TERMS_TEXT_9: 'You warrant and represent that:',
  SECURITY_POLICY_TERMS_LIST_REPRESENT_TEXT_1:
    'You have the right to post comments on our website and have all the necessary licenses and consents to do so;',
  SECURITY_POLICY_TERMS_LIST_REPRESENT_TEXT_2:
    'The comments do not invade any intellectual property rights, including, among others, copyright, patents, or trademarks of third parties;',
  SECURITY_POLICY_TERMS_LIST_REPRESENT_TEXT_3:
    'The comments do not contain any defamatory, libelous, offensive, indecent, or otherwise illegal material that is an invasion of privacy.',
  SECURITY_POLICY_TERMS_LIST_REPRESENT_TEXT_4:
    'The comments will not be used to solicit or promote custom or present commercial businesses or illegal activities.',
  SECURITY_POLICY_TERMS_TEXT_10:
    'By hereby granting Bluedraft a non-exclusive license to use, reproduce, edit, and authorize others to use, reproduce, and edit any of your comments in any and all forms, formats, or media.',
  SECURITY_POLICY_TERMS_SUBTITLE_3: 'Hyperlinks to our content',
  SECURITY_POLICY_TERMS_TEXT_11:
    'The following organizations may link to our website without prior written approval:',
  SECURITY_POLICY_TERMS_LIST_HIPERLINK_TEXT_1: 'Government agencies;',
  SECURITY_POLICY_TERMS_LIST_HIPERLINK_TEXT_2: 'Search engines;',
  SECURITY_POLICY_TERMS_LIST_HIPERLINK_TEXT_3: 'News organizations;',
  SECURITY_POLICY_TERMS_LIST_HIPERLINK_TEXT_4:
    'Online directory distributors may link to our website in the same manner as they hyperlink to the websites of other listed businesses; and',
  SECURITY_POLICY_TERMS_LIST_HIPERLINK_TEXT_5:
    'System-wide accredited businesses, except soliciting non-profit organizations, charity shopping malls, and charity fundraising groups which may not hyperlink to our website.',
  SECURITY_POLICY_TERMS_TEXT_12: `These organizations may link to our home page, to publications, or to other website information so long as the link: (a) is not misleading in any way; (b) does not falsely imply sponsorship, endorsement, or approval of the linking party and its products and/or services; and (c) fits within the context of the linking party's site.`,
  SECURITY_POLICY_TERMS_TEXT_13:
    'We may consider and approve other link requests from the following types of organizations:',
  SECURITY_POLICY_TERMS_LIST_HIPERLINK_TEXT_6: 'Consumer and/or business information sources;',
  SECURITY_POLICY_TERMS_LIST_HIPERLINK_TEXT_7: 'dot.com community sites;',
  SECURITY_POLICY_TERMS_LIST_HIPERLINK_TEXT_8:
    'associations or other groups representing charities;',
  SECURITY_POLICY_TERMS_LIST_HIPERLINK_TEXT_9: 'online directory distributors;',
  SECURITY_POLICY_TERMS_LIST_HIPERLINK_TEXT_10: 'internet portals;',
  SECURITY_POLICY_TERMS_LIST_HIPERLINK_TEXT_11: 'accounting, law and consulting firms; and',
  SECURITY_POLICY_TERMS_LIST_HIPERLINK_TEXT_12: 'educational institutions and trade associations.',
  SECURITY_POLICY_TERMS_TEXT_14:
    'We will approve link requests from these organizations if: (a) the link would not make us look unfavorably to ourselves or to our accredited businesses; (b) the organization does not have any negative records with us; (c) the benefit to us from the visibility of the hyperlink compensates the absence of Bluedraft; and (d) the link is in the context of general resource information.',
  SECURITY_POLICY_TERMS_TEXT_15: `These organizations may link to our home page as long as the link: (a) is not misleading in any way; (b) does not falsely imply sponsorship, endorsement, or approval of the linking party and its products or services; and (c) fits within the context of the linking party's site.`,
  SECURITY_POLICY_TERMS_TEXT_16:
    'If you are one of the organizations listed in paragraph 2 and are interested in linking to our website, you must inform us by sending an email to Bluedraft. Please include your name, your organization name, contact information, as well as the URL of your site, a list of any URLs from which you intend to link to our site, and a list of the URLs on our site to which you would like to access. Please allow 2-3 weeks for a response.',
  SECURITY_POLICY_TERMS_TEXT_17:
    'Approved organizations may hyperlink to our website in the following manner:',
  SECURITY_POLICY_TERMS_LIST_HIPERLINK_TEXT_13: 'By using our corporate name; or',
  SECURITY_POLICY_TERMS_LIST_HIPERLINK_TEXT_14:
    'By using the uniform resource locator being linked to; or',
  SECURITY_POLICY_TERMS_LIST_HIPERLINK_TEXT_15: `By using any other description of our website being linked to that makes sense within the context and format of content on the linking party's site.`,
  SECURITY_POLICY_TERMS_TEXT_18: `No use of Bluedraft's logo or other artwork will be allowed for linking absent a trademark license agreement.`,
  SECURITY_POLICY_TERMS_SUBTITLE_4: 'Responsability on the content',
  SECURITY_POLICY_TERMS_TEXT_19:
    'We will not be liable for any content that appears on your website. You agree to indemnify and defend us against all claims arising on your website. No link(s) shall appear on any website that could be construed as defamatory, obscene, or criminal, or that otherwise infringes, violates, or advocates the infringement or other violation of third-party rights.',
  SECURITY_POLICY_TERMS_SUBTITLE_5: 'Rights reserved',
  SECURITY_POLICY_TERMS_TEXT_20:
    'We reserve the right to request that you remove all links or any particular link to our website. You agree to immediately remove all links to our website upon request. We also reserve the right to modify these terms and conditions and your linking policy at any time. By continuously linking to our website, you agree to be bound and abide by these linking terms and conditions.',
  SECURITY_POLICY_TERMS_SUBTITLE_6: 'Removal of links from our website',
  SECURITY_POLICY_TERMS_TEXT_21:
    'If you come across any link on our site that is offensive for any reason, you can contact us and let us know at any time. We will consider requests to remove links, but we are not obligated to do so or to respond directly.',
  SECURITY_POLICY_TERMS_TEXT_22:
    'We do not ensure that the information on this website is correct. We do not guarantee its completeness or accuracy, nor do we promise to ensure that the website remains available or that the material on the site is kept up to date.',
  SECURITY_POLICY_TERMS_SUBTITLE_7: 'Responsibilities exceptions',
  SECURITY_POLICY_TERMS_TEXT_23:
    'To the maximum extent permitted by applicable law, we exclude all representations, warranties, and conditions related to our website and the use of it. Nothing.',
  SECURITY_POLICY_TERMS_LIST_RESPONSIBILITIES_TEXT_1:
    'limit or exclude our or your liability for death or personal injury;',
  SECURITY_POLICY_TERMS_LIST_RESPONSIBILITIES_TEXT_2:
    'limit or exclude our or your liability for fraud or fraudulent misrepresentation;',
  SECURITY_POLICY_TERMS_LIST_RESPONSIBILITIES_TEXT_3:
    'limit any of our or your liabilities in any way that is not permitted under applicable law; or',
  SECURITY_POLICY_TERMS_LIST_RESPONSIBILITIES_TEXT_4:
    'exclude any of our or your liabilities that may not be excluded under applicable law.',
  SECURITY_POLICY_TERMS_TEXT_24:
    'in this disclaimer will: The limitations and prohibitions of liability set forth in this section and elsewhere in this disclaimer: (a) are subject to the preceding paragraph; and (b) govern all liabilities arising under the disclaimer or in relation to the subject matter of this disclaimer, including liabilities arising in contract, in tort, and for breach of statutory duty.',
  SECURITY_POLICY_TERMS_TEXT_25:
    'As long as the website and the information and services on the website are provided free of charge, we will not be liable for any loss or damage of any nature.',
}

export default SecurityPolicy
