const ACCOUNTS_LABEL = 'Cuentas'
const ACCOUNTS_ID = 1
const ACCOUNT_LABEL = 'Cuenta'

const ACCOUNTS = {
  id: ACCOUNTS_ID,
  label: ACCOUNTS_LABEL,
}

const REPORTS_LABEL = 'Reportes'
const REPORTS_ID = 10001

const REPORTS = {
  id: REPORTS_ID,
  label: REPORTS_LABEL,
  url: '/reports/get_all_reports_structure/',
}

const REPORTS_CUSTOM_LABEL = 'Reportes personalizados'
const REPORTS_CUSTOM_ID = 10002

const REPORTS_CUSTOM = {
  id: REPORTS_CUSTOM_ID,
  label: REPORTS_CUSTOM_LABEL,
  url: '/report/custom',
}

const ALL_FIELDS_ID = 0
// NOTE: No deberia usarse, quedo legacy por la parte de factores
const ALL_FIELDS_ID_STRING = '0'

const LEVEL_KEY = 'Nivel'

const OPTIONS_CREATE = [
  {
    id: 'new_dimension',
    name: 'CONFIG_DIMENSIONS_TYPE_CREATION_NEW_SELECT_OPTION',
  },
  {
    id: 'dimension_plika',
    name: 'HOME_INITIAL_STEPS_SELECT_DATA_DIMENSIONS_PLIKA_SELECT_TITLE',
  },
]

const DIMENSIONS = {
  ACCOUNTS,
  ACCOUNT_LABEL,
  REPORTS,
  REPORTS_CUSTOM,
  ALL_FIELDS_ID,
  ALL_FIELDS_ID_STRING,
  LEVEL_KEY,
  OPTIONS_CREATE,
}

export default DIMENSIONS
