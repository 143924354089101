import React from 'react'
import { Popconfirm } from 'antd'
import { DropdownMenu } from 'modules/core/components'
import { useTranslation } from 'react-i18next'
import { EditOutlined, DeleteOutlined } from '@ant-design/icons'
import _ from 'lodash'
import { ROLES } from 'modules/core/constants'
import { isUserAllowed } from 'modules/core/utils'

const { SETTINGS__BUDGET_TYPE__ABM } = ROLES

const TableActions = ({ onConfirmDelete, onCancelDelete, visible, onClickEdit, onClickDelete }) => {
  const { t } = useTranslation()
  return (
    <Popconfirm
      placement="bottomRight"
      title={t('CONFIG_CONCEPT_POPCONFIRM_DELETE_TITLE')}
      okText={t('ACTION_DELETE')}
      onConfirm={onConfirmDelete}
      cancelText={t('ACTION_CANCEL')}
      onCancel={onCancelDelete}
      visible={visible}
    >
      <DropdownMenu
        title={t('ACTION_MORE')}
        menu={_.compact([
          {
            title: t('ACTION_EDIT'),
            icon: <EditOutlined />,
            onClick: onClickEdit,
            disabled: !isUserAllowed(SETTINGS__BUDGET_TYPE__ABM),
          },
          {
            title: t('ACTION_DELETE'),
            icon: <DeleteOutlined />,
            onClick: onClickDelete,
            disabled: !isUserAllowed(SETTINGS__BUDGET_TYPE__ABM),
          },
        ])}
      />
    </Popconfirm>
  )
}

export default TableActions
