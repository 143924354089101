import React, { useEffect, useState } from 'react'
import { SiderLayout } from 'modules/core/layouts'
import {
  Button,
  Spin,
  Tooltip,
  Row,
  Col,
  Collapse,
  Space,
  message,
  notification,
  Popconfirm,
  Alert,
} from 'antd'
import {
  ButtonActionBar,
  Typography,
  EmptyScreen,
  PeriodNavigator,
  InputMonth,
  CreationExitModal,
} from 'modules/core/components'
import { useHistory, useParams, Prompt } from 'react-router-dom'
import { LeftCircleOutlined } from '@ant-design/icons'
import { CONCEPTS, PROJECTION } from 'modules/core/constants'
import { generateMessageError, isApproved } from 'modules/core/utils'
import { connect } from 'react-redux'
import planning from 'modules/planning'
import { useTranslation } from 'react-i18next'
import emptyStateImage from 'assets/images/compositions/empty-big.svg'
import _ from 'lodash'
import configuration from 'modules/configuration'
import control from 'modules/control'

import './ExchangeRateScreen.scss'

const { Panel } = Collapse

const isForecast = (type) => type === PROJECTION.FORECAST

const PLANNING_KEY = 'planificacion'

const ExchangeRateScreen = ({
  fetchProjectionDetail,
  projectionDetail,
  fetchExchangeRateList,
  fetchMonthsList,
  monthsList,
  exchangeRateList,
  saveExchangeRate,
  setExchangeRateValues,
  fetchCurrencyList,
  currencyList,
  fetchMonthsToApprove,
  monthsToApprove,
  fetchApproveDates,
  approveDates,
}) => {
  const [isScreenLoading, setIsScreenLoading] = useState(false)
  const [isExchangeRateLoading, setIsExchangeRateLoading] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [showPopconfirm, setShowPopconfirm] = useState(false)
  const [showExitModal, setShowExitModal] = useState(false)
  const [lastLocation, setLastLocation] = useState(null)
  const [confirmedNavigation, setConfirmedNavigation] = useState(false)
  const { t } = useTranslation()
  let history = useHistory()
  let { periodId, budgetId } = useParams()

  //NOTE: QUEDA ACA ARRIBA PORQUE SE UTILIZA EN EL USEEFFECT
  const functionalCurrencyData = currencyList.data.find(
    (currency) => currency.value_type === PROJECTION.FUNCTIONAL_KEY && currency.predefined,
  )

  useEffect(() => {
    setIsScreenLoading(true)
    Promise.all([
      fetchMonthsList(periodId),
      fetchProjectionDetail(budgetId),
      fetchCurrencyList(),
    ]).then(() => setIsScreenLoading(false))
  }, [fetchMonthsList, fetchCurrencyList, periodId, budgetId, fetchProjectionDetail])

  //TODO: AJUSTAR PARAMS (ECONOMICO Y FINANCIERO SEGUN DONDE ESTOY PARADA) CUANDO ESTE LO DE FINANCIERO SEGUIMIENTO
  useEffect(() => {
    Promise.all([
      fetchMonthsToApprove(periodId, {
        concept_type: CONCEPTS.TYPE.ECONOMIC_KEY,
      }),
      fetchApproveDates(periodId, { concept_type: CONCEPTS.TYPE.ECONOMIC_KEY }),
    ])
  }, [fetchMonthsToApprove, fetchApproveDates, periodId])

  useEffect(() => {
    if (functionalCurrencyData?.id && budgetId) {
      const endpointParams = {
        projection: budgetId,
        functional_currency: functionalCurrencyData.id,
      }
      setIsExchangeRateLoading(true)
      fetchExchangeRateList(endpointParams).then(() => setIsExchangeRateLoading(false))
    }
  }, [fetchExchangeRateList, budgetId, functionalCurrencyData?.id])

  const monthsPublishAndApprove = [...monthsToApprove, ...approveDates]

  const areAllValues = (exr_by_month) => {
    //NOTE: PROVISORIO HASTA QUE FUNCIONE FORECAST MULTIMONED Y NOS TRAIGA LA DATA DE LAS FECHAS PUBLICADAS/aPROBADAS
    const publishedDateValues = monthsPublishAndApprove.reduce((obj, date, index) => {
      return {
        ...obj,
        [date]: 0,
      }
    }, {})
    const auxExrByMonth = isForecast(projectionDetail.type)
      ? { ...publishedDateValues, ...exr_by_month }
      : exr_by_month
    return Object.keys(auxExrByMonth).length === monthsList.length
  }

  const renderEmptyScreen = () => (
    <EmptyScreen
      image={emptyStateImage}
      title={t('PLANNING_CURRENCY_EMPTY_SCREEN_TITLE')}
      footer={
        <Button
          type="primary"
          onClick={() => history.push(`/configuracion/herramientas?tab=currency`)}
        >
          {t('ACTION_GO_TO_CONFIGURE')}
        </Button>
      }
    />
  )

  const renderHeader = () => <PeriodNavigator items={[{ name: t('LABEL_EXCHANGE_RATE') }]} />

  const renderButtonGoBack = () => (
    <ButtonActionBar position="start">
      <Button type="link" title={t('ACTION_RETURN')} onClick={() => history.goBack()}>
        <LeftCircleOutlined />
        {projectionDetail.name}
      </Button>
    </ButtonActionBar>
  )

  const handleSaveExchangeRate = ({
    showFeedbackSuccess,
    exchangeRateByMonths,
    exchangeRateId,
  }) => {
    setIsSaving(true)
    saveExchangeRate(exchangeRateId, {
      exr_by_month: exchangeRateByMonths,
    })
      .then(() =>
        fetchExchangeRateList({
          projection: budgetId,
          functional_currency: functionalCurrencyData.id,
        }).then(() => {
          showFeedbackSuccess && message.success(t('FEEDBACK_CHANGES_SAVED_SUCCESS'), 8)
          setIsSaving(false)
          setShowPopconfirm(false)
        }),
      )
      .catch((error) => {
        notification.error({
          message: t('FEEDBACK_DEFAULT_ERROR'),
          description: generateMessageError(error, 'FEEDBACK_SAVE_CHANGES_FAIL'),
          duration: 0,
        })
        setIsSaving(false)
        setShowPopconfirm(false)
      })
  }

  const renderButtonsCancelSave = (exchangeRate) => (
    <ButtonActionBar>
      <Popconfirm
        placement="bottomRight"
        title={t('PLANNING_CURRENCY_CANCEL_POPCONFIRM_TITLE')}
        okText={t('YES')}
        onConfirm={() =>
          handleSaveExchangeRate({
            showFeedbackSuccess: false,
            exchangeRateByMonths: exchangeRate.originAmounts,
            exchangeRateId: exchangeRate.id,
          })
        }
        cancelText={t('NO')}
        okButtonProps={{ loading: isSaving }}
        onCancel={() => setShowPopconfirm(false)}
        visible={showPopconfirm}
      >
        <Button onClick={() => setShowPopconfirm(true)}>{t('ACTION_CANCEL')}</Button>
      </Popconfirm>
      <Tooltip
        title={
          !areAllValues(exchangeRate.exr_by_month)
            ? t('PLANNING_CURRENCY_ALL_VALUES_TOOLTIP_TITLE')
            : ''
        }
      >
        <Button
          type="primary"
          onClick={() =>
            handleSaveExchangeRate({
              showFeedbackSuccess: true,
              exchangeRateByMonths: exchangeRate.exr_by_month,
              exchangeRateId: exchangeRate.id,
            })
          }
          disabled={showPopconfirm || !areAllValues(exchangeRate.exr_by_month)}
        >
          {t('ACTION_SAVE')}
        </Button>
      </Tooltip>
    </ButtonActionBar>
  )

  const renderCurrencyName = (exchangeRateCode) =>
    t(PROJECTION.CURRENCIES_OPTIONS.find((currency) => currency.code === exchangeRateCode).name)

  const renderCollapseHeader = () => (
    <div className="collapse-exchange-rate-header">{t('FIELD_ORIGIN_CURRENCIES')}</div>
  )

  const handleExitConfirm = () => {
    const projetionType = isForecast(projectionDetail.type) ? PROJECTION.FORECAST : PLANNING_KEY
    if (lastLocation) {
      setShowExitModal(false)
      history.push(lastLocation)
    } else {
      history.push(`${projetionType}/${periodId}/tipodecambio/${budgetId}`)
    }
  }

  const handleBlockedNavigation = (nextLocation) => {
    if (!confirmedNavigation) {
      setShowExitModal(true)
      setLastLocation(nextLocation)
      setConfirmedNavigation(true)
      return false
    }
    return true
  }

  const renderValue = ({ exr_by_month, month }) => {
    if (isForecast(projectionDetail.type) && monthsPublishAndApprove.includes(month)) {
      return ''
    } else {
      if (!_.isEmpty(exr_by_month) && exr_by_month[month]) {
        return exr_by_month[month]
      } else {
        return 0
      }
    }
  }

  const renderCollapseCurrencyList = () => (
    <Spin spinning={isSaving}>
      {renderCollapseHeader()}
      <Collapse accordion>
        {exchangeRateList.map((exchangeRate) => (
          <>
            <Prompt
              when={
                (!_.isEmpty(exchangeRate.exr_by_month) &&
                  !areAllValues(exchangeRate.exr_by_month)) ||
                (areAllValues(exchangeRate.exr_by_month) && _.isEmpty(exchangeRate.originAmounts))
              }
              message={handleBlockedNavigation}
            />
            <Panel
              header={
                <Tooltip title={renderCurrencyName(exchangeRate.origin_currency.code)}>
                  {exchangeRate.origin_currency.code}
                </Tooltip>
              }
              key={exchangeRate.id}
            >
              <Space direction="vertical">
                <div>
                  {monthsList.map((month, index) => (
                    <InputMonth
                      index={index}
                      month={month}
                      value={renderValue({
                        exr_by_month: exchangeRate.exr_by_month,
                        month,
                      })}
                      disabled={
                        (isForecast(projectionDetail.type) &&
                          monthsPublishAndApprove.includes(month)) ||
                        isApproved(projectionDetail.status)
                      }
                      onChange={(amount) =>
                        setExchangeRateValues({
                          exchangeRateId: exchangeRate.id,
                          [month]: amount,
                        })
                      }
                      style={index < monthsList.length - 1 && { paddingRight: 5 }}
                    />
                  ))}
                </div>
                {!_.isEmpty(exchangeRate.exr_by_month) &&
                  exchangeRate.areVisibleButtonsActions &&
                  renderButtonsCancelSave(exchangeRate)}
              </Space>
            </Panel>
          </>
        ))}
      </Collapse>
    </Spin>
  )

  const renderTopLabel = () => (
    <ButtonActionBar>
      <Tooltip placement="topRight" title={renderCurrencyName(functionalCurrencyData.code)}>
        <Typography.Subtitle>{`${t('FIELD_FUNCTIONAL_CURRENCY')}: ${
          functionalCurrencyData.code
        }`}</Typography.Subtitle>
      </Tooltip>
    </ButtonActionBar>
  )

  const renderAlertInfo = () => (
    <Alert
      showIcon
      closable
      type="info"
      description={t('PLANNING_CURRENCY_LOAD_ALL_EXCHANGE_RATE_ALERT_DESCRIPTION')}
    />
  )

  return (
    <SiderLayout>
      <Spin spinning={isScreenLoading || isExchangeRateLoading}>
        {renderHeader()}
        <Row gutter={[24, 24]}>
          <Col span={24}>{renderButtonGoBack()}</Col>
          {exchangeRateList.length <= 0 && !isScreenLoading && !isExchangeRateLoading && (
            <Col span={24}>{renderEmptyScreen()}</Col>
          )}
          {exchangeRateList.length > 0 && !isScreenLoading && !isExchangeRateLoading && (
            <>
              <Col span={24}>{renderTopLabel()}</Col>
              <Col span={24}>{renderAlertInfo()}</Col>
              <Col span={24}>{renderCollapseCurrencyList()}</Col>
            </>
          )}
        </Row>
        <CreationExitModal
          visible={showExitModal}
          onClose={() => {
            setShowExitModal(false)
            setConfirmedNavigation(false)
          }}
          onConfirm={handleExitConfirm}
        />
      </Spin>
    </SiderLayout>
  )
}

const mapStateToProps = (state) => ({
  exchangeRateList: planning.selectors.getExchangeRateList(state),
  monthsList: planning.selectors.getMonthsList(state),
  projectionDetail: planning.selectors.getProjectionDetail(state),
  currencyList: configuration.selectors.getCurrencyList(state),
  monthsToApprove: control.selectors.getMonthsToApprove(state),
  approveDates: control.selectors.getApproveDates(state),
})

const mapDispatchToProps = {
  fetchMonthsList: planning.actions.fetchMonthsList,
  fetchProjectionDetail: planning.actions.fetchProjectionDetail,
  fetchExchangeRateList: planning.actions.fetchExchangeRateList,
  saveExchangeRate: planning.actions.saveExchangeRate,
  setExchangeRateValues: planning.actions.setExchangeRateValues,
  fetchCurrencyList: configuration.actions.fetchCurrencyList,
  fetchMonthsToApprove: control.actions.fetchMonthsToApprove,
  fetchApproveDates: control.actions.fetchApproveDates,
}

export default connect(mapStateToProps, mapDispatchToProps)(ExchangeRateScreen)
