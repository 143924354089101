import React, { useState } from 'react'
import { Table } from 'antd'
import { calculateScrollX, columnsHelper } from 'modules/core/utils'
import { ROWS } from 'modules/core/constants'

import './EvolutionTable.scss'

const EvolutionTable = ({
  reportId,
  settings: { format, showNegativeInRed },
  rows,
  columns,
  loading,
  fetchEvolutionTableOpenRow,
  globalProjectionId,
  step,
  selectedDates,
  filters,
}) => {
  const [isExpandingByPeriod, setIsExpandingByPeriod] = useState(false)
  const [expandedByPeriodRow, setExpandedByPeriodRow] = useState([])

  const onExpandByPeriod = (expanded, record) => {
    setIsExpandingByPeriod(true)
    if (expanded) {
      fetchEvolutionTableOpenRow(reportId, globalProjectionId, step, record.id, {
        ...selectedDates,
        ...filters,
      }).then(() => {
        setIsExpandingByPeriod(false)
        setExpandedByPeriodRow((prevExpanded) => [...prevExpanded, record.id])
      })
    } else {
      setExpandedByPeriodRow((prevExpanded) => prevExpanded.filter((item) => item !== record.id))
      setIsExpandingByPeriod(false)
    }
  }

  const renderRowClassName = (record) => {
    if (record.has_rule_applied) {
      return `${record.has_rule_applied ? ROWS.TYPES.APPLIED_RULE_ROW : ''}`
    }

    return ROWS.TYPES[record.type]
  }

  const generatorEvolutionColumns = () =>
    columnsHelper.generatorReportColumns({
      columns,
      // onResize: setPeriodTableColumns,
      cellFormat: {
        format,
        showNegativeInRed,
      },
    })

  return (
    <Table
      rowKey="id"
      size="small"
      className="report-table"
      loading={isExpandingByPeriod || loading}
      columns={generatorEvolutionColumns()}
      dataSource={rows}
      rowClassName={renderRowClassName}
      expandable={{
        onExpand: onExpandByPeriod,
        expandedRowKeys: expandedByPeriodRow,
      }}
      scroll={{ x: calculateScrollX(generatorEvolutionColumns()), y: 450 }}
      pagination={false}
    />
  )
}

export default EvolutionTable
