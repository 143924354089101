import React from 'react'
import { saveAs } from 'file-saver'
import { notification } from 'antd'
import i18n from 'i18next'
import Button from '../Button/Button'

// TODO: APLICAR i18n
const projectionSuccessUploadNotice = ({
  errorFile = null,
  projectionType = 'presupuesto',
  type = '',
}) => {
  const renderSuccess = () =>
    notification.success({
      message: i18n.t('FEEDBACK_VERY_GOOD'),
      description: i18n.t('FEEDBACK_UPLOAD_PROJECITION_FILE_SUCCESS', {
        projectionType: projectionType,
        descriptionType:
          type === 'rule' ? i18n.t('PLANNING_FORMULAS_DESCRIPTION_NOTIFICATION_SUCCESS') : '',
      }),
      duration: 8,
    })
  const renderSuccessWithError = () =>
    notification.warning({
      key: 'warning_file',
      message: i18n.t('FEEDBACK_UPLOAD_FILE_WITH_ERRORS'),
      description: (
        <>
          {i18n.t('UPLOAD_ERRORS_FILE_DOWNLOAD_TEXT_1')}{' '}
          <Button.Link
            size="small"
            onClick={() => {
              saveAs(errorFile)
              notification.close('warning_file')
            }}
            title="UPLOAD_ERRORS_FILE_DOWNLOAD_TEXT_2"
          />{' '}
          {i18n.t('UPLOAD_ERRORS_FILE_DOWNLOAD_TEXT_3')}
        </>
      ),
      duration: 0,
    })

  return errorFile === null ? renderSuccess() : renderSuccessWithError()
}

export default projectionSuccessUploadNotice
