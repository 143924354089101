import React, { useState } from 'react'
import { Card, Dropdown, Menu, message, Space, Tag, Typography as TypographyAntd } from 'antd'
import { Comments, ProjectionListKPI, Typography } from 'modules/core/components'
import { useSortable } from '@dnd-kit/sortable'
import { GENERAL, REPORT } from 'modules/core/constants'
import { CommentOutlined } from '@ant-design/icons'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { generateMessageError } from 'modules/core/utils'
import login from 'modules/login'
import configuration from 'modules/configuration'
import _ from 'lodash'

import './ListCard.scss'

const { Text } = TypographyAntd

const ListCard = ({
  className = '',
  title,
  type,
  projectionId,
  periodId,
  image = null,
  onClick,
  renderPrefix = null,
  extra = null,
  actions = [],
  emptyState = { title: null, description: null },
  hasData = true,
  loading = false,
  dataKpi,
  reportType,
  colorTag,
  setDataComments,
  dataComments,
  onRefetchComments,
  loadingComments,
  hasComment,
  setHasComment,
  loggedUser,
  createComment,
  removeNewComment,
}) => {
  const [viewComment, setViewComment] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const { t } = useTranslation()
  const { isDragging, attributes, listeners, setNodeRef, transform } = useSortable({
    id: projectionId,
  })

  const auxKey = window.location.pathname.replaceAll('/', '-')
  const key = !auxKey.includes('?tab=') && type === 'pnl' ? `${auxKey}?tab=reportesplika` : auxKey

  const draggableStyle = transform
    ? {
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
        userSelect: 'none',
        cursor: 'move',
        opacity: isDragging ? 0.5 : 1,
      }
    : undefined

  const tagStyle = {
    cursor: transform ? 'move' : 'pointer',
  }

  const handleCreate = ({ text, usersIds, idComment = null, setIsNewThread }) => {
    const dataa = {
      text,
      key: `${key}-card-${projectionId}`,
      module: key,
      mentions: usersIds,
      ...(idComment && {
        parent: idComment,
      }),
    }
    setIsSaving(true)
    createComment(dataa)
      .then(() =>
        onRefetchComments().then(() => {
          message.success(t('FEEDBACK_SAVE_CHANGES_SUCCES'))
          setIsSaving(false)
          setIsNewThread(false)
        }),
      )
      .catch((error) => {
        message.error(generateMessageError(error, 'FEEDBACK_SAVE_CHANGES_FAIL_MESSAGE'), 8)
        setIsSaving(false)
        setIsNewThread(false)
      })
  }

  const renderExtra = () => (
    <Space align="center">
      <Tag color={colorTag}>{reportType}</Tag>
      {(viewComment ||
        dataComments.filter((el) => el.key === `${key}-card-${projectionId}` && !el.resolved)
          .length > 0) && (
        <Comments
          hasComment={
            !_.isEmpty(hasComment?.card)
              ? !_.isEmpty(hasComment?.card[key]) && hasComment?.card[key][projectionId]
              : false
          }
          onClick={(visible) => {
            setHasComment({ type: 'card', data: visible, key, id: projectionId })
            !visible && removeNewComment()
            !visible && setViewComment(visible)
          }}
          comments={dataComments.filter(
            (el) => (el.key === `${key}-card-${projectionId}` && !el.resolved) || !el.text,
          )}
          setData={setDataComments}
          loading={isSaving || loadingComments}
          onRefetchComments={onRefetchComments}
          onCommentCreate={(text, usersIds, idComment, setIsNewThread) =>
            handleCreate({ text, usersIds, idComment, setIsNewThread })
          }
          onCleanHasComment={() => {
            setHasComment({ type: 'card', data: false, key, id: projectionId })
            setViewComment(false)
          }}
        >
          <Typography.Icon
            style={{ cursor: 'pointer' }}
            title={t('LABEL_COMMENTS')}
            icon={CommentOutlined}
          />
        </Comments>
      )}
      <div
        className={type === REPORT.REPORTS_KEYS.PNL_KEY ? 'drag-card-icon' : ''}
        {...attributes}
        {...listeners}
        style={tagStyle}
      >
        {extra}
      </div>
    </Space>
  )

  const renderTitle = () => {
    return (
      <Text title={title} style={{ width: 180 }} ellipsis={true}>
        {title}
      </Text>
    )
  }

  return (
    <div ref={setNodeRef} style={draggableStyle}>
      <Dropdown
        trigger={['contextMenu']}
        overlay={
          <Menu>
            {GENERAL.OPTION_COMMENT.map((el) => (
              <Menu.Item
                key={el.key}
                icon={<CommentOutlined />}
                onClick={() => {
                  setViewComment(true)
                  setHasComment({
                    type: 'card',
                    data: !_.isEmpty(hasComment?.card)
                      ? !_.isEmpty(hasComment?.card[key])
                        ? !hasComment?.card[key][projectionId]
                        : true
                      : true,
                    key,
                    id: projectionId,
                  })
                }}
              >
                {t(el.label)}
              </Menu.Item>
            ))}
          </Menu>
        }
      >
        <Card
          className={`projection-list-card ${className}`}
          headStyle={{ height: 56 }}
          title={renderTitle()}
          bordered={false}
          data-type={type}
          onClick={onClick}
          extra={renderExtra()}
          actions={actions}
          loading={loading}
        >
          <ProjectionListKPI
            dataKpi={dataKpi}
            type={type}
            projectionId={projectionId}
            periodId={periodId}
            image={image}
            hasData={hasData}
            emptyState={emptyState}
            renderPrefix={renderPrefix}
          />
        </Card>
      </Dropdown>
    </div>
  )
}

const mapStateToProps = (state) => ({
  loggedUser: login.selectors.getWhoAmI(state),
  hasComment: configuration.selectors.getHasComment(state),
})

const mapDispatchToProps = {
  setHasComment: configuration.actions.setHasComment,
  createComment: configuration.actions.createComment,
  removeNewComment: configuration.actions.removeNewComment,
}

export default connect(mapStateToProps, mapDispatchToProps)(ListCard)
