const periods = {
  PERIOD_TITLE: 'Períodos presupuestarios',

  PERIOD_CREATE_ACTION: 'Crear período',

  PERIOD_EMPTY_STATE_TEXT:
    'Desde acá administrarás tus períodos, tanto los vigentes como los de años anteriores. Podrás hacer un seguimiento de los mismos, compararlos entre sí, analizarlos y más.',

  PERIOD_CREATE_MODAL_TITLE: 'Iniciar período presupuestario',
  PERIOD_CREATE_MODAL_NAME_LABEL: 'Definí un nombre',
  PERIOD_CREATE_MODAL_NAME_PLACEHOLDER: 'Ej. Período 1',
  PERIOD_CREATE_MODAL_DATES_LABEL: 'Ingresá un rango de fechas',
  PERIOD_CREATE_SUCCESS_FEEDBACK: 'El período se inició correctamente',

  PERIOD_DATES_LABEL: 'Mes inicio - Mes fin',

  PERIOD_DELETE_CONFIRM_TEXT: '¿Estás seguro que deseas eliminar este período?',
  PERIOD_DELETE_SUCCESS_FEEDBACK: 'El período se eliminó correctamente.',
  PERIOD_DELETE_WITH_DATA_MODAL_CONFIRM_TEXT:
    'El período que deseas eliminar podría tener datos cargados.',

  PERIOD_EDIT_NAME_SUCCES_FEEDBACK: 'El nombre del período se editó correctamente.',
  PERIOD_EDIT_DATE_SUCCES_FEEDBACK: 'Las fechas del período se editó correctamente.',

  PERIOD_ONBOARDING_INFORMATIVE_TOOLTIP_DESCRIPTION:
    'Te recomendamos que primero realices la configuración general para comenzar a explorar al máximo las funcionalidades de Plika',
  PERIOD_ALERT_TITLE: 'Configuración general',
  PERIOD_ALERT_DESCRIPTION:
    'Recordá configurar la herramienta antes de avanzar, de esta forma podrás realizar tus cargas con éxito',

  PERIOD_CONFIG_TOOL_TOOLTIP_TITLE: 'Configura la herramienta para ingresar',
}

export default periods
