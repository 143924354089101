import React, { useState } from 'react'
import { Col, Drawer, Row, Space, Table, Tag } from 'antd'
import { Button, DropdownMenu, Typography } from 'modules/core/components'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { QrCodeModal } from './components'
import { DeleteOutlined } from '@ant-design/icons'
import { handleDelete } from './components/QrCodeModal/utils'
import login from 'modules/login'

const ConfigUserDrawer = ({
  visible,
  onClose,
  is_configured_google,
  is_configured_azure,
  is_configured_qrCode,
  googleRedirect,
  azureRedirect,
  qrCodeRedirect,
  qrCodeData,
  deleteQrAunthentication,
  whoAmI,
}) => {
  const [showQrModal, setShowQrModal] = useState(false)
  const { t } = useTranslation()

  const handleClick = (action) => {
    action().then((response) => window.open(response.payload.data, '_self'))
  }

  const handleQrCode = (action) => {
    action().then(() => {
      setShowQrModal(true)
    })
  }

  const columns = [
    {
      title: t('FIELD_NAME'),
      dataIndex: 'name',
      render: (name) => t(name),
    },
    {
      title: t('FIELD_STATUS'),
      dataIndex: 'is_configured',
      render: (is_configured) => (
        <Tag color={is_configured ? 'green' : 'gold'}>
          {is_configured ? t('STATE_TAG_CONFIGURED') : t('STATE_TAG_NOT_CONFIGURED')}
        </Tag>
      ),
    },
    {
      title: t('FIELD_ACTIONS'),
      dataIndex: 'id',
      align: 'right',
      width: 50,
      render: (id, row) => {
        return (
          <Space direction="horizontal">
            {!row.is_configured && (
              <Button.Link title={t('ACTION_CONFIGURATE')} onClick={row.action} />
            )}
            {id === 'qrcode' && row.is_configured && (
              <DropdownMenu
                title={t('ACTION_MORE')}
                menu={[
                  {
                    title: t('ACTION_DELETE'),
                    icon: <DeleteOutlined />,
                    onClick: () =>
                      handleDelete({
                        deleteQrAunthentication: () => deleteQrAunthentication(),
                        onRefetch: () => whoAmI(),
                      }),
                  },
                ]}
              />
            )}
          </Space>
        )
      },
    },
  ]

  const dataSource = [
    {
      id: 'google',
      name: 'Google',
      is_configured: is_configured_google,
      action: () => handleClick(googleRedirect),
    },
    {
      id: 'azure',
      name: 'Microsoft',
      is_configured: is_configured_azure,
      action: () => handleClick(azureRedirect),
    },
    {
      id: 'qrcode',
      name: 'FIELD_QR_BUTTOM',
      is_configured: is_configured_qrCode,
      action: () => handleQrCode(qrCodeRedirect),
    },
  ]

  return (
    <Drawer visible={visible} title={t('CONFIG_USERS_DRAWER_TITLE')} size="large" onClose={onClose}>
      <Row gutter={[8, 8]}>
        <Col span={24}>
          <Typography.Subtitle>{t('FIELD_AUTHENTICATION')}</Typography.Subtitle>
        </Col>
        <Col span={24}>
          <Table
            size="small"
            rowKey="id"
            columns={columns}
            dataSource={dataSource}
            pagination={false}
          />
        </Col>
      </Row>
      <QrCodeModal
        visible={showQrModal}
        onCancel={() => setShowQrModal(false)}
        qrCodeData={qrCodeData}
      />
    </Drawer>
  )
}

const mapStateToProps = (state) => ({
  qrCodeData: login.selectors.getQrCodeData(state),
})

const mapDispatchToProps = {
  googleRedirect: login.actions.googleRedirect,
  azureRedirect: login.actions.azureRedirect,
  qrCodeRedirect: login.actions.qrCodeRedirect,
  deleteQrAunthentication: login.actions.deleteQrAunthentication,
  whoAmI: login.actions.whoAmI,
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfigUserDrawer)
