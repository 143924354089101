import React from 'react'
import { MessagesScreen, NoUserScreen } from './components'

import './ChatContent.scss'

const ChatContent = ({ onMessage }) => {
  return (
    <>
      {onMessage ? (
        <div className="chat-content-screen">
          <MessagesScreen suggestedQuestion={onMessage} />
        </div>
      ) : (
        <div className="chat-no-user-screen">
          <NoUserScreen />
        </div>
      )}
    </>
  )
}

export default ChatContent
