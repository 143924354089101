import React from 'react'
import { Col, Row } from 'antd'
import { KPIValue } from 'modules/core/components'
import numeral from 'numeral'

const CardData = ({ kpi }) => {
  return (
    <Row align="middle">
      <Col span={24}>
        <KPIValue
          size="large"
          fullValue={kpi && numeral(kpi).format('0,0.00')}
          value={kpi ? numeral(kpi).format('0[.]0a') : '-'}
          prefix={'$'}
        />
        {/* <Typography.Body level={3} type="secondary">
                {kpis.name}
                </Typography.Body> */}
      </Col>
    </Row>
  )
}

export default CardData
