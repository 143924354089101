import { Card, Col, Row, Space } from 'antd'
import { KPIValue, Typography } from 'modules/core/components'
import numeral from 'numeral'

const Kpis = ({ loading, adjustmentKPIS }) => {
  return (
    <Row gutter={[24, 0]} style={{ paddingLeft: 5 }}>
      <>
        {loading &&
          [1, 2, 3].map((index) => (
            <Col span={8} key={index}>
              <Card loading={true} />
            </Col>
          ))}
        {!loading &&
          adjustmentKPIS.map((kpi, index) => (
            <Col span={8} key={index}>
              <Card>
                <Space direction="vertical">
                  <KPIValue
                    size="medium"
                    prefix={kpi.prefix}
                    fullValue={numeral(kpi.amount).format('0,0.00')}
                    value={numeral(kpi.amount).format('0[.]0a')}
                  />
                  <Typography.Body level={3} type="secondary">
                    {kpi.title}
                  </Typography.Body>
                </Space>
              </Card>
            </Col>
          ))}
      </>
    </Row>
  )
}

export default Kpis
