import { Switch, Tooltip } from 'antd'
import { useTranslation } from 'react-i18next'

const ExtraPanel = ({ disabled, onChange, loading, isApplied }) => {
  const { t } = useTranslation()
  return (
    <div onClick={(event) => event.stopPropagation()}>
      <Tooltip title={disabled ? t('PLANNING_FORMULA_SAVE_DATA_TOOLTIP_TITLE') : ''}>
        <Switch disabled={disabled} onChange={onChange} loading={loading} checked={isApplied} />
      </Tooltip>
    </div>
  )
}

export default ExtraPanel
