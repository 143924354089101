import { message, notification } from 'antd'
import { generateMessageError } from 'modules/core/utils'
import i18n from 'i18next'

export const handleActivate = ({
  form,
  setIsLoading,
  endpointActions,
  onRefetch,
  onRefetchButtonList,
  onClose,
}) => {
  form.validateFields().then((values) => {
    setIsLoading(true)
    Promise.all([...endpointActions(values)])
      .then(() =>
        Promise.all([onRefetch(), onRefetchButtonList()]).then(() => {
          message.success(i18n.t('FEEDBACK_CHANGES_SAVED_SUCCESS', 8))
          onClose()
        }),
      )
      .catch((error) => {
        notification.error({
          message: i18n.t('FEEDBACK_DEFAULT_ERROR'),
          description: generateMessageError(error, 'FEEDBACK_SAVE_CHANGES_FAIL'),
          duration: 0,
        })
        onClose()
      })
  })
}
