import { Col, Row, Spin } from 'antd'
import { Navigator } from 'modules/core/components'
import { SiderLayout } from 'modules/core/layouts'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { useEffect, useState } from 'react'
import { usePagination } from 'modules/core/customHooks'
import { ProcessesTable } from './components'
import processes from 'modules/processes'

const ProcessesList = ({ fetchProcessesList, processesList: { count, values } }) => {
  const [isScreenLoading, setIsScreenLoading] = useState(true)
  const [isTableLoading, setIsTableLoading] = useState(false)
  const [tablePagination, setTablePagination] = usePagination()
  const { t } = useTranslation()

  useEffect(() => {
    setIsTableLoading(true)
    fetchProcessesList({ ...tablePagination }).then(() => {
      setIsScreenLoading(false)
      setIsTableLoading(false)
    })
  }, [fetchProcessesList, tablePagination])

  const renderHeader = () => (
    <Navigator floated={false} size="big" items={[{ name: t('LABEL_PROCESS_UPLOAD') }]} />
  )

  const renderTable = () => (
    <ProcessesTable
      loading={isTableLoading}
      dataSource={values}
      onChange={setTablePagination}
      pagination={tablePagination}
      count={count}
    />
  )

  return (
    <SiderLayout>
      <Spin spinning={isScreenLoading}>
        <Row gutter={[24, 24]}>
          <Col span={24}>{renderHeader()}</Col>
          <Col span={24}>{renderTable()}</Col>
        </Row>
      </Spin>
    </SiderLayout>
  )
}

const mapStateToProps = (state) => ({
  processesList: processes.selectors.getProcessesList(state),
})

const mapDispatchToProps = {
  fetchProcessesList: processes.actions.fetchProcessesList,
}

export default connect(mapStateToProps, mapDispatchToProps)(ProcessesList)
