import React from 'react'
import { RobotOutlined } from '@ant-design/icons'
import { Avatar } from 'antd'
import clsx from 'clsx'

const ReceiverMessageItem = ({ selectedUser, isPreviousSender = false, isLast, item }) => {
  return (
    <div
      className={clsx(
        'chat-message-list-item left',
        isPreviousSender ? 'hide-user-info' : 'first-chat-message',
        isLast ? 'last-chat-message' : '',
      )}
    >
      <div className="message-chat-view">
        {isPreviousSender ? null : selectedUser.image ? (
          <div className="message-chat-receiver">
            <Avatar size={34} className="message-chat-avatar" src={selectedUser.image} />
          </div>
        ) : (
          <div className="message-chat-receiver">
            <Avatar className="message-chat-avatar">
              <RobotOutlined />
            </Avatar>
          </div>
        )}
        <div className="message-chat-item">
          <span className="message-time">{item.time}</span>
          <div className="message-chat" style={{ whiteSpace: 'pre-line' }}>
            <p className="message-type-para">{item.message}</p>
          </div>
          {item.chart ? <div style={{ height: '350px' }}>{item.chart}</div> : null}
        </div>
      </div>
    </div>
  )
}

export default ReceiverMessageItem
