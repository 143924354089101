import { Select } from 'antd'
import { generateSelectOptions } from 'modules/core/utils'

export const columns = ({
  allDimensionsList,
  isLoadingOptions,
  allDimensionsValues,
  setDropdownOpen,
  setDimensionsSelection,
}) => {
  const renderValue = (record) => {
    if (
      record &&
      record?.dataValueSelect &&
      record?.dataValueSelect?.code &&
      record?.dataValueSelect?.name
    ) {
      return `${record.dataValueSelect.code}-${record.dataValueSelect.name}`
    }

    return undefined
  }

  return allDimensionsList.map((item) => {
    return {
      title: item.name_destination,
      dataIndex: item.name_destination,
      width: 200,
      render: (data, record) => {
        return record ? (
          <Select
            key={record.id}
            style={{ width: 200 }}
            value={renderValue(data)}
            loading={isLoadingOptions}
            placeholder={record[item.name_destination]?.name || ''}
            onDropdownVisibleChange={(value) =>
              setDropdownOpen({ isDropdownOpen: value, dimensionId: item.id })
            }
            options={generateSelectOptions({
              options: allDimensionsValues.map((dim) => {
                return {
                  id: parseInt(dim.id),
                  name: dim.name_destination,
                  code: dim.code_destination,
                }
              }),
            })}
            onChange={(id) =>
              setDimensionsSelection({
                key: 'default',
                index: record.id,
                data: { [item.id]: parseInt(id) },
                dimensionName: item.name_destination,
                dataValueSelect: {
                  code: allDimensionsValues.find((op) => parseInt(op.id) === id).code_destination,
                  name: allDimensionsValues.find((op) => parseInt(op.id) === id).name_destination,
                },
              })
            }
          />
        ) : null
      },
    }
  })
}
