import { isEmpty } from 'lodash'
import React from 'react'
import { aggregatorsTablePxQ } from '../../../Aggregators'
import {
  flatKey,
  formatter,
  normalizeName,
  PivotData,
  redColorScaleGenerator,
  spanSize,
  usFmt,
  usFmtPct,
} from '../../../Utilities'

class TablePxQ extends React.PureComponent {
  getBasePivotSettings() {
    const props = this.props
    const colAttrs = props.cols
    const rowAttrs = props.rows

    const pivotData = new PivotData(
      Object.assign({}, this.props, {
        aggregators: aggregatorsTablePxQ,
        vals: ['price', 'quantity'],
      }),
      {
        rowEnabled: true,
        colEnabled: false,
      },
    )
    const colKeys = pivotData.getColKeys()
    const rowKeys = pivotData.getRowKeys()

    const tableOptions = props.tableOptions
    // Custom lines
    const linesOrder = !isEmpty(tableOptions?.linesOrder) ? tableOptions.linesOrder : []
    const linesTotal = !isEmpty(tableOptions?.linesTotal) ? tableOptions.linesTotal : []
    const linesRatio = !isEmpty(tableOptions?.linesRatio) ? tableOptions.linesRatio : []
    const linesRatioN = !isEmpty(tableOptions?.linesRatioN) ? tableOptions.linesRatioN : []
    const linesFinancial = !isEmpty(tableOptions?.linesFinancial)
      ? tableOptions.linesFinancial
      : null
    const lineVertical = !isEmpty(tableOptions?.lineVertical) ? tableOptions.lineVertical : null

    return Object.assign({
      pivotData,
      colAttrs,
      rowAttrs,
      colKeys,
      rowKeys,
      linesOrder,
      linesTotal,
      linesRatio,
      linesRatioN,
      linesFinancial,
      lineVertical,
    })
  }

  getFormatter(pivotData) {
    let formatterFunction = usFmt
    if (formatter.hasOwnProperty(pivotData.props.aggregatorName)) {
      formatterFunction = formatter[pivotData.props.aggregatorName]
    }
    return formatterFunction
  }

  getAggregator(pivotData, rowKey, colKey) {
    try {
      const agg = pivotData.getAggregator(rowKey, colKey)
      const aggValue = agg.multivalued()
      return {
        p: aggValue['price'] ?? 0,
        q: aggValue['quantity'] ?? 0,
      }
    } catch (e) {
      return { p: 0, q: 0 }
    }
  }

  renderColHeaderRow(attrName, attrIdx, pivotSettings) {
    const { colAttrs, rowAttrs, colKeys } = pivotSettings

    const spanCell =
      attrIdx === 0 && rowAttrs.length !== 0 ? (
        <th className={`header_0`} colSpan={rowAttrs.length} rowSpan={colAttrs.length + 1} />
      ) : null

    const attrNameCell = (
      <th
        key="label"
        className={`pvtAxisLabel header_${rowAttrs.length} header_tot`}
        style={{ textAlign: 'right' }}
      >
        {attrName}
      </th>
    )

    const attrValueCells = colKeys.map((colKey, i) => {
      const x = spanSize(colKeys, i, attrIdx)
      if (x === -1) {
        return null
      }
      return (
        <th
          className="pvtColLabel"
          key={`colKey-${i}`}
          colSpan={x * 2}
          rowSpan={attrIdx === colAttrs.length - 1 && rowAttrs.length !== 0 ? 1 : 1}
        >
          {colKey[attrIdx]}
        </th>
      )
    })

    const totalCell =
      attrIdx === 0 ? (
        <th
          key="total"
          className="pvtTotalLabel"
          colSpan={2}
          rowSpan={colAttrs.length + (rowAttrs.length === 0 ? 0 : 0)}
        >
          TOTAL
        </th>
      ) : null

    const cells = [spanCell, attrNameCell, ...attrValueCells, totalCell]
    return <tr key={`colAttr-${attrIdx}`}>{cells}</tr>
  }

  renderColFixedHeaderRow(attrName, attrIdx, pivotSettings) {
    const { rowAttrs, colKeys } = pivotSettings

    const attrNameCell = (
      <th
        key="label"
        className={`pvtAxisLabel header_${rowAttrs.length} header_tot`}
        style={{ textAlign: 'right' }}
      >
        {attrName}
      </th>
    )

    const attrValueCells = []
    colKeys.forEach((colKey, i) => {
      const x = spanSize(colKeys, i, attrIdx)
      if (x === -1) {
        return null
      }
      attrValueCells.push(
        ...[
          <th className="pvtColLabel" key={`colKey-${i}`} rowSpan={2}>
            P
          </th>,
          <th className="pvtColLabel" key={`colKey-${i}`} rowSpan={2}>
            Q
          </th>,
        ],
      )
    })

    const totalCell = [
      <th key="total" className="pvtTotalLabel" rowSpan={2}>
        P
      </th>,
      <th key="total" className="pvtTotalLabel" rowSpan={2}>
        Q
      </th>,
    ]

    const cells = [attrNameCell, ...attrValueCells, ...totalCell]
    return <tr key={`colAttr-${attrIdx}`}>{cells}</tr>
  }

  renderRowHeaderRow(pivotSettings) {
    const { rowAttrs, colAttrs } = pivotSettings
    return rowAttrs.length !== 0 ? (
      <tr key="rowHdr">
        {rowAttrs.map(function (r, i) {
          return (
            <th className={`pvtAxisLabel header_${i}`} key={`rowAttr${i}`}>
              {r}
            </th>
          )
        })}
        <th className={`pvtTotalLabel header_${rowAttrs.length} header_tot`}>
          {colAttrs.length === 0 ? 'Totals' : null}
        </th>
      </tr>
    ) : null
  }

  renderLinesRow(rowKey, rowIdx, pivotSettings, visibleRowKeys) {
    const { linesOrder, linesTotal, linesRatio, linesRatioN, lineVertical } = pivotSettings

    let isTree = false
    let flatRowKey = flatKey(rowKey)

    if (rowKey.length > 1) {
      flatRowKey = rowKey[0]
      isTree = true
    }
    const line = linesOrder?.find((it) => normalizeName(it.name) === flatRowKey)
    const lineTotal = linesTotal?.find((it) => normalizeName(it.name) === flatRowKey)
    const lineRatio = linesRatio?.find((it) => normalizeName(it.name) === flatRowKey)
    const lineRatioN = linesRatioN?.find((it) => normalizeName(it.name) === flatRowKey)
    const originalRowIdx = visibleRowKeys.findIndex((entry) => flatKey(entry) === flatKey(rowKey))

    if (lineTotal) {
      if (!isTree) {
        return this.renderTableTotalRow(rowKey, rowIdx, pivotSettings, lineTotal, line)
      }
    } else if (lineRatio) {
      if (!isTree) {
        return this.renderTableRatioRow(rowKey, rowIdx, pivotSettings, lineRatio, usFmtPct, line)
      }
    } else if (lineRatioN) {
      if (!isTree) {
        return this.renderTableRatioRow(rowKey, rowIdx, pivotSettings, lineRatioN, usFmt, line)
      }
    } else if (originalRowIdx < 0) {
      return this.renderTableLineZeroRow(rowKey, rowIdx, pivotSettings, line)
    } else {
      return this.renderTableRow(rowKey, originalRowIdx, pivotSettings, lineVertical, line)
    }
  }

  renderTableTotalRow(rowKey, rowIdx, pivotSettings, lineTotal, line) {
    const { colKeys, rowAttrs, pivotData } = pivotSettings
    const flatRowKey = flatKey(rowKey)
    const formatter = this.getFormatter(pivotData)

    const lines = lineTotal.lines.reduce((mergedLines, it) => {
      return mergedLines.concat(it.lines)
    }, [])

    const attrValueCells = rowKey.map((r, i) => {
      return (
        <th
          key={`rowKeyLabel-${i}-${rowIdx}`}
          className={`pvtRowLabel fixed ${!line?.color ? 'pvtTotalizer' : ''}`}
          colSpan={rowAttrs.length + 1}
          style={{
            ...(line?.color
              ? { backgroundColor: line?.color }
              : { backgroundColor: '#eaeaea !important' }),
          }}
        >
          {r}
        </th>
      )
    })

    let valueCells = []
    colKeys.forEach((colKey) => {
      const flatColKey = flatKey(colKey)

      const value = lines.reduce(
        (acc, it) => {
          const agg = this.getAggregator(pivotData, [normalizeName(it)], colKey)
          const p = acc.p + agg.p
          const q = acc.q + agg.q

          return { p: p, q: q }
        },
        { p: 0, q: 0 },
      )

      valueCells.push(
        ...[
          <td
            key={'pvtVal-p-' + flatColKey}
            className={`pvtVal ${!line?.color ? 'pvtTotalizer' : ''}`}
            style={{ ...(line?.color ? { backgroundColor: line?.color } : {}) }}
          >
            {formatter(value.p)}
          </td>,
          <td
            key={'pvtVal-q-' + flatColKey}
            className={`pvtVal ${!line?.color ? 'pvtTotalizer' : ''}`}
            style={{ ...(line?.color ? { backgroundColor: line?.color } : {}) }}
          >
            {formatter(value.q)}
          </td>,
        ],
      )
    })

    let totalCell = []
    const value = lines.reduce(
      (acc, it) => {
        const agg = this.getAggregator(pivotData, [normalizeName(it)], [])
        const p = acc.p + agg.p
        const q = acc.q + agg.q

        return { p: p, q: q }
      },
      { p: 0, q: 0 },
    )

    totalCell.push(
      ...[
        <td
          key="total-p"
          className={`pvtTotal ${!line?.color ? 'pvtTotalizer' : ''}`}
          style={{ ...(line?.color ? { backgroundColor: line?.color } : {}) }}
        >
          {formatter(value.p)}
        </td>,
        <td
          key="total-q"
          className={`pvtTotal ${!line?.color ? 'pvtTotalizer' : ''}`}
          style={{ ...(line?.color ? { backgroundColor: line?.color } : {}) }}
        >
          {formatter(value.q)}
        </td>,
      ],
    )

    const rowCells = [...attrValueCells, ...valueCells, ...totalCell]
    return <tr key={'keyRow-' + flatRowKey}>{rowCells}</tr>
  }

  renderTableLineZeroRow(rowKey, rowIdx, pivotSettings, line) {
    const { colKeys, rowAttrs, pivotData } = pivotSettings
    const flatRowKey = flatKey(rowKey)
    const formatter = this.getFormatter(pivotData)

    const attrValueCells = rowKey.map((r, i) => {
      return (
        <th
          key={`rowKeyLabel-${i}-${rowIdx}`}
          className={`pvtRowLabel fixed`}
          colSpan={rowAttrs.length + 1}
          style={{ ...(line?.color ? { backgroundColor: line?.color } : {}) }}
        >
          {r}
        </th>
      )
    })

    const valueCells = []
    colKeys.forEach((colKey) => {
      const flatColKey = flatKey(colKey)

      valueCells.push(
        ...[
          <td
            className={`pvtVal`}
            key={'pvtVal-p-' + flatColKey}
            style={{ ...(line?.color ? { backgroundColor: line?.color } : {}) }}
          >
            {formatter(0)}
          </td>,
          <td
            className={`pvtVal`}
            key={'pvtVal-q-' + flatColKey}
            style={{ ...(line?.color ? { backgroundColor: line?.color } : {}) }}
          >
            {formatter(0)}
          </td>,
        ],
      )
    })

    const totalCell = [
      <td
        key="total-p"
        className="pvtTotal"
        style={{ ...(line?.color ? { backgroundColor: line?.color } : {}) }}
      >
        {formatter(0)}
      </td>,
      <td
        key="total-q"
        className="pvtTotal"
        style={{ ...(line?.color ? { backgroundColor: line?.color } : {}) }}
      >
        {formatter(0)}
      </td>,
    ]

    const rowCells = [...attrValueCells, ...valueCells, ...totalCell]
    return <tr key={'keyRow-' + flatRowKey}>{rowCells}</tr>
  }

  renderTableRatioRow(rowKey, rowIdx, pivotSettings, lineRatio, formatter = usFmt, line) {
    const { colKeys, rowAttrs, pivotData } = pivotSettings
    const flatRowKey = flatKey(rowKey)

    let lines1 = []
    let lines2 = []
    if (!isEmpty(lineRatio.lines)) {
      lines1 = lineRatio.lines[0]
      lines2 = lineRatio.lines[1]
    }

    const attrValueCells = rowKey.map((r, i) => {
      return (
        <th
          key={`rowKeyLabel-${i}-${rowIdx}`}
          className={`pvtRowLabel fixed ${!line?.color ? 'pvtRatio' : ''}`}
          colSpan={rowAttrs.length + 1}
          style={{ ...(line?.color ? { backgroundColor: line?.color } : {}) }}
        >
          {r}
        </th>
      )
    })

    let valueCells = []

    colKeys.forEach((colKey) => {
      const flatColKey = flatKey(colKey)

      let result = 0
      const value1 = lines1.lines.reduce(
        (acc, it) => {
          const agg = this.getAggregator(pivotData, [normalizeName(it)], colKey)
          const p = acc.p + agg.p
          const q = acc.q + agg.q

          return { p: p, q: q }
        },
        { p: 0, q: 0 },
      )

      const value2 = lines2.lines.reduce(
        (acc, it) => {
          const agg = this.getAggregator(pivotData, [normalizeName(it)], colKey)
          const p = acc.p + agg.p
          const q = acc.q + agg.q

          return { p: p, q: q }
        },
        { p: 0, q: 0 },
      )

      let resultP = 0
      if (value2.p !== 0.0) {
        resultP = value1.p / value2.p
      }

      let resultQ = 0
      if (value2.q !== 0.0) {
        resultQ = value1.q / value2.q
      }

      valueCells.push(
        ...[
          <td
            className={`pvtVal ${!line?.color ? 'pvtRatio' : ''}`}
            key={'pvtVal-p' + flatColKey}
            style={{ ...(line?.color ? { backgroundColor: line?.color } : {}) }}
          >
            {formatter(resultP)}
          </td>,
          <td
            className={`pvtVal ${!line?.color ? 'pvtRatio' : ''}`}
            key={'pvtVal-q' + flatColKey}
            style={{ ...(line?.color ? { backgroundColor: line?.color } : {}) }}
          >
            {formatter(resultQ)}
          </td>,
        ],
      )
    })

    let totalCell = []
    const value1 = lines1.lines.reduce(
      (acc, it) => {
        const agg = this.getAggregator(pivotData, [normalizeName(it)], [])
        const p = acc.p + agg.p
        const q = acc.q + agg.q

        return { p: p, q: q }
      },
      { p: 0, q: 0 },
    )

    const value2 = lines2.lines.reduce(
      (acc, it) => {
        const agg = this.getAggregator(pivotData, [normalizeName(it)], [])
        const p = acc.p + agg.p
        const q = acc.q + agg.q

        return { p: p, q: q }
      },
      { p: 0, q: 0 },
    )

    let resultP = 0
    if (value2.p !== 0.0) {
      resultP = value1.p / value2.p
    }

    let resultQ = 0
    if (value2.q !== 0.0) {
      resultQ = value1.q / value2.q
    }

    totalCell.push(
      ...[
        <td
          key="total-p"
          className={`pvtTotal ${!line?.color ? 'pvtRatio' : ''}`}
          style={{ ...(line?.color ? { backgroundColor: line?.color } : {}) }}
        >
          {formatter(resultP)}
        </td>,
        <td
          key="total-q"
          className={`pvtTotal ${!line?.color ? 'pvtRatio' : ''}`}
          style={{ ...(line?.color ? { backgroundColor: line?.color } : {}) }}
        >
          {formatter(resultQ)}
        </td>,
      ],
    )

    const rowCells = [...attrValueCells, ...valueCells, totalCell]
    return <tr key={'keyRow-' + flatRowKey}>{rowCells}</tr>
  }

  renderTableRow(rowKey, rowIdx, pivotSettings, lineVertical, line) {
    const { colKeys, pivotData, rowAttrs } = pivotSettings
    const flatRowKey = flatKey(rowKey)

    const attrValueCells = rowKey.map((txt, j) => {
      const x =
        rowKey.length < rowAttrs.length ? rowAttrs.length + 1 : j === rowAttrs.length - 1 ? 2 : 1

      return (
        <th key={`rowKeyLabel-${rowIdx}-${j}`} className={`pvtRowLabel header_${j}`} colSpan={x}>
          {txt}
        </th>
      )
    })

    let valueCells = []
    colKeys.forEach((colKey, j) => {
      const agg = pivotData.getAggregator(rowKey, colKey)
      const values = Object.keys(agg.multivalued())

      ;['price', 'quantity'].forEach((flatColKey) => {
        let aggValue = 0
        if (values.length > 0) {
          aggValue = agg.multivalued()[flatColKey]
        }

        valueCells.push(
          <td
            className="pvtVal"
            key={`pvtVal-${rowIdx}-${j}-${flatColKey}`}
            style={{ ...(line?.color ? { backgroundColor: line?.color } : {}) }}
          >
            {agg.format(aggValue)}
          </td>,
        )
      })
    })

    const totalCell = []
    const agg = pivotData.getAggregator(rowKey, [])
    const values = Object.keys(agg.multivalued())

    ;['price', 'quantity'].forEach((flatColKey) => {
      let aggValue = 0
      if (values.length > 0) {
        aggValue = agg.multivalued()[flatColKey]
      }

      totalCell.push(
        <td
          key="total"
          className="pvtTotal"
          style={{ ...(line?.color ? { backgroundColor: line?.color } : {}) }}
        >
          {agg.format(aggValue)}
        </td>,
      )
    })

    const rowCells = [...attrValueCells, ...valueCells, ...totalCell]
    return <tr key={'keyRow-' + flatRowKey}>{rowCells}</tr>
  }

  render() {
    if (this.cachedProps !== this.props) {
      this.cachedProps = this.props
      this.cachedBasePivotSettings = this.getBasePivotSettings()
    }
    const { colAttrs, rowAttrs, rowKeys, linesOrder } = this.cachedBasePivotSettings
    const pivotSettings = this.cachedBasePivotSettings

    let visibleRowKeys = []
    linesOrder.forEach((it) => {
      if (it.type === 'grouper') {
        const listFilter = rowKeys.filter((entry) => it.name === entry[0])
        if (listFilter.length > 0) {
          const firstItem = listFilter[0].length
          const sortedFilter = listFilter.filter((entry) => entry.length === firstItem)
          visibleRowKeys.push(...sortedFilter)
        } else {
          visibleRowKeys.push([it.name])
        }
      } else {
        visibleRowKeys.push([it.name])
      }
    })

    const indexLastColAttrs = colAttrs.length > 0 ? colAttrs.length - 1 : 0

    return (
      <table className="pvtTable">
        <thead>
          {colAttrs.map((it, index) => this.renderColHeaderRow(it, index, pivotSettings))}
          {this.renderColFixedHeaderRow('PxQ', indexLastColAttrs, pivotSettings)}
          {rowAttrs.length !== 0 && this.renderRowHeaderRow(pivotSettings)}
        </thead>
        <tbody>
          {visibleRowKeys.map((r, i) => this.renderLinesRow(r, i, pivotSettings, rowKeys))}
        </tbody>
      </table>
    )
  }
}

TablePxQ.defaultProps = PivotData.defaultProps
TablePxQ.propTypes = PivotData.propTypes
TablePxQ.defaultProps.aggregators = aggregatorsTablePxQ
TablePxQ.defaultProps.tableColorScaleGenerator = redColorScaleGenerator
TablePxQ.defaultProps.tableOptions = {}

export default TablePxQ
