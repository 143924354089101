import { message } from 'antd'
import { generateMessageError } from 'modules/core/utils'
import i18n from 'i18next'

export const handleDeleteRow = ({ setIsLoading, deleteRow, onRefetch, onClose }) => {
  setIsLoading(true)
  deleteRow()
    .then(() => {
      onRefetch()
      message.success(i18n.t('FEEDBACK_DELETE_SELECTION_SUCCESS'), 8)
      onClose()
    })
    .catch((error) => {
      message.error(generateMessageError(error, 'FEEDBACK_DELETE_SELECTION_ERROR'), 8)
      onClose()
    })
}

export const handleCellEdit = ({ updateTransactionNestedForecast, onRefetch }) => {
  message.loading({
    key: 'loading_edit',
    content: i18n.t('FEEDBACK_LOADING_CHANGES'),
  })
  updateTransactionNestedForecast()
    .then(() => {
      onRefetch()
      // .finally(() => {
      message.destroy('loading_edit')
      message.success(i18n.t('FEEDBACK_SAVE_CHANGES_SUCCES', 8))
      // });
    })
    .catch((error) => message.error(generateMessageError(error, 'FEEDBACK_DEFAULT_ERROR'), 8))
}

export const handleDelete = ({ setIsLoading, deleteLayout, onRefetch, onClose }) => {
  setIsLoading(true)
  deleteLayout()
    .then(() =>
      onRefetch().then(() => {
        message.success(i18n.t('FEEDBACK_CHANGES_SAVED_SUCCESS'), 8)
        onClose()
      }),
    )
    .catch((error) => {
      message.error(generateMessageError(error, 'FEEDBACK_SAVE_CHANGES_FAIL_MESSAGE'))
      onClose()
    })
}

//comentario en celda
// export const getFunctionComments = ({
//   showRowsComment,
//   createTransactionCommentForecast,
//   editTransactionCommentForecast,
//   deleteTransactionCommentForecast,
//   syncForecastComment
// })=> {
//   return{
//       showRowsComment,
//       onComment: (data)=> onCommentCell({
//           create: ()=> createTransactionCommentForecast({cell_id: data.transactionId, comment: data.comment}),
//           syncProjectionComment: (response)=> syncForecastComment({
//               columnId: data.columnId,
//               rowId: data.rowId,
//               reducerKey: 'dataTablePhase',
//               newComment: response.payload.data,
//               type: 'create'
//               })
//       }),
//       onEditComment: (data)=> onEditCell({
//           edit: ()=> editTransactionCommentForecast({comment_id:  data.commentId, comment: data.comment}),
//           syncProjectionComment: (response)=> syncForecastComment({
//               columnId: data.columnId,
//               rowId: data.rowId,
//               reducerKey: 'dataTablePhase',
//               newComment: response.payload.data,
//               commentId: data.commentId,
//               type: 'edit'
//           })
//       }),
//       onDeleteComment: (data)=>onDeleteCommentCell({
//           deleted: ()=> deleteTransactionCommentForecast(data.commentId),
//           syncProjectionComment: () => syncForecastComment({
//               columnId: data.columnId,
//               rowId: data.rowId,
//               reducerKey: 'dataTablePhase',
//               commentId: data.commentId,
//               type:'delete'
//           })
//       }),
//   }
// }
