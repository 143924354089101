import { useFeatureIsOn, useFeatureValue } from '@growthbook/growthbook-react'
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Spin, Row, Col } from 'antd'
import { Button, EmptyScreen, ProjectionCardList, UserActions } from 'modules/core/components'
import { useTranslation } from 'react-i18next'
import {
  handleDragEnd,
  handleDragStart,
  isApproved,
  isUserAllowed,
  sendUserDataToGTM,
} from 'modules/core/utils'
import { ROLES, CONCEPTS, PROJECTION } from 'modules/core/constants'
import { CreateBudgetModal } from 'modules/planning/components'
import {
  DndContext,
  useSensor,
  useSensors,
  closestCenter,
  MouseSensor,
  TouchSensor,
} from '@dnd-kit/core'
import emptyStateImage from 'assets/images/compositions/empty-big.svg'
import planning from 'modules/planning'
import configuration from 'modules/configuration'
import home from 'modules/home'
import login from 'modules/login'

const {
  PLANNING__SALES__BASE__ABM,
  PLANNING__SALES__TOP_DOWN__ABM,
  PLANNING__SALES__BOTTOM_UP__ABM,
  PLANNING__SALES__CLOSING__ABM,
  PLANNING__SALES__BASE__VIEW,
  PLANNING__SALES__TOP_DOWN__VIEW,
  PLANNING__SALES__BOTTOM_UP__VIEW,
  PLANNING__SALES__CLOSING__VIEW,
  PLANNING__EXPENSES__BASE__ABM,
  PLANNING__EXPENSES__TOP_DOWN__ABM,
  PLANNING__EXPENSES__BOTTOM_UP__ABM,
  PLANNING__EXPENSES__CLOSING__ABM,
  PLANNING__EXPENSES__BASE__VIEW,
  PLANNING__EXPENSES__TOP_DOWN__VIEW,
  PLANNING__EXPENSES__BOTTOM_UP__VIEW,
  PLANNING__EXPENSES__CLOSING__VIEW,
  PLANNING__COSTS__BASE__ABM,
  PLANNING__COSTS__TOP_DOWN__ABM,
  PLANNING__COSTS__BOTTOM_UP__ABM,
  PLANNING__COSTS__CLOSING__ABM,
  PLANNING__COSTS__BASE__VIEW,
  PLANNING__COSTS__TOP_DOWN__VIEW,
  PLANNING__COSTS__BOTTOM_UP__VIEW,
  PLANNING__COSTS__CLOSING__VIEW,
  PLANNING__HUMAN_RESOURCES__BASE__ABM,
  PLANNING__HUMAN_RESOURCES__TOP_DOWN__ABM,
  PLANNING__HUMAN_RESOURCES__BOTTOM_UP__ABM,
  PLANNING__HUMAN_RESOURCES__CLOSING__ABM,
  PLANNING__HUMAN_RESOURCES__BASE__VIEW,
  PLANNING__HUMAN_RESOURCES__TOP_DOWN__VIEW,
  PLANNING__HUMAN_RESOURCES__BOTTOM_UP__VIEW,
  PLANNING__HUMAN_RESOURCES__CLOSING__VIEW,
  PLANNING__OTHER_5__BASE__ABM,
  PLANNING__OTHER_5__TOP_DOWN__ABM,
  PLANNING__OTHER_5__BOTTOM_UP__ABM,
  PLANNING__OTHER_5__CLOSING__ABM,
  PLANNING__OTHER_5__BASE__VIEW,
  PLANNING__OTHER_5__TOP_DOWN__VIEW,
  PLANNING__OTHER_5__BOTTOM_UP__VIEW,
  PLANNING__OTHER_5__CLOSING__VIEW,
  PLANNING__OTHER_6__BASE__ABM,
  PLANNING__OTHER_6__TOP_DOWN__ABM,
  PLANNING__OTHER_6__BOTTOM_UP__ABM,
  PLANNING__OTHER_6__CLOSING__ABM,
  PLANNING__OTHER_6__BASE__VIEW,
  PLANNING__OTHER_6__TOP_DOWN__VIEW,
  PLANNING__OTHER_6__BOTTOM_UP__VIEW,
  PLANNING__OTHER_6__CLOSING__VIEW,
} = ROLES

// NOTE: En los roles, los conceptos deberian ser resueltos mediante restricciones de dimensiones y no de operaciones
const PLANNING__CONCEPT = {
  [CONCEPTS.IDS.SALES_ID]: {
    VIEW: [
      PLANNING__SALES__BASE__VIEW,
      PLANNING__SALES__TOP_DOWN__VIEW,
      PLANNING__SALES__BOTTOM_UP__VIEW,
      PLANNING__SALES__CLOSING__VIEW,
    ],
    ABM: [
      PLANNING__SALES__BASE__ABM,
      PLANNING__SALES__TOP_DOWN__ABM,
      PLANNING__SALES__BOTTOM_UP__ABM,
      PLANNING__SALES__CLOSING__ABM,
    ],
  },
  [CONCEPTS.IDS.EXPENSES_ID]: {
    VIEW: [
      PLANNING__EXPENSES__BASE__VIEW,
      PLANNING__EXPENSES__TOP_DOWN__VIEW,
      PLANNING__EXPENSES__BOTTOM_UP__VIEW,
      PLANNING__EXPENSES__CLOSING__VIEW,
    ],
    ABM: [
      PLANNING__EXPENSES__BASE__ABM,
      PLANNING__EXPENSES__TOP_DOWN__ABM,
      PLANNING__EXPENSES__BOTTOM_UP__ABM,
      PLANNING__EXPENSES__CLOSING__ABM,
    ],
  },
  [CONCEPTS.IDS.COSTS_ID]: {
    VIEW: [
      PLANNING__COSTS__BASE__VIEW,
      PLANNING__COSTS__TOP_DOWN__VIEW,
      PLANNING__COSTS__BOTTOM_UP__VIEW,
      PLANNING__COSTS__CLOSING__VIEW,
    ],
    ABM: [
      PLANNING__COSTS__BASE__ABM,
      PLANNING__COSTS__TOP_DOWN__ABM,
      PLANNING__COSTS__BOTTOM_UP__ABM,
      PLANNING__COSTS__CLOSING__ABM,
    ],
  },
  [CONCEPTS.IDS.HUMAN_RESOURCES_ID]: {
    VIEW: [
      PLANNING__HUMAN_RESOURCES__BASE__VIEW,
      PLANNING__HUMAN_RESOURCES__TOP_DOWN__VIEW,
      PLANNING__HUMAN_RESOURCES__BOTTOM_UP__VIEW,
      PLANNING__HUMAN_RESOURCES__CLOSING__VIEW,
    ],
    ABM: [
      PLANNING__HUMAN_RESOURCES__BASE__ABM,
      PLANNING__HUMAN_RESOURCES__TOP_DOWN__ABM,
      PLANNING__HUMAN_RESOURCES__BOTTOM_UP__ABM,
      PLANNING__HUMAN_RESOURCES__CLOSING__ABM,
    ],
  },
  [CONCEPTS.IDS.OTHER_5_ID]: {
    VIEW: [
      PLANNING__OTHER_5__BASE__VIEW,
      PLANNING__OTHER_5__TOP_DOWN__VIEW,
      PLANNING__OTHER_5__BOTTOM_UP__VIEW,
      PLANNING__OTHER_5__CLOSING__VIEW,
    ],
    ABM: [
      PLANNING__OTHER_5__BASE__ABM,
      PLANNING__OTHER_5__TOP_DOWN__ABM,
      PLANNING__OTHER_5__BOTTOM_UP__ABM,
      PLANNING__OTHER_5__CLOSING__ABM,
    ],
  },
  [CONCEPTS.IDS.OTHER_6_ID]: {
    VIEW: [
      PLANNING__OTHER_6__BASE__VIEW,
      PLANNING__OTHER_6__TOP_DOWN__VIEW,
      PLANNING__OTHER_6__BOTTOM_UP__VIEW,
      PLANNING__OTHER_6__CLOSING__VIEW,
    ],
    ABM: [
      PLANNING__OTHER_6__BASE__ABM,
      PLANNING__OTHER_6__TOP_DOWN__ABM,
      PLANNING__OTHER_6__BOTTOM_UP__ABM,
      PLANNING__OTHER_6__CLOSING__ABM,
    ],
  },
}

const PlanningList = ({
  periodId,
  concept,
  fetchBudgetInstanceList,
  budgetInstanceList,
  createBudgetInstance,
  deleteBudget,
  editBudgetName,
  periodList,
  uploadSpreadsheets,
  duplicateBudget,
  orderBudgetCard,
  setBudgetOrder,
  allDimensionsList,
  fetchAllDimensions,
  changeCardColor,
  loggedUser,
  fetchCommentsList,
  commentsList,
}) => {
  const extraConcept = useFeatureIsOn('feature-extra-concept')
  const actionCrudByRole = useFeatureValue('feature-action-crud-role', { planning: 'True' })
  const [isScreenLoading, setIsScreenLoading] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [showCreateModal, setShowCreateModal] = useState(false)
  const [activeId, setActiveId] = useState(null)
  const [searchValue, setSearchValue] = useState('')
  const [dimensionId, setDimensionId] = useState(null)
  const [dataComments, setDataComments] = useState([])
  const [isLoadingComments, setIsLoadingComments] = useState(false)
  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor))

  const { t } = useTranslation()
  let history = useHistory()

  const auxKey = window.location.pathname.replaceAll('/', '-')
  const key = !auxKey.includes('?tab=') ? `${auxKey}?tab=${concept.name}` : auxKey

  useEffect(() => {
    setIsLoadingComments(true)
    fetchCommentsList({ module: key }).then(() => setIsLoadingComments(false))
  }, [fetchCommentsList, key, concept.name])

  useEffect(() => {
    setDataComments(commentsList)
  }, [commentsList])

  useEffect(() => {
    setIsLoading(true)
    fetchBudgetInstanceList(concept.id, periodId, {
      ...(dimensionId && { dimension_id: dimensionId }),
    }).then(() => {
      setIsScreenLoading(false)
      setIsLoading(false)
    })
  }, [fetchBudgetInstanceList, concept.id, periodId, dimensionId])

  useEffect(() => {
    fetchAllDimensions()
  }, [fetchAllDimensions])

  useEffect(() => {
    sendUserDataToGTM(loggedUser, '/planificacion')
  }, [loggedUser])

  const verifyPermission = (concept) => {
    const permission = [
      PROJECTION.GLOBAL_BUDGET.toString(),
      CONCEPTS.IDS.SALES_ID.toString(),
      CONCEPTS.IDS.EXPENSES_ID.toString(),
      CONCEPTS.IDS.COSTS_ID.toString(),
      CONCEPTS.IDS.HUMAN_RESOURCES_ID.toString(),
      CONCEPTS.IDS.OTHER_5_ID.toString(),
      CONCEPTS.IDS.OTHER_6_ID.toString(),
    ]
    if (permission.includes(concept.id.toString())) {
      return isUserAllowed(PLANNING__CONCEPT[concept.id].ABM)
    } else if (extraConcept) {
      return true
    }

    return false
  }

  const getUserCanCreate = () => {
    const canCreate = verifyPermission(concept)
    if (canCreate) {
      return actionCrudByRole.planning === 'True'
    }
    return canCreate
  }

  const dataPeriod = periodList.find((el) => el.id === parseInt(periodId))
  const userCanCreate = getUserCanCreate()
  const areApprovedProjections =
    budgetInstanceList.values.filter((projection) => isApproved(projection.status)).length > 0

  const renderNoDataScreen = () => (
    <EmptyScreen
      image={emptyStateImage}
      title={t('PLANNING_EMPTY_SCREEN_TITLE')}
      description={t('PLANNING_EMPTY_SCREEN_BODY')}
      footer={
        <Button.Primary
          onClick={() => setShowCreateModal(true)}
          disabled={!userCanCreate}
          title="PLANNING_BUDGET_CREATE_ACTION"
        />
      }
    />
  )

  const renderTopActions = () => (
    <UserActions
      tooltipTitle={areApprovedProjections ? t('PLANNING_DISABLED_CREATION_TOOLTIP') : ''}
      onClick={() => setShowCreateModal(true)}
      disabled={!userCanCreate || areApprovedProjections}
      periodId={periodId}
      concept={concept}
      setSearchValue={setSearchValue}
      module_name="planning"
      onClickActivity={() =>
        history.push(`/planificacion/${periodId}/actividad?type=planning&module=planning`)
      }
      onClickWorkflow={() => history.push(`/planificacion/${periodId}/workflow?type=planning`)}
    />
  )

  const renderDataSource = () => {
    if (searchValue) {
      return budgetInstanceList.values
        .asMutable()
        .filter((el) => el.name.toLowerCase().includes(searchValue.toLowerCase()))
    } else {
      return budgetInstanceList.values.asMutable()
    }
  }

  const renderList = () => (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragStart={(event) => handleDragStart(event, setActiveId)}
      onDragEnd={(event) =>
        handleDragEnd({
          event,
          values: budgetInstanceList.values,
          order: orderBudgetCard,
          setOrder: setBudgetOrder,
          type: 'budget_ids',
        })
      }
    >
      <ProjectionCardList
        dataSource={renderDataSource()}
        type={concept.name}
        conceptId={concept.id}
        role={PLANNING__CONCEPT}
        onOpen={(projectionId) =>
          history.push(`/planificacion/${periodId}/${concept.name}/${projectionId}`)
        }
        onOpenActivities={(projectionId, step) =>
          history.push(
            `/planificacion/${periodId}/${concept.name}/${step}/actividad/${projectionId}?type=planning&module=planningDetail`,
          )
        }
        reloadList={() =>
          fetchBudgetInstanceList(concept.id, periodId, {
            ...(dimensionId && { dimension_id: dimensionId }),
          })
        }
        deleteProjection={deleteBudget}
        editProjectionName={editBudgetName}
        duplicateCard={duplicateBudget}
        activeId={activeId}
        loading={isLoading}
        allDimensionsList={allDimensionsList}
        setDimensionId={setDimensionId}
        changeCardColor={changeCardColor}
        module="planning"
        conceptName={concept.name}
        setDataComments={setDataComments}
        dataComments={dataComments}
        onRefetchComments={() => fetchCommentsList({ module: key })}
        loadingComments={isLoadingComments}
      />
    </DndContext>
  )

  return (
    <Spin spinning={isScreenLoading} size="large">
      <Row gutter={[24, 24]}>
        {!isScreenLoading && !isLoading && budgetInstanceList.count <= 0 && (
          <Col span={24}>{renderNoDataScreen()}</Col>
        )}
        {budgetInstanceList.count > 0 && (
          <>
            <Col span={24}>{renderTopActions()}</Col>
            <Col span={24}>{renderList()}</Col>
          </>
        )}
      </Row>
      <CreateBudgetModal
        period={periodId}
        concept={concept}
        visible={showCreateModal}
        onCancel={() => setShowCreateModal(false)}
        actionProjection={createBudgetInstance}
        actionSpreadsheets={uploadSpreadsheets}
        onRefetchProjections={() => fetchBudgetInstanceList(concept.id, periodId)}
        dataPeriod={dataPeriod}
        fileName="PLANNING_TEMPLATE_NAME"
        projectionInstanceList={budgetInstanceList}
        extraProps={{
          period_id: periodId,
        }}
      />
    </Spin>
  )
}

const mapStateToProps = (state) => ({
  budgetInstanceList: planning.selectors.getBudgetInstanceList(state),
  periodList: planning.selectors.getPeriodList(state),
  allDimensionsList: configuration.selectors.getAllDimensionsList(state),
  loggedUser: login.selectors.getWhoAmI(state),
  commentsList: configuration.selectors.getCommentsList(state),
})

const mapDispatchToProps = {
  fetchBudgetInstanceList: planning.actions.fetchBudgetInstanceList,
  createBudgetInstance: planning.actions.createBudgetInstance,
  deleteBudget: planning.actions.deleteBudget,
  editBudgetName: planning.actions.editBudgetName,
  uploadSpreadsheets: home.actions.uploadSpreadsheets,
  duplicateBudget: planning.actions.duplicateBudget,
  orderBudgetCard: planning.actions.orderBudgetCard,
  setBudgetOrder: planning.actions.setBudgetOrder,
  fetchAllDimensions: configuration.actions.fetchAllDimensions,
  changeCardColor: planning.actions.changeCardColor,
  fetchCommentsList: configuration.actions.fetchCommentsList,
}

export default connect(mapStateToProps, mapDispatchToProps)(PlanningList)
