import React from 'react'
import { getDashboardElement } from '../../utils'
import { ElementTable } from './components'

const CardBody = ({ dataElement, settingsChart }) => {
  const renderTable = () => (
    <div style={{ paddingTop: 5 }}>
      <ElementTable dataElement={dataElement} />
    </div>
  )

  return (
    <div className="report-dashboard-chart-area" data-flex={!dataElement.data}>
      {dataElement?.type === 'chart'
        ? getDashboardElement(dataElement, settingsChart)[dataElement?.chart_type]
        : renderTable()}
    </div>
  )
}

export default CardBody
