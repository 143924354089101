import React from 'react'
import { Popconfirm, Space } from 'antd'
import { Button, DropdownMenu, Typography } from 'modules/core/components'
import { DeleteOutlined, EditOutlined, LockOutlined, EyeOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'

const TableActions = ({
  visible,
  onConfirm,
  onCancel,
  titlePopconfirm,
  onClickEdit,
  onClickDelete,
  disabledDelete,
  disabledEdit,
  isSuperuser,
  onClickReset,
  onClickDetail,
  isCurrentUser = false,
}) => {
  const { t } = useTranslation()

  return (
    <Popconfirm
      placement="bottomRight"
      title={<div style={{ width: 300 }}>{t(titlePopconfirm)}</div>}
      okText={t('YES')}
      onConfirm={onConfirm}
      cancelText={t('NO')}
      onCancel={onCancel}
      visible={visible}
    >
      <Space size="middle">
        {!isSuperuser && isCurrentUser && (
          <Button.Icon
            type="text"
            shape="circle"
            icon={<Typography.Icon icon={EyeOutlined} />}
            title={t('ACTION_OPEN_DETAIL')}
            onClick={onClickDetail}
          />
        )}
        <DropdownMenu
          title={t('ACTION_MORE')}
          menu={_.compact([
            {
              title: t('ACTION_EDIT'),
              icon: <EditOutlined />,
              onClick: onClickEdit,
              disabled: disabledEdit,
            },
            {
              title: t('ACTION_DELETE'),
              icon: <DeleteOutlined />,
              onClick: onClickDelete,
              disabled: disabledDelete,
            },
            ...(isSuperuser
              ? [
                  {
                    title: t('LOGIN_FORM_RESET_PASSWORD_TITLE'),
                    icon: <LockOutlined />,
                    onClick: onClickReset,
                    disabled: false,
                  },
                ]
              : []),
          ])}
        />
      </Space>
    </Popconfirm>
  )
}

export default TableActions
