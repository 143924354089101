import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Table } from 'antd'
import { DeleteAndUpdate, InputCell } from 'modules/core/components'
import { TableActions } from './components'
import { onSaveChanges } from './utils'
import societies from 'modules/societies'

const SocietyTable = ({
  editSociety,
  onFetchSocietiesList,
  dataSource,
  loading,
  pagination,
  count,
  onChange,
}) => {
  const [editRow, setEditRow] = useState(null)
  const [dataCellEdit, setDataCellEdit] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const { t } = useTranslation()

  useEffect(() => {
    return () => {
      setEditRow(null)
      setDataCellEdit({})
      setIsLoading(false)
    }
  }, [])

  const onCellEdit = (name, value) => setDataCellEdit({ ...dataCellEdit, [`${name}`]: value })

  const onCancel = () => setEditRow(null)

  const onConfirm = () => {
    setEditRow(null)
    setDataCellEdit({})
    setIsLoading(false)
  }

  const columns = [
    {
      title: t('FIELD_NAME'),
      dataIndex: 'name',
    },
    {
      title: t('FIELD_DESCRIPTION'),
      dataIndex: 'description',
      render: (description, row) => {
        if (editRow && editRow === row.id) {
          return (
            <InputCell
              initialValue={description}
              onCellUpdate={onCellEdit}
              name="description"
              style={{ width: '100%' }}
            />
          )
        }
        return description
      },
    },
    {
      title: t('FIELD_STATUS'),
      dataIndex: 'status',
      render: (status) => (status ? t('STATE_TAG_ACTIVE') : t('STATE_TAG_INACTIVE')),
    },
    {
      title: t('FIELD_ACTIONS'),
      dataIndex: 'id',
      align: 'right',
      width: 150,
      render: (id) => {
        return id !== editRow ? (
          <TableActions
            onFetchSocietiesList={onFetchSocietiesList}
            id={id}
            setEditRow={setEditRow}
            setIsLoading={setIsLoading}
          />
        ) : (
          <DeleteAndUpdate
            onCancel={onCancel}
            onConfirm={onConfirm}
            onSave={() =>
              onSaveChanges({
                editSociety: () => editSociety(id, dataCellEdit),
                setIsLoading,
                onRefetch: onFetchSocietiesList,
                onClose: onConfirm,
              })
            }
            dataCellEdit={dataCellEdit}
            loading={isLoading}
          />
        )
      },
    },
  ]

  return (
    <Table
      rowKey="id"
      size="small"
      bordered
      columns={columns}
      loading={loading || isLoading}
      dataSource={dataSource}
      onChange={onChange}
      pagination={{
        pageSize: pagination.pageSize,
        size: 'small',
        total: count,
        current: pagination.page,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '30'],
      }}
    />
  )
}

const mapDispatchToProps = {
  editSociety: societies.actions.editSociety,
}

export default connect(null, mapDispatchToProps)(SocietyTable)
