import React, { useState } from 'react'
import { UploadModal } from 'modules/core/components'
import { onConfirmUpload } from './utils'
import PROJECTION from '../../constants/projections'

const ProjectionUploadModal = ({
  title,
  visible,
  onCancel,
  downloadTemplateFile = null,
  uploadFile,
  onSuccessUpload = null,
  onFailUpload = null,
  extraBody = null,
  disabled = false,
  disabledTooltip = '',
  enabledTypeOfLoad = false,
  extraInformativeCurrency = false,
}) => {
  const [isUploadingFile, setIsUploadingFile] = useState(false)
  const [fileList, setFileList] = useState([])
  const [value, setValue] = useState(PROJECTION.TYPE_UPLOAD_OPTION_DEFAULT)

  const onCloseUploadModal = () => {
    onCancel()
    setFileList([])
    setIsUploadingFile(false)
  }

  const onAddUploadFile = (file) => {
    setFileList([file])
    return false
  }

  const onChangeRadio = (e) => {
    setValue(e.target.value)
  }

  return (
    <UploadModal
      title={title}
      visible={visible}
      onCancel={onCloseUploadModal}
      onConfirm={() =>
        onConfirmUpload({
          setIsLoading: setIsUploadingFile,
          uploadFile: () => uploadFile(fileList, value),
          onSuccessUpload,
          onFailUpload,
          onClose: onCloseUploadModal,
        })
      }
      downloadTemplateFile={downloadTemplateFile}
      uploadProps={{
        name: 'file',
        accept: '.xls, .xlsx, .csv',
        showUploadList: { showPreviewIcon: false },
        beforeUpload: onAddUploadFile,
        onRemove: (file) => setFileList([]),
        fileList: fileList,
      }}
      isUploadingFile={isUploadingFile}
      extraBody={extraBody}
      disabled={disabled}
      disabledTooltip={disabledTooltip}
      enabledTypeOfLoad={enabledTypeOfLoad}
      onChangeTypeOfLoad={onChangeRadio}
      typeOfLoad={value}
      extraInformativeCurrency={extraInformativeCurrency}
    />
  )
}

export default ProjectionUploadModal
