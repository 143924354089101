import { Button, ButtonActionBar } from 'modules/core/components'
import { useTranslation } from 'react-i18next'
import { Input } from 'antd'

const { Search } = Input

const UserActions = ({ onSearch, disabledCreate, onClickCreate, onClickVariables }) => {
  const { t } = useTranslation()
  return (
    <ButtonActionBar>
      <Search
        className="formulas-search"
        placeholder={t('ACTION_SEARCH')}
        allowClear={true}
        onSearch={onSearch}
      />
      <Button.Primary onClick={onClickCreate} disabled={disabledCreate} title="ACTION_CREATE_NEW" />
      <Button.Default title="CONFIG_RULES_VIEW_VARIABLES_TITLE" onClick={onClickVariables} />
    </ButtonActionBar>
  )
}

export default UserActions
