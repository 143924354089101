import React, { useState } from 'react'
import { Upload, Alert, Space, notification, Tooltip } from 'antd'
import { Button, Typography } from 'modules/core/components'
import { useTranslation } from 'react-i18next'
import emptyImage from 'assets/images/compositions/empty-small.svg'

import './UploadDraggerScreen.scss'

const { Dragger } = Upload

const UploadDraggerScreen = ({
  title,
  description,
  downloadTemplate,
  uploadFile,
  onSuccessUpload = null,
  onFailUpload = null,
  downloadTooltip = '',
  uploadTooltip = '',
  dataType,
  ...props
}) => {
  const { t } = useTranslation()
  const [isUploadingFile, setIsUploadingFile] = useState(false)
  const [fileList, setFileList] = useState([])

  const onConfirmUpload = () => {
    setIsUploadingFile(true)
    notification.close('error_file')
    notification.close('warning_file')
    uploadFile(fileList)
      .then((response) => {
        onSuccessUpload && onSuccessUpload(response)
        setFileList([])
        setIsUploadingFile(false)
      })
      .catch((error) => {
        onFailUpload && onFailUpload(error)
        setFileList([])
        setIsUploadingFile(false)
      })
  }

  const onAddUploadFile = (file) => {
    setFileList([file])
    return false
  }

  return (
    <Space className="upload-dragger-screen" size="large" direction="vertical">
      <Alert
        showIcon
        closable
        className="upload-dragger-download-alert"
        type="info"
        message={t('UPLOAD_FILE_TITLE')}
        description={
          <div>
            {t('INFO_UPLOAD_FILE_BODY_1')}
            <div>
              {t('INFO_UPLOAD_FILE_BODY_2')}{' '}
              <span className="upload-dragger-download-tempate" onClick={downloadTemplate}>
                <Tooltip title={downloadTooltip}>{t('ACTION_DOWNLOAD_TEMPLATE')}</Tooltip>
              </span>
            </div>
          </div>
        }
      />
      <Space className="upload-dragger-screen-containter" size="large" direction="vertical">
        <div className="upload-dragger-title">
          <Typography.Headline level={5} type="primary">
            {title ? title : t('EMPTY_STATE_DEFAULT_TITLE')}
          </Typography.Headline>
          <Typography.Body>{description || t('CONTROL_EMPTY_SCREEN_DRAG_TITLE')}</Typography.Body>
        </div>

        <Dragger
          {...props}
          {...{
            name: 'file',
            accept: '.xls, .xlsx',
            beforeUpload: onAddUploadFile,
            onRemove: (file) => setFileList([]),
            fileList: fileList,
          }}
        >
          <Tooltip title={uploadTooltip} placement="right">
            <Space size="large" direction="vertical">
              <img alt="img" src={emptyImage} />
              <div className="upload-dragger-title">
                <Typography.Body type="title">{t('EMPTY_STATE_DRAG_FILE_TEXT')}</Typography.Body>
                <div className="upload-dragger-body-1"></div>
                {t('INFO_UPLOAD_TOOLTIP')}
              </div>
            </Space>
          </Tooltip>
        </Dragger>
        <Button.Primary
          onClick={onConfirmUpload}
          loading={isUploadingFile}
          disabled={fileList.length <= 0}
          title="ACTION_UPLOAD_START"
        />
      </Space>
    </Space>
  )
}

export default UploadDraggerScreen
