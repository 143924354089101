import * as types from './constants'

export const fetchQuestionList =
  (params = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.FETCH_QUESTION_LIST,
      payload: {
        request: {
          url: `/common/question/`,
          method: 'GET',
          params,
        },
      },
    })

    if (response.error) {
      return Promise.reject(response.error)
    }

    return response
  }

export const createQuestion =
  (data = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.CREATE_QUESTION,
      payload: {
        request: {
          url: `/common/question/`,
          method: 'POST',
          data: data,
        },
      },
    })

    if (response.error) {
      return Promise.reject(response.error)
    }

    return response
  }

export const editQuestion =
  (questionId, data = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.EDIT_QUESTION,
      payload: {
        request: {
          url: `/common/question/${questionId}/`,
          method: 'PUT',
          data,
        },
      },
    })

    if (response.error) {
      return Promise.reject(response.error)
    }

    return response
  }

export const deleteQuestion =
  (questionId, data = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.DELETE_QUESTION,
      payload: {
        request: {
          url: `/common/question/${questionId}/`,
          method: 'DELETE',
          data,
        },
      },
    })

    if (response.error) {
      return Promise.reject(response.error.response.data)
    }

    return response
  }
