import TSVExport from '../TSVExport'
import makeRenderer from './Table'
import TablePxQ from './TablePxQ'

export default {
  'Table': makeRenderer({ subtotals: true }),
  'Table PXQ': TablePxQ,
  'Table Heatmap': makeRenderer({ heatmapMode: 'full', subtotals: true }),
  'Table Col Heatmap': makeRenderer({ heatmapMode: 'col', subtotals: true }),
  'Table Row Heatmap': makeRenderer({ heatmapMode: 'row', subtotals: true }),
  'Exportable TSV': TSVExport,
}
