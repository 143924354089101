import React from 'react'
import { Modal, Upload, Col, Row, Tooltip, Space, Alert } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import ButtonActionBar from '../ButtonActionBar/ButtonActionBar'
import Button from '../Button/Button'
import TypeUploadOptions from '../TypeUploadOptions/TypeUploadOptions'

import './UploadModal.scss'

const UploadModal = ({
  visible,
  onConfirm,
  onCancel,
  downloadTemplateFile,
  uploadProps,
  isUploadingFile,
  title,
  extraBody = null,
  disabled = false,
  disabledTooltip = '',
  afterOnClose = null,
  extraFooter = null,
  enabledTypeOfLoad = false,
  onChangeTypeOfLoad = () => {},
  typeOfLoad = null,
  extraInformativeCurrency = false,
}) => {
  const { t } = useTranslation()
  return (
    <Modal
      title={title ? title : t('ACTION_UPLOAD_FILE')}
      visible={visible}
      centered
      onCancel={!isUploadingFile ? onCancel : null}
      closable={true}
      destroyOnClose={true}
      afterClose={afterOnClose}
      wrapClassName="upload-modal"
      footer={
        <ButtonActionBar position={extraFooter ? 'space-between' : 'end'}>
          {extraFooter}
          <Space direction="horizontal">
            <Button.Default onClick={onCancel} disabled={isUploadingFile} title="ACTION_CANCEL" />
            <Tooltip
              title={
                uploadProps.fileList.length <= 0
                  ? t('UPLOAD_FILE_PLACEHOLDER')
                  : disabled && disabledTooltip
              }
            >
              <Button.Primary
                onClick={onConfirm}
                loading={isUploadingFile}
                disabled={isUploadingFile || uploadProps.fileList.length <= 0 || disabled}
                title="ACTION_UPLOAD"
              />
            </Tooltip>
          </Space>
        </ButtonActionBar>
      }
    >
      <Row gutter={[8, 24]} type="flex" justify="center" className="upload-modal-body">
        {extraInformativeCurrency && (
          <Col span={24}>
            <Alert
              type="info"
              showIcon
              closable
              description={t('INFORMATIVE_ALERT_UPLOAD_DATA_CURRENCY')}
            />
          </Col>
        )}
        {extraBody && (
          <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
            {extraBody}
          </Col>
        )}
        {enabledTypeOfLoad && (
          <Col span={24}>
            <TypeUploadOptions onChange={onChangeTypeOfLoad} value={typeOfLoad} />
          </Col>
        )}

        <Col>
          <Upload {...uploadProps}>
            {uploadProps.fileList.length <= 0 && (
              <Button.DefaultWhitILeftIcon
                icon={<UploadOutlined />}
                title="UPLOAD_FILE_PLACEHOLDER"
              />
            )}
          </Upload>
        </Col>
        {downloadTemplateFile && (
          <Col>
            <div className="upload-moda-help">
              {t('INFO_UPLOAD_FILE_MODAL_DESCRIPTION')}{' '}
              <span onClick={downloadTemplateFile}>{t('INFO_UPLOAD_FILE_DOWNLOAD_ACTION')}</span>
            </div>
          </Col>
        )}
      </Row>
    </Modal>
  )
}

export default UploadModal
