import * as types from './constants'
import { saveAs } from 'file-saver'
import queryString from 'query-string'
import i18n from 'i18next'

export const fetchReportList =
  (params = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.FETCH_REPORT_LIST,
      payload: {
        request: {
          url: `/reports/get_all_reports_structure/`,
          method: 'GET',
          params,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const fetchAnalysisList =
  (params = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.FETCH_ANALYSIS_LIST,
      payload: {
        request: {
          url: `/reports/get_reports/`,
          method: 'GET',
          params,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const fetchReportDetail =
  (reportId, data = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.FETCH_REPORT_DETAIL,
      payload: {
        request: {
          url: `/reports/get_report_data_table/${reportId}/`,
          method: 'POST',
          data,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const fetchReportComparativeDetail =
  (reportId, data = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.FETCH_REPORT_COMPARATIVE_DETAIL,
      payload: {
        request: {
          url: `/reports/get_report/${reportId}/comparative/`,
          method: 'POST',
          data,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const setReportLineSelected = (lineId) => (dispatch) =>
  dispatch({
    type: types.SET_REPORT_LINE_SELECTED,
    payload: lineId,
  })

export const setReportOrder = (data) => (dispatch) =>
  dispatch({
    type: types.SET_REPORT_ORDER,
    payload: data,
  })

export const setSettingsCharts = (data) => (dispatch) =>
  dispatch({
    type: types.SET_SETTINGS_CHARTS,
    payload: data,
  })

export const fetchAnalysisKpi =
  (reportId, params = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.FETCH_ANALYSIS_KPI,
      payload: {
        request: {
          url: `/reports/get_report/${reportId}/kpis/`,
          method: 'GET',
          params,
          paramsSerializer: (params) => queryString.stringify(params),
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const fetchAnalysisFilters = () => async (dispatch) => {
  const response = await dispatch({
    type: types.FETCH_ANALYSIS_FILTERS,
    payload: {
      request: {
        url: `/reports/get_filter_report/`,
        method: 'GET',
      },
    },
  })
  if (response.error) {
    return Promise.reject(response.error)
  }
  return response
}

export const fetchReportDetailStructure =
  (reportId, params = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.FETCH_REPORT_DETAIL_STRUCTURE,
      payload: {
        request: {
          url: `/reports/${reportId}/get_reports_structure/`,
          method: 'GET',
          params,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const downloadReportStructure =
  (report_id, filename = i18n.t('CONFIG_REPORT_STRUCTURE_FILE_NAME')) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.DOWNLOAD_CUSTOM_XLS,
      payload: {
        request: {
          url: `/reports/${report_id}/config_report_download/`,
          method: 'GET',
          responseType: 'blob',
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    saveAs(response.payload.data, `${filename}.xlsx`)
    return Promise.resolve()
  }

// const fetchReportDataRow =
//   (rowId, type = types.FETCH_REPORT_ROW, params = {}) =>
//   async dispatch => {
//     // NOTE: Me quedo con el primer elemento que representa al padre raiz.
//     const rootRowId = `${rowId}`.split('_')[0];
//     const response = await dispatch({
//       type: type,
//       payload: {
//         request: {
//           url: `/report/reports/rows/${rootRowId}/`,
//           method: 'GET',
//           params: {
//             ...params,
//             row_id_front: rowId,
//           },
//           paramsSerializer: params => queryString.stringify(params),
//         },
//       },
//     });
//     if (response.error) {
//       return Promise.reject(response.error);
//     }
//     return response;
//   };

// export const fetchReportRow =
//   (rowId, params = {}) =>
//   async dispatch =>
//     dispatch(
//       fetchReportDataRow(rowId, types.FETCH_REPORT_ROW, {
//         ...params,
//         table: 'report',
//       })
//     );

export const fetchTableOpenRow =
  (reportId, lineId, data = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.FETCH_TABLE_OPEN_ROW,
      payload: {
        request: {
          url: `/reports/report/${reportId}/date_table/line/${lineId}/`,
          method: 'POST',
          data,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const fetchComparativeTableOpenRow =
  (reportId, lineId, data = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.FETCH_TABLE_COMPARATIVE_OPEN_ROW,
      payload: {
        request: {
          url: `/reports/report/${reportId}/comparative/line/${lineId}/`,
          method: 'POST',
          data,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const createReportByFile = (data) => async (dispatch) => {
  const { files_data, description, reporte, type_file, period_id, initial_amount } = data
  const formData = new FormData()
  files_data.fileList.forEach((file) => {
    formData.append('files_data', file['originFileObj'])
  })
  description && formData.append('description', description)
  formData.append('reporte', reporte)
  formData.append('type_file', type_file)
  formData.append('period_id', period_id)
  if (initial_amount) {
    formData.append('initial_amount', initial_amount)
  }

  const response = await dispatch({
    type: types.CREATE_REPORT_BY_FILE,
    payload: {
      request: {
        url: '/reports/upload_file/',
        method: 'POST',
        data: formData,
      },
    },
  })
  if (response.error) {
    return Promise.reject(response.error)
  }
  return response
}

export const editReport =
  (reportId, data = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.EDIT_REPORT,
      payload: {
        request: {
          url: `/report/reports/${reportId}/`,
          method: 'PATCH',
          data,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const deleteReport = (reportId) => async (dispatch) => {
  const response = await dispatch({
    type: types.DELETE_REPORT,
    payload: {
      request: {
        url: `reports/delete_report/${reportId}/`,
        method: 'DELETE',
      },
    },
  })
  if (response.error) {
    return Promise.reject(response.error)
  }
  return response
}

export const downloadEvolutionXLS =
  (reportId, data = {}, filename = i18n.t('REPORT_EVOLUTION_FILE_NAME_DEFAULT')) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.DOWNLOAD_EVOLUTION_XLS,
      payload: {
        request: {
          url: `/reports/report/${reportId}/evolution/download/`,
          method: 'POST',
          responseType: 'blob',
          data,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }

    saveAs(response.payload.data, `${filename}.xlsx`)
    return Promise.resolve()
  }

export const downloadTemplateXLS =
  (filename = i18n.t('REPORT_TEMPLATE_FILE_NAME')) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.DOWNLOAD_TEMPLATE_XLS,
      payload: {
        request: {
          url: `/reports/export_template/`,
          method: 'POST',
          responseType: 'blob',
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    saveAs(response.payload.data, `${filename}.xlsx`)
    return Promise.resolve()
  }

export const downloadComparativeXLS =
  (reportId, data = {}, filename = i18n.t('REPORT_EVOLUTION_FILE_NAME_DEFAULT')) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.DOWNLOAD_COMPARE_XLS,
      payload: {
        request: {
          url: `/reports/report/${reportId}/comparative/download/`,
          method: 'POST',
          responseType: 'blob',
          data,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }

    saveAs(response.payload.data, `${filename}.xlsx`)
    return Promise.resolve()
  }

export const fetchReportDahsboardList =
  (reportId, periodId, data = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.FETCH_REPORT_DASHBOARD_LIST,
      payload: {
        request: {
          url: `/dashboard_reports/list_elements/${periodId}/${reportId}/`,
          method: 'POST',
          data,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const createDashboardElement =
  (reportId, periodId, data = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.CREATE_DASHBOARD_ELEMENT,
      payload: {
        request: {
          url: `/dashboard_reports/create_elements/${periodId}/${reportId}/`,
          method: 'POST',
          data,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const editDashboardElement =
  (id, data = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.EDIT_DASHBOARD_ELEMENT,
      payload: {
        request: {
          url: `/dashboard_reports/update/${id}/`,
          method: 'PATCH',
          data,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const deleteDashboardElement = (reportId, id) => async (dispatch) => {
  const response = await dispatch({
    type: types.DELETE_DASHBOARD_ELEMENT,
    payload: {
      request: {
        url: `/dashboard_reports/delete/${reportId}/${id}/`,
        method: 'DELETE',
      },
    },
  })
  if (response.error) {
    return Promise.reject(response.error)
  }
  return response
}

export const downloadReportPPT =
  (reportId, data = {}, filename) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.DOWNLOAD_REPORT_PPT,
      payload: {
        request: {
          url: `/reports/download_pptx/${reportId}/`,
          method: 'POST',
          responseType: 'blob',
          data,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }

    saveAs(response.payload.data, `${filename}.pptx`)
    return Promise.resolve()
  }

export const downloadReportPPTDrive =
  (reportId, data = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.DOWNLOAD_REPORT_PPT_DRIVE,
      payload: {
        request: {
          url: `/reports/save_reports_on_drive/${reportId}/`,
          method: 'POST',
          data,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const orderReports =
  (data = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.ORDER_REPORTS,
      payload: {
        request: {
          url: `/reports/order_reports/`,
          method: 'POST',
          data,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const fetchDashboardUrl = () => async (dispatch) => {
  const response = await dispatch({
    type: types.FETCH_DASHBOARD_URL,
    payload: {
      request: {
        url: `/integrations/quicksight-user/`,
        method: 'GET',
      },
    },
  })

  if (response.error) {
    return Promise.reject(response.error)
  }

  return response
}

// TODO: NEW ENDPOINTS FOR REVAMP TABLE
export const fetchDataEvolutionByReport =
  (reportId, data = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.FETCH_DATA_EVOLUTION_BY_REPORT,
      payload: {
        request: {
          url: `/reports/get_report_data_all_table/${reportId}/`,
          method: 'POST',
          data: { ...data },
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const fetchDataGraphAndKpiByReport =
  (reportId, data = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.FETCH_DATA_GRAPH_AND_KPI_BY_REPORT,
      payload: {
        request: {
          url: `/reports/get_report_graphic/${reportId}/`,
          method: 'POST',
          data: { ...data },
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const fetchDataComparativeByReport =
  (reportId, data = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.FETCH_DATA_COMPARATIVE_BY_REPORT,
      payload: {
        request: {
          url: `/reports/get_report_comparative_data_all_table/${reportId}/`,
          method: 'POST',
          data: { ...data },
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const updatedInitialAmountByFinancial =
  (data = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.UPDATED_INITIAL_AMOUNT_BY_FINANCIAL,
      payload: {
        request: {
          url: `/reports/update_value_financial/`,
          method: 'PUT',
          data: { ...data },
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }
