import React from 'react'
import { Card } from 'antd'
import { CardBody, CardTitle } from './components'

const CardReport = ({
  dataList,
  reportStructure,
  lineReportId,
  setLineReportId,
  isLinesReportLoading,
  type,
}) => {
  return (
    <Card
      title={
        <CardTitle
          type={type}
          reportStructure={reportStructure}
          lineReportId={lineReportId}
          setLineReportId={setLineReportId}
          isLinesReportLoading={isLinesReportLoading}
        />
      }
      style={{ marginRight: 10 }}
      size="small"
      className="report-list-chart"
    >
      <CardBody
        percentage={dataList.percentage}
        value={dataList.value}
        prefix={dataList.prefix}
        name={dataList.name}
        subKpis={dataList.children}
      />
    </Card>
  )
}

export default CardReport
