//NOTE: ARMADO COMO ARRAY PORQUE NO ME TOMABA LA TRADUCCION SI LO HACIA COMO OBJETO Y TAMPOCO USANDO I18N.T() DESDE ACA. REVEER
const LOCALE_NAMES = [
  { key: 'back', label: 'ACTION_RETURN' },
  { key: 'close', label: 'ACTION_CLOSE' },
  { key: 'last', label: 'ACTION_FINISH' },
  { key: 'next', label: 'ACTION_NEXT' },
  { key: 'skip', label: 'ACTION_SKIP' },
]

const STEPS_STYLES = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  padding: 10,
  // fontWeight: 405,
}

const ONBOARDING = {
  LOCALE_NAMES,
  STEPS_STYLES,
}

export default ONBOARDING
