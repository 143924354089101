import React from 'react'
import { Popconfirm } from 'antd'
import { Comments, DropdownMenu, Typography } from 'modules/core/components'
import { CHARTS } from 'modules/core/constants'
import { useTranslation } from 'react-i18next'
import {
  CloseCircleOutlined,
  EditOutlined,
  ExportOutlined,
  SettingOutlined,
  CommentOutlined,
} from '@ant-design/icons'
import _ from 'lodash'

const ExtraActions = ({
  onConfirmPopconfirm,
  loading,
  onCancelPopconfirm,
  visible,
  onClickEdit,
  showConfig,
  onClickConfig,
  onClickExport,
  onClickDelete,
  showComment,
  setHasComment,
  hasComment,
  setViewComment,
  setDataComments,
  dataComments,
  onRefetchComments,
  loadingComments,
  onCommentCreate,
  elementId,
  userDetail,
  removeNewComment,
  commentKey,
}) => {
  const { t } = useTranslation()

  return (
    <>
      {showComment && (
        <Comments
          hasComment={
            !_.isEmpty(hasComment?.chart)
              ? !_.isEmpty(hasComment?.chart[commentKey]) &&
                hasComment?.chart[commentKey][elementId]
              : false
          }
          onClick={(visible) => {
            setHasComment({ type: 'chart', data: visible, key: commentKey, id: elementId })
            !visible && removeNewComment()
            !visible && setViewComment(visible)
          }}
          comments={dataComments}
          setData={setDataComments}
          loading={loadingComments}
          onRefetchComments={onRefetchComments}
          onCommentCreate={onCommentCreate}
          onCleanHasComment={() => {
            setHasComment({ type: 'chart', data: false, key: commentKey, id: elementId })
            setViewComment(false)
          }}
        >
          <Typography.Icon
            style={{ cursor: 'pointer' }}
            title={t('LABEL_COMMENTS')}
            icon={CommentOutlined}
          />
        </Comments>
      )}
      <Popconfirm
        placement="bottomRight"
        title={t('POPCONFIRM_REMOVE_DASHBOARD_ELEMENT')}
        okText={t('ACTION_REMOVE')}
        onConfirm={onConfirmPopconfirm}
        okButtonProps={{ loading, disabled: loading }}
        cancelButtonProps={{ disabled: loading }}
        cancelText={t('ACTION_CANCEL')}
        onCancel={onCancelPopconfirm}
        visible={visible}
      >
        <DropdownMenu
          title={t('ACTION_MORE')}
          menu={[
            {
              title: t('ACTION_EDIT'),
              icon: <EditOutlined />,
              onClick: onClickEdit,
              disabled: false,
            },
            ...(showConfig
              ? [
                  {
                    title: t('ACTION_CONFIGURATE'),
                    icon: <SettingOutlined />,
                    onClick: onClickConfig,
                    disabled: false,
                  },
                ]
              : []),
            {
              title: t('ACTION_EXPORT'),
              icon: <ExportOutlined />,
              submenu: CHARTS.EXPORT_FORMAT_TYPE_OPTIONS.map((formatType) => {
                return {
                  title: formatType.name,
                  key: formatType.id,
                  onClick: () => onClickExport(formatType),
                }
              }),
            },
            {
              title: t('ACTION_REMOVE'),
              icon: <CloseCircleOutlined />,
              onClick: onClickDelete,
              disabled: false,
            },
          ]}
        />
      </Popconfirm>
    </>
  )
}

export default ExtraActions
