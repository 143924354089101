import React, { useEffect, useRef, useState } from 'react'
import { Card } from 'antd'
import { LineChart } from 'modules/core/components'
import { CHARTS } from 'modules/core/constants'
import { connect } from 'react-redux'
import home from 'modules/home'
import numeral from 'numeral'

const ReportChart = ({
  period_id,
  projectionId,
  dataProjectionChart,
  fetchDataProjectionChart,
}) => {
  const [projectionChartLoading, setProjectionChartLoading] = useState(false)

  const chartRef = useRef(null)

  useEffect(() => {
    if (period_id) {
      setProjectionChartLoading(true)
      fetchDataProjectionChart({ period_id, projection: projectionId }).then(() =>
        setProjectionChartLoading(false),
      )
    }
    return () => setProjectionChartLoading(false)
  }, [fetchDataProjectionChart, period_id, projectionId])

  return (
    <div ref={chartRef}>
      <Card headStyle={{ height: 56 }} bodyStyle={{ padding: 0 }} loading={projectionChartLoading}>
        <LineChart
          containerHeight={253}
          data={dataProjectionChart.asMutable({ deep: true })}
          colors={CHARTS.COLORS_BUDGET_TYPES}
          axisLeft={{
            format: (value) => `$ ${numeral(value).format('0,0[.]0 a')}`,
          }}
          margin={{ right: 130 }}
          value={dataProjectionChart.asMutable({ deep: true }).map((val) => val)}
        />
      </Card>
    </div>
  )
}

const mapStateToProps = (state) => ({
  dataProjectionChart: home.selectors.getDataProjectionChart(state),
})

const mapDispatchToProps = {
  fetchDataProjectionChart: home.actions.fetchDataProjectionChart,
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportChart)
