import { ReconciliationOutlined, ShopOutlined } from '@ant-design/icons'
import React from 'react'

const adminMenu = (t) => [
  {
    key: 'empresas',
    title: t('LABEL_SOCIETIES'),
    url: `/empresas`,
    exact: false,
    icon: <ShopOutlined />,
    allowedFor: [],
  },
  {
    key: 'preguntas',
    title: t('LABEL_QUESTION'),
    url: `/preguntas`,
    exact: false,
    icon: <ReconciliationOutlined />,
    allowedFor: [],
  },
]

export default adminMenu
