import { useState } from 'react'
import { Card, Col, Dropdown, Menu, message, Row, Space } from 'antd'
import { KPIValue } from 'modules/core/components/KPI/KPI'
import { CommentOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import Typography from 'modules/core/components/Typography/Typography'
import numeral from 'numeral'
import Comments from 'modules/core/components/Comments/Comments'
import login from 'modules/login'
import configuration from 'modules/configuration'
import _ from 'lodash'
import { generateMessageError, renderPrefix } from 'modules/core/utils'
import { GENERAL } from 'modules/core/constants'

const CardKpi = ({
  title,
  id,
  variation,
  value,
  subKpis,
  hasComment,
  setHasComment,
  loggedUser,
  setDataComments,
  dataComments,
  onRefetchComments,
  loadingComments,
  createComment,
  removeNewComment,
  currencyData,
}) => {
  const [viewComment, setViewComment] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const { t } = useTranslation()

  const key = window.location.pathname.replaceAll('/', '-')

  const handleCreate = ({ text, usersIds, idComment = null, setIsNewThread }) => {
    const dataa = {
      text,
      key: `${key}-card-${id}`,
      module: key,
      mentions: usersIds,
      ...(idComment && {
        parent: idComment,
      }),
    }
    setIsSaving(true)
    createComment(dataa)
      .then(() =>
        onRefetchComments().then(() => {
          message.success(t('FEEDBACK_SAVE_CHANGES_SUCCES'), 8)
          setIsSaving(false)
          setIsNewThread(false)
        }),
      )
      .catch((error) => {
        message.error(generateMessageError(error, 'FEEDBACK_SAVE_CHANGES_FAIL_MESSAGE'), 8)
        setIsSaving(false)
        setIsNewThread(false)
      })
  }

  return (
    <Dropdown
      trigger={['contextMenu']}
      overlay={
        <Menu>
          {GENERAL.OPTION_COMMENT.map((el) => (
            <Menu.Item
              key={el.key}
              icon={<CommentOutlined />}
              onClick={() => {
                setViewComment(true)
                setHasComment({
                  type: 'card',
                  data: !_.isEmpty(hasComment?.card)
                    ? !_.isEmpty(hasComment?.card[key])
                      ? !hasComment?.card[key][id]
                      : true
                    : true,
                  key,
                  id,
                })
              }}
            >
              {t(el.label)}
            </Menu.Item>
          ))}
        </Menu>
      }
    >
      <Card
        key={id}
        className="global-detail-kpi-card"
        data-type={title !== 'Resultado neto' ? id : title}
      >
        <Row gutter={[16, 16]} align="middle">
          {(viewComment ||
            dataComments.filter((el) => el.key === `${key}-card-${id}` && !el.resolved).length >
              0) && (
            <Comments
              hasComment={
                !_.isEmpty(hasComment?.card)
                  ? !_.isEmpty(hasComment?.card[key]) && hasComment?.card[key][id]
                  : false
              }
              onClick={(visible) => {
                setHasComment({ type: 'card', data: visible, key, id })
                !visible && removeNewComment()
                !visible && setViewComment(visible)
              }}
              comments={dataComments.filter(
                (el) => (el.key === `${key}-card-${id}` && !el.resolved) || !el.text,
              )}
              setData={setDataComments}
              loading={loadingComments || isSaving}
              onRefetchComments={onRefetchComments}
              onCommentCreate={(text, usersIds, idComment, setIsNewThread) =>
                handleCreate({ text, usersIds, idComment, setIsNewThread })
              }
              onCleanHasComment={() => {
                setHasComment({ type: 'card', data: false, key, id })
                setViewComment(false)
              }}
            >
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'flex-end',
                  position: 'absolute',
                  zIndex: 1,
                  left: -10,
                  top: 10,
                }}
              >
                <Typography.Icon
                  style={{ cursor: 'pointer' }}
                  title={t('LABEL_COMMENTS')}
                  level={2}
                  icon={CommentOutlined}
                />
              </div>
            </Comments>
          )}
          <Col span={12}>
            <KPIValue
              size="medium"
              fullValue={value && numeral(value).format('0,0.00')}
              value={value ? numeral(value).format('0[.]0a') : '-'}
              prefix={renderPrefix(currencyData)}
            />
            <Typography.Body level={3} type="secondary">
              {title}
            </Typography.Body>
          </Col>
          {variation && (
            <Col style={{ paddingLeft: 0 }} span={4}>
              <KPIValue isVariation={true} value={variation ? variation : '-'} type="secondary" />
            </Col>
          )}
          <Col span={11}>
            {subKpis &&
              subKpis.map((dataChildren, index) => (
                <Row gutter={16} key={index}>
                  <Col span={16}>
                    <Space direction="vertical" size="small">
                      <KPIValue
                        fullValue={
                          dataChildren.value && numeral(dataChildren.value).format('0,0.00')
                        }
                        value={
                          dataChildren.value ? numeral(dataChildren.value).format('0[.]0a') : '-'
                        }
                        prefix={renderPrefix(currencyData)}
                      />
                      <Typography.Body level={3} type="secondary">
                        {dataChildren.title}
                      </Typography.Body>
                    </Space>
                  </Col>
                  {dataChildren.variation && (
                    <Col span={8}>
                      <KPIValue
                        isVariation={true}
                        value={dataChildren.variation ? dataChildren.variation : '-'}
                        type="secondary"
                      />
                    </Col>
                  )}
                </Row>
              ))}
          </Col>
        </Row>
      </Card>
    </Dropdown>
  )
}

const mapStateToProps = (state) => ({
  loggedUser: login.selectors.getWhoAmI(state),
  hasComment: configuration.selectors.getHasComment(state),
})

const mapDispatchToProps = {
  setHasComment: configuration.actions.setHasComment,
  createComment: configuration.actions.createComment,
  removeNewComment: configuration.actions.removeNewComment,
}

export default connect(mapStateToProps, mapDispatchToProps)(CardKpi)
