import reports from 'modules/reports'
import { connect } from 'react-redux'
import { setChartTitle } from '../../utils'
import { SelectReportLine } from './components'

const ChartTitle = ({ rows, lineId, selectedDates, setReportLineSelected }) => {
  const renderSelectReporLine = () => (
    <SelectReportLine rows={rows} lineId={lineId} onChange={(id) => setReportLineSelected(id)} />
  )

  return (
    <>
      {setChartTitle(selectedDates)}
      {rows.length > 0 && renderSelectReporLine()}
    </>
  )
}

const mapDispatchToProps = {
  setReportLineSelected: reports.actions.setReportLineSelected,
}

export default connect(null, mapDispatchToProps)(ChartTitle)
