import { message } from 'antd'
import { generateMessageError } from 'modules/core/utils'
import i18n from 'i18next'

export const globalProjectionCreation = ({
  form,
  setIsLoading,
  createGlobalProjection,
  onRefetch,
  onClose,
}) => {
  form
    .validateFields()
    .then((values) => {
      setIsLoading(true)
      createGlobalProjection(values)
        .then(() => {
          message.success(i18n.t('PLANNING_CREATE_BUDGET_SUCCESS_FEEDBACK'), 8)
          onRefetch().then(() => setIsLoading(false))
          onClose()
        })
        .catch((error) => {
          onClose()
          message.error(generateMessageError(error, 'PLANNING_COPY_BUDGET_ERROR_FEEDBACK'), 8)
        })
    })
    .catch((e) => {})
}

export const isAvailable = (form) =>
  form.getFieldValue('overall_budget')?.length > 0 &&
  form
    .getFieldValue('overall_budget')
    .every((data) => data && data.concept_id && (data.planning_id || data.forecast_id))
