import React from 'react'
import { Button, DropdownMenu } from 'modules/core/components'
import { DownOutlined } from '@ant-design/icons'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import reports from 'modules/reports'
import moment from 'moment'

const DOWNLOAD_PPT_OPTIONS = [
  { id: 'device', name: 'Dispositivo' },
  { id: 'drive', name: 'Drive' },
]

const ButtonDownloadPPT = ({
  reportId,
  params,
  periodDetail,
  name_report,
  setShowModal,
  downloadReportPPT,
}) => {
  const { t } = useTranslation()

  const download = {
    device: () =>
      downloadReportPPT(
        reportId,
        params,
        t('REPORT_PPT_FILE_NAME', {
          reportName: name_report,
          periodName: periodDetail.name,
          date: moment().format('lll'),
        }),
      ),
    drive: () => setShowModal(true),
  }

  return (
    <DropdownMenu
      title={t('ACTION_DOWNLOAD_PPT')}
      placement="bottomRight"
      menu={DOWNLOAD_PPT_OPTIONS.map((formatType) => {
        return {
          title: formatType.name,
          key: formatType.id,
          onClick: download[formatType.id],
        }
      })}
    >
      <Button.PrimaryWhitRightIcon icon={<DownOutlined />} title="ACTION_DOWNLOAD_PPT" />
    </DropdownMenu>
  )
}

const mapDispatchToProps = {
  downloadReportPPT: reports.actions.downloadReportPPT,
}

export default connect(null, mapDispatchToProps)(ButtonDownloadPPT)
