import React, { useEffect, useState } from 'react'
import { Table, Space, Tooltip, Switch } from 'antd'
import {
  ConfirmDeleteUserModal,
  DeleteAndUpdate,
  InputCell,
  SelectCell,
  Typography,
} from 'modules/core/components'
import { InfoCircleOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { TableActions } from './components'
import { connect } from 'react-redux'
import { handleActivate, handleSave, onConfirmReset } from './utils'
import { useHistory } from 'react-router-dom'
import configuration from 'modules/configuration'
import login from 'modules/login'

//TODO: AGREGAR EDITAR
//CONSULTAR SI EL MISMO USUARIO SE PUEDE EDITAR EL MISMO
const UsersTable = ({
  isLoading,
  onTableChange,
  pagination,
  onRefetch,
  dataSource,
  dataSelection,
  setDataSelection,
  canDelete,
  rolesList,
  fetchRolesList,
  fetchUserRoleRelation,
  deleteUserRoleRelation,
  assignRole,
  loggedUser: { society_id, isSuperuser, pkid },
  editUser,
  activateUser,
  resetPasswordUser,
}) => {
  const [isSaving, setIsSaving] = useState(false)
  const [deleteUserId, setDeleteUserId] = useState(null)
  const [showModalConfirm, setShowModalConfirm] = useState(false)
  const [userId, setUserId] = useState(null)
  const [editRow, setEditRow] = useState(null)
  const [dataCellEdit, setDataCellEdit] = useState({})
  const [resetId, setResetId] = useState(null)
  const { t } = useTranslation()
  let history = useHistory()

  useEffect(() => {
    fetchRolesList({ society: society_id })
  }, [fetchRolesList, society_id])

  const onCellEdit = (name, value) => {
    setDataCellEdit({ ...dataCellEdit, [`${name}`]: value })
  }

  const onCleanData = () => {
    setUserId(null)
    setIsSaving(false)
  }

  const onConfirmDelete = () => {
    setShowModalConfirm(true)
    setDeleteUserId(null)
  }

  const onCloseReset = () => {
    setIsSaving(false)
    setResetId(null)
  }

  const onSetDeleteUser = (id) => {
    setDeleteUserId(id)
    setUserId(id)
  }

  const onCleanDataEdit = () => {
    setIsSaving(false)
    setDataCellEdit({})
    setEditRow(null)
  }

  const columns = [
    {
      title: t('FIELD_EMAIL'),
      dataIndex: 'email',
      editable: true,
      render: (email, row) => {
        if (editRow && editRow === row.id && !isSuperuser) {
          return <InputCell initialValue={email} onCellUpdate={onCellEdit} name="email" />
        }
        return email
      },
    },
    {
      title: t('FIELD_NAME'),
      dataIndex: 'first_name',
      editable: true,
      render: (first_name, row) => {
        if (editRow && editRow === row.id && !isSuperuser) {
          return <InputCell initialValue={first_name} onCellUpdate={onCellEdit} name="first_name" />
        }
        return first_name
      },
    },
    {
      title: t('FIELD_SURNAME'),
      dataIndex: 'last_name',
      editable: true,
      render: (last_name, row) => {
        if (editRow && editRow === row.id && !isSuperuser) {
          return <InputCell initialValue={last_name} onCellUpdate={onCellEdit} name="last_name" />
        }
        return last_name
      },
    },
    {
      title: t('LABEL_ROLES'),
      dataIndex: 'roles',
      render: (roles, row) => {
        return row.id === editRow && !isSuperuser ? (
          <SelectCell
            initialValue={roles.map((rol) => rol.id)}
            onCellUpdate={onCellEdit}
            name="role_ids"
            options={rolesList.data}
            mode="multiple"
            maxTagCount={1}
            maxTagTextLength={8}
            showArrow
            allowClear
          />
        ) : (
          roles.map((rol) => rol.name).join(', ')
        )
      },
    },
    {
      title: t('FIELD_STATUS'),
      dataIndex: '',
      render: (record, row) => {
        if (editRow && editRow === row.id && isSuperuser && !record.is_active) {
          return (
            <Switch
              title={!record.is_active && t('ACTION_ACTIVATE')}
              checkedChildren={t('STATE_TAG_ACTIVE')}
              unCheckedChildren={t('STATE_TAG_INACTIVE')}
              onChange={(val) => {
                val && onCellEdit('status', val)
                !val && setDataCellEdit({})
              }}
            />
          )
        } else {
          if (record.status || record.is_active) return t('STATE_TAG_ACTIVE')
          return t('STATE_TAG_INACTIVE')
        }
      },
    },
    {
      title: (
        <Space size="large">
          {t('FIELD_ACTIONS')}
          <Tooltip title={t('CONFIG_USERS_TOOLTIP_INFO_TITLE')}>
            <Typography.Icon icon={InfoCircleOutlined} type="primary" />
          </Tooltip>
        </Space>
      ),
      dataIndex: 'id',
      align: 'right',
      width: 150,
      render: (id, row) => {
        if (id !== editRow) {
          return (
            <TableActions
              onCancel={() => {
                setDeleteUserId(null)
                setResetId(null)
              }}
              titlePopconfirm={
                deleteUserId
                  ? 'CONFIG_USERS_POPCOFIRM_DELETE_USER_TITLE'
                  : 'CONFIG_USERS_POPCONFIRM_RESET_PASSWORD_TITLE'
              }
              onConfirm={
                deleteUserId
                  ? onConfirmDelete
                  : () =>
                      onConfirmReset({
                        setIsLoading: setIsSaving,
                        resetPasswordUser: () => resetPasswordUser(resetId),
                        onRefetch,
                        onClose: onCloseReset,
                      })
              }
              visible={deleteUserId === id || resetId === id}
              onClickEdit={() => setEditRow(id)}
              onClickDelete={() => onSetDeleteUser(id)}
              disabledDelete={!canDelete || isSuperuser}
              disabledEdit={!canDelete || (isSuperuser && row.is_active)}
              isSuperuser={isSuperuser}
              onClickReset={() => setResetId(id)}
              onClickDetail={() => history.push(`/configuracion/usuarios/${id}`)}
              isCurrentUser={pkid === id}
            />
          )
        } else {
          return (
            <DeleteAndUpdate
              onCancel={onCleanDataEdit}
              onConfirm={onCleanDataEdit}
              onSave={
                !isSuperuser
                  ? () =>
                      handleSave({
                        dataCellEdit,
                        setIsLoading: setIsSaving,
                        roles: row.roles,
                        id,
                        assignRole,
                        editUser,
                        fetchUserRoleRelation,
                        deleteUserRoleRelation,
                        onRefetch,
                        onClose: onCleanDataEdit,
                        society_id,
                      })
                  : dataCellEdit.status &&
                    (() =>
                      handleActivate({
                        setIsLoading: setIsSaving,
                        activateUser: () => activateUser(id),
                        onRefetch,
                        onClose: onCleanDataEdit,
                      }))
              }
              dataCellEdit={dataCellEdit}
              loading={isSaving}
            />
          )
        }
      },
    },
  ]

  const renderRowSelection = () => {
    return {
      selectedRowKeys: dataSelection,
      onChange: (value) => setDataSelection(value),
    }
  }

  return (
    <>
      <Table
        rowKey="id"
        bordered
        size="small"
        loading={isLoading || isSaving}
        rowSelection={canDelete ? renderRowSelection() : undefined}
        onChange={onTableChange}
        columns={columns}
        dataSource={dataSource}
        pagination={{
          ...pagination,
          pageSizeOptions: ['10', '20', '30'],
          size: 'small',
          showSizeChanger: true,
        }}
      />
      <ConfirmDeleteUserModal
        visible={showModalConfirm}
        onClose={() => setShowModalConfirm(false)}
        onRefetch={onRefetch}
        setIsSaving={setIsSaving}
        id={userId}
        onCleanData={onCleanData}
      />
    </>
  )
}

const mapStateToProps = (state) => ({
  rolesList: configuration.selectors.getRolesList(state),
  loggedUser: login.selectors.getWhoAmI(state),
})

const mapDispatchToProps = {
  fetchRolesList: configuration.actions.fetchRolesList,
  fetchUserRoleRelation: configuration.actions.fetchUserRoleRelation,
  deleteUserRoleRelation: configuration.actions.deleteUserRoleRelation,
  assignRole: configuration.actions.assignRole,
  editUser: configuration.actions.editUser,
  activateUser: configuration.actions.activateUser,
  resetPasswordUser: configuration.actions.resetPasswordUser,
}

export default connect(mapStateToProps, mapDispatchToProps)(UsersTable)
