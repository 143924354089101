import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Col, Row, Spin } from 'antd'
import { Navigator } from 'modules/core/components'
import { SiderLayout } from 'modules/core/layouts'
import { ReportDetailTable, TableActions, UserActions } from './components'
import { functionSearch } from './utils'
import reports from 'modules/reports'
import moment from 'moment/moment'

import './ReportsDetail.scss'
import { ROLES } from 'modules/core/constants'
import { isUserAllowed } from 'modules/core/utils'
import { ConfigurationUploadModal } from 'modules/configuration/components'
import configuration from 'modules/configuration'

const { SETTINGS__REPORTS__REPORTSPNL__ABM } = ROLES

//TODO: VER ESTRUCTURA DE REPROTE
const ReportsDetail = ({
  reportStructure: { name, values },
  fetchReportDetailStructure,
  downloadReportStructure,
  uploadReportStructure,
}) => {
  const [isTableLoading, setIsTableLoading] = useState(false)
  const [searchValue, setSearchValue] = useState(null)
  const [showUploadModal, setShowUploadModal] = useState(false)
  let { reportId } = useParams()
  const { t } = useTranslation()
  let history = useHistory()

  useEffect(() => {
    setIsTableLoading(true)
    fetchReportDetailStructure(reportId, { search: searchValue }).then(() =>
      setIsTableLoading(false),
    )
  }, [fetchReportDetailStructure, reportId, searchValue])

  const userCanAbm = isUserAllowed(SETTINGS__REPORTS__REPORTSPNL__ABM)

  const renderHeader = () => (
    <Navigator
      floated={false}
      size="big"
      items={[
        {
          name: t('LABEL_CONFIG'),
          url: `/configuracion`,
        },
        {
          name: t('LABEL_REPORTS'),
          url: `/configuracion/reportes`,
        },
        { name: name || '' },
      ]}
    />
  )

  const renderUserActions = () => (
    <UserActions
      setSearchValue={setSearchValue}
      onClickUpload={() => setShowUploadModal(true)}
      disabled={!userCanAbm}
      onClickActivity={() => {
        history.push(
          `/configuracion/reportes/${reportId}/actividad?type=report&module=configDetail`,
        )
      }}
    />
  )

  const renderTable = () => (
    <ReportDetailTable
      dataSource={searchValue === null ? values : functionSearch(values, searchValue)}
      loading={isTableLoading}
    />
  )

  const renderTableActions = () => (
    <TableActions
      downloadReportStructure={() =>
        downloadReportStructure(
          reportId,
          t('CONFIG_REPORT_STRUCTURE_FILE_NAME', {
            date: moment().format('lll'),
          }),
        )
      }
    />
  )

  return (
    <SiderLayout>
      <div className="configuration-reports-detail-container">
        <Row gutter={[8, 8]}>
          <Col span={24}>{renderHeader()}</Col>
          <Spin spinning={isTableLoading} />
          {values.length > 0 && (
            <>
              <Col span={24}>{renderUserActions()}</Col>
              <Col span={24}>{renderTableActions()}</Col>
              <Col span={24}>{renderTable()}</Col>
              <ConfigurationUploadModal
                title={t('CONFIG_REPORT_DATA_UPLOAD_MODAL_TITLE')}
                visible={showUploadModal}
                onCancel={() => setShowUploadModal(false)}
                onSuccessUpload={() =>
                  fetchReportDetailStructure(reportId, { search: searchValue })
                }
                uploadValuesXLS={(file) => uploadReportStructure(reportId, file)}
                setIsTableLoading={setIsTableLoading}
                nameConfig={t('LABEL_REPORT')}
              />
            </>
          )}
        </Row>
      </div>
    </SiderLayout>
  )
}

const mapStateToProps = (state) => ({
  reportStructure: reports.selectors.getReportStructure(state),
  reportList: reports.selectors.getReportList(state),
})

const mapDispatchToProps = {
  fetchReportDetailStructure: reports.actions.fetchReportDetailStructure,
  fetchReportList: reports.actions.fetchReportList,
  downloadReportStructure: reports.actions.downloadReportStructure,
  uploadReportStructure: configuration.actions.uploadReportStructure,
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportsDetail)
