import { generateMessageError } from 'modules/core/utils'
import { message, notification } from 'antd'
import i18n from 'i18next'

export const handleVerify = ({
  setIsLoading,
  verifyCode,
  onRefetch,
  onClose,
  saveTokens,
  onConfirm,
}) => {
  setIsLoading(true)
  verifyCode()
    .then((response) => {
      saveTokens && saveTokens(response.payload.data)
      onRefetch().then(() => {
        message.success(i18n.t('FEEDBACK_CHANGES_SAVED_SUCCESS'), 8)
        onConfirm()
      })
    })
    .catch((error) => {
      notification.error({
        message: i18n.t('FEEDBACK_DEFAULT_ERROR'),
        description: generateMessageError(error, 'FEEDBACK_SAVE_CHANGES_FAIL'),
        duration: 0,
      })
      onClose()
    })
}

export const handleDelete = ({ deleteQrAunthentication, onRefetch }) => {
  deleteQrAunthentication()
    .then(() =>
      onRefetch().then(() => {
        message.success(i18n.t('FEEDBACK_DELETE_DATA_SUCCESS'), 8)
      }),
    )
    .catch((error) => {
      notification.error({
        message: i18n.t('FEEDBACK_DEFAULT_ERROR'),
        description: generateMessageError(error, 'FEEDBACK_DELETE_FAIL'),
        duration: 0,
      })
    })
}
