import React, { useState } from 'react'
import { Table, Space, Tooltip } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import { Typography, InputCell, DeleteAndUpdate, SelectCell } from 'modules/core/components'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import { TableActions } from './components'
import { handleOnSave, onDeleteDimension } from './utils'
import configuration from 'modules/configuration'

const DimensionsTable = ({
  dataSource,
  count,
  tableLoading,
  pagination,
  onChange,
  asignManagementDimension,
  onFetchDimensionList,
  dataSelection,
  setDataSelection,
  budgetTypeList,
  canDelete,
  deleteDimension,
}) => {
  const [editRow, setEditRow] = useState(null)
  const [dataCellEdit, setDataCellEdit] = useState({})
  const [isSaving, setIsSaving] = useState(false)
  const [deleteDimensionId, setDeleteDimensionId] = useState(null)
  const { t } = useTranslation()
  let history = useHistory()

  const onCellEdit = (name, value) => setDataCellEdit({ ...dataCellEdit, [`${name}`]: value })

  const onCancel = () => setEditRow(null)

  const onConfirm = () => {
    setEditRow(null)
    setDataCellEdit({})
    setIsSaving(false)
  }

  const onClose = () => {
    setDeleteDimensionId(null)
    setIsSaving(false)
  }

  const columns = [
    {
      title: t('CONFIG_DIMENSIONS_FIELD_ORIGIN_NAME'),
      dataIndex: 'name_origin',
      editable: true,
      width: 200,
    },
    {
      title: (
        <Space size="large">
          {t('CONFIG_DIMENSIONS_FIELD_DESTINATION_NAME')}
          <Tooltip title={t('CONFIG_DIMENSIONS_VALUES_MANAGEMENT_INFORMATIVE_ICON_TOOLTIP_TITLE')}>
            <Typography.Icon icon={InfoCircleOutlined} type="primary" />
          </Tooltip>
        </Space>
      ),
      dataIndex: 'name_destination',
      editable: true,
      width: 200,
      render: (record, row) => {
        if (editRow && editRow === row.id) {
          return (
            <InputCell initialValue={record} onCellUpdate={onCellEdit} name="name_destination" />
          )
        }
        return record
      },
    },
    {
      title: t('LABEL_BUDGET_TYPE'),
      dataIndex: 'concepts',
      editable: true,
      width: 200,
      render: (concepts, row) => {
        if (editRow && editRow === row.id) {
          return (
            <SelectCell
              initialValue={concepts.map((concept) => concept.id)}
              onCellUpdate={onCellEdit}
              name="concepts"
              mode="multiple"
              maxTagCount={1}
              maxTagTextLength={8}
              showArrow
              allowClear
              options={budgetTypeList}
            />
          )
        }
        return concepts.length > 0
          ? concepts.map((concept) => concept.name).join(', ')
          : t('CONFIG_CONCEPTS_EMPTY_DIENSIONS_TITLE')
      },
    },
    {
      title: t('FIELD_SOURCE'),
      dataIndex: 'data_source',
      editable: true,
      width: 200,
      render: (record) => record.display,
    },
    {
      title: (
        <Space size="large">
          {t('FIELD_ACTIONS')}
          <Tooltip title={t('CONFIG_DIMENSIONS_TOOLTIP_INFO_TITLE')}>
            <Typography.Icon icon={InfoCircleOutlined} type="primary" />
          </Tooltip>
        </Space>
      ),
      align: 'right',
      dataIndex: 'id',
      width: 150,
      render: (record) => {
        return record !== editRow ? (
          <TableActions
            onCancelDeleted={() => setDeleteDimensionId(null)}
            onConfirmDeleted={() =>
              onDeleteDimension({
                setIsLoading: setIsSaving,
                deleteDimension: () => deleteDimension(record),
                onRefetch: onFetchDimensionList,
                onClose,
              })
            }
            visible={deleteDimensionId === record}
            onClickDetail={() => history.push(`/configuracion/dimensiones/${record}`)}
            onClickEdit={() => setEditRow(record)}
            onClickDeleted={() => setDeleteDimensionId(record)}
          />
        ) : (
          <DeleteAndUpdate
            onCancel={onCancel}
            onConfirm={onConfirm}
            onSave={() =>
              handleOnSave({
                setIsLoading: setIsSaving,
                asignManagementDimension: () =>
                  asignManagementDimension({ ...dataCellEdit, id_dimension: record }),
                onRefetch: onFetchDimensionList,
                onClose: onConfirm,
              })
            }
            dataCellEdit={dataCellEdit}
            loading={isSaving}
          />
        )
      },
    },
  ]

  const renderRowSelection = () => {
    return {
      selectedRowKeys: dataSelection,
      onChange: (value) => setDataSelection(value),
    }
  }

  return (
    <Table
      rowKey="id"
      bordered
      size="small"
      loading={tableLoading || isSaving}
      rowSelection={canDelete ? renderRowSelection() : undefined}
      columns={columns}
      onChange={onChange}
      dataSource={dataSource}
      pagination={{
        pageSize: pagination.pageSize,
        size: 'small',
        total: count,
        current: pagination.page,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '30'],
      }}
    />
  )
}

const mapDispatchToProps = {
  asignManagementDimension: configuration.actions.asignManagementDimension,
  deleteDimension: configuration.actions.deleteDimension,
}

export default connect(null, mapDispatchToProps)(DimensionsTable)
