import React, { useState } from 'react'
import { Table } from 'antd'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { onDeleteReport, onSave } from './utils'
import { TableActions } from './components'
import { DeleteAndUpdate, InputCell } from 'modules/core/components'
import reports from 'modules/reports'
import configuration from 'modules/configuration'

const ReportListTable = ({
  searchValue,
  dataSource,
  functionSearch,
  isLoading,
  onFetchReportList,
  pagination,
  onChange,
  count,
  dataSelection,
  setDataSelection,
  canDelete,
  deleteReport,
  editReportName,
}) => {
  const [isDeletingReport, setIsDeletingReport] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [deleteReportId, setDeleteReportId] = useState(null)
  const [editRow, setEditRow] = useState(null)
  const [dataCellEdit, setDataCellEdit] = useState({})
  const { t } = useTranslation()
  let history = useHistory()

  const onClose = () => {
    setDeleteReportId(null)
    setIsDeletingReport(false)
  }

  const onCellEdit = (name, value) => setDataCellEdit({ ...dataCellEdit, [`${name}`]: value })

  const onCancel = () => setEditRow(null)

  const onConfirm = () => {
    setEditRow(null)
    setDataCellEdit({})
    setIsEditing(false)
  }

  const columns = [
    {
      title: t('FIELD_NAME'),
      dataIndex: 'name',
      render: (name, row) => {
        return row.id === editRow ? (
          <InputCell initialValue={name} onCellUpdate={onCellEdit} name="new_name" />
        ) : (
          name
        )
      },
    },
    {
      title: t('CONFIG_REPORTS_GROUPING_FIELD'),
      dataIndex: 'grouper_count',
    },
    {
      title: t('CONFIG_REPORTS_TOTALIZING_FILED'),
      dataIndex: 'totalizer_count',
    },
    {
      title: t('CONFIG_REPORTS_TYPE'),
      dataIndex: 'is_financial',
      render: (is_financial) => {
        return is_financial ? t('CONFIG_TEXT_FINANCIAL') : t('CONFIG_TEXT_ECONOMIC')
      },
    },
    {
      title: t('FIELD_ACTIONS'),
      dataIndex: 'id',
      align: 'right',
      render: (id) => {
        return id !== editRow ? (
          <TableActions
            onConfirm={() =>
              onDeleteReport({
                setIsLoading: setIsDeletingReport,
                deleteReport: () => deleteReport(deleteReportId),
                onRefetch: onFetchReportList,
                onClose,
              })
            }
            visible={deleteReportId === id}
            setDeleteReportId={() => setDeleteReportId(id)}
            isDeletingReport={isDeletingReport}
            onClick={() => history.push(`/configuracion/reportes/${id}`)}
            onClickEdit={() => setEditRow(id)}
          />
        ) : (
          <DeleteAndUpdate
            onCancel={onCancel}
            onConfirm={onConfirm}
            onSave={() =>
              onSave({
                dataCellEdit,
                setIsLoading: setIsEditing,
                editReportName: () => editReportName({ id_report: id, ...dataCellEdit }),
                onRefetch: onFetchReportList,
                onClose: onConfirm,
              })
            }
            dataCellEdit={dataCellEdit}
            loading={isEditing}
          />
        )
      },
    },
  ]

  const renderRowSelection = () => {
    return {
      selectedRowKeys: dataSelection,
      onChange: (value) => setDataSelection(value),
    }
  }

  return (
    <Table
      rowKey="id"
      bordered
      size="small"
      loading={isLoading || isDeletingReport}
      rowSelection={canDelete ? renderRowSelection() : undefined}
      columns={columns}
      onChange={onChange}
      dataSource={searchValue === null ? dataSource : functionSearch(dataSource, searchValue)}
      pagination={{
        pageSize: pagination.page_size,
        total: count,
        size: 'small',
        current: pagination.page,
        hideOnSinglePage: true,
        pageSizeOptions: ['10', '20', '30'],
      }}
    />
  )
}

const mapDispatchToProps = {
  deleteReport: reports.actions.deleteReport,
  editReportName: configuration.actions.editReportName,
}

export default connect(null, mapDispatchToProps)(ReportListTable)
