import { useTranslation } from 'react-i18next'
import ButtonBase from '../../ButtonBase'

const ButtonLinkWhitLeftIcon = ({
  title,
  disabled = false,
  icon = null,
  onClick = () => {},
  ...props
}) => {
  const { t } = useTranslation()
  return (
    <ButtonBase
      {...props}
      type="link"
      title={t(title)}
      disabled={disabled}
      icon={icon}
      onClick={onClick}
    >
      {t(title)}
    </ButtonBase>
  )
}

export default ButtonLinkWhitLeftIcon
