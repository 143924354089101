import { useEffect, useState } from 'react'
import { Col, Divider, Row, Spin } from 'antd'
import { PlusCircleOutlined } from '@ant-design/icons'
import { CardData, CardEmpty } from './components'
import { connect } from 'react-redux'
import Button from 'modules/core/components/Button/Button'
import ButtonActionBar from 'modules/core/components/ButtonActionBar/ButtonActionBar'
import Typography from 'modules/core/components/Typography/Typography'
import configuration from 'modules/configuration'

const CommentsContent = ({
  data,
  setData,
  onCommentCreate,
  onRefetchComments,
  loading,
  onCleanHasComment,
  fetchUsersList,
  usersList,
  setNewComment,
  removeNewComment,
}) => {
  const [editId, setEditId] = useState(null)
  const [isNewThread, setIsNewThread] = useState(false)

  useEffect(() => {
    fetchUsersList()
  }, [fetchUsersList])

  const handleViewReplies = (id) => {
    setData((prevState) =>
      prevState.map((el) => {
        if (el.id === id) {
          return {
            ...el,
            viewReplies: !el.viewReplies,
          }
        }
        return el
      }),
    )
  }

  return (
    <>
      {data.length > 0 && (
        <>
          <ButtonActionBar>
            {!isNewThread && (
              <Button.LinkWhitRightIcon
                title="ACTION_NEW_THREAD"
                disabled={data.some((d) => !d.text)}
                icon={<Typography.Icon level={2} icon={PlusCircleOutlined} />}
                onClick={() => {
                  setNewComment()
                  setIsNewThread(true)
                }}
              />
            )}
            {isNewThread && (
              <Button.Link
                title="ACTION_CANCEL"
                onClick={() => {
                  removeNewComment()
                  setIsNewThread(false)
                }}
              />
            )}
          </ButtonActionBar>
          <Divider style={{ margin: 0 }} />
        </>
      )}
      <Spin spinning={loading}>
        <Row
          gutter={[8, 8]}
          style={{ ...(data.length > 0 && { width: 300 }), maxHeight: 300, overflowY: 'auto' }}
        >
          {data.length > 0 ? (
            data.map((el, index) => (
              <>
                <Col span={24}>
                  {index !== 0 && <Divider style={{ marginTop: 10, marginBottom: 10 }} />}
                </Col>
                {el.text ? (
                  <>
                    <Col span={24}>
                      <CardData
                        el={el}
                        editId={editId}
                        onClickEdit={setEditId}
                        onClickReplies={() => handleViewReplies(el.id)}
                        isParent={true}
                        usersList={usersList}
                        onRefetchComments={onRefetchComments}
                        onCleanHasComment={onCleanHasComment}
                      />
                    </Col>
                    {el.replies.length > 0 &&
                      el.viewReplies &&
                      el.replies.map((re) => (
                        <>
                          <Col span={2} />
                          <Col span={22}>
                            <CardData
                              el={re}
                              editId={editId}
                              onClickEdit={setEditId}
                              usersList={usersList}
                              onRefetchComments={onRefetchComments}
                              onCleanHasComment={onCleanHasComment}
                            />
                          </Col>
                          <Col span={24}>
                            <Divider style={{ marginTop: 10, marginBottom: 10 }} />
                          </Col>
                        </>
                      ))}
                    {el.viewReplies && (
                      <>
                        <Col span={2} />
                        <Col span={22}>
                          <CardEmpty
                            onCommentCreate={(text, usersIds) =>
                              onCommentCreate(text, usersIds, el.id, setIsNewThread)
                            }
                            usersList={usersList}
                          />
                        </Col>
                      </>
                    )}
                  </>
                ) : (
                  <Col span={24}>
                    <CardEmpty
                      onCommentCreate={(text, usersIds) =>
                        onCommentCreate(text, usersIds, null, setIsNewThread)
                      }
                      usersList={usersList}
                    />
                  </Col>
                )}
              </>
            ))
          ) : (
            <CardEmpty
              onCommentCreate={(text, usersIds) =>
                onCommentCreate(text, usersIds, null, setIsNewThread)
              }
              usersList={usersList}
            />
          )}
        </Row>
      </Spin>
    </>
  )
}

const mapStateToProps = (state) => ({
  usersList: configuration.selectors.getUsersList(state),
})

const mapDispatchToProps = {
  fetchUsersList: configuration.actions.fetchUsersList,
  setNewComment: configuration.actions.setNewComment,
  removeNewComment: configuration.actions.removeNewComment,
}

export default connect(mapStateToProps, mapDispatchToProps)(CommentsContent)
