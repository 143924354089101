import { useTranslation } from 'react-i18next'
import ButtonBase from '../../ButtonBase'

const ButtonDefault = ({
  title,
  disabled = false,
  onClick = () => {},
  icon = null,
  tooltipTitle = '',
  ...props
}) => {
  const { t } = useTranslation()
  return (
    <ButtonBase
      {...props}
      type="default"
      title={t(title)}
      disabled={disabled}
      onClick={onClick}
      tooltipTitle={tooltipTitle}
    >
      {t(title)}
      {icon && icon}
    </ButtonBase>
  )
}

export default ButtonDefault
