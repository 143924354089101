import { useTranslation } from 'react-i18next'
import ButtonBase from '../../ButtonBase'

const ButtonText = ({ title, onClick = () => {}, ...props }) => {
  const { t } = useTranslation()
  return (
    <ButtonBase {...props} type="text" title={t(title)} onClick={onClick}>
      {t(title)}
    </ButtonBase>
  )
}

export default ButtonText
