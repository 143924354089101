import { Button, Typography } from 'modules/core/components'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
// import { ExceptionButtons } from './components'

const PLIKA_URL = 'https://drive.google.com/drive/folders/1XfPXOzTSb9Lb1fsejaAXZ3kr_3cBfEfE'

const ContentHelp = ({ exception_path }) => {
  const { t } = useTranslation()
  let history = useHistory()

  const renderTab = () => {
    const path = {
      planificacion: 'planning',
      seguimiento: 'control',
      analisis: 'analysis',
      forecast: 'forecast',
      configuracion: 'config',
    }
    let tab = ''

    Object.keys(path).forEach((el) => {
      if (window.location.pathname.includes(el)) {
        tab = path[el]
      }
    })

    return tab
  }

  return (
    <div
      style={{
        width: 200,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div style={{ textAlign: 'center', marginBottom: 10 }}>
        <Typography.Body level={2}>{t('NEED_HELP')}</Typography.Body>
      </div>
      <Button.PrimaryBlock
        style={{ marginBottom: 10 }}
        title="LABEL_PLIKA_VIDEOS"
        onClick={() => history.push(`/videosplika${renderTab() && `?tab=${renderTab()}`}`)}
      />
      <Button.PrimaryBlock
        style={{ marginBottom: 10 }}
        title="LABEL_USER_MANUAL"
        onClick={() => window.open(PLIKA_URL, '_blank')}
      />
      {/* {exception_path && <ExceptionButtons exception_path={exception_path} />} */}
    </div>
  )
}

export default ContentHelp
