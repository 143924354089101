import { Popconfirm } from 'antd'
import { DropdownMenu } from 'modules/core/components'
import { useTranslation } from 'react-i18next'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'

const TableActions = ({
  onConfirmPopconfirm,
  onCancelPopconfirm,
  loading,
  visiblePopconfirm,
  onClickEdit,
  onClickDeleted,
  disabled,
}) => {
  const { t } = useTranslation()
  return (
    <Popconfirm
      placement="bottomRight"
      title={t('CONFIG_FORMULAS_POPCONFIRM_DELETE_TITLE')}
      okText={t('ACTION_DELETE')}
      onConfirm={onConfirmPopconfirm}
      cancelText={t('ACTION_CANCEL')}
      onCancel={onCancelPopconfirm}
      okButtonProps={{ loading, disabled: loading }}
      cancelButtonProps={{ disabled: loading }}
      visible={visiblePopconfirm}
    >
      <DropdownMenu
        title={t('ACTION_MORE')}
        menu={[
          {
            title: t('ACTION_EDIT'),
            icon: <EditOutlined />,
            onClick: onClickEdit,
            disabled,
          },
          {
            title: t('ACTION_DELETE'),
            icon: <DeleteOutlined />,
            onClick: onClickDeleted,
            disabled,
          },
        ]}
      />
    </Popconfirm>
  )
}

export default TableActions
