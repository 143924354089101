import React from 'react'
import { Input, Form } from 'antd'
import { MailOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'

import './LoginForm.scss'
import { Button } from 'modules/core/components'

const FormItem = Form.Item

const LoginForm = ({ form, onFinish, loading }) => {
  const { t } = useTranslation()
  return (
    <Form
      form={form}
      layout="vertical"
      className="login-form"
      onFinish={onFinish}
      hideRequiredMark={true}
    >
      <div className="login-form-inputs">
        <FormItem
          label={t('LOGIN_FORM_INPUT_EMAIL_LABEL')}
          name="email"
          validateTrigger="onBlur"
          rules={[
            {
              type: 'email',
              message: t('LOGIN_FORM_INPUT_EMAIL_CAPTION_FORMAT'),
            },
            {
              required: true,
              message: t('LOGIN_FORM_INPUT_EMAIL_CAPTION_EMPTY'),
            },
          ]}
        >
          <Input
            size="large"
            placeholder={t('LOGIN_FORM_INPUT_EMAIL_PLACEHOLDER')}
            suffix={<MailOutlined />}
          />
        </FormItem>
        <FormItem
          label={t('LOGIN_FORM_INPUT_PASSWORD_LABEL')}
          name="password"
          rules={[
            {
              required: true,
              message: t('LOGIN_FORM_INPUT_A_PASSWORD_CAPTION_EMPTY'),
            },
          ]}
        >
          <Input.Password size="large" placeholder={t('LOGIN_FORM_INPUT_PASSWORD_PLACEHOLDER')} />
        </FormItem>
        <FormItem>
          <Button.PrimaryForm loading={loading} size="large" title="LOGIN_FORM_BUTTON_SUBMIT" />
        </FormItem>
      </div>
    </Form>
  )
}

export default LoginForm
