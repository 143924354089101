import { useFeatureIsOn } from '@growthbook/growthbook-react'
import React, { useEffect, useState } from 'react'
import { List, Row, Col, Space, message } from 'antd'
import { useTranslation } from 'react-i18next'
import { SolutionOutlined, CommentOutlined } from '@ant-design/icons'
import {
  PublishModal,
  ScreenWellDone,
  Button,
  StatusTag,
  Typography,
  ListCard,
  ToolsDrawer,
  Comments,
} from 'modules/core/components'
import { ButtonEnterFooter, KpiCard, UserActions } from './components'
import { generateMessageError, isUserAllowed } from 'modules/core/utils'
import { CONCEPTS, ROLES } from 'modules/core/constants'
import { connect } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import feedbackImage from 'assets/images/compositions/feedback-image.svg'
import planning from 'modules/planning'
import control from 'modules/control'
import configuration from 'modules/configuration'
import login from 'modules/login'
import moment from 'moment'
import _ from 'lodash'

const {
  CONTROL__REAL__STATUS_CHANGE__PUBLISH,
  CONTROL__REAL__SALES__VIEW,
  CONTROL__REAL__EXPENSES__VIEW,
  CONTROL__REAL__COSTS__VIEW,
  CONTROL__REAL__HUMAN_RESOURCES__VIEW,
  CONTROL__REAL__OTHER_5__VIEW,
  CONTROL__REAL__OTHER_6__VIEW,
} = ROLES

// NOTE: En los roles, los conceptos deberian ser resueltos mediante restricciones de dimensiones y no de operaciones
const CONTROL__REAL__BUDGET_TYPE__VIEW = {
  [CONCEPTS.IDS.SALES_ID]: CONTROL__REAL__SALES__VIEW,
  [CONCEPTS.IDS.EXPENSES_ID]: CONTROL__REAL__EXPENSES__VIEW,
  [CONCEPTS.IDS.COSTS_ID]: CONTROL__REAL__COSTS__VIEW,
  [CONCEPTS.IDS.HUMAN_RESOURCES_ID]: CONTROL__REAL__HUMAN_RESOURCES__VIEW,
  [CONCEPTS.IDS.OTHER_5_ID]: CONTROL__REAL__OTHER_5__VIEW,
  [CONCEPTS.IDS.OTHER_6_ID]: CONTROL__REAL__OTHER_6__VIEW,
}

const TracingList = ({
  dataSource,
  periodList,
  onFetchProjections,
  fetchMonthsToPublish,
  monthsToPublish,
  fetchAllDimensions,
  allDimensionsList,
  setDimensionId,
  isDataLoading,
  fetchCurrencyList,
  currencyList,
  hasComment,
  setHasComment,
  loggedUser,
  fetchCommentsList,
  commentsList,
  createComment,
  removeNewComment,
}) => {
  const enabledRevampCard = useFeatureIsOn('feature-revamp-card')
  const enabled = useFeatureIsOn('feature-moneda-seguimiento')
  const extraConcept = useFeatureIsOn('feature-extra-concept')
  const [showActionModal, setShowActionModal] = useState(false)
  const [showScreenSuccess, setShowScreenSuccess] = useState(false)
  const [date, setDate] = useState(null)
  const [showExchangeRateModal, setShowExchangeRateModal] = useState(false)
  const [isCurrencyLoading, setIsCurrencyLoading] = useState(false)
  const [viewComment, setViewComment] = useState({})
  // const [cardId, setCardId] = useState(null)
  const [dataComments, setDataComments] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [isSaving, setIsSaving] = useState(false)

  let { periodId } = useParams()
  let history = useHistory()
  const { t } = useTranslation()

  const key = window.location.pathname.replaceAll('/', '-')

  useEffect(() => {
    setIsLoading(true)
    fetchCommentsList({ module: key }).then(() => setIsLoading(false))
  }, [fetchCommentsList, key])

  useEffect(() => {
    setDataComments(commentsList)
  }, [commentsList])

  useEffect(() => {
    fetchMonthsToPublish()
  }, [fetchMonthsToPublish])

  useEffect(() => {
    fetchAllDimensions()
  }, [fetchAllDimensions])

  useEffect(() => {
    setIsCurrencyLoading(true)
    fetchCurrencyList().then(() => {
      setIsCurrencyLoading(false)
    })
  }, [fetchCurrencyList])

  const userCanPublish = isUserAllowed(CONTROL__REAL__STATUS_CHANGE__PUBLISH)
  const dataPeriod = periodList.find((el) => el.id === parseInt(periodId))

  const renderUserActions = () => (
    <UserActions
      onClick={() => setShowActionModal(true)}
      disabled={!userCanPublish || monthsToPublish.length === 0}
      titleTooltip={
        moment(dataPeriod?.end_date).utc().format('YYYY-MM') ===
        moment(dataSource?.last_publish_date).format('YYYY-MM')
          ? t('CONTROL_ALL_PUBLISH_DATES_TOOLTIP_TITLE')
          : ''
      }
      date={dataSource.last_publish_date}
      onClickTools={() => setShowExchangeRateModal(true)}
      onClickActivity={() =>
        history.push(`/seguimiento/${periodId}/actividad?type=real&module=real`)
      }
    />
  )

  const handleCreate = ({ id, text, usersIds, idComment = null, setIsNewThread }) => {
    const dataa = {
      text,
      key: `${key}-card-${id}`,
      module: key,
      mentions: usersIds,
      ...(idComment && {
        parent: idComment,
      }),
    }
    setIsSaving(true)
    createComment(dataa)
      .then(() =>
        fetchCommentsList({ module: key }).then(() => {
          message.success(t('FEEDBACK_SAVE_CHANGES_SUCCES'), 8)
          setIsSaving(false)
          setIsNewThread(false)
        }),
      )
      .catch((error) => {
        message.error(generateMessageError(error, 'FEEDBACK_SAVE_CHANGES_FAIL_MESSAGE'), 8)
        setIsSaving(false)
        setIsNewThread(false)
      })
  }

  const verifyPermission = (concept_id) => {
    const permission = [
      CONCEPTS.IDS.SALES_ID.toString(),
      CONCEPTS.IDS.EXPENSES_ID.toString(),
      CONCEPTS.IDS.COSTS_ID.toString(),
      CONCEPTS.IDS.HUMAN_RESOURCES_ID.toString(),
      CONCEPTS.IDS.OTHER_5_ID.toString(),
      CONCEPTS.IDS.OTHER_6_ID.toString(),
    ]
    if (permission.includes(concept_id.toString())) {
      return isUserAllowed(CONTROL__REAL__BUDGET_TYPE__VIEW[concept_id])
    } else if (extraConcept) {
      return true
    }

    return false
  }

  const renderList = () => (
    <List
      grid={{
        gutter: 24,
        xs: 1,
        sm: 1,
        md: 1,
        lg: 1,
        xl: enabledRevampCard ? 1 : 3,
        xxl: enabledRevampCard ? 1 : 3,
      }}
      dataSource={dataSource.values.filter((el) => verifyPermission(el.id))}
      loading={isDataLoading}
      renderItem={(tracing) => {
        const {
          id,
          concept,
          status,
          real_total,
          dimension,
          line_chart,
          ranking_down_chart,
          ranking_up_chart,
        } = tracing
        return (
          <List.Item>
            {enabledRevampCard ? (
              <KpiCard
                periodId={periodId}
                title={concept}
                status={status}
                dataKpi={real_total}
                id={id}
                onClick={() => history.push(`/seguimiento/${periodId}/${concept}/${id}`)}
                extra={
                  <Space align="center">
                    {status && <StatusTag status={status} />}
                    {(viewComment[id] ||
                      dataComments.filter((el) => el.key === `${key}-card-${id}` && !el.resolved)
                        .length > 0) && (
                      <Comments
                        hasComment={
                          !_.isEmpty(hasComment?.card)
                            ? !_.isEmpty(hasComment?.card[key]) && hasComment?.card[key][id]
                            : false
                        }
                        onClick={(visible) => {
                          setHasComment({ type: 'card', data: visible, key, id })
                          !visible && removeNewComment()
                          !visible && setViewComment({ ...viewComment, [id]: visible })
                        }}
                        comments={dataComments.filter(
                          (el) => (el.key === `${key}-card-${id}` && !el.resolved) || !el.text,
                        )}
                        setData={setDataComments}
                        loading={isSaving || isLoading}
                        onRefetchComments={() => fetchCommentsList({ module: key })}
                        onCommentCreate={(text, usersIds, idComment, setIsNewThread) =>
                          handleCreate({ id, text, usersIds, idComment, setIsNewThread })
                        }
                        onCleanHasComment={() => {
                          setHasComment({ type: 'card', data: false, key, id })
                          setViewComment(false)
                        }}
                      >
                        <Typography.Icon
                          style={{ cursor: 'pointer' }}
                          title={t('LABEL_COMMENTS')}
                          icon={CommentOutlined}
                        />
                      </Comments>
                    )}
                    <Button.Icon
                      title="ACTION_ACTIVITIES"
                      onClick={() =>
                        history.push(
                          `/seguimiento/${periodId}/${concept}/actividad/${id}?type=real`,
                        )
                      }
                      icon={<Typography.Icon icon={SolutionOutlined} />}
                    />
                  </Space>
                }
                emptyState={{
                  description: t('CONTROL_CARD_EMPTY_STATE_DESCRIPTION', {
                    conceptName: t(concept).toLowerCase(),
                  }),
                }}
                bottomText={`Total ${concept} real`}
                dataRankingUp={ranking_up_chart}
                dataRankingDown={ranking_down_chart}
                dataLineChart={line_chart.asMutable({ deep: true })}
                allDimensionsList={allDimensionsList}
                dimension={dimension}
                setDimensionId={setDimensionId}
                setViewComment={setViewComment}
                hasComment={hasComment}
                setHasComment={setHasComment}
                // setCardId={() => setCardId(id)}
              />
            ) : (
              <ListCard
                title={concept}
                type={id.toString()}
                projectionId={id}
                extra={
                  <Space align="center">
                    {status && <StatusTag status={status} />}
                    <Button.Icon
                      title="ACTION_ACTIVITIES"
                      onClick={() =>
                        history.push(
                          `/seguimiento/${periodId}/${concept}/actividad/${id}?type=real&module=realDetail`,
                        )
                      }
                      icon={<Typography.Icon icon={SolutionOutlined} />}
                    />
                  </Space>
                }
                dataKpi={real_total}
                actions={[<ButtonEnterFooter conceptName={concept} conceptId={id} />]}
                emptyState={{
                  description: t('CONTROL_CARD_EMPTY_STATE_DESCRIPTION', {
                    conceptName: t(concept).toLowerCase(),
                  }),
                }}
                setDataComments={setDataComments}
                dataComments={dataComments}
                onRefetchComments={() => fetchCommentsList({ module: key })}
                loadingComments={isLoading}
                defaultCurrencyData={currencyList.data.find((el) => el.is_default)}
              />
            )}
          </List.Item>
        )
      }}
    />
  )

  const handleOnClick = () => {
    onFetchProjections()
    setShowScreenSuccess(false)
  }

  return (
    <>
      {!showScreenSuccess && (
        <>
          <Row gutter={[24, 24]}>
            <Col span={24}>{renderUserActions()}</Col>
            <Col span={24}>{renderList()}</Col>
          </Row>
          <PublishModal
            visible={showActionModal}
            monthsToPublish={monthsToPublish}
            onConfirm={() => setShowScreenSuccess(true)}
            onClose={() => setShowActionModal(false)}
            onSetDate={setDate}
            date={date}
          />
        </>
      )}
      {showScreenSuccess && (
        <ScreenWellDone
          title={t('FEEDBACK_WELLDONE')}
          description={t('CONTROL_CONCEPT_ACTION_SUCCESS_FEEDBACK_DESCRIPTION', {
            action: t('ACTION_PUBLISH').toLowerCase(),
            rangeDate: date.map((date) => moment(date).format('MMMM')).join(', '),
          })}
          image={feedbackImage}
          onConfirm={() => {
            setDate([])
            setShowScreenSuccess(false)
          }}
          footer={<Button.Primary onClick={() => handleOnClick()} title="ACTION_FINISH" />}
        />
      )}
      {enabled && (
        <ToolsDrawer
          onRefetchDataTable={onFetchProjections}
          periodId={periodId}
          visible={showExchangeRateModal}
          onClose={() => setShowExchangeRateModal(false)}
          isCurrencyLoading={isCurrencyLoading}
          currencyList={currencyList}
          type="real"
          hasCurrency={true}
          params={{
            module: 'REAL',
          }}
        />
      )}
    </>
  )
}

const mapStateToProps = (state) => ({
  periodList: planning.selectors.getPeriodList(state),
  monthsToPublish: control.selectors.getMonthsToPublish(state),
  allDimensionsList: configuration.selectors.getAllDimensionsList(state),
  currencyList: configuration.selectors.getCurrencyList(state),
  loggedUser: login.selectors.getWhoAmI(state),
  hasComment: configuration.selectors.getHasComment(state),
  commentsList: configuration.selectors.getCommentsList(state),
})

const mapDispatchToProps = {
  fetchMonthsToPublish: control.actions.fetchMonthsToPublish,
  fetchAllDimensions: configuration.actions.fetchAllDimensions,
  fetchCurrencyList: configuration.actions.fetchCurrencyList,
  setHasComment: configuration.actions.setHasComment,
  fetchCommentsList: configuration.actions.fetchCommentsList,
  createComment: configuration.actions.createComment,
  removeNewComment: configuration.actions.removeNewComment,
}

export default connect(mapStateToProps, mapDispatchToProps)(TracingList)
