import React, { useState } from 'react'
import { Card, Dropdown, Menu, message } from 'antd'
import { CardTitle, ContainerBody, ExtraActions, FooterActions } from './components'
import { onEnterPeriod } from '../../utils'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import { handleEdit, handleDelete } from './utils'
import { CommentOutlined } from '@ant-design/icons'
import { useSortable } from '@dnd-kit/sortable'
import planning from 'modules/planning'
import login from 'modules/login'
import configuration from 'modules/configuration'

import './ContainerList.scss'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import { generateMessageError } from 'modules/core/utils'
import { GENERAL } from 'modules/core/constants'

const ContainerList = ({
  name,
  disabled,
  start_date,
  end_date,
  id,
  onRefetch,
  editPeriod,
  deletePeriod_,
  hasComment,
  setHasComment,
  loggedUser,
  setDataComments,
  dataComments,
  onRefetchComments,
  loadingComments,
  createComment,
  removeNewComment,
}) => {
  const [editName, setEditName] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  const [viewComment, setViewComment] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  let history = useHistory()
  const { t } = useTranslation()

  const { isDragging, attributes, listeners, setNodeRef, transform } = useSortable({ id: id })

  const draggableStyle = transform
    ? {
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
        userSelect: 'none',
        cursor: 'move',
        opacity: isDragging ? 0.5 : 1,
      }
    : undefined

  const tagStyle = {
    cursor: transform ? 'move' : 'pointer',
  }

  const key = window.location.pathname.replaceAll('/', '-')

  const onClose = () => {
    setIsEditing(false)
    setEditName(false)
    setIsDeleting(false)
  }

  const handleCreate = ({ text, usersIds, idComment = null, setIsNewThread }) => {
    const dataa = {
      text,
      key: `${key}-card-${id}`,
      module: key,
      mentions: usersIds,
      ...(idComment && {
        parent: idComment,
      }),
    }
    setIsSaving(true)
    createComment(dataa)
      .then(() =>
        onRefetchComments().then(() => {
          message.success(t('FEEDBACK_SAVE_CHANGES_SUCCES'), 8)
          setIsSaving(false)
          setIsNewThread(false)
        }),
      )
      .catch((error) => {
        message.error(generateMessageError(error, 'FEEDBACK_SAVE_CHANGES_FAIL_MESSAGE'), 8)
        setIsSaving(false)
        setIsNewThread(false)
      })
  }

  return (
    <Dropdown
      trigger={['contextMenu']}
      overlay={
        <Menu>
          {GENERAL.OPTION_COMMENT.map((el) => (
            <Menu.Item
              key={el.key}
              icon={<CommentOutlined />}
              onClick={() => {
                setViewComment(true)
                setHasComment({
                  type: 'card',
                  data: !_.isEmpty(hasComment?.card)
                    ? !_.isEmpty(hasComment?.card[key])
                      ? !hasComment?.card[key][id]
                      : true
                    : true,
                  key,
                  id,
                })
              }}
            >
              {t(el.label)}
            </Menu.Item>
          ))}
        </Menu>
      }
    >
      <div className="container-list" ref={setNodeRef} style={draggableStyle}>
        <Card
          className="period-card"
          title={
            <CardTitle
              editName={editName}
              name={name}
              handleEdit={(value) =>
                handleEdit({
                  condition: value !== name,
                  setILoading: setIsEditing,
                  editPeriod: () => editPeriod(id, { name: value }),
                  onRefetch,
                  onClose,
                })
              }
              isEditing={isEditing}
            />
          }
          headStyle={{ height: 56 }}
          bodyStyle={{ height: 126 }}
          extra={
            <ExtraActions
              onCancel={onClose}
              visible={isDeleting}
              onClickEdit={() => setEditName(true)}
              onClickDelete={() => setIsDeleting(true)} // Cambia el estado para mostrar la confirmación
              onDeleteConfirmed={() => {
                // Aquí puedes ejecutar acciones adicionales después de confirmar la eliminación
                handleDelete({
                  deletePeriod_,
                  id,
                  onRefetch,
                  setIsDeleting,
                  onClose,
                })
              }}
              hasComment={hasComment}
              setHasComment={setHasComment}
              viewComment={viewComment}
              setViewComment={setViewComment}
              loggedUser={loggedUser}
              className="drag-card-icon"
              attributes={attributes}
              listeners={listeners}
              tagStyle={tagStyle}
              showComments={
                viewComment ||
                dataComments.filter((el) => el.key === `${key}-card-${id}` && !el.resolved).length >
                  0
              }
              setDataComments={setDataComments}
              comments={dataComments.filter(
                (el) => (el.key === `${key}-card-${id}` && !el.resolved) || !el.text,
              )}
              onRefetchComments={onRefetchComments}
              loadingComments={isSaving || loadingComments}
              onCommentCreate={(text, usersIds, idComment, setIsNewThread) =>
                handleCreate({ text, usersIds, idComment, setIsNewThread })
              }
              cardId={id}
              removeNewComment={removeNewComment}
            />
          }
          actions={[
            <FooterActions disabled={disabled} onClick={() => onEnterPeriod(id, history)} />,
          ]}
        >
          <ContainerBody start_date={start_date} end_date={end_date} />
        </Card>
      </div>
    </Dropdown>
  )
}

const mapStateToProps = (state) => ({
  loggedUser: login.selectors.getWhoAmI(state),
  hasComment: configuration.selectors.getHasComment(state),
})

const mapDispatchToProps = {
  editPeriod: planning.actions.editPeriod,
  deletePeriod_: planning.actions.deletePeriod_,
  setHasComment: configuration.actions.setHasComment,
  createComment: configuration.actions.createComment,
  removeNewComment: configuration.actions.removeNewComment,
}

export default connect(mapStateToProps, mapDispatchToProps)(ContainerList)
