import { Popconfirm } from 'antd'
import { useTranslation } from 'react-i18next'
import Button from 'modules/core/components/Button/Button'
import ButtonActionBar from 'modules/core/components/ButtonActionBar/ButtonActionBar'

const ButtonsFooter = ({
  onChangePopConfirm,
  onCancelPopConfirm,
  visiblePopconfirm,
  disableCnacel,
  onClickCancel,
  onClickSave,
  loading,
  disableSave,
}) => {
  const { t } = useTranslation()
  return (
    <ButtonActionBar>
      <Popconfirm
        placement="bottomRight"
        title={t('POPCONFIRM_EDIT_CANCEL')}
        okText={t('YES')}
        onConfirm={onChangePopConfirm}
        cancelText={t('NO')}
        onCancel={onCancelPopConfirm}
        visible={visiblePopconfirm}
      >
        <Button.Default title="ACTION_CANCEL" disabled={disableCnacel} onClick={onClickCancel} />
      </Popconfirm>
      <Button.Primary
        title="ACTION_SAVE"
        onClick={onClickSave}
        loading={loading}
        disabled={disableSave}
      />
    </ButtonActionBar>
  )
}

export default ButtonsFooter
