import { Table } from 'antd'
import { getRowClassName } from 'modules/configuration/containers/Reports/containers/ReportsDetail/utils/DynamicClass'
import { calculateScrollX, columnsHelper } from 'modules/core/utils'
import reports from 'modules/reports/index'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import { generatorComparativeColumns, onExpandByPeriod } from './utils'

const ComparativeTable = ({
  dataSource,
  loading,
  periodTableColumns,
  setPeriodTableColumns,
  periodTableSettings,
  filters,
  selectedDates,
  fetchComparativeTableOpenRow,
  tableTypeKey,
  referenceBase,
  referenceComparison,
}) => {
  const [isExpandingByPeriod, setIsExpandingByPeriod] = useState(false)
  const [expandedByPeriodRow, setExpandedByPeriodRow] = useState([])
  let { periodId, reportId } = useParams()

  const paramsColumn = {
    periodTableColumns,
    setPeriodTableColumns,
    periodTableSettings,
    tableTypeKey,
  }
  const paramsOpenRow = {
    period_id: periodId,
    filter_date: selectedDates,
    filter: filters,
    reference_base: referenceBase,
    reference_comparative: referenceComparison,
    report_type: tableTypeKey,
  }

  // Generar clase dinamica para la row
  const generateRowClassName = (record) => {
    return getRowClassName(record)
  }

  return (
    <Table
      rowKey="id"
      size="small"
      columns={generatorComparativeColumns(paramsColumn)}
      dataSource={dataSource}
      rowClassName={generateRowClassName}
      loading={isExpandingByPeriod || loading}
      expandable={{
        onExpand: (expanded, record) =>
          onExpandByPeriod({
            expanded,
            record,
            setIsLoading: setIsExpandingByPeriod,
            fetchTableOpenRow: () =>
              fetchComparativeTableOpenRow(reportId, record.id, {
                ...paramsOpenRow,
                row_id: record.id,
              }),
            setExpandedByPeriodRow,
          }),
        expandedRowKeys: expandedByPeriodRow,
      }}
      scroll={{ x: calculateScrollX(generatorComparativeColumns(paramsColumn)), y: 420 }}
      pagination={false}
      components={columnsHelper.tableComponents}
    />
  )
}

const mapDispatchToProps = {
  fetchComparativeTableOpenRow: reports.actions.fetchComparativeTableOpenRow,
}

export default connect(null, mapDispatchToProps)(ComparativeTable)
