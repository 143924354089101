import React from 'react'
import { mockDataCards } from './mockDataCards'
import { StepCard } from './components'

const StepCardsList = () => {
  return (
    <div className="initial-screen-steps-container">
      {mockDataCards.map((data) => (
        <StepCard key={data.id} data={data} />
      ))}
    </div>
  )
}

export default StepCardsList
