const login = {
  LOGIN_WELLCOME_TITLE: 'Efficiency and simplicity for financial analysis',
  // LOGIN_WELLCOME_TITLE: 'Agilidad y simpleza para el análisis financiero.',
  LOGIN_WELLCOME_SUBTITLE: 'Have everything under control with Plika',

  LOGIN_ASSOCIATED_ACCOUNT_MODAL_TITLE: 'Select an account',
  LOGIN_ASSOCIATED_ACCOUNT_SOCIETY_NAME: 'Company: {{societyName}}',

  LOGIN_FORM_TITLE: 'Sign in to get started',
  LOGIN_FORM_TITLE_CREATE_PASSWORD: 'Create a password',
  LOGIN_FORM_INPUT_EMAIL_LABEL: 'Enter your e-mail',
  LOGIN_FORM_INPUT_EMAIL_PLACEHOLDER: 'Example name@email.com',
  LOGIN_FORM_INPUT_EMAIL_CAPTION_EMPTY: 'Please enter an e-mail',
  LOGIN_FORM_INPUT_EMAIL_CAPTION_FORMAT: 'Please enter a valid e-mail',
  LOGIN_FORM_INPUT_EMAIL_CAPTION_NAME: 'Please enter your name',
  LOGIN_FORM_INPUT_EMAIL_CAPTION_SURNAME: 'Please enter your last name',
  LOGIN_FORM_INPUT_EMAIL_CAPTION_OK: 'Valid e-mail format',
  LOGIN_FORM_INPUT_PASSWORD_LABEL: 'Enter your password',
  LOGIN_FORM_INPUT_PASSWORD_PLACEHOLDER: 'Password',
  LOGIN_FORM_INPUT_A_PASSWORD_CAPTION_EMPTY: 'Please enter a password',
  LOGIN_FORM_INPUT_PASSWORD_CAPTION_FORMAT: 'Please enter a valid password',
  LOGIN_FORM_INPUT_PASSWORD_CAPTION_OK: 'Valid password format',
  LOGIN_FORM_BUTTON_SUBMIT: 'Log in',
  LOGIN_FORM_CONFIRM_PASSWORD_LABEL_CURRENT: 'Current password',
  LOGIN_FORM_CONFIRM_PASSWORD_LABEL_NEW: 'New password',
  LOGIN_FORM_CONFIRM_PASSWORD_CHARACTERS: 'Must contain at least 8 characters',
  LOGIN_FORM_CONFIRM_PASSWORD_RECONFIRM: 'Reconfirm new password',
  LOGIN_FORM_PASSWORD_NOT_SAME: 'Passwords do not match',
  LOGIN_FORM_PASSWORD_NOT_DIFFERENT: 'The password must be different from the previous one',
  LOGIN_USER_NOT_FOUND_FEEDBACK_TITLE: 'No user was found with the entered email in Plika',
  LOGIN_USER_NOT_FOUND_FEEDBACK_DESCRIPTION:
    'To log in with Google or Microsoft, you must create a user with the entered email in Plika. Or if you already have a Plika account, associate it with the entered email from settings',

  LOGIN_FORM_PASSWORD_VALIDATOR_FEEDBACK:
    'The password must have at least 1 number, 1 lowercase letter, 1 uppercase letter, 1 special character, must not contain spaces, and be at least 8 characters long.',

  LOGIN_FEEDBACK_ERROR:
    'We did not recognize the email or password you have just entered. Please try again',

  LOGIN_FORGOT_PASSWORD_ACTION: 'I forgot my password',
  LOGIN_GO_BACK_SESSION_ACTION: 'Return to login',
  LOGIN_FORM_RESET_PASSWORD_TITLE: 'Reset password',
  LOGIN_FORM_RESET_PASSWORD_NOTIFICATION_TEXT:
    'We sent you an email to reset your password, please check your inbox',
  LOGIN_RESET_PASSWORD_FEEDBACK_SUCCESS_DESCRIPTION_1:
    'The password was reset successfully. Now you can ',
  LOGIN_RESET_PASSWORD_FEEDBACK_SUCCESS_DESCRIPTION_2: ' with your new password.',

  LOGIN_CONGIF_SCAN_QR_INFORMATIVE_ALERT_DESCRIPTION:
    'In order to scan the code you will have to download and authentication app. For example: Google authenticator',
  LOGIN_CONFIG_ENTER_CODE_TYPOGRAPHY_BODY: 'Enter de code that appears after scanning',

  LOGIN_FEEDBACK_USER_BLOCKED_ERROR:
    'The user has been blocked  due to multiply incorrects attempts. We will send you an email to {{email}} to active your account',
  LOGIN_SEND_EMAIL_FEEDBACK_TEXT: 'Send email',
}

export default login
