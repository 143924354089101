import React from 'react'
import { Table } from 'antd'
import { dimensionsColumns } from '../../dimensionsColumns'

const CombinationDimensionsTable = ({
  dataSource,
  loadingTable,
  dataKey,
  loading,
  setDimensionsSelection,
  dimensionsSelection,
  dimensionsList,
  extraColumns = [],
  areAllSelected,
  setAreAllSelected,
}) => {
  return (
    <Table
      rowKey="id"
      size="small"
      showHeader={false}
      loading={loadingTable}
      dataSource={dataSource}
      columns={[
        ...dimensionsColumns({
          key: dataKey,
          dimensionsList,
          loading,
          setDimensionsSelection,
          dimensionsSelection,
          areAllSelected,
          setAreAllSelected,
        }),
        ...extraColumns,
      ]}
      scroll={{ x: 'max-content' }}
      pagination={false}
    />
  )
}

export default CombinationDimensionsTable
