import * as types from './constants'
import { PROJECTION } from 'modules/core/constants'
import { saveAs } from 'file-saver'
import queryString from 'query-string'
import i18n from 'i18next'
// import { GA } from 'modules/core/utils';

export const setData = (data) => async (dispatch) =>
  dispatch({
    type: types.SET_DATA,
    payload: data,
  })

export const fetchDataNestedTableByPhase =
  (params = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.FETCH_DATA_NESTED_TABLE_BY_PHASE,
      payload: {
        request: {
          url: `/planning/get_data_step_table/`,
          method: 'GET',
          params,
          paramsSerializer: (params) => queryString.stringify(params),
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const fetchExpandNestedTableRow =
  (rowId, params = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.FETCH_EXPAND_NESTED_TABLE_ROW,
      payload: {
        request: {
          url: `/planning/get_data_step_table_detail/${rowId}/`,
          method: 'GET',
          params: { rowId, ...params },
          paramsSerializer: (params) => queryString.stringify(params),
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const downloadBudgetTableNestedXLS =
  (filename, params = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.DOWNLOAD_BUDGET_TABLE_NESTED_XLS,
      payload: {
        request: {
          url: `/planning/get_data_step_table_download/`,
          method: 'GET',
          responseType: 'blob',
          params,
          paramsSerializer: (params) => queryString.stringify(params),
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    saveAs(response.payload.data, `${filename}.xlsx`)
    return Promise.resolve()
  }

export const fetchBudgetInstanceDetail = (id) => async (dispatch) => {
  const response = await dispatch({
    type: types.FETCH_BUDGET_INSTANCE_DETAIL,
    payload: {
      request: {
        url: `/planning/get/${id}/`,
        method: 'GET',
      },
    },
  })
  if (response.error) {
    return Promise.reject(response.error)
  }
  return response
}

export const setNextPhase =
  (data = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.SET_NEXT_PHASE,
      payload: {
        request: {
          url: `/planning/next_step/`,
          method: 'POST',
          data,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const closingBudget =
  (data = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.CLOSING_BUDGET,
      payload: {
        request: {
          url: `/planning/closing/`,
          method: 'POST',
          data,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const fetchPeriodList = () => async (dispatch) => {
  const response = await dispatch({
    type: types.FETCH_PERIODS,
    payload: {
      request: {
        url: `/core/periods/get_periods/`,
        method: 'GET',
      },
    },
  })
  if (response.error) {
    return Promise.reject(response.error)
  }
  return response
}

export const orderPeriodCard =
  (data = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.ORDER_PERIOD_CARD,
      payload: {
        request: {
          url: `/core/order_periods/`,
          method: 'POST',
          data,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const setPeriodOrder = (data) => (dispatch) =>
  dispatch({
    type: types.SET_PERIOD_ORDER,
    payload: data,
  })

export const fetchGlobalBudgetChart =
  (params = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.FETCH_GLOBAL_BUDGET_CHART,
      payload: {
        request: {
          url: `/planning/overall_budget/get_data_graphic/`,
          method: 'GET',
          params,
          paramsSerializer: (params) => queryString.stringify(params),
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const fetchGlobalBudgetEvolutionTable =
  (reportId, budgetId, step, params = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.FETCH_GLOBAL_BUDGET_EVOLUTION_TABLE,
      payload: {
        request: {
          url: `/planning/overall_budget/get_evolutionary/${reportId}/${budgetId}/${step}/`,
          method: 'GET',
          params,
          paramsSerializer: (params) => queryString.stringify(params),
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const fetchGlobalBudgetKpi =
  (periodId, budgetId, data = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.FETCH_GLOBAL_BUDGET_KPI,
      payload: {
        request: {
          url: `/planning/overall_budget/kpis_cards/${periodId}/${budgetId}/`,
          method: 'POST',
          data,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const fetchEvolutionTableOpenRow =
  (reportId, globalBudgetId, step, rowId, params = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.FETCH_EVOLUTION_TABLE_OPEN_ROW,
      payload: {
        request: {
          url: `/planning/overall_budget/get_evolutionary_detail/${reportId}/${globalBudgetId}/${step}/${rowId}/`,
          method: 'GET',
          params: { rowId, ...params },
          paramsSerializer: (params) => queryString.stringify(params),
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const downloadGlobalBudgeEvolutiontXLS =
  (filename = i18n.t('PLANNING_EVOLTION_FILE_NAME'), reportId, budgetId, step, params = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.DOWNLOAD_GLOBAL_BUDGET_EVOLUTION_XLS,
      payload: {
        request: {
          url: `/planning/overall_budget/get_evolutionary_download/${reportId}/${budgetId}/${step}/`,
          method: 'GET',
          responseType: 'blob',
          params,
          paramsSerializer: (params) => queryString.stringify(params),
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    saveAs(response.payload.data, `${filename}.xlsx`)
    return Promise.resolve()
  }

export const downloadGlobalBudgeComparisontXLS =
  (filename = i18n.t('PLANNING_COMPARISON_FILE_NAME'), reportId, budgetId, params = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.DOWNLOAD_GLOBAL_BUDGET_COMPARISON_XLS,
      payload: {
        request: {
          url: `/planning/overall_budget/get_comparative_download/${reportId}/${budgetId}/`,
          method: 'GET',
          responseType: 'blob',
          params,
          paramsSerializer: (params) => queryString.stringify(params),
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    saveAs(response.payload.data, `${filename}.xlsx`)
    return Promise.resolve()
  }

export const fetchPeriodDetail = (id) => async (dispatch) => {
  const response = await dispatch({
    type: types.FETCH_PERIOD_DETAIL,
    payload: {
      request: {
        url: `/planning/periods/${id}/`,
        method: 'GET',
      },
    },
  })
  if (response.error) {
    return Promise.reject(response.error)
  }
  return response
}

export const createPeriod =
  (data = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.CREATE_PERIOD,
      payload: {
        request: {
          url: `/core/periods/create/`,
          method: 'POST',
          data,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return dispatch(fetchPeriodList())
  }

export const deletePeriod_ = (id) => async (dispatch) => {
  const response = await dispatch({
    type: types.DELETE_PERIOD,
    payload: {
      request: {
        url: `/core/periods/delete/${id}/`,
        method: 'DELETE',
      },
    },
  })
  if (response.error) {
    return Promise.reject(response.error)
  }
  return response
}

export const editPeriod =
  (id, data = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.EDIT_PERIOD,
      payload: {
        request: {
          url: `/core/periods/${id}/`,
          method: 'PATCH',
          data,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const deletePeriod =
  (periodId, params = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.DELETE_PERIOD,
      payload: {
        request: {
          url: `/planning/periods/${periodId}/`,
          method: 'DELETE',
          params,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const fetchConceptTemplateXLS =
  (params = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.FETCH_CONCEPT_TEMPLATE_XLS,
      payload: {
        request: {
          url: `/planning/concepts/template/`,
          method: 'GET',
          responseType: 'blob',
          params,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }

    saveAs(response.payload.data, i18n.t('CONFIG_CONCEPTS_TEMPLATE'))
    return Promise.resolve()
  }

export const fetchConceptConsolidatedXLS =
  (conceptType, params = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.FETCH_CONCEPT_CONSOLIDATED_XLS,
      payload: {
        request: {
          url: `/planning/concepts/consolidated/`,
          method: 'GET',
          responseType: 'blob',
          params,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }

    saveAs(
      response.payload.data,
      i18n.t('CONFIG_CONCEPTS_FILE_NAME', {
        conceptType,
        conceptName: i18n.t(params.concept_name),
      }),
    )
    return Promise.resolve()
  }

/////////////////////////////////////

export const fetchBudgetInstanceList =
  (conceptId, periodId, params = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.FETCH_BUDGET_INSTANCE_LIST,
      payload: {
        request: {
          url: `/planning/get_all/${conceptId}/${periodId}/`,
          method: 'GET',
          params,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response.payload.data.results
  }

export const fetchProjectionDetail = (id) => async (dispatch) => {
  return dispatch({
    type: types.FETCH_PROJECTION_DETAIL,
    payload: {
      request: {
        url: `/planning/projections/${id}/`,
        method: 'GET',
      },
    },
  })
}

export const updateTransaction =
  (data = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.UPDATE_TRANSACTION,
      payload: {
        request: {
          url: `/planning/data_step/row_edit/`,
          method: 'PUT',
          data,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const updateTransactionNested =
  (data = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.UPDATE_TRANSACTION_NESTED,
      payload: {
        request: {
          url: `/planning/update_data_step_table_cell/`,
          method: 'PUT',
          data,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const updatePercentageTransactionNested =
  (data = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.UPDATE_PERCENTAGE_TRANSACTION_NESTED,
      payload: {
        request: {
          url: `/planning/update_data_percentage_step_table_cell/`,
          method: 'PUT',
          data,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const updateTransactionForward =
  (data = {}) =>
  async (dispatch) => {
    const { startDate, endDate, rowId, ...restData } = data
    const response = await dispatch({
      type: types.UPDATE_TRANSACTION_FORWARD,
      payload: {
        request: {
          url: `/planning/rows/${rowId}/`,
          method: 'PATCH',
          data: {
            start_date: startDate,
            end_date: endDate,
            ...restData,
          },
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const updateTransactionAll =
  ({ projectionId, value }, params = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.UPDATE_TRANSACTION_ALL,
      payload: {
        request: {
          url: `/planning/projections/${projectionId}/rows/${projectionId}/`,
          method: 'PATCH',
          params: {
            ...params,
            percentage_to_apply: value,
            filter: 'conventional',
          },
          paramsSerializer: (params) => queryString.stringify(params),
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

//++ USERS TRACKS
export const fetchUserTracks =
  (projectionId, params = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.FETCH_USER_TRACKS,
      payload: {
        request: {
          url: `/planning/tracks/`,
          method: 'GET',
          params: {
            ...params,
            projection: projectionId,
          },
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

//++ FILE TRACKS
export const fetchFileTracks = (params) => async (dispatch) => {
  const response = await dispatch({
    type: types.FETCH_FILE_TRACKS,
    payload: {
      request: {
        url: '/auth/users/tracker/get_activities/',
        method: 'GET',
        params,
      },
    },
  })
  if (response.error) {
    return Promise.reject(response.error)
  }
  return response
}

// ++ BUDGET TRANSACTIONS ++
export const fetchProjectionColumns =
  (id, params = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.FETCH_PROJECTION_COLUMNS,
      payload: {
        request: {
          url: `/planning/projections/${id}/columns/`,
          method: 'GET',
          params,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const fetchProjectionRows = (id, params) => async (dispatch) => {
  const response = await dispatch({
    type: types.FETCH_PROJECTION_ROWS,
    payload: {
      request: {
        url: `/planning/projections/${id}/rows/`,
        method: 'GET',
        params: { page_size: 12, page: 1, ...params },
        paramsSerializer: (params) => queryString.stringify(params),
      },
    },
  })
  if (response.error) {
    return Promise.reject(response.error)
  }
  return response
}

export const fetchProjectionAmountsRows = (id, params) => async (dispatch) => {
  const response = await dispatch({
    type: types.FETCH_PROJECTION_AMOUNTS_ROWS,
    payload: {
      request: {
        url: `/planning/projections/${id}/rows/`,
        method: 'GET',
        params: {
          page_size: 12,
          page: 1,
          filter: PROJECTION.LOAD_TYPES.CONVENTIONAL.id,
          ...params,
        },
        paramsSerializer: (params) => queryString.stringify(params),
      },
    },
  })
  if (response.error) {
    return Promise.reject(response.error)
  }
  return response
}

// ++ XLS ++
export const downloadTemplateXLS =
  (projectionId, filename = i18n.t('PLANNING_TEMPLATE_NAME_DEFAULT'), params = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.FETCH_PROJECTION_TEMPLATE_XLS,
      payload: {
        request: {
          url: `/planning/projections/${projectionId}/template/`,
          method: 'GET',
          params,
          responseType: 'blob',
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    saveAs(response.payload.data, `${filename}.xlsx`)
    return Promise.resolve()
  }

export const downloadVariablesTemplateXLS =
  (id, filename = i18n.t('PLANNING_TEMPLATE_NAME_DEFAULT'), params = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.DOWNLOAD_VARIABLES_TEMPLATE_XLS,
      payload: {
        request: {
          url: `/planning/business_rules/download-template/${id}/`,
          method: 'GET',
          responseType: 'blob',
          params,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    saveAs(response.payload.data, `${filename}.xlsx`)
    return Promise.resolve()
  }

export const uploadVariablesFileXLS = (id, fileList, type, pk) => async (dispatch) => {
  const formData = new FormData()
  fileList.forEach((file) => {
    formData.append('file', file)
  })
  formData.append('period_id', id)
  formData.append('type', type)
  formData.append('pk', pk)
  const response = await dispatch({
    type: types.UPLOAD_VARIABLES_FILE_XLS,
    payload: {
      request: {
        url: `/planning/business_rules/upload-template/`,
        method: 'POST',
        data: formData,
      },
    },
  })
  if (response.error) {
    return Promise.reject(response.error)
  }
  return response
}

export const uploadProjectionXLS =
  (projectionId, data, params = {}) =>
  async (dispatch) => {
    const formData = new FormData()
    data.forEach((file) => {
      formData.append('input_file', file)
    })
    const response = await dispatch({
      type: types.UPLOAD_PROJEXTION_XLS,
      payload: {
        request: {
          url: `/planning/projections/${projectionId}/excel-upload/`,
          method: 'POST',
          data: formData,
          params,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }

    return response
  }

export const uploadBudgetTypeValuesXLS =
  (data, params = {}) =>
  async (dispatch) => {
    const formData = new FormData()
    data.forEach((file) => {
      formData.append('file', file)
    })
    const response = await dispatch({
      type: types.UPLOAD_BUDGET_TYPE_VALUES_XLS,
      payload: {
        request: {
          url: `/planning/concepts/concept-configs/`,
          method: 'POST',
          data: formData,
          params,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error.response.data[0])
    }
    return response
  }

export const downloadBudgetTableByPhaseXLS =
  (filename, params = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.DOWNLOAD_BUDGET_TABLE_BY_PHASE_XLS,
      payload: {
        request: {
          url: `/planning/download_file/`,
          method: 'GET',
          responseType: 'blob',
          params,
          paramsSerializer: (params) => queryString.stringify(params),
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    saveAs(response.payload.data, `${filename}.csv`)
    return Promise.resolve()
  }

export const downloadBudgetTableByPhaseExcel =
  (filename, params = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.DOWNLOAD_BUDGET_TABLE_BY_PHASE_XLS,
      payload: {
        request: {
          url: `/planning/download_file_xlsx/`,
          method: 'GET',
          responseType: 'blob',
          params,
          paramsSerializer: (params) => queryString.stringify(params),
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    saveAs(response.payload.data, `${filename}.xlsx`)
    return Promise.resolve()
  }

export const downloadBudgetTableByPhaseParquet =
  (filename, params = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.DOWNLOAD_BUDGET_TABLE_BY_PHASE_XLS,
      payload: {
        request: {
          url: `/planning/download_file_parquet/`,
          method: 'GET',
          responseType: 'blob',
          params,
          paramsSerializer: (params) => queryString.stringify(params),
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    saveAs(response.payload.data, `${filename}.parquet`)
    return Promise.resolve()
  }

// PROJECTION DETAIL KPIS
export const fetchBudgetKpis =
  (params = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.FETCH_BUDGET_KPIS,
      payload: {
        request: {
          url: `/planning/kpis_step/`,
          method: 'GET',
          params,
          paramsSerializer: (params) => queryString.stringify(params),
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }
export const fetchProjectionAmountsKpis =
  (id, params = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.FETCH_PROJECTION_AMOUNTS_KPIS,
      payload: {
        request: {
          url: `/planning/projections/${id}/kpis/`,
          method: 'GET',
          params: {
            table_type: PROJECTION.LOAD_TYPES.CONVENTIONAL.id,
            ...params,
          },
          paramsSerializer: (params) => queryString.stringify(params),
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

// ++APPROVE BUTTON ON PROJECTION DETAIL++
export const approveGlobalBudget = (id) => async (dispatch) => {
  const response = await dispatch({
    type: types.APPROVE_GLOBAL_BUDGET,
    payload: {
      request: {
        url: `/planning/overall_budget/approved/${id}/`,
        method: 'POST',
      },
    },
  })
  if (response.error) {
    //GA.event(GA.ACTIONS.APPROVE_DATA_BUDGET_ERROR);
    return Promise.reject(response.error)
  }
  // GA.event({ ...GA.ACTIONS.APPROVE_DATA_BUDGET, label: id });
  return Promise.resolve()
}

// ++FILTERS++
export const fetchProjectionFilters =
  (idConcept = null, params = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.FETCH_PROJECTION_FILTERS,
      payload: {
        request: {
          url: idConcept
            ? `/planning/concepts/${idConcept}/filters/`
            : `/planning/concepts/filters/`,
          method: 'GET',
          params,
          paramsSerializer: (params) => queryString.stringify(params),
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const fetchPublishedAccounts =
  (data, params = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.FETCH_PUBLISHED_ACCOUNTS,
      payload: {
        request: {
          url: '/planning/rows/dimensions/',
          method: 'POST',
          data: { ...data, desired_dimension_id: 1 },
          params,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const cleanPublishedDates = () => (dispatch) =>
  dispatch({
    type: types.CLEAN_PUBLISHED_DATES,
  })

export const applyVariables = (budgetId, varList) => async (dispatch) => {
  const response = await dispatch({
    type: types.FETCH_PUBLISHED_ACCOUNTS,
    payload: {
      request: {
        url: '/planning/variables/apply/',
        method: 'POST',
        data: {
          projection_id: budgetId,
          variable_ids: varList,
        },
      },
    },
  })
  if (response.error) {
    return Promise.reject(response.error)
  }
  return response.payload.data
}
// ++ Upload limit date  ++
export const setUploadLimitDate =
  (data, params = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.SET_UPLOAD_LIMIT_DATE,
      payload: {
        request: {
          url: '/planning/upload-limit-date/',
          method: 'POST',
          data,
          params,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response.payload.data
  }

export const updateUploadLimitDate =
  (id, data = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.SET_UPLOAD_LIMIT_DATE,
      payload: {
        request: {
          url: `/planning/upload-limit-date/${id}/`,
          method: 'PATCH',
          data,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response.payload.data
  }

export const getUploadLimitDate =
  (params = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.FETCH_UPLOAD_LIMIT_DATE,
      payload: {
        request: {
          url: '/planning/upload-limit-date/',
          method: 'GET',
          params,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response.payload.data.results
  }
// ++ CREAR PPTO ++
export const createBudgetInstance =
  (data = {}) =>
  async (dispatch) => {
    const { file_upload, ...restProps } = data
    const formData = new FormData()
    if (file_upload) {
      file_upload.forEach((file) => {
        formData.append('file_upload', file)
      })
      formData.append('type_budget', restProps.type_budget)
      formData.append('concept', restProps.concept)
      formData.append('name', restProps.name)
      formData.append('period_id', restProps.period_id)
      restProps.description && formData.append('description', restProps.description)
    }
    const response = await dispatch({
      type: types.CREATE_BUDGET_INSTANCE,
      payload: {
        request: {
          url: `/planning/create/`,
          method: 'POST',
          data: file_upload ? formData : { ...restProps },
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const updateBudgetInstance =
  (data = {}) =>
  async (dispatch) => {
    const { file_upload, ...restProps } = data
    const formData = new FormData()
    if (file_upload) {
      file_upload.forEach((file) => {
        formData.append('file_upload', file)
      })
      formData.append('concept', restProps.concept)
      formData.append('type_budget', restProps.type_budget)
      formData.append('planning_id', restProps.planning_id)
      formData.append('type_of_load', restProps.type_of_load)
      formData.append('date_format', restProps.date_format)
    }
    const response = await dispatch({
      type: types.UPDATE_BUDGET_INSTANCE,
      payload: {
        request: {
          url: `/planning/update/`,
          method: 'PUT',
          data: file_upload ? formData : { ...restProps },
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

// ++ EDITAR NOMBRE PPTO ++
export const editBudgetName = (id, data) => async (dispatch) => {
  const response = await dispatch({
    type: types.EDIT_BUDGET_NAME,
    payload: {
      request: {
        url: `/planning/update/${id}/`,
        method: 'PUT',
        data,
      },
    },
  })
  if (response.error) {
    return Promise.reject(response.error)
  }
  return response
}

export const editGlobalBudgetName = (id, data) => async (dispatch) => {
  const response = await dispatch({
    type: types.EDIT_GLOBAL_BUDGET_NAME,
    payload: {
      request: {
        url: `/planning/overall_budget/update/${id}/`,
        method: 'PUT',
        data,
      },
    },
  })
  if (response.error) {
    return Promise.reject(response.error)
  }
  return response
}

//++ ELIMINAR PPTO ++

export const deleteBudget = (id) => async (dispatch) => {
  const response = await dispatch({
    type: types.DELETE_BUDGET,
    payload: {
      request: {
        url: `/planning/delete/${id}/`,
        method: 'DELETE',
      },
    },
  })
  if (response.error) {
    return Promise.reject(response.error.response.data)
  }
  return response
}

export const deleteGlobalBudget = (id) => async (dispatch) => {
  const response = await dispatch({
    type: types.DELETE_GLOBAL_BUDGET,
    payload: {
      request: {
        url: `/planning/overall_budget/delete/${id}/`,
        method: 'DELETE',
      },
    },
  })
  if (response.error) {
    return Promise.reject(response.error.response.data)
  }
  return response
}

//++ DUPLICAR PPTO ++

export const duplicateBudget = (projectionId) => async (dispatch) => {
  const response = await dispatch({
    type: types.DUPLICATE_BUDGET,
    payload: {
      request: {
        url: `/planning/duplicate_planning/${projectionId}/`,
        method: 'POST',
      },
    },
  })
  if (response.error) {
    return Promise.reject(response.error.response.data)
  }
  return response
}

// ++ Global budget ++

export const fetchGlobalBudgetList =
  (params = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.FETCH_GLOBAL_BUDGET_LIST,
      payload: {
        request: {
          url: `/planning/overall_budget/get_all/`,
          method: 'GET',
          params,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const fetchGlobalBudgetDetail = (id) => async (dispatch) => {
  const response = await dispatch({
    type: types.FETCH_GLOBAL_BUDGET_DETAIL,
    payload: {
      request: {
        url: `/planning/overall_budget/get/${id}/`,
        method: 'GET',
      },
    },
  })
  if (response.error) {
    return Promise.reject(response.error)
  }
  return response
}

export const fetchGlobalBudgetComparisonTable =
  (reportId, idGlobalBudget, params = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.FETCH_GLOBAL_BUDGET_COMPARISON_TABLE,
      payload: {
        request: {
          url: `/planning/overall_budget/get_detail/${reportId}/${idGlobalBudget}/`,
          method: 'GET',
          params,
          paramsSerializer: (params) => queryString.stringify(params),
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const fetchComparisonTableOpenRow =
  (reportId, budgetId, rowId, params = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.FETCH_COMPARISON_TABLE_OPEN_ROW,
      payload: {
        request: {
          url: `/planning/overall_budget/comparative/get_detail/${reportId}/${budgetId}/${rowId}/`,
          method: 'GET',
          params: { rowId, ...params },
          paramsSerializer: (params) => queryString.stringify(params),
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const createGlobalBudget =
  (data = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.CREATE_GLOBAL_BUDGET,
      payload: {
        request: {
          url: '/planning/overall_budget/create/',
          method: 'POST',
          data,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const fetchGlobalBudgetDetailKPI = (id) => async (dispatch) => {
  const response = await dispatch({
    type: types.FETCH_GLOBAL_BUDGET_DETAIL_KPI,
    payload: {
      request: {
        url: `/planning/projections/${id}/consolidation-kpis/`,
        method: 'GET',
      },
    },
  })
  if (response.error) {
    return Promise.reject(response.error)
  }
  return response
}

export const changeBudget =
  (id, data = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.CHANGE_BUDGET,
      payload: {
        request: {
          url: `/planning/overall_budget/update_detail/${id}/`,
          method: 'PUT',
          data,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

//eliminar linea de ppto
export const deleteBudgetRow = (id) => async (dispatch) => {
  const response = await dispatch({
    type: types.DELETE_BUDGET_ROW,
    payload: {
      request: {
        url: `/planning/rows/${id}/`,
        method: 'DELETE',
      },
    },
  })
  if (response.error) {
    return Promise.reject(response.error.response.data)
  }
  return response
}

//Eliminar toda la data de ppto
export const deleteAllProjectionRows =
  (projectionId, params = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.DELETE_ALL_PROJECTION_ROWS,
      payload: {
        request: {
          url: `/planning/projections/${projectionId}/delete-all-rows/`,
          method: 'DELETE',
          params,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const fetchFormulasAmountList =
  (params = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.FETCH_FORMULAS_AMOUNT_LIST,
      payload: {
        request: {
          url: `/planning/formulas/count/`,
          method: 'GET',
          params,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }

    return response
  }

export const fetchFactorRows =
  (params = {}) =>
  async (dispatch) => {
    const { factorId, projectionId, ...restParams } = params
    const response = await dispatch({
      type: types.FETCH_FACTOR_ROWS,
      payload: {
        request: {
          url: `/planning/factor/${factorId}/projection/${projectionId}/rows/`,
          method: 'GET',
          params: restParams,
          paramsSerializer: (params) => queryString.stringify(params),
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }

    return response
  }

export const deleteAllFactorRows =
  (id, params = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.DELETE_ALL_FACTOR_ROWS,
      payload: {
        request: {
          url: `/planning/factores/${id}/delete-all-rows/`,
          method: 'DELETE',
          params,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }

    return response
  }

export const deleteFactorRow =
  (id, params = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.DELETE_FACTOR_ROW,
      payload: {
        request: {
          url: `/planning/factores/${id}/delete-row/`,
          method: 'DELETE',
          params,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }

    return response
  }

export const replicateFactor =
  (data = {}) =>
  async (dispatch) => {
    const { factorId, projectionId, destinationFactor } = data
    const response = await dispatch({
      type: types.REPLICATE_FACTOR,
      payload: {
        request: {
          url: `/planning/factores/${factorId}/retort/`,
          method: 'POST',
          data: {},
          params: {
            projection_id: projectionId,
            factor_to_replicate_id: destinationFactor,
          },
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }

    return response
  }

export const fetchFactorColumns =
  (id, params = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.FETCH_FACTOR_COLUMNS,
      payload: {
        request: {
          url: `/planning/formulas/${id}/columns/`,
          method: 'GET',
          params,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }

    return response
  }

export const updateTransactionFactor =
  (data = {}) =>
  async (dispatch) => {
    const { transactionId, amount } = data
    const response = await dispatch({
      type: types.UPDATE_TRANSACTION_FACTOR,
      payload: {
        request: {
          url: `/planning/relacion-factores-transaccion/${transactionId}/`,
          method: 'PATCH',
          data: { factor_value: amount },
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }

    return response
  }

export const unApplyRule =
  (budgetId, ruleId, params = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.UNAPPLY_RULE,
      payload: {
        request: {
          url: `/planning/business_rules/planning_overall_budget/unapply/${ruleId}/${budgetId}/`,
          method: 'POST',
          params,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }

    return response
  }

export const applyRule =
  (budgetId, ruleId, params = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.APPLY_RULE,
      payload: {
        request: {
          url: `/planning/business_rules/planning_overall_budget/apply/${ruleId}/${budgetId}/`,
          method: 'POST',
          params,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }

    return response
  }

export const applyBudgetRule =
  (budgetId, ruleId, data = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.APPLY_BUDGET_RULE,
      payload: {
        request: {
          url: `/planning/business_rules/planning/apply/${ruleId}/${budgetId}/`,
          method: 'POST',
          data,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }

    return response
  }

export const unApplyBudgetRule =
  (budgetId, ruleId, data = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.UNAPPLY_BUDGET_RULE,
      payload: {
        request: {
          url: `/planning/business_rules/planning/unapply/${ruleId}/${budgetId}/`,
          method: 'POST',
          data,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }

    return response
  }

export const saveVariableValues =
  (ruleId, varId, data = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.SAVE_VARIABLE_VALUES,
      payload: {
        request: {
          url: `/planning/business_rules/${ruleId}/assign_variable_values/${varId}/`,
          method: 'POST',
          data,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }

    return response
  }

export const fetchDimensionsValuesList = (id) => async (dispatch) => {
  const response = await dispatch({
    type: types.FETCH_DIMENSIONS_VALUES_LIST,
    payload: {
      request: {
        url: `/planning/periods/${id}/dimension_values_list/`,
        method: 'GET',
      },
    },
  })

  if (response.error) {
    return Promise.reject(response.error)
  }
  return response
}

// NOTA: Fijar presupuesto global por defecto en ppto
export const setDefaultBudget = (id) => async (dispatch) => {
  const response = await dispatch({
    type: types.SET_DEFAULT_BUDGET,
    payload: {
      request: {
        url: `/planning/overall_budget/predetermined/${id}/`,
        method: 'POST',
        params: { id },
      },
    },
  })
  if (response.error) {
    return Promise.reject(response.error)
  }
  return response
}

export const updateGlobalBudget = (id) => async (dispatch) => {
  const response = await dispatch({
    type: types.UPDATE_GLOBAL_BUDGET,
    payload: {
      request: {
        url: `/planning/projections/${id}/update-consolidated/`,
        method: 'POST',
      },
    },
  })
  if (response.error) {
    return Promise.reject(response.error)
  }
  return response
}

export const publishGlobalBudget = (id) => async (dispatch) => {
  const response = await dispatch({
    type: types.PUBLISH_GLOBAL_BUDGET,
    payload: {
      request: {
        url: `/planning/overall_budget/plublished/${id}/`,
        method: 'POST',
      },
    },
  })
  if (response.error) {
    return Promise.reject(response.error)
  }
  return response
}

// ++ COMMENTS ++
export const fetchTransactionComments = (transactionId) => async (dispatch) => {
  const response = await dispatch({
    type: types.FETCH_TRANSACTION_COMMENTS,
    payload: {
      request: {
        url: `/planning/comments/get_comments/?transaction_id=${transactionId}`,
        method: 'GET',
      },
    },
  })

  if (response.error) {
    return Promise.reject(response.error)
  }
  return response
}

export const syncBudgetComment =
  (data = {}) =>
  (dispatch) => {
    const { type } = data
    const aux = {
      create: types.SYNC_CREATE_BUDGET_COMMENT,
      edit: types.SYNC_EDIT_BUDGET_COMMENT,
      delete: types.SYNC_DELETE_BUDGET_COMMENT,
    }

    // Reviso que venga la prop newComment, sino asumo que esta borrando comentario.
    return dispatch({
      type: aux[type],
      payload: { data },
    })
  }

export const createTransactionComment =
  (data = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.CREATE_TRANSACTION_COMMENT,
      payload: {
        request: {
          url: `/planning/comment/create/`,
          method: 'POST',
          data,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const deleteTransactionComment =
  (data = {}) =>
  async (dispatch) => {
    const { commentId } = data
    const response = await dispatch({
      type: types.DELETE_TRANSACTION_COMMENT,
      payload: {
        request: {
          url: `/planning/comments/delete_comment/?comment_id=${commentId}`,
          method: 'DELETE',
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }

    return response
  }

export const editTransactionComment =
  (data = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.EDIT_TRANSACTION_COMMENT,
      payload: {
        request: {
          url: `/planning/comment/update/`,
          method: 'PUT',
          data,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }

    return response
  }

// ++ END COMMENTS ++

//multimoneda

export const fetchMonthsList = (periodId) => async (dispatch) => {
  const response = await dispatch({
    type: types.FETCH_MONTHS_LIST,
    payload: {
      request: {
        url: `/planning/periods/${periodId}/dates/`,
        method: 'GET',
      },
    },
  })

  if (response.error) {
    return Promise.reject(response.error)
  }
  return response
}

export const fetchCurrenciesTracks =
  (params = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.FETCH_CURRENCIES_TRACKS,
      payload: {
        request: {
          url: `/planning/exchange-rate-tracking/`,
          method: 'GET',
          params,
        },
      },
    })

    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const saveExchangeRate =
  (id, data = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.SAVE_EXCHANGE_RATE,
      payload: {
        request: {
          url: `/planning/exchange-rate/${id}/`,
          method: 'PATCH',
          data,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

//INTEGRATIONS
export const integrationFilesUpload =
  (data, presupuesto_ventas_id, presupuesto_gastos_id) => async (dispatch) => {
    const formData = new FormData()
    data.forEach((file) => {
      formData.append('files_field', file['originFileObj'])
    })
    presupuesto_ventas_id.forEach((v) => {
      formData.append('presupuesto_ventas_id', v)
    })
    presupuesto_gastos_id.forEach((g) => {
      formData.append('presupuesto_gastos_id', g)
    })
    const response = await dispatch({
      type: types.INTEGRATIONS_UPLOAD_FILE,
      payload: {
        request: {
          url: `planning/projections/upload_files/`,
          method: 'POST',
          data: formData,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const fetchOriginRows =
  (rowId, params = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.FETCH_ORIGIN_ROWS,
      payload: {
        request: {
          url: `/planning/rows/${rowId}/get_origin_rows/`,
          method: 'GET',
          params,
          data: rowId,
        },
      },
    })

    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const editTransactionOriginCurrency =
  (data = {}) =>
  async (dispatch) => {
    const { transactionId, amount } = data
    const response = await dispatch({
      type: types.EDIT_TRANSACTION_ORIGIN_CURRENCY,
      payload: {
        request: {
          url: `/planning/origin-transactions/${transactionId}/`,
          method: 'PATCH',
          data: { amount },
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const createTransactionOriginCurrency =
  (data = {}) =>
  async (dispatch) => {
    const { amount, date, origin_row } = data
    const response = await dispatch({
      type: types.CREATE_TRANSACTION_ORIGIN_CURRENCY,
      payload: {
        request: {
          url: `/planning/origin-transactions/`,
          method: 'POST',
          data: {
            amount,
            date,
            origin_row,
          },
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const updateTransactionForwardOrigin =
  (data = {}) =>
  async (dispatch) => {
    const { startDate, endDate, rowId, amount } = data
    const response = await dispatch({
      type: types.UPDARE_TRANSACTION_FORWARD_ORIGIN,
      payload: {
        request: {
          url: `/planning/origin-rows/${rowId}/auto_fill_origin_transactions/`,
          method: 'PATCH',
          data: {
            start_date: startDate,
            end_date: endDate,
            amount,
          },
        },
      },
    })

    if (response.error) {
      return Promise.reject(response.error)
    }

    return response
  }

export const fetchPXQ = () => async (dispatch) => {
  const response = await dispatch({
    type: types.FETCH_PXQ,
    payload: {
      request: {
        url: `/planning/PxQ/`,
        method: 'GET',
      },
    },
  })

  if (response.error) {
    return Promise.reject(response.error)
  }

  return response
}

export const createPXQ = () => async (dispatch) => {
  const response = await dispatch({
    type: types.CREATE_PXQ,
    payload: {
      request: {
        url: `/planning/PxQ/`,
        method: 'POST',
      },
    },
  })

  if (response.error) {
    return Promise.reject(response.error)
  }

  return response
}

export const enablePXQ =
  (formulaId, data = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.ENABLE_PXQ,
      payload: {
        request: {
          url: `/planning/PxQ/${formulaId}/`,
          method: 'PATCH',
          data,
        },
      },
    })

    if (response.error) {
      return Promise.reject(response.error)
    }

    return response
  }

export const updateCellPXQ =
  (formulaId, params = {}, data = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.UPDATE_CELL_PXQ,
      payload: {
        request: {
          url: `/planning/PxQ/${formulaId}/`,
          method: 'PUT',
          params,
          data,
        },
      },
    })

    if (response.error) {
      return Promise.reject(response.error)
    }

    return response
  }

export const setDataUpdateCellPXQ = (data) => (dispatch) =>
  dispatch({
    type: types.SET_DATA_UPDATE_CELL_PXQ,
    payload: data,
  })

export const deleteRow =
  (params = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.DELETE_ROW,
      payload: {
        request: {
          url: `/core/transactions/planning/delete_row_planning/`,
          method: 'DELETE',
          params,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const deleteAll =
  (params = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.DELETE_ALL,
      payload: {
        request: {
          url: `/core/transactions/planning/delete_all_by_concept/`,
          method: 'DELETE',
          params,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const deleteFilter =
  (params = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.DELETE_FILTER,
      payload: {
        request: {
          url: `/core/transactions/planning/delete_row_by_filter/`,
          method: 'DELETE',
          params,
          paramsSerializer: (params) => queryString.stringify(params),
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const orderBudgetCard =
  (data = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.ORDER_BUDGET_CARD,
      payload: {
        request: {
          url: `/planning/order_budgets/`,
          method: 'POST',
          data,
        },
      },
    })

    if (response.error) {
      return Promise.reject(response.error)
    }

    return response
  }

export const setBudgetOrder = (data) => (dispatch) =>
  dispatch({
    type: types.SET_BUDGET_ORDER,
    payload: data,
  })

export const setGlobalBudgetOrder = (data) => (dispatch) =>
  dispatch({
    type: types.SET_GLOBAL_BUDGET_ORDER,
    payload: data,
  })

export const fetchMLList = () => async (dispatch) => {
  const response = await dispatch({
    type: types.FETCH_ML_LIST,
    payload: {
      request: {
        url: `/planning/get_list_create_planning/`,
        method: 'GET',
      },
    },
  })

  if (response.error) {
    return Promise.reject(response.error)
  }

  return response
}

export const orderGlobalBudgetCard =
  (data = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.ORDER_GLOBAL_BUDGET_CARD,
      payload: {
        request: {
          url: `/planning/order_overall_budgets/`,
          method: 'POST',
          data,
        },
      },
    })

    if (response.error) {
      return Promise.reject(response.error)
    }

    return response
  }

export const massiveCreatePlanningTransaction =
  (data = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.MASSIVE_CREATE_PLANNING_TRANSACTION,
      payload: {
        request: {
          url: `/planning/create/massive/`,
          method: 'POST',
          data,
        },
      },
    })

    if (response.error) {
      return Promise.reject(response.error)
    }

    return response
  }

export const changeCardColor =
  (data = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.CHANGE_CARD_COLOR,
      payload: {
        request: {
          url: `/planning/change_color_card/`,
          method: 'POST',
          data,
        },
      },
    })

    if (response.error) {
      return Promise.reject(response.error)
    }

    return response
  }
// TODO: NEW ENDPOINTS FOR REVAMP TABLE
export const fetchDataEvolutionByOverallBudget =
  (reportId, budgetId, step, params = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.FETCH_DATA_EVOLUTION_BY_OVERALL_BUDGET,
      payload: {
        request: {
          url: `planning/overall_budget/get_evolutionary_all_table/${reportId}/${budgetId}/${step}/`,
          method: 'GET',
          params: { ...params },
          // paramsSerializer: (params) => queryString.stringify(params),
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const fetchDataComparativeByOverallBudget =
  (reportId, budgetId, params = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.FETCH_DATA_COMPARATIVE_BY_OVERALL_BUDGET,
      payload: {
        request: {
          url: `planning/overall_budget/get_comparative_all_table/${reportId}/${budgetId}/`,
          method: 'GET',
          params: { ...params },
          // paramsSerializer: (params) => queryString.stringify(params),
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const fetchDataRdnPercentageByPlanning =
  (params = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.FETCH_DATA_RDN_PERCENTAGE_BY_PLANNING,
      payload: {
        request: {
          url: `/planning/get_percentage_step_table/`,
          method: 'GET',
          params: { ...params },
          paramsSerializer: (params) => queryString.stringify(params),
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const updateTransactionPercentageByPlanning =
  (data = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.UPDATE_TRANSACTION_PERCENTAGE_BY_PLANNING,
      payload: {
        request: {
          url: `/planning/update_data_step_table_cell_percentage/`,
          method: 'PUT',
          data,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const applyRdnPercentageByPlanning =
  (businessRuleId, budgetId, data = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.APPLY_RDN_PERCENTAGE_BY_PLANNING,
      payload: {
        request: {
          url: `/planning/business_rules/planning/percentage/apply/${businessRuleId}/${budgetId}/`,
          method: 'POST',
          data,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const unApplyRdnPercentageByPlanning =
  (businessRuleId, budgetId, data = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.UN_APPLY_RDN_PERCENTAGE_BY_PLANNING,
      payload: {
        request: {
          url: `/planning/business_rules/planning/percentage/unapply/${businessRuleId}/${budgetId}/`,
          method: 'POST',
          data,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const deleteTransactionFilterByPlanning =
  (planningId, step, filters = {}) =>
  async (dispatch) => {
    let dimension_filter = {}
    Object.entries(filters).forEach(function ([field_key, value]) {
      dimension_filter[field_key] = Object.entries(value).map(([field_key, _]) => field_key)
    })

    const response = await dispatch({
      type: types.DELETE_TRANSACTION_FILTER_BY_PLANNING,
      payload: {
        request: {
          url: `/core/transactions/planning/delete_rows/`,
          method: 'POST',
          data: {
            planning_id: planningId,
            step: step,
            dimension_filter: dimension_filter,
          },
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const duplicateGlobalBudget =
  (data = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.DUPLICATE_GLOBAL_BUDGET,
      payload: {
        request: {
          url: `/planning/overall_budget/create_copy/`,
          method: 'POST',
          data,
        },
      },
    })

    if (response.error) {
      return Promise.reject(response.error)
    }

    return response
  }

export const fetchPlanningFilterDates =
  (params = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.FETCH_PLANNING_FILTER_DATES,
      payload: {
        request: {
          url: `/planning/get_all_date_range/`,
          method: 'GET',
          params,
        },
      },
    })

    if (response.error) {
      return Promise.reject(response.error)
    }

    return response
  }
