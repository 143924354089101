import { PROJECTION } from 'modules/core/constants'

// TABLES
const DEFAULT_SETTINGS = {
  visibleData: null, // null-> all, [...]-> selected keys from options
  format: '0,0', // or '0[.]0'
}

const NEGATIVE_VALUES_TYPES = {
  NORMAL: 'normal',
  BRACKET: 'bracket',
}

const NEGATIVE_VALUES_TYPES_LIST = [
  {
    key: '-100',
    value: NEGATIVE_VALUES_TYPES.NORMAL,
  },
  {
    key: '(100)',
    value: NEGATIVE_VALUES_TYPES.BRACKET,
  },
]

const OPTIONS_DOWNLOAD_DEFAULT = [
  {
    id: 'spreadsheets',
    name: 'SpreadSheets',
  },
]

const OPTIONS_DOWNLOAD = [
  {
    id: 'file',
    name: 'Excel',
  },
  ...OPTIONS_DOWNLOAD_DEFAULT,
]

const OPTIONS_DOWNLOAD_CSV = [
  {
    id: 'file',
    name: 'csv',
  },
  ...OPTIONS_DOWNLOAD_DEFAULT,
]

const OPTIONS_DOWNLOAD_ALL = [
  {
    id: 'file',
    name: 'CSV',
  },
  {
    id: 'excel',
    name: 'Excel',
  },
  {
    id: 'parquet',
    name: 'Parquet',
  },
  ...OPTIONS_DOWNLOAD_DEFAULT,
]

export const TABLES = {
  DEFAULT_SETTINGS,
  NEGATIVE_VALUES_TYPES,
  NEGATIVE_VALUES_TYPES_LIST,
  OPTIONS_DOWNLOAD,
  OPTIONS_DOWNLOAD_CSV,
  OPTIONS_DOWNLOAD_ALL,
}

// COLUMNS
const TOTAL_DATA_INDEX = 'TOTAL'
const ANALYSIS_VERTICAL_INDEX = 'AV'
const FIRST_INDEX = 0
const MAX_COLUMNS_TO_FIX = 8

const COLUMNS_TYPES = {
  COLLAPSER: 'column-collapser',
  TOTALIZER: 'totalizer-column',
  TRANSACTION: 'transaction-column',
}

const COLUMNS_WIDTH = {
  TRANSACTION: 85,
  REPORT_TRANSACTION: 110,
  VARIATION_PERCENTAGE: 70,
  DIMENSION: 130,
  EXPANDABLE: 250,
  SMALL: 24,
  DEFAULT: 200,
  ACTIONS: 150,
}

const COLUMNS_PERCENTAGE = [
  PROJECTION.REAL_LAST_PERIOD_PERCENTAGE,
  PROJECTION.BUDGET_VAR_PERCENTAGE,
  PROJECTION.LAST_FORECAST_VAR_PERCENTAGE,
]

// NOTE: Order is important
const ALL_TRANSACTION_COLUMNS = [
  PROJECTION.BUDGET,
  PROJECTION.REAL,
  PROJECTION.BUDGET_VAR_MONEY,
  PROJECTION.BUDGET_VAR_PERCENTAGE,
  PROJECTION.REAL_LAST_PERIOD,
  PROJECTION.REAL_LAST_PERIOD_MONEY,
  PROJECTION.REAL_LAST_PERIOD_PERCENTAGE,
  PROJECTION.REAL_LAST_PERIOD,
  PROJECTION.REAL_LAST_PERIOD_MONEY,
  PROJECTION.LAST_FORECAST,
  PROJECTION.LAST_FORECAST_VAR_MONEY,
  PROJECTION.LAST_FORECAST_VAR_PERCENTAGE,
]

export const COLUMNS = {
  TOTAL_DATA_INDEX,
  ANALYSIS_VERTICAL_INDEX,
  MAX_COLUMNS_TO_FIX,
  FIRST_INDEX,
  TYPES: COLUMNS_TYPES,
  WIDTH: COLUMNS_WIDTH,
  PERCENTAGE: COLUMNS_PERCENTAGE,
  ALL_TRANSACTION: ALL_TRANSACTION_COLUMNS,
}

// ROWS
const TOTALIZER_ID = 0

const RATIO = 'ratio'
const RATION = 'ration'
const TOTALIZER = 'totalizer'
const FINANCIAL = 'financial'
const GROUPER = 'grouper'

const RATIO_ROW = 'ratio-row'
const TOTALIZER_ROW = 'totalizer-row'
const GROUPER_ROW = 'grouper-row'
const APPLIED_VAR_ROW = 'applied-var-row'
const APPLIED_RULE_ROW = 'applied-rule-row'
const FINANCIAL_ROW = 'financial-row'

const ROW_TYPES = {
  [RATIO]: RATIO_ROW,
  [RATION]: RATIO_ROW,
  [TOTALIZER]: TOTALIZER_ROW,
  [GROUPER]: GROUPER_ROW,
  APPLIED_VAR_ROW: APPLIED_VAR_ROW,
  APPLIED_RULE_ROW: APPLIED_RULE_ROW,
  [FINANCIAL]: FINANCIAL_ROW,
}

export const ROWS = {
  TOTALIZER_ID,
  RATIO,
  RATION,
  TOTALIZER,
  GROUPER,
  FINANCIAL,
  TYPES: ROW_TYPES,
}

const CELLS_TYPES = {
  RED: 'red-cell',
  GREEN: 'green-cell',
  GREEN_KPI: 'green-kpi',
  YELLOW: 'yellow-cell',
  FONT_WIDTH_RATIO: 'font-width-ratio',
  FONT_WIDTH_FINANCIAL: 'font-width-financial',
}

export const CELLS = { TYPES: CELLS_TYPES }

const TAB_KEYS = {
  TAB_KEY_EVOLUTION: 'evolution',
  TAB_KEY_COMPARATION: 'comparison',
}

export { TAB_KEYS }

export const STATUS_OPTIONS = [
  { id: 'active', name: 'STATE_TAG_ACTIVE' },
  { id: 'inactive', name: 'STATE_TAG_INACTIVE' },
]
