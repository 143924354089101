import React, { useState } from 'react'
import { Table } from 'antd'
import { InputCell, DeleteAndUpdate } from 'modules/core/components'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { TableActions } from './components'
import { onDeleteBudgetType, onSave } from './utils'
import configuration from 'modules/configuration'

const BudgetTypeTable = ({
  dataSource,
  count,
  tableLoading,
  onChange,
  pagination,
  onRefetchBudgetTypeList,
  editBudgetType,
  deleteBudgetType,
}) => {
  const [editRow, setEditRow] = useState(null)
  const [dataCellEdit, setDataCellEdit] = useState({})
  const [isEditing, setIsEditing] = useState(false)
  const [deleteBudgetTypeId, setDeleteBudgetTypeId] = useState(null)
  const { t } = useTranslation()

  const onCellEdit = (name, value) => setDataCellEdit({ ...dataCellEdit, [`${name}`]: value })

  const onCancel = () => setEditRow(null)

  const onConfirm = () => {
    setEditRow(null)
    setDataCellEdit({})
    setIsEditing(false)
  }

  const onClose = () => {
    setIsEditing(false)
    setDeleteBudgetTypeId(null)
  }

  const columns = [
    {
      title: t('FIELD_NAME'),
      dataIndex: 'name',
      width: 500,
      render: (name, row) => {
        if (editRow && editRow === row.id) {
          return <InputCell initialValue={name} onCellUpdate={onCellEdit} name="name" />
        }
        return name
      },
    },
    {
      title: t('FIELD_CODE'),
      dataIndex: 'code',
    },
    {
      title: t('FIELD_ACTIONS'),
      dataIndex: 'id',
      align: 'right',
      render: (id) => {
        return id !== editRow ? (
          <TableActions
            onConfirmDelete={() =>
              onDeleteBudgetType({
                setIsLoading: setIsEditing,
                deleteBudgetType: () => deleteBudgetType({ id: parseInt(id) }),
                onRefetch: onRefetchBudgetTypeList,
                onClose,
              })
            }
            onCancelDelete={() => setDeleteBudgetTypeId(null)}
            visible={deleteBudgetTypeId === id}
            onClickEdit={() => setEditRow(id)}
            onClickDelete={() => setDeleteBudgetTypeId(id)}
          />
        ) : (
          <DeleteAndUpdate
            onCancel={onCancel}
            onConfirm={onConfirm}
            onSave={() =>
              onSave({
                setIsLoading: setIsEditing,
                editBudgetType: () => editBudgetType({ id: parseInt(id), ...dataCellEdit }),
                onRefetch: onRefetchBudgetTypeList,
                onClose: onConfirm,
              })
            }
            dataCellEdit={dataCellEdit}
            loading={isEditing}
          />
        )
      },
    },
  ]

  return (
    <Table
      rowKey="id"
      bordered
      size="small"
      loading={tableLoading}
      onChange={onChange}
      columns={columns}
      dataSource={dataSource}
      pagination={{
        pageSize: pagination.page_size,
        size: 'small',
        total: count,
        current: pagination.page,
        pageSizeOptions: ['10', '20', '30'],
        showSizeChanger: true,
      }}
    />
  )
}

const mapDispatchToProps = {
  editBudgetType: configuration.actions.editBudgetType,
  deleteBudgetType: configuration.actions.deleteBudgetType,
}

export default connect(null, mapDispatchToProps)(BudgetTypeTable)
