import React, { useEffect, useRef, useState } from 'react'
import { Dropdown, Menu, Spin } from 'antd'
import { CommentOutlined } from '@ant-design/icons'
import { aggregators2 } from '../../../../../components/PivotTable/Utilities'
import { GENERAL } from 'modules/core/constants'
import { useTranslation } from 'react-i18next'
import PivotTableUX from '../../../../../components/PivotTable/PivotTableUX'
import TableRenderers from '../../../../../components/PivotTable/Renderers/Percentage/TableRenderers'
import _ from 'lodash'

const PercentageTable = (props) => {
  const {
    loading,
    settings,
    setSettings,
    dataPercentageTable,
    onCellEdit,
    hasComment,
    setHasComment,
    setViewComment,
    hasCellEdit,
    ruleTypeValue,
  } = props
  const { dataSource, fields } = dataPercentageTable
  const [dataCell, setDataCell] = useState({})
  const { t } = useTranslation()
  const inputValueRef = useRef(null)

  useEffect(() => {
    setSettings((prevState) => ({ ...prevState, data: dataSource }))
  }, [dataPercentageTable, dataSource, setSettings])

  const setHidden = () => {
    const rows = fields.rows ? Object.values(fields.rows) : []
    const values = fields.values ? Object.values(fields.values) : []
    const data = dataSource[0] ? Object.values(dataSource[0]) : []
    const allData = [...rows, ...values]
    const extraHidden = _.compact(data.map((el) => !allData.includes(el) && el))

    return [...allData, ...extraHidden].filter((el) => el !== ruleTypeValue)
  }

  return (
    <Spin spinning={loading}>
      <Dropdown
        trigger={['contextMenu']}
        overlay={
          <Menu>
            {GENERAL.OPTION_COMMENT.map((el) => (
              <Menu.Item
                key={el.key}
                icon={<CommentOutlined />}
                onClick={() => {
                  setViewComment(true)
                  setHasComment({ type: 'table', data: !hasComment.table })
                }}
              >
                {t(el.label)}
              </Menu.Item>
            ))}
          </Menu>
        }
      >
        <div style={{ width: '100%' }}>
          <PivotTableUX
            renderers={Object.assign({}, TableRenderers)}
            aggregators={aggregators2}
            data={dataSource}
            onChange={(s) => setSettings(s)}
            vals={[ruleTypeValue]}
            cols={fields.columns}
            rows={fields.rows_default}
            aggregatorName="Average"
            hiddenFromDragDrop={fields.values}
            hiddenFromAggregators={setHidden()}
            tableFilter={{}}
            tableOptions={{
              clickCallback: (info, value, record, pivotData) => {
                if (hasCellEdit) {
                  if (_.isEmpty(dataCell) && info.target.className !== 'pvtTotal') {
                    setDataCell(record)

                    const originElement = info.target.innerHTML
                    const newElement = info.target

                    newElement.innerHTML = `<input type="number" step="0.00" value="${
                      value ?? 0
                    }" >`
                    const inputElement = document.querySelector('input[type="number"]')

                    inputElement.focus()

                    const handleInput = (e) => {
                      inputValueRef.current = e.target.value
                    }

                    const handleBlur = () => {
                      newElement.innerHTML = originElement
                      inputElement.removeEventListener('input', handleInput)
                      inputElement.removeEventListener('blur', handleBlur)
                      inputElement.removeEventListener('keypress', handleKeyPress)
                      setDataCell({})
                      inputValueRef.current = null
                    }

                    const handleKeyPress = (e) => {
                      if (e.key === 'Enter') {
                        onCellEdit(
                          parseFloat(inputValueRef.current),
                          record,
                          pivotData.props.vals[0],
                          'base',
                        )
                        inputElement.removeEventListener('input', handleInput)
                        inputElement.removeEventListener('blur', handleBlur)
                        inputElement.removeEventListener('keypress', handleKeyPress)
                        setDataCell({})
                        newElement.innerHTML = inputValueRef.current
                      }
                    }

                    inputElement.addEventListener('input', handleInput)
                    inputElement.addEventListener('blur', handleBlur)
                    inputElement.addEventListener('keypress', handleKeyPress)
                  }
                }
              },
            }}
            {...settings}
          />
        </div>
      </Dropdown>
    </Spin>
  )
}

export default PercentageTable
