import { useFeatureIsOn } from '@growthbook/growthbook-react'
import React, { useState } from 'react'
import { Modal, Row, Col, Form, Input } from 'antd'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import configuration from 'modules/configuration'
import { onCreateBudgetType } from './utils'
import { ROLES } from 'modules/core/constants'
import { isUserAllowed } from 'modules/core/utils'
import { Button } from 'modules/core/components'

const { SETTINGS__BUDGET_TYPE__ABM } = ROLES

const LIMIT_BUDGET_TYPE_CREATE = 6

const CreateBudgetTypeModal = ({ budgetTypeList, onRefetchBudgetTypeList, createBudgetType }) => {
  const [showCraeteModal, setShowCraeteModal] = useState(false)
  const [isCreating, setIsCreating] = useState(false)
  const [form] = Form.useForm()
  const { t } = useTranslation()

  const canCreate = isUserAllowed(SETTINGS__BUDGET_TYPE__ABM)

  const onClose = () => {
    form.resetFields()
    setIsCreating(false)
    setShowCraeteModal(false)
  }

  const extraConcept = useFeatureIsOn('feature-extra-concept')

  const renderButton = () => (
    <Button.Primary
      title="ACTION_CREATE_NEW"
      onClick={() => setShowCraeteModal(true)}
      disabled={
        !canCreate ||
        budgetTypeList?.values.length === (extraConcept === false ? LIMIT_BUDGET_TYPE_CREATE : 7)
      }
    />
  )

  return (
    <>
      {renderButton()}
      <Modal
        title={t('CONFIG_CONCEPT_EMPTY_STATE_CREATE_ACTION')}
        visible={showCraeteModal}
        width={560}
        bodyStyle={{ overflowY: 'auto', maxHeight: 400 }}
        centered
        okText={t('ACTION_CREATE')}
        cancelText={t('ACTION_CANCEL')}
        onOk={() =>
          onCreateBudgetType({
            form,
            setIsLoading: setIsCreating,
            createBudgetType,
            onRefetch: onRefetchBudgetTypeList,
            onClose,
          })
        }
        onCancel={onClose}
        okButtonProps={{ loading: isCreating }}
        cancelButtonProps={{ loading: isCreating }}
      >
        <Form form={form} layout="vertical">
          <Row gutter={24} type="flex" justify="end">
            <Col span={12}>
              <Form.Item
                name="name"
                label={t('FIELD_NAME')}
                rules={[
                  {
                    required: true,
                    message: t('REQUIRED_FIELD'),
                  },
                ]}
              >
                <Input placeholder={t('ENTER_NAME_PLACEHOLDER')} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="code"
                label={t('FIELD_CODE')}
                rules={[
                  {
                    required: true,
                    message: t('REQUIRED_FIELD'),
                  },
                ]}
              >
                <Input placeholder={t('ENTER_CODE_PLACEHOLDER')} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  )
}

const mapDispatchToProps = {
  createBudgetType: configuration.actions.createBudgetType,
}

export default connect(null, mapDispatchToProps)(CreateBudgetTypeModal)
