import React, { useState, useEffect } from 'react'
import numeral from 'numeral'
import { Input } from 'antd'
import { CellStyle } from 'modules/core/components'
import { CELLS, ROWS } from 'modules/core/constants'

import './NumberCell.scss'

const numeralFormat = ({ value, format }) => numeral(value).format(format)

const NumberCell = ({
  disabled = false,
  // hasVarsOrRule,
  initialValue,
  type = '',
  onCellUpdate,
  showNegativeInRed,
  format = '0,0',
  undoAction: { canUndo = false, previousValue = null, setPreviousValue, setCanUndo },
}) => {
  const [cellValue, setCellValue] = useState(numeralFormat({ value: initialValue, format }))

  const onLocalUpdateCellValue = (value) => setCellValue(numeralFormat({ value, format }))
  const onUpdatePreviousValue = (value) => setPreviousValue(numeralFormat({ value, format }))

  useEffect(() => {
    onLocalUpdateCellValue(cellValue)
    if (previousValue) {
      onUpdatePreviousValue(previousValue)
    }
    if (cellValue !== initialValue) {
      onLocalUpdateCellValue(initialValue)
    }
    // eslint-disable-next-line
  }, [format, initialValue])

  useEffect(() => {
    if (canUndo) {
      onCellUpdate(numeral(previousValue).value())
      setCellValue(previousValue)
      setPreviousValue(null)
      setCanUndo(false)
    }
  }, [canUndo, previousValue, onCellUpdate, setPreviousValue, setCanUndo])

  const renderClassName = () => {
    if (type === ROWS.FINANCIAL) {
      return CELLS.TYPES.FONT_WIDTH_FINANCIAL
    }
    if (numeral(cellValue).value() < 0 && showNegativeInRed) {
      return CELLS.TYPES.RED
    }
    return ''
  }

  const updateCellValue = () => {
    // Check if the user put only - or empty value
    if (cellValue !== '-' && cellValue !== '' && cellValue !== null) {
      // If value don't change, don't update
      if (numeral(initialValue).value() !== numeral(cellValue).value()) {
        onLocalUpdateCellValue(cellValue)
        onUpdatePreviousValue(initialValue)
        if (cellValue.includes('%')) {
          onCellUpdate(cellValue)
        } else {
          onCellUpdate(numeral(cellValue).value())
        }
      } else {
        onLocalUpdateCellValue(initialValue)
      }
    } else {
      onLocalUpdateCellValue(initialValue)
    }
  }

  const handleOnChange = (event) => {
    const { value } = event.target
    if (
      (!Number.isNaN(value) && numeral(value).value() !== null) ||
      value === '' ||
      value === '-'
    ) {
      setCellValue(value)
    }
  }

  const renderDisabledCell = () => (
    <CellStyle.Number
      value={initialValue}
      format={format}
      showNegativeInRed={showNegativeInRed}
      type={type}
      // hasVarsOrRule={hasVarsOrRule}
    />
  )

  const renderCell = () => (
    <Input
      style={previousValue ? { fontWeight: 600, color: 'black' } : {}}
      className={`cell-input-number ${renderClassName()}`}
      value={cellValue}
      onPressEnter={updateCellValue}
      // onBlur={updateCellValue}
      onChange={handleOnChange}
      onFocus={(e) => e.target.select()}
    />
  )

  return !disabled ? renderCell() : renderDisabledCell()
}

export default NumberCell
