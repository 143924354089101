import Button from 'modules/core/components/Button/Button'
import ButtonActionBar from 'modules/core/components/ButtonActionBar/ButtonActionBar'

const ButtonApply = ({ onClick, loading }) => {
  return (
    <ButtonActionBar>
      <Button.Primary title="ACTION_APPLY" onClick={onClick} loading={loading} />
    </ButtonActionBar>
  )
}

export default ButtonApply
