import Immutable from 'seamless-immutable'
import * as types from './constants'

const initialState = Immutable({
  questionList: {
    count: 0,
    values: [],
  },
  valuesList: {
    unitOfTime: [],
    unitOfAmount: [],
  },
})

/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_QUESTION_LIST_SUCCESS:
      return state.merge({
        questionList: {
          count: action.payload.data.count,
          values: action.payload.data.results,
        },
      })
    case types.FETCH_VALUES_LIST_SUCCESS:
      return state.merge({
        valuesList: {
          unitOfTime: action.payload.data['unit_of_time'],
          unitOfAmount: action.payload.data['unit_of_amount'],
        },
      })
    default:
      return state
  }
}
