import React from 'react'
import { Button, Input, Form } from 'antd'
import { useTranslation } from 'react-i18next'
import { renderPasswordFormItem } from 'modules/core/utils'

import './ConfirmPasswordForm.scss'

const FormItem = Form.Item

const ConfirmPasswordForm = ({ form, loading, onFinish }) => {
  const { t } = useTranslation()

  return (
    <Form
      form={form}
      layout="vertical"
      className="password-form"
      onFinish={onFinish}
      hideRequiredMark={true}
    >
      <div className="password-form-inputs">
        {/* <FormItem
          label={t('FIELD_NAME')}
          name="first_name"
          rules={[
            {
              required: true,
              message: t('LOGIN_FORM_INPUT_EMAIL_CAPTION_NAME'),
            },
          ]}
        >
          <Input placeholder={t('FIELD_NAME')} />
        </FormItem>
        <FormItem
          label={t('FIELD_SURNAME')}
          name="last_name"
          rules={[
            {
              required: true,
              message: t('LOGIN_FORM_INPUT_EMAIL_CAPTION_SURNAME'),
            },
          ]}
        >
          <Input placeholder={t('FIELD_SURNAME')} />
        </FormItem> */}
        <FormItem
          label={t('LOGIN_FORM_CONFIRM_PASSWORD_LABEL_CURRENT')}
          name="old_password"
          rules={[
            {
              required: true,
              message: t('LOGIN_FORM_INPUT_A_PASSWORD_CAPTION_EMPTY'),
            },
            { min: 8, message: t('LOGIN_FORM_CONFIRM_PASSWORD_CHARACTERS') },
          ]}
        >
          <Input.Password placeholder={t('LOGIN_FORM_CONFIRM_PASSWORD_LABEL_CURRENT')} />
        </FormItem>
        {renderPasswordFormItem({})}
        <FormItem>
          <Button type="primary" htmlType="submit" loading={loading} size="large" block>
            {t('ACTION_MODIFY')}
          </Button>
        </FormItem>
      </div>
    </Form>
  )
}

export default ConfirmPasswordForm
