import React, { useEffect, useState } from 'react'
import { withRouter, NavLink } from 'react-router-dom'
import { connect } from 'react-redux'
import { Layout, Space, Spin } from 'antd'
import { isUserAllowed, getOperationsByKeys } from 'modules/core/utils'
import { useTranslation } from 'react-i18next'
import { AllComments, ButtonHelp } from './components'
import periodsMenu from './periodsMenu'
import planning from 'modules/planning'
import login from 'modules/login'
import configuration from 'modules/configuration'

import './SiderLayout.scss'

const { Content, Sider } = Layout

const SiderLayout = ({
  extraKey = null,
  match: {
    params: { periodId },
    path,
  },
  header = null,
  children,
  className,
  fetchPeriodList,
  periodList,
  loggedUser,
  showAllComments,
  fetchCommentsList,
  commentsList,
}) => {
  const [open, setOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState([])
  const { t } = useTranslation()

  const key = window.location.pathname.replaceAll('/', '-')

  useEffect(() => {
    if (!periodId) {
      fetchPeriodList()
    }
  }, [fetchPeriodList, periodId])

  useEffect(() => {
    if (showAllComments) {
      setIsLoading(true)
      fetchCommentsList({ module: `${key}${extraKey || ''}` }).then((response) => {
        setIsLoading(false)
      })
    }
  }, [fetchCommentsList, key, showAllComments, extraKey])

  useEffect(() => {
    setData([...commentsList])
  }, [commentsList])

  const EXCEPTION_PATHS = ['/configuracion', '/analisis', '/periodos']

  const exception_path = EXCEPTION_PATHS.find((el) => (path.includes(el) ? el : null))

  const renderButtonHelp = () => (
    <ButtonHelp open={open} onOpenChange={() => setOpen(!open)} exception_path={exception_path} />
  )

  return (
    <Layout className={`sider-layout ${className ? className : ''}`}>
      <Sider
        className="sider-layout-menu"
        collapsible
        collapsed={true}
        trigger={null}
        collapsedWidth={56}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100%',
            paddingBottom: 10,
          }}
        >
          <Space className="sub-layout-actions" align="center" direction="vertical" size="large">
            {periodsMenu(t)
              .filter((navItem) => isUserAllowed(getOperationsByKeys(navItem.allowedFor)))
              .map((item, index) => {
                const { title, url, exact, icon } = item
                return (
                  <NavLink
                    key={index}
                    className="menu-icon"
                    to={url.replace(
                      ':periodId',
                      periodId ? periodId : periodList.length > 0 && periodList[0].id,
                    )}
                    exact={exact}
                    activeClassName="selected"
                    title={title}
                  >
                    {icon}
                  </NavLink>
                )
              })}
          </Space>
          <div>{renderButtonHelp()}</div>
        </div>
      </Sider>
      <Layout>
        {header}
        <Content className={`sider-layout-content ${className ? className : ''}`}>
          {children}
        </Content>
        {showAllComments && (
          <Sider width={330} style={{ padding: 10 }}>
            <Spin spinning={isLoading}>
              <AllComments
                userDetail={loggedUser}
                comments={data}
                setData={setData}
                onRefetchComments={() => fetchCommentsList({ module: `${key}${extraKey || ''}` })}
              />
            </Spin>
          </Sider>
        )}
      </Layout>
    </Layout>
  )
}

const mapStateToProps = (state) => ({
  periodList: planning.selectors.getPeriodList(state),
  loggedUser: login.selectors.getWhoAmI(state),
  showAllComments: configuration.selectors.getShowAllComments(state),
  commentsList: configuration.selectors.getCommentsList(state),
})

const mapDispatchToProps = {
  fetchPeriodList: planning.actions.fetchPeriodList,
  fetchCommentsList: configuration.actions.fetchCommentsList,
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SiderLayout))
