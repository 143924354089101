import React from 'react'
import { Card, Col, Progress, Row, Space } from 'antd'
import { KPIValue, Typography } from 'modules/core/components'
import { PROJECTION } from 'modules/core/constants'
import { useTranslation } from 'react-i18next'
import { renderPercentage } from '../../../../../../utils'
import numeral from 'numeral'

const PROGRESS_COLOR_STROKE = {
  1: '#00aa63',
  2: '#ffcd00',
  3: '#fd4a5c',
  4: '#e499b0',
  5: '#a73ba1',
  6: '#df9100',
}

const DashboardKpiCard = ({ title, id, percentage, value, prefix, name, subKpis }) => {
  const { t } = useTranslation()

  return (
    <Card title={title} className="dashboard-detail-kpi-card" size="small" key={id} data-type={id}>
      <Row gutter={[8, 0]}>
        <Col span={12} style={!percentage ? { marginBottom: 88 } : {}}>
          <Row gutter={[8, 8]}>
            <Col span={24}>
              <KPIValue
                size="medium"
                fullValue={value && numeral(value).format('0,0.00')}
                value={value ? numeral(value).format('0[.]0a') : '-'}
                prefix={prefix}
              />
              <Typography.Body level={3} type="secondary">
                {t(PROJECTION.NAME_PROJECTIONS[name])}
              </Typography.Body>
            </Col>
            <Col span={24}>
              {percentage && (
                <Space direction="vertical">
                  <Progress
                    format={(data) =>
                      data === 100 ? <span style={{ color: 'grey' }}>100%</span> : `${data}%`
                    }
                    strokeColor={PROGRESS_COLOR_STROKE[id]}
                    strokeLinecap="butt"
                    strokeWidth={10}
                    type="circle"
                    percent={renderPercentage(percentage)}
                    width={60}
                  />
                  <Typography.Body level={3} type="secondary">
                    {percentage.name}
                  </Typography.Body>
                </Space>
              )}
            </Col>
          </Row>
        </Col>
        <Col span={12}>
          {subKpis &&
            subKpis.map((kpi) => (
              <Row gutter={[8, 0]} key={kpi.id}>
                <Col span={16}>
                  <KPIValue
                    prefix={kpi.prefix}
                    fullValue={kpi.value && numeral(kpi.value).format('0,0.00')}
                    value={kpi.value ? numeral(kpi.value).format('0[.]0a') : '-'}
                  />
                  <br />
                  <Typography.Body level={3} type="secondary">
                    {t(PROJECTION.NAME_PROJECTIONS[kpi.name])}
                  </Typography.Body>
                </Col>
                <Col span={8}>
                  <KPIValue
                    isVariation={true}
                    value={kpi.variation ? kpi.variation : '-'}
                    type="secondary"
                  />
                </Col>
              </Row>
            ))}
        </Col>
      </Row>
    </Card>
  )
}

export default DashboardKpiCard
