import { useFeatureValue } from '@growthbook/growthbook-react'
import React from 'react'
import {
  ButtonActionBar,
  CreateGlobalProjectionModal,
  CustomBottomAction,
  DropdownMenu,
} from 'modules/core/components'
import { useTranslation } from 'react-i18next'
import { Input, Space } from 'antd'
import { SolutionOutlined } from '@ant-design/icons'

const { Search } = Input

const UserActionsGlobal = ({
  globalBudgetApproved,
  periodId,
  onFetchGlobalBudgetList,
  title,
  globalText,
  role,
  createGlobalProjection,
  type,
  fetchProjectionInstanceList,
  projectionInstanceList,
  setSearchValue,
  module_name,
  onClickActivity,
}) => {
  const { t } = useTranslation()

  return (
    <ButtonActionBar>
      <Space direction="horizontal">
        <Search placeholder={t('ACTION_SEARCH')} allowClear={true} onSearch={setSearchValue} />
        <CreateGlobalProjectionModal
          globalBudgetApproved={globalBudgetApproved}
          periodId={periodId}
          title={title}
          globalText={globalText}
          role={role}
          onFetchGlobalBudgetList={onFetchGlobalBudgetList}
          text={t('LABEL_NEW').toLowerCase()}
          createGlobalProjection={createGlobalProjection}
          type={type}
          fetchProjectionInstanceList={fetchProjectionInstanceList}
          projectionInstanceList={projectionInstanceList}
          module={module_name}
        />
        <CustomBottomAction extraButtons={{ module_name: module_name }} />
        <DropdownMenu
          title={t('ACTION_MORE')}
          menu={[
            {
              title: t('ACTION_ACTIVITIES'),
              icon: <SolutionOutlined />,
              onClick: onClickActivity,
            },
          ]}
        />
      </Space>
    </ButtonActionBar>
  )
}

export default UserActionsGlobal
