import React from 'react'
import { useBottomScrollListener } from 'react-bottom-scroll-listener'
import AnimateGroup from '../../../AnimateGroup/AnimateGroup'

const getEmptyContainer = (ListEmptyComponent) => {
  if (ListEmptyComponent)
    return React.isValidElement(ListEmptyComponent) ? ListEmptyComponent : <ListEmptyComponent />
  return null
}

const getFooterContainer = (ListFooterComponent) => {
  if (ListFooterComponent)
    return React.isValidElement(ListFooterComponent) ? ListFooterComponent : <ListFooterComponent />
  return null
}

const ListView = ({
  renderItem,
  onEndReached,
  data,
  ListFooterComponent,
  ListEmptyComponent,
  interval,
  type,
  duration,
  className,
  delay,
}) => {
  if (!onEndReached) {
    onEndReached = () => {}
  }

  useBottomScrollListener(onEndReached, 200)
  return (
    <AnimateGroup
      className={className}
      animateStyle={{ flexDirection: 'column', flexWrap: 'no-wrap' }}
      interval={interval}
      type={type}
      delay={delay}
      duration={duration}
    >
      {data.length > 0
        ? data.map((item, index) => renderItem(item, index))
        : getEmptyContainer(ListEmptyComponent)}

      {getFooterContainer(ListFooterComponent)}
    </AnimateGroup>
  )
}

export default ListView
