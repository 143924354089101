import _ from 'lodash'

const SALES = 'SALES'
const SALES_LABEL = 'Ventas'
const SALES_ID = 1

const EXPENSES = 'EXPENSES'
const EXPENSES_LABEL = 'Gastos'
const EXPENSES_ID = 2

const COSTS = 'COSTS'
const COST_LABEL = 'Costos directos'
const COSTS_ID = 3

const HUMAN_RESOURCES = 'HUMAN_RESOURCES'
const HUMAN_RESOURCES_LABEL = 'Recursos humanos'
const HUMAN_RESOURCES_ID = 4

const OTHER_5_ID = 5

const OTHER_6_ID = 6

const ECONOMIC_KEY = 'economic'
const ECONOMIC_ID = 'economico'

const FINANCIAL_KEY = 'financial'
const FINANCIAL_ID = 'financiero'

const TYPE_LIST = [
  {
    id: ECONOMIC_KEY,
    name: 'LABEL_ECONOMIC',
  },
  {
    id: FINANCIAL_KEY,
    name: 'LABEL_FINANCIAL',
  },
]

const KEYS = {
  [SALES]: SALES_LABEL,
  [EXPENSES]: EXPENSES_LABEL,
  [COSTS]: COST_LABEL,
  [HUMAN_RESOURCES]: HUMAN_RESOURCES_LABEL,
}

const KEYS_REVERSE = _.invert(KEYS)

const TYPE = {
  ECONOMIC_KEY: ECONOMIC_KEY,
  ECONOMIC_ID: ECONOMIC_ID,
  FINANCIAL_KEY: FINANCIAL_KEY,
  FINANCIAL_ID: FINANCIAL_ID,
}

const IDS = {
  SALES_ID: SALES_ID,
  EXPENSES_ID: EXPENSES_ID,
  HUMAN_RESOURCES_ID: HUMAN_RESOURCES_ID,
  COSTS_ID: COSTS_ID,
  OTHER_5_ID: OTHER_5_ID,
  OTHER_6_ID: OTHER_6_ID,
}

const CONCEPTS = { KEYS, KEYS_REVERSE, TYPE, TYPE_LIST, IDS }

export default CONCEPTS
