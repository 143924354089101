import React, { useEffect, useState } from 'react'
import { Col, Row } from 'antd'
import { ButtonActionBar, Navigator } from 'modules/core/components'
import { usePagination } from 'modules/core/customHooks'
import { AdminLayout } from 'modules/core/layouts'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { CreateQuestionModal } from '../../components'
import { QuestionTable } from './components'
import question from 'modules/question'

const QuestionList = ({ fetchQuestionList, questionsList }) => {
  const [isTableLoading, setIsTableLoading] = useState(false)
  const [tablePagination, setTablePagination] = usePagination()
  const { t } = useTranslation()

  useEffect(() => {
    setIsTableLoading(true)
    fetchQuestionList({ ...tablePagination }).then(() => setIsTableLoading(false))
  }, [fetchQuestionList, tablePagination])

  const renderHeader = () => (
    <Navigator
      floated={false}
      size="big"
      items={[
        {
          name: t('LABEL_QUESTION'),
          url: `/preguntas`,
        },
      ]}
    />
  )

  const renderUserActions = () => (
    <ButtonActionBar>
      <CreateQuestionModal
        onRefetchQuestionList={() => fetchQuestionList({ ...tablePagination })}
      />
    </ButtonActionBar>
  )

  const renderTable = () => (
    <QuestionTable
      dataSource={questionsList?.values}
      count={questionsList?.count}
      tableLoading={isTableLoading}
      onChange={setTablePagination}
      pagination={tablePagination}
      onRefetchQuestionList={() => fetchQuestionList({ ...tablePagination })}
    />
  )

  return (
    <AdminLayout>
      <Row gutter={[8, 8]}>
        <Col span={24}>{renderHeader()}</Col>
        <Col span={24}>{renderUserActions()}</Col>
        <Col span={24}>{renderTable()}</Col>
      </Row>
    </AdminLayout>
  )
}

const mapStateToProps = (state) => ({
  questionsList: question.selectors.getQuestionsList(state),
})

const mapDispatchToProps = {
  fetchQuestionList: question.actions.fetchQuestionList,
}

export default connect(mapStateToProps, mapDispatchToProps)(QuestionList)
