import React, { useEffect, useRef, useState } from 'react'
import { MessageOutlined, RobotOutlined } from '@ant-design/icons'
import { post } from 'modules/chatbot/client'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { ChartBar, ChartLine, ChartPie } from '../../../Chart'
import { AddMessage, ListMessage } from './components'
import login from 'modules/login'
import moment from 'moment'

import './MessagesScreen.scss'

const MessagesScreen = ({ suggestedQuestion, loggedUser }) => {
  const { t } = useTranslation()
  const user = { id: 'xxxxxx-1-xxxxxx', name: 'Plika Bot', email: 'info@plikabot.com' }
  const [userMessages, setUserMessages] = useState({
    channelId: 2001,
    messageData: [
      {
        message: t('CHATBOT_MESSAGE_WELCOME'),
        message_type: 1,
        sender: user.id,
        time: moment().format('llll'),
      },
      {
        message: t('CHATBOT_MESSAGE_HELP'),
        message_type: 1,
        sender: user.id,
        time: moment().format('llll'),
      },
    ],
  })
  const [loading, setLoading] = useState(false)

  let _scrollBarRef = useRef(null)
  useEffect(() => {
    if (userMessages?.messageData?.length > 0) {
      if (_scrollBarRef?.current) {
        const scrollEl = _scrollBarRef.current.getScrollElement()
        scrollEl.scrollTop = scrollEl.scrollHeight
      }
    }
  }, [userMessages, _scrollBarRef])

  const onSend = (message, currentQuestion) => {
    const messageDataList = userMessages.messageData || {}
    const dataSend = {
      message,
      message_type: 1,
      sender: loggedUser.userId,
      time: moment().format('llll'),
    }
    let messageData = [...messageDataList, dataSend]
    setUserMessages({ ...userMessages, messageData: messageData })

    setLoading(true)
    post(`/search/${loggedUser?.tokenEncrypt}`, { input: message })
      .then((res) => {
        let chart = null
        if (currentQuestion.chart === 'PIE' && res?.sql_result) {
          chart = <ChartPie data={res?.sql_result} />
        } else if (currentQuestion.chart === 'BAR' && res?.sql_result) {
          chart = <ChartBar data={res?.sql_result} />
        } else if (currentQuestion.chart === 'LINE' && res?.sql_result) {
          chart = <ChartLine data={res?.sql_result} />
        }

        const dataReceiver = {
          message: res.answer,
          message_type: 1,
          sender: user.id,
          time: moment().format('llll'),
          chart: chart,
        }

        setUserMessages({ ...userMessages, messageData: [...messageData, dataReceiver] })
        setLoading(false)
      })
      .catch((error) => {
        setLoading(false)
        console.log(error)
      })
  }

  return (
    <div className="message-screen">
      <div className="apps-header">
        <div className="chat-header">
          <RobotOutlined />
          <span style={{ paddingLeft: '5px' }}>PLIKA CHATBOT</span>
        </div>
      </div>

      {userMessages && user ? (
        <ListMessage
          userMessages={userMessages}
          authUser={loggedUser}
          selectedUser={user}
          loading={loading}
        />
      ) : (
        <div className="scroll-chat-no-main">
          <span className="noMessage no-user-screen">
            <MessageOutlined className="message-icon" />
            <p className="mb-0">{t('LABEL_CHAT_BOT_START_CHAT')}</p>
          </span>
        </div>
      )}
      <div className={'apps-footer message-apps-footer'}>
        <AddMessage currentMessage={suggestedQuestion} onSendMessage={onSend} />
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  loggedUser: login.selectors.getWhoAmI(state),
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(MessagesScreen)
