import React, { useState } from 'react'
import { Row, Col, Form, Button } from 'antd'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import { onReset } from './utils'
import {
  renderLoginAndResetFormTitle,
  renderLoginWelcome,
  renderPasswordFormItem,
} from 'modules/core/utils'
import login from 'modules/login'

import './ResetPasswordForm.scss'
import { tokenutils } from 'modules/login/actions'

const FormItem = Form.Item

const ResetPasswordForm = ({ resetPassword, selfResetPassword }) => {
  const [isReseting, setIsReseting] = useState(false)
  const [form] = Form.useForm()
  const { t } = useTranslation()
  let history = useHistory()

  const urlParams = new URLSearchParams(window.location.search)
  const uid = urlParams.get('uid')
  const token = urlParams.get('token')
  const user_id = urlParams.get('user_id')
  const token_id = urlParams.get('token_id')

  const handleReset = (values) => {
    const data = {
      uid,
      new_password: values.password,
      re_new_password: values.password_confirm,
      token,
    }

    if (user_id && token_id) {
      tokenutils.saveTokens({ access: token_id, refresh: token_id })
      return selfResetPassword(user_id, { password: values.password })
    } else {
      return resetPassword(data)
    }
  }

  return (
    <div className="plika-login-container">
      <Row>
        <Col span={10} offset={1} className="brand-hero">
          {renderLoginWelcome()}
        </Col>
        <Col span={10} offset={3} className="login-form-container ">
          <div className="login-sector">
            {renderLoginAndResetFormTitle({
              title: 'LOGIN_FORM_RESET_PASSWORD_TITLE',
              level: 6,
              align: 'center',
            })}
            <Form
              form={form}
              layout="vertical"
              className="password-reset-form"
              hideRequiredMark={true}
            >
              <div className="password-reset-form-inputs">
                {renderPasswordFormItem({ size: 'large' })}
                <FormItem>
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={isReseting}
                    size="large"
                    block
                    onClick={() =>
                      onReset({
                        form,
                        setIsLoading: setIsReseting,
                        resetPassword: (values) => handleReset(values),
                        history,
                        onClose: () => setIsReseting(false),
                      })
                    }
                  >
                    {t('ACTION_CONFIRM')}
                  </Button>
                </FormItem>
              </div>
            </Form>
          </div>
        </Col>
      </Row>
    </div>
  )
}

const mapDispatchToProps = {
  resetPassword: login.actions.resetPassword,
  selfResetPassword: login.actions.selfResetPassword,
}

export default connect(null, mapDispatchToProps)(ResetPasswordForm)
