import React from 'react'
import { Tooltip } from 'antd'
import { Button, ButtonActionBar } from 'modules/core/components'
import { useTranslation } from 'react-i18next'
import { RightCircleOutlined } from '@ant-design/icons'

const FooterActions = ({ disabled, onClick }) => {
  const { t } = useTranslation()
  return (
    <ButtonActionBar position="start">
      <Tooltip title={disabled ? t('PERIOD_CONFIG_TOOL_TOOLTIP_TITLE') : ''}>
        <Button.LinkWhitRightIcon
          onClick={onClick}
          disabled={disabled}
          icon={<RightCircleOutlined />}
          title="ACTION_ENTER"
        />
      </Tooltip>
    </ButtonActionBar>
  )
}

export default FooterActions
