import imgEbook from 'assets/images/compositions/ebook.svg'
import imgArticle from 'assets/images/compositions/articulo.svg'
import imgMetrics from 'assets/images/compositions/metricas.svg'
import imgNewFunctions from 'assets/images/compositions/nuevas_funciones.svg'

const COLUMN_TYPE_LIST = [
  {
    id: 'dimension-name',
    name: 'HOME_INITIAL_STEPS_SELECT_DATA_TRANSACTION_FILE_TYPE_COLUMN_DIMENSION_NAME',
  },
  {
    id: 'dimension-code',
    name: 'HOME_INITIAL_STEPS_SELECT_DATA_TRANSACTION_FILE_TYPE_COLUMN_DIMENSION_CODE',
  },
  {
    id: 'transaction-date',
    name: 'HOME_INITIAL_STEPS_SELECT_DATA_TRANSACTION_FILE_TYPE_COLUMN_DIMENSION_DATE',
  },
  {
    id: 'transaction-amount',
    name: 'HOME_INITIAL_STEPS_SELECT_DATA_TRANSACTION_FILE_TYPE_COLUMN_TRANSACTION_AMOUNT',
  },
  {
    id: 'transaction-price',
    name: 'HOME_INITIAL_STEPS_SELECT_DATA_TRANSACTION_FILE_TYPE_COLUMN_TRANSACTION_PRICE',
  },
  {
    id: 'transaction-quantity',
    name: 'HOME_INITIAL_STEPS_SELECT_DATA_TRANSACTION_FILE_TYPE_COLUMN_TRANSACTION_QUANTITY',
  },
  {
    id: 'transaction-currency',
    name: 'HOME_INITIAL_STEPS_SELECT_DATA_TRANSACTION_FILE_TYPE_COLUMN_TRANSACTION_CURRENCY',
  },
]

const FORMAT_DATE = [
  {
    id: 'dd/mm/yyyy',
    name: 'dd/mm/yyyy',
  },
  {
    id: 'mm/dd/yyyy',
    name: 'mm/dd/yyyy',
  },
]

const CONTACTS = [
  {
    id: 1,
    title: 'DASHBOARD_COMERCIAL_CONTACT',
    description: [
      {
        id: 'contact1',
        name: 'Julián Bevilacqua',
        mail: 'jbevilacqua@getplika.com',
        numero: '+5492364614162',
        linkWhatsapp: 'https://wa.me/5492364614162',
      },
    ],
  },
  {
    id: 2,
    title: 'DASHBOARD_SERVICES_CONTACT',
    description: [
      {
        id: 'contact2',
        name: 'Facundo De Andres Bao',
        mail: 'fdeandres@getplika.com',
        numero: '+5492215472126',
        linkWhatsapp: 'https://wa.me/5492215472126',
      },
    ],
  },
  {
    id: 3,
    title: 'WhatsApp Plika',
    description: [
      {
        id: 'contact3',
        link: 'https://api.whatsapp.com/send/?phone=5492216387240&text&type=phone_number&app_absent=0',
      },
    ],
  },
]

const CARDS_NEWS = [
  {
    id: 1,
    name: 'Ebook',
    description: 'DASHBOARD_EBOOK_DESCRIPTION',
    link: 'https://getplika.com/wp-content/uploads/2022/06/Plika-E-book-Junio.pdf',
    img: imgEbook,
    textoBoton: 'DASHBOARD_GO_EBOOK_LINK',
  },
  {
    id: 2,
    name: 'DASHBOARD_LABEL_ARTICLES',
    description: 'DASHBOARD_ARTICLE_DESCRIPTION',
    link: 'https://getplika.com/blog/',
    img: imgArticle,
    textoBoton: 'DASHBOARD_GO_BLOG_LINK',
  },
  {
    id: 3,
    name: 'DASHBOARD_LABEL_DICTIONARY',
    description: 'DASHBOARD_METRICS_DESCRIPTION',
    link: 'https://getplika.com/diccionarios/',
    img: imgMetrics,
    textoBoton: 'DASHBOARD_GO_METRICS_LINK',
  },
  {
    id: 4,
    name: 'DASHBOARD_LABEL_NEW_FUNCTIONS',
    description: 'DASHBOARD_NEW_FUNCTIONS_DESCRIPTION',
    link: 'https://www.youtube.com/playlist?list=PLJo0bl0cWTANbbnIJAswNCWHOzZ08yGwm',
    img: imgNewFunctions,
    textoBoton: 'DASHBOARD_GO_NEW_FUNCTIONS_LINK',
  },
]

const MAPPING_DATA = {
  COLUMN_TYPE_LIST,
  FORMAT_DATE,
}

const LIST_DATA_DASHBOARD = {
  CONTACTS,
  CARDS_NEWS,
}

const HOME = { MAPPING_DATA, LIST_DATA_DASHBOARD }

export default HOME
