import { Modal, Row } from 'antd'
import configuration from 'modules/configuration'
import { connect } from 'react-redux'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { handleConfirm } from './utils'
import { handleVariable } from 'modules/core/components/RulesComponents/utils'
import { ContainerDataFormula } from 'modules/core/components/RulesComponents'

//ver con back como me envia la data pra poder reemplazar las variables
const EditFormulaModal = ({
  visible,
  onCancel,
  onConfirm,
  dataFormula,
  setDataFormula,
  variablesList,
  fetchVariablesList,
}) => {
  const { t } = useTranslation()

  useEffect(() => {
    visible && fetchVariablesList()
  }, [fetchVariablesList, visible])

  const onClose = () => {
    setDataFormula({ formula: null, variablesIds: {} })
    onCancel()
  }

  return (
    <Modal
      title={t('CONFIG_RULE_EDIT_FORMULA_MODAL_TITLE')}
      visible={visible}
      bodyStyle={{ overflowY: 'auto', maxHeight: 400 }}
      width={560}
      centered
      forceRender
      destroyOnClose={true}
      onCancel={onClose}
      onOk={() =>
        handleConfirm({
          dataFormula,
          variablesList: variablesList?.values,
          onConfirm: (data) => onConfirm(data),
          onCancel,
        })
      }
    >
      <Row gutter={[12, 18]}>
        <ContainerDataFormula
          variablesIds={dataFormula.variablesIds}
          value={dataFormula.formula}
          onChangeFormula={(value) => {
            setDataFormula({ formula: value, variablesIds: {} })
            handleVariable(value, dataFormula, setDataFormula)
          }}
          variablesList={variablesList?.values}
          onChangeVariable={(id, variable) =>
            setDataFormula({ variablesIds: { ...dataFormula.variablesIds, [variable]: id } })
          }
        />
      </Row>
    </Modal>
  )
}

const mapStateToProps = (state) => ({
  variablesList: configuration.selectors.getVariablesList(state),
  dataFormula: configuration.selectors.getDataFormula(state),
})

const mapDispatchToProps = {
  setDataFormula: configuration.actions.setDataFormula,
  fetchVariablesList: configuration.actions.fetchVariablesList,
}

export default connect(mapStateToProps, mapDispatchToProps)(EditFormulaModal)
