export const NAME = 'planning'

export const CREATE_PERIOD = `${NAME}/CREATE_PERIOD`
export const CREATE_PERIOD_SUCCESS = `${NAME}/CREATE_PERIOD_SUCCESS`
export const CREATE_PERIOD_FAIL = `${NAME}/CREATE_PERIOD_FAIL`

export const DELETE_PERIOD = `${NAME}/DELETE_PERIOD`

export const EDIT_PERIOD = `${NAME}/EDIT_PERIOD`

export const FETCH_PERIODS = `${NAME}/FETCH_PERIODS`
export const FETCH_PERIODS_SUCCESS = `${NAME}/FETCH_PERIODS_SUCCESS`
export const FETCH_PERIODS_FAIL = `${NAME}/FETCH_PERIODS_FAIL`

export const FETCH_GLOBAL_BUDGET_CHART = `${NAME}/FETCH_GLOBAL_BUDGET_CHART`
export const FETCH_GLOBAL_BUDGET_CHART_SUCCESS = `${NAME}/FETCH_GLOBAL_BUDGET_CHART_SUCCESS`
export const FETCH_GLOBAL_BUDGET_CHART_FAIL = `${NAME}/FETCH_GLOBAL_BUDGET_CHART_FAIL`

export const FETCH_GLOBAL_BUDGET_EVOLUTION_TABLE = `${NAME}/FETCH_GLOBAL_BUDGET_EVOLUTION_TABLE`
export const FETCH_GLOBAL_BUDGET_EVOLUTION_TABLE_SUCCESS = `${NAME}/FETCH_GLOBAL_BUDGET_EVOLUTION_TABLE_SUCCESS`
export const FETCH_GLOBAL_BUDGET_EVOLUTION_TABLE_FAIL = `${NAME}/FETCH_GLOBAL_BUDGET_EVOLUTION_TABLE_FAIL`

export const FETCH_EVOLUTION_TABLE_OPEN_ROW = `${NAME}/FETCH_EVOLUTION_TABLE_OPEN_ROW`
export const FETCH_EVOLUTION_TABLE_OPEN_ROW_SUCCESS = `${NAME}/FETCH_EVOLUTION_TABLE_OPEN_ROW_SUCCESS`
export const FETCH_EVOLUTION_TABLE_OPEN_ROW_FAIL = `${NAME}/FETCH_EVOLUTION_TABLE_OPEN_ROW_FAIL`

export const FETCH_GLOBAL_BUDGET_KPI = `${NAME}/FETCH_GLOBAL_BUDGET_KPI`
export const FETCH_GLOBAL_BUDGET_KPI_SUCCESS = `${NAME}/FETCH_GLOBAL_BUDGET_KPI_SUCCESS`
export const FETCH_GLOBAL_BUDGET_KPI_FAIL = `${NAME}/FETCH_GLOBAL_BUDGET_KPI_FAIL`

export const DOWNLOAD_GLOBAL_BUDGET_EVOLUTION_XLS = `${NAME}/DOWNLOAD_GLOBAL_BUDGET_EVOLUTION_XLS`
export const DOWNLOAD_GLOBAL_BUDGET_COMPARISON_XLS = `${NAME}/DOWNLOAD_GLOBAL_BUDGET_COMPARISON_XLS`

export const FETCH_PERIOD_DETAIL = `${NAME}/FETCH_PERIOD_DETAIL`
export const FETCH_PERIOD_DETAIL_SUCCESS = `${NAME}/FETCH_PERIOD_DETAIL_SUCCESS`
export const FETCH_PERIOD_DETAIL_FAIL = `${NAME}/FETCH_PERIOD_DETAIL_FAIL`

export const FETCH_DATA_NESTED_TABLE_BY_PHASE = `${NAME}/FETCH_DATA_NESTED_TABLE_BY_PHASE`
export const FETCH_DATA_NESTED_TABLE_BY_PHASE_SUCCESS = `${NAME}/FETCH_DATA_NESTED_TABLE_BY_PHASE_SUCCESS`
export const FETCH_DATA_NESTED_TABLE_BY_PHASE_FAIL = `${NAME}/FETCH_DATA_NESTED_TABLE_BY_PHASE_FAIL`

export const FETCH_EXPAND_NESTED_TABLE_ROW = `${NAME}/FETCH_EXPAND_NESTED_TABLE_ROW`
export const FETCH_EXPAND_NESTED_TABLE_ROW_SUCCESS = `${NAME}/FETCH_EXPAND_NESTED_TABLE_ROW_SUCCESS`
export const FETCH_EXPAND_NESTED_TABLE_ROW_FAIL = `${NAME}/FETCH_EXPAND_NESTED_TABLE_ROW_FAIL`

export const DOWNLOAD_BUDGET_TABLE_NESTED_XLS = `${NAME}/DOWNLOAD_BUDGET_TABLE_NESTED_XLS`

export const SET_NEXT_PHASE = `${NAME}/SET_NEXT_PHASE`

export const CLOSING_BUDGET = `${NAME}/CLOSING_BUDGET`

export const FETCH_CONCEPTS_DETAIL = `${NAME}/FETCH_CONCEPTS_DETAIL`
export const FETCH_CONCEPTS_DETAIL_SUCCESS = `${NAME}/FETCH_CONCEPTS_DETAIL_SUCCESS`
export const FETCH_CONCEPTS_DETAIL_FAIL = `${NAME}/FETCH_CONCEPTS_DETAIL_FAIL`

export const FETCH_CONCEPT_TEMPLATE_XLS = `${NAME}/FETCH_REPORT_TEMPLATE_XLS`

export const FETCH_CONCEPT_CONSOLIDATED_XLS = `${NAME}/FETCH_CONCEPT_CONSOLIDATED_XLS`

export const FETCH_BUDGET_INSTANCE_LIST = `${NAME}/FETCH_BUDGET_INSTANCE_LIST`
export const FETCH_BUDGET_INSTANCE_LIST_SUCCESS = `${NAME}/FETCH_BUDGET_INSTANCE_LIST_SUCCESS`
export const FETCH_BUDGET_INSTANCE_LIST_FAIL = `${NAME}/FETCH_BUDGET_INSTANCE_LIST_FAIL`

export const FETCH_BUDGET_INSTANCE_DETAIL = `${NAME}/FETCH_BUDGET_INSTANCE_DETAIL`
export const FETCH_BUDGET_INSTANCE_DETAIL_SUCCESS = `${NAME}/FETCH_BUDGET_INSTANCE_DETAIL_SUCCESS`
export const FETCH_BUDGET_INSTANCE_DETAIL_FAIL = `${NAME}/FETCH_BUDGET_INSTANCE_DETAIL_FAIL`

export const FETCH_PROJECTION_DETAIL = `${NAME}/FETCH_PROJECTION_DETAIL`
export const FETCH_PROJECTION_DETAIL_SUCCESS = `${NAME}/FETCH_PROJECTION_DETAIL_SUCCESS`
export const FETCH_PROJECTION_DETAIL_FAIL = `${NAME}/FETCH_PROJECTION_DETAIL_FAIL`

export const FETCH_PROJECTION_COLUMNS = `${NAME}/FETCH_PROJECTION_COLUMNS`
export const FETCH_PROJECTION_COLUMNS_SUCCESS = `${NAME}/FETCH_PROJECTION_COLUMNS_SUCCESS`
export const FETCH_PROJECTION_COLUMNS_FAIL = `${NAME}/FETCH_PROJECTION_COLUMNS_FAIL`

export const FETCH_PROJECTION_ROWS = `${NAME}/FETCH_PROJECTION_ROWS`
export const FETCH_PROJECTION_ROWS_SUCCESS = `${NAME}/FETCH_PROJECTION_ROWS_SUCCESS`
export const FETCH_PROJECTION_ROWS_FAIL = `${NAME}/FETCH_PROJECTION_ROWS_FAIL`

export const FETCH_PROJECTION_AMOUNTS_ROWS = `${NAME}/FETCH_PROJECTION_AMOUNTS_ROWS`
export const FETCH_PROJECTION_AMOUNTS_ROWS_SUCCESS = `${NAME}/FETCH_PROJECTION_AMOUNTS_ROWS_SUCCESS`
export const FETCH_PROJECTION_AMOUNTS_ROWS_FAIL = `${NAME}/FETCH_PROJECTION_AMOUNTS_ROWS_FAIL`

export const FETCH_PROJECTION_TEMPLATE_XLS = `${NAME}/FETCH_PROJECTION_TEMPLATE_XLS`
export const FETCH_PROJECTION_TEMPLATE_XLS_SUCCESS = `${NAME}/FETCH_PROJECTION_TEMPLATE_XLS_SUCCESS`
export const FETCH_PROJECTION_TEMPLATE_XLS_FAIL = `${NAME}/FETCH_PROJECTION_TEMPLATE_XLS_FAIL`

export const DOWNLOAD_VARIABLES_TEMPLATE_XLS = `${NAME}/DOWNLOAD_VARIABLES_TEMPLATE_XLS`
export const UPLOAD_VARIABLES_FILE_XLS = `${NAME}/UPLOAD_VARIABLES_FILE_XLS`

export const UPLOAD_PROJEXTION_XLS = `${NAME}/UPLOAD_PROJEXTION_XLS`
export const UPLOAD_PROJEXTION_XLS_SUCCESS = `${NAME}/UPLOAD_PROJEXTION_XLS_SUCCESS`
export const UPLOAD_PROJEXTION_XLS_FAIL = `${NAME}/UPLOAD_PROJEXTION_XLS_FAIL`

export const UPLOAD_BUDGET_TYPE_VALUES_XLS = `${NAME}/UPLOAD_BUDGET_TYPE_VALUES_XLS`

export const DOWNLOAD_BUDGET_TABLE_BY_PHASE_XLS = `${NAME}/DOWNLOAD_BUDGET_TABLE_BY_PHASE_XLS,`

export const FETCH_BUDGET_KPIS = `${NAME}/FETCH_BUDGET_KPIS`
export const FETCH_BUDGET_KPIS_SUCCESS = `${NAME}/FETCH_BUDGET_KPIS_SUCCESS`
export const FETCH_BUDGET_KPIS_FAIL = `${NAME}/FETCH_BUDGET_KPIS_FAIL`

export const FETCH_PROJECTION_AMOUNTS_KPIS = `${NAME}/FETCH_PROJECTION_AMOUNTS_KPIS`
export const FETCH_PROJECTION_AMOUNTS_KPIS_SUCCESS = `${NAME}/FETCH_PROJECTION_AMOUNTS_KPIS_SUCCESS`
export const FETCH_PROJECTION_AMOUNTS_KPIS_FAIL = `${NAME}/FETCH_PROJECTION_AMOUNTS_KPIS_FAIL`

export const FETCH_USER_TRACKS = `${NAME}/FETCH_TRACKS`
export const FETCH_USER_TRACKS_SUCCESS = `${NAME}/FETCH_TRACKS_SUCCESS`
export const FETCH_USER_TRACKS_FAIL = `${NAME}/FETCH_TRACKS_FAIL`

export const FETCH_FILE_TRACKS = `${NAME}/FETCH_FILE_TRACKS`
export const FETCH_FILE_TRACKS_SUCCESS = `${NAME}/FETCH_FILE_TRACKS_SUCCESS`
export const FETCH_FILE_TRACKS_FAIL = `${NAME}/FETCH_FILE_TRACKS_FAIL`

export const APPROVE_GLOBAL_BUDGET = `${NAME}/APPROVE_GLOBAL_BUDGET`

export const FETCH_PROJECTION_FILTERS = `${NAME}/FETCH_PROJECTION_FILTERS`
export const FETCH_PROJECTION_FILTERS_SUCCESS = `${NAME}/FETCH_PROJECTION_FILTERS_SUCCESS`
export const FETCH_PROJECTION_FILTERS_FAIL = `${NAME}/FETCH_PROJECTION_FILTERS_FAIL`

export const FETCH_PUBLISHED_ACCOUNTS = `${NAME}/FETCH_PUBLISHED_ACCOUNTS`
export const FETCH_PUBLISHED_ACCOUNTS_SUCCESS = `${NAME}/FETCH_PUBLISHED_ACCOUNTS_SUCCESS`
export const FETCH_PUBLISHED_ACCOUNTS_FAIL = `${NAME}/FETCH_PUBLISHED_ACCOUNTS_FAIL`

export const CLEAN_PUBLISHED_DATES = `${NAME}/CLEAN_PLANNING_PUBLISHED_DATES`

export const UPDATE_TRANSACTION = `${NAME}/UPDATE_TRANSACTION`
export const UPDATE_TRANSACTION_SUCCESS = `${NAME}/UPDATE_TRANSACTION_SUCCESS`
export const UPDATE_TRANSACTION_FAIL = `${NAME}/UPDATE_TRANSACTION_FAIL`

export const UPDATE_TRANSACTION_NESTED = `${NAME}/UPDATE_TRANSACTION_NESTED`
export const UPDATE_PERCENTAGE_TRANSACTION_NESTED = `${NAME}/UPDATE_PERCENTAGE_TRANSACTION_NESTED`

export const UPDATE_TRANSACTION_FORWARD = `${NAME}/UPDATE_TRANSACTION_FORWARD`
export const UPDATE_TRANSACTION_FORWARD_SUCCESS = `${NAME}/UPDATE_TRANSACTION_FORWARD_SUCCESS`
export const UPDATE_TRANSACTION_FORWARD_FAIL = `${NAME}/UPDATE_TRANSACTION_FORWARD_FAIL`

export const UPDATE_TRANSACTION_ALL = `${NAME}/UPDATE_TRANSACTION_ALL`
export const UPDATE_TRANSACTION_ALL_SUCCESS = `${NAME}/UPDATE_TRANSACTION_ALL_SUCCESS`
export const UPDATE_TRANSACTION_ALL_FAIL = `${NAME}/UPDATE_TRANSACTION_ALL_FAIL`

export const APPLY_VARIABLES = `${NAME}/APPLY_VARIABLES`
export const APPLY_VARIABLES_SUCCESS = `${NAME}/APPLY_VARIABLES_SUCCESS`
export const APPLY_VARIABLES_FAIL = `${NAME}/APPLY_VARIABLES_FAIL`

export const SET_UPLOAD_LIMIT_DATE = `${NAME}/SET_UPLOAD_LIMIT_DATE`

export const FETCH_UPLOAD_LIMIT_DATE = `${NAME}/FETCH_UPLOAD_LIMIT_DATE`
export const FETCH_UPLOAD_LIMIT_DATE_SUCCESS = `${NAME}/FETCH_UPLOAD_LIMIT_DATE_SUCCESS`
export const FETCH_UPLOAD_LIMIT_DATE_FAIL = `${NAME}/FETCH_UPLOAD_LIMIT_DATE_FAIL`

export const CREATE_BUDGET_INSTANCE = `${NAME}/CREATE_BUDGET_INSTANCE`
export const UPDATE_BUDGET_INSTANCE = `${NAME}/UPDATE_BUDGET_INSTANCE`

export const EDIT_BUDGET_NAME = `${NAME}/EDIT_BUDGET_NAME`

export const EDIT_GLOBAL_BUDGET_NAME = `${NAME}/EDIT_GLOBAL_BUDGET_NAME`

export const DELETE_BUDGET = `${NAME}/DELETE_BUDGET`

export const DELETE_GLOBAL_BUDGET = `${NAME}/DELETE_GLOBAL_BUDGET`

export const SET_DATA = `${NAME}/SET_DATA`

export const DUPLICATE_BUDGET = `${NAME}/DUPLICATE_BUDGET`
export const DUPLICATE_BUDGET_SUCCESS = `${NAME}/DUPLICATE_BUDGET_SUCCESS`
export const DUPLICATE_BUDGET_FAIL = `${NAME}/DUPLICATE_BUDGET_FAIL`

export const FETCH_GLOBAL_BUDGET_LIST = `${NAME}/FETCH_GLOBAL_BUDGET_LIST`
export const FETCH_GLOBAL_BUDGET_LIST_SUCCESS = `${NAME}/FETCH_GLOBAL_BUDGET_LIST_SUCCESS`
export const FETCH_GLOBAL_BUDGET_LIST_FAIL = `${NAME}/FETCH_GLOBAL_BUDGET_LIST_FAIL`

export const FETCH_GLOBAL_BUDGET_DETAIL = `${NAME}/FETCH_GLOBAL_BUDGET_DETAIL`
export const FETCH_GLOBAL_BUDGET_DETAIL_SUCCESS = `${NAME}/FETCH_GLOBAL_BUDGET_DETAIL_SUCCESS`
export const FETCH_GLOBAL_BUDGET_DETAIL_FAIL = `${NAME}/FETCH_GLOBAL_BUDGET_DETAIL_FAIL`

export const FETCH_GLOBAL_BUDGET_COMPARISON_TABLE = `${NAME}/FETCH_GLOBAL_BUDGET_COMPARISON_TABLE`
export const FETCH_GLOBAL_BUDGET_COMPARISON_TABLE_SUCCESS = `${NAME}/FETCH_GLOBAL_BUDGET_COMPARISON_TABLE_SUCCESS`
export const FETCH_GLOBAL_BUDGET_COMPARISON_TABLE_FAIL = `${NAME}/FETCH_GLOBAL_BUDGET_COMPARISON_TABLE_FAIL`

export const FETCH_COMPARISON_TABLE_OPEN_ROW = `${NAME}/FETCH_COMPARISON_TABLE_OPEN_ROW`
export const FETCH_COMPARISON_TABLE_OPEN_ROW_SUCCESS = `${NAME}/FETCH_COMPARISON_TABLE_OPEN_ROW_SUCCESS`
export const FETCH_COMPARISON_TABLE_OPEN_ROW_FAIL = `${NAME}/FETCH_COMPARISON_TABLE_OPEN_ROW_FAIL`

export const CREATE_GLOBAL_BUDGET = `${NAME}/CREATE_GLOBAL_BUDGET`
export const CREATE_CGLOBAL_BUDGET_SUCCESS = `${NAME}/CREATE_GLOBAL_BUDGET_SUCCESS`
export const CREATE_GLOBAL_BUDGET_FAIL = `${NAME}/CREATE_GLOBAL_BUDGET_FAIL`

export const FETCH_GLOBAL_BUDGET_DETAIL_KPI = `${NAME}/FETCH_GLOBAL_BUDGET_DETAIL_KPI`
export const FETCH_GLOBAL_BUDGET_DETAIL_KPI_SUCCESS = `${NAME}/FETCH_GLOBAL_BUDGET_DETAIL_KPI_SUCCESS`
export const FETCH_GLOBAL_BUDGET_DETAIL_KPI_FAIL = `${NAME}/FETCH_GLOBAL_BUDGET_DETAIL_KPI_FAIL`

export const CHANGE_BUDGET = `${NAME}/CHANGE_BUDGET`
export const CHANGE_BUDGET_SUCCESS = `${NAME}/CHANGE_BUDGET_SUCCESS`
export const CHANGE_BUDGET_FAIL = `${NAME}/CHANGE_BUDGET_FAIL`

export const DELETE_BUDGET_ROW = `${NAME}/DELETE_BUDGET_ROW`
export const DELETE_ALL_PROJECTION_ROWS = `${NAME}/DELETE_ALL_PROJECTION_ROWS`

export const FETCH_FORMULAS_AMOUNT_LIST = `${NAME}/FETCH_FORMULAS_AMOUNT_LIST`
export const FETCH_FORMULAS_AMOUNT_LIST_SUCCESS = `${NAME}/FETCH_FORMULAS_AMOUNT_LIST_SUCCESS`
export const FETCH_FORMULAS_AMOUNT_LIST_FAIL = `${NAME}/FETCH_FORMULAS_AMOUNT_LIST_FAIL`

export const FETCH_FACTOR_ROWS = `${NAME}/FETCH_FACTOR_ROWS`
export const FETCH_FACTOR_ROWS_SUCCESS = `${NAME}/FETCH_FACTOR_ROWS_SUCCESS`
export const FETCH_FACTOR_ROWS_FAIL = `${NAME}/FETCH_FACTOR_ROWS_FAIL`

export const DELETE_ALL_FACTOR_ROWS = `${NAME}/DELETE_ALL_FACTOR_ROWS`
export const DELETE_FACTOR_ROW = `${NAME}/DELETE_FACTOR_ROW`

export const REPLICATE_FACTOR = `${NAME}/REPLICATE_FACTOR`
export const REPLICATE_FACTOR_SUCCESS = `${NAME}/REPLICATE_FACTOR_SUCCESS`
export const REPLICATE_FACTOR_FAIL = `${NAME}/REPLICATE_FACTOR_FAIL`

export const FETCH_FACTOR_COLUMNS = `${NAME}/FETCH_FACTOR_COLUMNS`
export const FETCH_FACTOR_COLUMNS_SUCCESS = `${NAME}/FETCH_FACTOR_COLUMNS_SUCCESS`
export const FETCH_FACTOR_COLUMNS_FAIL = `${NAME}/FETCH_FACTOR_COLUMNS_FAIL`

export const UPDATE_TRANSACTION_FACTOR = `${NAME}/UPDATE_TRANSACTION_FACTOR`

export const UNAPPLY_FORMULA = `${NAME}/UNAPPLY_FORMULA`
export const UNAPPLY_FORMULA_SUCCESS = `${NAME}/UNAPPLY_FORMULA_SUCCESS`
export const UNAPPLY_FORMULA_FAIL = `${NAME}/UNAPPLY_FORMULA_FAIL`

export const APPLY_RULE = `${NAME}/APPLY_RULE`
export const UNAPPLY_RULE = `${NAME}/UNAPPLY_RULE`
export const APPLY_BUDGET_RULE = `${NAME}/APPLY_BUDGET_RULE`
export const UNAPPLY_BUDGET_RULE = `${NAME}/UNAPPLY_BUDGET_RULE`

export const SAVE_VARIABLE_VALUES = `${NAME}/SAVE_VARIABLE_VALUES`

export const FETCH_DIMENSIONS_VALUES_LIST = `${NAME}/FETCH_DIMENSIONS_VALUES_LIST`
export const FETCH_DIMENSIONS_VALUES_LIST_SUCCESS = `${NAME}/FETCH_DIMENSIONS_VALUES_LIST_SUCCESS`
export const FETCH_DIMENSIONS_VALUES_LIST_FAIL = `${NAME}/FETCH_DIMENSIONS_VALUES_LIST_FAIL`

//FIJAR PPTO
export const SET_DEFAULT_BUDGET = `${NAME}/SET_DEFAULT_BUDGET`
export const SET_DEFAULT_BUDGET_SUCCESS = `${NAME}/SET_DEFAULT_BUDGET_SUCCESS`
export const SET_DEFAULT_BUDGET_FAIL = `${NAME}/SET_DEFAULT_BUDGET_FAIL`

//Actualizar datos
export const UPDATE_GLOBAL_BUDGET = `${NAME}/UPDATE_GLOBAL_BUDGET`
export const UPDATE_GLOBAL_BUDGET_SUCCESS = `${NAME}/UPDATE_GLOBAL_BUDGET_SUCCESS`
export const UPDATE_GLOBAL_BUDGET_FAIL = `${NAME}/UPDATE_GLOBAL_BUDGET_FAIL`

export const PUBLISH_GLOBAL_BUDGET = `${NAME}/PUBLISH_GLOBAL_BUDGET`

// ++ COMMENTS ++
export const SYNC_CREATE_BUDGET_COMMENT = `${NAME}/SYNC_CREATE_BUDGET_COMMENT`
export const SYNC_DELETE_BUDGET_COMMENT = `${NAME}/SYNC_DELETE_BUDGET_COMMENT`
export const SYNC_EDIT_BUDGET_COMMENT = `${NAME}/SYNC_EDIT_BUDGET_COMMENT`

export const FETCH_TRANSACTION_COMMENTS = `${NAME}/FETCH_TRANSACTION_COMMENTS`
export const FETCH_TRANSACTION_COMMENTS_SUCCESS = `${NAME}/FETCH_TRANSACTION_COMMENTS_SUCCESS`
export const FETCH_TRANSACTION_COMMENTS_FAIL = `${NAME}/FETCH_TRANSACTION_COMMENTS_FAIL`

export const CREATE_TRANSACTION_COMMENT = `${NAME}/CREATE_TRANSACTION_COMMENT`

export const DELETE_TRANSACTION_COMMENT = `${NAME}/DELETE_TRANSACTION_COMMENT`
export const DELETE_TRANSACTION_COMMENT_SUCCESS = `${NAME}/DELETE_TRANSACTION_COMMENT_SUCCESS`
export const DELETE_TRANSACTION_COMMENT_FAIL = `${NAME}/DELETE_TRANSACTION_COMMENT_FAIL`

export const EDIT_TRANSACTION_COMMENT = `${NAME}/EDIT_TRANSACTION_COMMENT`
export const EDIT_TRANSACTION_COMMENT_SUCCESS = `${NAME}/EDIT_TRANSACTION_COMMENT_SUCCESS`
export const EDIT_TRANSACTION_COMMENT_FAIL = `${NAME}/EDIT_TRANSACTION_COMMENT_FAIL`

// ++ END COMMENTS ++

//MESES
export const FETCH_MONTHS_LIST = `${NAME}/FETCH_MONTHS_LIST`
export const FETCH_MONTHS_LIST_SUCCESS = `${NAME}/FETCH_MONTHS_LIST_SUCCESS`
export const FETCH_MONTHS_LIST_FAIL = `${NAME}/FETCH_MONTHS_LIST_FAIL`

//MULTIMONEDA

export const SAVE_EXCHANGE_RATE = `${NAME}/SAVE_EXCHANGE_RATE`

export const FETCH_CURRENCIES_TRACKS = `${NAME}/FETCH_CURRENCIES_TRACKS`
export const FETCH_CURRENCIES_TRACKS_SUCCESS = `${NAME}/FETCH_CURRENCIES_TRACKS_SUCCESS`
export const FETCH_CURRENCIES_TRACKS_FAIL = `${NAME}/FETCH_CURRENCIES_TRACKS_FAIL`

export const EDIT_TRANSACTION_ORIGIN_CURRENCY = `${NAME}/EDIT_TRANSACTION_ORIGIN_CURRENCY`
export const CREATE_TRANSACTION_ORIGIN_CURRENCY = `${NAME}/CREATE_TRANSACTION_ORIGIN_CURRENCY`
export const UPDARE_TRANSACTION_FORWARD_ORIGIN = `${NAME}/UPDARE_TRANSACTION_FORWARD_ORIGIN`

// INTEGRATIONS
export const INTEGRATIONS_UPLOAD_FILE = `${NAME}/INTEGRATIONS_UPLOAD_FILE`
export const INTEGRATIONS_UPLOAD_FILE_SUCCESS = `${NAME}/INTEGRATIONS_UPLOAD_FILE_SUCCESS`
export const INTEGRATIONS_UPLOAD_FILE_FAIL = `${NAME}/INTEGRATIONS_UPLOAD_FILE_FAIL`

export const FETCH_ORIGIN_ROWS = `${NAME}/FETCH_ORIGIN_ROWS`
export const FETCH_ORIGIN_ROWS_SUCCESS = `${NAME}/FETCH_ORIGIN_ROWS_SUCCESS`
export const FETCH_ORIGIN_ROWS_FAIL = `${NAME}/FETCH_ORIGIN_ROWS_FAIL`

//pxq

export const FETCH_PXQ = `${NAME}/FETCH_PXQ`
export const FETCH_PXQ_SUCCESS = `${NAME}/FETCH_PXQ_SUCCESS`
export const FETCH_PXQ_FAIL = `${NAME}/FETCH_PXQ_FAIL`

export const CREATE_PXQ = `${NAME}/CREATE_PXQ`

export const ENABLE_PXQ = `${NAME}/ENABLE_PXQ`

export const UPDATE_CELL_PXQ = `${NAME}/UPDATE_CELL_PXQ`

export const SET_DATA_UPDATE_CELL_PXQ = `${NAME}/SET_DATA_UPDATE_CELL_PXQ`

export const DELETE_ROW = `${NAME}/DELETE_ROW`
export const DELETE_ALL = `${NAME}/DELETE_ALL`
export const DELETE_FILTER = `${NAME}/DELETE_FILTER`

export const ORDER_BUDGET_CARD = `${NAME}/ORDER_BUDGET_CARD`
export const SET_BUDGET_ORDER = `${NAME}/SET_BUDGET_ORDER`

export const FETCH_ML_LIST = `${NAME}/FETCH_ML_LIST`
export const FETCH_ML_LIST_SUCCESS = `${NAME}/FETCH_ML_LIST_SUCCESS`
export const FETCH_ML_LIST_FAIL = `${NAME}/FETCH_ML_LIST_FILE`

export const ORDER_GLOBAL_BUDGET_CARD = `${NAME}/ORDER_GLOBAL_BUDGET_CARD`
export const SET_GLOBAL_BUDGET_ORDER = `${NAME}/SET_GLOBAL_BUDGET_ORDER`

export const MASSIVE_CREATE_PLANNING_TRANSACTION = `${NAME}/MASSIVE_CREATE_PLANNING_TRANSACTION,`
export const MASSIVE_CREATE_PLANNING_TRANSACTION_SUCCESS = `${NAME}/MASSIVE_CREATE_PLANNING_TRANSACTION_SUCCESS`
export const MASSIVE_CREATE_PLANNING_TRANSACTION_FAIL = `${NAME}/MASSIVE_CREATE_PLANNING_TRANSACTION_FAIL`

export const CHANGE_CARD_COLOR = `${NAME}/CHANGE_CARD_COLOR`
// TODO: NEW ENDPOINTS FOR REVAMP TABLE
export const FETCH_DATA_EVOLUTION_BY_OVERALL_BUDGET = `${NAME}/FETCH_DATA_EVOLUTION_BY_OVERALL_BUDGET`
export const FETCH_DATA_EVOLUTION_BY_OVERALL_BUDGET_SUCCESS = `${NAME}/FETCH_DATA_EVOLUTION_BY_OVERALL_BUDGET_SUCCESS`
export const FETCH_DATA_EVOLUTION_BY_OVERALL_BUDGET_FAIL = `${NAME}/FETCH_DATA_EVOLUTION_BY_OVERALL_BUDGET_FAIL`

export const FETCH_DATA_COMPARATIVE_BY_OVERALL_BUDGET = `${NAME}/FETCH_DATA_COMPARATIVE_BY_OVERALL_BUDGET`
export const FETCH_DATA_COMPARATIVE_BY_OVERALL_BUDGET_SUCCESS = `${NAME}/FETCH_DATA_COMPARATIVE_BY_OVERALL_BUDGET_SUCCESS`
export const FETCH_DATA_COMPARATIVE_BY_OVERALL_BUDGET_FAIL = `${NAME}/FETCH_DATA_COMPARATIVE_BY_OVERALL_BUDGET_FAIL`

export const FETCH_DATA_RDN_PERCENTAGE_BY_PLANNING = `${NAME}/FETCH_DATA_RDN_PERCENTAGE_BY_PLANNING`
export const FETCH_DATA_RDN_PERCENTAGE_BY_PLANNING_SUCCESS = `${NAME}/FETCH_DATA_RDN_PERCENTAGE_BY_PLANNING_SUCCESS`
export const FETCH_DATA_RDN_PERCENTAGE_BY_PLANNING_FAIL = `${NAME}/FETCH_DATA_RDN_PERCENTAGE_BY_PLANNING_FAIL`

export const UPDATE_TRANSACTION_PERCENTAGE_BY_PLANNING = `${NAME}/UPDATE_TRANSACTION_PERCENTAGE_BY_PLANNING`

export const APPLY_RDN_PERCENTAGE_BY_PLANNING = `${NAME}/APPLY_RDN_PERCENTAGE_BY_PLANNING`
export const UN_APPLY_RDN_PERCENTAGE_BY_PLANNING = `${NAME}/UN_APPLY_RDN_PERCENTAGE_BY_PLANNING`

export const DELETE_TRANSACTION_FILTER_BY_PLANNING = `${NAME}/DELETE_TRANSACTION_FILTER_BY_PLANNING`

export const DUPLICATE_GLOBAL_BUDGET = `${NAME}/DUPLICATE_GLOBAL_BUDGET`

export const ORDER_PERIOD_CARD = `${NAME}/ORDER_PERIOD_CARD`

export const SET_PERIOD_ORDER = `${NAME}/SET_PERIOD_ORDER`

export const FETCH_PLANNING_FILTER_DATES = `${NAME}/FETCH_PLANNING_FILTER_DATES`
export const FETCH_PLANNING_FILTER_DATES_SUCCESS = `${NAME}/FETCH_PLANNING_FILTER_DATES_SUCCESS`
