import { Col, Form, Input, Radio, Row, Space, Switch, Tooltip } from 'antd'
import { Typography } from 'modules/core/components'
import { useTranslation } from 'react-i18next'
import { InfoCircleOutlined } from '@ant-design/icons'

const OPTIONS_RULE_CREATE = [
  {
    id: 'amount',
    name: 'FIELD_AMOUNT',
  },
  {
    id: 'price',
    name: 'FIELD_PRICE',
  },
  {
    id: 'quantity',
    name: 'FIELD_COUNT',
  },
]

const validatorValue = /^[a-zA-ZáéíóúÁÉÍÓÚÑñ0-9\s]+$/

const FormName = ({ form, setIsReplaceValue }) => {
  const { t } = useTranslation()
  return (
    <Form form={form} layout="vertical" hideRequiredMark={false}>
      {() => (
        <Row gutter={[8, 8]}>
          <Col span={24}>
            <Form.Item
              name="name"
              label={t('FIELD_NAME')}
              rules={[
                { required: true, message: t('REQUIRED_FIELD') },
                () => ({
                  validator(_, value) {
                    if (value && validatorValue.test(value)) {
                      return Promise.resolve()
                    }
                    if (value && !validatorValue.test(value)) {
                      return Promise.reject(
                        new Error(t('CONFIG_RULE_NAME_WITHOUT_NUMBERS_INFORMATIVE_TOOLTIP')),
                      )
                    }
                    if (!value) {
                      return Promise.reject(new Error())
                    }
                  },
                }),
              ]}
            >
              <Input placeholder={t('CONFIG_FORMULAS_CREATE_MODAL_PLACEHOLDER')} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="description" label={t('FIELD_DESCRIPTION')}>
              <Input.TextArea placeholder={t('WRITE_SOMETHING_PLACEHOLDER')} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="replace_values" label={t('FIELD_BEHAVIOR')}>
              <Space direction="horizontal">
                <Switch onChange={setIsReplaceValue} />
                <Typography.Body level={2}>
                  {t('CONFIG_RULES_SWITCH_FORM_LABEL_REPLACE_VALUES')}
                </Typography.Body>
                <Tooltip title={t('CONFIG_RULES_SWITCH_FORM_INFORMATIVE_TOOLTIP_TITLE')}>
                  <Typography.Icon level={2} icon={InfoCircleOutlined} />
                </Tooltip>
              </Space>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="type_value"
              label={t('CONFIG_RULE_CALCULATE_FORM_LABEL')}
              // rules={[{ required: true, message: t('REQUIRED_FIELD') }]}
              initialValue="amount"
            >
              <Radio.Group>
                {OPTIONS_RULE_CREATE.map((option) => (
                  <Radio key={option.id} value={option.id}>
                    {t(option.name)}
                  </Radio>
                ))}
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
      )}
    </Form>
  )
}

export default FormName
