import React from 'react'
import { Typography } from 'modules/core/components'
import { useTranslation } from 'react-i18next'

const DashboardHeader = ({ firstName }) => {
  const { t } = useTranslation()
  return (
    <div className="dashboard-title">
      <Typography.Headline level={3} type="primary">
        {t('DASHBOARD_USER_HELLO', { name: firstName })}
      </Typography.Headline>
      <Typography.Body>{t('DASHBOARD_SUBTITLE')}</Typography.Body>
    </div>
  )
}

export default DashboardHeader
