import { Select, Space } from 'antd'
import { Typography } from 'modules/core/components'
import { useTranslation } from 'react-i18next'

const SelectFilters = ({ title, loading, onChange, value, options, style = { width: '100%' } }) => {
  const { t } = useTranslation()
  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Typography.Body level={2}>{t(title)}</Typography.Body>
      <Select
        placeholder={t('ACTION_SELECT')}
        style={style}
        loading={loading}
        onChange={onChange}
        value={value}
        options={options}
      />
    </Space>
  )
}

export default SelectFilters
