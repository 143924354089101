import { CommentOutlined } from '@ant-design/icons'
import { useFeatureValue } from '@growthbook/growthbook-react'
import { Alert, Col, Dropdown, Menu, message, Row, Space, Spin } from 'antd'
import PivotTableUX from 'components/PivotTable/PivotTableUX'
import PivotTableRenderers from 'components/PivotTable/Renderers/Variation/Tree'
import _ from 'lodash'
import configuration from 'modules/configuration'
import control from 'modules/control'
import {
  handleSave,
  handleSavePrimary,
} from 'modules/control/containers/ControlDetail/components/Evolution/utils'
import { Button, ProgressBar, SelectType } from 'modules/core/components'
import { GENERAL, PROJECTION } from 'modules/core/constants'
import { generateMessageError } from 'modules/core/utils'
import reports from 'modules/reports'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import { handleDelete } from '../../utils'
import { TableActions } from './components'

const DEFAULT_FORMAT_CREATE = {
  'Suma (Decimales)': 'float',
  'Suma (Enteros)': 'int',
  'Suma (Miles)': 't',
  'Suma (Millones)': 'm',
}

const DEFAULT_FORMAT_SET = {
  float: 'Suma (Decimales)',
  int: 'Suma (Enteros)',
  t: 'Suma (Miles)',
  m: 'Suma (Millones)',
}

const getRowAuxName = (rows, allDimensionsList) => {
  return rows.map((row) => {
    if (row === 'Linea de reporte') {
      return 'Linea de reporte'
    } else {
      const dimension = allDimensionsList.find((el) => parseInt(row) === el.id)
      return dimension ? dimension.name_origin : ''
    }
  })
}

const getColsAuxName = (columns, allDimensionsList) => {
  return columns.map((col) => {
    if (col === 'Fecha') {
      return 'Fecha'
    } else if (col === 'Proyecciones') {
      return 'Proyecciones'
    } else {
      const dimension = allDimensionsList.find((el) => parseInt(col) === el.id)
      return dimension ? dimension.name_origin : ''
    }
  })
}

const getRowAuxId = (settings, allDimensionsList) => {
  return (
    !_.isEmpty(settings) &&
    settings.rows.map((row) => {
      if (row === 'Linea de reporte') {
        return 'Linea de reporte'
      } else {
        const id = allDimensionsList.find((el) => el.name_origin === row).id
        return id.toString()
      }
    })
  )
}

const getColsAuxId = (settings, allDimensionsList) => {
  return (
    !_.isEmpty(settings) &&
    settings.cols.map((col) => {
      if (col === 'Fecha') {
        return 'Fecha'
      } else if (col === 'Proyecciones') {
        return 'Proyecciones'
      } else {
        const id = allDimensionsList.find((el) => el.name_origin === col)
          ? allDimensionsList.find((el) => el.name_origin === col).id
          : null
        return id.toString()
      }
    })
  )
}

const Variation = ({
  loading,
  groupProjection,
  referenceBase,
  setReferenceBase,
  referenceComparison,
  setReferenceComparison,
  onRefetchComparative,
  setDataComments,
  dataComments,
  onRefetchComments,
  loadingComments,
  commentKey,
  dataComparativeByReport,
  createLayout,
  fetchAllDimensions,
  fetchLayoutList,
  layoutList,
  allDimensionsList,
  deleteLayout,
  hasComment,
  setHasComment,
  createComment,
  removeNewComment,
  currencyData,
  isProgressLoading,
  showProgressBar,
}) => {
  // Feature flag
  const formatBaseNumber = useFeatureValue('format-number-table', 'Suma (Decimales)')

  // Hooks
  const { t } = useTranslation()
  let { reportId, periodId } = useParams()

  // State
  const [settings, setSettings] = useState({})
  const [localReferenceBase, setLocalReferenceBase] = useState(referenceBase)
  const [localReferenceComparison, setLocalReferenceComparison] = useState(referenceComparison)
  const [valueReferenceBase, setValueReferenceBase] = useState('REAL')
  const [showPopover, setShowPopover] = useState(false)
  const [favouriteName, setFavouriteName] = useState('')
  const [isSaving, setIsSaving] = useState(false)
  const [isLayoutLoading, setIsLayoutLoading] = useState(false)
  const [viewSelect, setViewSelect] = useState({})
  const [viewComment, setViewComment] = useState(false)

  // Values
  const { dataSource, linesOrder, linesTotal, linesRatio, linesRatioN } = dataComparativeByReport

  // Methods
  const handleComparisonSelection = (values) => {
    setReferenceComparison(values)
  }

  useEffect(() => {
    fetchAllDimensions()
  }, [fetchAllDimensions])

  useEffect(() => {
    setIsLayoutLoading(true)
    fetchLayoutList('reports', `${periodId}-${reportId}-variation`).then(() => {
      setIsLayoutLoading(false)
      setSettings({})
    })
  }, [fetchLayoutList, periodId, reportId])

  useEffect(() => {
    const defaultLayout = layoutList.values.find((el) => el.primary)
    setViewSelect(defaultLayout)
  }, [layoutList.values])

  useEffect(() => {
    let value = 'REAL'
    if (localReferenceBase.value === PROJECTION.REAL) {
      value = 'REAL'
    } else if (localReferenceBase.value === PROJECTION.BUDGET) {
      const findValue = groupProjection.BUDGET.find(
        (it) => it.id.toString() === localReferenceBase.id,
      )
      if (findValue) {
        value = `Presupuesto - ${findValue.name}`
      }
    } else if (localReferenceBase.value === PROJECTION.FORECAST) {
      const findValue = groupProjection.FORECAST.find(
        (it) => it.id.toString() === localReferenceBase.id,
      )
      if (findValue) {
        value = `Forecast - ${findValue.name}`
      }
    }

    const defaultTableOptions = {
      linesOrder: linesOrder,
      linesTotal: linesTotal,
      linesRatio: linesRatio,
      linesRatioN: linesRatioN,
      referenceBase: valueReferenceBase,
    }

    setValueReferenceBase(value)
    if (!_.isEmpty(settings)) {
      setSettings((prevState) => ({
        ...prevState,
        tableOptions: { ...prevState.tableOptions, ...defaultTableOptions, referenceBase: value },
      }))
    }
    // eslint-disable-next-line
  }, [
    localReferenceBase,
    groupProjection.BUDGET,
    groupProjection.FORECAST,
    linesOrder,
    linesTotal,
    linesRatio,
    linesRatioN,
    valueReferenceBase,
  ])

  useEffect(() => {
    if (layoutList.values.length > 0 && !_.isEmpty(viewSelect) && allDimensionsList.length > 0) {
      setSettings((prevState) => {
        return {
          ...prevState,
          ...viewSelect.config,
          cols:
            getColsAuxName(viewSelect.config.columns, allDimensionsList).length > 0
              ? getColsAuxName(viewSelect.config.columns, allDimensionsList)
              : ['date'],
          rows: getRowAuxName(viewSelect.config.rows, allDimensionsList),
          aggregatorName: DEFAULT_FORMAT_SET[viewSelect.config.default_format],
          vals: [viewSelect.config.default_value],
        }
      })
    }
  }, [allDimensionsList, layoutList.values, viewSelect])

  const onClose = () => {
    setIsSaving(false)
    setShowPopover(false)
    setFavouriteName('')
  }

  const onCreate = () => {
    const data = {
      row_order: 0,
      column_order: 1,
      default_value: settings.vals[0],
      default_format: DEFAULT_FORMAT_CREATE[settings.aggregatorName],
      rows: getRowAuxId(settings, allDimensionsList),
      columns: getColsAuxId(settings, allDimensionsList),
    }

    return createLayout('reports', `${periodId}-${reportId}-variation`, favouriteName, {
      config: data,
    })
  }

  const defaultData = !_.isEmpty(viewSelect) && {
    ...viewSelect.config,
    rows: getRowAuxName(viewSelect.config.rows, allDimensionsList),
    columns: getColsAuxName(viewSelect.config.columns, allDimensionsList),
    default_format: DEFAULT_FORMAT_SET[viewSelect.config.default_format],
  }

  const defaultSettings = !_.isEmpty(settings) && {
    rows: settings?.rows,
    columns: settings?.cols || ['Fecha', 'Proyecciones'],
    row_order: settings?.row_order,
    column_order: settings?.column_order,
    default_value: settings?.vals ? settings.vals[0] : '',
    default_format: settings?.aggregatorName,
  }

  const onSavePrimary = (data) => {
    const dataAux = layoutList.values.find((el) => el.name === data.name)
    return createLayout('reports', `${periodId}-${reportId}-variation`, dataAux.name, {
      ...dataAux,
      primary: true,
    })
  }

  const setReferenceBase_ = (value) => {
    setLocalReferenceBase(value)
    setReferenceBase(value)
  }

  const setReferenceComparison_ = (value) => {
    setLocalReferenceComparison(value)
  }

  const handleCreate = ({ text, usersIds, idComment = null, setIsNewThread }) => {
    const dataa = {
      text,
      key: `${commentKey}-table-variation`,
      module: commentKey,
      mentions: usersIds,
      ...(idComment && {
        parent: idComment,
      }),
    }
    setIsSaving(true)
    createComment(dataa)
      .then(() =>
        onRefetchComments().then(() => {
          message.success(t('FEEDBACK_SAVE_CHANGES_SUCCES'), 8)
          setIsSaving(false)
          setIsNewThread(false)
        }),
      )
      .catch((error) => {
        message.error(generateMessageError(error, 'FEEDBACK_SAVE_CHANGES_FAIL_MESSAGE'), 8)
        setIsSaving(false)
        setIsNewThread(false)
      })
  }

  const renderTable = () => {
    return (
      <Spin spinning={loading}>
        <Dropdown
          trigger={['contextMenu']}
          overlay={
            <Menu>
              {GENERAL.OPTION_COMMENT.map((el) => (
                <Menu.Item
                  key={el.key}
                  icon={<CommentOutlined />}
                  onClick={() => {
                    setViewComment(true)
                    setHasComment({ type: 'table', data: !hasComment.table })
                  }}
                >
                  {t(el.label)}
                </Menu.Item>
              ))}
            </Menu>
          }
        >
          <div style={{ width: '100%' }}>
            <PivotTableUX
              renderers={Object.assign({}, PivotTableRenderers)}
              data={dataSource}
              onChange={(s) => setSettings(s)}
              vals={settings.default_value ? [settings.default_value] : ['amount']}
              cols={['Fecha', 'Proyecciones']}
              rows={['Linea de reporte']}
              aggregatorName={settings.default_format || formatBaseNumber}
              hiddenFromDragDrop={['amount', 'price', 'quantity']}
              hiddenFromAggregators={
                dataSource[0] &&
                dataSource[0].filter((el) => !['amount', 'price', 'quantity'].includes(el))
              }
              tableOptions={{
                linesOrder: linesOrder,
                linesTotal: linesTotal,
                linesRatio: linesRatio,
                linesRatioN: linesRatioN,
                referenceBase: valueReferenceBase,
              }}
              tableFilter={{}}
              plotlyConfig={{
                responsive: true,
              }}
              {...settings}
            />
          </div>
        </Dropdown>
      </Spin>
    )
  }

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Space direction="horizontal">
          <SelectType.EvolutionSelect
            loading={loading}
            groupProjection={groupProjection}
            activeOptionTable={localReferenceBase}
            setActiveByPeriodTable={setReferenceBase_}
          />
          <SelectType.ComparisonVariantSelect
            loading={loading}
            groupProjection={groupProjection}
            baseReference={localReferenceBase}
            selectedOptions={localReferenceComparison}
            setSelectedOptions={setReferenceComparison_}
          />
          <Button.Primary
            onClick={() => handleComparisonSelection(localReferenceComparison)}
            title="ACTION_APPLY"
          />
        </Space>
      </Col>
      <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
        {showProgressBar && <ProgressBar isLoading={isProgressLoading || loading} />}
      </Col>
      <Col span={24}>
        <Alert message={t('REPORT_VARIANT_INFORMATIVE_ALERT_DESCRIPTION')} type="info" showIcon />
      </Col>
      <Col span={24}>
        <TableActions
          onClickSaveFav={() => setShowPopover(true)}
          visibleFav={showPopover}
          onClickSave={() =>
            handleSave({
              setIsLoading: setIsSaving,
              createLayout: () => onCreate(),
              onRefetch: () => fetchLayoutList('reports', `${periodId}-${reportId}-variation`),
              onRefresh: onRefetchComparative,
              onClose,
            })
          }
          onClickCancelSaveFav={onClose}
          setFavouriteName={setFavouriteName}
          favouriteName={favouriteName}
          isLoading={isSaving}
          hasFavourite={layoutList.values.length > 0}
          options={layoutList.values.map((el) => {
            return { name: el.name, id: el.name, primary: el.primary }
          })}
          canSave={
            !_.isEmpty(settings) ||
            (!_.isEmpty(settings) && !_.isEqual(defaultData, defaultSettings))
          }
          viewSelect={viewSelect}
          setViewSelect={(val) => {
            const aux = layoutList.values.find((el) => el.name === val)
            setViewSelect(aux)
          }}
          alreadyExist={_.isEqual(defaultData, defaultSettings)}
          layoutLoading={isLayoutLoading}
          setPrimary={(data) =>
            handleSavePrimary({
              setIsLoading: setIsSaving,
              createLayout: () => onSavePrimary(data),
              onRefetch: () => fetchLayoutList('reports', `${periodId}-${reportId}-variation`),
              onClose: () => setIsSaving(false),
            })
          }
          onClickDeleteFav={(name) =>
            handleDelete({
              setIsLoading: setIsSaving,
              deleteLayout: () =>
                deleteLayout('reports', `${periodId}-${reportId}-variation`, name),
              onRefetch: () => fetchLayoutList('reports', `${periodId}-${reportId}-variation`),
              onClose: () => setIsSaving(false),
            })
          }
          showComments={
            viewComment ||
            dataComments.filter((el) => el.key === `${commentKey}-table-comparison` && !el.resolved)
              .length > 0
          }
          hasComment={hasComment}
          setHasComment={setHasComment}
          setViewComment={setViewComment}
          settings={settings}
          setDataComments={setDataComments}
          dataComments={dataComments.filter(
            (el) => (el.key === `${commentKey}-table-comparison` && !el.resolved) || !el.text,
          )}
          onRefetchComments={onRefetchComments}
          loadingComments={loadingComments}
          onCommentCreate={(text, usersIds, idComment, setIsNewThread) =>
            handleCreate({ text, usersIds, idComment, setIsNewThread })
          }
          removeNewComment={removeNewComment}
          currencyData={currencyData}
        />
      </Col>
      <Col span={24}>{renderTable()}</Col>
    </Row>
  )
}

const mapStateToProps = (state) => ({
  dataComparativeByReport: reports.selectors.getDataComparativeByReport(state),
  layoutList: control.selectors.getLayoutList(state),
  allDimensionsList: configuration.selectors.getAllDimensionsList(state),
  hasComment: configuration.selectors.getHasComment(state),
})

const mapDispatchToProps = {
  createLayout: control.actions.createLayout,
  fetchLayoutList: control.actions.fetchLayoutList,
  fetchAllDimensions: configuration.actions.fetchAllDimensions,
  deleteLayout: control.actions.deleteLayout,
  setHasComment: configuration.actions.setHasComment,
  createComment: configuration.actions.createComment,
  removeNewComment: configuration.actions.removeNewComment,
}

export default connect(mapStateToProps, mapDispatchToProps)(Variation)
