import { notification } from 'antd'
import i18n from 'i18next'
import { generateMessageError } from 'modules/core/utils'

export const onPublishApprove = ({
  setIsLoading,
  onAction,
  onRefetch,
  onClose,
  isActionApprove,
}) => {
  setIsLoading(true)
  onAction()
    .then(() => {
      onRefetch()
      onClose()
    })
    .catch((error) => {
      notification.error({
        message: i18n.t('FEEDBACK_DEFAULT_ERROR'),
        description: generateMessageError(error, 'FEEDBACK_ACTION_DEFAULT_ERROR', {
          action: isActionApprove
            ? i18n.t('ACTION_APPROVE').toLowerCase()
            : i18n.t('ACTION_PUBLISH').toLowerCase(),
        }),
        duration: 0,
      })
      onClose()
    })
}
