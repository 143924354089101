import React, { useCallback } from 'react'
import { Comments, DropdownMenu, Typography } from 'modules/core/components'
import { useTranslation } from 'react-i18next'
import { ExportOutlined } from '@ant-design/icons'
import { CHARTS } from 'modules/core/constants'
import { executeExportAsImage } from 'modules/core/utils'
import { CommentOutlined } from '@ant-design/icons'
import _ from 'lodash'

const ExtraActionsChart = ({
  chartRef,
  exportTitle,
  showComment,
  hasComment,
  setHasComment,
  setViewComment,
  userDetail,
  dataComments,
  setDataComments,
  loadingComments,
  onRefetchComments,
  onCommentCreate,
  onCleanHasComment,
  removeNewComment,
}) => {
  const { t } = useTranslation()
  const onExportChart = useCallback((props) => {
    executeExportAsImage({ ...props })
  }, [])

  const key = window.location.pathname.replaceAll('/', '-')

  return (
    <>
      {showComment && (
        <Comments
          hasComment={
            !_.isEmpty(hasComment?.chart)
              ? !_.isEmpty(hasComment?.chart[key]) && hasComment?.chart[key]['0']
              : false
          }
          onClick={(visible) => {
            setHasComment({ type: 'chart', data: visible, key, id: 0 })
            !visible && removeNewComment()
            !visible && setViewComment(visible)
          }}
          comments={dataComments}
          setData={setDataComments}
          loading={loadingComments}
          onRefetchComments={onRefetchComments}
          onCommentCreate={onCommentCreate}
          onCleanHasComment={onCleanHasComment}
        >
          <Typography.Icon
            style={{ cursor: 'pointer' }}
            title={t('LABEL_COMMENTS')}
            icon={CommentOutlined}
          />
        </Comments>
      )}
      <DropdownMenu
        title={t('ACTION_MORE')}
        placement="bottomRight"
        menu={[
          {
            title: t('ACTION_EXPORT'),
            icon: <ExportOutlined />,
            submenu: CHARTS.EXPORT_FORMAT_TYPE_OPTIONS.map((formatType) => {
              return {
                title: formatType.name,
                key: formatType.id,
                onClick: () =>
                  onExportChart({
                    chartRef: chartRef.current,
                    format: formatType.id,
                    onExport: formatType.onAction,
                    props: formatType.props,
                    title: exportTitle,
                  }),
              }
            }),
          },
        ]}
      />
    </>
  )
}

export default ExtraActionsChart
