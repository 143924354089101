import React from 'react'
import { Popconfirm, Tooltip } from 'antd'
import { ButtonActionBar, Comments, DropdownMenu, Typography } from 'modules/core/components'
import { useTranslation } from 'react-i18next'
import {
  InfoCircleOutlined,
  EditOutlined,
  DeleteOutlined,
  CommentOutlined,
  HolderOutlined,
} from '@ant-design/icons'
import _ from 'lodash'

const ExtraActions = ({
  onCancel,
  visible,
  onClickEdit,
  onClickDelete,
  onDeleteConfirmed,
  hasComment,
  setHasComment,
  viewComment,
  setViewComment,
  loggedUser,
  className,
  attributes,
  listeners,
  tagStyle,
  showComments,
  setDataComments,
  comments,
  onRefetchComments,
  loadingComments,
  onCommentCreate,
  cardId,
  removeNewComment,
}) => {
  const { t } = useTranslation()

  const key = window.location.pathname.replaceAll('/', '-')

  return (
    <ButtonActionBar>
      <Tooltip
        title={t('FIELD_CREATOR_INFO', {
          username: '',
        })}
      >
        <Typography.Icon icon={InfoCircleOutlined} style={{ marginLeft: 5 }} />
      </Tooltip>
      {showComments && (
        <Comments
          hasComment={
            !_.isEmpty(hasComment?.card)
              ? !_.isEmpty(hasComment?.card[key]) && hasComment?.card[key][cardId]
              : false
          }
          onClick={(visible) => {
            setHasComment({ type: 'card', data: visible, key, id: cardId })
            !visible && removeNewComment()
            !visible && setViewComment(visible)
          }}
          comments={comments}
          setData={setDataComments}
          loading={loadingComments}
          onRefetchComments={onRefetchComments}
          onCommentCreate={onCommentCreate}
          onCleanHasComment={() => {
            setHasComment({ type: 'card', data: false, key, id: cardId })
            setViewComment(false)
          }}
        >
          <Typography.Icon
            style={{ cursor: 'pointer' }}
            title={t('LABEL_COMMENTS')}
            icon={CommentOutlined}
          />
        </Comments>
      )}
      <Popconfirm
        placement="bottomRight"
        title={t('PERIOD_DELETE_CONFIRM_TEXT')}
        okText={t('ACTION_DELETE')}
        // okButtonProps={{ loading, }}
        onConfirm={onDeleteConfirmed}
        cancelText={t('ACTION_CANCEL')}
        onCancel={onCancel}
        visible={visible}
      >
        <DropdownMenu
          title={t('ACTION_MORE')}
          menu={[
            {
              title: t('ACTION_EDIT_NAME'),
              icon: <EditOutlined />,
              onClick: onClickEdit,
              disabled: false,
            },

            {
              title: t('ACTION_DELETE'),
              icon: <DeleteOutlined />,
              disabled: false,
              onClick: onClickDelete,
            },
          ]}
        />
      </Popconfirm>
      <div className={className} {...attributes} {...listeners} style={tagStyle}>
        <Typography.Icon title={t('ACTION_SORT')} icon={HolderOutlined} />
      </div>
    </ButtonActionBar>
  )
}

export default ExtraActions
