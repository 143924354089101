import { PushpinOutlined, PushpinFilled } from '@ant-design/icons'
import { Button, Typography } from 'modules/core/components'

const ButtonFix = ({ isFixed, setFixed }) => {
  return (
    <Button.Icon
      style={isFixed ? { cursor: 'default' } : {}}
      icon={<Typography.Icon icon={isFixed ? PushpinFilled : PushpinOutlined} />}
      onClick={!isFixed && setFixed}
    />
  )
}

export default ButtonFix
