import React from 'react'
import { Col, Row, Space, Tabs } from 'antd'
import { Typography } from 'modules/core/components'
import { useQuery } from 'modules/core/customHooks'
import { PrivacyPolicy, TermsAndConditions } from './components'
import { useTranslation } from 'react-i18next'
import logoCompleto from 'assets/images/logo/logo.svg'

import './PolicyAndTermsHome.scss'

const POLICY_KEY = 'policy'
const TERMS_KEY = 'terms'

const PolicyAndTermsHome = () => {
  const { t } = useTranslation()
  let query = useQuery()

  return (
    <div className="policy-terms-container">
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Space direction="horizontal">
            <img alt="brand-logo" src={logoCompleto} />
            <Typography.Subtitle>{t('FIELD_DOCUMENTATION')}</Typography.Subtitle>
          </Space>
        </Col>
        <Col span={24}>
          <Tabs
            size="small"
            defaultActiveKey={query.get('tab') !== null ? query.get('tab') : POLICY_KEY}
          >
            <Tabs.TabPane tab={t('SECURITY_POLICY_PRIVACY_POLICY_TAB_TITLE')} key={POLICY_KEY}>
              <PrivacyPolicy />
            </Tabs.TabPane>
            <Tabs.TabPane tab={t('SECURITY_POLICY_TERMS_CONDITIONS_TAB_TITLE')} key={TERMS_KEY}>
              <TermsAndConditions />
            </Tabs.TabPane>
          </Tabs>
        </Col>
      </Row>
    </div>
  )
}

export default PolicyAndTermsHome
