import { Button, ButtonActionBar, DeleteSelectionButton, Typography } from 'modules/core/components'
import { DownloadOutlined } from '@ant-design/icons'

const TableActions = ({
  dataSelection,
  onConfirmDelete,
  downloadUserList,
  disabled,
  isDeleting,
}) => {
  return (
    <ButtonActionBar position={dataSelection.length > 0 ? 'space-between' : 'end'}>
      {dataSelection.length > 0 && (
        <DeleteSelectionButton
          onConfirm={onConfirmDelete}
          disabled={disabled}
          isDeleting={isDeleting}
        />
      )}
      <Button.Icon
        title="ACTION_DOWNLOAD_TABLE"
        icon={<Typography.Icon icon={DownloadOutlined} />}
        onClick={downloadUserList}
      />
    </ButtonActionBar>
  )
}

export default TableActions
