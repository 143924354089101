import { Modal } from 'antd'

const VideoModal = ({ visible, onClose, srcVideo }) => {
  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      footer={null}
      width={770}
      centered
      destroyOnClose
      forceRender
    >
      <iframe
        width="700"
        height="415"
        src={srcVideo}
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerpolicy="strict-origin-when-cross-origin"
        allowfullscreen
      ></iframe>
    </Modal>
  )
}

export default VideoModal
