import { ROLES } from 'modules/core/constants'
import { getOperationsByKeys, isUserAllowed } from 'modules/core/utils'

const {
  // PLANNING,
  CONTROL,
  // FORECAST,
  // ANALYSIS,
} = ROLES

export const onEnterPeriod = (periodId, history) => {
  // if (isUserAllowed(getOperationsByKeys([PLANNING]))) {
  //   return history.push(`/planificacion/${periodId}`);
  // } else
  if (isUserAllowed(getOperationsByKeys([CONTROL]))) {
    return history.push(`/seguimiento/${periodId}`)
  } else {
    return null
  }
  // else if (isUserAllowed(getOperationsByKeys([FORECAST]))) {
  //   return history.push(`/forecast/${periodId}`);
  // } else if (isUserAllowed(getOperationsByKeys([ANALYSIS]))) {
  //   return history.push(`/analisis/${periodId}`);
}
