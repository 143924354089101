import { message, notification } from 'antd'
import { generateMessageError } from 'modules/core/utils'
import i18n from 'i18next'

export const onCreate = ({ form, setIsLoading, createDashboardElement, onRefetch, onClose }) => {
  form.validateFields().then((values) => {
    setIsLoading(true)
    const { value, dimension, report_line, ...restProps } = values

    const data = {
      ...restProps,
      values: {
        ...(dimension && { dimension }),
        ...(report_line && { report_line }),
        value,
      },
    }

    createDashboardElement(data)
      .then(() =>
        onRefetch().then(() => {
          message.success(i18n.t('FEEDBACK_CHANGES_SAVED_SUCCESS'), 8)
          onClose()
        }),
      )
      .catch((error) => {
        notification.error({
          message: i18n.t('FEEDBACK_DEFAULT_ERROR'),
          description: generateMessageError(error, 'FEEDBACK_SAVE_CHANGES_FAIL'),
          duration: 0,
        })
        onClose()
      })
  })
}

export const onEdit = ({ form, setIsLoading, editDashboardElement, onRefetch, onClose }) => {
  form.validateFields().then((values) => {
    setIsLoading(true)
    editDashboardElement(values)
      .then(() =>
        onRefetch().then(() => {
          message.success(i18n.t('FEEDBACK_CHANGES_SAVED_SUCCESS'), 8)
          onClose()
        }),
      )
      .catch((error) => {
        notification.error({
          message: i18n.t('FEEDBACK_DEFAULT_ERROR'),
          description: generateMessageError(error, 'FEEDBACK_SAVE_CHANGES_FAIL'),
          duration: 0,
        })
        onClose()
      })
  })
}
