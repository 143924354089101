import { Col, Collapse, Row } from 'antd'
import { DndContext } from '@dnd-kit/core'
import { restrictToVerticalAxis, restrictToFirstScrollableAncestor } from '@dnd-kit/modifiers'
import { ButtonApply, ContainerItems } from './components'
import { handleApply, handleDragEnd } from './utils'
import { connect } from 'react-redux'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import configuration from 'modules/configuration'

import './ColumnsAndRowsSelections.scss'

const { Panel } = Collapse

const ColumnsAndRowsSelections = ({
  onRefresh,
  params,
  period_id,
  concept_id = null,
  setPagination,
  dataColumnsRows,
  fetchColumnsAndRowsSelections,
  createColumnsRows,
  setDataColumnsRows,
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const { t } = useTranslation()

  useEffect(() => {
    fetchColumnsAndRowsSelections(params)
    // eslint-disable-next-line
  }, [fetchColumnsAndRowsSelections, params.step])

  const onCreate = () => {
    const endpointParams = {
      ...(concept_id && {
        concept_id,
      }),
      ...params,
    }
    return createColumnsRows({
      rows: dataColumnsRows[0].options,
      columns: dataColumnsRows[1].options,
      period_id,
      ...endpointParams,
    })
  }

  return (
    <Collapse bordered={false}>
      <Panel header={t('LABEL_VISUALIZATION_TABLE')} key="Tabla">
        <Row gutter={[24, 24]} className="col-row-style">
          {dataColumnsRows.map((infoContainer) => (
            <Col span={8} key={infoContainer.id}>
              <DndContext
                modifiers={[restrictToVerticalAxis, restrictToFirstScrollableAncestor]}
                onDragEnd={(event) => handleDragEnd({ event, dataColumnsRows, setDataColumnsRows })}
              >
                <ContainerItems dataColumnsRows={dataColumnsRows} infoContainer={infoContainer} />
              </DndContext>
            </Col>
          ))}
          <Col span={24}>
            <ButtonApply
              onClick={() =>
                handleApply({
                  setIsLoading,
                  createColumnsRows: () => onCreate(),
                  setPagination,
                  onRefresh,
                  onClose: () => setIsLoading(false),
                })
              }
              loading={isLoading}
            />
          </Col>
        </Row>
      </Panel>
    </Collapse>
  )
}

const mapStateToProps = (state) => ({
  dataColumnsRows: configuration.selectors.getDataColumnsRows(state),
})

const mapDispatchToProps = {
  fetchColumnsAndRowsSelections: configuration.actions.fetchColumnsAndRowsSelections,
  createColumnsRows: configuration.actions.createColumnsRows,
  setDataColumnsRows: configuration.actions.setDataColumnsRows,
}

export default connect(mapStateToProps, mapDispatchToProps)(ColumnsAndRowsSelections)
