import { Popover } from 'antd'
import { Button, Typography } from 'modules/core/components'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { ContentHelp } from './components'

const ButtonHelp = ({ open, onOpenChange, onClick, exception_path }) => {
  return (
    <Popover
      placement="rightBottom"
      trigger="click"
      open={open}
      onOpenChange={onOpenChange}
      content={<ContentHelp onClick={onClick} exception_path={exception_path} />}
    >
      <Button.Icon title="LABEL_HELP" icon={<Typography.Icon icon={QuestionCircleOutlined} />} />
    </Popover>
  )
}

export default ButtonHelp
