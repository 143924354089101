//recibe los reportes y al filtrar por el valor agrega los kpis que coinciden al array 'Results'
export const functionSearch = ({ values, searchValue }) => {
  const results = []
  values.forEach((value) => {
    if (value.name.toLowerCase().includes(searchValue.toLowerCase())) {
      results.push(value)
    }
  })
  return results
}
