const DATA_LINKS = [
  {
    id: 1,
    href: '#informacion-personal',
    title: 'SECURITY_POLICY_PRIVACY_SUBTITLE_1',
  },
  {
    id: 2,
    href: '#procesamos-datos',
    title: 'SECURITY_POLICY_PRIVACY_SUBTITLE_2',
  },
  {
    id: 3,
    href: '#tus-derechos',
    title: 'SECURITY_POLICY_PRIVACY_SUBTITLE_3',
  },
  {
    id: 4,
    href: '#enlaces-sitios',
    title: 'SECURITY_POLICY_PRIVACY_SUBTITLE_4',
  },
  {
    id: 5,
    href: '#seguridad-informacion',
    title: 'SECURITY_POLICY_PRIVACY_SUBTITLE_5',
  },
  {
    id: 6,
    href: '#divulgacion-legal',
    title: 'SECURITY_POLICY_PRIVACY_SUBTITLE_6',
  },
  {
    id: 7,
    href: '#informacion-contacto',
    title: 'SECURITY_POLICY_PRIVACY_SUBTITLE_7',
  },
]

export const dataLinks = DATA_LINKS
