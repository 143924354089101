import { useEffect, useState } from 'react'
import { client } from 'httpclient'
import store from 'store'

const useDefaultCurrency = () => {
  const [currencyData, setCurrencyData] = useState(store.getState().configuration.currencyList.data)

  useEffect(() => {
    client.get(`/currency/list_currency/`).then((response) => {
      setCurrencyData(response.data)
    })
  }, [])

  const defaultCurrency =
    currencyData.length > 0 ? currencyData.find((currency) => currency.is_default) : null

  return defaultCurrency
}

export default useDefaultCurrency
