import { message } from 'antd'
import { generateMessageError } from 'modules/core/utils'
import i18n from 'i18next'

export const handleSave = ({ setIsLoading, saveVariableValues, onClose }) => {
  setIsLoading(true)
  saveVariableValues()
    .then(() => {
      message.success(i18n.t('FEEDBACK_CHANGES_SAVED_SUCCESS'), 8)
      onClose()
    })
    .catch((error) => {
      message.error(generateMessageError(error, 'FEEDBACK_SAVE_CHANGES_FAIL'), 8)
      onClose()
    })
}

export const handleAction = ({ setIsLoading, action, onRefetchRules, text, onClose }) => {
  setIsLoading(true)
  action()
    .then(() =>
      onRefetchRules().then(() => {
        setTimeout(() => {
          message.success(i18n.t('PLANNING_RULES_APPLY_SUCCESS_FEEDBACK', { action: text }), 8)
          onClose()
        }, 1000)
      }),
    )
    .catch((error) => {
      message.error(
        generateMessageError(error, 'PLANNING_RULES_APPLY_ERROR_FEEDBACK', { action: text }),
        8,
      )
      onClose()
    })
}
