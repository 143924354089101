import { Space, Input } from 'antd'
import { ButtonActionBar } from 'modules/core/components'
import { useTranslation } from 'react-i18next'
import CreateButton from '../CreateButton/CreateButton'

const { Search } = Input

const UserActions = ({ setSearchValue, disabledCreateButton, onClickCreateButton }) => {
  const { t } = useTranslation()
  return (
    <ButtonActionBar>
      <Space size="middle">
        <Search placeholder={t('ACTION_SEARCH')} allowClear={true} onSearch={setSearchValue} />
        <CreateButton disabled={disabledCreateButton} onClick={onClickCreateButton} />
      </Space>
    </ButtonActionBar>
  )
}

export default UserActions
