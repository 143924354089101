import React from 'react'
import { Modal, Table } from 'antd'
import { useTranslation } from 'react-i18next'
import { Button } from 'modules/core/components'
import { connect } from 'react-redux'
import { onDownload } from './utils'
import moment from 'moment'
import processes from 'modules/processes'

const ProcessesTable = ({
  loading,
  dataSource,
  onChange,
  pagination,
  count,
  downloadProcessFile,
}) => {
  const { t } = useTranslation()

  const renderModal = (message) =>
    Modal.info({
      title: t('FIELD_OBSERVATIONS'),
      content: <div style={{ overflowY: 'auto', maxHeight: 400 }}>{message}</div>,
      onOk() {},
    })

  const columns = [
    {
      title: t('LABEL_USER'),
      dataIndex: 'user',
    },
    {
      title: t('FIELD_DATE'),
      dataIndex: 'uploaded_at',
      render: (date) => moment(date).calendar(),
    },
    {
      title: t('LABEL_PROCESS_START'),
      dataIndex: 'date_process_start',
      render: (date) => moment(date).calendar(),
    },
    {
      title: t('LABEL_PROCESS_END'),
      dataIndex: 'date_process_end',
      render: (date) => moment(date).calendar(),
    },
    {
      title: t('LABEL_FILE'),
      ellipsis: true,
      dataIndex: 'file',
      width: 200,
      render: (data) => {
        return (
          <Button.Link
            title={data.name_file}
            onClick={() =>
              onDownload({
                downloadProcessFile,
                dataFile: data,
              })
            }
          />
        )
      },
    },
    {
      title: t('LABEL_BUDGET_TYPE'),
      dataIndex: 'concept',
    },
    {
      title: t('FIELD_STATUS'),
      dataIndex: 'status',
    },
    {
      title: t('FIELD_LOAD_TYPE'),
      dataIndex: 'upload_from',
    },
    {
      title: t('FIELD_OBSERVATIONS'),
      dataIndex: 'message',
      render: (message, row) => {
        if (message) {
          return (
            <Button.Link title={t('ACTION_OPEN_DETAIL')} onClick={() => renderModal(message)} />
          )
        }
        return ''
      },
    },
  ]

  return (
    <Table
      rowKey="id"
      size="small"
      bordered
      columns={columns}
      loading={loading}
      dataSource={dataSource}
      onChange={onChange}
      pagination={{
        pageSize: pagination.pageSize,
        size: 'small',
        total: count,
        current: pagination.page,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '30'],
      }}
    />
  )
}

const mapDispatchToProps = {
  downloadProcessFile: processes.actions.downloadProcessFile,
}

export default connect(null, mapDispatchToProps)(ProcessesTable)
