import React from 'react'
import { Space, Input, Popconfirm } from 'antd'
import { Button, ButtonActionBar, DropdownMenu } from 'modules/core/components'
import { useTranslation } from 'react-i18next'
import { DeleteOutlined, SolutionOutlined } from '@ant-design/icons'

const { Search } = Input

const UserActions = ({
  setSearchValue,
  onClick,
  disabled,
  onClickDeleteAll,
  loading,
  onConfirmPopConfirm,
  onCancelPopConfirm,
  visible,
  onClickActivity,
}) => {
  const { t } = useTranslation()
  return (
    <ButtonActionBar>
      <Space>
        <Search
          className="dimension-search"
          placeholder={t('ACTION_SEARCH')}
          allowClear={true}
          onSearch={setSearchValue}
        />
        <Button.Primary onClick={onClick} disabled={disabled} title="ACTION_CREATE_NEW" />
        <DropdownMenu
          title={t('ACTION_MORE')}
          menu={[
            {
              title: t('ACTION_DELETE_ALL'),
              icon: <DeleteOutlined />,
              onClick: onClickDeleteAll,
            },
            {
              title: t('ACTION_ACTIVITIES'),
              icon: <SolutionOutlined />,
              onClick: onClickActivity,
            },
          ]}
        />
        <Popconfirm
          title={t('POPCONFIRM_DELETE_ALL_PAGES')}
          placement="right"
          okText={t('ACTION_DELETE')}
          okButtonProps={{ loading }}
          cancelButtonProps={{ disabled: loading }}
          onConfirm={onConfirmPopConfirm}
          cancelText={t('ACTION_CANCEL')}
          onCancel={onCancelPopConfirm}
          visible={visible}
        />
      </Space>
    </ButtonActionBar>
  )
}

export default UserActions
