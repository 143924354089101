import React from 'react'
import { Input, Form } from 'antd'
import { Typography } from '../components'
import i18n from 'i18next'
import brandWhite from 'assets/images/logo/brand-black.svg'

const FormItem = Form.Item

const regexPasswordValidator =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[[\]\-/$@=#_(){}.!¿¡%*?&])([A-Za-z\d[\]\-/$@=#_(){}.!¿¡%*?&]|[^ ]){8,}$/

export const renderLoginAndResetFormTitle = ({ title, level, align = '' }) => (
  <Typography.Headline level={level} align={align ? align : ''}>
    {i18n.t(title)}
  </Typography.Headline>
)

export const renderLoginWelcome = () => (
  <>
    <img className="brand-logo" alt="brand-logo" src={brandWhite} />
    {renderLoginAndResetFormTitle({
      title: 'LOGIN_WELLCOME_TITLE',
      level: 3,
    })}
    {renderLoginAndResetFormTitle({
      title: 'LOGIN_WELLCOME_SUBTITLE',
      level: 5,
    })}
  </>
)

export const renderPasswordFormItem = ({ ...props }) => (
  <>
    <FormItem
      label={i18n.t('LOGIN_FORM_CONFIRM_PASSWORD_LABEL_NEW')}
      name="password"
      rules={[
        {
          required: true,
          message: i18n.t('LOGIN_FORM_INPUT_A_PASSWORD_CAPTION_EMPTY'),
        },
        // { validator: validateToConfirmPassword },
        ({ getFieldValue }) => ({
          validator(_, value) {
            regexPasswordValidator.test(value)
            if (
              (!value || getFieldValue('old_password') !== value) &&
              regexPasswordValidator.test(value)
            ) {
              return Promise.resolve()
            }
            if (getFieldValue('old_password') === value) {
              return Promise.reject(new Error(i18n.t('LOGIN_FORM_PASSWORD_NOT_DIFFERENT')))
            }
            if (value && !regexPasswordValidator.test(value)) {
              return Promise.reject(new Error(i18n.t('LOGIN_FORM_PASSWORD_VALIDATOR_FEEDBACK')))
            }
            if (!value) {
              return Promise.reject(new Error())
            }
          },
        }),
      ]}
    >
      <Input.Password {...props} placeholder={i18n.t('LOGIN_FORM_CONFIRM_PASSWORD_LABEL_NEW')} />
    </FormItem>
    <FormItem
      label={i18n.t('LOGIN_FORM_CONFIRM_PASSWORD_RECONFIRM')}
      name="password2"
      rules={[
        {
          required: true,
          message: i18n.t('LOGIN_FORM_INPUT_A_PASSWORD_CAPTION_EMPTY'),
        },
        ({ getFieldValue }) => ({
          validator(_, value) {
            if (!value || getFieldValue('password') === value) {
              return Promise.resolve()
            }
            return Promise.reject(new Error(i18n.t('LOGIN_FORM_PASSWORD_NOT_SAME')))
          },
        }),
      ]}
    >
      <Input.Password {...props} placeholder={i18n.t('LOGIN_FORM_CONFIRM_PASSWORD_RECONFIRM')} />
    </FormItem>
  </>
)
