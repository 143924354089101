import * as types from './constants'

export const fetchQuestionList =
  (params = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.FETCH_QUESTION_LIST,
      payload: {
        request: {
          url: `/common/questions/`,
          method: 'GET',
          params,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const fetchValuesList =
  (params = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.FETCH_VALUES_LIST,
      payload: {
        request: {
          url: `/common/values/`,
          method: 'GET',
          params,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }
