import { updateCommentInReducer } from 'modules/core/utils'
import Immutable from 'seamless-immutable'
import { ROWS } from '../core/constants'
import {
  FETCH_DATA_COMPARATIVE_BY_OVERALL_FORECAST_SUCCESS,
  FETCH_DATA_EVOLUTION_BY_OVERALL_FORECAST_SUCCESS,
  FETCH_DATE_BLOCKING_BY_FORECAST_SUCCESS,
} from './constants'
import * as types from './constants'

const initialState = Immutable({
  forecasts: {
    count: 0,
    values: [],
  },
  cutDates: [],
  forecastInstanceList: {
    count: 0,
    values: [],
  },
  forecastInstanceDetail: {},
  //Todavía no están construído desde backend
  forecastKPIS: [],
  forecastCreatable: {
    creatable: true,
    message: '',
  },
  projections: [],
  forecastKPIs: [],
  dataNestedTablePhase: {
    data: [],
    fields: [],
    meta: [],
    // columns:[],
    // rows:[],
    // num_pages:1
  },
  globalForecastEvolutionTable: {
    columns: [],
    rows: [],
  },
  globalForecastList: {
    count: 0,
    values: [],
  },
  globalForecastComparisonTable: [],
  globalForecastChart: [],
  globalForecastDetail: {},
  globalForecastPublishApprove: [],
  globalForecastFilterList: {
    count: 0,
    values: [],
  },
  dateBlocking: [],
  dataEvolutionForecastReport: {
    dataSource: [],
    fields: [],
    meta: [],
    linesOrder: [],
    linesTotal: [],
    linesRatio: [],
    linesRatioN: [],
  },
  dataComparativeForecastReport: {
    dataSource: [],
    fields: [],
    meta: [],
    linesOrder: [],
    linesTotal: [],
    linesRatio: [],
    linesRatioN: [],
  },
  dataRdnPercentageForecast: {
    dataSource: [],
    fields: [],
    meta: [],
  },
  forecastFilterDates: {},
})

const addNewNode = (nodes, nodeId, newNode) => {
  let newNodes = []
  nodes.forEach((node) => {
    if (node.id === nodeId) {
      node = {
        ...node,
        children: newNode && newNode,
      }
    }
    if (node.id !== nodeId && node.children) {
      node = {
        ...node,
        children: addNewNode(node.children, nodeId, newNode),
      }
    }
    newNodes.push(node)
  })
  return newNodes
}

/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_FORECASTS_SUCCESS:
      return state.merge({
        forecasts: {
          count: action.payload.data.length,
          values: action.payload.data,
        },
      })

    case types.FETCH_CUT_DATES_SUCCESS:
      const auxCutDates = action.payload.data
      return state.merge({
        cutDates: auxCutDates.sort(),
      })

    case types.FETCH_FORECAST_INSTANCE_LIST_SUCCESS:
      return state.merge({
        forecastInstanceList: {
          count: action.payload.data.length,
          values: action.payload.data,
        },
      })

    case types.FETCH_FORECAST_INSTANCE_DETAIL_SUCCESS:
      return state.merge({
        forecastInstanceDetail: action.payload.data,
      })

    case types.FETCH_FORECAST_PROJECTIONS_SUCCESS:
      return state.merge({
        projections: action.payload.data,
      })

    case types.FETCH_FORECAST_KPIS_SUCCESS:
      return state.merge({
        forecastKPIs: action.payload.data,
      })

    case types.FETCH_DATA_NESTED_TABLE_FORECAST_BY_PHASE_SUCCESS:
      // const page = action.meta.previousAction.payload.request.params.page
      // let auxrows =  page === 1 ? action.payload.data.rows : [...state.dataNestedTablePhase.rows, ...action.payload.data.rows]
      return state.merge({
        dataNestedTablePhase: {
          data: action.payload.data.data,
          fields: action.payload.data.fields,
          // columns: action.payload.data.colums,
          // rows: auxrows,
          // num_pages: action.payload.data.num_pages
        },
      })

    case types.FETCH_EXPAND_NESTED_TABLE_ROW_SUCCESS:
      const nestedparentId = action.payload.config.params.rowId
      let auxReportTableByPeriods = state.dataNestedTablePhase.rows.asMutable({
        deep: true,
      })
      return state.merge({
        dataNestedTablePhase: {
          ...state.dataNestedTablePhase,
          rows: addNewNode(auxReportTableByPeriods, nestedparentId, action.payload.data.rows),
        },
      })

    case types.FETCH_GLOBAL_FORECAST_PUBLISH_APPROVE_SUCCESS:
      return state.merge({
        globalForecastPublishApprove: action.payload.data.map((global) => {
          return { ...global, type: 'forecast' }
        }),
      })

    case types.FETCH_GLOBAL_FORECAST_EVOLUTION_TABLE_SUCCESS:
      return state.merge({
        globalForecastEvolutionTable: {
          ...action.payload.data,
          rows: action.payload.data.rows.map((row) => {
            return { ...row, children: row.type === 'totalizer' ? null : [] }
          }),
        },
      })

    case types.FETCH_FORECAST_EVOLUTION_TABLE_OPEN_ROW_SUCCESS:
      const parentId = action.payload.config.params.rowId
      let auxEvolutionTableRow = state.globalForecastEvolutionTable.rows.asMutable({
        deep: true,
      })

      return state.merge({
        globalForecastEvolutionTable: {
          ...state.globalForecastEvolutionTable,
          rows: addNewNode(auxEvolutionTableRow, parentId, action.payload.data),
        },
      })

    case types.FETCH_GLOBAL_FORECAST_LIST_SUCCESS:
      return state.merge({
        globalForecastList: {
          count: action.payload.data.length,
          values: action.payload.data,
        },
      })

    case types.FETCH_GLOBAL_FORECAST_FILTER_LIST_SUCCESS:
      return state.merge({
        globalForecastFilterList: {
          count: action.payload.data.length,
          values: action.payload.data,
        },
      })

    case types.FETCH_GLOBAL_FORECAST_COMPARISON_TABLE_SUCCESS:
      return state.merge({
        globalForecastComparisonTable: action.payload.data.map((row) => {
          return { ...row, children: row.type === 'totalizer' ? null : [] }
        }),
      })

    case types.FETCH_FORECAST_COMPARISON_TABLE_OPEN_ROW_SUCCESS:
      const idParent = action.payload.config.params.rowId
      let auxComparisonTableRow = state.globalForecastComparisonTable.asMutable({
        deep: true,
      })

      return state.merge({
        globalForecastComparisonTable: addNewNode(
          auxComparisonTableRow,
          idParent,
          action.payload.data,
        ),
      })

    case types.SET_DEFAULT_FORECAST_SUCCESS:
      const globalForecastId = action.payload.config.params.id
      return state.merge({
        globalForecastList: {
          ...state.globalForecastList,
          values: state.globalForecastList.values.map((budget) => {
            if (budget.id === globalForecastId) {
              return {
                ...budget,
                predefined: true,
              }
            }
            return budget
          }),
        },
      })

    case types.FETCH_GLOBAL_FORECAST_CHART_SUCCESS:
      return state.merge({
        globalForecastChart: action.payload.data,
      })

    case types.SYNC_CREATE_FORECAST_COMMENT: {
      const { reducerKey, ...data } = action.payload.data
      return state.merge(
        updateCommentInReducer({
          state,
          reducerKey,
          data,
        }),
      )
    }

    case types.SYNC_DELETE_FORECAST_COMMENT: {
      const { reducerKey, ...data } = action.payload.data
      return state.merge(
        updateCommentInReducer({
          state,
          reducerKey,
          data,
        }),
      )
    }

    case types.SYNC_EDIT_FORECAST_COMMENT: {
      const { reducerKey, ...data } = action.payload.data
      return state.merge(
        updateCommentInReducer({
          state,
          reducerKey,
          data,
        }),
      )
    }

    case types.FETCH_GLOBAL_FORECAST_DETAIL_SUCCESS:
      return state.merge({
        globalForecastDetail: action.payload.data,
      })

    case types.SET_FORECAST_ORDER:
      return state.merge({
        forecastInstanceList: {
          ...state.forecastInstanceList,
          values: action.payload,
        },
      })

    case types.FETCH_DATE_BLOCKING_BY_FORECAST_SUCCESS:
      console.log(action.payload)
      return state.merge({
        dateBlocking: action.payload.data.month_blocking ?? [],
      })

    case types.FETCH_DATA_EVOLUTION_BY_OVERALL_FORECAST_SUCCESS:
      const lines = action.payload.data.totalizer ?? []
      return state.merge({
        dataEvolutionForecastReport: {
          dataSource: action.payload.data.data,
          fields: action.payload.data.fields,
          meta: action.payload.data.meta,
          linesOrder: action.payload.data.order_columns,
          linesTotal: lines.filter((it) => it.type === ROWS.TOTALIZER),
          linesRatio: lines.filter((it) => it.type === ROWS.RATIO),
          linesRatioN: lines.filter((it) => it.type === ROWS.RATION),
        },
      })

    case types.FETCH_DATA_COMPARATIVE_BY_OVERALL_FORECAST_SUCCESS:
      const linesComparative = action.payload.data.totalizer ?? []
      return state.merge({
        dataComparativeForecastReport: {
          dataSource: action.payload.data.data,
          fields: action.payload.data.fields,
          meta: action.payload.data.meta,
          linesOrder: action.payload.data.order_columns,
          linesTotal: linesComparative.filter((it) => it.type === ROWS.TOTALIZER),
          linesRatio: linesComparative.filter((it) => it.type === ROWS.RATIO),
          linesRatioN: linesComparative.filter((it) => it.type === ROWS.RATION),
        },
      })

    case types.FETCH_DATA_RDN_PERCENTAGE_BY_FORECAST_SUCCESS:
      return state.merge({
        dataRdnPercentageForecast: {
          dataSource: action.payload.data.data,
          fields: action.payload.data.fields,
          meta: action.payload.data.meta,
        },
      })

    case types.FETCH_FORECAST_FILTER_DATES_SUCCESS:
      return state.merge({
        forecastFilterDates: action.payload.data,
      })

    default:
      return state
  }
}
