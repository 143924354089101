const integrations = {
  INTEGRATION_ENTER_USER_INPUT_PLACEHOLDER: 'Enter user',
  INTEGRATION_ENTER_PASSWORD_INPUT_PLACEHOLDER: 'Enter password',
  INTEGRATION_CONNECTION_DATA_DIVIDER_TEXT: 'Connection data',
  INTEGRATION_URL_FORMITEM_LABEL: 'URL Database',
  INTEGRATION_ENTER_URL_INPUT_PLACEHOLDER: 'Enter a URL',
  INTEGRATION_DATA_BASE_NAME_FORMITEM_LABEL: 'Database name',
  INTEGRATION_ENTER_DATA_BASE_INPUT_PLACEHOLDER: 'Enter database name',
  INTEGRATION_PORT_FORMITEM_LABEL: 'Port',
  INTEGRATION_ENTER_PORT_INPUT_PLACEHOLDER: 'Enter port',
  INTEGRATION_HOST_FORMITEM_LABEL: 'Host',
  INTEGRATION_ENTER_HOST_INPUT_PLACEHOLDER: 'Enter host',
  INTEGRATION_DATA_INTEGRATION_MODAL_TITLE: 'Data {{name}}',
  INTEGRATIONS_SELECT_SOFTWARE_CONNECT_MODAL_TITLE: 'Select your favorite software',
  INTEGRATIONS_LINK_SPREADSHEET_INPUT_PLACEHOLDER: 'Enter spredsheet link',
  INTEGRATIONS_CREDENTIALS_TAB_TITLE: 'Credentials',
  INTEGRATIONS_FOLDER_ID_SPREADSHEET_INPUT_PLACEHOLDER: 'Enter folder ID',
  INTEGRATION_FOLDER_ID_FORMITEM_LABEL: 'ID folder',
}

export default integrations
