import { Space } from 'antd'
import Typography from '../Typography/Typography'

const ProgressBar = ({ isLoading }) => {
  return (
    <Space direction="vertical" size="small">
      <div
        style={{
          width: 300,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography.Body>
          {!isLoading ? 'Carga finalizada ' : 'Estamos cargando los datos'}
        </Typography.Body>
        {isLoading && <Typography.Body level={3}>Puede tardar unos minutos..</Typography.Body>}
        <progress value={!isLoading ? 100 : null} style={{ width: 300 }} />
      </div>
    </Space>
  )
}

export default ProgressBar
