import React, { useEffect, useState } from 'react'
import { useFeatureIsOn, useFeatureValue } from '@growthbook/growthbook-react'
import { Col, Dropdown, Menu, message, Row, Space, Spin } from 'antd'
import {
  ColumnsAndRowsSelections,
  DownloadModal,
  ProgressBar,
  SelectType,
} from 'modules/core/components'
import { GENERAL, INTEGRATIONS } from 'modules/core/constants'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import {
  handleSave,
  handleSavePrimary,
} from 'modules/control/containers/ControlDetail/components/Evolution/utils'
import { EvolutionTable } from './components'
import { CommentOutlined } from '@ant-design/icons'
import { handleEditFinancial } from './utils/helper'
import TableActions from '../TableActions/TableActions'
import login from 'modules/login'
import reports from 'modules/reports'
import configuration from 'modules/configuration'
import control from 'modules/control'
import moment from 'moment/moment'
import _ from 'lodash'
import { handleDelete } from '../../utils'
import { generateMessageError } from 'modules/core/utils'

import PivotTableUX from 'components/PivotTable/PivotTableUX'
import PivotTableRenderers from 'components/PivotTable/Renderers/Evolution/Tree'

import PlaneTableUX from 'components/PivotTable/PlaneTableUX'
import PlaneTableRenderers from 'components/PivotTable/Renderers/Evolution/Plane'

const DEFAULT_FORMAT_CREATE = {
  'Suma (Decimales)': 'float',
  'Suma (Enteros)': 'int',
  'Suma (Miles)': 't',
  'Suma (Millones)': 'm',
}

const DEFAULT_FORMAT_SET = {
  float: 'Suma (Decimales)',
  int: 'Suma (Enteros)',
  t: 'Suma (Miles)',
  m: 'Suma (Millones)',
}

const getRowAuxName = (rows, allDimensionsList) => {
  return rows.map((row) => {
    if (row === 'Linea de reporte') {
      return 'Linea de reporte'
    } else {
      const dimension = allDimensionsList.find((el) => parseInt(row) === el.id)
      return dimension ? dimension.name_origin : ''
    }
  })
}

const getColsAuxName = (columns, allDimensionsList) => {
  return columns.map((col) => {
    if (col === 'Fecha') {
      return 'Fecha'
    } else {
      const dimension = allDimensionsList.find((el) => parseInt(col) === el.id)
      return dimension ? dimension.name_origin : ''
    }
  })
}

const getRowAuxId = (settings, allDimensionsList) => {
  return (
    !_.isEmpty(settings) &&
    settings.rows.map((row) => {
      if (row === 'Linea de reporte') {
        return 'Linea de reporte'
      } else {
        const id = allDimensionsList.find((el) => el.name_origin === row).id
        return id.toString()
      }
    })
  )
}

const getColsAuxId = (settings, allDimensionsList) => {
  return (
    !_.isEmpty(settings) &&
    settings.cols.map((col) => {
      const id = allDimensionsList.find((el) => el.name_origin === col)
        ? allDimensionsList.find((el) => el.name_origin === col).id
        : null
      if (col === 'Fecha') {
        return 'Fecha'
      } else {
        return id.toString()
      }
    })
  )
}

const Evolution = ({
  loading,
  selectedFilters,
  selectedDates,
  selectedPeriod,
  groupProjection,
  periodTableSettings,
  setPeriodTableSettings,
  selectedProjection,
  setSelectedProjection,
  setTablePagination,
  tablePagination,
  onRefetch,
  dataTableEvolution: { columns, rows },
  downloadEvolutionXLS,
  loggedUser,
  reportStructure,
  lineVertical,
  setLineVertical,
  onRefetchEvolution,
  setDataComments,
  dataComments,
  onRefetchComments,
  loadingComments,
  commentKey,
  dataEvolutionReport,
  fetchLayoutList,
  layoutList,
  fetchAllDimensions,
  allDimensionsList,
  createLayout,
  updatedInitialAmountByFinancial,
  deleteLayout,
  hasComment,
  setHasComment,
  createComment,
  removeNewComment,
  isProgressLoading,
  showProgressBar,
  currencyData,
}) => {
  // Feature flag
  const enabledEvolutionTable = useFeatureIsOn('feature-react-pivot-evolution')
  const enabledPlaneTable = useFeatureIsOn('feature-plane-table-custom')
  const formatBaseNumber = useFeatureValue('format-number-table', 'Suma (Decimales)')

  console.log(formatBaseNumber)

  // Hooks
  const { t } = useTranslation()
  let { reportId, periodId } = useParams()

  // State
  const [periodTableColumns, setPeriodTableColumns] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [editFilter, setEditFilters] = useState({})
  // TODO: NEW ENDPOINTS FOR REVAMP TABLE
  const [settings, setSettings] = useState({})
  const [showPopover, setShowPopover] = useState(false)
  const [favouriteName, setFavouriteName] = useState('')
  const [isSaving, setIsSaving] = useState(false)
  const [isLayoutLoading, setIsLayoutLoading] = useState(false)
  const [viewSelect, setViewSelect] = useState({})
  const [viewComment, setViewComment] = useState(false)

  // Values
  const { dataSource, fields, linesOrder, linesTotal, linesRatio, linesRatioN, linesFinancial } =
    dataEvolutionReport
  const linesReport = reportStructure?.values.filter((item) =>
    ['grouper', 'totalizer'].includes(item.type),
  )
  let inputValue = null

  const paramsDownload = {
    period_id: periodId,
    filter_date: selectedDates,
    filter: selectedFilters,
    type_report: selectedProjection,
  }
  const params = {
    step: null,
    type_table: 'report',
    report_id: reportId,
  }

  // Methods
  useEffect(() => {
    setPeriodTableColumns(columns)
  }, [columns])

  useEffect(() => {
    fetchAllDimensions()
  }, [fetchAllDimensions])

  useEffect(() => {
    setIsLayoutLoading(true)
    fetchLayoutList('reports', `${periodId}-${reportId}-montly_evolution`).then(() => {
      setIsLayoutLoading(false)
      setSettings({})
    })
  }, [fetchLayoutList, periodId, reportId])

  useEffect(() => {
    const defaultLayout = layoutList.values.find((el) => el.primary)
    setViewSelect(defaultLayout)
  }, [layoutList.values])

  useEffect(() => {
    if (layoutList.values.length > 0 && !_.isEmpty(viewSelect) && allDimensionsList.length > 0) {
      setSettings((prevState) => {
        return {
          ...prevState,
          ...viewSelect.config,
          cols:
            getColsAuxName(viewSelect.config.columns, allDimensionsList).length > 0
              ? getColsAuxName(viewSelect.config.columns, allDimensionsList)
              : ['date'],
          rows: getRowAuxName(viewSelect.config.rows, allDimensionsList),
          aggregatorName: DEFAULT_FORMAT_SET[viewSelect.config.default_format],
          vals: [viewSelect.config.default_value],
        }
      })
    }
  }, [allDimensionsList, layoutList.values, viewSelect])

  const onClose = () => {
    setIsSaving(false)
    setShowPopover(false)
    setFavouriteName('')
  }

  const onCreate = () => {
    const data = {
      row_order: 0,
      column_order: 1,
      default_value: settings.vals[0],
      default_format: DEFAULT_FORMAT_CREATE[settings.aggregatorName],
      rows: getRowAuxId(settings, allDimensionsList),
      columns: getColsAuxId(settings, allDimensionsList),
    }

    return createLayout('reports', `${periodId}-${reportId}-montly_evolution`, favouriteName, {
      config: data,
    })
  }

  const defaultData = !_.isEmpty(viewSelect) && {
    ...viewSelect.config,
    rows: getRowAuxName(viewSelect.config.rows, allDimensionsList),
    columns: getColsAuxName(viewSelect.config.columns, allDimensionsList),
    default_format: DEFAULT_FORMAT_SET[viewSelect.config.default_format],
  }
  const defaultSettings = !_.isEmpty(settings) && {
    rows: settings.rows,
    columns: settings.cols || ['Fecha'],
    row_order: settings.row_order,
    column_order: settings.column_order,
    default_value: settings.vals ? settings.vals[0] : '',
    default_format: settings.aggregatorName,
  }

  const onSavePrimary = (data) => {
    const dataAux = layoutList.values.find((el) => el.name === data.name)
    return createLayout('reports', `${periodId}-${reportId}-montly_evolution`, dataAux.name, {
      ...dataAux,
      primary: true,
    })
  }

  useEffect(() => {
    setSettings((prevState) => ({
      ...prevState,
      data: dataEvolutionReport.dataSource,
      tableOptions: {
        ...prevState.tableOptions,
        linesOrder: linesOrder,
        linesTotal: linesTotal,
        linesRatio: linesRatio,
        linesRatioN: linesRatioN,
        linesFinancial: linesFinancial,
        lineVertical: lineVertical,
        clickCallback: clickCallback,
      },
    }))
  }, [dataEvolutionReport, lineVertical])

  const clickCallback = (info, value, filters, pivotData) => {
    if (_.isEmpty(editFilter)) {
      setEditFilters(filters)
      const originElement = info.target.innerHTML
      const newElement = info.target

      newElement.innerHTML = `<input type=text value=${value} >`
      const inputElement = document.querySelector('input[type="text"]')

      inputElement.focus()

      inputElement.addEventListener('input', function (e) {
        inputValue = e.target.value
      })

      inputElement.addEventListener('blur', function () {
        newElement.innerHTML = originElement
        inputElement.removeEventListener('keypress', {})
        inputElement.removeEventListener('input', {})
        setEditFilters({})
        inputValue = null
      })

      inputElement.addEventListener('keypress', function (e) {
        if (e.key === 'Enter') {
          console.log(inputValue)
          handleEditFinancial({
            updatedFinancial: () =>
              updatedInitialAmountByFinancial({
                ...filters,
                period_id: periodId,
                initial_amount: inputValue,
                id_report: reportId,
                type_report: selectedProjection,
              }),
            onRefetch: onRefetch,
          })
          inputElement.removeEventListener('keypress', {})
          inputElement.removeEventListener('input', {})
          setEditFilters({})
        }
      })
    }
  }

  const handleCreate = ({ text, usersIds, idComment = null, setIsNewThread }) => {
    const dataa = {
      text,
      key: `${commentKey}-table-evolution`,
      module: commentKey,
      mentions: usersIds,
      ...(idComment && {
        parent: idComment,
      }),
    }
    setIsSaving(true)
    createComment(dataa)
      .then(() =>
        onRefetchComments().then(() => {
          message.success(t('FEEDBACK_SAVE_CHANGES_SUCCES'), 8)
          setIsSaving(false)
          setIsNewThread(false)
        }),
      )
      .catch((error) => {
        message.error(generateMessageError(error, 'FEEDBACK_SAVE_CHANGES_FAIL_MESSAGE'), 8)
        setIsSaving(false)
        setIsNewThread(false)
      })
  }
  const renderTable = () => {
    if (enabledPlaneTable) {
      return (
        <Spin spinning={loading}>
          <Dropdown
            trigger={['contextMenu']}
            overlay={
              <Menu>
                {GENERAL.OPTION_COMMENT.map((el) => (
                  <Menu.Item
                    key={el.key}
                    icon={<CommentOutlined />}
                    onClick={() => {
                      setViewComment(true)
                      setHasComment({ type: 'table', data: !hasComment.table })
                    }}
                  >
                    {t(el.label)}
                  </Menu.Item>
                ))}
              </Menu>
            }
          >
            <div style={{ width: '100%' }}>
              <PlaneTableUX
                renderers={Object.assign({}, PlaneTableRenderers)}
                data={dataSource}
                onChange={(s) => setSettings(s)}
                vals={settings.default_value ? [settings.default_value] : ['amount']}
                cols={fields.columns}
                rows={['Linea de reporte']}
                aggregatorName={settings.default_format || formatBaseNumber}
                hiddenFromDragDrop={['amount', 'price', 'quantity']}
                hiddenFromAggregators={
                  dataSource[0] &&
                  dataSource[0].filter(
                    (el) => el !== 'amount' && el !== 'price' && el !== 'quantity',
                  )
                }
                tableFilter={{}}
                tableOptions={{
                  linesOrder: linesOrder,
                  linesTotal: linesTotal,
                  linesRatio: linesRatio,
                  linesRatioN: linesRatioN,
                  linesFinancial: linesFinancial,
                  lineVertical: lineVertical,
                  clickCallback: clickCallback,
                }}
                plotlyConfig={{
                  responsive: true,
                }}
                {...settings}
              />
            </div>
          </Dropdown>
        </Spin>
      )
    }
    if (enabledEvolutionTable) {
      return (
        <Spin spinning={loading}>
          <Dropdown
            trigger={['contextMenu']}
            overlay={
              <Menu>
                {GENERAL.OPTION_COMMENT.map((el) => (
                  <Menu.Item
                    key={el.key}
                    icon={<CommentOutlined />}
                    onClick={() => {
                      setViewComment(true)
                      setHasComment({ type: 'table', data: !hasComment.table })
                    }}
                  >
                    {t(el.label)}
                  </Menu.Item>
                ))}
              </Menu>
            }
          >
            <div style={{ width: '100%' }}>
              <PivotTableUX
                renderers={Object.assign({}, PivotTableRenderers)}
                data={dataSource}
                onChange={(s) => setSettings(s)}
                vals={settings.default_value ? [settings.default_value] : ['amount']}
                cols={fields.columns}
                rows={['Linea de reporte']}
                aggregatorName={settings.default_format || formatBaseNumber}
                hiddenFromDragDrop={['amount', 'price', 'quantity']}
                hiddenFromAggregators={
                  dataSource[0] &&
                  dataSource[0].filter(
                    (el) => el !== 'amount' && el !== 'price' && el !== 'quantity',
                  )
                }
                tableFilter={{}}
                tableOptions={{
                  linesOrder: linesOrder,
                  linesTotal: linesTotal,
                  linesRatio: linesRatio,
                  linesRatioN: linesRatioN,
                  linesFinancial: linesFinancial,
                  lineVertical: lineVertical,
                  clickCallback: clickCallback,
                }}
                plotlyConfig={{
                  responsive: true,
                }}
                {...settings}
              />
            </div>
          </Dropdown>
        </Spin>
      )
    }

    return (
      <EvolutionTable
        loading={loading}
        dataSource={rows}
        periodTableColumns={periodTableColumns}
        setPeriodTableColumns={setPeriodTableColumns}
        periodTableSettings={periodTableSettings}
        filters={selectedFilters}
        selectedDates={selectedDates}
        selectedProjection={selectedProjection}
        tablePagination={tablePagination}
        lineVertical={lineVertical}
      />
    )
  }

  return (
    <Row gutter={[12, 12]}>
      {!enabledEvolutionTable && (
        <Col span={24}>
          <ColumnsAndRowsSelections
            onRefresh={onRefetch}
            params={params}
            period_id={periodId}
            setPagination={setTablePagination}
          />
        </Col>
      )}
      <Col span={24}>
        <Space direction="horizontal">
          <SelectType.EvolutionSelect
            loading={loading}
            groupProjection={groupProjection}
            activeOptionTable={selectedProjection}
            setActiveByPeriodTable={setSelectedProjection}
          />
          <SelectType.VerticalAnalysisSelect
            loading={loading}
            groupLines={linesReport}
            selectedOptions={lineVertical}
            setSelectedOptions={setLineVertical}
          />
        </Space>
      </Col>
      <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
        {showProgressBar && <ProgressBar isLoading={isProgressLoading} />}
      </Col>
      <Col span={24}>
        <TableActions
          setPeriodTableSettings={setPeriodTableSettings}
          periodTableSettings={periodTableSettings}
          onClick={() =>
            downloadEvolutionXLS(
              reportId,
              paramsDownload,
              t('REPORT_EVOLUTION_FILE_NAME', {
                periodName: selectedPeriod.name,
                date: moment().format('lll'),
              }),
            )
          }
          onClickDownloadSpreadsheets={() => setShowModal(true)}
          onClickSaveFav={() => setShowPopover(true)}
          visibleFav={showPopover}
          onClickSave={() =>
            handleSave({
              setIsLoading: setIsSaving,
              createLayout: () => onCreate(),
              onRefetch: () =>
                fetchLayoutList('reports', `${periodId}-${reportId}-montly_evolution`),
              onRefresh: onRefetchEvolution,
              onClose,
            })
          }
          onClickCancelSaveFav={onClose}
          setFavouriteName={setFavouriteName}
          favouriteName={favouriteName}
          isLoading={isSaving}
          hasFavourite={layoutList.values.length > 0}
          options={layoutList.values.map((el) => {
            return { name: el.name, id: el.name, primary: el.primary }
          })}
          canSave={
            !_.isEmpty(settings) ||
            (!_.isEmpty(settings) && !_.isEqual(defaultData, defaultSettings))
          }
          viewSelect={viewSelect}
          setViewSelect={(val) => {
            const aux = layoutList.values.find((el) => el.name === val)
            setViewSelect(aux)
          }}
          alreadyExist={_.isEqual(defaultData, defaultSettings)}
          layoutLoading={isLayoutLoading}
          setPrimary={(data) =>
            handleSavePrimary({
              setIsLoading: setIsSaving,
              createLayout: () => onSavePrimary(data),
              onRefetch: () =>
                fetchLayoutList('reports', `${periodId}-${reportId}-montly_evolution`),
              onClose: () => setIsSaving(false),
            })
          }
          onClickDeleteFav={(name) =>
            handleDelete({
              setIsLoading: setIsSaving,
              deleteLayout: () =>
                deleteLayout('reports', `${periodId}-${reportId}-montly_evolution`, name),
              onRefetch: () =>
                fetchLayoutList('reports', `${periodId}-${reportId}-montly_evolution`),
              onClose: () => setIsSaving(false),
            })
          }
          showComments={
            viewComment ||
            dataComments.filter((el) => el.key === `${commentKey}-table-evolution` && !el.resolved)
              .length > 0
          }
          hasComment={hasComment}
          setHasComment={setHasComment}
          setViewComment={setViewComment}
          userDetail={loggedUser}
          settings={settings}
          setDataComments={setDataComments}
          dataComments={dataComments.filter(
            (el) => (el.key === `${commentKey}-table-evolution` && !el.resolved) || !el.text,
          )}
          onRefetchComments={onRefetchComments}
          loadingComments={loadingComments}
          onCommentCreate={(text, usersIds, idComment, setIsNewThread) =>
            handleCreate({ text, usersIds, idComment, setIsNewThread })
          }
          removeNewComment={removeNewComment}
          currencyData={currencyData}
        />
      </Col>
      <Col span={24}>
        {/* TODO: NEW FOR REVAMP TABLE */}
        {renderTable()}
      </Col>
      <DownloadModal
        title={INTEGRATIONS.KEYS.SPREADSHEETS_KEY}
        visible={showModal}
        onCancel={() => setShowModal(false)}
        defaultValue={loggedUser.folder_id}
        dataSpreadsheets={{
          type_load: 'report',
          params: {
            report_id: reportId,
            period_id: periodId,
          },
        }}
      />
    </Row>
  )
}

const mapStateToProps = (state) => ({
  dataTableEvolution: reports.selectors.getReportDetail(state),
  loggedUser: login.selectors.getWhoAmI(state),
  reportStructure: reports.selectors.getReportStructure(state),

  // TODO: NEW ENDPOINTS FOR REVAMP TABLE
  dataEvolutionReport: reports.selectors.getDataEvolutionByReport(state),
  layoutList: control.selectors.getLayoutList(state),
  allDimensionsList: configuration.selectors.getAllDimensionsList(state),
  hasComment: configuration.selectors.getHasComment(state),
})

const mapDispatchToProps = {
  downloadEvolutionXLS: reports.actions.downloadEvolutionXLS,
  createLayout: control.actions.createLayout,
  fetchLayoutList: control.actions.fetchLayoutList,
  fetchAllDimensions: configuration.actions.fetchAllDimensions,
  updatedInitialAmountByFinancial: reports.actions.updatedInitialAmountByFinancial,
  deleteLayout: control.actions.deleteLayout,
  setHasComment: configuration.actions.setHasComment,
  createComment: configuration.actions.createComment,
  removeNewComment: configuration.actions.removeNewComment,
}

export default connect(mapStateToProps, mapDispatchToProps)(Evolution)
