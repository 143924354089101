import { message } from 'antd'
import { generateMessageError } from 'modules/core/utils'
import i18n from 'i18next'

export const onDeleteSelections = ({
  setIsDeleting,
  dataSelection,
  deleteUser,
  onRefetch,
  onClose,
}) => {
  setIsDeleting(true)
  Promise.all(
    dataSelection.map((id) =>
      deleteUser(id).catch((error) => {
        message.error(generateMessageError(error, 'FEEDBACK_DELETE_SELECTION_ERROR'), 8)
        onClose()
      }),
    ),
  ).then(() =>
    onRefetch().then(() => {
      message.success(i18n.t('FEEDBACK_DELETE_SELECTION_SUCCESS'), 8)
      onClose()
    }),
  )
}

export const handleDelete = ({ setIsLoading, deleteUsersSelections, onRefetch, onClose }) => {
  setIsLoading(true)
  deleteUsersSelections()
    .then(() =>
      onRefetch().then(() => {
        message.success(i18n.t('FEEDBACK_DELETE_SELECTION_SUCCESS'), 8)
        onClose()
      }),
    )
    .catch((error) => {
      message.error(generateMessageError(error, 'FEEDBACK_DELETE_SELECTION_ERROR'), 8)
      onClose()
    })
}
