import { message, notification } from 'antd'
import { generateMessageError } from 'modules/core/utils'
import i18n from 'i18next'

export const addItem = ({ event, setIsLoading, create, onRefetch, onClose }) => {
  event.preventDefault()
  setIsLoading(true)
  create()
    .then(() => {
      onRefetch().then(() => {
        message.success(i18n.t('FEEDBACK_CREATE_SUCCESS'), 8)
        onClose()
      })
    })
    .catch((error) => {
      notification.error({
        message: i18n.t('FEEDBACK_DEFAULT_ERROR'),
        description: generateMessageError(error, 'FEEDBACK_CREATION_FAIL'),
        duration: 0,
      })
      onClose()
    })
}

export const onSelectOption = ({ value, key, setDataCreate }) => {
  setDataCreate((prevState) => {
    return { ...prevState, [key]: value.target.value }
  })
}

export const generateInputsNewItem = (dataCreate) => [
  {
    id: 'code',
    placeholder: 'ENTER_CODE_PLACEHOLDER',
    value: dataCreate.code,
  },
  {
    id: 'name',
    placeholder: 'ENTER_NAME_PLACEHOLDER',
    value: dataCreate.name,
  },
]
