import React from 'react'
import { useTranslation } from 'react-i18next'
import { Form, Modal } from 'antd'
import { CHARTS } from 'modules/core/constants'
import {
  BarChartSettings,
  LineChartSettings,
  PieChartSettings,
  SankeyChartSettings,
} from './components'

const getDataSettingsChart = (form, settingsChart, chart_type) => {
  return {
    pie: <PieChartSettings form={form} defaultSetting={settingsChart} chart_type={chart_type} />,
    bar: <BarChartSettings form={form} defaultSetting={settingsChart} chart_type={chart_type} />,
    line: <LineChartSettings form={form} defaultSetting={settingsChart} chart_type={chart_type} />,
    sankey: <SankeyChartSettings form={form} defaultSetting={settingsChart} />,
  }
}

const CHARTS_NAME = {
  pie: CHARTS.I18N.pie,
  bar: CHARTS.I18N.bar,
  line: CHARTS.I18N.line,
  sankey: CHARTS.I18N.sankey,
}

const ChartsSettingsModal = ({ visible, chart_type, settingsChart, onCancel, onOk }) => {
  const [form] = Form.useForm()
  const { t } = useTranslation()

  const onConfirm = () => {
    form.validateFields().then((values) => {
      onOk(values)
      onCancel()
    })
  }

  return (
    <Modal
      title={t('DISPLAY_MODAL_SETTINGS_TITLE', { chartName: t(CHARTS_NAME[chart_type]) })}
      visible={visible}
      onCancel={onCancel}
      okText={t('ACTION_ACEPT')}
      cancelText={t('ACTION_CANCEL')}
      bodyStyle={{ overflowY: 'auto', maxHeight: 400 }}
      onOk={onConfirm}
      destroyOnClose
      forceRender
      centered
    >
      {getDataSettingsChart(form, settingsChart, chart_type)[chart_type]}
    </Modal>
  )
}

export default ChartsSettingsModal
