import { Modal, Space } from 'antd'
import { Typography } from 'modules/core/components'
import { useTranslation } from 'react-i18next'

const InformativeRuleModal = ({ visible, onCancel, onConfirm }) => {
  const { t } = useTranslation()
  return (
    <Modal
      title={t('CONFIG_FORMULAS_CREATE_ACTION')}
      width={560}
      centered
      forceRender
      onCancel={onCancel}
      visible={visible}
      destroyOnClose
      okText={t('ACTION_BEGIN')}
      onOk={onConfirm}
      cancelText={t('ACTION_CANCEL')}
    >
      <Space direction="vertical">
        <Typography.Body level={2}>{t('CONFIG_RULES_INFORMATIVE_MODAL_BODY_1')}</Typography.Body>
        <Typography.Body level={2}>{t('CONFIG_RULES_INFORMATIVE_MODAL_BODY_2')}</Typography.Body>
        <Typography.Body level={2}>{t('CONFIG_RULES_INFORMATIVE_MODAL_BODY_3')}</Typography.Body>
        <Typography.Body level={2}>{t('CONFIG_RULES_INFORMATIVE_MODAL_BODY_4')}</Typography.Body>
      </Space>
    </Modal>
  )
}

export default InformativeRuleModal
