import { useFeatureIsOn } from '@growthbook/growthbook-react'
import React from 'react'
import { Input, Space } from 'antd'
import { Button, ButtonActionBar, CustomBottomAction, DropdownMenu } from 'modules/core/components'
import { useTranslation } from 'react-i18next'
import { SolutionOutlined, NodeIndexOutlined } from '@ant-design/icons'
// import { DeadlineModal } from "modules/planning/containers/PlanningList/components";

const { Search } = Input

const UserActions = ({
  onClick,
  disabled,
  tooltipTitle = '',
  periodId,
  concept,
  setSearchValue,
  module_name,
  onClickActivity,
  onClickWorkflow,
}) => {
  const { t } = useTranslation()
  const enabledWorkflow = useFeatureIsOn('feature-workflow')

  const listMenu = [
    {
      title: t('ACTION_ACTIVITIES'),
      icon: <SolutionOutlined />,
      onClick: onClickActivity,
    },
  ]

  if (enabledWorkflow && module_name === 'forecast') {
    listMenu.push({
      title: t('ACTION_WORKFLOW'),
      icon: <NodeIndexOutlined />,
      onClick: onClickWorkflow,
    })
  }

  return (
    <ButtonActionBar>
      <Space size="middle">
        <Search placeholder={t('ACTION_SEARCH')} allowClear={true} onSearch={setSearchValue} />
        <Button.Primary
          onClick={onClick}
          disabled={disabled}
          title="ACTION_CREATE_NEW"
          tooltipTitle={tooltipTitle}
        />
        <CustomBottomAction extraButtons={{ module_name: module_name, concept: concept.id }} />
        <DropdownMenu title={t('ACTION_MORE')} menu={listMenu} />
        {/* <DeadlineModal
                    period={periodId}
                    concept={concept.id}
                /> */}
        {/* <Button
                type="primary"
                ghost
                // onClick={() => setShowCreateModal(true)}
                // disabled={!userCanCreate || areApprovedProjections}
                >
                Ver analiticas
                </Button> */}
      </Space>
    </ButtonActionBar>
  )
}

export default UserActions
