export const NAME = 'login'

// ++ LOGIN ++
export const USER_LOGIN = `${NAME}/USER_LOGIN`
export const USER_LOGIN_SUCCESS = `${NAME}/USER_LOGIN_SUCCESS`
export const USER_LOGIN_FAIL = `${NAME}/USER_LOGIN_FAILED`

export const USER_LOGOUT = `${NAME}/USER_LOGOUT`

export const USER_LOGIN_CONFIRM_PASSWORD = `${NAME}/USER_LOGIN_CONFIRM_PASSWORD`

// ++ CHANGE PASSWORD
export const USER_CHANGE_PASSWORD = `${NAME}/USER_CHANGE_PASSWORD`

// ++ WHO AM I ++
export const USER_WHO_AM_I = `${NAME}/USER_WHO_AM_I`
export const USER_WHO_AM_I_SUCCESS = `${NAME}/USER_WHO_AM_I_SUCCESS`
export const USER_WHO_AM_I_FAIL = `${NAME}/USER_WHO_AM_I_FAIL`

// ++ SETTING LANG
export const USER_SETTING_LANG = `${NAME}/USER_SETTING_LANG`
export const USER_SETTING_LANG_SUCCESS = `${NAME}/USER_SETTING_LANG_SUCCESS`
export const USER_SETTING_LANG_FAIL = `${NAME}/USER_SETTING_LANG_FAILED`

// RESET PASSWORD

export const EMAIL_CONFIRM = `${NAME}/EMAIL_CONFIRM`
export const RESET_PASSWORD = `${NAME}/RESET_PASSWORD`

export const GOOGLE_REDIRECT = `${NAME}/GOOGLE_REDIRECT`
export const GOOGLE_CALLBACK = `${NAME}/GOOGLE_CALLBACK`
export const GOOGLE_ACCOUNTS = `${NAME}/GOOGLE_ACCOUNTS`

export const FETCH_LOGIN_OTHER_ACCOUNTS = `${NAME}/FETCH_LOGIN_OTHER_ACCOUNTS`
export const FETCH_LOGIN_OTHER_ACCOUNTS_SUCCESS = `${NAME}/FETCH_LOGIN_OTHER_ACCOUNTS_SUCCESS`
export const FETCH_LOGIN_OTHER_ACCOUNTS_FAIL = `${NAME}/FETCH_LOGIN_OTHER_ACCOUNTS_FAIL`

export const ASSOCIATED_ACCOUNT = `${NAME}/ASSOCIATED_ACCOUNT`

export const SET_AUTHENTICATED = `${NAME}/SET_AUTHENTICATED`

export const AZURE_REDIRECT = `${NAME}/AZURE_REDIRECT`
export const AZURE_CALLBACK = `${NAME}/AZURE_CALLBACK`

export const QR_CODE_REDIRECT = `${NAME}/QR_CODE_REDIRECT`
export const QR_CODE_REDIRECT_SUCCESS = `${NAME}/QR_CODE_REDIRECT_SUCCESS`
export const QR_CODE_REDIRECT_FAIL = `${NAME}/QR_CODE_REDIRECT_FAIL`

export const VERIFY_CODE = `${NAME}/VERIFY_CODE`

export const DELETE_QR_AUTHENTICATION = `${NAME}/DELETE_QR_AUTHENTICATION`

export const SEND_EMAIL_SELF_RESET_PASSWORD = `${NAME}/SEND_EMAIL_SELF_RESET_PASSWORD`

export const SELF_REDET_PASSWORD = `${NAME}/SELF_REDET_PASSWORD`
