import React, { useState } from 'react'
import { Table, Space, Tooltip } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import { Typography, DeleteAndUpdate, InputCell } from 'modules/core/components'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { TableActions } from './components'
import { handleOnSave, onDeleteDimensionValue } from './utils'
import { isUserAllowed } from 'modules/core/utils'
import { ROLES } from 'modules/core/constants'
import configuration from 'modules/configuration'

const { SETTINGS__DIMENSIONS__ABM } = ROLES

const DimensionsDetailTable = ({
  tableLoading,
  onTableChange,
  dataSource,
  pagination,
  count,
  onSetRowSelection,
  dataSelection,
  asignManagementDimensionValues,
  onFetchDimensionValues,
  dimensionId,
  deleteDimension,
}) => {
  const [editRow, setEditRow] = useState(null)
  const [dataCellEdit, setDataCellEdit] = useState({})
  const [isSaving, setIsSaving] = useState(false)
  const [deleteDimensionValueId, setDeleteDimensionValueId] = useState(null)
  const { t } = useTranslation()

  const canDelete = isUserAllowed(SETTINGS__DIMENSIONS__ABM)

  const onCellEdit = (name, value) => setDataCellEdit({ ...dataCellEdit, [`${name}`]: value })

  const onCancel = () => setEditRow(null)

  const onConfirm = () => {
    setEditRow(null)
    setDataCellEdit({})
    setIsSaving(false)
  }

  const onClose = () => {
    setIsSaving(false)
    setDeleteDimensionValueId(null)
  }

  const columns = [
    {
      title: t('CONFIG_DIMENSIONS_FIELD_ORIGIN_CODE'),
      dataIndex: 'code_origin',
      editable: true,
    },
    {
      title: t('CONFIG_DIMENSIONS_FIELD_ORIGIN_NAME'),
      dataIndex: 'name_origin',
      editable: true,
    },
    {
      title: t('CONFIG_DIMENSIONS_FIELD_DESTINATION_CODE'),
      dataIndex: 'code_destination',
      editable: true,
      render: (record, row) => {
        if (editRow && editRow === row.id) {
          return (
            <InputCell initialValue={record} onCellUpdate={onCellEdit} name="code_destination" />
          )
        }
        return record
      },
    },
    {
      title: t('CONFIG_DIMENSIONS_FIELD_DESTINATION_NAME'),
      dataIndex: 'name_destination',
      editable: true,
      render: (record, row) => {
        if (editRow && editRow === row.id) {
          return (
            <InputCell initialValue={record} onCellUpdate={onCellEdit} name="name_destination" />
          )
        }
        return record
      },
    },
    {
      title: t('FIELD_SOURCE'),
      dataIndex: 'source_data',
      editable: true,
      render: (record) => record.display,
    },
    {
      title: (
        <Space size="large">
          {t('FIELD_ACTIONS')}
          <Tooltip title={t('CONFIG_DIMENSIONS_TOOLTIP_INFO_TITLE')}>
            <Typography.Icon icon={InfoCircleOutlined} type="primary" />
          </Tooltip>
        </Space>
      ),
      align: 'right',
      dataIndex: 'id',
      width: 150,
      render: (record) => {
        return record !== editRow ? (
          <TableActions
            onConfirmDelete={() =>
              onDeleteDimensionValue({
                setIsLoading: setIsSaving,
                deleteDimension: () =>
                  deleteDimension(dimensionId, { dim_value: parseInt(deleteDimensionValueId) }),
                onRefetch: onFetchDimensionValues,
                onClose,
              })
            }
            onCancelDelete={() => setDeleteDimensionValueId(null)}
            visible={deleteDimensionValueId === record}
            onClickEdit={() => setEditRow(record)}
            onClickDelete={() => setDeleteDimensionValueId(record)}
          />
        ) : (
          <DeleteAndUpdate
            onCancel={onCancel}
            onConfirm={onConfirm}
            onSave={() =>
              handleOnSave({
                setIsLoading: setIsSaving,
                asignManagementDimensionValues: () =>
                  asignManagementDimensionValues({
                    ...dataCellEdit,
                    id_dimension: parseInt(dimensionId),
                    id_value_dimension: parseInt(record),
                  }),
                onRefetch: onFetchDimensionValues,
                onClose: onConfirm,
              })
            }
            dataCellEdit={dataCellEdit}
            loading={isSaving}
          />
        )
      },
    },
  ]

  const renderRowSelection = () => {
    return {
      selectedRowKeys: dataSelection,
      onChange: (value) => onSetRowSelection(value),
    }
  }

  return (
    <Table
      rowKey="id"
      bordered
      size="small"
      rowSelection={canDelete ? renderRowSelection() : undefined}
      loading={tableLoading || isSaving}
      onChange={onTableChange}
      columns={columns}
      dataSource={dataSource}
      pagination={{
        pageSize: pagination.page_size,
        current: pagination.page,
        total: count,
        pageSizeOptions: ['10', '20', '30'],
        size: 'small',
        showSizeChanger: true,
      }}
    />
  )
}

const mapDispatchToProps = {
  asignManagementDimensionValues: configuration.actions.asignManagementDimensionValues,
  deleteDimension: configuration.actions.deleteDimension,
}

export default connect(null, mapDispatchToProps)(DimensionsDetailTable)
