import { notification } from 'antd'
import i18n from 'i18next'
import { generateMessageError } from 'modules/core/utils'

export const onConfirmUpload = ({
  setIsLoading,
  fileList,
  uploadServices3,
  uploadUrlAws,
  onClose,
}) => {
  setIsLoading(true)
  fileList.forEach((file) => {
    uploadServices3(file)
      .then((response) =>
        uploadUrlAws(response, file).then((response2) => {
          if (response2.status === 204) {
            notification.success({
              description: i18n.t('FEEDBACK_UPLOAD_FILE_SUCCESS', { configModule: file.name }),
              duration: 8,
            })
            onClose()
          } else {
            notification.error({
              message: i18n.t('FEEDBACK_DEFAULT_ERROR'),
              description: generateMessageError(response2, 'FEEDBACK_UPLOAD_FILE_ERROR', {
                fileName: file.name,
              }),
              duration: 0,
            })
            onClose()
          }
        }),
      )
      .catch((error) => {
        notification.error({
          message: i18n.t('FEEDBACK_DEFAULT_ERROR'),
          description: generateMessageError(error, 'FEEDBACK_UPLOAD_FILE_ERROR', {
            fileName: file.name,
          }),
          duration: 0,
        })
        onClose()
      })
  })
}
