import * as types from './constants'
import { saveAs } from 'file-saver'

export const fetchProcessesList =
  (params = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.FETCH_PROCESSES_LIST,
      payload: {
        request: {
          url: '/core/processes/get_all_process/',
          method: 'GET',
          params,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }

    return response
  }

export const downloadProcessFile = (url, fileName) => async (dispatch) => {
  const response = await dispatch({
    type: types.DOWNLOAD_PROCESS_FILE,
    payload: {
      request: {
        url,
        method: 'GET',
        responseType: 'blob',
      },
    },
  })
  if (response.error) {
    return Promise.reject(response.error)
  }

  saveAs(response.payload.data, `${fileName}`)
  return Promise.resolve()
}

export const fetchAuditList = (module, key) => async (dispatch) => {
  const response = await dispatch({
    type: types.FETCH_AUDIT_LIST,
    payload: {
      request: {
        url: `/audit/${module}/${key}/`,
        method: 'GET',
      },
    },
  })
  if (response.error) {
    return Promise.reject(response.error)
  }
  return response
}
