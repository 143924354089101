import { Input } from 'antd'
import { useTranslation } from 'react-i18next'
import Button from 'modules/core/components/Button/Button'
import ButtonActionBar from 'modules/core/components/ButtonActionBar/ButtonActionBar'

const { Search } = Input

const UserActions = ({ disabled, onClick, setSearchValue }) => {
  const { t } = useTranslation()
  return (
    <ButtonActionBar>
      <Search
        placeholder={t('ACTION_SEARCH')}
        style={{ width: 200 }}
        allowClear={true}
        onSearch={setSearchValue}
      />
      <Button.Primary title="ACTION_UPLOAD_FILE" disabled={disabled} onClick={onClick} />
    </ButtonActionBar>
  )
}

export default UserActions
