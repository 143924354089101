const FACTOR_CODE_DELETE_KEEP_DATA = 1

const FACTOR_CODE_DELETE_DEFAULT = 0

const RULE_CODE_DESACTIVATED = 0

const RULE_CODE_DELETE_CASCADE = 1

const RULE_CODE_DELETE_UNAPPLY = 2

const FORMULAS_OPTIONS = [
  {
    id: '(1-variable1)',
    name: '(1-variable1)',
  },
  {
    id: '(1+variable1)',
    name: '(1+variable1)',
  },
  {
    id: 'variable1',
    name: 'variable1',
  },
]

const FORMULA_TYPES_OPTIONS = [
  {
    id: 'individual',
    name: 'CONFIG_RULES_FORMULA_VALUES_STEP_FORMULA_INDIVIDUAL_RADIO_BUTTON_OPTION',
  },
  {
    id: 'acumulativa',
    name: 'CONFIG_RULES_FORMULA_VALUES_STEP_FORMULA_ACCUMULATIVE_RADIO_BUTTON_OPTION',
  },
]

const FACTOR = {
  CODES_DELETE: {
    KEEP_DATA: FACTOR_CODE_DELETE_KEEP_DATA,
    DEFAULT: FACTOR_CODE_DELETE_DEFAULT,
  },
}

const TAKE_ORIGIN_KEY = 'takeOrigin'
const OTHER_DESTINATION_KEY = 'otherDestination'

const KEYS = {
  TAKE_ORIGIN_KEY,
  OTHER_DESTINATION_KEY,
}

const RULE = {
  CODES_DELETE: {
    DESACTIVATED: RULE_CODE_DESACTIVATED,
    CASCADE: RULE_CODE_DELETE_CASCADE,
    UNAPPLY: RULE_CODE_DELETE_UNAPPLY,
  },
  KEYS,
}

const PROJECTION_ELEMENTS = { FACTOR, RULE, FORMULAS_OPTIONS, FORMULA_TYPES_OPTIONS }

export default PROJECTION_ELEMENTS
