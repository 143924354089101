export const handleConfirm = ({ dataFormula, variablesList, onConfirm, onCancel }) => {
  let formula = ''
  const isVariable = [...dataFormula.formula.matchAll('variable[0-9]')]
  isVariable.forEach((v) => {
    const variableName = variablesList.find((el) => el.id === dataFormula.variablesIds[v[0]]).name
    formula = dataFormula.formula.replace(v[0], variableName)
  })
  onConfirm(formula)
  onCancel()
}
