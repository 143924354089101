import { message, notification } from 'antd'
import { PROJECTION_ELEMENTS } from 'modules/core/constants'
import { generateMessageError } from 'modules/core/utils'
import i18n from 'i18next'
import _ from 'lodash'

export const isOtherDestination = (value) =>
  value === PROJECTION_ELEMENTS.RULE.KEYS.OTHER_DESTINATION_KEY

export const isDisabled = ({ dimensionsSelection, dataFormula, destinationOption }) =>
  _.isEmpty(dimensionsSelection.origin_dimensions[0]) ||
  !dataFormula.formula ||
  (dataFormula.formula && _.values(dataFormula.variablesIds).some((value) => !value)) ||
  (isOtherDestination(destinationOption) && _.isEmpty(dimensionsSelection.target_dimensions[0]))

export const validateNameDescription = ({ form, setValuesFormRuleName, setIsNext }) => {
  form.validateFields().then((values) => {
    setValuesFormRuleName(values)
    setIsNext(true)
  })
}

export const onRuleCreate = ({ setIsLoading, createRule, onRefetch, onClose }) => {
  setIsLoading(true)
  createRule()
    .then((response) =>
      onRefetch().then(() => {
        message.success(i18n.t('FEEDBACK_CREATE_SUCCESS'), 8)
        onClose()
      }),
    )
    .catch((error) => {
      notification.error({
        message: i18n.t('FEEDBACK_DEFAULT_ERROR'),
        description: generateMessageError(error, 'FEEDBACK_CREATION_FAIL'),
        duration: 0,
      })
      onClose()
    })
}
