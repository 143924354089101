import { Input, Space, Typography } from 'antd'

const { Text } = Typography

const AdjustmentDetailInfoItem = ({
  title = '',
  icon = null,
  content,
  isTextArea = false,
  placeholder = '',
}) => {
  return (
    <Space className="adjustment-detail-info-item" direction="vertical">
      <Text>{title}</Text>
      {isTextArea ? (
        <Input.TextArea
          value={content}
          disabled
          autoSize
          style={{ width: 300 }}
          placeholder={placeholder}
        />
      ) : (
        <Input value={content} suffix={icon} disabled />
      )}
    </Space>
  )
}

export default AdjustmentDetailInfoItem
