import React, { useEffect, useState } from 'react'
import { Col, Row, Spin } from 'antd'
import { connect } from 'react-redux'
import { ChartsSettingsModal, ElementContainer, ElementCreateModal } from './components'
import { Button, ButtonActionBar, EmptyScreen, PeriodNavigator } from 'modules/core/components'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import emptyStateImage from 'assets/images/compositions/empty-big.svg'
import reports from 'modules/reports'
import configuration from 'modules/configuration'

import './ReportDashboard.scss'

const sizes = {
  large: 24,
  medium: 12,
  small: 6,
}

const ReportDashboard = ({
  selectedDates,
  filters,
  commentKey,
  reportDahsboardList,
  fetchReportDahsboardList,
  settingsCharts,
  setSettingsCharts,
  reportDetail: { name_report },
  fetchCommentsList,
  commentsList,
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [showCreateModal, setShowCreateModal] = useState(false)
  const [showSettingModal, setShowSettingModal] = useState(false)
  const [duplicateData, setDuplicateData] = useState({})
  const [dataElementDash, setDataElementDash] = useState({ id: null, chartType: null })
  const [dataComments, setDataComments] = useState([])
  const [isLoadingComments, setIsLoadingComments] = useState(false)
  const { t } = useTranslation()
  let { reportId, periodId } = useParams()

  useEffect(() => {
    setIsLoadingComments(true)
    fetchCommentsList({ module: commentKey }).then(() => setIsLoadingComments(false))
  }, [fetchCommentsList, commentKey])

  useEffect(() => {
    setDataComments(commentsList)
  }, [commentsList])

  useEffect(() => {
    setIsLoading(true)
    fetchReportDahsboardList(reportId, periodId, {
      filter_date: selectedDates,
      filter: filters,
    }).then(() => setIsLoading(false))
  }, [fetchReportDahsboardList, periodId, reportId, selectedDates, filters])

  const renderHeader = () => (
    <PeriodNavigator
      items={[
        {
          name: t('LABEL_ANALYSIS'),
          url: `/analisis/${periodId}`,
        },
        {
          name: t('REPORT_PLIKA_CARD_TITLE'),
          url: `/analisis/${periodId}/reportesplika`,
        },
        { name: name_report || '' },
      ]}
    />
  )

  const renderEmptyScreen = () => (
    <EmptyScreen
      image={emptyStateImage}
      title={t('EMPTY_STATE_NO_DATA_TITLE')}
      description={t('REPORT_DASHBOARD_EMPTY_AREA_DESCRIPTION')}
      footer={
        <Button.Primary title={t('ACTION_CREATE_NEW')} onClick={() => setShowCreateModal(true)} />
      }
    />
  )

  return (
    <Spin spinning={isLoading}>
      {!isLoading && renderHeader()}
      {reportDahsboardList.length === 0 && renderEmptyScreen()}
      {reportDahsboardList.length > 0 && (
        <Row gutter={[8, 8]}>
          <Col span={24}>
            <ButtonActionBar>
              <Button.Primary
                title={t('ACTION_CREATE_NEW')}
                onClick={() => setShowCreateModal(true)}
              />
            </ButtonActionBar>
          </Col>
          {reportDahsboardList
            .asMutable()
            .sort((itemA, itemB) => sizes[itemA.size] - sizes[itemB.size])
            .map((dataElement, index) => (
              <Col span={sizes[dataElement.size]} key={dataElement.id}>
                <ElementContainer
                  key={dataElement.id}
                  dataElement={dataElement}
                  index={index}
                  setShowCreateModal={setShowCreateModal}
                  setShowSettingModal={setShowSettingModal}
                  setDataElementDash={setDataElementDash}
                  setDuplicateData={setDuplicateData}
                  settingsChart={settingsCharts[dataElement.id]}
                  onRefetch={() =>
                    fetchReportDahsboardList(reportId, periodId, {
                      filter_date: selectedDates,
                      filter: filters,
                    })
                  }
                  setDataComments={setDataComments}
                  dataComments={dataComments}
                  onRefetchComments={() => fetchCommentsList({ module: commentKey })}
                  loadingComments={isLoadingComments}
                  commentKey={commentKey}
                />
              </Col>
            ))}
        </Row>
      )}
      <ElementCreateModal
        visible={showCreateModal}
        onCancel={() => setShowCreateModal(false)}
        duplicateData={duplicateData}
        setDuplicateData={setDuplicateData}
        chartType={dataElementDash.chartType}
        onRefetch={() =>
          fetchReportDahsboardList(reportId, periodId, {
            filter_date: selectedDates,
            filter: filters,
          })
        }
      />
      {dataElementDash.id && (
        <ChartsSettingsModal
          visible={showSettingModal && dataElementDash.chartType}
          chart_type={dataElementDash.chartType}
          settingsChart={settingsCharts[dataElementDash.id]}
          onCancel={() => {
            setShowSettingModal(false)
            setDataElementDash({ id: null, chartType: null })
          }}
          onOk={(values) => setSettingsCharts({ id: dataElementDash.id, data: values })}
        />
      )}
    </Spin>
  )
}

const mapStateToProps = (state) => ({
  reportDahsboardList: reports.selectors.getReportDahsboardList(state),
  settingsCharts: reports.selectors.getSettingsCharts(state),
  reportDetail: reports.selectors.getReportDetail(state),
  commentsList: configuration.selectors.getCommentsList(state),
})

const mapDispatchToProps = {
  fetchReportDahsboardList: reports.actions.fetchReportDahsboardList,
  setSettingsCharts: reports.actions.setSettingsCharts,
  fetchCommentsList: configuration.actions.fetchCommentsList,
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportDashboard)
