import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { ReportDashboards, ReportList } from './ReportHome/components'
import ReportHome from './ReportHome/ReportHome'
import ReportDetailHome from './ReportDetailHome/ReportDetailHome'
import { ProjectionTrackingScreen } from 'modules/core/components'

const Home = () => (
  <Switch>
    <Route exact path="/analisis/:periodId" component={ReportHome} />
    <Route exact path="/analisis/:periodId/reportesplika" component={ReportList} />
    <Route exact path="/analisis/:periodId/reportesselfservice" component={ReportDashboards} />
    <Route exact path="/analisis/:periodId/reportesplika/:reportId" component={ReportDetailHome} />
    <Route exact path="/analisis/:periodId/actividad/" component={ProjectionTrackingScreen} />
    <Route
      exact
      path="/analisis/:periodId/reportesplika/:reportId/actividad"
      component={ProjectionTrackingScreen}
    />
    <Redirect to="/analisis" />
  </Switch>
)

export default Home
