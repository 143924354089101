import { CommentOutlined, DollarOutlined } from '@ant-design/icons'
import { Space } from 'antd'
import {
  ButtonActionBar,
  Comments,
  FavouriteIcon,
  FavouriteSelect,
  References,
  Typography,
} from 'modules/core/components'
import React from 'react'
import { useTranslation } from 'react-i18next'

const TableActions = ({
  onClickSaveFav,
  visibleFav,
  onClickSave,
  onClickCancelSaveFav,
  setFavouriteName,
  favouriteName,
  isLoading,
  hasFavourite = false,
  options = [],
  canSave,
  viewSelect = undefined,
  setViewSelect,
  alreadyExist = false,
  layoutLoading,
  setPrimary,
  onClickDeleteFav,
  showComments,
  hasComment,
  setHasComment,
  setViewComment,
  settings,
  setDataComments,
  dataComments,
  onRefetchComments,
  loadingComments,
  onCommentCreate,
  removeNewComment,
  currencyData,
}) => {
  // Hooks
  const { t } = useTranslation()

  return (
    <ButtonActionBar position="space-between">
      <References
        referencesItems={[
          {
            color: '',
            type: '123',
            title: t('LABEL_TYPE_VALUES_REFERENCE', {
              type: settings?.aggregatorName?.toLowerCase() || t('FIELD_DECIMALS').toLowerCase(),
            }),
          },
          {
            color: '#a73ba1',
            type: 'Ab',
            title: t('LABEL_RATIO'),
            description: t('REPORT_VALUES_RATIO'),
          },
          ...(currencyData
            ? [
                {
                  type: <DollarOutlined />,
                  title: currencyData && currencyData.code,
                  description:
                    currencyData &&
                    t('REFERENCE_DESCRIPTION', {
                      currencyName: t(currencyData.name).toLowerCase(),
                    }),
                },
              ]
            : []),
        ]}
      />
      <Space direction="horizontal">
        {canSave && (
          <FavouriteIcon
            visible={visibleFav}
            favouriteName={favouriteName}
            setFavouriteName={setFavouriteName}
            onClickCancelSaveFav={onClickCancelSaveFav}
            onClickSave={onClickSave}
            loading={isLoading}
            alreadyExist={alreadyExist}
            onClickSaveFav={onClickSaveFav}
          />
        )}
        {hasFavourite && (
          <FavouriteSelect
            viewSelect={viewSelect}
            loading={layoutLoading}
            options={options}
            setPrimary={setPrimary}
            setViewSelect={setViewSelect}
            onClickDeleteFav={onClickDeleteFav}
          />
        )}
        {showComments && (
          <Comments
            hasComment={hasComment.table}
            onClick={(visible) => {
              setHasComment({ type: 'table', data: visible })
              !visible && removeNewComment()
              !visible && setViewComment(visible)
            }}
            comments={dataComments}
            setData={setDataComments}
            loading={loadingComments}
            onRefetchComments={onRefetchComments}
            onCommentCreate={onCommentCreate}
            onCleanHasComment={() => {
              setHasComment({ type: 'table', data: false })
              setViewComment(false)
            }}
          >
            <Typography.Icon
              style={{ cursor: 'pointer' }}
              title={t('LABEL_COMMENTS')}
              icon={CommentOutlined}
            />
          </Comments>
        )}
      </Space>
    </ButtonActionBar>
  )
}

export default TableActions
