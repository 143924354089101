import React, { useEffect, useState } from 'react'
import { Card, List, Modal, Space, Spin } from 'antd'
import { UserAvatar } from 'modules/configuration/components'
import { Button } from 'modules/core/components'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { handleOnClick } from './utils'
import { useTranslation } from 'react-i18next'
import { tokenutils } from 'modules/login/actions'
import CodeModal from '../CodeModal/CodeModal'
import login from 'modules/login'

import './AssociatedAccountModal.scss'

const AssociatedAccountModal = ({
  visible,
  onCancel,
  associatedAccount,
  loginOtherAccountsList,
  whoAmI,
  setAuthenticated,
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [accountId, setAccountId] = useState(null)
  const [showQrModal, setShowQrModal] = useState(false)
  const { t } = useTranslation()
  let history = useHistory()

  useEffect(() => {
    return () => {
      setIsLoading(false)
      setAccountId(null)
    }
  }, [])

  const onClose = () => {
    setIsLoading(false)
    setAccountId(null)
    onCancel()
  }

  const onCancelModal = () => {
    onCancel()
    setAuthenticated()
    tokenutils.removeToken()
    history.push('/login')
  }

  return (
    <>
      <Modal
        title={t('LOGIN_ASSOCIATED_ACCOUNT_MODAL_TITLE')}
        visible={visible}
        centered
        forceRender
        destroyOnClose
        onCancel={onCancelModal}
        bodyStyle={{ overflowY: 'auto', maxHeight: 400 }}
        footer={
          <Button.Primary title={t('ACTION_EXIT')} onClick={onCancelModal} disabled={isLoading} />
        }
      >
        {loginOtherAccountsList &&
          loginOtherAccountsList.map((dataAccount) => (
            <List
              key={dataAccount.society}
              grid={{ gutter: 24, xs: 1, sm: 1, md: 1, lg: 1, xl: 1, xxl: 1 }}
              dataSource={dataAccount.accounts_sso}
              renderItem={(account) => {
                return (
                  <List.Item
                    key={account.id_account_sso}
                    style={{ display: 'flex', justifyContent: 'center' }}
                  >
                    <Card
                      size="small"
                      style={{ width: '50%' }}
                      bodyStyle={{ padding: 9 }}
                      className="associate-account-modal"
                      onClick={() => {
                        setAccountId(account.id_account_sso)
                        handleOnClick({
                          setIsLoading,
                          associatedAccount: () =>
                            associatedAccount({ id_account_sso: account.id_account_sso }),
                          whoAmI,
                          onClose,
                          history,
                          setAuthenticated,
                          onShowCode: setShowQrModal,
                        })
                      }}
                    >
                      <Spin spinning={isLoading && accountId === account.id_account_sso}>
                        <Space direction="vertical">
                          <UserAvatar
                            size="large"
                            avatarLetter={`${account.full_name.split(' ')[0][0]}${
                              account.full_name.split(' ')[1][0]
                            }`}
                            fullname={account.full_name}
                            description={t('LOGIN_ASSOCIATED_ACCOUNT_SOCIETY_NAME', {
                              societyName: dataAccount.society,
                            })}
                          />
                        </Space>
                      </Spin>
                    </Card>
                  </List.Item>
                )
              }}
            />
          ))}
      </Modal>
      <CodeModal
        visible={showQrModal}
        onCancel={() => {
          setShowQrModal(false)
          onClose()
        }}
      />
    </>
  )
}

const mapStateToProps = (state) => ({
  loginOtherAccountsList: login.selectors.getLoginOtherAccountsList(state),
})

const mapDispatchToProps = {
  associatedAccount: login.actions.associatedAccount,
  whoAmI: login.actions.whoAmI,
  setAuthenticated: login.actions.setAuthenticated,
}

export default connect(mapStateToProps, mapDispatchToProps)(AssociatedAccountModal)
