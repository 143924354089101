import { notification } from 'antd'
import { generateMessageError } from 'modules/core/utils'
// import { GA } from "modules/core/utils";
import i18n from 'i18next'
import moment from 'moment'

export const onPublishDates = ({ setIsLoading, publishMonth, onClose, onConfirm }) => {
  setIsLoading(true)
  publishMonth()
    .then(() => {
      onConfirm()
      onClose()
      // GA.event(GA.ACTIONS.PUBLISH_DATA);
    })
    .catch((error) => {
      onClose()
      notification.error({
        message: i18n.t('FEEDBACK_DEFAULT_ERROR'),
        description: generateMessageError(
          error,
          'CONTROL_PUBLISH_MONTHS_FAIL_FEEDBACK_DESCRIPTION',
        ),
        duration: 0,
      })
    })
}

export const onDisabledDates = (date, availableDate) => {
  const pickerDates = moment(date._d).format('YYYY-MM')
  if (availableDate.length === 0) {
    return true
  }
  return !availableDate.includes(pickerDates)
}
