import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { Space, Row, Col, Button, Spin } from 'antd'
import { Navigator, ButtonActionBar, EmptyScreen } from 'modules/core/components'
import { SiderLayout } from 'modules/core/layouts'
import { CreateRuleDetailModal, RuleDetailTable } from './components'
import { isUserAllowed } from 'modules/core/utils'
import { ROLES } from 'modules/core/constants'
import configuration from 'modules/configuration'
import emptyStateImage from 'assets/images/compositions/empty-big.svg'

const { SETTINGS__PROJECTION_ELEMENTS__BUSINESS_RULES__ABM } = ROLES

// TODO: Conexión listado de factores (ahora usa los del detalle)
// TODO: Buscador de tabla
// TODO: Carga de archivo xls + descarga template
const RuleDetail = ({
  formulaDetail,
  fetchFormulaDetail,
  fetchFactoresBehaviours,
  optionsBehaviour,
}) => {
  const { t } = useTranslation()
  const [isScreenLoading, setIsScreenLoading] = useState(true)
  const [isDataLoading, setIsDataLoading] = useState(false)
  const [showCreateModal, setShowCreateModal] = useState(false)

  let { formulaId } = useParams()

  useEffect(() => {
    Promise.all([fetchFormulaDetail(formulaId), fetchFactoresBehaviours()]).then(() => {
      setIsScreenLoading(false)
    })
  }, [fetchFormulaDetail, formulaId, fetchFactoresBehaviours])

  const userCanCreate = isUserAllowed(SETTINGS__PROJECTION_ELEMENTS__BUSINESS_RULES__ABM)

  const renderHeader = () => (
    <Navigator
      floated={false}
      size="big"
      items={[
        {
          name: t('LABEL_CONFIG'),
          url: `/configuracion`,
        },
        {
          name: t('LABEL_TOOLS'),
          url: '/configuracion/herramientas',
        },
        {
          name: t('LABEL_BUSINESS_RULES'),
          url: '/configuracion/herramientas?tab=rules',
        },
        { name: formulaDetail.name },
      ]}
    />
  )

  const renderNoDataScreen = () => (
    <EmptyScreen
      image={emptyStateImage}
      title={t('CONFIG_FACTOR_EMPTY_SCREEN_TITLE')}
      description={t('CONFIG_FACTOR_EMPTY_SCREEN_DESCRIPTION')}
      footer={
        <Button type="primary" onClick={() => setShowCreateModal(true)} disabled={!userCanCreate}>
          {t('CONFIG_FACTOR_CREATE_ACTION')}
        </Button>
      }
    />
  )

  const renderTopActions = () => (
    <ButtonActionBar>
      <Space size="middle">
        <Button type="primary" onClick={() => setShowCreateModal(true)} disabled={!userCanCreate}>
          {t('ACTION_CREATE_NEW')}
        </Button>
      </Space>
    </ButtonActionBar>
  )

  return (
    <SiderLayout>
      <Space direction="vertical" size="middle">
        <Row gutter={[24, 24]}>
          <Col span={24}>{renderHeader()}</Col>
          {!isScreenLoading && formulaDetail.factores.length <= 0 && (
            <Col span={24}>{renderNoDataScreen()}</Col>
          )}
          <Spin spinning={isScreenLoading} />
          {!isScreenLoading && formulaDetail.factores.length > 0 && (
            <>
              <Col span={24}>{renderTopActions()}</Col>
              <Col span={24}>
                {!isScreenLoading && (
                  <RuleDetailTable
                    loading={isDataLoading}
                    dataSource={formulaDetail.factores}
                    onFetchFactorsList={() => fetchFormulaDetail(formulaId)}
                    optionsBehaviour={optionsBehaviour}
                  />
                )}
              </Col>
            </>
          )}
        </Row>
        <CreateRuleDetailModal
          visible={showCreateModal}
          onCancel={() => setShowCreateModal(false)}
          refresh={() => {
            setIsDataLoading()
            fetchFormulaDetail(formulaId).then(() => {
              setIsDataLoading(false)
            })
          }}
          optionsBehaviour={optionsBehaviour}
        />
      </Space>
    </SiderLayout>
  )
}

const mapStateToProps = (state) => ({
  formulaDetail: configuration.selectors.getFormulaDetail(state),
  optionsBehaviour: configuration.selectors.getFactoresBehaviours(state),
})

const mapDispatchToProps = {
  fetchFormulaDetail: configuration.actions.fetchFormulaDetail,
  fetchFactoresBehaviours: configuration.actions.fetchFactoresBehaviours,
}

export default connect(mapStateToProps, mapDispatchToProps)(RuleDetail)
