import odooImage from 'assets/images/integraciones/Odoo_logo.svg'
import qbImage from 'assets/images/integraciones/qb-logo.svg'
import sheetImage from 'assets/images/integraciones/sheets-logo.svg'

const QUICKBOOK_KEY = 'quickbooks'
const FILE_KEY = 'file'
const ODOO_KEY = 'odoo'
const SPREADSHEETS_KEY = 'spreadsheets'

const LIST = [
  {
    id: FILE_KEY,
    name: 'Excel',
    width: 200,
    is_connect: false,
    type: 'manual',
  },
  {
    id: ODOO_KEY,
    name: 'Odoo',
    image: odooImage,
    width: 200,
    is_connect: false,
    type: 'integration',
  },
  {
    id: QUICKBOOK_KEY,
    name: 'QuickBooks',
    image: qbImage,
    width: 200,
    is_connect: false,
    type: 'integration',
  },
  {
    id: SPREADSHEETS_KEY,
    name: 'SpreadSheets',
    image: sheetImage,
    style: { padding: 10 },
    width: 230,
    is_connect: false,
    type: 'manual',
  },
]

const KEYS = {
  QUICKBOOK_KEY,
  FILE_KEY,
  ODOO_KEY,
  SPREADSHEETS_KEY,
}

const INTEGRATIONS = { LIST, KEYS }

export default INTEGRATIONS
