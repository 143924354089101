// TODO: APLICAR i18n
const ADJUSTMENT_KEY = 'AJUSTMENT'
const DISTRIBUTION_KEY = 'DISTRIBUTION'

const TYPES = {
  extension: 'AMPLIACION',
  reduction: 'REDUCCION',
  reclassification: 'RECLASIFICACION',
}

const KEY = {
  ADJUSTMENT_KEY,
  DISTRIBUTION_KEY,
}

const DATA_TYPE = [
  {
    id: ADJUSTMENT_KEY,
    name: 'LABEL_ADJUSTMENTS',
  },
  {
    id: DISTRIBUTION_KEY,
    name: 'LABEL_DISTRIBUTIONS',
  },
]

const OPTIONS_ADJUSTMENT_TYPE = [
  {
    id: TYPES.extension,
    name: 'ADJUSTMENT_TYPE_EXTENSION',
  },
  {
    id: TYPES.reclassification,
    name: 'ADJUSTMENT_TYPE_RECLASSIFICATION',
  },
  {
    id: TYPES.reduction,
    name: 'ADJUSTMENT_TYPE_REDUCTION',
  },
]

const OPTIONS_DISTRIBUTION_TYPE = [
  {
    id: 'PORCENTAJE',
    name: 'FIELD_PERCENTAGE',
  },
  {
    id: 'VALOR_ABSOLUTO',
    name: 'FIELD_ABSOLUTE',
  },
]

const ADJUSTMENTS = { TYPES, KEY, DATA_TYPE, OPTIONS_ADJUSTMENT_TYPE, OPTIONS_DISTRIBUTION_TYPE }

export default ADJUSTMENTS
