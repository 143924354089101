import React from 'react'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { DatePicker } from 'antd'

const RangePicker = ({
  onSetRange = null,
  placeholder = null,
  format = 'MMMM YYYY',
  picker = 'month',
  periodDate = {},
  ...props
}) => {
  const { t } = useTranslation()
  const handleOnChange = (dates) => {
    if (dates !== null && dates[0] && dates[1]) {
      onSetRange({
        'start-date': moment(dates[0]._d).startOf('month').format('YYYY-MM-DD'),
        'end-date': moment(dates[1]._d).endOf('month').format('YYYY-MM-DD'),
      })
    } else {
      onSetRange({})
    }
  }

  const disabledDate = (date) => {
    const pickerDates = moment(date._d).utc().format('YYYY-MM')

    return !(pickerDates >= periodDate.startDate && pickerDates <= periodDate.endDate)
  }

  return (
    <DatePicker.RangePicker
      {...props}
      picker={picker}
      placeholder={placeholder ? placeholder : [t('FIELD_START_DATE'), t('FIELD_END_DATE')]}
      format={format}
      disabledDate={disabledDate}
      onChange={onSetRange ? handleOnChange : null}
    />
  )
}

export default RangePicker
