import React from 'react'
import { ListFooter, ListView } from './components'

const ChatbotList = ({ footerProps, ...props }) => {
  return (
    <ListView
      {...props}
      ListFooterComponent={
        footerProps ? (
          <ListFooter loading={footerProps.loading} footerText={footerProps.footerText} />
        ) : null
      }
    />
  )
}

export default ChatbotList
