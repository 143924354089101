import React from 'react'
import { Modal, Form, Input, Row, Col } from 'antd'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import configuration from 'modules/configuration'
import { Typography } from 'modules/core/components'
import { onDeleteUser } from './utils'

const ComfirmDeleteUserModal = ({
  visible,
  onClose,
  onCleanData,
  deleteUser,
  onRefetch,
  setIsSaving,
  id,
}) => {
  const [form] = Form.useForm()
  const { t } = useTranslation()

  const onCancel = () => {
    form.resetFields()
    onCleanData()
    onClose()
  }

  return (
    <Modal
      title={t('CONFIRM_ACTION_TITLE')}
      visible={visible}
      onCancel={onCancel}
      onOk={() =>
        onDeleteUser({
          form,
          deleteUser: (values) => deleteUser(id, values),
          setIsSaving,
          onRefetch,
          onClose: onCancel,
        })
      }
    >
      <Form form={form} layout="vertical">
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <Typography.Body>{t('CONFIG_USERS_CONFIRM_PASSWORD_TYPOGRAPHY_TEXT')}</Typography.Body>
          </Col>
          <Col span={24}>
            <Form.Item
              label={t('LOGIN_FORM_INPUT_PASSWORD_LABEL')}
              name="current_password"
              rules={[
                {
                  required: true,
                  message: t('LOGIN_FORM_INPUT_A_PASSWORD_CAPTION_EMPTY'),
                },
              ]}
            >
              <Input.Password placeholder={t('LOGIN_FORM_INPUT_PASSWORD_PLACEHOLDER')} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

const mapDispatchToProps = {
  deleteUser: configuration.actions.deleteUser,
}

export default connect(null, mapDispatchToProps)(ComfirmDeleteUserModal)
