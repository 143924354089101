import React, { useState } from 'react'
import { Popconfirm } from 'antd'
import { Button, Typography } from 'modules/core/components'
import { DeleteOutlined } from '@ant-design/icons'
import { connect } from 'react-redux'
import home from 'modules/home'
import { useTranslation } from 'react-i18next'

const ButtonDeletedFile = ({ deleteFile, indexFact, dataFact }) => {
  const [deletFileId, setDeletFileId] = useState(null)
  const { t } = useTranslation()

  return (
    <Popconfirm
      placement="bottomRight"
      title={
        <div style={{ width: 400 }}>
          {t('HOME_INITIAL_STEPS_SELECT_DATA_DELETE_UPLOAD_FILE_POPCONFIRM_TITLE')}
        </div>
      }
      okText={t('ACTION_DELETE')}
      onConfirm={() => deleteFile(indexFact)}
      cancelText={t('ACTION_CANCEL')}
      onCancel={() => setDeletFileId(null)}
      visible={deletFileId === dataFact.id}
    >
      <Button.Icon
        title="ACTION_DELETE"
        icon={<Typography.Icon level={2} icon={DeleteOutlined} />}
        onClick={(event) => {
          event.stopPropagation()
          setDeletFileId(dataFact.id)
        }}
      />
    </Popconfirm>
  )
}
const mapDispatchToProps = {
  deleteFile: home.actions.deleteFile,
}

export default connect(null, mapDispatchToProps)(ButtonDeletedFile)
