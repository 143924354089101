import { React } from 'react'
import { Space, Popconfirm } from 'antd'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import Button from '../Button/Button'

const DeleteAndUpdate = ({ dataCellEdit, onCancel, onConfirm, onSave, isEditing }) => {
  let { t } = useTranslation()

  return (
    <Space direction="horizontal" size="middle">
      {!_.isEmpty(dataCellEdit) ? (
        <Popconfirm
          placement="bottomRight"
          title={t('POPCONFIRM_EDIT_CANCEL')}
          okText={t('YES')}
          onConfirm={onConfirm}
          cancelText={t('NO')}
          okbuttonprops={{ loading: isEditing }}
        >
          <Button.Link title="ACTION_CANCEL" />
        </Popconfirm>
      ) : (
        <Button.Link onClick={onCancel} title="ACTION_CANCEL" />
      )}
      <Button.Link
        onClick={onSave}
        disabled={_.isEmpty(dataCellEdit)}
        title="ACTION_SAVE_CHANGES"
      />
    </Space>
  )
}
export default DeleteAndUpdate
