import { message } from 'antd'
import { generateMessageError } from 'modules/core/utils'
import i18n from 'i18next'

export const onCreate = ({
  form,
  setIsLoading,
  createRole,
  onRefetch,
  onClose,
  setShowLicenseDrawer,
}) => {
  form
    .validateFields()
    .then((values) => {
      setIsLoading(true)
      createRole(values)
        .then((response) => {
          onRefetch()
          message.success(i18n.t('FEEDBACK_CREATE_SUCCESS'), 8)
          setShowLicenseDrawer({
            roleId: response.payload.data.id,
            name: response.payload.data.name,
            visible: true,
          })
          onClose()
        })
        .catch((error) => {
          message.error(generateMessageError(error, 'FEEDBACK_CREATION_FAIL'), 8)
          onClose()
        })
    })
    .catch((e) => {})
}

export const onDuplicate = ({ form, setIsLoading, duplicateRole, onRefetch, onClose }) => {
  form
    .validateFields()
    .then((values) => {
      setIsLoading(true)
      duplicateRole(values)
        .then(() => {
          onRefetch()
          message.success(i18n.t('FEEDBACK_CREATE_SUCCESS'), 8)
          onClose()
        })
        .catch((error) => {
          message.error(generateMessageError(error, 'FEEDBACK_CREATION_FAIL'), 8)
          onClose()
        })
    })
    .catch((e) => {})
}
