import Immutable from 'seamless-immutable'
import * as types from './constants'

const initialState = Immutable({
  questionsList: {
    count: 0,
    values: [],
  },
})

/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_QUESTION_LIST_SUCCESS:
      return state.merge({
        questionsList: {
          count: action.payload.data.count,
          values: action.payload.data.results,
        },
      })

    default:
      return state
  }
}
