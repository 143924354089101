import React, { useEffect, useState } from 'react'
import { Form, Input, Modal, Row, Col } from 'antd'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import societies from 'modules/societies'
import { onCreateSociety } from './utils'
import { Button } from 'modules/core/components'

const { TextArea } = Input

const SocietyCreateModal = ({ createSociety, onRefetchSocietiesList }) => {
  const [showCreateModal, setShowCreateModal] = useState(false)
  const [isCreating, setIsCreating] = useState(false)
  const { t } = useTranslation()
  const [form] = Form.useForm()

  useEffect(() => {
    return () => {
      setShowCreateModal(false)
      setIsCreating(false)
    }
  }, [])

  const onClose = () => {
    form.resetFields()
    setShowCreateModal(false)
    setIsCreating(false)
  }

  return (
    <>
      <Button.Primary onClick={() => setShowCreateModal(true)} title="ACTION_CREATE_NEW" />
      <Modal
        title={t('SOCIETY_ADD_NEW_ACTION')}
        visible={showCreateModal}
        cancelText={t('ACTION_CANCEL')}
        centered
        onCancel={onClose}
        okText={t('ACTION_CREATE')}
        onOk={() =>
          onCreateSociety({
            form,
            createSociety,
            setIsCreating,
            onRefetch: onRefetchSocietiesList,
            onClose,
          })
        }
        okButtonProps={{ loading: isCreating, disabled: isCreating }}
        cancelButtonProps={{ disabled: isCreating }}
      >
        <Form form={form} layout="vertical">
          <Row gutter={[12, 12]}>
            <Col span={12}>
              <Form.Item
                name="name"
                label={t('SOCIETY_LABEL_NAME')}
                rules={[{ required: true, message: t('REQUIRED_FIELD') }]}
              >
                <Input placeholder={t('ENTER_NAME_PLACEHOLDER')} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="domain"
                label={t('SOCIETY_LABEL_DOMAIN')}
                rules={[{ required: true, message: t('REQUIRED_FIELD') }]}
              >
                <Input placeholder={t('SOCIETY_ENTER_DOMAIN_PLACEHOLDER')} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="description"
                label={t('SOCIETY_LABEL_DESCRIPTION')}
                rules={[{ required: true, message: t('REQUIRED_FIELD') }]}
              >
                <TextArea
                  rows={2}
                  placeholder={t('SOCIETY_ENTER_DESCRIPTION_PLACEHOLDER')}
                  maxLength={100}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  )
}

const mapDispatchToProps = {
  createSociety: societies.actions.createSociety,
}

export default connect(null, mapDispatchToProps)(SocietyCreateModal)
