import { Col, Select, Space } from 'antd'
import _ from 'lodash'
import { Typography } from 'modules/core/components'
import { PROJECTION_ELEMENTS } from 'modules/core/constants'
import { generateSelectOptions } from 'modules/core/utils'
import { useTranslation } from 'react-i18next'

const ContainerDataFormula = ({
  variablesIds,
  value,
  onChangeFormula,
  variablesList,
  onChangeVariable,
}) => {
  const { t } = useTranslation()
  return (
    <>
      <Col span={24}>
        <Space direction="horizontal">
          <Typography.Body>
            {t('CONFIG_RULES_FORMULA_VALUES_STEP_FORMULA_LABEL_ORIGIN')} *{' '}
          </Typography.Body>
          <Select
            style={{ width: 350 }}
            placeholder={t('CONFIG_RULES_FORMULA_SELECT_VARIABLE_PLACEHOLDER')}
            allowClear
            value={value}
            onChange={onChangeFormula}
            options={generateSelectOptions({
              options: PROJECTION_ELEMENTS.FORMULAS_OPTIONS,
            })}
          />
          <Typography.Body>
            = {t('CONFIG_RULES_FORMULA_VALUES_STEP_FORMULA_LABEL_DESTINATION')}
          </Typography.Body>
        </Space>
      </Col>
      {_.keys(variablesIds).length > 0 && (
        <>
          <Col span={24}>
            <Typography.Body level={2}>
              {t('CONFIG_RULES_FORMULA_SELECT_VARIABLE_TITLE')}
            </Typography.Body>
          </Col>
          {_.keys(variablesIds).map((variable, index) => (
            <Col
              key={index}
              span={!_.isEmpty(variablesIds) ? 24 / _.keys(variablesIds).length : 24}
            >
              <Select
                style={{ width: '100%' }}
                value={variablesIds[variable]}
                placeholder={variable}
                onChange={(id) => onChangeVariable(id, variable)}
                options={generateSelectOptions({ options: variablesList })}
              />
            </Col>
          ))}
        </>
      )}
    </>
  )
}

export default ContainerDataFormula
